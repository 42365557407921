import React, { useEffect, useState, useReducer,useContext } from "react";
import { Context } from "../../../../App";
import { cancelRoom } from "../operations";
import {Button, Row, Form, Alert, Modal}from 'react-bootstrap';
import Utilities from "../../../utilities/Utilities";
export const USER_AUTH_DATA = "userAuthData";

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
};

//=====================================
// Reducer function for UserAction
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CancelHotel({ cancelData, close, reloadQueueListAfterCancel }) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [email, setEmail] = useState("");
  const [cancelRemarks, setCancelRemarks] = useState("");
  const [selectedRooms, setSelectedRooms] = useState([cancelData.rooms[0]]);
  const [cancelPopup, setCancelPopup] = useState("");

  useEffect(() => {
    let data = localStorage.getItem(USER_AUTH_DATA);
    if (data !== null) {
      let itm = JSON.parse(data) 
      setEmail(itm.email);
    }
  }, []);

  // Function to handle checkbox changes
  const handleCheckboxChange = (checked, room) => {
    if (checked) {
      // If checked, add the item to the selectedRooms state
      setSelectedRooms([...selectedRooms, room]);
    } else {
      // If unchecked, remove the item from the selectedRooms state
      const updatedRooms = selectedRooms.filter(
        (selectedRoom) => selectedRoom.room_number !== room.room_number
      );
      setSelectedRooms(updatedRooms);
    }
  };

  const handleRemarksChange = (e) => {
    setCancelRemarks(e.target.value);
  };

  const submitCancelPopup = (type) =>{
    const filterSelectedRooms = selectedRooms.filter((room) => room.room_status.toUpperCase() === "CONFIRMED");
    if (filterSelectedRooms.length === 0) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Select alteast one room and proceed",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    } else {
      setCancelPopup(true);
    }
}

  const backToNormal = () => {
    close(false);
  };

  const submitCancel = () => {
      var selrooms = [];
      const filterSelectedRooms = selectedRooms.filter((room) => room.room_status.toUpperCase() === "CONFIRMED");
      if (filterSelectedRooms.length > 0) {
        filterSelectedRooms.forEach((rm, idx) => {
          var rmid = rm.rmid;
          var htlconfno = rm.supplier_ref;
          var htlrefno = rm.hotel_ref;
          var room_number = rm.room_number;
          var cancellation_policy = rm.cancellation_policy;
          selrooms.push({
            rmid,
            htlconfno,
            htlrefno,
            room_number,
            cancellation_policy,
          });
        }); 
        try {
          const payload = {
            email_id: email,
            sup: cancelData.sup,
            booking_id: cancelData.booking_id,
            hid: cancelData.hid,
            roomInfo: selrooms,
            upby: cancelData.upby,
            cid: cancelData.cid,
            cur: cancelData.usr_currency,
            region_type: cancelData.region_type,
            remarks: cancelRemarks,
          };
          //Call Cancel Room API
          let tokenKey = Utilities.generateAPIToken(payload);
          cancelRoom(payload,tokenKey).then((response) => {
            console.log("Hotel cancel response " + JSON.stringify(response?.data));
            if (response.suc) {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: response?.res,
              });
              dispatch({ type: "notiVarient", payload: "success" });
              reloadQueueListAfterCancel({product:"Hotel"})
              hidemessage();
              setTimeout(() => {
                backToNormal();
              }, 3000);
            } else {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: response.data,
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            }
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  const handleCloseCancel = () => {
    setCancelPopup(false)
  }

  return (
    <div>

      {state.notiMessageShow &&
        <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      
      {cancelData &&
        cancelData.rooms.map((room, idx) => (
          <div className="hotelRoomDetailsWrap">
            {room.room_status.toUpperCase() !== "CONFIRMED" ? (
              <>
              <span className="hotelRoomWrap">{"Room"} {room.room_number} : {room.room_name} </span>
              <span className="hotelRoomStatusWrap">{"Status"}:{" "}
                <span className="hotelRoomStatus primary">{room.room_status}</span>
              </span>
                
              </>
            ) :
            <>
            <div className="checkboxDormitory">
            <Form.Check
              type="checkbox"
              checked={selectedRooms.some(
                (selectedRoom) => selectedRoom.room_number === room.room_number
              )}
              onChange={(e) => handleCheckboxChange(e.target.checked, room)}
              disabled={room.room_status.toUpperCase() !== "CONFIRMED" ? true : false}
              custom
            />
            <span className="hotelRoomWrap">{"Room"} {room.room_number} : {room.room_name} </span>
            </div>
         
              
              
              <span className="hotelRoomStatusWrap">{"Status"}:{" "}
                <span className="hotelRoomStatus active">{room.room_status}</span>
              </span>
              </>
            } 
          </div>
        ))}
        <Form.Group className="mb-3">
          <Form.Control 
            as="textarea" 
            rows={3} 
            placeholder={"Remarks"}
            onChange={handleRemarksChange}
            value={cancelRemarks}
            maxLength={100}
          />
        </Form.Group>
      <p className="noteWrap"> <span className="notetitle">{"Note:"}</span>  {"One of our executive will connect with you once your request is submitted"}  </p>
      <div className="buttonWrap">
        <Button onClick={backToNormal} className="backBtn">
          {"Cancel"}
        </Button>
        <Button type="primary" onClick={submitCancelPopup} className="cntBtn cancelBtn" 
          disabled={cancelData.rooms.filter((room) => room.room_status.toUpperCase() === "CONFIRMED").length > 0 ? false : true}
        >
          {"Request Cancel"}
        </Button>
      </div>

      <Modal show={cancelPopup} onHide={handleCloseCancel} className='addtoQue'>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to process</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setCancelPopup(false);
            submitCancel()
          }}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => {
            setCancelPopup(false);
          }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CancelHotel;
