import React, { useEffect, useContext, useReducer, useState } from "react";
import { Col, Row, Container, Alert, Form, Button, Modal } from "react-bootstrap";
import Header from "../../common/Header";
import Sidebarmenu from "../../home/SideBar";
import { Context } from "../../../App";
import Footer from "../../common/Footer";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  checkCancelPrice,
  fetchHotelBookingInfo,
  processEditBooking,
} from "../../dashboard/queues/operations";
import TransactionSummary from "./TransactionSummary";
import Utilities from "../../utilities/Utilities";
import { isArrayNotEmpty, isNotNull } from "../../utilities/validators";
import HotelSummaryFare from "./HotelSummaryFare";
import FormateCurrency from "../../utilities/FormatCurrency";

const initialState = {
  htlresponse: "",
  notiMessageShow: false,
  notiMessage: "",
  notiVarient: "",
  loading: true,
  enableCancel: false,
  isprocess: false,
  enableManulRefund: false,
  enableEdit: false,
  updateBstatus: "",
  updateBstatusno: "",
  showEditpop: false, 
};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @author Praveen Varma
 * @date 21-11-2023
 * @description Hotel booking summary
 * @param {*} props
 * @returns
 */
function HotelSummaryPage(props) {
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const [context, setContext] = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [manageType, setManageType] = useState("");
  const [sel, setSel] = useState(false);
  const { booking_id } = useParams();
  const [states, setState] = useState({})
  const [roomStatusList, setRoomStatusList] = useState([]);

  useEffect(() => {
    if (context?.logindata) {
      if (
        booking_id === undefined ||
        booking_id === "" ||
        booking_id === null
      ) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: "Please provide booking id" });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return;
      }
      fetchBookingInfo();
    }
  }, [context?.logindata]);


  useEffect(() => {
    const ticketsList = statusList.map((obj) =>
        <option value={obj.value}>{obj.label}</option>
    );
    setRoomStatusList(ticketsList);
    for (let val of statusList) {
    //   if (val.label === room.rst) {
    //       room.stid = val.value;
    //   }
    }
}, [state.enableEdit])

  const advanceSearchOpen = () => {
    if (advanceSearch) {
      setAdvanceSearch(false);
    } else {
      setAdvanceSearch(true);
    }
  };

  const contentStyle = {
    margin: 0,
    height: "178px",
    width: "242px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    borderRadius: "4px",
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const fetchBookingInfo = async () => {
    if (booking_id === null || booking_id === "") {
      return;
    }
    let payload = {
      region_type: context?.logindata?.region_type,
      bid: booking_id,
      cid: context?.logindata?.cmp_id || undefined,
      uid: context?.logindata?.user_id || undefined,
      is_admin: context?.logindata?.is_admin ? 1:0
    };
    if (context?.logindata?.is_admin) {
      payload.is_admin = true;
    }
    fetchHotelBookingInfo(payload)
      .then((response) => {
        if (response.suc) {
          dispatch({ type: "htlresponse", payload: response.res });
          dispatch({ type: "loading", payload: false });
        } else {
          dispatch({ type: "htlresponse", payload: undefined });
          dispatch({ type: "loading", payload: false });
        }
      })
      .catch(function (error) {
        dispatch({ type: "loading", payload: false });
        console.error(
          "Exception occured in fetchHotelBookingInfo function---" + error
        );
      });
  };

  const SelectOptions = [
    { value: "Edit", label: "Edit Booking" },
    { value: "Refund", label: "Manual Refund" },
  ];

  const handleChangeType = (selectedOption) => {
    setManageType(selectedOption?.value);
    if(selectedOption?.value === "Refund") {
      dispatch({ type: "enableCancel", payload: true });
      dispatch({ type: "enableManulRefund", payload: true });
      dispatch({ type: 'enableEdit', payload: false })
    } else if (selectedOption?.value === "Edit") {
      for (let val of statusList) {
        for (let room of state.htlresponse.rooms) {
          if (val.label === room.room_status) {
              room.stid = val.value;
          }
        }
      }
      dispatch({ type: 'enableEdit', payload: true })
      dispatch({ type: 'enableCancel', payload: false })
    }
  };

  const showNonRefundable = (room) => {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({
      type: "notiMessage",
      payload: "Room" + room.room_no + ":" + room.rnm + " is Non Refundable.",
    });
    dispatch({ type: "notiVarient", payload: "danger" });
    hidemessage();
  };

  const handleChange = (obj) => () => {
    for (let room of state.htlresponse.rooms) {
      if (
        obj.cancellation_policy !== "non refundable" &&
        room.room_number === obj.room_number &&
        (obj.room_status?.toUpperCase() === "CONFIRMED" ||
          obj.room_status === "On hold")
      ) {
        if (obj.selected) {
          room.selected = false;
          setSel(false);
        } else {
          room.selected = true;
          setSel(true);
        }
      } else if (obj.cancellation_policy === "non refundable") {
        room.disble = true;
        room.selected = false;
        showNonRefundable(obj);
        break;
      }
    }
  };

  const createRoomPrice = (room, type) => {
    let total=Number(room.fare_map.salespr.bf)+Number(room.fare_map.salespr.tax)+Number(room.fare_map.salespr.markup)+Number(room.fare_map.salespr.servicefee)+Number(room.fare_map.salespr.onflymkp);
    if(room.fare_map.salespr.mtadisc!==undefined && room.fare_map.salespr.mtadisc!==0){
    total = total - Number(room.fare_map.salespr.mtadisc)
    }
    if(room.fare_map.salespr.tadisc!==undefined && room.fare_map.salespr.tadisc!==0){
    total = total - Number(room.fare_map.salespr.tadisc)
    }
    if(room.fare_map.salespr.com_disc!==undefined && room.fare_map.salespr.com_disc!==0){
    total = total - Number(room.fare_map.salespr.com_disc)
    }
    if(type==="TA" && room.fare_map.salespr.sup_fee!==undefined && room.fare_map.salespr.sup_fee!==0){
    total = total - Number(room.fare_map.salespr.sup_fee);
    }
    let refundFromSup=0;
    if(type==="CNT"){
    refundFromSup=Number(room.fare_map.salespr.bf)+Number(room.fare_map.salespr.tax);
    }else{
    refundFromSup=Number(room.fare_map.salespr.bf)+Number(room.fare_map.salespr.tax) + Number(room.fare_map.salespr.servicefee);

    }
    const req={
    "type":type,
    "bf": room.fare_map.salespr.bf,
    "taxes": room.fare_map.salespr.tax,
    "bkmarkup": room.fare_map.salespr.markup,
    "bkservice": room.fare_map.salespr.servicefee,
    "supfee": room.fare_map.salespr.sup_fee!==undefined && room.fare_map.salespr.sup_fee!==0?room.fare_map.salespr.sup_fee:0,
    "canservice": 0,
    "canmarkup": 0,
    "refundprice": total,
    "reffrmsup": refundFromSup,
    "roomprice": total,
    "supcancharges": 0,
    "cancharges": 0,
    "salestaxes": room.fare_map.salespr.sales_tax,
    "onflymkp": room.fare_map.salespr.onflymkp!==undefined && room.fare_map.salespr.onflymkp!==0?room.fare_map.salespr.onflymkp:0,
    "canmarkupdisc": 0,
    "com_disc" :room.fare_map.salespr.com_disc!==undefined && room.fare_map.salespr.com_disc!==0? room.fare_map.salespr.com_disc:0,
    "sup_disc": 0,
    "mtadisc" : room.fare_map.salespr.mtadisc!==undefined && room.fare_map.salespr.mtadisc!==0?room.fare_map.salespr.mtadisc:0,
    "tadisc": room.fare_map.salespr.tadisc!==undefined && room.fare_map.salespr.tadisc!==0?room.fare_map.salespr.tadisc:0,
    "reffrmusr": 0,
    "expediacharges": 0,
    "cur": state.htlresponse.usr_currency,
    "region_type":state.htlresponse.region_type
    }
    return req;
  };

  //Creating the Refund Rooms List
  const createRefundRoomsList = () => {
    let roomList = [];
    // for(let fare of state.htlresponse.fare_det){
    for (let fare of state.htlresponse.rooms) {
      //    if(fare.type==="MTA"){
      //  let selRooms=fare.rooms.filter((room)=>room.selected);
      let selRooms = [fare].filter((room) => room.selected);
      for (let room of selRooms) {
        //    let taList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
        //    let taRoomsList = taList[0].rooms.filter((obj) => Number(obj.rmid)===Number(room.rmid));
        var obj = {
          rmid: room.rmid,
          rnm: room.room_name,
          rno: room.room_number,
          adt: room.adults,
          chd: room.childs,
          htl_ref_num: room.hotel_ref,
          htlconfno: room.supplier_ref,
          rst: "Cancelled",
          prices: createRoomPrice(room, "CNT"),
          cur: state.htlresponse.usr_currency,
          region_type: state.htlresponse.region_type,
        };
        roomList.push(obj);
      }
      // }
    }
    let status = true;
    let st = "";
    let isRoomCancel = false;
    // let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    // for (let val of mtaList[0].rooms){
    for (let val of state.htlresponse.rooms) {
      if (val.room_status === "Confirmed" && !val.selected) {
        status = false;
        break;
      } else if (val.room_status === "Cancelled") {
        isRoomCancel = true;
      }
    }
    if (status === true) {
      st = "Cancelled";
    } else {
      st = "Partially Confirmed";
    }
    const request = {
      st: st,
      isRoomCancel: isRoomCancel,
      upby: 1,
      //  "butype":context.logindata.butype,
      cid: context.logindata.cid,
      pcid: context.logindata.pcid,
      booking_id: state.htlresponse.booking_id,
      hid: state.htlresponse.hid,
      refundprice: roomList,
      cur: state.htlresponse.usr_currency,
      region_type: state.htlresponse.region_type,
    };
    navigate("/crm/hotel/summary/manual-refund", {
        state: {
            req: request,
        },
    });
  };

  //Calling service to get the Refund prices
  const callCancelPrice = () => {
    let selRooms = [];
    let selected = false;
    let mtaList = [];
    let nonrefundable = false;
    let roomNo = "";
    // if (context.logindata.utype === "att_user") {
    //   mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    // } else if (context.logindata.utype === "ta_user") {
    //   mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    // } else if (context.logindata.utype === "sta_user") {
    //   mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    // }
    for (let val of state.htlresponse.rooms) {
      //   if(val.cancellation_policy=="non refundable" && state.htlresponse.rooms.bkng_det.supplier_id==1) {
      if (val.cancellation_policy == [] && state.htlresponse.sup == 1) {
        nonrefundable = true;
        roomNo = val.room_number;
      }
      if (val.selected) {
        const req = {
          rmid: val.rmid,
          htlconfno: val.supplier_ref,
          htlrefno: val.hotel_ref,
          room_number: val.room_number,
        };
        selRooms.push(req);
        selected = true;
      }
    }
    if (!nonrefundable) {
      if (selected) {
        if (state.enableCancel && !state.enableManulRefund) {
          dispatch({ type: "isprocess", payload: true });
          const payload = {
            // "sup":state.htlresponse.supplier_id,
            sup: state.htlresponse.sup,
            booking_id: state.htlresponse.booking_id,
            hid: state.htlresponse.hid,
            roomInfo: selRooms,
            upby: context?.logindata?.uid,
            // "butype": context?.logindata?.butype,
            cid: context?.logindata?.cid,
            pcid: context?.logindata?.pcid,
            cur: state.htlresponse.usr_currency,
            region_type: state.htlresponse.region_type,
          };
          checkCancelPrice(payload).then((response) => {
            if (response.suc && response.data.refundprice !== undefined) {
              //   var userType = (context.logindata.butype === "ta_user") ? "TA" : "STA";
              //   let priceList = response.data.refundprice.filter((obj) => obj.type === userType);
              let priceList = response.data.refundprice;

              //Validation Room wise st and msg tag values for displyaing--start
              let cnt_data = response.data.refundprice;
              let msg_arry = [];
              let roommsg_arry = [];
              if (isArrayNotEmpty(cnt_data)) {
                cnt_data[0].rooms.map((rmo) => {
                  if (isNotNull(rmo.st) && !rmo.st) {
                    let room_no = "Room - " + rmo.rno;
                    let msg = rmo.message;
                    let rm_obj = { label: room_no, value: msg };
                    msg_arry.push(rm_obj);
                    roommsg_arry.push(room_no);
                  }
                });
              }
              if (isArrayNotEmpty(msg_arry)) {
                let cmmsg =
                  "Unable to process the cancelation of " +
                  roommsg_arry.toString() +
                  ", please contact our customer support team. Dial: +974 44484848 ";
                let cm_obj = { label: "", value: cmmsg };
                msg_arry.push(cm_obj);
                dispatch({ type: "message_array", payload: msg_arry });
                dispatch({ type: "refundmsgshow", payload: true });
                dispatch({ type: "notiVarient", payload: "danger" });
                hidemessage();
              }
              //   let cnt_data1 = response.data.refundprice.filter((obj) => obj.type === "MTA");
              let data1 = response.data.refundprice;
              data1 = isArrayNotEmpty(data1)
                ? data1[0].rooms.filter((obj) => obj.st)
                : [];
              //   let ta_data2 = response.data.refundprice.filter((obj) => obj.type === "TA");
              //   ta_data2 = isArrayNotEmpty(ta_data2) ? ta_data2[0].rooms.filter(obj => obj.st) : [];
              //   let sta_data3 = response.data.refundprice.filter((obj) => obj.type === "STA");
              //   sta_data3 = isArrayNotEmpty(sta_data3) ? sta_data3[0].rooms.filter(obj => obj.st) : [];
              if (isArrayNotEmpty(data1)) {
                dispatch({ type: "refund_data_show", payload: true });
              }
              //Validation Room wise st and msg tag values for displyaing--End

              //   setPriceMtaList(response.data.refundprice.filter((obj) => obj.type === "MTA"));
              //   setPriceTaList(response.data.refundprice.filter((obj) => obj.type === "TA"));
              //   sePricetStaList(response.data.refundprice.filter((obj) => obj.type === "STA"));
              dispatch({ type: "cancelPriceResp", payload: priceList });
              dispatch({
                type: "processRequst",
                payload: response.data.refundprice,
              });
              dispatch({ type: "showCancelPop", payload: true });
              dispatch({ type: "isprocess", payload: false });
            } else {
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload:
                  "Unable to process the cancelation, please contact our customer support team. Dial: +974 44484848 ",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "isprocess", payload: false });
              hidemessage();
            }
          });
        } else if (state.enableCancel && state.enableManulRefund) {
          //For Manual Refund
          createRefundRoomsList();
        }
      } else {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload:
            "Please select atleast one room and proceed for the Cancellation",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
      }
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: `Room${roomNo} is non refundable`,
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const backCancel = () => {
    dispatch({ type: "enableCancel", payload: false });
    dispatch({ type: "enableManulRefund", payload: false });
    dispatch({ type: "enablePay", payload: false });
    // let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA")
    for (let room of state.htlresponse.rooms) {
      room.selected = false;
    }
    window.location = window.location = window.location.pathname;
  };

const statusList = [
    { value: '23', label: 'Confirmed' },
    { value: '28', label: 'Cancelled' },
    { value: '24', label: 'Amended' },
    { value: '22', label: 'On hold' },
    { value: '41', label: 'Partially Confirmed' }]

  const getStatus = (id) => {
      let stary = statusList.filter((obj) => obj.value === id);
      return stary[0].label;
  }  

  const handleRoomChange = (room, type) => (e) => {
    const { id, value } = e.target
    setState({ [e.target.name]: e.target.value });
    if (room !== undefined) {
        if (type === "status") {
          room.rst = getStatus(value);
          room.changed = true;
          room.stid = value;
        }
        if (type === "ref") {
          room.htlrefno = value;
          room.changed = true;
        }
        if (type === "bed_type") {
          room.bedtype = value;
          room.changed = true;
        }
    }
    // props.sendSelRoom(state.htlresponse.rooms[0])
}

const updateBstatus = (data) => {
  dispatch({ type: 'updateBstatus', payload: data })
}

const updateBno = (data) => {
  dispatch({ type: 'updateBstatusno', payload: data })
}

  //Processing the Edit Booking
  const processEditBookingFlow = () => {
    let roomList = [];
    let validate = false;
    if (state.updateBstatus !== "") {
      if (state.htlresponse.rooms.length !== 0) {
        let roomsList = state.htlresponse.rooms;
        if (roomsList.length !== 0) {
          for (let room of roomsList) {
            //if(validateRoom(room)){
            if ((room.changed !== undefined && room.changed) || (state.htlresponse.booking_status !== state.updateBstatusno)) {
              const payload = {
                "roomId": room.rmid,
                "room_no": room.room_number,
                "rst": room.rst !== undefined ? room.rst : room.room_status,
                "htlrefno": room.htlrefno !== undefined ? room.htlrefno : room.hotel_ref,
                "bed_type": room.bedtype !== undefined ? room.bedtype : room.bed_type
              }
              roomList.push(payload);
              validate = true;
            }
            //}
          }
        }
      }
      if (validate) {
        const payload = {
          "booking_id": state.htlresponse.booking_id,
          "bst": state.updateBstatus,
          "upby": context?.logindata?.user_id,
          "rooms": roomList
        }
        processEditBooking(payload).then((resp) => {
          dispatch({ type: 'showEditpop', payload: false })
          if (resp.suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Booking details are updated Successfully' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            setTimeout(() => {
              return window.location = window.location.pathname;
            }, 2000)
          } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Something went wrong.Booking details are not updated' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
          }
        })
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Edit alteast one field and proceed' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispatch({ type: 'showEditpop', payload: false })
        hidemessage();
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

  const oncloseEidt = () => {
    dispatch({ type: 'showEditpop', payload: false })
  }

  const callEditBooking = () => {
    dispatch({ type: 'showEditpop', payload: true })
  }

  const cancelEdit = () => {
    dispatch({ type: 'showEditpop', payload: false });
    dispatch({ type: 'enableEdit', payload: false });
    setManageType("");
    // return window.location = window.location.pathname;
  }

  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu />
          </Col>
          <Col xs={10}  className="mainftr">
            {state.notiMessageShow && (
              <Alert
                className="notification alert"
                variant={state.notiVarient}
                show={state.notiMessageShow}
                onClose={() =>
                  dispatch({ type: "notiMessageShow", payload: false })
                }
                dismissible
              >
                {" "}
                {state.notiMessage}
              </Alert>
            )}
            {state?.loading && <div className='loadding noDatafound'><span class="loadernew"></span></div>}
            {!state?.loading &&
              state?.htlresponse !== undefined &&
              state?.htlresponse !== null && (
                <div className="resultSection HotelBsummary boxShadow bg-white summary mb-4">
                  <div className="transactionSummery">
                    <h5 className="title d-flex justify-content-between">
                      Booking Details
                    </h5>
                    {state?.htlresponse !== undefined &&
                      state?.htlresponse !== "" &&
                      state?.htlresponse !== null && (
                        <TransactionSummary bkngdetails={state.htlresponse} sendBookStatus={updateBstatus} sendBookno={updateBno} enableEdit={state.enableEdit} />
                      )}
                  </div>
                  <div className="hotelreviewDetailsbooSummary">
                    <div className="bookingSUmmary">
                      <h5 className="pageTitle d-flex justify-content-between justify-items-center">
                        Booking Summary
                        <span
                          onClick={advanceSearchOpen}
                          className={
                            "plusAdv ezyIcon icon-" +
                            (advanceSearch ? "down-arrow" : "arrowup")
                          }
                        >
                          {" "}
                         </span>
                  
                      </h5>
                      
                      <div
                        className={
                          "advSerch " + (advanceSearch ? "active" : "inactive")
                        }
                      >
                        <div className="selectMangeBook">
                        <Form.Group className="selectplugin m-0">
                          <Select
                            //value={selectedOption}
                            isSearchable={false}
                            onChange={handleChangeType}
                            options={SelectOptions}
                            placeholder="Manage Booking"
                            classNamePrefix='filter'
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                              },
                            })}

                          />
                          </Form.Group>
                        </div>
                        <div className="hotelReviewDetails marginTop30">
                          <div className="sliderWrapper">
                            <span className="hotelTag">
                              {state?.htlresponse?.hotel_name}
                            </span>
                            <div className="Carousel">
                              <img
                                src={state?.htlresponse?.hotel_image}
                                style={contentStyle}
                              />
                            </div>
                          </div>

                          <div className="fullWidth">
                            <div>
                              <h4> {state?.htlresponse?.hotel_name}</h4>
                              <span className="stars">
                                {Utilities.getHotelStartRating(
                                  +state?.htlresponse?.star_rating?.split(
                                    "."
                                  )[0]
                                ).map((star, idx) => (
                                  <React.Fragment key={idx}>
                                    <span className="hotelRating">
                                      <span className="ezyIcon icon-star"></span>
                                    </span>
                                  </React.Fragment>
                                ))}
                              </span>
                              <h5 className="hotelAddress">
                                {" "}
                                {state?.htlresponse?.hotel_address},{" "}
                                {state?.htlresponse?.hotel_city},{" "}
                                {state?.htlresponse?.hotel_country}
                              </h5>
                              <div className="onlyFlex">
                                <div className="checkInBorderRight">
                                  <span className="checkInOut">
                                    {"Check-in"}
                                  </span>
                                  <span className="dateTime">
                                    {state?.htlresponse?.cin_date},{" "}
                                    {state?.htlresponse?.cin_time}
                                  </span>
                                </div>
                                <div>
                                  <span className="checkInOut">
                                    {"Check-Out"}
                                  </span>
                                  <span className="dateTime">
                                    {state?.htlresponse?.cout_date},{" "}
                                    {state?.htlresponse?.cout_time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="roomDetailTitle">
                            {"Room Details"}
                          </div>
                          {state.htlresponse &&
                            state?.htlresponse?.rooms.length > 0 &&
                            state?.htlresponse?.rooms.map((room, index) => (
                              <>
                                {state.enableCancel && (
                                  <Form.Check
                                    type="checkbox"
                                    disabled={room.disble}
                                    label=""
                                    custom
                                    id={index + 1}
                                    checked={room.selected}
                                    value={room.selected}
                                    onChange={handleChange(room)}
                                  />
                                )}
                                <div className="guestsWrapper">
                                  <div className="roomWrapr">
                                    {" "}
                                    {"Room"} {index + 1}: {room.room_name}
                                  </div>
                                  <div className="travellersWrapper">
                                    <span className="travellersTitle">
                                      {"Guests"}:
                                    </span>
                                    <span className="travellersDetailWrap">
                                      {room.pax.map((pax, index) => (
                                        <React.Fragment key={index}>
                                          <span className="travellersDetail">
                                            <i className="ezyIcon icon-newtick"></i>
                                            {pax.title} {pax.fname} {pax.lname}{" "}
                                            ({pax.type})
                                          </span>
                                        </React.Fragment>
                                      ))}
                                    </span>
                                  </div>
                                </div>
                                <div className="travellerDetails">
                                  <div className="tableHeader row m-0">
                                    <div className="tableValue col-md">
                                      {"Room Type"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Occupancy"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Board Type"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Hotel Ref"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Supplier Conf"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Special Request"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Status"}
                                    </div>
                                    <div className="tableValue col-md">
                                      {"Total Price"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="tableData row m-0">
                                      <div className="tableValue col-md">
                                        {!state.enableEdit && (room.bed_type !== undefined && room.bed_type ? room.bed_type : "N/A")}
                                        {state.enableEdit &&
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="bed_type" className="d-flex align-items-center justify-content-between">
                                                    <Form.Control type="text" placeholder="Enter Bed Type" value={room.bedtype} onChange={handleRoomChange(room, "bed_type")} />
                                                </Form.Group>
                                            </Form.Row>
                                        }
                                      </div>
                                      <div className="tableValue col-md">
                                        {room.adults}{" "}
                                        {+room.childs?.split(" ")[0] === 0
                                          ? ""
                                          : "," + room.childs}
                                      </div>
                                      <div className="tableValue col-md">
                                        {/* {!Array.isArray(room.board_basis) && room.board_basis.toUpperCase().split(" ").join("") === "ROOMONLY" ? "Room Only" : Array.isArray(room.board_basis) ? room.board_basis.join(", ") :"N/A"} */}
                                        {!Array.isArray(room.board_basis) &&
                                          "Room Only"}
                                        {Array.isArray(room.board_basis) &&
                                          room.board_basis.join(", ")}
                                      </div>
                                      <div
                                        className="tableValue wordBreak col-md"
                                      >
                                      {!state.enableEdit && (room.hotel_ref !== undefined && room.hotel_ref ? room.hotel_ref : "N/A")}
                                      {state.enableEdit &&
                                          <Form.Row>
                                              <Form.Group as={Col} controlId="htlrefno" className="d-flex align-items-center justify-content-between">
                                                  <Form.Control type="text" placeholder="Enter Ticket number" value={room.htlrefno} onChange={handleRoomChange(room, "ref")} />
                                              </Form.Group>
                                          </Form.Row>
                                      }
                                      </div>
                                      <div
                                        className="tableValue wordBreak col-md"
                                      >
                                        {room.supplier_ref
                                          ? room.supplier_ref
                                          : "N/A"}
                                      </div>
                                      <div className="tableValue col-md">
                                        {room.special_request
                                          ? room.special_request
                                          : "N/A "}
                                      </div>
                                      <div
                                        className={
                                          room.room_status.toUpperCase() ===
                                          "CONFIRMED"
                                            ? "tableValue active col-md"
                                            : "tableValue primary"
                                        }
                                      >
                                        {!state.enableEdit && room.room_status}
                                        
                                        {state.enableEdit &&
                                            <Form.Row>
                                                <Form.Group as={Col} className="customSelect editOne" controlId="stid" >
                                                    <Form.Control as="select" value={room.stid} onChange={handleRoomChange(room, "status")}>
                                                        {roomStatusList}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                        }
                                      </div>
                                      <div
                                        className="tableValue wordBreak col-md"
                                      >
                                        {state?.htlresponse?.usr_currency}{" "}
                                        {room.total_price * (room.base_usr_exrate ? room.base_usr_exrate : 1)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="horizontalDivider"></div>
                               
                                {room &&
                                  <>
                                    <HotelSummaryFare room={room} cur={state?.htlresponse?.usr_currency} index ={index}/>
                                  </>
                                }

                                 
                                <div className="horizontalDivider"></div>
                                <div>
                                  <div className="impWrap">
                                    {room.cancellation_policy &&
                                      room.cancellation_policy?.length !==
                                        0 && (
                                        <>
                                          <div level={4} className="impTitle">
                                            {"Cancellation Policy:"}
                                          </div>
                                          {room.cancellation_policy.map(
                                            (canp, index) => (
                                              <React.Fragment key={index}>
                                                <p className="impContent">
                                                  {canp}
                                                </p>
                                              </React.Fragment>
                                            )
                                          )}
                                        </>
                                      )}
                                    {room.cancellation_policy?.length === 0 && (
                                      <>
                                        <p className="impContent">
                                          {
                                            "Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws."
                                          }
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  <div className="impWrap">
                                    <>
                                      {room.important_info &&
                                        room.important_info !== undefined && (
                                          <>
                                          <div level={4} className="impTitle">
                                            {"Important Info:"}
                                          </div>
                                          <div className="toolTipCUstoom cnclInfo">
                                            {typeof room.important_info === "string" ? 
                                            (
                                              <p className="importantContent">
                                                {room.important_info}
                                              </p>                                              
                                            ):(
                                              <>
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      room.important_info
                                                        .instructions,
                                                  }}
                                                  className="importantContent"
                                                ></p>
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      room.important_info
                                                        .specialInstructions,
                                                  }}
                                                  className="importantContent"
                                                ></p>
                                              </>
                                            )}
                                          </div>
                                          </>
                                        )}
                                      {room.important_info !== undefined &&
                                        (room.important_info.instructions ===
                                          "" ||
                                          room.important_info
                                            .specialInstructions === "") && (
                                          <div className="toolTipCUstoom cnclInfo">
                                            <p className="importantContent">
                                              N/A
                                            </p>
                                          </div>
                                        )}
                                    </>
                                  </div>
                                  {state?.htlresponse?.note &&
                                    state?.htlresponse?.note !== "" &&
                                    state?.htlresponse?.note !== undefined && (
                                      <div className="impWrap">
                                        <div level={4} className="impTitle">
                                          {"Note:"}
                                        </div>
                                        <p className="impContent">
                                          {state?.htlresponse?.note}
                                        </p>
                                      </div>
                                    )}
                                </div>
                                <div className="horizontalDivider"></div>
                              </>
                            ))}
                        </div>
                        <div className="travellerDetails paymenDetal">
                          <div level={3} className="heading">
                            {"Payment Details"}
                          </div>
                          <div className="tableHeader row m-0">
                              <div className="tableValue col-md">
                                {"Mode of Payment"}
                              </div>
                              <div span={6} className="tableValue col-md">
                                {"Card Type"}
                              </div>
                              <div span={6} className="tableValue col-md">
                                {"Card Details"}
                              </div>
                              <div span={6} md={3} className="tableValue col-md">
                                {"Amount Paid"}
                              </div>
                            </div>
                          {state?.htlresponse?.pay && state?.htlresponse?.pay.length > 0 && 
                            state?.htlresponse?.pay?.map((pay, index) => (
                            <React.Fragment key={index}>
                              <div className="tableData row m-0">
                                <div className="tableValue col-md">
                                  {pay?.payment_mode} {(pay?.payment_mode == "Credit" || pay?.payment_mode == "Debit") && "Card"}
                                </div>
                                <div className="tableValue col-md">
                                  {pay?.card_type ? pay?.card_type+" Card" : "N/A"} 
                                </div>
                                {pay?.card_details ? (
                                  <div span={6} className="tableValue col-md">
                                    xxxxxxxxxxxx{pay?.card_details}
                                  </div>
                                ) : (
                                  <div span={6} className="tableValue col-md">
                                    N/A
                                  </div>
                                )}
                                <div span={6} md={3} className="tableValue col-md">
                                  {state?.htlresponse?.usr_currency}&nbsp;<FormateCurrency value={pay?.paid_amount} className="currencyAmount"/>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {!state?.loading &&
              (state?.htlresponse === undefined ||
                state?.htlresponse === "") && <div>No data found</div>}

            {state.enableCancel && (
              <div className="text-right buttonGrop border-0 pt-0">
                {state.isprocess ? (
                  <Button
                    size="xs"
                    variant="outline-primary"
                    className="progressIn"
                    type="submit"
                  >
                    {" "}
                    Process...
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      size="xs"
                      variant="outline-secondary"
                      className="mr-3"
                      type="submit"
                      onClick={backCancel}
                    >
                      {" "}
                      Back
                    </Button>
                    <Button
                      size="xs"
                      variant="primary"
                      type="submit"
                      onClick={callCancelPrice}
                    >
                      {" "}
                      Continue
                    </Button>
                  </>
                )}
              </div>
            )}

            {state.enableEdit &&
              <div className="text-right buttonGrop border-0 pt-0">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  type="submit"
                  className="mr-3"
                  onClick={cancelEdit}
                > Cancel</Button>
                <Button
                  size="xs"
                  variant="primary"
                  type="submit"
                  onClick={callEditBooking}
                > Update</Button>
              </div>
            }

            {/* edit hotel booking confirm popup */}
            {state.showEditpop &&
              <Modal
                show={state.showEditpop}
                onHide={oncloseEidt}
                className="importPNRMdl requiredFare"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <strong>Are you sure to update the change(s)? </strong>
                  </div>

                </Modal.Header>
                <Modal.Footer className="justify-content-center">
                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={oncloseEidt}
                    className="mr-2"
                    type="submit"
                  >No</Button>
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={processEditBookingFlow}
                    type="submit"
                  >Yes</Button>
                </Modal.Footer>
              </Modal>
            }
                 <Footer/>
          </Col>
     
        </Row>
      </Container>
    </>
  );
}
export default HotelSummaryPage;
