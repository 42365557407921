import React, { useEffect, useState, useReducer, useContext } from "react";
import { Context } from "../../../../App";
import { isArrayNotEmpty } from "../../../utilities/validators";
import { cancelFlightTicket } from "../operations";
import {Button, Row, Form, Alert, Modal}from 'react-bootstrap';
import Utilities from "../../../utilities/Utilities";
export const USER_AUTH_DATA = "userAuthData";


const _ = require("lodash");

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  cancelRadioType:"FC",
  radioFC: true,
  radioPC: false,
  radioCT: false,
};

//=====================================
// Reducer function for UserAction
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CancelFlightTrip({ cancelData, close, reloadQueueListAfterCancel }) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sectors, setSectors] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("FC");
  const [selectedItems, setSelectedItems] = useState([0]);
  const [selectedRow, setSelectedRow] = useState(1); // Initially selecting the first row
  const [remarksFC, setRemarksFC] = useState([]);
  const [remarksPC, setRemarksPC] = useState([]);
  const [remarksCT, setRemarksCT] = useState([]);
  const [email, setEmail] = useState("");
  const [cancelPopup, setCancelPopup] = useState("");


  useEffect(() => {
    constructSectors();
  }, []);

  const constructSectors = () => {
    // Initialize the result array
    var sec = [];
    var tt = parseInt(cancelData.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      cancelData.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var psngrs = cancelData.psngrs.filter((pax) => pax.tid === srcorign.tid);
        var route =
          srcorign.seg[0].da + "-" + srcorign.seg[srcorign.seg.length - 1].ar;
        var office_id = srcorign.office_id;
        var tid = srcorign.tid;
        var re = srcorign.re;
        // var st = "Confirmed";
        var st = cancelData.sty === "split" ? cancelData.psngrs.filter((pax) => pax.tid === srcorign.tid)[0].tktst : "Confirmed";  
        var pnr = cancelData.flt_b_data[0].pnr;
        var air_pnr = cancelData?.flt_d_data[0]?.airline_pnr;
        sec.push({
          src,
          des,
          leg: index + 1, // Increment leg number
          office_id,
          psngrs,
          tid,
          route,
          re,
          st,
          pnr,
          air_pnr,
        });
      });
      if (tt === 2 || cancelData.sty === "split") {
        cancelData.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var psngrs = cancelData.psngrs.filter((pax) => pax.tid === destorigin.tid);
          var route =
            destorigin.seg[0].da +
            "-" +
            destorigin.seg[destorigin.seg.length - 1].ar;
          var office_id = destorigin.office_id;
          var tid = destorigin.tid;
          var re = destorigin.re;
          // var st = "Confirmed";
          var st = cancelData.sty === "split" ? cancelData.psngrs.filter((pax) => pax.tid === destorigin.tid)[0].tktst : "Confirmed";  
          var pnr = cancelData?.flt_b_data.length === 1 ? cancelData?.flt_b_data[0]?.pnr : cancelData?.flt_b_data[1]?.pnr;
          var air_pnr = cancelData?.flt_d_data[cancelData?.flt_d_data.length - 1]?.airline_pnr;
          // Push the data into the "sec" array
          sec.push({
            src,
            des,
            leg: cancelData.origns[0].srcorigns.length + index + 1, // Increment leg number
            office_id,
            psngrs,
            tid,
            route,
            re,
            st,
            pnr,
            air_pnr,
          });
        });
      }
    }
    if (isArrayNotEmpty(sec)) {
      setSectors(sec);
    }

    let data = localStorage.getItem(USER_AUTH_DATA);
    if (data && data !== undefined && data !== null) {
      let itm = JSON.parse(data) 
      setEmail(itm?.email);
    }
  };

  // State to track selected checkboxes
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    // Initial checkbox of first passengers array
    0: [0],
  });

  // Function to handle changes in nested checkboxes
  const handleNestedChange = (parentIndex, nestedIndex) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      if (!updatedSelected[parentIndex]) {
        updatedSelected[parentIndex] = [];
      }

      const nestedIndexInArray =
        updatedSelected[parentIndex].indexOf(nestedIndex);

      if (nestedIndexInArray === -1) {
        updatedSelected[parentIndex].push(nestedIndex);
      } else {
        updatedSelected[parentIndex].splice(nestedIndexInArray, 1);
      }

      return updatedSelected;
    });
  };

  const handlePartialCancel = (index) => {
    setSelectedRow(index);
  };

  const handleRemarksChange = (type) => (e) => {
    if (type === "FC") {
      setRemarksFC([e.target.value]);
    }
    if (type === "PC") {
      setRemarksPC([e.target.value]);
    }
    if (type === "CT") {
      setRemarksCT([e.target.value]);
    }
  };

  const backToNormal = () => {
    close(false);
  };

  const submitCancelPopup = (type) =>{
      setCancelPopup(true);
  }

  const submitCancel = (type) => {
    let payload;
    let sectsCopy = _.cloneDeep(sectors);
    let sects = sectsCopy?.map((sec) => {
      delete sec.psngrs;
      delete sec.src;
      delete sec.des;
      delete sec.leg;
      delete sec.re;
      return sec;
    });
    let filterRefundable = sects?.filter((sec) => {
      return sec.re !== "Non-Refundable" && sec.st == "Confirmed";
    });
    let passengesNames = []
    cancelData.psngrs?.map((ticket) => {
      if(ticket.inf !== "" && ticket.inf !== undefined) {
        passengesNames.push(ticket.fn+"-"+ticket.ln);
        passengesNames.push(ticket.inf.fn+"-"+ticket.inf.ln);
      } else {
        passengesNames.push(ticket.fn+"-"+ticket.ln);
      }
    });
    try {
      if (type === "FC") {
        payload = {
          action: "cancellation",
          j_type: cancelData.tt == 2 ? "O/R" : "O",
          email_id: email,
          region_type: cancelData.region_type,
          action_type: type,
          sty: cancelData.sty,
          tid: cancelData.tid,
          trip_type: cancelData.tt,
          booking_id: cancelData.booking_id,
          cid: cancelData.cid,
          sup: cancelData.sup,
          tkts: passengesNames,
          psngr_det: cancelData.psngr_det,
          psngrs: cancelData.psngrs,
          Cancelledpsngrs: cancelData.psngrs,
          sectors: filterRefundable,
          remarks: remarksFC,
          origns: cancelData.origns,
          mappings: cancelData.mappings
        };
      }
      if (type === "PC") {
        if (cancelData.tt == 3) {
          payload = {
            action: "cancellation",
            j_type: "O",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            tkts: passengesNames,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cancelData.psngrs,
            sectors: [sects[selectedRow - 1]],
            remarks: remarksPC,
            origns: [{ srcorigns: [cancelData.origns[0].srcorigns[selectedRow - 1]] }],
            mappings: cancelData.mappings
          };
        } else {
          payload = {
            action: "cancellation",
            j_type: selectedRow == 1 ? "O" : "R",
            email_id: email,
            region_type: cancelData.region_type,
            action_type: type,
            sty: cancelData.sty,
            tid: cancelData.tid,
            trip_type: cancelData.tt,
            booking_id: cancelData.booking_id,
            cid: cancelData.cid,
            sup: cancelData.sup,
            tkts: passengesNames,
            psngr_det: cancelData.psngr_det,
            psngrs: cancelData.psngrs,
            Cancelledpsngrs: cancelData.sty !== "split" ? cancelData.psngrs : cancelData.psngrs.filter((psn) => psn.tid === (selectedRow == 1 ? cancelData.origns[0].srcorigns[0].tid : cancelData.origns[0].destorigins[0].tid)),
            sectors: [sects[selectedRow - 1]],
            remarks: remarksPC,
            origns: selectedRow == 1 ? [{ srcorigns: [cancelData.origns[0].srcorigns[0]] }] : [{ destorigns: [cancelData.origns[0].destorigins[0]] }],
            mappings: cancelData.mappings
          };
        }
      }
      // if (type === "CT") {
      //   let sectsCTCopy = _.cloneDeep(sectors);

      //   let secCombine = [
      //     selectedCheckboxes[0] !== undefined &&
      //       selectedCheckboxes[0]?.length !== 0 &&
      //       sectsCTCopy[0],
      //     selectedCheckboxes[1] !== undefined &&
      //       selectedCheckboxes[1]?.length !== 0 &&
      //       sectsCTCopy[1],
      //     selectedCheckboxes[2] !== undefined &&
      //       selectedCheckboxes[2]?.length !== 0 &&
      //       sectsCTCopy[2],
      //     selectedCheckboxes[3] !== undefined &&
      //       selectedCheckboxes[3]?.length !== 0 &&
      //       sectsCTCopy[3],
      //     selectedCheckboxes[4] !== undefined &&
      //       selectedCheckboxes[4]?.length !== 0 &&
      //       sectsCTCopy[4],
      //     selectedCheckboxes[5] !== undefined &&
      //       selectedCheckboxes[5]?.length !== 0 &&
      //       sectsCTCopy[5],
      //     selectedCheckboxes[6] !== undefined &&
      //       selectedCheckboxes[6]?.length !== 0 &&
      //       sectsCTCopy[6],
      //     selectedCheckboxes[7] !== undefined &&
      //       selectedCheckboxes[7]?.length !== 0 &&
      //       sectsCTCopy[7],
      //   ];

      //   var filterSectorCombine = secCombine.filter(
      //     (obj) => obj !== undefined && obj !== false
      //   );

      //   let sectorsCT = filterSectorCombine?.map((sec) => {
      //     delete sec.psngrs;
      //     delete sec.src;
      //     delete sec.des;
      //     delete sec.leg;
      //     delete sec.re;
      //     return sec;
      //   });

      //   let ticketsCT = cancelData.psngrs;
      //   const tkCTOne =
      //     selectedCheckboxes[0]?.length !== 0 &&
      //     selectedCheckboxes[0]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTTwo =
      //     selectedCheckboxes[1]?.length !== 0 &&
      //     selectedCheckboxes[1]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTThree =
      //     selectedCheckboxes[2]?.length !== 0 &&
      //     selectedCheckboxes[2]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTFour =
      //     selectedCheckboxes[3]?.length !== 0 &&
      //     selectedCheckboxes[3]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTFive =
      //     selectedCheckboxes[4]?.length !== 0 &&
      //     selectedCheckboxes[4]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTSix =
      //     selectedCheckboxes[5]?.length !== 0 &&
      //     selectedCheckboxes[5]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
      //   const tkCTSeven =
      //     selectedCheckboxes[6]?.length !== 0 &&
      //     selectedCheckboxes[6]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);

      //   const tkCT = [].concat(
      //     tkCTOne,
      //     tkCTTwo,
      //     tkCTThree,
      //     tkCTFour,
      //     tkCTFive,
      //     tkCTSix,
      //     tkCTSeven
      //   );
      //   const filtertkCT = tkCT.filter((t) => t !== undefined);

      //   let cnclticketsCT = cancelData.psngrs;
      //   const cncltkCTOne =
      //     selectedCheckboxes[0]?.length !== 0 &&
      //     selectedCheckboxes[0]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTTwo =
      //     selectedCheckboxes[1]?.length !== 0 &&
      //     selectedCheckboxes[1]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTThree =
      //     selectedCheckboxes[2]?.length !== 0 &&
      //     selectedCheckboxes[2]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTFour =
      //     selectedCheckboxes[3]?.length !== 0 &&
      //     selectedCheckboxes[3]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTFive =
      //     selectedCheckboxes[4]?.length !== 0 &&
      //     selectedCheckboxes[4]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTSix =
      //     selectedCheckboxes[5]?.length !== 0 &&
      //     selectedCheckboxes[5]?.map((t, i) => cnclticketsCT[t]);
      //   const cncltkCTSeven =
      //     selectedCheckboxes[6]?.length !== 0 &&
      //     selectedCheckboxes[6]?.map((t, i) => cnclticketsCT[t]);

      //   const cncltkCT = [].concat(
      //     cncltkCTOne,
      //     cncltkCTTwo,
      //     cncltkCTThree,
      //     cncltkCTFour,
      //     cncltkCTFive,
      //     cncltkCTSix,
      //     cncltkCTSeven
      //   );
      //   const cnclfiltertkCT = cncltkCT.filter((t) => t !== undefined);
        
      //   if(cancelData.tt == 3) {
      //     let cncloriginsCT = cancelData.origns[0];
      //     const cnclsrcoriginOne =
      //       selectedCheckboxes[0]?.length !== 0 && cncloriginsCT.srcorigns[0];
      //     const cnclsrcoriginTwo =
      //       selectedCheckboxes[1]?.length !== 0 && cncloriginsCT.srcorigns[1];
      //     const cnclsrcoriginThree =
      //       selectedCheckboxes[2]?.length !== 0 && cncloriginsCT.srcorigns[2];
      //     const cnclsrcoriginFour =
      //       selectedCheckboxes[3]?.length !== 0 && cncloriginsCT.srcorigns[3];
      //     const cnclsrcoriginFive =
      //       selectedCheckboxes[4]?.length !== 0 && cncloriginsCT.srcorigns[4];
      //     const cnclsrcoriginSix =
      //       selectedCheckboxes[5]?.length !== 0 && cncloriginsCT.srcorigns[5];

      //     const cnclorgCT = [].concat(
      //       cnclsrcoriginOne,
      //       cnclsrcoriginTwo,
      //       cnclsrcoriginThree,
      //       cnclsrcoriginFour,
      //       cnclsrcoriginFive,
      //       cnclsrcoriginSix
      //     );

      //     const cnclfilterorignsCT = cnclorgCT.filter((t) => t !== undefined);
          
      //     payload = {
      //       action: "cancellation",
      //       j_type: "O",
      //       email_id: email,
      //       region_type: cancelData.region_type,
      //       action_type: type,
      //       sty: cancelData.sty,
      //       tid: cancelData.tid,
      //       trip_type: cancelData.tt,
      //       booking_id: cancelData.booking_id,
      //       cid: cancelData.cid,
      //       sup: cancelData.sup,
      //       // tkts: (filtertkCT[0] === null || filtertkCT[0] === "" || filtertkCT[0] === undefined) ? [] : filtertkCT,
      //       tkts: filtertkCT,
      //       psngr_det: cancelData.psngr_det,
      //       psngrs: cancelData.psngrs,
      //       Cancelledpsngrs: cnclfiltertkCT,
      //       sectors: sectorsCT,
      //       remarks: remarksCT,
      //       origns: [{srcorigns: cnclfilterorignsCT}],
      //       mappings: cancelData.mappings
      //     };
      //   } else {
      //     let cncloriginsCT = cancelData.origns[0];
      //     const cnclsrcorigin =
      //     selectedCheckboxes[0] === undefined ? undefined : (selectedCheckboxes[0]?.length !== 0 && cncloriginsCT.srcorigns);
      //     const cncldestorigin =
      //     selectedCheckboxes[1] === undefined ? undefined : (selectedCheckboxes[1]?.length !== 0 && cncloriginsCT.destorigins);

      //     const cnclsrcorgCT = [].concat(
      //       cnclsrcorigin
      //     );

      //     const cncldestorgCT = [].concat(
      //       cncldestorigin
      //     );

      //     const cnclfiltersrcorignsCT = cnclsrcorgCT.filter((t) => t !== undefined);
      //     const cnclfilterdestorignsCT = cncldestorgCT.filter((t) => t !== undefined);

      //     payload = {
      //       action: "cancellation",
      //       j_type: selectedCheckboxes[0]?.length !== 0 && selectedCheckboxes[1]?.length !== 0 ? "O/R" : selectedCheckboxes[0]?.length !== 0  ? "O" : selectedCheckboxes[1]?.length !== 0 && "R",
      //       email_id: email,
      //       region_type: cancelData.region_type,
      //       action_type: type,
      //       sty: cancelData.sty,
      //       tid: cancelData.tid,
      //       trip_type: cancelData.tt,
      //       booking_id: cancelData.booking_id,
      //       cid: cancelData.cid,
      //       sup: cancelData.sup,
      //       tkts: (filtertkCT[0] === null || filtertkCT[0] === "") ? [] : filtertkCT,
      //       psngr_det: cancelData.psngr_det,
      //       psngrs: cancelData.psngrs,
      //       Cancelledpsngrs: cnclfiltertkCT,
      //       sectors: sectorsCT,
      //       remarks: remarksCT,
      //       origns: [cnclfiltersrcorignsCT.length !==0 && {srcorigns: cnclfiltersrcorignsCT}, cnclfilterdestorignsCT.length !==0 && {destorigns: cnclfilterdestorignsCT}],
      //       mappings: cancelData.mappings
      //     };
      //   }
      // }
      //Call Cancel Ticket API
      let tokenKey = Utilities.generateAPIToken(payload);
      cancelFlightTicket(payload,tokenKey).then((response) => {
        console.log("Cancel ticket response " + JSON.stringify(response?.data));
        if (response.suc) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response.res,
          });
          dispatch({ type: "notiVarient", payload: "success" });
          reloadQueueListAfterCancel({product:"Flight"})
          hidemessage();
          setTimeout(() => {
            backToNormal();
          }, 3000);
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: response.err,
          });
          dispatch({ type: "notiVarient", payload: "error" });
          hidemessage();
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };


  const handleRadioChange = (event) => {
    if (event.target.id === "FC") {
      dispatch({ type: "cancelRadioType", payload: "FC" });
      dispatch({ type: "radioFC", payload: true });
      dispatch({ type: "radioPC", payload: false });
      dispatch({ type: "radioCT", payload: false });
      setSelectedRadio("FC");
    } else if (event.target.id === "PC") {
      dispatch({ type: "cancelRadioType", payload: "PC" });
      dispatch({ type: "radioFC", payload: false });
      dispatch({ type: "radioPC", payload: true });
      dispatch({ type: "radioCT", payload: false });
      setSelectedRadio("PC");
    }else if (event.target.id === "CT") {
      dispatch({ type: "cancelRadioType", payload: "CT" });
      dispatch({ type: "radioFC", payload: false });
      dispatch({ type: "radioPC", payload: false });
      dispatch({ type: "radioCT", payload: true });
      setSelectedRadio("CT");
    }

  };

  const handleCloseCancel = () => {
    setCancelPopup(false)
  }
  return (
    <>
      <div class="horizontalDivider"></div>
      <div className="fullYcancellation">
        
        {state.notiMessageShow &&
          <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }

        <Form.Row className="m-0">
          <div className="d-flex align-items-center ReIssuep">
          <label>
              <input
              type="radio"
              id="FC"
              checked={state.radioFC}
              value={state.cancelRadioType}
              onChange={handleRadioChange}
              custom
              name="classes"
            />
            Full Cancellation
            </label>
            {cancelData && sectors && sectors.length > 1 && (
              <label>
              <input
                type="radio"
                id="PC"
                checked={state.radioPC}
                value={state.cancelRadioType}
                onChange={handleRadioChange}
                custom
                name="classes"
              />
              Partial Cancellation
              </label>
            )}
            {/* {cancelData &&
              sectors &&
              sectors.length === 1 &&
              sectors[0].psngrs.length > 1 && (
                <Form.Check
                  type="radio"
                  label="Cancellation by Traveler"
                  id="CT"
                  checked={state.radioCT}
                  value={state.cancelRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
              )}

            {cancelData &&
              sectors &&
              sectors.length === 2 &&
              (sectors[0].psngrs.length > 1 || sectors[1].psngrs.length > 1) && (
                <Form.Check
                  type="radio"
                  label="Cancellation by Traveler"
                  id="CT"
                  checked={state.radioCT}
                  value={state.cancelRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
              )}
            {cancelData &&
              sectors &&
              sectors.length === 3 &&
              (sectors[0].psngrs.length > 1 ||
                sectors[1].psngrs.length > 1 ||
                sectors[2].psngrs.length > 1) && (
                <Form.Check
                  type="radio"
                  label="Cancellation by Traveler"
                  id="CT"
                  checked={state.radioCT}
                  value={state.cancelRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
              )}
            {cancelData &&
              sectors &&
              sectors.length === 4 &&
              (sectors[0].psngrs.length > 1 ||
                sectors[1].psngrs.length > 1 ||
                sectors[2].psngrs.length > 1 ||
                sectors[3].psngrs.length > 1) && (
                <Form.Check
                  type="radio"
                  label="Cancellation by Traveler"
                  id="CT"
                  checked={state.radioCT}
                  value={state.cancelRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
              )} */}
          </div>
        </Form.Row>

        {selectedRadio && selectedRadio === "FC" && (
          <div>
            <div className="tableHeader row m-0">
              <div className="tableValue col-md-1">

              </div>
              <div className="tableValue col-md-2">
                {"Sectors"}
              </div>
              <div className="tableValue col-md-3">
                {"Travellers"}
              </div>
              <div className="tableValue col-md-2">
                {"Traveller Type"}
              </div>
              <div className="tableValue col-md-2">
                {"PNR"}
              </div>
              <div className="tableValue col-md-2">
                {"Status"}
              </div>
            </div>
            <div>
              {cancelData &&
                sectors &&
                sectors.map((sec, idx) => (
                  <div key={idx} className="tableData alignPaxDetails row m-0">
                    <div className="tableValue col-md-1">
                      <div className="ReIssuep detailsS">
                      <input
                        type="radio"
                        checked={true}
                        disabled={(sec.re === "Non-Refundable") || (sec.psngrs[0]?.tktst?.toUpperCase() !== "CONFIRMED" && sec.psngrs[1]?.tktst?.toUpperCase() !== "CONFIRMED") ? true : false}
                      />
                      </div>
                    </div>
                    <div className="tableValue col-md-2">
                      {sec.src} - {sec.des}
                    </div>
                    <div className="tableValue col-md-3">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div className="paxFullName">{pax.fn} {pax.ln}</div>

                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                              <div className="paxFullName">{pax.inf.fn} {pax.inf.ln}</div>

                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="tableValue col-md-2">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.ty}</div>

                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                              <div>{pax.inf.ty}</div>

                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="tableValue col-md-2">
                      {sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}
                    </div>
                    <div className="tableValue active col-md-2">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.tktst}</div>
                          {pax.inf!=="" && pax.inf!==undefined &&   
                            <>
                            {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
                            </>
                          }                     
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <Form.Group className="mb-3">
              <Form.Control 
                as="textarea" 
                rows={3} 
                placeholder={"Remarks"}
                onChange={handleRemarksChange(selectedRadio)}
                value={remarksFC[remarksFC.length - 1]}
                maxLength={100}
              />
            </Form.Group>
            <p className="noteWrap"> <span className="notetitle">{"Note:"}</span>  {"One of our executive will connect with you once your request is submitted"} </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {"Cancel"}
              </Button>
              {cancelData.tt == 1 && cancelData.sty !== "split" ? (
              <Button
                type="primary"
                onClick={() => submitCancelPopup(selectedRadio)}
                disabled={
                  (sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0 || sectors.filter((sec) => sec.re !== "Non-Refundable")
                    .length === 0)
                    ? true
                    : false
                }
                className="cntBtn cancelBtn"
              >
                {"Request Cancellation"}
              </Button>
              ) : (
                <>
                {cancelData.tt == 2 || cancelData.sty === "split" && (
                <Button
                type="primary"
                onClick={() => submitCancelPopup(selectedRadio)}
                disabled={
                  ((sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0 && sectors[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0) || sectors.filter((sec) => sec.re !== "Non-Refundable")
                    .length === 0)
                    ? true
                    : false
                }
                className="cntBtn cancelBtn"
              >
                {"Request Cancellation"}
              </Button>
                )}
                </>
            )}
            </div>
          </div>
        )}
        {selectedRadio && selectedRadio === "PC" && (
          <div>
            <div className="tableHeader PartialCancellation row m-0">
              <div className="tableValue col-md-1">

              </div>
              <div className="tableValue col-md-2">
                {"Sectors"}
              </div>
              <div className="tableValue col-md-3">
                {"Travellers"}
              </div>
              <div className="tableValue col-md-2">
                {"Traveller Type"}
              </div>
              <div className="tableValue col-md-2">
                {"PNR"}
              </div>
              <div className="tableValue col-md-2">
                {"Status"}
              </div>
            </div>
            <div className="partialCancellationPax">
            {cancelData && sectors && (
              <>
                {sectors.map((sec, idx) => (
                  <Row key={idx}>
                  
                    <div className="tableData partialAlignPaxDetails row">

                    <div className="tableValue col-md-1">
                      <Form.Check
                        type="radio"
                        checked={idx + 1 === selectedRow}
                        onChange={() => handlePartialCancel(idx + 1)}
                        disabled={(sec.re === "Non-Refundable") || (cancelData.psngrs[idx]?.tktst?.toUpperCase() !== "CONFIRMED") ? true : false}
                      />
                    </div>

                         <div className="tableValue col-md-2">
                        {sec.src} - {sec.des}
                      </div>
                      <div className="tableValue col-md-3">
                        {sec.psngrs.map((pax, index) => (
                          <React.Fragment key={index}>
                            <div className="paxFullName">{pax.fn} {pax.ln}</div>
                            
                            {pax.inf !== undefined && pax.inf !== "" && (
                              <>
                                <div className="paxFullName">{pax.inf.fn} {pax.inf.ln}</div>
                              </>
                            )}
                            
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="tableValue col-md-2">
                        {sec.psngrs.map((pax, index) => (
                          <React.Fragment key={index}>
                            <div>{pax.ty}</div>
                            
                            {pax.inf !== undefined && pax.inf !== "" && (
                              <><div>{pax.inf.ty}</div></>
                            )}
                            
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="tableValue col-md-2">
                        {sec.pnr}
                      </div>                          
                      <div className="tableValue active col-md-2">
                        {sec.psngrs.map((pax, index) => (
                          <React.Fragment key={index}>
                            <div>{pax.tktst}</div>
                            {pax.inf!=="" && pax.inf!==undefined &&   
                              <>
                              {pax.inf.tktst?pax.inf.tktst:pax.tktst} 
                              </>
                            }     
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </Row>
                ))}
              </>
            )}
            </div>
            <Form.Group className="mb-3">
              <Form.Control 
                as="textarea" 
                rows={3} 
                placeholder={"Remarks"}
                onChange={handleRemarksChange(selectedRadio)}
                value={remarksPC[remarksFC.length - 1]}
                maxLength={100}
              />
            </Form.Group>
            <p className="noteWrap"> <span className="notetitle">{"Note:"}</span>  {"One of our executive will connect with you once your request is submitted"} </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {"Cancel"}
              </Button>
              {cancelData.tt == 1 && cancelData.sty !== "split" ? (
              <Button
                type="primary"
                onClick={() => submitCancelPopup(selectedRadio)}
                disabled={
                  (sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0 || sectors.filter((sec) => sec.re !== "Non-Refundable")
                    .length === 0)
                    ? true
                    : false
                }
                className="cntBtn"
              >
                {"Request Cancellation"}
              </Button>
              ) : (
                <>
                {cancelData.tt == 2 || cancelData.sty === "split" && (
                <Button
                  type="primary"
                  onClick={() => submitCancelPopup(selectedRadio)}
                  disabled={
                    ((sectors[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                      .length === 0 && sectors[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                      .length === 0) || sectors.filter((sec) => sec.re !== "Non-Refundable")
                      .length === 0)
                      ? true
                      : false
                  }
                  className="cntBtn"
                >
                  {"Request Cancellation"}
                </Button>
                )}
                </>
              )}
            </div>
          </div>
        )}
       
        <Modal show={cancelPopup} onHide={handleCloseCancel} className='addtoQue'>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure want to process</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {
              setCancelPopup(false);
              submitCancel(selectedRadio)
            }}>
              Yes
            </Button>
            <Button variant="primary" onClick={() => {
              setCancelPopup(false);
            }}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default CancelFlightTrip;
