import React, { useState, useEffect } from "react";
import DateUtils from "../common/DateUtils";
import { isNotNull } from "../utilities/validators";
import { Link } from 'react-router-dom';
import CheckoutPay from "./CheckoutPay";
import { validatePaymentUrl } from "./operations";
import LoaderN from "../../components/common/loaders/LoaderN";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/images/cntlogo.png"
import hand from "../../assets/images/hand.png"
import clock from "../../assets/images/clock.png"
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
const PAY_SUPPORT_NUM = process.env.REACT_APP_PAY_SUPPORT_NUM;
const PAY_SUPPORT_EMAIL = process.env.REACT_APP_PAY_SUPPORT_EMAIL;


/**
 * @author Praveen Varma
 * @date 04-12-2023
 * @description Payment Gatwway
 * @param {*} props 
 * @returns 
 */
function PayAuth(props) {
    const [showCheckout, setShowCheckout] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const [payRes, setPayRes] = useState("");
    const [error, setError] = useState("");
    const [paySuccessPopup, setPaySuccessPopup] = useState(false);
    const [paymentExpired, setPaymentExpired] = useState(false);
    const [paymentInvalidLink, setPaymentInvalidLink] = useState(false);
    const [paymentAlreadyDone, setPaymentAlreadyDone] = useState(false);
    const [payFailedErrMsg, setPayFailedErrMsg] = useState("");
    const [payRefId, setPayRefId] = useState("");
    const [returnUrl, setReturnUrl] = useState("");

    useEffect(() => {
        console.log("Entered into payment auth check ................")
        let search = window.location.search;
        setReturnUrl(window.location.search);
        let params = new URLSearchParams(search);
        if (params) {
            let id = params.get('id');
            console.log("Payment auth id: ", id);
            if (isNotNull(id)) {
                id = id.trim();
                id = id.replace(/\s+/g, '+');
                validatePaymentUrlCheck(id);
            } else {
                setPaymentInvalidLink(true);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            setError("Invalid request");
        }
    }, [])

    const validatePaymentUrlCheck = async (payId) => {
        let payload = {
            url: payId,
            dt: await DateUtils.getNewDate()
        }
        validatePaymentUrl(payload).then((response) => {
            if (response && response.suc) {
                if(response?.res?.is_pay_made){
                    setPaymentAlreadyDone(true);
                    setIsLoading(false);
                    setShowCheckout(false);
                }else if(response?.res?.is_expired){
                    setPaymentExpired(true);
                    setIsLoading(false);
                    setShowCheckout(false);
                }else{
                    setPayRes(response.res);
                    setIsLoading(false);
                    setShowCheckout(true);
                }
            } else {
                setPayRes("");
                setPaymentInvalidLink(true);
                setIsLoading(false);
                setShowCheckout(false);
            }
        }).catch(function (error) {
            setPayRes("");
            setShowCheckout(false);
            setIsLoading(false)
            setError("Invalid link")
            console.error(
                "Exception occured in validatePaymentUrl function---" + error
            );
        });
    }

    const redirectParent = async (data) => {
        setPayRefId("");
        setPaySuccessPopup(false);
        if (data?.status === "success") {
            setPayRefId(data?.payId)
            setIsLoading(false);
            setPaySuccessPopup(true);
        } else if (data?.status === "failed") {
            setPayFailedErrMsg("Payment failed due to some technical error, please try again ...")
            setIsLoading(false);
        } else if (data?.status === "error") {
            setPayFailedErrMsg("Payment error due to some technical error, please try again ...")
            setIsLoading(false);
        }

    }

    const handleClose = () => {
        setPaySuccessPopup(false);
    };

    return (
        <div>
            {loading &&
                <LoaderN
                    title={"Please Wait...!"}
                    subtitle={"Do not refresh the page while we securely process your payment."}
                />
            }
            <div className="paymentHeader">
                <div className="logoSec">
                    <Link to={WEBSITE_URL}><img src={logo} /></Link>
                </div>
            </div>
            {paymentInvalidLink &&
                <div className="BookingRefPay stepOne">
                    <span><img src={hand}/></span>
                    <h3>Invalid payment link</h3>
                    <h4>Please contact our customer service at <span className="phnEmail"><a href={`tel:${PAY_SUPPORT_NUM}`}>{PAY_SUPPORT_NUM}</a></span><br/>
                    (or) send us an email at <span className="phnEmail"><a href={`mailto:${PAY_SUPPORT_EMAIL}`}>{PAY_SUPPORT_EMAIL}</a></span></h4>
                </div>
            }
            {paymentExpired &&
                <div className="BookingRefPay stepOne">
                    <span><img src={hand}/></span>
                    <h3>Your payment link has expired</h3>
                    <h4>Please contact our customer service at <span className="phnEmail"><a href={`tel:${PAY_SUPPORT_NUM}`}>{PAY_SUPPORT_NUM}</a></span><br/>
                    (or) send us an email at  <span className="phnEmail"><a href={`mailto:${PAY_SUPPORT_EMAIL}`}>{PAY_SUPPORT_EMAIL}</a></span></h4>
                </div>
            }
            {paymentAlreadyDone &&
                <div className="BookingRefPay stepOne">
                      <span><img src={clock}/></span>
                    <h3>Your payment has already been made</h3>
                    <h4>For any queries, please contact our customer service at <span className="phnEmail"><a href={`tel:${PAY_SUPPORT_NUM}`}>{PAY_SUPPORT_NUM}</a></span><br/>
                    (or) send us an email at  <span className="phnEmail"><a href={`mailto:${PAY_SUPPORT_EMAIL}`}>{PAY_SUPPORT_EMAIL}</a></span></h4>
                </div>
            }
            {!loading && showCheckout && payRes &&
                <>
                    <div className="BookingRefPay">
                        <div className="d-flex">
                            {payRes && payRes.booking_id &&
                                <div><span>Booking Ref :</span><span>{payRes.booking_id}</span></div>
                            }
                            {payRes && payRes.payment_unique_id &&
                                <div><span>Pay Unique Id :</span><span>{payRes.payment_unique_id}</span></div>
                            }
                            {payRes && payRes.booking_type &&
                                <div><span>Booking Type :</span><span>{payRes.booking_type}</span></div>
                            }
                            {payRes && payRes.product &&
                                <div><span>Product :</span><span>{payRes.product}</span></div>
                            }
                        </div>

                    </div>
                    <CheckoutPay payment={payRes} redirectParent={redirectParent} payErrMsg ={payFailedErrMsg} returnUrl={returnUrl}/>
                </>
            }
            <Modal show={paySuccessPopup} onHide={handleClose} className="addtoQue payMentsuc" backdrop="static">
                <Modal.Body>
                    <Modal.Title><span className="ezyIcon icon-newtick" ></span>Payment successful</Modal.Title>
                    <span className="thankYou"><span>Thank you!,</span> Your payment has been received</span>
                    <div className="BookingRefPay">
                        <div className="d-flex">
                            <div><span>Transaction ID:</span><span>{payRefId}</span></div>
                        </div>
                    </div>
                    <Link to={WEBSITE_URL} className="btn-primary">Go to Home page</Link>
                </Modal.Body>
            </Modal>
        </div>
    )

}
export default PayAuth;