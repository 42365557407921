import log from "loglevel";
import React, { useReducer, useState } from 'react';
import { Col, Form,Alert } from "react-bootstrap";
import ManageBookingService from "../../../services/ManageBookingService";
import Select from "react-select";
import Button from '../../common/Button';
import { emailValidation, mobileNumberValidation } from '../../utilities/FormValidations';

let stdCodes = require("../../masterData/CountrySTDCodes.json");
/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
  id: 0, profid: 0, tl: "", fn: "", mn: "", ln: "", phc: "", phn: "", em: "",uniq_id:"", data: "", profiles: [], profile: "", selProfile: [], proEnable: false, selTitle: [], selPhc: [], enableImport: false, flowType: "",usr_email: "", usr_uniq_id: "", user_mobile: "", user_mobile_code: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ProfileDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoad, setIsLoad] = useState(false);
  
  const titles = [
    { label: "Mr", value: "Mr" },
    { label: "Miss", value: "Miss" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Shka", value: "Shka" },
    { label: "Shk", value: "Shk" },
  ];

  const validateProfile=()=>{
    if(state.usr_email!=="" && !emailValidation(state.usr_email)){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid email for Profile' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if (state.user_mobile!=="" && !mobileNumberValidation(state.user_mobile)) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid phone number for Profile' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if (state.usr_email === "" && state.user_mobile === "" && state.usr_uniq_id ==="") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter atleast one field and proceed' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    return true;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  

  const convertProfile = (obj) => {
    dispatch({ type: 'tl', payload: obj.tl })
    dispatch({ type: 'fn', payload: obj.fn })
    dispatch({ type: 'ln', payload: obj.ln })
    dispatch({ type: 'em', payload: obj.email })
    dispatch({ type: 'phc', payload: obj.ph_code!=="" ? obj.ph_code: "N/A"})
    dispatch({ type: 'phn', payload: obj.mobile!=="" ? obj.mobile: "N/A"})
    dispatch({ type: 'profid', payload: obj.id })
    dispatch({ type: 'selTitle', payload: [{ "value": obj.tl, "label": obj.tl }] })
    dispatch({ type: 'uniq_id', payload: obj.uniq_id })
    
    const payload = {
      "tl": obj.tl,
      "fn": obj.fn,
      "ln": obj.ln,
      "mn": obj.mn,
      "phc": obj.ph_code,
      "phn": obj.mobile,
      "em": obj.email,
      "profid": obj.id,
      "uniq_id": obj.uniq_id
    }
    props.sendProfile(payload);

  }

  const handleChangePnr = (evt) => {
    const { id, value } = evt.target;
    dispatch({ type: id, payload: value });
  }

  const handleChangePhc = (e) => {
    if(e!==undefined) {
      if(e.value !== undefined) {
        dispatch({ type: 'user_mobile_code', payload: e.value })
      }
    }
  }

  const fetchProfile=()=>{
    if(validateProfile()){
      const req={
        "email":state.usr_email,
        "ph_code":state.user_mobile_code,
        "mobile":state.user_mobile,
        "uniq_id":state.usr_uniq_id
      }
      setIsLoad(true)
      ManageBookingService.fetchUserProfile(req).then((res) => {
        if (res.data.suc){
          convertProfile(res.data.res);
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "Profile details fetched successfully."})
          dispatch({ type: 'notiVarient', payload: 'success' })
          hidemessage();
          setIsLoad(false)
        }else{
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "Unable to fetch the Profile details."})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          setIsLoad(false)
        }
      });
    }
  }

  return (
    <>
      <div className="boxLayout">
      {state.notiMessageShow &&
               <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
               }
      <Form.Row className="fetchPdesect">
              <Form.Group as={Col} xs={3} controlId="usr_email" className="selectplugin">
                <Form.Label>Email Id</Form.Label>
                <Form.Control type="text" placeholder="Enter Email Id" value={state.usr_email}
                  autoComplete="off" onChange={handleChangePnr} />
                  <span class="noteQpnr"><span>Note :</span> Enter email id to fetch the profile details </span>
              </Form.Group>

              <Form.Group as={Col} xs={3} controlId="user_mobile_code" className="selectplugin scrollHandle">
              <Form.Label>Phone Code</Form.Label>
              <Select
                options={stdCodes}
                value={stdCodes.find((option) => option.value === state.user_mobile_code)}
                onChange={handleChangePhc}
                classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
              />            
            </Form.Group>
              <Form.Group as={Col} xs={3} controlId="user_mobile" className="selectplugin">
              <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" placeholder="Enter Mobile Number" value={state.user_mobile}
                  autoComplete="off" onChange={handleChangePnr} />
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="usr_uniq_id" className="selectplugin">
                <Form.Label>UID Number</Form.Label>
                <Form.Control type="text" placeholder="Enter UID Number" value={state.usr_uniq_id}
                  autoComplete="off" onChange={handleChangePnr} />
              </Form.Group>
              <div className="text-right d-flex fetchPdetails">
                {!isLoad?<Button
                  size="xs"
                  variant="secondary"
                  onClick={fetchProfile}
                  disabled={isLoad}
                  type="submit"
                >Fetch Profile</Button>:
             
                <div class="progress progress-striped active mt-0">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Fetching..</span>
                </div>
                </div>
           
                }
              </div>
            </Form.Row>
            <Form.Row>
              <Form.Group  as={Col} xs={3} className="selectplugin" controlId="tl">
              <Form.Label>Title</Form.Label>
                <Select
                  options={titles}
                  searchable={false}
                  value={state.selTitle}
                  disabled={true}
                  //onChange={handleTitle}
                  className="disaBled customDisable"
                />
              </Form.Group>
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="fn">
              <Form.Label>First Name</Form.Label>
                <Form.Control type="text" disabled={true} placeholder="First Name" value={state.fn} />
              </Form.Group>
              <Form.Group className="selectplugin" as={Col} xs={3}controlId="ln">
              <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" disabled={true} placeholder="Last Name" value={state.ln}/>
              </Form.Group>
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="phn">
              <Form.Label>Phone Code</Form.Label>
                <Form.Control type="text" disabled={true} placeholder="Phone Code" value={state.phc} /> 
                         
              </Form.Group>
              
            </Form.Row>
            <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="phn">
              <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="text" disabled={true} placeholder="Mobile Number" value={state.phn} />              
              </Form.Group>
        
             <Form.Group className="selectplugin pb-0 mb-0" as={Col} xs={3} controlId="em">
              <Form.Label>Email ID</Form.Label>
                <Form.Control type="em" disabled={true} placeholder="Email ID"  value={state.em}  />
              </Form.Group>
              <Form.Group className="selectplugin pb-0 mb-0" as={Col} xs={3} controlId="uniq_id">
              <Form.Label>UID Number </Form.Label>
                <Form.Control type="text" disabled={true} placeholder="UID Number" value={state.uniq_id} />
              </Form.Group>
            </Form.Row>
         
      </div>
    </>
  )
}
export default ProfileDetails