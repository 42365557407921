import React, { useContext, useEffect, useReducer, useState } from "react";
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import log from "loglevel";
import AirportMemo from "../../common/airlines/AirportNames";
import DateUtils from "../../common/DateUtils";
import { Context } from "../../../App";
import Utilities from "../../utilities/Utilities";
import AirlinesMemo from "../../common/airlines/AirlinesMemo";
import { isNotNull } from "../../utilities/validators";
// import LayoverDetails from '../../bookingFlows/flight/common/LayoverDetails';
// import FlightSegmentImportDetails from '../../bookingFlows/flight/common/FlightSegmentImportDetails';
// import ManualPaymentForm from "../../dashBoard/ManualPaymentForm";
// import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
import Button from "../../common/Button";
import ManageBookingService from "../../../services/ManageBookingService";
import PaymentDetails from "../../dashboard/manageBookings/PaymentDetails";
const _ = require("lodash");
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
/**
 * @description:This function will load the Import PNR details
 * @author: Ubedullah
 * @param {*}
 * @function PNRImport
 * @date : 24-04-2024
 */

/**
 * Initial State Declaration
 */
const initialState = {
  all: false,
  selSegment: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  priceResp: "",
  showSegments: [],
  adt: 0,
  chd: 0,
  inf: 0,
  grandTotal: 0,
  finalisd_import_seg: [],
  fareobj: "",
  grand_total: 0,
  adts: 1,
  chds: 0,
  inf: 0,
  adtbase: "",
  adttaxes: "",
  adtmarkup: "",
  adtsf: "",
  adttotal: 0,
  adtbaseta: "",
  adttaxesta: "",
  adtmarkupta: "",
  adtsfta: "",
  adtbagta: "",
  adtmealsta: "",
  adttotalta: 0,
  adttotalamt: 0,
  adttotalamtta: 0,
  adtbasesta: "",
  adttaxessta: "",
  adtmarkupsta: "",
  adtsfsta: "",
  adtbagsta: "",
  adtmealssta: "",
  adttotalsta: 0,
  adttotalamtsta: 0,
  infbase: "",
  inftaxes: "",
  infmarkup: "",
  infsf: "",
  inftotal: 0,
  infbaseta: "",
  inftaxesta: "",
  infmarkupta: "",
  infsfta: "",
  infbagta: "",
  infmealsta: "",
  inftotalta: 0,
  inftotalamt: 0,
  infs: 0,
  inftotalamtta: 0,
  infbasesta: "",
  inftaxessta: "",
  infmarkupsta: "",
  infsfsta: "",
  infbagsta: "",
  infmealssta: "",
  inftotalsta: 0,
  inftotalamtsta: 0,
  chdbase: "",
  chdtaxes: "",
  chdmarkup: "",
  chdsf: "",
  chdtotal: 0,
  chdbaseta: "",
  chdtaxesta: "",
  chdmarkupta: "",
  chdsfta: "",
  chdbagta: "",
  chdmealsta: "",
  chdtotalta: 0,
  chdtotalamt: 0,
  chds: 0,
  chdtotalamtta: 0,
  chdbasesta: "",
  chdtaxessta: "",
  chdmarkupsta: "",
  chdsfsta: "",
  chdbagsta: "",
  chdmealssta: "",
  chdtotalsta: 0,
  chdtotalamtsta: 0,
  nat: [],
  sa: "",
  sp: "",
  mp: "",
  ffnum: "",
  air: "",
  jtype: "",
  supplier: "0",
  ttype: "",
  adtpax: [],
  infpax: [],
  chdpax: [],
  adtPenalty: "",
  adtResVal: 0,
  chdPenalty: "",
  chdResVal: 0,
  infPenalty: "",
  infResidualVal: 0,
  payData: "",
  disable: false,
  enablePayment: false,
  isItineraryLoading: false,
  adtdis: 0,
  adtdista: 0,
  chddis: 0,
  chddista: 0,
  infdis: 0,
  infdista: 0,
  gst_type: "",
  cgst: 0,
  sgst: 0,
  igst: 0,
  adt_attgst: 0,
  chd_attgst: 0,
  inf_attgst: 0,
  adt_tagst: 0,
  chd_tagst: 0,
  inf_tagst: 0,
  att_adtcgst: 0,
  att_adtsgst: 0,
  att_adtigst: 0,
  att_adtRetcgst: 0,
  att_adtRetsgst: 0,
  att_adtRetigst: 0,
  att_chdcgst: 0,
  att_chdsgst: 0,
  att_chdigst: 0,
  ta_adtcgst: 0,
  ta_adtsgst: 0,
  ta_adtigst: 0,
  ta_chdcgst: 0,
  ta_chdsgst: 0,
  ta_chdigst: 0,
  ta_infcgst: 0,
  ta_infsgst: 0,
  ta_infigst: 0,
  payment_details: "",
  pay_uniq_id: "",
  cur: "QAR",
  remarks: null,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "upprof":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ShowManualPnrData(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const handleClose = () => setShow(false);
  const [response, setResponse] = useState(props.pnrResponse.res);
  const [mappings, setMappings] = useState(props.pnrResponse.res.mappings);
  const [type, setType] = useState("");
  const [ptype, setPType] = useState("");
  const [typeOne, setTypeOne] = useState("");
  const [ptypeOne, setPTypeOne] = useState("");
  const [typeTwo, setTypeTwo] = useState("");
  const [ptypeTwo, setPTypeTwo] = useState("");
  const [enableAttPrice, setEnableAttPrice] = useState(false);
  const [enableTAPrice, setEnableTAPrice] = useState(false);
  const [enableSTAPrice, setEnableSTAPrice] = useState(false);
  const [context, setContext] = useContext(Context);
  const [paymentActive, setPaymentActive] = useState("payment");
  const [remarks, setRemarks] = useState(null);

  useEffect(() => {
    if (context.logindata.region_type !== undefined) {
      dispatch({ type: "region_type", payload: "Qatar" });
    }
  }, [context.logindata]);

  useEffect(() => {
    //******if import booking then handle accordingly *********/
    let initData = []; //segment details
    if (props.pnrResponse.res.origns) {
      initData = props.pnrResponse.res.origns[0].srcorigns[0].seg;
    }
    let count = 1; // count of seg order for further use
    if (initData.length !== 0) {
      for (let val of initData) {
        //iteration of seg details
        if (val) {
          val.trip_type = 1;
          val.seg_order = count;
          count = count + 1;
        }
      }
    }
    let new_seg_details = constructSegmentForImport(initData); //handle layover time or new departure
    if (new_seg_details !== null && new_seg_details.length > 0) {
      initData = new_seg_details; //set new modified seg details
    }

    let arr = _.groupBy(initData, "trip_type");
    const propertyNames = Object.values(arr);

    dispatch({ type: "finalisd_import_seg", payload: propertyNames });
  }, [props.pnrResponse.res]);

  const constructSegmentForImport = (data) => {
    let seg_data = data; //segment details

    // scenarios->

    if (seg_data.length > 0 && seg_data.length === 2) {
      //check if its like one way with connectivity
      let duration = DateUtils.msToTime(
        Math.abs(new Date(seg_data[1].dd) - new Date(seg_data[0].ad))
      ); //layover time
      let dayExceedTag = false;
      if (duration.includes("day") || duration.includes("days")) {
        dayExceedTag = true;
      }
      //   if(dayExceedTag) {
      if (seg_data[0].ar == seg_data[1].da) {
        //checking arrival of first segment and departure of second segment
        if (seg_data[0].da == seg_data[1].ar) {
          //checking departure of first segment and arrival of first segment
          seg_data[1].trip_type = 2; //assiginng new trip type(AKA segment header count) to show as new departure
          seg_data[1].seg_order = 1; // assigning inital seg order

          return seg_data; //no changes
        } else {
          if (dayExceedTag) {
            seg_data[1].trip_type = 2; //assiginng new trip type(AKA segment header count) to show as new departure
            seg_data[1].seg_order = 1; // assigning inital seg order

            return seg_data; //changes
          } else {
            return null; //no changes
          }
        }
      } else {
        if (seg_data[0].da == seg_data[1].ar) {
          seg_data[1].trip_type = 2; //assiginng new trip type(AKA segment header count) to show as new departure
          seg_data[1].seg_order = 1; // assigning inital seg order

          return seg_data; //changes
        } else {
          return null; //no changes
        }
      }
    } else {
      let count = 1; //intial count of trip type
      let segment_number = 1;
      //iterate each of the seg to handle same condition as above
      for (let indexOf in seg_data) {
        let current_seg = seg_data[indexOf]; //iterative current segment
        let next_seg = null;
        if (seg_data[Number(indexOf) + 1]) {
          next_seg = seg_data[Number(indexOf) + 1]; //next segment
        }
        //****condition to check whether next segment is available or not *****/
        if (next_seg) {
          //if current segment arrival === next segment destination
          // if(current_seg.ar == next_seg.da) {
          let duration = DateUtils.msToTime(
            Math.abs(new Date(next_seg.dd) - new Date(current_seg.ad))
          ); //layover time
          let dayExceedTag = false;
          if (
            duration.includes("day") ||
            duration.includes("days") ||
            duration.includes("d")
          ) {
            dayExceedTag = true;
          }
          //this means its more than or equal to 24 hrs
          if (dayExceedTag) {
            count = count + 1;
            segment_number = 1;
          }
          let next_seg_count = count;
          next_seg.trip_type = next_seg_count; //assiginng new trip type(AKA segment header count) to show as new departure
          if (dayExceedTag) {
            next_seg.seg_order = 1; // assigning inital seg order
          } else {
            segment_number = segment_number + 1;
            next_seg.seg_order = segment_number;
          }
        }
        // }
      }
      return seg_data; //changes depends on layover time
    }
  };

  function getAirportdata(code, val) {
    if (isNotNull(props.airport_data) && isNotNull(code)) {
      for (let a in props.airport_data) {
        let air_data = props.airport_data[a];
        if (isNotNull(air_data.airportCode)) {
          if (air_data.airportCode === code && val === "c") {
            return air_data.cityName;
          } else if (air_data.airportCode === code && val === "a") {
            return air_data.airportName;
          }
        }
      }
    }
  }

  const handlePax = (data) => (e) => {
    const { id, value } = e.target;
    if (response.psngrs.length !== 0) {
      for (let pax of response.psngrs) {
        if (pax.sno === data.sno) {
          if (!pax.selected) {
            pax.selected = true;
          } else {
            pax.selected = false;
          }
        }
      }
    }
    dispatch({ type: id, payload: value });
  };

  const handlePaxEmd = (data) => (e) => {
    const { id, value } = e.target;
    if (response.psngrs.length !== 0) {
      for (let pax of response.psngrs) {
        if (pax.sno === data.sno) {
          if (!pax.isemd) {
            pax.isemd = true;
          } else {
            pax.isemd = false;
          }
        }
      }
    }
    dispatch({ type: id, payload: value });
  };

  const handlePaxChange = (data, isInf) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    if (response.psngrs.length !== 0) {
      for (let pax of response.psngrs) {
        if (isInf) {
          if (pax.sno === data.sno) {
            pax.inf.tkt = value;
          }
        } else {
          if (pax.sno === data.sno) {
            pax.tkt = value;
          }
        }
      }
    }
    dispatch({ type: id, payload: value });
    props.sendPaxInfo(response.psngrs);
  };

  const handlePaxEmdChange = (data, isInf) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    if (response.psngrs.length !== 0) {
      for (let pax of response.psngrs) {
        if (isInf) {
          if (pax.sno === data.sno) {
            pax.inf.emd_number = value;
          }
        } else {
          if (pax.sno === data.sno) {
            pax.emd_number = value;
          }
        }
      }
    }
    dispatch({ type: id, payload: value });
    props.sendPaxInfo(response.psngrs);
  };

  useEffect(() => {
    if (enableAttPrice) {
      calculateADTPrice();
    }
  }, [enableAttPrice]);

  useEffect(() => {
    if (enableTAPrice) {
      calculateCHDPrice();
    }
  }, [enableTAPrice]);

  useEffect(() => {
    if (enableSTAPrice) {
      calculateSTAPrice();
    }
  }, [enableSTAPrice]);

  useEffect(() => {
    dispatch({ type: "adts", payload: response.adt });
    dispatch({ type: "chds", payload: response.chd });
    dispatch({ type: "infs", payload: response.inf });
    convertPaxpriceDetails();
    let adtList = [];
    let chdList = [];
    let infList = [];
    for (let pax of response.psngrs) {
      if (pax.ty == "ADT") {
        let obj1 = {
          pname: pax.fn + " " + pax.ln,
          number: pax.tkt,
          ty: pax.ty,
        };
        adtList.push(obj1);
        dispatch({ type: "adtpax", payload: state.adtpax.concat(adtList) });
        if (pax.inf !== undefined && pax.inf !== "") {
          let obj3 = {
            pname: pax.inf.fn + " " + pax.inf.ln,
            number: pax.inf.tkt,
            ty: pax.inf.ty,
          };
          infList.push(obj3);
          dispatch({ type: "infpax", payload: state.infpax.concat(infList) });
        }
      } else if (pax.ty == "CHD") {
        let obj2 = {
          pname: pax.fn + " " + pax.ln,
          number: pax.tkt,
          ty: pax.ty,
        };
        chdList.push(obj2);
        dispatch({ type: "chdpax", payload: state.chdpax.concat(chdList) });
      }
    }
  }, [response]);

  const convertPaxpriceDetails = () => {
    for (let price of response.origns[0].srcorigns[0].paxpr) {
      if (price.ptype == "ADT") {
        // dispatch({ type: "adtbase", payload: price.bf });
        // dispatch({
        //   type: "adttaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({ type: "adtbase", payload: 0 });
        dispatch({
          type: "adttaxes",
          payload: 0,
        });
        dispatch({ type: "adtsupCharge", payload: Number(price.tf) });
        // dispatch({ type: "adttotal", payload: Number(price.tf) });
        dispatch({ type: "adttotal", payload: 0 });
        // dispatch({
        //   type: "adttotalamt",
        //   payload: Number(price.tf) * Number(response.adt),
        // });
        dispatch({
          type: "adttotalamt",
          payload: 0,
        });
        // dispatch({ type: "adttotalta", payload: Number(price.tf) });
        // dispatch({
        //   type: "adttotalamtta",
        //   payload: Number(price.tf) * Number(response.adt),
        // });
        dispatch({ type: "adttotalta", payload: 0 });
        dispatch({
          type: "adttotalamtta",
          payload: 0,
        });
        dispatch({ type: "adttotalsta", payload: Number(price.tf) });
        dispatch({
          type: "adttotalamtsta",
          payload: Number(price.tf) * Number(response.adt),
        });
      } else if (price.ptype == "CHD") {
        // dispatch({ type: "chdbase", payload: price.bf });
        // dispatch({
        //   type: "chdtaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({ type: "chdbase", payload: 0 });
        dispatch({
          type: "chdtaxes",
          payload: 0,
        });
        dispatch({ type: "chdsupCharge", payload: Number(price.tf) });
        // dispatch({ type: "chdtotal", payload: Number(price.tf) });
        // dispatch({
        //   type: "chdtotalamt",
        //   payload: Number(price.tf) * Number(response.chd),
        // });
        dispatch({ type: "chdtotal", payload: 0 });
        dispatch({
          type: "chdtotalamt",
          payload: 0,
        });
        // dispatch({ type: "chdtotalta", payload: Number(price.tf) });
        // dispatch({
        //   type: "chdtotalamtta",
        //   payload: Number(price.tf) * Number(response.chd),
        // });
        dispatch({ type: "chdtotalta", payload: 0 });
        dispatch({
          type: "chdtotalamtta",
          payload: 0,
        });
        dispatch({ type: "chdtotalsta", payload: Number(price.tf) });
        dispatch({
          type: "chdtotalamtsta",
          payload: Number(price.tf) * Number(response.chd),
        });
      } else if (price.ptype == "INF") {
        // dispatch({ type: "infbase", payload: price.bf });
        // dispatch({
        //   type: "inftaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({ type: "infbase", payload: 0 });
        dispatch({
          type: "inftaxes",
          payload: 0,
        });
        dispatch({ type: "infsupCharge", payload: Number(price.tf) });
        // dispatch({ type: "inftotal", payload: Number(price.tf) });
        // dispatch({
        //   type: "inftotalamt",
        //   payload: Number(price.tf) * Number(response.inf),
        // });
        dispatch({ type: "inftotal", payload: 0 });
        dispatch({
          type: "inftotalamt",
          payload: 0,
        });
        // dispatch({ type: "inftotalta", payload: Number(price.tf) });
        // dispatch({
        //   type: "inftotalamtta",
        //   payload: Number(price.tf) * Number(response.inf),
        // });
        dispatch({ type: "inftotalta", payload: 0 });
        dispatch({
          type: "inftotalamtta",
          payload: 0,
        });
        dispatch({ type: "inftotalsta", payload: Number(price.tf) });
        dispatch({
          type: "inftotalamtsta",
          payload: Number(price.tf) * Number(response.inf),
        });
      }
    }
  };

  //Calculation ADT Price for ATT , TA AND STA
  const handleChangeADTPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setType(type);
    setPType(paxType);
    setEnableAttPrice(true);
  };
  //Calculation ADT Price for ATT , TA AND STA
  const calculateADTPrice = () => {
    if (type === "ATT" && ptype === "ADT") {
      let supprice = Number(state.adtbase) + Number(state.adttaxes);
      dispatch({ type: "sup_totalPrice", payload: supprice });
      let price =
        Number(supprice) +
        Number(state.adtmarkup) +
        Number(state.adtsf) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      let taPrice = Number(price);
      if (state.adtmarkup !== "" || state.adtsf !== "") {
        let totalGst =
          Number(state.adtmarkup) + Number(state.adtsf) - Number(state.adtdis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_adtcgst", payload: Number(attCgst) });
            dispatch({ type: "att_adtsgst", payload: Number(attSgst) });
            dispatch({
              type: "adt_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            price = price + Number(attSgst) + Number(attCgst);
            taPrice = taPrice + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_adtigst", payload: Number(attIgst) });
            dispatch({ type: "adt_attgst", payload: Number(attIgst) });
            price = price + Number(attIgst);
            taPrice = taPrice + Number(attIgst);
          }
        }
      }
      dispatch({ type: "adtsupCharge", payload: Number(supprice) });
      dispatch({ type: "adttotal", payload: price.toFixed(2) });
      dispatch({ type: "adttotalta", payload: taPrice.toFixed(2) });
      dispatch({ type: "adttotalsta", payload: taPrice.toFixed(2) });
      dispatch({ type: "adttotalamt", payload: Number(price) * state.adts });
      dispatch({
        type: "adttotalamtta",
        payload: Number(taPrice) * state.adts,
      });
      dispatch({
        type: "adttotalamtsta",
        payload: Number(taPrice) * state.adts,
      });
    } else if (type === "TA" && ptype === "ADT") {
      let price =
        Number(state.sup_totalPrice) +
        Number(state.adtmarkupta) +
        Number(state.adtmarkup) +
        Number(state.adtsf) +
        Number(state.adtsfta) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      let staPrice = Number(price);
      if (state.adtmarkupta !== "" || state.adtsfta !== "") {
        let totalGst =
          Number(state.adtmarkupta) +
          Number(state.adtsfta) -
          Number(state.adtdista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_adtcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_adtsgst", payload: Number(taSgst) });
            dispatch({
              type: "adt_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            price = price + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_adtigst", payload: Number(attIgst) });
            dispatch({ type: "adt_tagst", payload: Number(attIgst) });
            price = price + Number(attIgst);
          }
        }
      }
      dispatch({ type: "adttotalta", payload: price.toFixed(2) });
      dispatch({
        type: "adttotalamtta",
        payload: price.toFixed(2) * state.adts,
      });
      dispatch({ type: "adttotalsta", payload: staPrice });
      dispatch({ type: "adttotalamtsta", payload: staPrice * state.adts });
    } else if (type === "STA" && ptype === "ADT") {
      let totalsta =
        Number(state.sup_totalPrice) +
        Number(state.adtmarkup) +
        Number(state.adtmarkupta) +
        Number(state.adtsfta) +
        Number(state.adtsf) +
        Number(state.adtmarkupsta) +
        Number(state.adtsfsta) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      dispatch({ type: "adttotalsta", payload: totalsta });
      dispatch({ type: "adttotalamtsta", payload: totalsta * state.adts });
    }
    setEnableAttPrice(false);
  };

  //Calculation CHD Price for ATT , TA AND STA
  const handleChangeCHDPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setTypeOne(type);
    setPTypeOne(paxType);
    setEnableTAPrice(true);
  };
  const calculateCHDPrice = () => {
    if (typeOne === "ATT" && ptypeOne === "CHD") {
      let suppChdrice = Number(state.chdbase) + Number(state.chdtaxes);
      let price =
        Number(suppChdrice) +
        Number(state.chdmarkup) +
        Number(state.chdsf) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      let taPrice = Number(price);
      if (state.chdmarkup !== "" || state.chdsf !== "") {
        let totalGst =
          Number(state.chdmarkup) + Number(state.chdsf) - Number(state.chddis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_chdcgst", payload: Number(attCgst) });
            dispatch({ type: "att_chdsgst", payload: Number(attSgst) });
            dispatch({
              type: "chd_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            price = price + Number(attSgst) + Number(attCgst);
            taPrice = taPrice + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_chdigst", payload: Number(attIgst) });
            dispatch({ type: "chd_attgst", payload: Number(attIgst) });
            price = price + Number(attIgst);
            taPrice = taPrice + Number(attIgst);
          }
        }
      }
      dispatch({ type: "chdsupCharge", payload: Number(suppChdrice) });
      dispatch({ type: "chdtotal", payload: price.toFixed(2) });
      dispatch({ type: "chdtotalamt", payload: price.toFixed(2) * state.chds });
      dispatch({ type: "chdtotalta", payload: taPrice });
      dispatch({
        type: "chdtotalamtta",
        payload: Number(taPrice) * state.chds,
      });
      dispatch({ type: "chdtotalsta", payload: Number(taPrice) });
      dispatch({
        type: "chdtotalamtsta",
        payload: Number(taPrice) * state.chds,
      });
    } else if (typeOne === "TA" && ptypeOne === "CHD") {
      let price =
        Number(state.chdsupCharge) +
        Number(state.chdmarkup) +
        Number(state.chdsf) +
        Number(state.chdmarkupta) +
        Number(state.chdsfta) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      let staPrice = Number(price);
      if (state.chdmarkupta !== "" || state.chdsfta !== "") {
        let totalGst =
          Number(state.chdmarkupta) +
          Number(state.chdsfta) -
          Number(state.chddista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_chdcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_chdsgst", payload: Number(taSgst) });
            dispatch({
              type: "chd_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            price = price + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_chdigst", payload: Number(attIgst) });
            dispatch({ type: "chd_tagst", payload: Number(attIgst) });
            price = price + Number(attIgst);
          }
        }
      }
      dispatch({ type: "chdtotalta", payload: price.toFixed(2) });
      dispatch({
        type: "chdtotalamtta",
        payload: price.toFixed(2) * state.chds,
      });
      dispatch({ type: "chdtotalsta", payload: staPrice });
      dispatch({ type: "chdtotalamtsta", payload: staPrice * state.chds });
    } else if (typeOne === "STA" && ptypeOne === "CHD") {
      let totalsta =
        Number(state.chdsupCharge) +
        Number(state.chdmarkup) +
        Number(state.chdmarkupta) +
        Number(state.chdsfta) +
        Number(state.chdsf) +
        Number(state.chdmarkupsta) +
        Number(state.chdsfsta) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      dispatch({
        type: "chdsupChargeSTA",
        payload: Number(state.chdsupCharge),
      });
      dispatch({ type: "chdtotalsta", payload: totalsta });
      dispatch({ type: "chdtotalamtsta", payload: totalsta * state.chds });
    }
    setEnableTAPrice(false);
  };

  const handleChangeINFPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setTypeTwo(type);
    setPTypeTwo(paxType);
    setEnableSTAPrice(true);
  };
  //Calculation INF Price for ATT , TA AND STA
  const calculateSTAPrice = () => {
    if (typeTwo === "ATT" && ptypeTwo === "INF") {
      let supInfprice = Number(state.infbase) + Number(state.inftaxes);
      let total =
        Number(supInfprice) + Number(state.infmarkup) + Number(state.infsf) + Number(state.infPenalty);
      if (state.infmarkup !== "" || state.infsf !== "") {
        let totalGst =
          Number(state.infmarkup) + Number(state.infsf) - Number(state.infdis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_infcgst", payload: Number(attCgst) });
            dispatch({ type: "att_infsgst", payload: Number(attSgst) });
            dispatch({
              type: "inf_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            total = total + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_infigst", payload: Number(attIgst) });
            dispatch({ type: "inf_attgst", payload: Number(attIgst) });
            total = total + Number(attIgst);
          }
        }
      }
      dispatch({ type: "infsupCharge", payload: supInfprice });
      dispatch({ type: "inftotal", payload: total });
      dispatch({ type: "inftotalamt", payload: total * state.infs });
      dispatch({ type: "inftotalta", payload: total });
      dispatch({ type: "inftotalamtta", payload: total * state.infs });
      dispatch({ type: "inftotalsta", payload: Number(total) });
      dispatch({ type: "inftotalamtsta", payload: Number(total) * state.infs });
    } else if (typeTwo === "TA" && ptypeTwo === "INF") {
      let totalta =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infsf) +
        Number(state.infmarkupta) +
        Number(state.infsfta);
      let charge = Number(state.infsupCharge);
      let staPrice =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infmarkupta) +
        Number(state.infsf) +
        Number(state.infsfta);
      if (state.infmarkupta !== "" || state.infsfta !== "") {
        let totalGst =
          Number(state.infmarkupta) +
          Number(state.infsfta) -
          Number(state.infdista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_infcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_infsgst", payload: Number(taSgst) });
            dispatch({
              type: "inf_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            totalta = totalta + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_infigst", payload: Number(attIgst) });
            dispatch({ type: "inf_tagst", payload: Number(attIgst) });
            totalta = totalta + Number(attIgst);
          }
        }
      }
      dispatch({ type: "infsupChargeTA", payload: charge.toFixed(2) });
      dispatch({ type: "inftotalta", payload: totalta.toFixed(2) });
      dispatch({
        type: "inftotalamtta",
        payload: totalta.toFixed(2) * state.infs,
      });
      dispatch({ type: "inftotalsta", payload: Number(staPrice) });
      dispatch({
        type: "inftotalamtsta",
        payload: Number(staPrice) * state.infs,
      });
    } else if (typeTwo === "STA" && ptypeTwo === "INF") {
      let totalsta =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infmarkupta) +
        Number(state.infmarkupsta) +
        Number(state.infsfsta) +
        Number(state.infsf) +
        Number(state.infsfta);
      dispatch({
        type: "infsupChargeSTA",
        payload: Number(state.infsupCharge),
      });
      dispatch({ type: "inftotalsta", payload: totalsta });
      dispatch({ type: "inftotalamtsta", payload: totalsta * state.infs });
    }
    setEnableSTAPrice(false);
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const validatePrice = () => {
    if (state.adtbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Adult",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.adttaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Adult",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.chds > 0 && state.chdbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Child",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.chds > 0 && state.chdtaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Child",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.infs > 0 && state.infbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Infant",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.infs > 0 && state.inftaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Infant",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.payment_details === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Payment deatails and proceed",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    if (response.psngrs.length !== 0) {
      let tkts1 = response.psngrs.filter((obj) => obj.tkt === "");
      let tkts2 = response.psngrs.filter(
        (obj) => obj.inf !== undefined && obj.inf.tkt === ""
      );
      if (tkts1.length !== 0 || tkts2.length !== 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: "Enter Ticket Number" });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      } else if (paymentActive === "payment") {
        if(!state.payment_details.is_pay_uniq_id && !state.payment_details.is_bank_transfer && !state.payment_details.is_cheque) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter payment details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          window.scrollTo(0, 0);
          return false;
        } else if (
          state.payment_details.is_pay_uniq_id &&
          (state.payment_details.pay_uniq_id == "" ||
            state.payment_details.paid_amount === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please check Payment UID details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          window.scrollTo(0, 0);
          return false;
        } else if (
          state.payment_details.is_bank_transfer &&
          (state.payment_details.bank_name === "" ||
            state.payment_details.ac_number === "" ||
            state.payment_details.total_amt === "" ||
            state.payment_details.cust_name === "" ||
            state.payment_details.trans_number === "" ||
            state.payment_details.date_of_transfer === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Bank Transfer details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          state.payment_details.is_cheque &&
          (state.payment_details.che_bank_name === "" ||
            state.payment_details.che_ac_number === "" ||
            state.payment_details.che_total_amt === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Cheque details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      } else if (paymentActive === "remarks") {
        if(remarks === null) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: "Enter Remarks" });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      }   
    } else {
      return true;
    }
    return true;
  };

  const continueBooking = () => {
    if (validatePrice()) {
      confirmManualBooking();
    }
  };

  const cancel = () => {
    props.closePopup();
    setIsLoad(false);
  };

  const createMainPr = () => {
    let totalSf = 0;
    let baseFare =
      Number(state.adtbase * state.adts) +
      Number(state.chdbase * state.chds) +
      Number(state.infbase * state.infs);
    let taxes =
      Number(state.adttaxes * state.adts) +
      Number(state.chdtaxes * state.chds) +
      Number(state.inftaxes * state.infs);
    let total = Number(baseFare) + Number(taxes);
    const payload = {
      bf: Number(baseFare),
      tf: Number(total),
      tax: [
        {
          OT: Number(taxes),
        },
        {
          attmkp:
            Number(state.adtmarkup * state.adts) +
            Number(state.chdmarkup * state.chds) +
            Number(state.infmarkup * state.infs),
        },
        {
          attdisc:
            Number(state.adtdis * state.adts) +
            Number(state.chddis * state.chds) +
            Number(state.infdis * state.infs),
        },
        {
          att_gst:
            Number(state.adt_attgst * state.adts) +
            Number(state.chd_attgst * state.chds) +
            Number(state.inf_attgst * state.infs),
        },
      ],

      tfd: Number(total),
      penlty:
        Number(state.adtPenalty * state.adts) +
        Number(state.chdPenalty * state.chds) +
        Number(state.infPenalty * state.infs),
      resdval: 0,
      gtd: Number(total),
      bfd: Number(baseFare),
      scur: state.cur,
      sup_fare: baseFare + "_" + total,
      sup_cur_fare: baseFare + "_" + total,
      markupnames: createMarkupname(),
      gst_total:0,
    };
    return payload;
  };

  const createMarkupname = () => {
    let markNames = [];
    if (state.adtmarkup !== 0) {
      const att = {
        "code": "CNT",
        "name": "CNT Manual Markup"
      }
      markNames.push(att);
    }
    return markNames;
  };

  const createPaxRequest = (type) => {
    let baseFare = 0;
    let total = 0;
    let taxes = 0;
    let att = 0;
    let attdisc = 0;
    let fbc = [];
    let penalty = 0;
    let resVal = 0;
    let att_cgst = 0;
    let att_sgst = 0;
    let att_igst = 0;
    if (type === "ADT") {
      baseFare = Number(state.adtbase) + Number(state.adtsf);
      let totalSup = Number(state.adtbase) + Number(state.adttaxes);
      total = Number(totalSup) + Number(state.adtsf);
      taxes = state.adttaxes;
      att = state.adtmarkup;
      attdisc = state.adtdis;
      fbc = response.origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "ADT"
      );
      penalty = state.adtPenalty;
      resVal = state.adtResVal;
      att_cgst = state.att_adtcgst;
      att_sgst = state.att_adtsgst;
      att_igst = state.att_adtigst;
    } else if (type === "CHD") {
      baseFare = Number(state.chdbase) + Number(state.chdsf);
      let totalSup = Number(state.chdbase) + Number(state.chdtaxes);
      total = Number(totalSup) + Number(state.chdsf);
      taxes = state.chdtaxes;
      att = state.chdmarkup;
      attdisc = state.chddis;
      fbc = response.origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "CHD"
      );
      penalty = state.chdPenalty;
      resVal = state.chdPenalty;
      att_cgst = state.att_chdcgst;
      att_sgst = state.att_chdsgst;
      att_igst = state.att_chdigst;
    } else if (type === "INF") {
      baseFare =Number(state.infbase) + Number(state.infsf);
      let totalSup = Number(state.infbase) + Number(state.inftaxes);
      total = Number(totalSup) + Number(state.infsf);
      taxes = state.inftaxes;
      att = state.infmarkup;
      attdisc = state.infdis;
      fbc = response.origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "INF"
      );
      penalty = state.infPenalty;
      resVal = state.infResidualVal;
      att_cgst = state.att_infcgst;
      att_sgst = state.att_infsgst;
      att_igst = state.att_infigst;
    }
    const payload = {
      bf: Number(baseFare),
      tf: Number(total),
      ptype: type,
      tax: [
        {
          OT: Number(taxes),
        },
        {
          attmkp: Number(att),
        },
        {
          attdisc: Number(attdisc),
        },
        {
          att_cgst: Number(att_cgst),
        },
        {
          att_sgst: Number(att_sgst),
        },
        {
          att_igst: Number(att_igst),
        },
      ],
      scur: state.cur,
      fbc: fbc[0]?.fbc,
      tfd: Number(total),
      penlty: Number(penalty),
      resdval: 0,
      gtd: Number(total),
      bfd: Number(baseFare),
      psup_fare: baseFare + "_" + total,
      sup_cur_fare: baseFare + "_" + total,
    };
    return payload;
  };

  const createPaxPr = () => {
    let paxList = [];
    paxList.push(createPaxRequest("ADT"));
    if (state.chds > 0) {
      paxList.push(createPaxRequest("CHD"));
    }
    if (state.infs > 0) {
      paxList.push(createPaxRequest("INF"));
    }
    return paxList;
  };

  const convertSegReq = (ref) => {
    const payload = {
      tid:
        response.origns[0].srcorigns[0].tid !== undefined
          ? response.origns[0].srcorigns[0].tid
          : "",
      ref: response.origns[0].srcorigns[0].ref,
      ofcid: response.origns[0].srcorigns[0].ofcid,
      sdur: response.origns[0].srcorigns[0].sdur,
      re: response.origns[0].srcorigns[0].re,
      stop: response.origns[0].srcorigns[0].stop,
      sup: response.origns[0].srcorigns[0].sup,
      cl: response.origns[0].srcorigns[0].cl,
      ft: response.origns[0].srcorigns[0].ft,
      tktair: response.origns[0].srcorigns[0].tktair,
      tecst: response.origns[0].srcorigns[0].tecst,
      seg: response.origns[0].srcorigns[0].seg,
      bgd: response.origns[0].srcorigns[0].bag,
      pr: createMainPr(),
      paxpr: createPaxPr(),
      serviceFee: createServiceFee(),
    };
    return payload;
  };

  const request = (type, supFee) => {
    let adt = 0;
    let chd = 0;
    let inf = 0;
    let total = 0;
    let nm = "";
    if (type === "CNT") {
      adt = state.adtsf;
      chd = state.chdsf;
      inf = state.infsf;
      total = Number(state.adtsf) + Number(state.chdsf) + Number(state.infsf);
      nm = "CNT Manual Service Fee";
      supFee = 0;
    }
    const payload = {
      type: type,
      adt: adt,
      chd: chd,
      inf: inf,
      servicefee_name: nm,
      apl_on: "Per Ticket",
      sf: total,
      sf_disc: 0,
      adt_sf_disc: 0,
      chd_sf_disc: 0,
      inf_sf_disc: 0,
      complete_sfvalue: total,
      sup_fee: supFee,
    };
    return payload;
  };

  const createServiceFee = () => {
    let services = [];
    let att = request("CNT", 0);
    services.push(att);
    return services;
  };

  const convertSrcorigns = () => {
    if (Number(response.tt) === 2) {
      const payload = {
        srcorigns: [convertSegReq("O")],
        destorigins: [convertSegReq("R")],
      };
      return payload;
    } else {
      const payload1 = {
        srcorigns: [convertSegReq("O")],
      };
      return payload1;
    }
  };

  const confirmManualBooking = () => {
    dispatch({ type: "isItineraryLoading", payload: true });
    // for(let pax of response.psngrs){
    //   let paxinfo=props.paxList[0].filter((obj)=>Number(obj.paxsno)===Number(pax.sno));
    //   if(paxinfo.length!==0){
    //     pax.trv_op_id=paxinfo[0].trv_op_id;
    //   }
    // }
    const payload = {
      pbreq: {
        cha: "B2C",
        cur: state.cur,
        oldtid: props.oldtid,
        bkref: props.bookingId,
        pnr: response.pnr,
        adt: response.adt,
        chd: response.chd,
        inf: response.inf,
        cid: FLIGHT_SEARCH_CID,
        cname: "QA b2c",
        sty: "normal",
        tt: Number(response.tt),
        utype: context.logindata.utype,
        bid: context.logindata.bid,
        butype: context.logindata.butype,
        pcid: context.logindata.pcid,
        ofcId: response.origns[0].srcorigns[0].ofcid,
        tkt_user_type: "CRM_USER",
        tkt_user_id: context.logindata.user_id,
        sup: "0",
        bkby: context.logindata.user_id,
        cuscode: context.logindata.cuscode,
        bktype: "MANUAL/REISSUE",
        crby: context.logindata.user_id,
        prof: [],
        psngrs: response.psngrs,
        origns: [convertSrcorigns()],
        pay: remarks === null ? createPayObj() : [],
        remarks: remarks,
        tfflow: false,
        region_type: state.region_type,
        type: state.gst_type,
        cgst: state.cgst,
        sgst: state.sgst,
        igst: state.igst,
        pay_uniq_id: state.pay_uniq_id,
        bkby_name: context.logindata.fname + " " + context.logindata.lname,
        region_cur: "QAR",
        req_cur: "QAR",
        sup_base_exrate: 1,
        dest_sup_base_exrate: 1,
        base_sup_exrate: 1,
        dest_base_sup_exrate: 1,
        base_usr_exrate: 1,
        base_region_exrate: 1,
      },
    };
    setIsLoad(true);
    setBtnDisable(true);
    ManageBookingService.saveBooking(payload)
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data.success &&
          response.data.data !== undefined
        ) {
          if (
            response.data.data.pbres.status !== undefined &&
            response.data.data.pbres.status === "inserted"
          ) {
            setIsLoad(false);
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Successfully Re-Issued your Booking",
            });
            dispatch({ type: "notiVarient", payload: "success" });
            dispatch({ type: "isItineraryLoading", payload: false });
            setTimeout(function () {
              setBtnDisable(false);
              return (window.location = window.location.pathname);
            }, 3000);
          } else {
            setIsLoad(false);
            dispatch({ type: "isItineraryLoading", payload: false });
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Something went wrong..Please try again later",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
          }
        } else {
          setIsLoad(false);
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Something went wrong..Please try again later",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
        }
      })
      .catch(function (err) {
        setIsLoad(false);
        log.error("Exception occured in continueBooking function---" + err);
      });
  };

  const updatePayment = (data) => {
    dispatch({ type: "payment_details", payload: data });
  };

  const createPayObj = () => {
    let payObj = [];
    let uniq_pay = "";
    let bank_pay = "";
    let che_pay = "";
    if (state.payment_details.is_pay_uniq_id) {
      dispatch({
        type: "pay_uniq_id",
        payload: state.payment_details.pay_uniq_id,
      });
      uniq_pay = {
        pay_uniq_id: state.payment_details.pay_uniq_id,
        ptype: "Pay UID",
        amt: state.payment_details.paid_amount,
        psta: "Confirmed",
      };
      payObj.push(uniq_pay);
    }
    if (state.payment_details.is_bank_transfer) {
      bank_pay = {
        bank_name: state.payment_details.bank_name,
        ac_number: state.payment_details.ac_number,
        cust_name: state.payment_details.cust_name,
        trans_number: state.payment_details.trans_number,
        date_of_transfer: state.payment_details.date_of_transfer,
        psta: "Confirmed",
        amt: state.payment_details.total_amt,
        ptype: "Bank Transfer",
      };
      payObj.push(bank_pay);
    }
    if (state.payment_details.is_cheque) {
      che_pay = {
        bank_name: state.payment_details.che_bank_name,
        ac_number: state.payment_details.che_ac_number,
        trans_number: state.payment_details.che_trans_number,
        psta: "Confirmed",
        ptype: "Cheque",
        amt: state.payment_details.che_total_amt,
      };
      payObj.push(che_pay);
    }

    return payObj;
  };

  const handleRadioChange = (value) => {
    setPaymentActive(value);
    setRemarks(null);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  return (
    <>
      <div className="confirmation">
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
        {state.isItineraryLoading ? (
          <div class="loaderPageBackdrop">
            <div class="loaderPage">
              <div class="loaderIcon"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flightDetailsWrap commonWrap mt-0">
          <h5 className="title d-flex justify-content-between">
            Flight Details
          </h5>
          {state.finalisd_import_seg.length > 0 &&
                  state.finalisd_import_seg.map((sec, idx) => (
                    <>
                      <div className="rs-headerBlock mt-3 mb-4">
                        <Row className="align-items-center rs-row">
                          <Col xs={4} className="rs-rTypeDate d-flex">
                            <>
                              <span className="departure ezyIcon icon-flight">
                                <span className="departureTitle">Departure</span>{" "}
                              </span>
                              <span>
                                {DateUtils.prettyDate(
                                  sec[0].dd,
                                  "ddd, DD MMM YYYY"
                                )}
                              </span>
                            </>
                          </Col>
                          <Col xs={8} className="rs-FromTo">
                            <Row className="align-items-center">
                              <Col xs={4} className="d-flex flex-column">
                                <AirportMemo
                                  code={sec[0].da}
                                  mapping={mappings}
                                />
                              </Col>
                              <Col xs="1">
                                <span className="ezyIcon icon-flight"></span>
                              </Col>
                              <Col xs={4} className="d-flex flex-column">
                                <AirportMemo
                                  code={sec[sec.length - 1].ar}
                                  mapping={mappings}
                                />
                              </Col>
                            </Row>
                            <span></span>
                          </Col>
                        </Row>
                      </div>
                      {/* <FlightSegmentImportDetails  origindata={[{"seg":sec}, 'Confirm']} cl={response.origns[0].srcorigns[0].cl} pnr={response.pnr} bgd={response.origns[0].srcorigns[0].bag} mapping={mappings} confirmation={true}/> */}
                    </>
                  ))}
                  <div className="selectedRout resultSection tableLayout">
            <Table className="mb-0">
              <tbody>
                {state.finalisd_import_seg.length !== 0 &&
                  response.origns[0].srcorigns[0].seg.map((sege, index) => (
                    <React.Fragment key={index}>
                      <>
                        <tr>
                          <td>
                            <div className="d-flex planeInfo">
                              <img
                                src={
                                  "https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/" +
                                  sege.ma +
                                  ".png"
                                }
                                className="logo"
                                alt="AirLine"
                              />
                              <div className="">
                                <div className="planeName">
                                  <AirlinesMemo code={sege.ma} />
                                </div>
                                <div className="planeCode">
                                  {sege.ac} {sege.fn}
                                </div>
                                <div className="oprtBy">
                                  <span>Operated By : </span>{" "}
                                  <span>
                                    <AirlinesMemo code={sege.oa} />
                                  </span>
                                </div>
                              </div>
                            </div>{" "}
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <strong>
                                {DateUtils.prettyDate(sege.dd, "HH:mm")}{" "}
                                <small>
                                  {DateUtils.prettyDate(
                                    sege.dd,
                                    "ddd, MMM DD, YYYY"
                                  )}
                                </small>
                              </strong>
                              <span className="d-flex flex-column">
                                <AirportMemo
                                  code={sege.da}
                                  mapping={mappings}
                                />
                              </span>
                              <span>
                                {getAirportdata(sege.da, "c")} ({sege.da}),
                              </span>
                              <span>{getAirportdata(sege.da, "a")}</span>
                              {sege.terminal && (
                                <small className="trm">
                                  {"Terminal - " + sege.terminal.dep}
                                </small>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <strong>
                                {DateUtils.prettyDate(sege.ad, "HH:mm")}{" "}
                                <small>
                                  {DateUtils.prettyDate(
                                    sege.ad,
                                    "ddd, MMM DD, YYYY"
                                  )}
                                </small>
                              </strong>
                              <span className="d-flex flex-column">
                                <AirportMemo
                                  code={sege.ar}
                                  mapping={mappings}
                                />
                              </span>
                              <span>
                                {getAirportdata(sege.ar, "c")} ({sege.ar}),
                              </span>
                              <span>{getAirportdata(sege.ar, "a")}</span>
                              {sege.terminal && (
                                <small className="trm">
                                  {"Terminal - " + sege.terminal.arr}
                                </small>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <div className="oprtBy">
                                <span>Airline PNR {sege.airpnr}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="p-0 border-0">
                            {response.origns[0].srcorigns[0].bag !==
                              undefined &&
                              response.origns[0].srcorigns[0].bag.length !==
                                0 && (
                                <>
                                  {response.origns[0].srcorigns[0].bag.map(
                                    (bag, idx) => (
                                      <React.Fragment key={idx}>
                                        {bag.tatoo === sege.tatoo && (
                                          <div className="sectBaggageinfo ezyIcon icon-baggage">
                                            <span>Baggage Info :&nbsp;</span>  Cabin 7 kgs, Check-in{" "}
                                            {bag.qun} {bag.wt} per Adult & Child{" "}
                                          </div>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </>
                              )}
                          </td>
                        </tr>
                        {/* <tr>
                            {response.origns[0].srcorigns[0].seg.length > 1 &&
                             (isNotNull(response.origns[0].srcorigns[0].seg[(index + 1)]))
                             && <LayoverDetails layoverdata={[response.origns[0].srcorigns[0].seg[index].ad, response.origns[0].srcorigns[0].seg[(index + 1)].dd, sege.ar, mappings]} />}
                            </tr> */}
                      </>
                    </React.Fragment>
                  ))}

                
              </tbody>
            </Table>
          </div>
        </div>
        
          
          <div className="passengerDetailsWrap commonWrap">
          <h5 className="title">Passenger Details </h5>
          <div className="selectedRout tableLayout manReissue">
            <Table className="mb-0 mt-3">
              <thead>
                <tr>
                  <th>Traveller</th>
                  <th>Pax Type</th>
                  <th>Ticket Numbers</th>
                  <th>EMD Numbers</th>
                  <th>Segment Status</th>
                  <th>Ticketing Status</th>
                </tr>
              </thead>
              <tbody>
                {response.psngrs.map((pasenger, idx) => (
                  <>
                    <tr>
                      <td>
                        {pasenger.tl.toUpperCase()}{" "}
                        {pasenger.fn.toUpperCase() +
                          " " +
                          pasenger.ln.toUpperCase()}{" "}
                        {pasenger.lead ? "Lead" : ""}
                      </td>
                      <td>{pasenger.plbl}</td>
                      <td width="200">
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={pasenger.selected}
                            onChange={handlePax(pasenger)}
                            custom
                            id={pasenger.tkt}
                          />
                          {!pasenger.selected && <span>{pasenger.tkt} </span>}
                          {pasenger.selected && (
                            <Form.Row>
                              <Form.Group
                                xs={12}
                                as={Col}
                                controlId="tkt"
                                className="edittime d-flex align-items-center justify-content-between m-0"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Ticket number"
                                  maxlength="14"
                                  value={pasenger.tkt}
                                  onChange={handlePaxChange(pasenger, false)}
                                  onKeyUp={(e) => Utilities.handleKeyUp(e)}
                                />
                              </Form.Group>
                            </Form.Row>
                          )}
                        </div>
                      </td>
                      <td width="200">
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={pasenger.isemd}
                            onChange={handlePaxEmd(pasenger)}
                            custom
                            id={idx + 1}
                          />
                          {!pasenger.isemd && (
                            <span>
                              {pasenger.emd_number !== ""
                                ? pasenger.emd_number
                                : "N/A"}{" "}
                            </span>
                          )}
                          {pasenger.isemd && (
                            <Form.Row>
                              <Form.Group
                                xs={12}
                                as={Col}
                                controlId="emd_number"
                                className="edittime d-flex align-items-center justify-content-between m-0"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter EMD number"
                                  value={pasenger.emd_number}
                                  onChange={handlePaxEmdChange(pasenger, false)}
                                />
                              </Form.Group>
                            </Form.Row>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className="text-success">{pasenger.segst}</span>
                      </td>
                      <td>
                        <span className="text-success">{pasenger.sts}</span>
                      </td>
                    </tr>

                    {pasenger.inf !== "" && (
                      <tr>
                        <td width="332">
                          {pasenger.inf.tl.toUpperCase()}{" "}
                          {pasenger.inf.fn + " " + pasenger.inf.ln} (
                          {pasenger.inf.plbl.toUpperCase()})
                        </td>
                        <td>Infant</td>

                        <td width="332">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={pasenger.selected}
                              onChange={handlePax(pasenger)}
                              custom
                              id={pasenger.inf.tkt}
                            />
                            {!pasenger.selected && (
                              <span>{pasenger.inf.tkt} </span>
                            )}
                            {pasenger.selected && (
                              <Form.Row>
                                <Form.Group
                                  xs={12}
                                  as={Col}
                                  controlId="tkt"
                                  className="edittime d-flex align-items-center justify-content-between m-0"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Ticket number"
                                    maxlength="14"
                                    value={pasenger.inf.tkt}
                                    onChange={handlePaxChange(pasenger, true)}
                                    onKeyUp={(e) => Utilities.handleKeyUp(e)}
                                  />
                                </Form.Group>
                              </Form.Row>
                            )}
                          </div>
                        </td>
                        <td width="332">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={pasenger.isemd}
                              onChange={handlePaxEmd(pasenger)}
                              custom
                              id={idx + 1}
                            />
                            {!pasenger.isemd && (
                              <span>
                                {pasenger.inf.emd_number !== ""
                                  ? pasenger.inf.emd_number
                                  : "N/A"}{" "}
                              </span>
                            )}
                            {pasenger.isemd && (
                              <Form.Row>
                                <Form.Group
                                  xs={12}
                                  as={Col}
                                  controlId="emd_number"
                                  className="edittime d-flex align-items-center justify-content-between m-0"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter EMD number"
                                    value={pasenger.inf.emd_number}
                                    onChange={handlePaxEmdChange(
                                      pasenger,
                                      true
                                    )}
                                  />
                                </Form.Group>
                              </Form.Row>
                            )}
                          </div>
                        </td>
                        <td>
                          <span className="text-success">{pasenger.segst}</span>
                        </td>
                        <td>
                          <span className="text-success">{pasenger.sts}</span>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
          </div>
          <div className="paxDetailsWrap commonWrap">
          <h5 className="title">Passenger Details({props.userCurrency})</h5>
          <div className="confirmation confirmationMore mt-3">
            {state.adts > 0 && (
              <div>
                <div className="passengerName mb-3">
                  <Row>
                    <Col className="d-flex flex-wrap">
                      {state.adtpax.length !== 0 &&
                        state.adtpax.map((adt, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{adt.pname}</span>{" "}
                              <span className="ticketNo">
                                {" "}
                                ({adt.ty}){" "}
                              </span>{" "}
                            </span>{" "}
                          </React.Fragment>
                        ))}
                        {state.chds > 0 && state.chdpax.map((chd, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{chd.pname}</span>{" "}
                              <span className="ticketNo">
                                {" "}
                                ({chd.ty}){" "}
                              </span>{" "}
                            </span>{" "}
                          </React.Fragment>
                        ))}
                        {state.infs > 0 && state.infpax.map((inf, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{inf.pname}</span>{" "}
                              <span className="ticketNo">
                                {" "}
                                ({inf.ty}){" "}
                              </span>{" "}
                            </span>{" "}
                          </React.Fragment>
                      ))}
                    </Col>
                  </Row>
                </div>
                <h5 className="layoutTitle  d-flex justify-content-between mb-3">
                  <span>Fare Details ({props.userCurrency})</span>
                </h5>
                <table class="m-0 table passDetails">
                  <tr>
                    <th width="10%">Pax Type</th>
                    <th width="10%">Fare Difference</th>
                    <th width="10%">Tax Difference</th>
                    <th width="10%">Markup</th>
                    <th width="10%">Service Fee</th>
                    <th width="10%">Penalty</th>
                    <th width="10%">Residual Value</th>
                    {/* <th width="10%">Supplier Fee</th> */}
                    {/* {context.logindata.region_type === "India" && (
                      <th width="10%">GST</th>
                    )} */}
                    <th width="10%">Sub Total Charge</th>
                    <th width="10%">No. of Adults</th>
                    <th width="10%">Total Charge</th>
                  </tr>
                  <tr>
                    <td>
                      <span>ADT</span>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtbase}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adttaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adttaxes}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtmarkup === "" ? 0 : state.adtmarkup}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtsf === "" ? 0 : state.adtsf}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtPenalty === "" ? 0 : state.adtPenalty}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtResVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={true}
                          value={state.adtResVal}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    {/* <td><Form.Group as={Col}  controlId="adtsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={""} disabled={true}/>
                        </Form.Group></td> */}
                    {/* {context.logindata.region_type === "India" && (
                      <td>
                        <Form.Group as={Col} controlId="adt_attgst" className="mb-0 px-0">
                          <Form.Control
                            type="number"
                            min={0}
                            placeholder="GST"
                            autoComplete="off"
                            value={state.adt_attgst}
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                    )} */}
                    <td>
                      <span>{state.adttotal}</span>
                    </td>
                    <td>
                      <span>{state.adts}</span>
                    </td>
                    <td>{state.adttotalamt}</td>
                  </tr>

                  {state.chds !== 0 &&
                  <tr>
                    <td>
                      <span>CHD</span>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdbase}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdtaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdtaxes}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdmarkup}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdsf}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.chdPenalty}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdResVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={true}
                          value={state.chdResVal}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>

                    {/* <td>
                      <Form.Group as={Col} controlId="infsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="Service Fee"
                          autoComplete="off"
                          value={""}
                          disabled={true}
                        />
                      </Form.Group>
                    </td> */}
                    {/* {context.logindata.region_type === "India" && (
                      <td>
                        <Form.Group as={Col} controlId="chd_attgst" className="mb-0 px-0">
                          <Form.Control
                            type="number"
                            min={0}
                            placeholder="GST"
                            autoComplete="off"
                            value={state.chd_attgst}
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                    )} */}
                    <td>
                      <span>{state.chdtotal}</span>
                    </td>
                    <td>
                      <span>{state.chds}</span>
                    </td>
                    <td>{state.chdtotalamt}</td>
                  </tr>
                  }

                  {state.infs !== 0 && (
                  <tr>
                    <td>
                      <span>INF</span>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infbase}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="inftaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.inftaxes}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infmarkup}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infsf}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.infPenalty}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infResidualVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="Residua Value"
                          autoComplete="off"
                          disabled={true}
                          value={state.infResidualVal}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>

                    {/* <td>
                      <Form.Group as={Col} controlId="infsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="Service Fee"
                          autoComplete="off"
                          value={0}
                          disabled={true}
                        />
                      </Form.Group>
                    </td> */}
                    {/* {context.logindata.region_type === "India" && (
                      <td>
                        <Form.Group as={Col} controlId="inf_attgst" className="mb-0 px-0">
                          <Form.Control
                            type="number"
                            min={0}
                            placeholder="GST"
                            autoComplete="off"
                            value={state.inf_attgst}
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                    )} */}
                    <td>
                      <span>{state.inftotal}</span>
                    </td>
                    <td>
                      <span>{state.infs}</span>
                    </td>
                    <td>{state.inftotalamt}</td>
                  </tr>
                  )}
                </table>
              </div>
            )}
          </div>
          </div>

          
          <div className="ticketSummerynew d-flex justify-content-between">
            {state.adts > 0 && (
              <div className="commonWrap">
                <h5 className="layoutTitle d-flex justify-content-between ticketSummaryTitle">
                  Ticket Summary Supplier
                </h5>
                <div className="table-responsive">
                  <table class="m-0 table">
                    <tr>
                      <th width="16%">Pax Type</th>
                      <th width="16%">Sub Total x Pax</th>
                      <th width="16%">
                        Supplier Changes
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <span>ADT</span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotal)} x {state.adts}
                        </span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotal) * Number(state.adts)}
                        </span>
                      </td>
                    </tr>
                    {state.chds > 0 && (
                      <tr>
                        <td>
                          <span>CHD</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotal)} x {state.chds}
                          </span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotal) * Number(state.chds)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {state.infs > 0 && (
                      <tr>
                        <td>
                          <span>INF</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotal)} x {state.infs}
                          </span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotal) * Number(state.infs)}
                          </span>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td></td>
                      <td align="right" className="pr-0 brandColor">Total Charges :</td>
                      <td className="brandColor">
                        {Number(state.adttotalamt) +
                        Number(state.chdtotalamt) +
                        Number(state.inftotalamt)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
            {state.adts > 0 && (
              <div className="commonWrap">
                <h5 className="layoutTitle d-flex justify-content-between ticketSummaryTitle">
                  Ticket Summary Customer
                </h5>
                <div className="table-responsive">
                  <table class="m-0 table">
                    <tr>
                      <th width="16%">Passenger Type</th>
                      <th width="16%">Sub Total x Pax</th>
                      <th width="16%">Customer Changes</th>
                    </tr>
                    <tr>
                      <td>
                        <span>ADT</span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotalta)} x {state.adts}
                        </span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotalta) * Number(state.adts)}
                        </span>
                      </td>
                    </tr>
                    {state.chds > 0 && (
                      <tr>
                        <td>
                          <span>CHD</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotalta)} x {state.chds}
                          </span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotalta) * Number(state.chds)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {state.infs > 0 && (
                      <tr>
                        <td>
                          <span>INF</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotalta)} x {state.infs}
                          </span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotalta) * Number(state.infs)}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td align="right" className="pr-0 brandColor">Total Charges :</td>
                      <td className="brandColor">
                        {Number(state.adttotalamtta) +
                        Number(state.chdtotalamtta) +
                        Number(state.inftotalamtta)}{" "}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
          </div>
            {state.enablePayment && (
              <div className="cardMainlayout minHight0 mannualReissuet justify-content-between">
                {/* <ManualPaymentForm payobj={state.payData} callBooking={confirmManualBooking}/> */}
              </div>
            )}

            <div>
            <div className="commonWrap">
              <label className="paymentMode">
                <input
                  type="radio"
                  value={paymentActive}
                  checked={paymentActive === "payment"}
                  onChange={() => handleRadioChange("payment")}
                  className="paymentModeInput"
                />
                Continue with payment
              </label>
              <label className="paymentMode">
                <input
                  type="radio"
                  value={paymentActive}
                  checked={paymentActive === "remarks"}
                  onChange={() => handleRadioChange("remarks")}
                  className="paymentModeInput ml-5"
                />
                Continue without payment
              </label>

              
              {paymentActive === "payment" && (
                
                <PaymentDetails
                  sendPaymentinfo={updatePayment}
                  region_type={state.region_type}
                  booking_id={props.bookingId}
                  type="MANUAL/REISSUE"
                />
                
              )}

              {paymentActive === "remarks" && (
               <>
               <h5 class="layoutTitle  d-flex justify-content-between mb-3"><span>Remarks<sup>*</sup></span></h5>
                <textarea
                  value={remarks}
                  onChange={handleRemarksChange}
                  placeholder="Enter remarks"
                  className="remarksArea"
                  maxLength={100}
                />
               </>
              )}
              </div>
            </div>
          <div className="d-flex justify-content-end footerWrap">
            <div className="text-right mr-3">
              <Button
               className="btn btn-outline-secondary"
                onClick={() => cancel()}
                type="submit"
              >
                Cancel
              </Button>
            </div>

            {!isLoad ? (
              <div className="text-right">
                <Button
                  variant="primary"
                  onClick={() => continueBooking()}
                  type="submit"
                  isDisabled={btnDisable ? true : false}
                >
                  Submit
                </Button>
              </div>
            ) : (
              <div className="progress progress-striped active mt-0">
                <div role="progressbar progress-striped" className="progress-bar">
                  <span>Processing...</span>
                </div>
              </div>
            )}
          </div>
      </div>
    </>
  );
}
export default ShowManualPnrData;
