/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-18 00:16:44
 * @ Description: This is to fetch the Airlines Object with the help of airline code
 */

import React, { useContext } from "react";
import { AirlinesContext } from "../../../App";
import { Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';

/**
 *  For the airlines object fetching with respective to the code received
 */
function AirlinesMemo({ code, isEdit, sendoperatingAirline }) {
  const airlinesInfo = useContext(AirlinesContext);

  let name = "";
  let selectedValue = {};
  if (
    airlinesInfo !== undefined &&
    airlinesInfo.filter((obj) => obj.value === code)[0]
  ) {
    name = airlinesInfo.filter((obj) => obj.value === code)[0].label;
    selectedValue = airlinesInfo.filter((obj) => obj.value === code)
  }

  const handleChange = (data) => {
    sendoperatingAirline(data)
  }
  
  return (
    <>
    {!isEdit ? name : 
      <Form.Group className="selectplugin editOne m-0" as={Col} controlId="operating_airline">
        <Select
            options={airlinesInfo}
            values={selectedValue}
            onChange={(value) => handleChange(value)}
        />
      </Form.Group>
    }
    </>
  );
}

export default AirlinesMemo;
