import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

function PaymentHistoryPopup({ history_data, close }) {
  const [show, setShow] = useState(false);

  const columns = [
    {
      dataField: "expiry_date",
      text: "Expiry Date",
    },

    {
      dataField: "payment_link",
      text: "Payment Link",
    },
  ];

  const handleClose = () => {
    setShow(false);
    close();
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="paymentLinkPopup">
        <Modal.Header closeButton>
          <Modal.Title>Payment Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {history_data.length !== 0 ? 
          <div className="cardMainlayout tableRes p-0 noMarginPadd">
            <div className="tablescroll">
              <BootstrapTable
                bootstrap4
                keyField="expiry_date"
                data={history_data}
                classes="markup filterTbl"
                columns={columns}
                filter={filterFactory()}
              />
            </div>
          </div>
          :
          <span className="ndFound">No Data Found</span>}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PaymentHistoryPopup;
