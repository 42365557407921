/**
 * @ Author: Jamaluddin
 * @ Create Time: 2023-07-25 17:05:26
 * @ Description: Common Loader with Logo
 */

import React from "react";
import LoaderLogo from "../../../assets/images/N_FOREVER.gif";

function LoaderN(props) {
  return (
    <>

      <div className="loaderN">
        <div className="loaderN-cont">
          <span className="loaderN-logo">
            <small>
              <img src={LoaderLogo} className="LoaderLogo" />{" "}
            </small>{" "}
            <div className="customLoader"></div>
          </span>
          <div className="dests">
            <strong>{props.title}</strong>
          </div>
          <div className="note">{props.subtitle}</div>
        </div>
      </div>

    </>
  );
}

export default LoaderN;
