
import React, { useReducer, useState,useEffect } from 'react';
import { Col, Form,Alert } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../common/Button';
import ManageBookingService from "../../../services/ManageBookingService";
import DateUtils from "../../common/DateUtils";
/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
    is_pay_uniq_id:true,is_bank_transfer:false,is_cheque:false,pay_uniq_id:"",paid_amount:"",
    bank_name:"",ac_number:"",cust_name:"",trans_number:"",total_amt:"",
    che_bank_name:"",che_ac_number:"",che_trans_number:"",che_total_amt:"",date_of_transfer:""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function PaymentDetails(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoad, setIsLoad] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    useEffect(() => {
        props.sendPaymentinfo(state)
    }, [state])
   
    const clearData=(type)=>{
        if(type==="Bank"){
        dispatch({ type: 'bank_name', payload: "" });
        dispatch({ type: 'ac_number', payload: "" }); 
        dispatch({ type: 'cust_name', payload: "" });
        dispatch({ type: 'total_amt', payload: "" }); 
        dispatch({ type: 'trans_number', payload: "" });  
        }else{
        dispatch({ type: 'che_bank_name', payload: "" });
        dispatch({ type: 'che_ac_number', payload: "" }); 
        dispatch({ type: 'che_total_amt', payload: "" }); 
        dispatch({ type: 'che_trans_number', payload: "" });
        }
    }
    
    const handlePayTypes = (type) => {
            if(type==="PAY"){
            if (state.is_pay_uniq_id){
                dispatch({ type: 'is_pay_uniq_id', payload: false });
                dispatch({ type: 'pay_uniq_id', payload: "" });
                dispatch({ type: 'paid_amount', payload: "" });
              } else {
                dispatch({ type: 'is_pay_uniq_id', payload: true });
              }
            }else if(type==="Bank"){
                if (state.is_bank_transfer){
                    dispatch({ type: 'is_bank_transfer', payload: false });
                    clearData("Bank")
                } else {
                    dispatch({ type: 'is_bank_transfer', payload: true });
                }
            }else if(type==="Cheque"){
                if (state.is_cheque){
                    dispatch({ type: 'is_cheque', payload: false });
                    clearData("Cheque")
                } else {
                    dispatch({ type: 'is_cheque', payload: true });
                }
            }
      }
      
    const handleChangePnr = (evt) => {
        const { id, value } = evt.target;
        dispatch({ type: id, payload: value });
    }

      const hidemessage = () => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    //Retriveing the PNR Details
  const checkPayment = () => {
    if (state.pay_uniq_id !== "" && props.region_type) {
      dispatch({ type: 'isItineraryLoading', payload: true })
      setIsLoad(true);
      var payload;
      if(props.type === "MANUAL/REISSUE") {
        payload = {
          pay_uniq_id:state.pay_uniq_id,
          region_type:props.region_type,
          booking_id:props.booking_id,
          type:props.type
        }
      } else if(props.type === "REISSUE") {
        payload = {
          pay_uniq_id:state.pay_uniq_id,
          region_type:props.region_type,
          booking_id:props.booking_id,
          type:props.type
        }
      } else {
        payload = {
          pay_uniq_id:state.pay_uniq_id,
          region_type:props.region_type
        }
      }
      ManageBookingService.checkPayment(payload).then((resp) => {
        if (resp.data.suc) {
          dispatch({ type: 'paid_amount', payload: resp.data.res.paid_amt +" "+resp.data.res.cur })
          dispatch({ type: 'isItineraryLoading', payload: false })
          setIsLoad(false)
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Successfully fetched Payment' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          state.paid_amount=resp.data.res.paid_amt;
          props.sendPaymentinfo(state)
          hidemessage();
        } else {
          dispatch({ type: 'isItineraryLoading', payload: false })
          setIsLoad(false)
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Unable to fetch the Payment' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        }
      })
    }else if(state.pay_uniq_id===""){
        setIsLoad(false)
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Enter Pay Unique id' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
    }else if(props.region_type===""){
        setIsLoad(false)
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Region is Mandatory to Fetch the Payment' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
    }
  }

  const handleissueDate = (date) => {
    const convertDate = (date !== null ? DateUtils.convertStringToDate(date) : null);
    dispatch({ type: 'date_of_transfer', payload: convertDate })
    state.date_of_transfer=convertDate;
    props.sendPaymentinfo(state)
}

    return (
        <>
              {state.notiMessageShow &&
               <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
               }
            <>
            <div className='BookingDetails'>


            
            <h5 className="layoutTitle  d-flex justify-content-between mb-3"><span>Mode of Payment</span></h5>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="ttype" className="inputCheck pt-0 mb-0">
              <div className="d-flex align-items-center">
                <div className='payIdu'>
                <Form.Check type="checkbox" id="pay" label="Pay UID" className="ml-0" name="applicableOn" checked={state.is_pay_uniq_id} custom onChange={()=>handlePayTypes("PAY")} />
               </div>
                <div className='payIdu'>
                <Form.Check type="checkbox" id="bank" label="Bank Transfer" name="applicableOn" checked={state.is_bank_transfer} custom onChange={()=>handlePayTypes("Bank")} />
                </div>
                <div className='payIdu'>
                <Form.Check type="checkbox" id="check" label="Cheque" name="applicableOn" checked={state.is_cheque} custom onChange={()=>handlePayTypes("Cheque")} />
                </div>
              </div>
            </Form.Group>
            </Form.Row>
            <br/>
            {state.is_pay_uniq_id &&
            <>
            <div className='PayUec PayUecWrap'>
            <h6 className="layoutTitle  d-flex justify-content-between mb-3"><span>Pay UID</span></h6>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="pay_uniq_id" className="selectplugin pb-0 mb-0">
                <Form.Label>Pay Unique Id <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Pay Unique Id" value={state.pay_uniq_id}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <div className="text-right fetchUdib">
                {!isLoad?<Button
                    size="xs"
                    variant="secondary"
                    onClick={() => checkPayment()}
                    type="submit"
                >Fetch UID</Button>:
                <div class="progress progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Fetching..</span>
                </div>
                </div>}
            </div>
            <Form.Group as={Col} xs={2} controlId="insertPnr" className="selectplugin pb-0 mb-0">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" disabled={true}  value={state.paid_amount}  autoComplete="off"/>
            </Form.Group>
           
            </Form.Row>
            </div>
            </>
            }
            {state.is_bank_transfer &&
            <>
               <div className='PayUec'>
            <h6 className="layoutTitle  d-flex justify-content-between mb-3"><span>Bank Transfer</span></h6>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="bank_name" className="selectplugin">
                <Form.Label>Bank Name <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Bank Name" value={state.bank_name}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="ac_number" className="selectplugin">
                <Form.Label>A/C Number <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter A/C Number" value={state.ac_number}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="total_amt" className="selectplugin">
                <Form.Label>Amount<sup>*</sup></Form.Label>
                <Form.Control type="number" placeholder="Enter Paid Amount"   onChange={handleChangePnr} value={state.total_amt}
                    autoComplete="off"/>
            </Form.Group>
            </Form.Row>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="cust_name" className="selectplugin pb-0 mb-0">
                <Form.Label>Customer Name <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Customer Name" value={state.cust_name}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="trans_number" className="selectplugin pb-0 mb-0">
                <Form.Label>Pay Trans Number <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Pay Trans Number" value={state.trans_number}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="insertPnr" className="selectplugin datePicker pb-0 mb-0">
                <Form.Label>Date of Transfer<sup>*</sup></Form.Label>
                <DatePicker
                    value={state.date_of_transfer}
                    onChange={date => handleissueDate(date)}
                    fromDate={state.date_of_transfer}
                    toDate={toDate}
                    placeholderText="DD-MM-YYYY"
                    maxDate={new Date()}
                    minDate={new Date(2020, 1, 1)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                 <span className="calendar ezyIcon icon-newcalendar"></span>
            </Form.Group>
            </Form.Row>
            </div>
            </>
            }
            {state.is_cheque &&
            <>
                 <div className='PayUec'>
            <h6 className="layoutTitle  d-flex justify-content-between mb-3"><span>Cheque</span></h6>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="che_bank_name" className="selectplugin pb-0 mb-0">
                <Form.Label>Bank Name <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Bank Name" value={state.che_bank_name}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="che_ac_number" className="selectplugin pb-0 mb-0">
                <Form.Label>A/C Number <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter A/C Number" value={state.che_ac_number}
                    autoComplete="off" onChange={handleChangePnr} />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="che_trans_number" className="selectplugin pb-0 mb-0">
                <Form.Label>Cheque Number<sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter Cheque number"   onChange={handleChangePnr} value={state.che_trans_number}
                    autoComplete="off"/>
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="che_total_amt" className="selectplugin pb-0 mb-0">
                <Form.Label>Amount<sup>*</sup></Form.Label>
                <Form.Control type="number" placeholder="Enter Paid Amount"   onChange={handleChangePnr} value={state.che_total_amt}
                    autoComplete="off"/>
            </Form.Group>
            </Form.Row>
            </div>
            </>
            }
            </div>
            </>
            </>
        
    )
}
export default PaymentDetails