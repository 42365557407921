import React, { useEffect, useContext, useState, useReducer } from "react";
import { Context } from "../../../App";
import { Table, Row, Col, Button, Modal, Form } from "react-bootstrap";
import FormateCurrency from "../../utilities/FormatCurrency";
import Utilities from "../../utilities/Utilities";
import { isArrayNotEmpty } from "../../utilities/validators";
export const EDIT_FARE_DETAILS = "editFareDetails";
export const EDIT_INITIAL_FARE_DATA = "editinitialFareData";
export const EDIT_INITIAL_ONWARDFARE_DATA = "editinitialOnwardFareData";
export const EDIT_INITIAL_RETURNFARE_DATA = "editinitialReturnFareData";

/**
 * @author Praveen Varma
 * @date 30-11-2023
 * @description Flight booking summary fare data
 * @param {*} props 
 * @returns 
 */

/**
* Initial State Declaration
*/
const initialState = {
    notiMessageShow: false, notiMessage: '', notiVarient: ''
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, notiMessageShow: false, notiMessage: '', notiVarient: ''
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function FlightSummaryFare({ book_info, itiData, base_usr_exrate, isEdit, processFareEditBooking, trvl_type, btnLoader }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [fareData, setFareData] = useState([]);
    const [fareEdit, setFareEdit] = useState(false);
    const [showEditpop, setShowEditpop] = useState(false);

    useEffect(() => {
        setFareEdit(false);
    }, [isEdit])

    useEffect(() => {
        setFareData([]);
        if (itiData && itiData.fare_info && itiData.fare_info.length > 0) {
            if(itiData.fare_info[0].pasngrs && itiData.fare_info[0].pasngrs.length > 0){
                if(itiData.fare_info[0].pasngrs[0].pax && itiData.fare_info[0].pasngrs[0].pax.length > 0){
                    setFareData(itiData.fare_info[0].pasngrs[0].pax);
                }
            }
        }
    }, []);

    //Calculating the Total Sales Amount
    const totalSales = (data) => {
        let total = 0;
        if (isArrayNotEmpty(data)) {
            for (let val of data) {
                if (total === 0) {
                    total = Utilities.addPrice(val.sales.total, 0, "Add");
                } else {
                    total = Utilities.addPrice(total, val.sales.total, "Add");
                }
            }
        }
        return total * (base_usr_exrate ? base_usr_exrate : 1);
    }

    const totalPurchase = (data, type) => {
        let total = 0;
        if (isArrayNotEmpty(data)) {
            for (let val of data) {
                if (total === 0) {
                    total = Utilities.addPrice(val.purchase.total, 0, "Add");
                } else {
                    total = Utilities.addPrice(total, val.purchase.total, "Add");
                }
            }
        }
        return total * (base_usr_exrate ? base_usr_exrate : 1);
    }

    const handleFareEdit = () => {
        localStorage.removeItem(EDIT_FARE_DETAILS);
        setFareEdit(true);
    }

    const cancelEdit = () => {
        localStorage.removeItem(EDIT_FARE_DETAILS);
        setFareEdit(false);
        if(trvl_type === "Onward") {
            var fareInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_ONWARDFARE_DATA));
            setFareData(fareInfo);
        } else {
            var fareInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_RETURNFARE_DATA));
            setFareData(fareInfo);
        }
    }

    const callEditBooking = () => {
        setShowEditpop(true);
    }
    
    const oncloseEidt = () => {
        setShowEditpop(false);
      }

      const processEditBookingFlow = () => {
        // if (state.enableEdit) {
        //   processEditBookingFlowOnward();
        // } else if (state.enableEditBooking) {
        //   processEditBookingSplitReturn();
        // }
      }

      const handleFareChange = (data,type,fareType) => (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
        localStorage.removeItem(EDIT_FARE_DETAILS);
        if(fareData.length!==0){
            for(let fare of fareData){
                if(fare.trv_id===data.trv_id){
                    if(fareType === "invoice") {
                        if(type==="basefare"){
                            fare.purchase.basefare=value;
                            fare.changed=true;
                        } else if(type==="taxes"){
                            fare.taxes=value;
                            fare.changed=true;
                            fare.sales.total=Number(fare.sales.basefare) + Number(fare.taxes) + Number(fare.sales.markup) +  Number(fare.sales.servicefee) - Number(fare.sales.disc);
                        } 
                        fare.purchase.total=Number(fare.purchase.basefare) + Number(fare.taxes);
                    } else {
                        fare.sales.total=fare.sales.total
                        if(type==="basefare"){
                            fare.sales.basefare=value;
                            fare.changed=true;
                        } else if(type==="taxes"){
                            fare.taxes=value;
                            fare.changed=true;
                            fare.purchase.total=Number(fare.purchase.basefare) + Number(fare.taxes);
                        } else if(type==="markup"){
                            fare.sales.markup=value;
                            fare.changed=true;
                        } else if(type==="servicefee"){
                            fare.sales.servicefee=value;
                            fare.changed=true;
                        } 
                        fare.sales.total=Number(fare.sales.basefare) + Number(fare.taxes) + Number(fare.sales.markup) + Number(fare.sales.servicefee) - Number(fare.sales.disc);
                    }          
                   }
            }
            localStorage.setItem(EDIT_FARE_DETAILS,JSON.stringify(fareData));
       }

    }

    const callFareEditBooking = () => {
        processFareEditBooking(trvl_type, "fare");
    }

    return (
        <>

        <div className="fareTablemHead overSummery marginTop30">
                <div className="editIcontil">
                    <h5 class="title">Fare Details {book_info?.cur ? `(${book_info?.cur})` : ""}</h5>
                    <div className="editBtnr d-flex">
                        {isEdit && <Button className="d-flex" onClick={handleFareEdit}>Edit <span className="calendar ezyIcon icon-edit ml-2"></span></Button>}
                    </div>
                </div>
            {fareData && fareData.length > 0 &&
                <Row>
                    <Col className="fareTableLayout">
                        <Table className="fareTable noHover">
                            
                            <thead>
                                <div className="purcTitle">
                            <h6 className="title"><span>Invoice</span></h6>
                            </div>
                                <tr>
                                     <th>Traveller</th>
                                    <th>Type</th>
                                   { book_info.bktype=="REISSUE"?<th>Fare Difference</th>:<th>Base Fare</th>}
                                    {book_info.bktype=="REISSUE"?<th>Tax Difference</th>:<th>Taxes</th>}
                                    {book_info.bktype=="REISSUE" && <th className="text-right">Penalty</th>}
                                    <th className="text-right">Payable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="space"> </td>
                                </tr>
                                {fareData.map((pr, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            <span className="textOverFlow150" title={pr?.paxname?.toUpperCase()}>{pr?.paxname?.toUpperCase()}</span>
                                        </td>
                                        <td>
                                            {pr.ptype === "ADT" &&
                                                <span>Adult</span>
                                            }
                                            {pr.ptype === "CHD" &&
                                                <span>Child</span>
                                            }
                                            {pr.ptype === "INF" &&
                                                <span>Infant</span>
                                            }
                                        </td>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.purchase.basefare * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="basefare">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "basefare", "invoice")} value={pr.purchase.basefare * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}
                                            </span>

                                        </td>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.taxes * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="basefare">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "taxes", "invoice")} value={pr.taxes * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}
                                            </span>
                                        </td>
                                        {book_info.bktype=="REISSUE" && <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.penalty} />}</span>
                                        </td>}
                                        <td className="tdborder text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(pr.purchase.total * (base_usr_exrate ? base_usr_exrate : 1), 0, "Add")} />}</span>
                                        </td>

                                    </tr>
                                ))}
                                <tr className="alltotal">
                                    <td colSpan="2"></td>
                                    <td colSpan="9" className="text-right tdborder"><strong><span className="totalSp">Total Purchases :</span> {<FormateCurrency value={totalPurchase(fareData)} />}
                                    </strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="fareTable noHover">
                            <thead>
                            <div className="purcTitle">
                            <h6 className="title"><span>Sales</span></h6>
                            </div>
                                <tr>
                                { book_info.bktype=="REISSUE"?<th>Fare Difference</th>:<th>Base Fare</th>}
                                    {book_info.bktype=="REISSUE"?<th>Tax Difference</th>:<th>Taxes</th>}
                                    <th>Markup</th>
                                    <th>Service Fee</th>
                                    <th>Discount</th>
                                    {/* <th>PLB</th> */}
                                    {book_info.bktype=="REISSUE" && <th className="text-right">Penalty</th>}
                                    <th className="text-right">Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td colSpan="7" className="space">
                                </td></tr>
                                {fareData.map((pr, idx) => (
                                    <tr key={idx}>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.sales.basefare * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="basefare">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "basefare")} value={pr.sales.basefare * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}
                                            </span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.taxes * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="basefare">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "taxes")} value={pr.taxes * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}
                                            </span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.sales.markup * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="markup">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "markup")} value={pr.sales.markup * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}</span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{!fareEdit ? <FormateCurrency value={pr.sales.servicefee * (base_usr_exrate ? base_usr_exrate : 1)} /> : 
                                            <Form.Row>
                                                <Form.Group controlId="markup">
                                                    <Form.Control type="text" placeholder="" onChange={handleFareChange(pr, "servicefee")} value={pr.sales.servicefee * (base_usr_exrate ? base_usr_exrate : 1)} />
                                                </Form.Group>
                                            </Form.Row>}</span>
                                        </td>

                                        <td className="tdborder">
                                            <span> {<FormateCurrency value={pr.sales.disc * (base_usr_exrate ? base_usr_exrate : 1)} />}</span>
                                        </td>
                                        {/* <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.sales.plb * (base_usr_exrate ? base_usr_exrate : 1)} />}</span>
                                        </td> */}
                                        {book_info.bktype=="REISSUE" && 
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.sales.penalty} />}</span>
                                        </td>
                                        }

                                        <td className="tdborder text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(pr.sales.total * (base_usr_exrate ? base_usr_exrate : 1), 0, "Add")} />}</span>
                                        </td>
                                    </tr>
                                ))}
                                <tr className="alltotal">
                                    <td colSpan="11" className="text-right tdborder"><strong><span className="totalSp">Total Sales :</span> {<FormateCurrency value={totalSales(fareData)} />}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
                
                        
            {fareEdit &&
            <div className="text-right buttonGrop border-0 pt-0">
                <Button
                size="xs"
                className="mr-3"
                variant="outline-secondary"
                type="submit"
                onClick={cancelEdit}
                > Cancel</Button>                    
                {!btnLoader? 
                    <Button
                    size="xs"
                    variant="primary"
                    type="submit"
                    onClick={callFareEditBooking}
                    > Update</Button>
                    :                    
                    <div class="progress progress-striped active mt-0" style={{float:"right"}}>
                        <div role="progressbar progress-striped" class="progress-bar">
                        <span>Updating..</span>
                        </div>
                    </div>
                }
            </div>
            }
                
                        
            {showEditpop &&
            <Modal
            show={showEditpop}
            onHide={oncloseEidt}
            className="importPNRMdl editpnrPop"
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton className="border-bottom-0">
                <div className="popUplayout center">
                <div className="icons warning"></div>
                <strong>Are you sure! You want to proceed with the Edit Booking? </strong>
                </div>

            </Modal.Header>
            <Modal.Footer className="justify-content-center border-top-0 pb-4">
                <Button
                size="xs"
                variant="secondary"
                onClick={oncloseEidt}
                type="submit"
                >No</Button>
                <Button
                size="xs"
                variant="primary"
                onClick={processEditBookingFlow}
                type="submit"
                >Yes</Button>
            </Modal.Footer>
            </Modal>
        }
             </div>
        </>
    )
}

export default FlightSummaryFare;