import React from "react";
import { Table } from "react-bootstrap";
import Utilities from "../../utilities/Utilities";

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:22-05-2024
 * @author:Ubedullah
 *
 */
function RefundDetails(props) {
  const calculateRefundSup = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.refundsup, "Add");
      }, 0);

    return Number(total);
  };

  const calculateRefundCL = (price) => {
    const total = price.reduce((prev, t) => {
      return Utilities.addPrice(prev, t.subtotal, "Add");
    }, 0);

    return Number(total);
  };

  return (
    <div className="recordInfo">
      {props.tarefundData.length !== 0 && (
        <Table className="m-0 refDetailsc">
          <thead>
            <tr>
              <th>Passenger Name</th>
              <th>Passenger Type</th>
              <th>Base Fare</th>
              <th>Taxes</th>
              <th>Markup</th>
              <th className="canclInfo">Service Fee</th>
              <th className="canclInfo">Airline Charges</th>
              <th className="canclInfo">Supplier Charges</th>
              <th className="canclInfo">Refund Markup</th>
              <th className="canclInfo">Refund Fee</th>
              <th className="canclInfo">Other Charges</th>
              <th className="canclInfo">Fare Used</th>
              <th>Sub Total</th>
              <th>Refund from Supplier</th>
              <th>Refund to TA</th>
            </tr>
          </thead>

          <tbody>        
            {props.tarefundData.map((tapr, idx) => (
              <tr>
                <td>
                  <span>{tapr.paxname.toUpperCase()}</span>
                </td>
                <td>
                  {tapr.ptype === "ADT" && <span>Adult</span>}
                  {tapr.ptype === "CHD" && <span>Child</span>}
                  {tapr.ptype === "INF" && <span>Infant</span>}
                </td>
                <td>
                  <span>{Number(tapr.basefare).toFixed(2)}</span>
                </td>
                <td>
                  <span>
                    {Number(tapr.taxes) +
                      Number(tapr.bk_tot_gst) +
                      Number(tapr.bk_tot_tds)}
                  </span>
                </td>
                <td>
                  <span>{Number(tapr.bkmarkup).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.bkservice).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.aircharge).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.supcharge).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.canmarkup).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.canservice).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.ocharges).toFixed(2)}</span>
                </td>
                <td className="canclInfo">
                  <span>{Number(tapr.fareused).toFixed(2)}</span>
                </td>
                <td>
                  <span>{Number(tapr.subtotal).toFixed(2)}</span>
                </td>
                <td>
                  <span>{Number(tapr.refundsup).toFixed(2)}</span>
                </td>
                <td>
                  <span>{Number(tapr.subtotal).toFixed(2)}</span>
                </td>
              </tr>
            ))}
            <tr className="totalAmount">
              <td colSpan="12"></td>
              <td>
                <strong>Total :</strong>
              </td>
              <td>
                {" "}
                <strong> {calculateRefundSup(props.tarefundData)}</strong>
              </td>
              <td>
                {" "}
                <strong> {calculateRefundCL(props.tarefundData)}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
}
export default RefundDetails;
