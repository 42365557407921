import React ,{useState} from "react";
import { Col, Row, Container, Form, Dropdown, DropdownButton, Nav  } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FlightNewBook from "./FlightNewBook";
import FlightPostBook from "./FlightPostBook";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Sidebarmenu from "../../home/SideBar";




function Managebookings() {
  const [manangeBook, setManageBook] = useState({
      active: 'NewBookingTab'
  });



  
  return (
      <>

      <Container fluid>
        <Header />

        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu active="Flight"/>

          </Col>
          <Col xs={10}  className="mainftr">

            <div className="cardMainlayout mangeBooking mb-0">
              <FlightNewBook />

            </div>
            <Footer />
          </Col>
        </Row>

      </Container>
      </>
  )
}
export default Managebookings;