import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../App";
import Utilities from "../../utilities/Utilities";
import RefundDetails from "./RefundDetails";

function FlightSummaryRefund(props) {
  const [context, setContext] = useContext(Context);
  const [attPriceList, setAttPriceList] = useState([]);
  const [taPriceList, setTaPriceList] = useState([]);
  const [refundRes, setRefundRes] = useState(props.refundResp);

  useEffect(() => {
    if (refundRes !== undefined && refundRes.psngrs !== undefined) {
      let attFinalList = [];
      let taFinalList = [];
      let attList = [];
      let taList = [];
      for (let paxs of refundRes.psngrs) {
        for (let pax of paxs.price) {
          let att = pax.filter(
            (obj) => obj.type !== undefined && obj.type === "ATT"
          );
          attList.push(att[0]);
          let ta = pax.filter(
            (obj) => obj.type !== undefined && obj.type === "TA"
          );
          taList.push(ta[0]);
        }
      }
      let attOth = 0;
      let taOth = 0;
      for (let pax of refundRes.psngrs) {
        for (let val of pax.tktnum) {
          if (val.st === "Refund/booking" || val.st === "Refund/cancel") {
            if (attList.length !== 0) {
              attOth = val.att_ot_charges;
              createRefundPrice(
                val.pname,
                pax.ptype,
                attList,
                "ATT",
                attFinalList,
                taFinalList,
                val.att_ot_charges,
                0,
                val.mta_fare_used
              );
            }
            if (taList.length !== 0 && taList !== undefined) {
              taOth = val.ta_ot_charges;
              createRefundPrice(
                val.pname,
                pax.ptype,
                taList,
                "TA",
                attFinalList,
                taFinalList,
                val.ta_ot_charges,
                attOth,
                val.ta_fare_used
              );
            }
          }
        }
      }
    }
  }, []);

  //Calculating the TAX
  const calculateTax = (taxes) => {
    if (taxes) {
      const total = taxes.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.price, "Add");
      }, 0);
      return total;
    } else {
      return 0;
    }
  };
  //Creating the Refund Price List CNT, Customer
  const createRefundPrice = (
    pname,
    paxType,
    list,
    type,
    attFinalList,
    taFinalList,
    otherCharge,
    prevOthrCharge,
    fare_used
  ) => {
    if (paxType === "ADT" && list !== undefined && list[0] !== undefined) {
      var price = Utilities.addPrice(
        list[0].basefare,
        calculateTax(list[0].taxes),
        "Add"
      );
      var refSup = Utilities.addPrice(Number(price), list[0].aircharge, "Sub");
      const adtreq = createFareRequest(
        pname,
        paxType,
        list[0],
        refSup,
        otherCharge,
        fare_used
      );
      if (type === "ATT") {
        adtreq.refundsup = adtreq.refundsup - otherCharge - fare_used;
        attFinalList.push(adtreq);
      } else if (type === "TA") {
        adtreq.refundsup = attFinalList[0].refundsup;
        if (prevOthrCharge !== 0) {
          adtreq.aircharge = Utilities.addPrice(
            adtreq.aircharge,
            prevOthrCharge,
            "Add"
          );
          // adtreq.subtotal = Utilities.addPrice(
          //   adtreq.subtotal,
          //   prevOthrCharge,
          //   "Sub"
          // );
          adtreq.subtotal = adtreq.subtotal;
        }
        taFinalList.push(adtreq);
      } 
    } else if (paxType === "CHD") {
      let chdreq = "";
      if (list[0] !== undefined && list.length === 1) {
        var price = Utilities.addPrice(
          list[0].basefare,
          calculateTax(list[0].taxes),
          "Add"
        );
        var refSup = Utilities.addPrice(
          Number(price),
          list[0].aircharge,
          "Sub"
        );
        chdreq = createFareRequest(
          pname,
          paxType,
          list[0],
          refSup,
          otherCharge,
          fare_used
        );
      } else if (list[1] !== undefined) {
        var price = Utilities.addPrice(
          list[1].basefare,
          calculateTax(list[1].taxes),
          "Add"
        );
        var refSup = Utilities.addPrice(
          Number(price),
          list[1].aircharge,
          "Sub"
        );
        chdreq = createFareRequest(
          pname,
          paxType,
          list[1],
          refSup,
          otherCharge,
          fare_used
        );
      }
      if (type === "ATT") {
        chdreq.refundsup = chdreq.refundsup - otherCharge - fare_used;
        attFinalList.push(chdreq);
      } else if (type === "TA") {
        //chdreq.refundsup=attFinalList[0].subtotal;
        if (prevOthrCharge !== 0) {
          chdreq.aircharge = Utilities.addPrice(
            chdreq.aircharge,
            prevOthrCharge,
            "Add"
          );
          chdreq.refundsup = Utilities.addPrice(
            chdreq.refundsup,
            prevOthrCharge + fare_used,
            "Sub"
          );
          // chdreq.subtotal = Utilities.addPrice(
          //   chdreq.subtotal,
          //   prevOthrCharge,
          //   "Sub"
          // );
          chdreq.subtotal = chdreq.subtotal;
        }
        taFinalList.push(chdreq);
      }
    } else if (paxType === "INF") {
      let infreq = "";
      if (list[0] !== undefined && list.length === 1) {
        var price = Utilities.addPrice(
          list[0].basefare,
          calculateTax(list[0].taxes),
          "Add"
        );
        var refSup = Utilities.addPrice(
          Number(price),
          list[0].aircharge,
          "Sub"
        );
        infreq = createFareRequest(
          pname,
          paxType,
          list[0],
          refSup,
          otherCharge,
          fare_used
        );
      } else if (list[1] !== undefined && list.length === 2) {
        var price = Utilities.addPrice(
          list[1].basefare,
          calculateTax(list[1].taxes),
          "Add"
        );
        var refSup = Utilities.addPrice(
          Number(price),
          list[1].aircharge,
          "Sub"
        );
        infreq = createFareRequest(
          pname,
          paxType,
          list[1],
          refSup,
          otherCharge,
          fare_used
        );
      // } else if (list[2] !== undefined && list.length === 3) {
      } else if (list[2] !== undefined) {
        var price = Utilities.addPrice(
          list[2].basefare,
          calculateTax(list[2].taxes),
          "Add"
        );
        var refSup = Utilities.addPrice(
          Number(price),
          list[2].aircharge,
          "Sub"
        );
        infreq = createFareRequest(
          pname,
          paxType,
          list[2],
          refSup,
          otherCharge,
          fare_used
        );
      }
      if (type === "ATT") {
        infreq.refundsup = infreq.refundsup - otherCharge - fare_used;
        attFinalList.push(infreq);
      } else if (type === "TA") {
        //infreq.refundsup=attFinalList[0].subtotal;
        if (prevOthrCharge !== 0) {
          infreq.aircharge = Utilities.addPrice(
            infreq.aircharge,
            prevOthrCharge,
            "Add"
          );
          infreq.refundsup = Utilities.addPrice(
            infreq.refundsup,
            prevOthrCharge + fare_used,
            "Sub"
          );
          // infreq.subtotal = Utilities.addPrice(
          //   infreq.subtotal,
          //   prevOthrCharge,
          //   "Sub"
          // );
          infreq.subtotal = infreq.subtotal;
        }
        taFinalList.push(infreq);
      }
    }
    setAttPriceList(attFinalList);
    setTaPriceList(taFinalList);
  };

  //Common Fare response
  const createFareRequest = (
    pname,
    paxType,
    fare,
    supRef,
    otCharge,
    fare_used
  ) => {
    const resp = {
      paxname: pname,
      ptype: paxType,
      basefare: fare.basefare,
      bkmarkup: fare.bkmarkup,
      bkservice: fare.bkservice,
      canmarkup: fare.canmarkup,
      canservice: fare.canservice,
      aircharge: fare.aircharge,
      supcharge: fare.supcharge,
      ocharges: otCharge,
      // subtotal: fare.ttlrefund - otCharge - fare_used,
      subtotal: fare.ttlrefund,
      taxes: calculateTax(fare.taxes),
      refundsup: supRef,
      fareused: fare_used,
      bk_tot_gst: Number(fare.bk_tot_gst).toFixed(2),
      bk_tot_tds: Number(fare.bk_tot_tds).toFixed(2),
    };
    return resp;
  };

  return (
    <>
      {attPriceList.length !== 0 && taPriceList.length !== 0 && (
        <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
          <h5 class="title ">Refund Details</h5>
          <RefundDetails 
            // attrefundData={attPriceList} 
            tarefundData={taPriceList} 
          />
        </div>
      )}
    </>
  );
}

export default FlightSummaryRefund;
