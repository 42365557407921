import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Alert,
  Col,
  Form,
  Modal,
  Row,
  Button,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../App.js";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Sidebarmenu from "../../home/SideBar";
import Utilities from "../../utilities/Utilities.js";
import { useLocation } from "react-router-dom";
import RefundSummary from "./RefundSummary.js";
import { processManualRefund } from "../../dashboard/queues/operations.js";
import FormatCurrency from "../../utilities/FormatCurrency.jsx";

/**
 * Initial State Declaration
 */
const initialState = {
  taxPopup: false,
  notiMessageShow: false,
  notiMessage: "",
  notiVarient: "",
  remarks: "",
  attPaxPrice: [],
  taPaxPrice: [],
  showPopup: false,
  setPtype: "",
  enableLoader: false,
  othrPriceAdt: 0,
  othrPriceChd: 0,
  othrPriceInf: 0,
  othrPricetaAdt: 0,
  othrPricetaChd: 0,
  othrPricetaInf: 0,
  fareUPriceAdt: 0,
  fareUPriceChd: 0,
  fareUPriceInf: 0,
  fareUPricetaAdt: 0,
  fareUPricetaChd: 0,
  fareUPricetaInf: 0,
  count: 0,
};
let totalRefund = 0;
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "upprof":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
function ManualRefundRecord(props) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [othrPriceAdt, setOthrPriceAdt] = useState(0);
  const [othrPriceChd, setOthrPriceChd] = useState(0);
  const [othrPriceInf, setOthrPriceInf] = useState(0);

  const [othrPricetaAdt, setOthrPriceTAAdt] = useState(0);
  const [othrPricetaChd, setOthrPriceTAChd] = useState(0);
  const [othrPricetaInf, setOthrPriceTAInf] = useState(0);

  const [fareUPriceAdt, setfareUPriceAdt] = useState(0);
  const [fareUPriceChd, setfareUPriceChd] = useState(0);
  const [fareUPriceInf, setfareUPriceInf] = useState(0);

  const [fareUPricetaAdt, setfareUPriceTAAdt] = useState(0);
  const [fareUPricetaChd, setfareUPriceTAChd] = useState(0);
  const [fareUPricetaInf, setfareUPriceTAInf] = useState(0);

  const [airChargeAttAdt, setAirChargeAttAdt] = useState(0);
  const [airChargeAttChd, setAirChargeAttChd] = useState(0);
  const [airChargeAttInf, setAirChargeAttInf] = useState(0);

  const [serviceFeeAttAdt, setServiceFeeAttAdt] = useState(0);
  const [serviceFeeAttChd, setServiceFeeAttChd] = useState(0);
  const [serviceFeeAttInf, setServiceFeeAttInf] = useState(0);

  const [serviceFeeTaAdt, setServiceFeeTaAdt] = useState(0);
  const [serviceFeeTaChd, setServiceFeeTaChd] = useState(0);
  const [serviceFeeTaInf, setServiceFeeTaInf] = useState(0);

  const [markupAttAdt, setMarkupAttAdt] = useState(0);
  const [markupAttChd, setMarkupAttChd] = useState(0);
  const [markupAttInf, setMarkupAttInf] = useState(0);

  const [markupTaAdt, setMarkupTaAdt] = useState(0);
  const [markupTaChd, setMarkupTaChd] = useState(0);
  const [markupTaInf, setMarkupTaInf] = useState(0);

  const [prices, setprices] = useState("");
  const [chdprices, setCHDprices] = useState("");
  const [infprices, setINFprices] = useState("");
  const [type, setType] = useState("");
  const [refundData, setRequest] = useState(location?.state);
  const [resp, setResp] = useState();
  const navigate = useNavigate();
  const [refund, setRefund] = useState(location?.state.req);
  const [refundCur, setRefundCur] = useState(location?.state);
  const [pnr, setPnr] = useState("");
  //   let refund = require('../../../masterData/Reissue.json');

  const cancelRefund = () => {
    return (window.location = "/crm/flight/summary/" + refundData.bref);
  };
  //Calculating the Summary
  useEffect(() => {
    if(refund.data.psngrs[0].price?.length === 1) {
      refund.data.psngrs[0].price[1] = {...refund.data.psngrs[0].price[0], type:"TA"}
    }
    if (refund.data.psngrs !== undefined) {
      refund.data.split_pnr = refund.data.pnr;
      if (refund.data.psngrs.length !== 0) {
        for (let pax of refund.data.psngrs) {
          for (let tkt of pax.tktnum) {
            tkt.st = 42;
          }
        }
      }
      refundSummaryChanges();
    }
  }, []);

  //data to show in refund summary components
  const refundSummaryChanges = () => {
    let attList = [];
    let taList = [];
    for (let pax of refund.data.psngrs) {
      if (state.count === 0) {
        if (pax.ptype == "ADT") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              setfareUPriceAdt(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              setfareUPriceTAAdt(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            }
          }
        }

        if (pax.ptype == "CHD") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              setfareUPriceChd(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              setfareUPriceTAChd(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            }
          }
        }

        if (pax.ptype == "INF") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              setfareUPriceInf(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              setfareUPriceTAInf(
                price.fare_used && price.fare_used.price
                  ? Number(price.fare_used.price)
                  : 0
              );
            }
          }
        }
        dispatch({ type: "count", payload: 1 });
      }
      for (let price of pax.price) {
        if (price.type === "ATT") {
          var taxes = Number(calculateTax(price));
          const att = {
            ptype: pax.ptype,
            subtotal: price.subtotal,
            count: pax.count,
            refunSupPrice: Utilities.addPrice(
              price.basefare +
                taxes -
                price.aircharge -
                price.ocharges -
                price.fare_used.newprice,
              pax.count,
              "Cnt"
            ),
            refundToCl: Utilities.addPrice(price.subtotal, pax.count, "Cnt"),
          };
          attList.push(att);
        } else if (price.type === "TA") {
          const ta = {
            ptype: pax.ptype,
            subtotal: price.subtotal,
            count: pax.count,
            refunSupPrice: Utilities.addPrice(
              pax.price[0].subtotal,
              pax.count,
              "Cnt"
            ),
            refundToCl: Utilities.addPrice(price.subtotal, pax.count, "Cnt"),
          };
          taList.push(ta);
        }
      }
      pax.attlist = attList;
      pax.talist = taList;
      dispatch({ type: "attPaxPrice", payload: attList });
      dispatch({ type: "taPaxPrice", payload: taList });
    }
  };
  //Calculating the TAX
  const calculateTax = (price) => {
    const total = price.taxes.reduce((prev, t) => {
      return Utilities.addPrice(prev, t.newprice, "Add");
    }, 0);
    return total;
  };

  //Proceess Refund flow
  const procesRefundFlow = () => {
    dispatch({ type: "enableLoader", payload: true });
    refund.data.tkt_user_type = context.logindata.utype;
    refund.data.tkt_user_id = context.logindata.user_id;
    refund.data.journey_type = location?.state.journey_type;
    refund.data.type = "REFUND/BOOKING";
    refund.data.cus_code = context.logindata.cuscode;
    if (pnr !== "") {
      refund.data.split_pnr = pnr;
    }
    refund.data.region_type = location?.state.region_type;
    refund.data.req_cur = location?.state.req_cur;
    processManualRefund(refund.data).then((resp) => {
      dispatch({ type: "showPopup", payload: false });
      if (resp.suc) {
        gotoSummary(true, resp.res);
      } else {
        gotoSummary(false, resp.res);
      }
      dispatch({ type: "enableLoader", payload: false });
    });
  };

  const gotoSummary = (suc, response) => {
    if (suc) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Refund processed Successfully..",
      });
      dispatch({ type: "notiVarient", payload: "success" });
      setTimeout(() => {
        return (window.location = "summary/" + location?.state.bref);
      }, 3000);
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Unable to process the Refund for selected tickets",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      setTimeout(() => {
        navigate({
          pathname: "summary/" + location?.state.bref,
          state: {
            req: undefined,
            type: location?.state.type,
          },
        });
      }, 3000);
    }
  };

  const handleChange = (obj, idx, type) => (e) => {
    let { id, value } = e.target;
    if (value === undefined || value === "") {
      value = parseInt(0);
    }
    obj.ocharges = parseInt(value);
    if (obj.orgtotal == undefined || obj.orgtotal == "") {
      obj.orgtotal =
        obj.orgsubTtl && obj.type !== "ATT"
          ? obj.orgsubTtl
          : parseInt(obj.initVal);
    }
    if (obj.orgtotal) {
      let condt_value = 0;
      if (obj.type == "TA") {
        if (type == "ADT") {
          condt_value = parseInt(state.othrPriceAdt);
        } else if (type == "CHD") {
          condt_value = parseInt(state.othrPriceChd);
        } else if (type == "INF") {
          condt_value = parseInt(state.othrPriceInf);
        }
      }
      // parent fare used calculated
      let pfc = 0;
      if (obj.parentfareUchanged) {
        pfc = obj.parentfareUchanged;
      }
      //this calculates if fare used has been applied and changes are done to the other charges
      let fare_used = 0;
      if (obj.fareChanged) {
        fare_used = parseInt(obj.fareChanged);
      }
      obj.subtotal = parseInt(
        obj.orgtotal - (parseInt(value) + condt_value) - fare_used
      );
      obj.orgFUtotal = parseInt(obj.orgtotal - (parseInt(value) + condt_value));
    }
    if (obj.type === "ATT") {
      if (type === "ADT") {
        setOthrPriceAdt(value);
        state.othrPriceAdt = value;
        updateOtherCharges(type, "TA", value, obj.fareChanged);
      } else if (type === "CHD") {
        setOthrPriceChd(value);
        state.othrPriceChd = value;
        updateOtherCharges(type, "TA", value, obj.fareChanged);
      } else if (type === "INF") {
        setOthrPriceInf(value);
        state.othrPriceInf = value;
        updateOtherCharges(type, "TA", value, obj.fareChanged);
      }
    } else if (obj.type === "TA") {
      if (type === "ADT") {
        setOthrPriceTAAdt(value);
        state.othrPricetaAdt = value;
        updateOtherCharges(type, "STA", value, obj.fareChanged);
      } else if (type === "CHD") {
        setOthrPriceTAChd(value);
        state.othrPricetaChd = value;
        updateOtherCharges(type, "STA", value, obj.fareChanged);
      } else if (type === "INF") {
        setOthrPriceTAInf(value);
        state.othrPricetaInf = value;
        updateOtherCharges(type, "STA", value, obj.fareChanged);
      }
    }
    refundSummaryChanges();
  };
  //Calculating  the Other Charges
  const updateOtherCharges = (paxType, type, value, fareChanged) => {
    for (let pax of refund.data.psngrs) {
      if (pax.ptype === paxType) {
        for (let price of pax.price) {
          if (price.type === type) {
            let condt_value = 0;
            if (type == "TA") {
              if (paxType == "ADT") {
                condt_value =
                  parseInt(state.othrPriceAdt) + parseInt(state.othrPricetaAdt);
              } else if (paxType == "CHD") {
                condt_value =
                  parseInt(state.othrPriceChd) + parseInt(state.othrPricetaChd);
              } else if (paxType == "INF") {
                condt_value =
                  parseInt(state.othrPriceInf) + parseInt(state.othrPricetaInf);
              }
            } 
            // }
            if (price.orgairCharge == undefined || price.orgairCharge == "") {
              price.orgairCharge = parseInt(price.aircharge);
            }
            if (price.orgairCharge) {
              price.aircharge = Utilities.addPrice(
                price.orgairCharge,
                value,
                "Add"
              );
            }
            if (price.orgsubTtl == undefined || price.orgsubTtl == "") {
              price.orgsubTtl = parseInt(price.initVal);
            }
            if (value) {
              price.parentOcharges = value;
            }
            if (price.orgsubTtl) {
              let fc = 0; //farecharged for the parent type
              if (fareChanged) {
                fc = fareChanged;
              }
              let fare_used = 0; //farecharge for the type
              if (price.fareChanged) {
                fare_used = parseInt(price.fareChanged);
              }
              price.subtotal =
                parseInt(price.orgsubTtl - condt_value) - fare_used;
              price.parentfareUchanged = fareChanged ? fareChanged : 0;
              price.orgFUtotal = parseInt(price.orgsubTtl - condt_value);
            }
            break;
          }
        }
      }
    }
  };

  const handleChangeTaxes = (code, idx, type) => (e) => {
    let data = null;

    //data re-renders with according to the pax type
    if (type === "ADT") {
      data = prices;
    } else if (type === "CHD") {
      data = chdprices;
    } else if (type === "INF") {
      data = infprices;
    }
    let newResp = data.taxes.map((tax, sidx) => {
      const { id, value } = e.target;
      if (idx !== sidx && code !== tax.code) return tax;
      else {
        if (code === tax.code && idx === sidx) {
          return { ...tax, newprice: value ? parseInt(value) : 0 };
        } else {
          return tax;
        }
      }
    });
    if (type === "ADT") {
      setprices({ ...prices, taxes: newResp });
    } else if (type === "CHD") {
      setCHDprices({ ...chdprices, taxes: newResp });
    } else if (type === "INF") {
      setINFprices({ ...infprices, taxes: newResp });
    }
    for (let pax of refund.data.psngrs) {
      let ptype = pax.ptype; //refund pax type
      for (let pr of pax.price) {
        if (ptype === type) {
          //checking the refunded passenger data paxtype with the selected paxtype
          let oldTax = calculateTax(pr);
          let wild = pr.subtotal;
          pr.taxes = newResp;
          let newTax = calculateTax(pr);

          let tap = pr.subtotal;
          let orgtap = pr.subtotal;
          let poc = pr.parentOcharges ? parseInt(pr.parentOcharges) : 0;
          let ztap = parseInt(pr.initVal) - pr.ocharges;
          pr.subtotal = Utilities.addPrice(pr.subtotal - oldTax, newTax, "Add");

          if (pr.orgtotal) {
            tap = pr.orgtotal;
          }
          if (pr.orgsubTtl) {
            orgtap = parseInt(pr.initVal);
          }
          pr.orgtotal = Utilities.addPrice(tap - oldTax, newTax, "Add");
          pr.orgsubTtl = Utilities.addPrice(orgtap - oldTax, newTax, "Add");
          pr.orgFUtotal = Utilities.addPrice(
            parseInt(pr.initVal) - poc - pr.ocharges - oldTax,
            newTax,
            "Add"
          );
          pr.initVal = Utilities.addPrice(pr.initVal - oldTax, newTax, "Add");
        }
      }
    }
    //set refund summary data change in order to cross check in drop tables with updated values
    refundSummaryChanges();
    const payload = {
      type: data.type,
      basefare: data.basefare,
      totalfare: data.totalfare,
      bkmarkup: data.bkmarkup,
      bkservice: data.bkservice,
      canmarkup: data.canmarkup,
      canservice: data.canservice,
      aircharge: data.aircharge,
      supcharge: data.supcharge,
      supsfee: data.supsfee,
      ocharges: data.ocharges,
      subtotal: data.subtotal,
      ttlrefund: data.ttlrefund,
      taxes: newResp,
    };
    calculateTax(payload);
  };

  const callTaxes = (priceObj, type, utype) => () => {
    if (utype === "ADT") {
      //set priceObj to Adult
      setprices(priceObj);
      dispatch({ type: "setPtype", payload: "ADT" });
    } else if (utype === "CHD") {
      //set priceObj to Child
      setCHDprices(priceObj);
      dispatch({ type: "setPtype", payload: "CHD" });
    } else if (utype === "INF") {
      // set price obj for infant
      setINFprices(priceObj);
      dispatch({ type: "setPtype", payload: "INF" });
    }
    setType(type);
    dispatch({ type: "taxPopup", payload: true });
  };

  const showPopup = () => {
    if (pnr !== "") {
      dispatch({ type: "showPopup", payload: true });
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Enter GDS PNR" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };
  const handleCLClose = () => {
    dispatch({ type: "showPopup", payload: false });
  };

  const handleTaxClose = (type) => {
    let data = null;
    if (type === "ADT") {
      data = prices;
    } else if (type === "CHD") {
      data = chdprices;
    } else if (type === "INF") {
      data = infprices;
    }
    let valid = true;
    let newResp = data.taxes.map((tax, sidx) => {
      if (parseInt(tax.newprice) === 0) {
        valid = false;
      }
    });
    if (valid) {
      dispatch({ type: "taxPopup", payload: false });
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Taxes Should be more than 0" });
      dispatch({ type: "notiVarient", payload: "danger" });
      setTimeout(() => {
        dispatch({ type: "notiMessageShow", payload: false });
        dispatch({ type: "notiMessage", payload: "" });
        dispatch({ type: "notiVarient", payload: "" });
      }, 5000);
    }
  };

  /**
   * @description :fare used calculations
   * @author : Ubedullah
   * @date : 20-05-2024
   */
  const updateFUchanges = (obj, idx, type) => (e) => {
    let { id, value } = e.target;
    if (value === undefined || value === "") {
      value = parseInt(0);
    }
    for (let val of obj.price) {
      if (val.orgFUtotal == undefined || val.orgFUtotal == "") {
        val.orgFUtotal =
          val.orgFUtotal && val.type !== "ATT"
            ? val.orgFUtotal
            : parseInt(val.initVal);
      }
      if (val.orgFUtotal) {
        let initialVal = 0;
        //if intial time then add the price and remove the new price
        if (Number(val.fare_used.newprice) === Number(val.fare_used.price)) {
          initialVal = Number(val.fare_used.newprice);
        }
        val.subtotal = parseInt(val.orgFUtotal + initialVal - parseInt(value));

        //     // 1---> subtotal + oldfareused - comingvalue
        //     // 2===> subtotal - comingvalue
      }
      val.fare_used.newprice = parseInt(value);
      val.fareChanged = parseInt(value);
    }
    if (obj.ptype == "ADT") {
      setfareUPriceAdt(value);
    } else if (obj.ptype == "CHD") {
      setfareUPriceChd(value);
    } else {
      setfareUPriceInf(value);
    }
    refundSummaryChanges();
  };

  /**
   * @description :fare used calculations
   * @author : Ubedullah
   * @date : 20-05-2024
   */
  const updateCharges = (obj, idx, type, flowType, chtype) => (e) => {
    let { id, value } = e.target;
    if (value === undefined || value === "") {
      value = parseInt(0);
    }
    if (flowType === "Air") {
      if (type === obj.ptype) {
        for (let tkt of obj.tktnum) {
          tkt.aircharge = parseInt(value);
        }
      }
    }
    for (let val of obj.price) {
      if (val.orgFUtotal == undefined || val.orgFUtotal == "") {
        val.orgFUtotal =
          val.orgFUtotal && val.type !== "ATT"
            ? val.orgFUtotal
            // : parseInt(val.initVal);
            : Number(val.initVal);
      }
      if (val.orgFUtotal) {
        if (chtype === "ATT" && val.type === "ATT") {
          if (flowType === "Air") {
            val.subtotal = 
              val.orgFUtotal -
                val.canservice -
                val.canmarkup -
                val.ocharges -
                val.fare_used.newprice -
                parseInt(value);
            val.aircharge = parseInt(value);
            totalRefund =
              val.canservice +
              val.canmarkup +
              val.ocharges +
              val.fare_used.newprice +
              parseInt(value);
          } else if (flowType === "Service") {
            val.subtotal = 
              val.orgFUtotal -
                val.aircharge -
                val.canmarkup -
                val.ocharges -
                val.fare_used.newprice -
                parseInt(value);
            val.canservice = parseInt(value);
            totalRefund =
              val.aircharge +
              val.canmarkup +
              val.ocharges +
              val.fare_used.newprice +
              parseInt(value);
          } else if (flowType === "Markup") {
            val.subtotal = 
              val.orgFUtotal -
                val.aircharge -
                val.canservice -
                val.ocharges -
                val.fare_used.newprice -
                parseInt(value);
            val.canmarkup = parseInt(value);
            totalRefund =
              val.aircharge +
              val.canservice +
              val.ocharges +
              val.fare_used.newprice +
              parseInt(value);
          } else if (flowType === "Fare") {
            val.subtotal = 
              val.orgFUtotal -
                val.aircharge -
                val.canservice -
                val.canmarkup -
                val.ocharges -
                parseInt(value);
            val.fare_used.newprice = parseInt(value);
            val.fareChanged = parseInt(value);
            totalRefund =
              val.aircharge +
              val.canservice +
              val.ocharges +
              val.canmarkup +
              parseInt(value);
          } else if (flowType === "Other") {
            val.subtotal = 
              val.orgFUtotal -
                val.aircharge -
                val.canservice -
                val.canmarkup -
                val.fare_used.newprice -
                parseInt(value);
            val.ocharges = parseInt(value);
            totalRefund =
              val.aircharge +
              val.canservice +
              val.fare_used.newprice +
              val.canmarkup +
              parseInt(value);
          }
        } else if (val.type === chtype) {
          if (flowType === "Service") {
            val.subtotal = 
              val.orgFUtotal -
                val.canmarkup -
                val.ocharges -
                totalRefund -
                parseInt(value);
            val.canservice = parseInt(value);
          } else if (flowType === "Markup") {
            val.subtotal = 
              val.orgFUtotal -
                val.canservice -
                val.ocharges -
                totalRefund -
                parseInt(value);
            val.canmarkup = parseInt(value);
          } else if (flowType === "Other") {
            val.subtotal = 
              val.orgFUtotal -
                val.canmarkup -
                val.canservice -
                totalRefund -
                parseInt(value);
            val.ocharges = parseInt(value);
          }
        } else if (val.type === "TA") {
          // val.subtotal = parseInt(val.orgFUtotal - totalRefund);
          val.subtotal = Number(val.orgFUtotal - totalRefund - val.canmarkup -
            val.canservice - val.ocharges);
          val.fare_used.newprice = obj.price[0].fare_used.newprice;
          val.fareChanged = obj.price[0].fare_used.newprice;
        }
      }
    }
    if (obj.ptype == "ADT") {
      if (chtype === "ATT") {
        if (flowType === "Air") {
          setAirChargeAttAdt(value);
        } else if (flowType === "Service") {
          setServiceFeeAttAdt(value);
        } else if (flowType === "Markup") {
          setMarkupAttAdt(value);
        } else if (flowType === "Fare") {
          setfareUPriceAdt(value);
        } else if (flowType === "Other") {
          setOthrPriceAdt(value);
        }
      } else if (chtype === "TA") {
        if (flowType === "Service") {
          setServiceFeeTaAdt(value);
        } else if (flowType === "Markup") {
          setMarkupTaAdt(value);
        } else if (flowType === "Other") {
          setOthrPriceTAAdt(value);
        }
      }
    } else if (obj.ptype == "CHD") {
      if (chtype === "ATT") {
        if (flowType === "Air") {
          setAirChargeAttChd(value);
        } else if (flowType === "Service") {
          setServiceFeeAttChd(value);
        } else if (flowType === "Markup") {
          setMarkupAttChd(value);
        } else if (flowType === "Fare") {
          setfareUPriceChd(value);
        } else if (flowType === "Other") {
          setOthrPriceChd(value);
        }
      } else if (chtype === "TA") {
        if (flowType === "Service") {
          setServiceFeeTaChd(value);
        } else if (flowType === "Markup") {
          setMarkupTaChd(value);
        } else if (flowType === "Other") {
          setOthrPriceTAChd(value);
        }
      }
    } else {
      if (chtype === "ATT") {
        if (flowType === "Air") {
          setAirChargeAttInf(value);
        } else if (flowType === "Service") {
          setServiceFeeAttInf(value);
        } else if (flowType === "Markup") {
          setMarkupAttInf(value);
        } else if (flowType === "Fare") {
          setfareUPriceInf(value);
        } else if (flowType === "Other") {
          setOthrPriceInf(value);
        }
      } else if (chtype === "TA") {
        console.log("data", airChargeAttInf)
        if (flowType === "Service") {
          setServiceFeeTaInf(value);
        } else if (flowType === "Markup") {
          setMarkupTaInf(value);
        } else if (flowType === "Other") {
          setOthrPriceTAInf(value);
        }
      }
    }
    refundChanges(flowType);
  };

  //data to show in refund summary components
  const refundChanges = (type) => {
    let attList = [];
    let taList = [];
    for (let pax of refund.data.psngrs) {
      if (state.count === 0) {
        if (pax.ptype == "ADT") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              if (type === "Air") {
                setAirChargeAttAdt(
                  price.aircharge ? Number(price.aircharge) : 0
                );
              } else if (type === "Service") {
                setServiceFeeAttAdt(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupAttAdt(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceAdt(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceAdt(price.ocharges ? Number(price.ocharges) : 0);
              }
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              if (type === "Service") {
                setServiceFeeTaAdt(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupTaAdt(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceTAAdt(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceTAAdt(price.ocharges ? Number(price.ocharges) : 0);
              }
            }
          }
        }
        if (pax.ptype == "CHD") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              if (type === "Air") {
                setAirChargeAttChd(
                  price.aircharge ? Number(price.aircharge) : 0
                );
              } else if (type === "Service") {
                setServiceFeeAttChd(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupAttChd(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceChd(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceChd(price.ocharges ? Number(price.ocharges) : 0);
              }
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              if (type === "Service") {
                setServiceFeeTaChd(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupTaChd(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceTAChd(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceTAChd(price.ocharges ? Number(price.ocharges) : 0);
              }
            }
          }
        }
        if (pax.ptype == "INF") {
          for (let price of pax.price) {
            if (price.type == "ATT") {
              price.initVal = price.subtotal;
              if (type === "Air") {
                setAirChargeAttInf(
                  price.aircharge ? Number(price.aircharge) : 0
                );
              } else if (type === "Service") {
                setServiceFeeAttInf(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupAttInf(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceInf(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceInf(price.ocharges ? Number(price.ocharges) : 0);
              }
            } else if (price.type == "TA") {
              price.initVal = price.subtotal;
              if (type === "Service") {
                setServiceFeeTaInf(
                  price.canservice ? Number(price.canservice) : 0
                );
              } else if (type === "Markup") {
                setMarkupTaInf(price.canmarkup ? Number(price.canmarkup) : 0);
              } else if (type === "Fare") {
                setfareUPriceTAInf(
                  price.fare_used !== undefined && price.fare_used.newprice
                    ? Number(price.fare_used.newprice)
                    : 0
                );
              } else if (type === "Other") {
                setOthrPriceTAInf(price.ocharges ? Number(price.ocharges) : 0);
              }
            }
          }
        }
        dispatch({ type: "count", payload: 1 });
      }
      for (let price of pax.price) {
        if (price.type === "ATT") {
          var taxes = Number(calculateTax(price));
          const att = {
            ptype: pax.ptype,
            subtotal: price.subtotal,
            count: pax.count,
            refunSupPrice: Utilities.addPrice(
              price.basefare +
                taxes -
                price.aircharge -
                price.ocharges -
                price.canservice -
                price.canmarkup -
                price.fare_used.newprice,
              pax.count,
              "Cnt"
            ),
            refundToCl: Utilities.addPrice(price.subtotal, pax.count, "Cnt"),
          };
          attList.push(att);
        } else if (price.type === "TA") {
          const ta = {
            ptype: pax.ptype,
            subtotal: price.subtotal,
            count: pax.count,
            refunSupPrice: Utilities.addPrice(
              pax.price[0].subtotal,
              pax.count,
              "Cnt"
            ),
            refundToCl: Utilities.addPrice(price.subtotal, pax.count, "Cnt"),
          };
          taList.push(ta);
        }
      }
      pax.attlist = attList;
      pax.talist = taList;
      dispatch({ type: "attPaxPrice", payload: attList });
      dispatch({ type: "taPaxPrice", payload: taList });
    }
  };

  const handleChangePNR = (e) => {
    const { id, value } = e.target;
    setPnr(value);
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  return (
    <>
      <Container fluid>
        <Header />
        <Row>
        <Alert
              className="notification"
              variant={state.notiVarient}
              show={state.notiMessageShow}
              onClose={() =>
                dispatch({ type: "notiMessageShow", payload: false })
              }
              dismissible
            >
              {" "}
              {state.notiMessage}
            </Alert>
          <Col xs={2} className="pl-0 sidebarMain">
            <div className="sideBar">
              <Sidebarmenu />
            </div>
          </Col>
          <Col xs={10} className="bookingSearch fmanualRfund">
           

            <h5 className="pageTitle">Summary</h5>
                      <div className="cardMainlayout payPage">
              <h5 class="pageTitle">PNR Details</h5>
              <div className="transactionSummery">
                <Form>
                  <Form.Group
                    as={Col}
                    xs={3}
                    controlId="pnr"
                    className="selectplugin pl-0"
                  >
                    <Form.Label>
                      GDS PNR <sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter GDS PNR"
                      onChange={handleChangePNR}
                      value={pnr}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="confirmation refundRecord">
              {refund !== undefined &&
                refund.data.psngrs !== undefined &&
                refund.data.psngrs.map((pax, index) => (
                  <React.Fragment key={index}>
                    <div className="paxDetailsWrap commonWrap">
                      <h5 className="title d-flex">
                        Passenger Details :
                        {pax.ptype === "ADT" && (
                          <span className="d-inline-block ml-1 mr-1">Adult </span>
                        )}
                        {pax.ptype === "CHD" && (
                          <span className="d-inline-block ml-1  mr-1">Child</span>
                        )}
                        {pax.ptype === "INF" && (
                          <span className="d-inline-block ml-1  mr-1">Infant</span>
                        )}{" "}
                          X {" "}
                        {pax.count}  {index === 0 && refund.rtype}
                      </h5>

                      <div class="passengerName mb-3">
                        <Row>
                          <Col className="d-flex flex-wrap">
                        
                              {pax.tktnum.map((tkt, index) => (
                            <>
                                  {" "}
                                  <span class="passName"> {tkt.pname}{" "}</span>
                                  <span className="ticketNo"> (Tkt# : {tkt.orgTktNum})</span>
                                  </>
                              ))}
                      
                        
                            {/* <span class="d-flex mr-3">
                                <span class="passName">Sayed Ahemd Khan</span>{" "}
                                <span class="ticketNo"> (TKT#: 910-1702313108) </span>{" "}
                                </span> */}
                          </Col>
                        </Row>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          <h5 class="layoutTitle  d-flex justify-content-between mb-3">
                            Refund Details ({refundCur.req_cur})
                          </h5>

                          <Row className="m-0">
                            <Col xs={12} className="p-0">
                              <div className="bookingListinfo refundScc">
                                <table class="m-0 table passDetailsrefund">
                                  {pax.price.map((price, idx) => (
                                    <React.Fragment key={idx}>
                                      {idx === 0 && (
                                        <thead>
                                          <tr className="cancellatioFr">
                                            <th colSpan={5}>&nbsp;</th>
                                            <th colSpan={6}>
                                              <span className="canRefund">
                                                Cancellation
                                              </span>
                                            </th>
                                            <th>&nbsp;</th>
                                          </tr>
                                          <tr>
                                            <th width="10%">Company Name</th>
                                            <th width="10%">Base Fare</th>
                                            <th width="10%">Supplier Fee</th>
                                            <th width="10%">Markup</th>
                                            <th width="10%">Service Fee</th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Airline Charges
                                            </th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Supplier Charges
                                            </th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Refund Fee
                                            </th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Refund Markup
                                            </th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Other Charges
                                            </th>
                                            <th
                                              width="10%"
                                              className="tdborder"
                                            >
                                              Fare Used{" "}
                                            </th>
                                            <th width="10%" className="d-flex">
                                              Taxes
                                              <span
                                                className="showtax mt-1 ml-2"
                                                onClick={callTaxes(
                                                  price,
                                                  price.type,
                                                  pax.ptype
                                                )}
                                              >
                                                
                                                <span className="ezyIcon icon-info"></span>
                                              </span>
                                            </th>
                                            <th className="text-right">
                                              Sub Total Refund
                                            </th>
                                            <th className="noa">
                                              {pax.ptype === "ADT" && (
                                                <span>No. Of Adult</span>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <span>No. Of Child</span>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <span>No. Of Infant</span>
                                              )}
                                            </th>
                                            <th className="text-right">
                                              Total Refund
                                            </th>
                                          </tr>
                                        </thead>
                                      )}
                                      {/* ATT Login */}
                                      <tr className="firstRowr">
                                        <td>
                                          <span>{price.type === "ATT" ? "CNT" : "Customer"}</span>
                                        </td>
                                        <td>
                                          <span>{price.basefare}</span>
                                        </td>
                                        <td>
                                          {price.type === "ATT" && (
                                            <span>0.00</span>
                                          )}
                                          {price.type === "TA" && (
                                            <span>
                                              {pax.price[0].bkservice}
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <span>{price.bkmarkup}</span>
                                        </td>
                                        <td>
                                          <span>{price.bkservice}</span>
                                        </td>

                                        <td className="tdborder">
                                          {price.type === "ATT" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="airChargeAttAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={airChargeAttAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Air",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="airChargeAttChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={airChargeAttChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Air",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="airChargeAttInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={airChargeAttInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Air",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                          {price.type === "TA" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={
                                                      Number(airChargeAttAdt) +
                                                      Number(serviceFeeAttAdt) +
                                                      Number(markupAttAdt) +
                                                      Number(othrPriceAdt)
                                                    }
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={
                                                      Number(airChargeAttChd) +
                                                      Number(serviceFeeAttChd) +
                                                      Number(markupAttChd) +
                                                      Number(othrPriceChd)
                                                    }
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={
                                                      Number(airChargeAttInf) +
                                                      Number(serviceFeeAttInf) +
                                                      Number(markupAttInf) +
                                                      Number(othrPriceInf)
                                                    }
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                        </td>
                                        <td className="tdborder">
                                          <span>{price.supcharge}</span>
                                        </td>
                                        <td className="tdborder">
                                          {price.type === "ATT" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="serviceFeeAttAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeAttAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="serviceFeeAttChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeAttChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="serviceFeeAttInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeAttInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                          {price.type === "TA" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="serviceFeeTaAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeTaAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="serviceFeeTaChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeTaChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={serviceFeeTaInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Service",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                        </td>
                                        <td className="tdborder">
                                          {price.type === "ATT" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupAttAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupAttAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupAttChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupAttChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupAttInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupAttInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                    disabled={true}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                          {price.type === "TA" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupTaAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupTaAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupTaChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupTaChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="markupTaInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={markupTaInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Markup",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                        </td>
                                        <td className="tdborder">
                                          {price.type === "ATT" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPriceAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPriceAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPriceChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPriceChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPriceInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPriceInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}

                                          {price.type === "TA" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPricetaAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPricetaAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPricetaChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPricetaChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="othrPricetaInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={othrPricetaInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Other",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                        </td>

                                        <td className="tdborder">
                                          {price.type === "ATT" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="fareUPriceAdt"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={fareUPriceAdt}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Fare",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="fareUPriceChd"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={fareUPriceChd}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Fare",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="fareUPriceInf"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    value={fareUPriceInf}
                                                    onChange={updateCharges(
                                                      pax,
                                                      index,
                                                      pax.ptype,
                                                      "Fare",
                                                      price.type
                                                    )}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}

                                          {price.type === "TA" && (
                                            <Form.Row>
                                              {pax.ptype === "ADT" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={fareUPriceAdt}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "CHD" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={fareUPriceChd}
                                                  />
                                                </Form.Group>
                                              )}
                                              {pax.ptype === "INF" && (
                                                <Form.Group
                                                  as={Col}
                                                  controlId="ocharges"
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={true}
                                                    value={fareUPriceInf}
                                                  />
                                                </Form.Group>
                                              )}
                                            </Form.Row>
                                          )}
                                        </td>
                                        <td>
                                          <span>{calculateTax(price)}</span>
                                        </td>
                                        <td className="text-right">
                                          <span>{<FormatCurrency value={price.subtotal} /> }</span>
                                        </td>
                                        <td className="text-center noa">
                                          <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                          <span>
                                            {Utilities.addPrice(
                                              price.subtotal,
                                              pax.count,
                                              "Cnt"
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </table>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              <Row>
                {/* ATT Login */}
                <>
                  {state.attPaxPrice && state.attPaxPrice !== 0 && (
                    <RefundSummary
                      priceData={state.attPaxPrice}
                      type={"Supplier"}
                      flow={"Refund"}
                      cur={refundCur.req_cur}
                    />
                  )}
                  {state.taPaxPrice && state.taPaxPrice.length !== 0 && (
                    <RefundSummary
                      priceData={state.taPaxPrice}
                      type={"Customer"}
                      flow={"Refund"}
                      cur={refundCur.req_cur}
                    />
                  )}
                </>
              </Row>
              <div className="text-right buttonGrop mt-4">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  type="submit"
                  onClick={cancelRefund}
                  className="mr-3"
                >
                  {" "}
                  Cancel Refund
                </Button>
                <Button
                  size="xs"
                  variant="primary"
                  type="submit"
                  onClick={showPopup}
                >
                  {" "}
                  Process Refund
                </Button>
              </div>

              <Modal
                show={state.showPopup}
                onHide={handleCLClose}
                className="importPNRMdl requiredFare d-flex justify-content-center"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header>
                  <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <div className="d-flex justify-content-center">
                      <strong>
                        Are you sure, you want to process the refund?
                      </strong>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Footer className="justify-content-center">
                  {state.enableLoader ? (
                    ""
                  ) : (
                    <Button variant="outline-secondary" onClick={handleCLClose}>
                      Close
                    </Button>
                  )}
                  <Button
                    size="xs"
                   variant="primary"
                    type="submit"
                    onClick={procesRefundFlow}
                  >
                    {" "}
                    {!state.enableLoader ? "Yes" : "Processing..."}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={state.taxPopup}
                onHide={() => handleTaxClose(state.setPtype)}
                className="importPNRMdl satusUpdate otherRefund"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                <h5 class="modal-title">Other Taxes</h5>
           
                </Modal.Header>
                <Modal.Body>
                <div className="popUplayout center">
                    {prices !== "" &&
                      prices.taxes !== undefined &&
                      state.setPtype === "ADT" &&
                      prices.taxes.map((tax, index) => (
                        <div className="d-flex">
                          <Form.Row className="mb-3">
                            <span> {tax.code} </span>
                            <Form.Group
                              as={Col}
                              controlId="othrPrice"
                              className="d-flex align-items-center justify-content-between mb-0"
                            >
                              <Form.Control
                                type="text"
                                value={tax.newprice}
                                disabled={!tax.refundable}
                                onChange={handleChangeTaxes(
                                  tax.code,
                                  index,
                                  state.setPtype
                                )}
                              />
                            </Form.Group>
                            <span> {tax.refundable ? "(R)" : "(NR)"} </span>
                          </Form.Row>
                        </div>
                      ))}
                    {chdprices !== "" &&
                      chdprices.taxes !== undefined &&
                      state.setPtype === "CHD" &&
                      chdprices.taxes.map((tax, index) => (
                        <div className="d-flex">
                          <Form.Row>
                            <span> {tax.code} </span>
                            <Form.Group
                              as={Col}
                              controlId="othrPrice"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <Form.Control
                                type="text"
                                value={tax.newprice}
                                disabled={!tax.refundable}
                                onChange={handleChangeTaxes(
                                  tax.code,
                                  index,
                                  state.setPtype
                                )}
                              />
                            </Form.Group>
                            <span> {tax.refundable ? "(R)" : "(NR)"} </span>
                          </Form.Row>
                        </div>
                      ))}
                    {infprices !== "" &&
                      infprices.taxes !== undefined &&
                      state.setPtype === "INF" &&
                      infprices.taxes.map((tax, index) => (
                        <div className="d-flex">
                          <Form.Row>
                            <span> {tax.code} </span>
                            <Form.Group
                              as={Col}
                              controlId="othrPrice"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <Form.Control
                                type="text"
                                value={tax.newprice}
                                disabled={!tax.refundable}
                                onChange={handleChangeTaxes(
                                  tax.code,
                                  index,
                                  state.setPtype
                                )}
                              />
                            </Form.Group>
                            <span> {tax.refundable ? "(R)" : "(NR)"} </span>
                          </Form.Row>
                        </div>
                      ))}
                  </div>
        </Modal.Body>
              </Modal>
            </div>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ManualRefundRecord;
