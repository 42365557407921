import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
const CRM_URL = process.env.REACT_APP_CRM_ENGINE_URL
const B2C_ENGINE_URL = process.env.REACT_APP_B2C_ENGINE_URL
const B2C_CNTENT_URL = process.env.REACT_APP_CNT_HOTEL_CNT_URL

/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations service
 * @param {*} Service 
 */
class ManageBookingService {
    
    async searchBookings(obj) {
        return await axios.post(`${API_URL}/att`, obj);
    }

    async retreieveSearch(obj,token) {
        // const obj={
        //     searchCriteria:data
        // }
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'auth-token': token
            }
         };
        console.log(obj)
        return await axios.post(`${API_URL}/common/airports`,obj,headers);
    };

    async retriveRecentSearch(id) {
        return await axios.get(`${API_URL}/common/fetchPredictive/${id}`);
    }

    async fetchUserProfile(req) {
        return await axios.post(`${CRM_URL}/crmc/user/fetch-profile`,req)
    }

    async fetchTravellers(req,token){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': token
              }
           };
        return await axios.post(`${B2C_ENGINE_URL}/cnt/user/b2cgettraveller`,req,headers)
    }

    async getPNRDetails(req) {
        return await axios.post(`${CRM_URL}/crmf/postbook/pnr-retrieve`,req)
    }

    async loadOfficeIdList(data) {
        return await axios.post(`${CRM_URL}/crmf/opt/fetch-ofid`,data)
    }

    async checkPayment(payId) {
        return await axios.post(`${CRM_URL}/crmc/payment/fetch_payinvoice`,payId)
    }
    
    async saveBooking(obj) {
        return await axios.post(`${CRM_URL}/crmf/opt/managebook`,obj)
    }

    async hotelSearch(data) {
        return await axios.get(`${B2C_CNTENT_URL}/cnt/search/`+data+'?skip=0&limitCity=10&limitProperty=50');
    };

    async saveHotelManualBooking(obj) {
        return await axios.post(`${CRM_URL}/crmh/opt/manualbook`,obj)
    }
    
}
export default new ManageBookingService()