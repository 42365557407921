import React, { useEffect, useState, useContext, useReducer } from "react";
import { Col, Row } from "react-bootstrap";
import { Context } from "../../../App";
import DateUtils from "../../common/DateUtils";
import Utilities from "../../utilities/Utilities";
import { isArrayNotEmpty, isNotNull } from "../../utilities/validators";
import Airpalne from "../../../../src/assets/images/airplane.png";

/**
 * Initial State Declaration
 */
const initialState = {
    source: '', destination: '', duration: '', travel_type: '', departure_date: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        default:
            return { ...state, [action.type]: action.payload };
    }
};

/**
 * @author Praveen Varma
 * @date 27-11-2023
 * @description Sector Header Info
 * @param {*} props 
 * @returns 
 */
function SectorHeader({ itiData, trvl_type, mappings }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);

    useEffect(() => {
        if (itiData && itiData.seg_det && itiData.seg_det.length > 0) {
            dispatch({ type: 'source', payload: itiData.seg_det[0].seg_source });
            dispatch({ type: 'destination', payload: itiData.seg_det[itiData.seg_det.length - 1].seg_destination });
            dispatch({ type: 'departure_date', payload: DateUtils.prettyDate(itiData.seg_det[0].depart_time, "ddd, DD MMM, YYYY") })
            dispatch({ type: 'travel_type', payload: trvl_type ? (trvl_type === "Onward" ? "Departure" : "Arrival") : "" });
            let duartionn = '';
            if (itiData.seg_det[0].duration) {
                let duration = itiData.seg_det[0].duration;
                if (Number(duration)) {
                    duartionn = duration < 10 ? " 0" + duration + "h 00m" : duration + "h 00m"
                }
                if (duration.includes(":")) {
                    let durTag = itiData.seg_det[0].duration.split(":")
                    if (durTag.length > 0) {
                        duartionn = durTag[0] + "h " + durTag[1] + "m"
                    }
                }
                dispatch({ type: 'duration', payload: duartionn })
            }
        }
    }, []);


    


    return (
        <>
        {itiData && itiData.seg_det && itiData.seg_det.length > 0 &&
        <div class="ltag lcc fcc ezyIcon icon-nFlight">{itiData?.seg_det?.[0].supplier !== "0" ? "LCC": "FSC"} </div>
        }
        <div className="rs-headerBlock mt-3 mb-4">
            
            <Row className="align-items-center rs-row">
                <Col xs={12} className="rs-rTypeDate">
                    {/* <span className={state.travel_type === "Return" ? "departure arrival" : "departure "} >
                         {state.travel_type}</span> */}
                         <img src={Airpalne} className="ml-2 mr-2 ezyIcon icon-flight"></img>
                         <span className="route">{Utilities.getAirportdata(mappings, state.source, 'c')} </span>
                         <span className="ezyIcon icon-rightArrow"></span>
                         <span className="route">{Utilities.getAirportdata(mappings, state.destination, 'c')} </span>
                         <span className="date">{state.departure_date}</span>
                </Col>
                {/* <Col xs={7} className="rs-FromTo">
                    <Row className="align-items-center">
                        <Col className="d-flex flex-column">
                            <span>{Utilities.getAirportdata(mappings, state.source, 'c')} ({state.source}),</span>
                            <span>{Utilities.getAirportdata(mappings, state.source, 'a')}</span>
                        </Col>
                        <Col xs="1" className="p-0">    <img src={Airpalne} className="ml-2 mr-2 ezyIcon icon-flight"></img></Col>
                        <Col className="d-flex flex-column">
                            <span>{Utilities.getAirportdata(mappings, state.destination, 'c')} ({state.destination}),</span>
                            <span>{Utilities.getAirportdata(mappings, state.destination, 'a')}</span>
                        </Col>
                    </Row>
                    <span>
                    </span>
                </Col> */}
                {/* <Col xs={2}><span>Trip Duration : {state.duration}</span></Col> */}
            </Row>
        </div>
        </>
       
    )
}

export default SectorHeader;