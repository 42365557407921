import React, { useState, useContext, useReducer, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../common/Header";
import Sidebarmenu from "../../home/SideBar";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Footer from "../../common/Footer";
import { Context } from "../../../App";
import {
  getPaymentData,
  resendPaymentLink,
  loadPaymentLinksHistory,
} from "./operations";
import DateUtils from "../../common/DateUtils";
import CreatePayment from "./CreatePayment";
import log from "loglevel";
import PaymentHistoryPopup from "./PaymentHistoryPopup";
export const USER_AUTH_DATA = "userAuthData";

let bookingTypeOptions = require('../../masterData/BookingType.json');

/**
 * @description: Initial state
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function Payment Configuration
 */

const initialState = {
  prdt: "",
  b_type: "",
  region: "",
  payment_status: "",
};

// Reducer function for useReducer Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    case "editRecord":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function Payments() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [paymentData, setPaymentData] = useState([]);
  const [id, setId] = useState(0);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [dataCalled, setDataCalled] = useState(true);
  const [disableFormFields, setDisableFormFields] = useState(false);
  const [show, setShow] = useState(false);
  const [resendPayLinkObj, setResendPayLinkObj] = useState();
  const [showPayUniqId, setShowPayUniqId] = useState(false);
  const [payUniqId, setPayUniqId] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [createPayment, setCreatePayment] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [editForm, setEditForm] = useState(false);
  const [error, setError] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [resetRegion, setResetRegion] = useState(true);
  const [resetProduct, setResetProduct] = useState(true);
  const [resetBookingType, setResetBookingType] = useState(true);
  const [resetPaymentStatus, setResetPaymentStatus] = useState(true);
  const [loadSave, setLoadSave] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const regionOptions = [
    { label: "India", value: "India" },
    { label: "Qatar", value: "Qatar" },
  ];

  const productOptions = [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" },
  ];

  // const bookingTypeOptions = [
  //   { label: "Manual", value: "Manual" },
  //   { label: "Reissue", value: "Reissue" },
  //   { label: "Amendament", value: "Amendament" },
  //   { label: "Cancel", value: "Cancel" },
  // ];

  const paymentTypeOptions = [
    { label: "Confirmed", value: "Confirmed" },
    { label: "Pending", value: "Pending" },
    { label: "Failed", value: "Failed" },
  ];

  useEffect(() => {
    const payload = {
      region_type:
        context.logindata !== undefined &&
        context.logindata?.region_type !== undefined
          ? context.logindata?.region_type
          : "",
      user_id: context.logindata !== undefined ? context.logindata?.user_id : 0,
      cid: context?.logindata?.cmp_id || undefined,
      type: "ALL",
    };
    if (context?.logindata?.is_admin) {
      payload.is_admin = true;
    }
    setIsLoad(true);
    getPaymentData(payload).then((response) => {
      console.log("PAYMENT INVOICE DATA", response);
      if (response?.suc) {
        loadResponse(response?.res);
      }
    });
  }, [dataCalled, loadSave]);

  const loadResponse = (response) => {
    var enableEdit = false;
    var enableResend = false;
    if (context.logindata !== "") {
      if (context?.logindata?.roles?.includes("P_T_I_E")) {
        enableEdit = true;
      }
      if (context?.logindata?.roles?.includes("P_T_I_R_L")) {
        enableResend = true;
      }
    } else {
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      if (userData?.roles?.includes("P_T_I_E")) {
        enableEdit = true;
      }
      if (userData?.roles?.includes("P_T_I_R_L")) {
        enableResend = true;
      }
    }
    let data = [];
    for (let val of response) {
      data.push({
        pay_unique_id: val.payment_unique_id,
        booking_type: val.booking_type,
        product: val.product,
        bk_id: val.booking_id ? val.booking_id : "N/A",
        customer_name: val.customer_name,
        email_id: val.email,
        pnone_no: "+" + val.phone_code + " - " + val.phone_number,
        pay_amount: val.currency + " " + val.amount,
        created_by: val.created_by,
        modified_by: val.modified_by,
        // exp_date:
        //   val.expiry_date !== null
        //     ? DateUtils.convertStringToDate(val.expiry_date) +
        //       " " +
        //       val.expiry_date?.split(" ")[4]
        //     : null,
        exp_date: val.expiry_date !== null && val.expiry_date,
        pay_status: val.status,
        resend: (
          <>
            <Button
              className="ezyIcon payMent"
              onClick={regeneratePayLink(val)}
              disabled={
                val.status?.toUpperCase() === "CONFIRMED"
                  ? true
                  : enableResend
                  ? false
                  : true
              }
            >
              Resend Link
            </Button>
          </>
        ),
        options: (
          <>
            <span className="tbAction">
              <Button
                disabled={enableEdit ? false : true}
                className="ezyIcon icon-edit"
                onClick={editPaymentData(val, "Edit")}
              ></Button>
            </span>
          </>
        ),
      });
    }
    if (response.length !== 0) {
      setPaymentData(data);
      setIsLoad(false);
    }
  };

  const editPaymentData = (obj, type) => () => {
    if (type == "Edit") {
      setEditRecord(obj);
      setCreatePayment(true);
      setEditForm(true);
      setEnableUpdate(true);
      setId(obj.id);
      dispatch({ type: "product", payload: obj.product });
      dispatch({ type: "region_type", payload: obj.region_type });
      dispatch({ type: "bk_type", payload: obj.booking_type });
      dispatch({ type: "booking_id", payload: obj.booking_id });
      dispatch({ type: "amt", payload: obj.amount });
      dispatch({ type: "cus_name", payload: obj.customer_name });
      dispatch({ type: "email_id", payload: obj.email });
      dispatch({ type: "phc", payload: obj.phone_code });
      dispatch({ type: "phno", payload: obj.phone_number });
      dispatch({ type: "remarks", payload: obj.remarks });
      if (obj.status?.toUpperCase() === "CONFIRMED") {
        setDisableFormFields(true);
      }
      setShowPayUniqId(true);
      setPayUniqId(obj.payment_unique_id);
    }
  };

  const regeneratePayLink = (obj) => () => {
    setShow(true);
    setResendPayLinkObj(obj);
  };

  const handleClose = () => {
    setShow(false);
  };

  const updatePaymentLink = () => {
    try {
      const payload = {
        payment_unique_id: resendPayLinkObj.payment_unique_id,
        payment_link: resendPayLinkObj.payment_link,
      };
      resendPaymentLink(payload).then((response) => {
        if (response?.suc) {
          setNotiMessageShow(true);
          setNotiMessage("Payment link updated successfully");
          setNotiVarient("success");
          hidemessage();
          setDataCalled(!dataCalled);
          setShow(false);
        } else {
          setNotiMessageShow(true);
          setNotiMessage("Payment link not updated");
          setNotiVarient("success");
          hidemessage();
        }
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const loadPayLinks = (row) => {
    let payload = {
      payment_unique_id: row.pay_unique_id,
    };
    loadPaymentLinksHistory(payload)
      .then((response) => {
        if (response?.suc) {
          setHistoryData(response.res ? response.res[0].links : []);
          setHistoryPopup(true);
        } else {
          setHistoryData([]);
          setHistoryPopup(true);
        }
      })
      .catch(function (error) {
        log.error("Exception occured in payment links function---" + error);
      });
  };

  // const defaultSorted = [
  //   {
  //     dataField: "pay_unique_id",
  //     order: "asc",
  //   },
  // ];


  const getColor = (pay_status) => {
    if (pay_status) {
      if (pay_status === "CONFIRMED") {
        return "Confirmed"
      } else if (pay_status === "PENDING") {
        return "Pending"
      } else if (pay_status === "FAILED") {
        return "failed"
      } 
    }
  }

  const columns = [
    {
      dataField: "pay_unique_id",
      text: "Pay Unique Id",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "booking_type",
      text: "Booking Type",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "product",
      text: "Product",
      sort: true,
      filter: textFilter(),
      editable: false,
      formatter: (cell, row) => {
        return (
          <span
            className={row?.product == "Flight" ? "icon-Flight" : "icon-Hotel"}
          >
            {row?.product}
          </span>
        );
      },
    },
    {
      dataField: "bk_id",
      text: "Booking Ref",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "email_id",
      text: "Email Id",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "pnone_no",
      text: "Phone no",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "pay_amount",
      text: "Pay Amount",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "modified_by",
      text: "Modified By",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "pay_status",
      text: "Payment Status",
      sort: true,
      filter: textFilter(),
      editable: false,
      formatter: (cell, row) =>{
        return <span className={getColor(row?.pay_status)}>{row?.pay_status}</span>;
      }

    },
    {
      dataField: "exp_date",
      text: "Expiry Date",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "pmt_links",
      text: "History",
      formatter: (cell, row) => getPaymentLinks(cell, row),
    },
    {
      dataField: "resend",
      text: "Resend",
    },
    {
      dataField: "options",
      text: "Action",
    },
  ];

  const closeHistoryStatus = () => {
    setHistoryPopup(false);
  };

  const getPaymentLinks = (cell, row) => {
    return (
      <>
        <Form.Row
          className="selectplugin"
          as={Col}
          xs={3}
          controlId="payment_links"
        >
          <Button className="payMent" onClick={(e) => loadPayLinks(row)}>
            Payment Links
          </Button>
        </Form.Row>
      </>
    );
  };

  const handleFieldChange = (field, value) => {
    // dispatch({ type: "UPDATE_FIELD", field, value });
    if (field === "prdt") {
      setResetProduct(true);
      dispatch({ type: "prdt", payload: value });
    }
    if (field === "region") {
      setResetRegion(true);
      dispatch({ type: "region", payload: value });
    }
    if (field === "b_type") {
      setResetBookingType(true);
      dispatch({ type: "b_type", payload: value });
    }
    if (field === "payment_status") {
      setResetPaymentStatus(true);
      dispatch({ type: "payment_status", payload: value });
    }
  };

  const validate = () => {
    if (
      state.region === "" &&
      state.prdt === "" &&
      state.b_type === "" &&
      state.payment_status === "" &&
      fromDate == "" &&
      toDate == ""
    ) {
      setNotiMessageShow(true);
      setNotiMessage("Please select atleast one search criteria and proceed");
      setNotiVarient("danger");
      hidemessage();
      return false;
    } else if (fromDate !== "" && toDate !== "" && fromDate === toDate) {
      setNotiMessageShow(true);
      setNotiMessage("From date and To date cannot be same.");
      setNotiVarient("danger");
      hidemessage();
      return false;
    }
    return true;
    // if (state.region == "") {
    //   setError(true);
    //   return false;
    // } else if (state.prdt == "") {
    //   setError(true);
    //   return false;
    // } else if (state.b_type === "") {
    //   setError(true);
    //   return false;
    // } else if (state.payment_status === "") {
    //   setError(true);
    //   return false;
    // } else if (fromDate === "") {
    //   setError(true);
    //   return false;
    // } else if (toDate === "") {
    //   setError(true);
    //   return false;
    // }
    // return true;
  };

  const resetSearch = () => {
    setResetRegion(false);
    setResetProduct(false);
    setResetBookingType(false);
    setResetPaymentStatus(false);
    dispatch({ type: "prdt", payload: "" });
    dispatch({ type: "region", payload: "" });
    dispatch({ type: "b_type", payload: "" });
    dispatch({ type: "payment_status", payload: "" });
    setFromDate("");
    setToDate("");
  };

  const handleSearch = () => {
    if (validate()) {
      try {
        const payload = {
          region_type: state.region,
          product: state.prdt,
          bk_type: state.b_type,
          pay_type: state.payment_status,
          fmdate:
            fromDate && fromDate !== ""
              ? DateUtils.convertStringToDate(fromDate)
              : undefined,
          todate:
            toDate && toDate !== ""
              ? DateUtils.convertStringToDate(toDate)
              : undefined,
          type: "ADVANCE",
        };
        setIsLoad(true);
        getPaymentData(payload).then((response) => {
          console.log("PAYMENT INVOICE DATA", response);
          if (response?.suc) {
            loadResponse(response?.res);
          }
        });
      } catch (error) {
        setIsLoad(false);
        console.error("Error:", error);
      } finally {
      }
    }
  };

  const handlePaymentCreate = () => {
    setCreatePayment(true);
    setEditForm(false);
    setLoadSave(false);
  };

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu active="Payment" />
          </Col>
          <Col xs={10} className="mainftr">
            <Row>
              {createPayment ? (
                <CreatePayment
                  back={setCreatePayment}
                  edit={editForm}
                  formData={editRecord}
                  fetchData={setLoadSave}
                />
              ) : (
                <>
                  <Col className="bookingSearch pb-0 mb-0">
                    {notiMessageShow && (
                      <Alert
                        className="notification alert"
                        variant={notiVarient}
                        show={notiMessageShow}
                        onClose={() => setNotiMessageShow(false)}
                        style={{ width: "100%" }}
                        dismissible
                      >
                        {" "}
                        {notiMessage}
                      </Alert>
                    )}
                    <h5 className="pageTitle">Search Payments</h5>
                    <div className="submenu">
                      <div className="cardMainlayout payPage">
                        <Form.Row>
                          {context?.logindata?.region_type?.includes(",") && (
                            <Form.Group
                              controlId="regionDropdown"
                              as={Col}
                              xs={3}
                              className="selectplugin"
                            >
                              <Form.Label>Region</Form.Label>
                              <Select
                                options={regionOptions}
                                placeholderText="Select"
                                value={
                                  resetRegion &&
                                  regionOptions.find(
                                    (option) => option.value === state.region
                                  )
                                }
                                onChange={(selectedOption) =>
                                  handleFieldChange(
                                    "region",
                                    selectedOption.value
                                  )
                                }
                                classNamePrefix="filter"
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#F8F9FF",
                                    primary: "#3156ab",
                                    cursor: "pointer",
                                  },
                                })}
                                placeholder="Select"
                              />
                              <small style={{ color: "red" }}>
                                {error &&
                                  state.region === "" &&
                                  "Please select Region"}
                              </small>
                            </Form.Group>
                          )}

                          <Form.Group
                            controlId="productDropdown"
                            as={Col}
                            xs={3}
                            className="selectplugin"
                          >
                            <Form.Label>Product</Form.Label>
                            <Select
                              options={productOptions}
                              placeholderText="Select"
                              value={
                                resetProduct &&
                                productOptions.find(
                                  (option) => option.value === state.prdt
                                )
                              }
                              onChange={(selectedOption) =>
                                handleFieldChange("prdt", selectedOption.value)
                              }
                              classNamePrefix="filter"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#F8F9FF",
                                  primary: "#3156ab",
                                  cursor: "pointer",
                                },
                              })}
                              placeholder="Select"
                            />
                            <small style={{ color: "red" }}>
                              {error &&
                                state.prdt === "" &&
                                "Please select Product"}
                            </small>
                          </Form.Group>

                          <Form.Group
                            controlId="bookingTypeDropdown"
                            as={Col}
                            xs={3}
                            className="selectplugin"
                          >
                            <Form.Label>Booking Type</Form.Label>
                            <Select
                              placeholder="Select"
                              options={bookingTypeOptions}
                              value={
                                resetBookingType &&
                                bookingTypeOptions.find(
                                  (option) => option.value === state.b_type
                                )
                              }
                              onChange={(selectedOption) =>
                                handleFieldChange(
                                  "b_type",
                                  selectedOption.value
                                )
                              }
                              classNamePrefix="filter"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#F8F9FF",
                                  primary: "#3156ab",
                                  cursor: "pointer",
                                },
                              })}
                            />
                            <small style={{ color: "red" }}>
                              {error &&
                                state.b_type === "" &&
                                "Please select Booking type"}
                            </small>
                          </Form.Group>

                          <Form.Group
                            controlId="paymentTypeDropdown"
                            as={Col}
                            xs={3}
                            className="selectplugin"
                          >
                            <Form.Label>Payment Status</Form.Label>
                            <Select
                              placeholder="Select"
                              options={paymentTypeOptions}
                              value={
                                resetPaymentStatus &&
                                paymentTypeOptions.find(
                                  (option) =>
                                    option.value === state.payment_status
                                )
                              }
                              onChange={(selectedOption) =>
                                handleFieldChange(
                                  "payment_status",
                                  selectedOption.value
                                )
                              }
                              classNamePrefix="filter"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#F8F9FF",
                                  primary: "#3156ab",
                                  cursor: "pointer",
                                },
                              })}
                            />
                            <small style={{ color: "red" }}>
                              {error &&
                                state.payment_status === "" &&
                                "Please select Payment status"}
                            </small>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin datePicker mb-0"
                            controlId="fromDate"
                          >
                            <Form.Label>Payment From</Form.Label>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date) => setFromDate(date)}
                              fromDate={fromDate}
                              toDate={toDate}
                              placeholderText="MM/DD/YYYY"
                              maxDate={new Date()}
                              popperModifiers={{
                                flip: {
                                  behavior: ["bottom"], // don't allow it to flip to be above
                                },
                                preventOverflow: {
                                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                  enabled: false, // turn off since needs preventOverflow to be enabled
                                },
                              }}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <small style={{ color: "red" }}>
                              {error &&
                                fromDate === "" &&
                                "Please select from date"}
                            </small>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin datePicker datepicleft mb-4"
                            controlId="toDate"
                          >
                            <Form.Label>Payment To</Form.Label>
                            <DatePicker
                              selected={toDate}
                              onChange={(date) => setToDate(date) + 1}
                              fromDate={fromDate}
                              toDate={toDate}
                              minDate={fromDate ? fromDate : null}
                              className="datefield"
                              placeholderText="MM/DD/YYYY"
                              popperModifiers={{
                                flip: {
                                  behavior: ["bottom"], // don't allow it to flip to be above
                                },
                                preventOverflow: {
                                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                  enabled: false, // turn off since needs preventOverflow to be enabled
                                },
                              }}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <small style={{ color: "red" }}>
                              {error &&
                                toDate === "" &&
                                "Please select to date"}
                            </small>
                          </Form.Group>
                        </Form.Row>

                        <div className="d-flex buttonGrop justify-content-between pb-0">
                          {context?.logindata?.roles?.includes("P_T_I_C") && (
                            <div>
                              <Button
                                size="xs"
                                variant="primary"
                                type="submit"
                                onClick={handlePaymentCreate}
                              >
                                {" "}
                                Create Payment
                              </Button>
                            </div>
                          )}
                          <div className="text-right pb-0">
                            <Button
                              size="xs"
                              variant="outline-secondary"
                              className="mr-3"
                              type="submit"
                              onClick={resetSearch}
                            >
                              {" "}
                              Reset
                            </Button>
                            <Button
                              size="xs"
                              variant="primary"
                              type="submit"
                              onClick={handleSearch}
                            >
                              {" "}
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="logOutyes"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>
                          Are you sure want to Regenerate payment link
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Footer>
                        <Button variant="primary" onClick={updatePaymentLink} className="mr-3">
                          Yes
                        </Button>
                        <Button variant="outline-secondary" onClick={handleClose}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <div className="cardMainlayout tableRes payPage p-0 mb-0">
                      {isLoad ? (
                        <div className="loadding">
                          <span class="loadernew"></span>
                        </div>
                      ) : (
                        <div className="tablescroll">
                          <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={paymentData}
                            classes="markup filterTbl queuesList payMntlist"
                            columns={columns}
                            // defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
      {historyPopup && (
        <PaymentHistoryPopup
          close={closeHistoryStatus}
          history_data={historyData ? historyData : []}
        />
      )}
    </>
  );
}

export default Payments;
