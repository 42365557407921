import React, { useEffect, useContext, useReducer, useState, useRef } from "react";
import {
  Col,
  Row,
  Container,
  Alert,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import log from "loglevel";
import moment from 'moment';
import Header from "../../common/Header";
import Sidebarmenu from "../../home/SideBar";
import { Context } from "../../../App";
import { useParams, Link, useNavigate } from "react-router-dom";
import DateUtils from "../../common/DateUtils";
import TransactionSummary from "./TransactionSummary";
import Footer from "../../common/Footer";
import FlightSummary from "./FlightSummary";

import {
  fetchHistoryData,
  fetchSummaryInfo,
  getRefundDetails,
  processFlightEditBooking,
  showRefunds,
  sendConfirmationObj
} from "../../dashboard/queues/operations";
import PaymentSummary from "./PaymentSummary";
import Select from "react-select";
import ShowManualPnrData from "../reissue/ShowManualPnrData";
import ManageBookingService from "../../../services/ManageBookingService";
import { isArrayNotEmpty, isNotNull } from "../../utilities/validators";
import FlightSummaryRefund from "../refund/FlightSummaryRefund";
import SplitPnr from "../refund/Splitpnr";
import PaymentDetails from "../../dashboard/manageBookings/PaymentDetails";
import { emailValidation } from "../../utilities/FormValidations";
export const EDIT_SEG_DATA = "editData";
export const EDIT_TICKET_DATA = "editTicketData";
export const EDIT_FARE_DETAILS = "editFareDetails";
export const EDIT_INITIAL_ONWARDSEG_DATA = "editinitialOnwardSegData";
export const EDIT_INITIAL_RETURNDSEG_DATA = "editinitialReturnSegData";
export const EDIT_INITIAL_ONWARDPAX_DATA = "editinitialOnwardPaxData";
export const EDIT_INITIAL_RETURNPAX_DATA = "editinitialReturnPaxData";
export const EDIT_INITIAL_ONWARDFARE_DATA = "editinitialOnwardFareData";
export const EDIT_INITIAL_RETURNFARE_DATA = "editinitialReturnFareData";
// export const EDIT_INITIAL_PAX_DATA = "editinitialPaxData";
export const EDIT_INITIAL_FARE_DATA = "editinitialFareData";
let bookingStatusList = require('../../masterData/BookingStatus..json');

const initialState = {
  fltresponse: "",
  notiMessageShow: false,
  notiMessage: "",
  notiVarient: "",
  shareAlert:"",
  loading: true,
  showPopup:false,
  email:"",
  flow_type:"",
  enableEdit: false,
  enableOnwardEdit: false,
  enableReturnEdit: false,
  enableEditBooking: false,
  showEditpop: false,
  enableReissue: false,
  insertPnr: "",
  enableManualReissue: false,
  enablePnrResp: false,
  manualResponse: "",
  insertPax: [],
  region_type: "Qatar",
  continueReissue: false,
  pax_det: [],
  rt_pax_det: [],
  seg_det: [],
  rt_seg_det: [],
  oldTid: "",
  flt_supplier: 0,
  flt_supplier_return: 0,
  showReissuelink: false,
  onwardReissueList: [],
  returnReissueList: [],
  office_id: "",
  enableRefund: false,
  enableRefundReturn: false,
  ticketsRefunded: [],
  allTktslength: 0,
  owpaxTNO: [],
  rtpaxTNO: [],
  updateBstatus: "",
  fare_info: [],
  rt_fare_info : [],
  type: "",
  block: "",
  payment_details: "",
  isCancelBook: false,
  manageBooking: [],
  rt_manageBooking: []
};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @author Praveen Varma
 * @date 24-11-2023
 * @description Flight booking summary
 * @param {*} props
 * @returns
 */
function FlightSummaryPage(props) {
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const [context, setContext] = useContext(Context);
  const paymentredirect = useRef(null);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [manageType, setManageType] = useState("");
  const [searchPNR, setSearchPNR] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [manualRefund, setManualRefund] = useState(false);
  const [request, setRequest] = useState([]);
  const [editrefundMore, setrefundEditMore,] = useState(false);
  const [manageBookingOnward, setManageBookingOnward] = useState();
  const [manageBookingReturn, setManageBookingReturn] = useState();
  const [paymentActive, setPaymentActive] = useState("payment");
  const [remarks, setRemarks] = useState(null);
  const [booking_status_str, setBokingStatusStr] = useState("");
  const { booking_id } = useParams();

  
  let unique_airport_codes = [];
  let unique_airline_codes = [];

  useEffect(() => {
    if (context?.logindata) {
      if (
        booking_id === undefined ||
        booking_id === "" ||
        booking_id === null
      ) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: "Please provide booking id" });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return;
      }
      fetchBookingInfo();
    }
  }, [context?.logindata]);

  useEffect(() =>{
      if(state.fltresponse?.bkng_info){
          if(state.fltresponse?.bkng_info?.booking_status_str){
              setBokingStatusStr(state.fltresponse?.bkng_info?.booking_status_str);
          }else if(state.fltresponse?.bkng_info?.booking_status){
              let status = bookingStatusList?.filter(itm => itm.value === state.fltresponse?.bkng_info?.booking_status);
              if(status){
                  setBokingStatusStr(status[0].label);
              }
          }
      }
  }, [state.fltresponse])

  const advanceSearchOpen = () => {
    if (advanceSearch) {
      setAdvanceSearch(false);
    } else {
      setAdvanceSearch(true);
    }
  };

  const contentStyle = {
    margin: 0,
    height: "178px",
    width: "242px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    borderRadius: "4px",
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const fetchBookingInfo = async () => {
    if (booking_id === null || booking_id === "") {
      return;
    }
    let payload = {
      region_type: context?.logindata?.region_type,
      bid: booking_id,
      cid: context?.logindata?.cmp_id || undefined,
      uid: context?.logindata?.user_id || undefined,
      is_admin: context?.logindata?.is_admin ? 1 : 0,
    };
    fetchSummaryInfo(payload)
      .then((response) => {
        if (response.suc) {
          dispatch({ type: 'summaryRes', payload: response?.res });
          state.summaryRes = response?.res;
          dispatch({ type: "fltresponse", payload: response?.res });
          dispatch({ type: "loading", payload: false });
          let all_tkts = [];
          let bookingData = [];
          let paxData = [];
          let rtpaxData = [];
          let paxIndigoData = [];
          let tkno_refundable = []
          let rt_tkno_refundable = []
          let allpax = []
          let reissue = false
          let ft = ""
          let reissue_split = false
          let seg_details = []
          let split_tickets = []
          let pnrs = []
          let hold_tkts = []
          let hold_tkts_split = []

          // for (let r in response?.res) {
            let data_ = response?.res;

            //Booking_details_section
            if (isNotNull(data_["bkng_info"])) {
              bookingData = data_.bkng_info;
              dispatch({ type: "bkng_det", payload: data_.bkng_info });
              dispatch({ type: "flow_type", payload: data_.bkng_info.flow_type });
              reissue_split = data_.bkng_info.issplitreissue; //reiisue tag call to function
              ft = data_.bkng_info.flow_type;
              if (data_.bkng_info.flow_type !== "split") {
                dispatch({ type: "tid", payload: data_.bkng_info.tid });
              }
              dispatch({ type: "cus_code", payload: data_.bkng_info.customer_code });
              dispatch({
                type: "flt_supplier",
                payload: Number(data_.onword_info?.seg_det[0]?.supplier),
              });
              if (data_.bkng_info.flow_type === "split") { 
                dispatch({
                  type: "flt_supplier_return",
                  payload: Number(data_.return_info?.seg_det[0]?.supplier),
                });
              }
              dispatch({ type: "bktype", payload: data_.bkng_info.bktype });
              dispatch({ type: "b_status", payload: data_.bkng_info?.booking_status });
              dispatch({ type: "company_id", payload: data_.bkng_info.company_id });
              dispatch({
                type: "normalTFtwoLcc",
                payload: data_.bkng_info.normalTFtwoLcc,
              });
              dispatch({ type: "comp_id", payload: data_.bkng_info.company_id });
              dispatch({ type: "custms", payload: data_.bkng_info.custms });
              dispatch({
                type: "bk_region_type",
                payload: data_.bkng_info.region_type,
              });
              dispatch({ type: "usr_cur", payload: data_.bkng_info.usr_curr_code });
              pnrs =
                data_.bkng_info.pnr_arr && data_.bkng_info.pnr_arr.length > 0
                  ? data_.bkng_info.pnr_arr
                  : [];
              
            }
            if (isNotNull(data_.onword_info['seg_det'])) {

              //below code collecting all src and destination airport codes
              let values = [];
              let airCodes = [];
              seg_details = data_.onword_info.seg_det
              for (var i = 0; i < data_.onword_info.seg_det.length; i++) {
                airCodes.push(data_.onword_info.seg_det[i]['tkt_airline']);
                if (data_.onword_info.seg_det[i].hasOwnProperty('seg_source') || data_.onword_info.seg_det[i].hasOwnProperty('seg_destination'))
                  values.push(data_.onword_info.seg_det[i]['seg_source']);
                values.push(data_.onword_info.seg_det[i]['seg_destination']);
              };

              dispatch({ type: "airCodesArray", payload: airCodes })
              // //Here find uniqu airport codes
              unique_airport_codes = uniquearray(values);
              newArray = uniquearray(airCodes);
              var newArray = unique_airline_codes.toString();
              dispatch({ type: 'aircodes', payload: newArray })
              //Here load airport data based on airport codes
              // if (isNotNull(unique_airport_codes) && state.airport_data.length === 0) {
              //   const req_code = {
              //     searchCriteria: unique_airport_codes
              //   }
              //   getairportData(req_code).then((res) => {

              //     dispatch({ type: 'airport_data', payload: res });
              //   });
              // }


              dispatch({ type: 'seg_det', payload: data_.onword_info.seg_det })
              let todaysDate = parseInt(moment(new Date()).format("YYYYMMDD"))
              let dept_date = parseInt(DateUtils.prettyDate(data_.onword_info.seg_det.depart_time, "YYYYMMDD"))
              if (dept_date >= todaysDate) {
                dispatch({ type: 'showRefund', payload: true })
              }
            } 
            //pax_details_section
            if (isNotNull(data_.onword_info['pax_info'])) {
              let onhold_pax_arr = []
              dispatch({ type: 'pax_det', payload: data_.onword_info.pax_info })
              let confirmedPax= data_.onword_info.pax_info?.filter((pax) => pax?.sts?.toUpperCase() === "CONFIRMED")
              dispatch({ type: 'manageBooking', payload: confirmedPax })
              dispatch({ type: 'temp_paxList', payload: data_.onword_info.pax_info })
              if (data_.onword_info.pax_info.length > 0) {
                for (let val in data_.onword_info.pax_info) {
                  let pax = data_.onword_info.pax_info[val]
                  if (val.length > 0) {
                    let voidrefundpax = []
                    let cancelledseg = []
                    for (let dub of [pax]) {
                     if(state.summaryRes !== undefined && state.summaryRes.bkng_info.flow_type == "normal"){
                       dispatch({type:"showitinerary",payload: true})
                       if(dub.stsid == "31" || dub.stsid == "39" || dub.stsid == "42" || dub.stsid == "44"){
                         voidrefundpax.push(dub)
                       }
                       if(dub.segst == "Cancelled"){
                         cancelledseg.push(dub)
                       }
                     }
                      allpax.push(val)
                      if(dub.stsid == "23" && !state.enableConfirmFunctions) {
                        dispatch({type:"enableConfirmFunctions",payload:true})
                      }
                      if (dub.split_pnr == null) {
                        if(dub.sts == "Pending" || dub.sts == "On Hold" ) {
                        if(val == 0) {
                         onhold_pax_arr.push(dub.sts)
                         dispatch({type:"onward_hold_varient",payload:true})
                        }
                         if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44" || dub.split_pnr == null) {
                           hold_tkts.push(dub.pax_name)
                         } else {
                           hold_tkts_split.push(dub.pax_name)
                         }
                        }
                        else{
                        all_tkts.push(dub.tkt_no)
                        }
                      } else {
                        if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "40" || dub.stsid === "42" || dub.stsid === "43" || dub.stsid === "44" || dub.stsid === "45" || dub.stsid === "38") {
                          all_tkts.push(dub.tkt_no)
                        } else {
                          split_tickets.push(dub.tkt_no)
                        }
                      }

                      if (state.trip_type == "") {
                        dispatch({ type: "trip_type", payload: dub.trip_type })
                      }
                    }
                    if(pax.length == voidrefundpax.length){
                       dispatch({type:"showitinerary",payload: false})
                    }
                    dispatch({type:"showReissuelink",payload:true})
                    dispatch({type:"showitinerary",payload:true})
                    if(pax.length == cancelledseg.length){
                      dispatch({type:"enableConfirmFunctions",payload:false})
                      dispatch({type:"showReissuelink",payload:false})
                    }
                    else{
                     dispatch({type:"enableConfirmFunctions",payload:true})
                     dispatch({type:"showReissuelink",payload:true})
                    }
                  }

                }
                if(onhold_pax_arr.length > 1) {
                dispatch({type:"onhold_tag",payload:true})
                }
                dispatch({ type: "hold_tkts", payload: hold_tkts })
                dispatch({ type: "all_tkts", payload: all_tkts })
                dispatch({ type: "split_tickets", payload: split_tickets })
              }
              //For Refund onward
              if(data_.onword_info !== undefined) {
                if (paxData.length === 0) {
                  for (let val of data_.onword_info.pax_info) {
                    for (let p of [val]) {
                      if (p != undefined && (p.sts === "Refund/booking" || p.sts === "Refund/cancel")) {
                        paxData.push(p.trv_id);
                        const req={
                         "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                         "id": p.trv_id
                        }
                        paxIndigoData.push(req);
                        tkno_refundable.push(p.tkt_no)
                      }
                    }
                  }
                }
              }
              //For Refund return
              if(data_.return_info !== undefined && Object.keys(data_.return_info).length !== 0) {
                if (rtpaxData.length === 0) {
                  if(data_.return_info?.pax_info !== undefined) {
                    for (let val of data_.return_info.pax_info) {
                      for (let p of [val]) {
                        if (p != undefined && (p.sts === "Refund/booking" || p.sts === "Refund/cancel")) {
                          rtpaxData.push(p.trv_id);
                          const req={
                          "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                          "id": p.trv_id
                          }
                          paxIndigoData.push(req);
                          rt_tkno_refundable.push(p.tkt_no)
                        }
                      }
                    }
                  }
                }
              }
              //For Edit Booking
              // if (bookingData[0].flow_type == 'split') {
              //   for (let val of data_.pax_det[0]) {
              //     if (val.sts === "Confirmed") {
              //       dispatch({ type: "enableOnwardIti", payload: false })
              //     }
              //   }
              // }
              if (bookingData.flow_type == 'split') {
                dispatch({ type: "allTktslength", payload: all_tkts.length })
                //onward pax
                let owpax = data_.onword_info.pax_info
                let rtpax = data_.return_info.pax_info
                dispatch({
                  type: "rt_pax_det",
                  payload: rtpax,
                });                
                let confirmedPax= rtpax?.filter((pax) => pax?.sts?.toUpperCase() === "CONFIRMED")
                dispatch({
                  type: "rt_manageBooking",
                  payload: confirmedPax,
                });
                dispatch({
                  type: "rt_seg_det",
                  payload: data_.return_info.seg_det,
                });
                // dispatch({ type: "flt_supplier", payload: Number(owpax[0].supplier) })
                // dispatch({ type: "flt_supplier_return", payload: Number(rtpax[0].supplier) })
                let owrefundpax = []
                let cancelledowseg = []
                for (let val of owpax) {

                 state.owpaxTNO.push(val.tkt_no)
                  if(val.stsid == "31" || val.stsid == "39" || val.stsid == "42" || val.stsid == "44"){
                   owrefundpax.push(val)
                 }
                 if(val.segst == "Cancelled"){
                   cancelledowseg.push(val)
                 }
                }
                if(owrefundpax.length == owpax.length){
                 dispatch({type:"showitinerary",payload: false})
                }
                else{
                 dispatch({type:"showitinerary",payload: true})
               }
                dispatch({type:"showReissuelink",payload:true})
                if(cancelledowseg.length == owpax.length ){
                 dispatch({type:"enableConfirmFunctions",payload:false})
                 dispatch({type:"showReissuelink",payload:false})
                }
                else{
                 dispatch({type:"enableConfirmFunctions",payload:true})
                 dispatch({type:"showReissuelink",payload:true})
                }

                let rtrefundpax = []
                let cancelledrtseg = []
                for (let sval of rtpax) {
                   if(sval.stsid == "31" || sval.stsid == "39" || sval.stsid == "42" || sval.stsid == "44"){
                     rtrefundpax.push(sval)
                   }
                  state.rtpaxTNO.push(sval.tkt_no)
                  if(sval.segst == "Cancelled"){
                   cancelledrtseg.push(sval)
                 }
                }
                if(rtrefundpax.length == rtpax.length){
                 dispatch({type:"returnshowitinerary",payload: false})
                }
               else{
                  dispatch({type:"returnshowitinerary",payload: true})
                }
                dispatch({type:"showrtReissuelink",payload:true})
                dispatch({type:"enablertConfirmFunctions",payload:true})

                if(cancelledrtseg.length == rtpax.length ){
                 dispatch({type:"enablertConfirmFunctions",payload:false})
                 dispatch({type:"showrtReissuelink",payload:false})
                }
                else{
                 dispatch({type:"enablertConfirmFunctions",payload:true})
                 dispatch({type:"showrtReissuelink",payload:true})
                }
              } else {
                dispatch({ type: "allTktslength", payload: all_tkts.length })
              }

            }

            //fare info section
            if (isNotNull(data_.onword_info['fare_info'])) {
              dispatch({ type: 'fare_info', payload: data_.onword_info['fare_info'] })
            } 
            //fare info section
          if(data_.return_info !== undefined && Object.keys(data_.return_info).length !== 0) {
            if (isNotNull(data_.return_info['fare_info'])) {
              dispatch({ type: 'rt_fare_info', payload: data_.return_info['fare_info'] })
            } 
          }
          // }
          //Calling Refund Details for onward
          if (bookingData!==undefined && data_.onword_info.seg_det[0].supplier==="0" && paxData !== undefined && paxData.length !== 0 && bookingData.bktype != "IMPORT" && bookingData.bktype != "IMPORT/SPLIT" && bookingData.bktype != "IMPORT/REISSUE") {
            const payload = {
              "booking_id": bookingData.booking_id,
              "tid": (bookingData !== undefined && bookingData.tid !== undefined) ? bookingData.tid : "",
              "trans_id": (bookingData !== undefined && bookingData.trans_id !== undefined) ? bookingData.trans_id : "",
              "trav_id": paxData, 
              "tkt_no": tkno_refundable,
              "region_type": state.bk_region_type,
              "req_cur": state.usr_cur
            }
            showRefunds(payload).then((resp) => {
              if (resp.suc && resp.res !== undefined) {
                //constructing Splitpnr data for display--start
                let pnr_data = [];
                for (let r in resp.res.psngrs) {
                  let paxwise_data = resp.res.psngrs[r];
                  let p_type = paxwise_data.ptype;
                  for (let tkt in paxwise_data.tktnum) {
                    let tikt_data = paxwise_data.tktnum[tkt];
                    if (isNotNull(tikt_data.split_pnr)) {
                      let split_pnr_obj = {
                        "pn": tikt_data.pname, "lp": tikt_data.paxsno, "tkt": tikt_data.number, "type": p_type,
                        "pnr": tikt_data.split_pnr, "bt": tikt_data.st, "sts": tikt_data.stn
                      }
                      pnr_data.push(split_pnr_obj);
                    }
                    state.ticketsRefunded.push(tikt_data.number)
                  }
                }
                let refundpnr = []
                for(let item of pnr_data){
                  if(item.sts == 44){
                  refundpnr.push(item)
                  }
                };
                if(refundpnr.length == pnr_data.length ){
                dispatch({ type: 'showCancel', payload: false })
                }
                else{
                dispatch({ type: 'showCancel', payload: true })
                }
                dispatch({ type: 'refund_split_onward', payload: pnr_data })
                //constructing Splitpnr data for display--End
                dispatch({ type: "initialRefundResponse", payload: resp.res })

                setRequest(resp.res);
                dispatch({ type: 'refundAvailonward', payload: true })
              }
            })
            .catch(function (error) {
              console.error(
                "Exception occured in refunds retrieve function---" + error
              );
            });
          }
          //Calling Refund Details for return
          if(data_.return_info !== undefined && Object.keys(data_.return_info).length !== 0) {
            if (bookingData!==undefined && data_.return_info.seg_det[0].supplier==="0" && rtpaxData !== undefined && rtpaxData.length !== 0 && bookingData.bktype != "IMPORT" && bookingData.bktype != "IMPORT/SPLIT" && bookingData.bktype != "IMPORT/REISSUE") {
              const payload = {
                "booking_id": bookingData.booking_id,
                "tid": data_.return_info?.seg_det[0]?.tid,                
                "trans_id": data_.return_info?.seg_det[0]?.fk_trans_id,
                "trav_id": rtpaxData, 
                "tkt_no": rt_tkno_refundable,
                "region_type": state.bk_region_type,
                "req_cur": state.usr_cur
              }
              showRefunds(payload).then((resp) => {
                if (resp.suc && resp.res !== undefined) {
                  //constructing Splitpnr data for display--start
                  let pnr_data = [];
                  for (let r in resp.res.psngrs) {
                    let paxwise_data = resp.res.psngrs[r];
                    let p_type = paxwise_data.ptype;
                    for (let tkt in paxwise_data.tktnum) {
                      let tikt_data = paxwise_data.tktnum[tkt];
                      if (isNotNull(tikt_data.split_pnr)) {
                        let split_pnr_obj = {
                          "pn": tikt_data.pname, "lp": tikt_data.paxsno, "tkt": tikt_data.number, "type": p_type,
                          "pnr": tikt_data.split_pnr, "bt": tikt_data.st, "sts": tikt_data.stn
                        }
                        pnr_data.push(split_pnr_obj);
                      }
                      state.ticketsRefunded.push(tikt_data.number)
                    }
                  }
                  let refundpnr = []
                  for(let item of pnr_data){
                    if(item.sts == 44){
                    refundpnr.push(item)
                    }
                  };
                  if(refundpnr.length == pnr_data.length ){
                  dispatch({ type: 'showCancel', payload: false })
                  }
                  else{
                  dispatch({ type: 'showCancel', payload: true })
                  }
                  dispatch({ type: 'refund_split_return', payload: pnr_data })
                  //constructing Splitpnr data for display--End
                  dispatch({ type: "initialRefundResponse", payload: resp.res })
  
                  setRequest(resp.res);
                  dispatch({ type: 'refundAvailreturn', payload: true })
                }
              })
              .catch(function (error) {
                console.error(
                  "Exception occured in refunds retrieve function---" + error
                );
              });
            }

          }
          
          if (bookingData !== undefined) {
            fetchHistoryData(bookingData.booking_id).then((response) => {
              if (response?.suc) {
                dispatch({ type: "onwardReissueList", payload: response?.res?.reissue });
                let returnList = [];
                if(response?.res?.rtreissue !== undefined) {
                  returnList = response?.res?.rtreissue;
                }
                dispatch({ type: "returnReissueList", payload: returnList });
              }
            })
            .catch(function (error) {
              console.error(
                "Exception occured in fetchHistoryData function---" + error
              );
            });
          }

        } else {
          dispatch({ type: "fltresponse", payload: undefined });
          dispatch({ type: "loading", payload: false });
        }
      })
      .catch(function (error) {
        dispatch({ type: "loading", payload: false });
        console.error(
          "Exception occured in fetchFlightBookingInfo function---" + error
        );
      });
  };

  
   //Edit Booking Partials
   useEffect(() => {
    if (state.pax_det.length !== 0 && state.bkng_det.booking_status == "36") {
      if (state.flow_type == 'split') {
        let tidAry = state.bkng_det.tid.split("/");
        for (let val of state.pax_det) {
          // for (let val of pax) {
            if (val.tid === tidAry[0] && val.sts === "Ticketing failed") {
              // dispatch({ type: 'enableEdit', payload: true })
              dispatch({ type: 'onwardAvail', payload: true })
              break;
            } else if (val.tid === tidAry[1] && val.sts === "Ticketing failed") {
              dispatch({ type: 'enableReturnEdit', payload: true })
              dispatch({ type: 'returnAvail', payload: true })
              break;
            }
          // }
        }
      }
    }
  }, [state.pax_det])

  let SelectOptions = [
    { value: "Edit", label: "Edit Booking" },
    { value: "Manual_Reissue_Fetch", label: "Manual Reissue Fetch" },
    { value: "Manual_Reissue", label: "Manual Reissue" },
    { value: "Manual_Refund", label: "Manual Refund" },
  ];

  let SelectOptions1 = [
    { value: "Edit", label: "Edit Booking" },
  ];

  const openshareevent = (type) => {
    if(type=="R"){
      dispatch({ type: 'fbfreturn', payload: true })
    }else{
      dispatch({ type: 'fbfreturn', payload: false })
    }
    dispatch({ type: 'showPopup', payload: true })
  }

  const handleChangeType = (selectedOption, type) => {
    if(type==="ONWARD") {
      setManageBookingOnward(selectedOption);
    } else if(type==="RETURN") {
      setManageBookingReturn(selectedOption);
    }
    if (selectedOption?.value === "Manual_Reissue_Fetch") {
      setSearchPNR(true);
    }
    if (selectedOption?.value === "Manual_Reissue") {
      state.fltresponse.type=type
      const fltresponse = state.fltresponse;      
      navigate("/crm/flight/summary/manualReissue",{state:fltresponse});
    }
    if (selectedOption?.value === "Manual_Refund") {
      if(type==="ONWARD") {
        dispatch({ type: "enableRefund", payload: true })
        dispatch({ type: "enableRefundReturn", payload: false })
      } else if(type==="RETURN") {
        dispatch({ type: "enableRefund", payload: false })
        dispatch({ type: "enableRefundReturn", payload: true })
      }
    }
    
    setManageType(selectedOption?.value);
    if(type === "ONWARD") {
      dispatch({ type: "oldTid", payload: state.fltresponse?.onword_info?.seg_det[0]?.tid })
      dispatch({ type: "office_id", payload: state.fltresponse?.onword_info?.seg_det[0]?.office_id })
    } else {
      dispatch({ type: "oldTid", payload: state.fltresponse?.return_info?.seg_det[0]?.tid })
      dispatch({ type: "office_id", payload: state.fltresponse?.return_info?.seg_det[0]?.office_id })
    }
    if (selectedOption?.value === "Edit") {
      dispatch({ type: "enableRefund", payload: false })
      dispatch({ type: "enableRefundReturn", payload: false })
      if(type === "ONWARD") {
        sessionStorage.setItem(EDIT_INITIAL_ONWARDSEG_DATA,JSON.stringify(state.fltresponse?.onword_info?.seg_det));
        sessionStorage.setItem(EDIT_INITIAL_ONWARDPAX_DATA,JSON.stringify(state.fltresponse?.onword_info?.pax_info));
        sessionStorage.setItem(EDIT_INITIAL_ONWARDFARE_DATA,JSON.stringify(state.fltresponse?.onword_info?.fare_info[0].pasngrs[0].pax));
        dispatch({ type: "enableEdit", payload: true });
        dispatch({ type: "enableOnwardEdit", payload: true });        
        dispatch({ type: "enableReturnEdit", payload: false });
      } else {
        sessionStorage.setItem(EDIT_INITIAL_RETURNDSEG_DATA,JSON.stringify(state.fltresponse?.return_info?.seg_det));
        sessionStorage.setItem(EDIT_INITIAL_RETURNPAX_DATA,JSON.stringify(state.fltresponse?.return_info?.pax_info));
        sessionStorage.setItem(EDIT_INITIAL_RETURNFARE_DATA,JSON.stringify(state.fltresponse?.return_info?.fare_info[0].pasngrs[0].pax));
        dispatch({ type: "enableEdit", payload: true });
        dispatch({ type: "enableReturnEdit", payload: true });
        dispatch({ type: "enableOnwardEdit", payload: false }); 
      }
      dispatch({ type: "enableEditBooking", payload: true });
    } else {      
      dispatch({ type: "enableEdit", payload: false });
      dispatch({ type: "enableOnwardEdit", payload: false }); 
      dispatch({ type: "enableReturnEdit", payload: false });
      dispatch({ type: "enableEditBooking", payload: false });
    }
  };

  const cancelRefundblock = (data, type) => {
    if(type==="ONWARD") {
      setManageBookingOnward(null);
      dispatch({ type: "enableRefund", payload: data })
    } else if(type==="RETURN") {
      setManageBookingReturn(null);
      dispatch({ type: "enableRefundReturn", payload: data })
    }
  }

  const onclosePNR = () => {
    setSearchPNR(false);
    setIsLoad(false);
    dispatch({ type: "insertPnr", payload: "" });
  };

  const enableReissueForm = () => {
    dispatch({ type: "enableReissue", payload: true });
  };

  //Retriveing the PNR Details
  const retrivePNRData = () => {
    if (state.insertPnr !== "") {
      dispatch({ type: "isItineraryLoading", payload: true });
      const req = {
        pnr: state.insertPnr,
        office_id: state.office_id,
        region: state.region_type,
        req_cur: "QAR",
        type: "INSERT_PNR",
      };
      setIsLoad(true);
      ManageBookingService.getPNRDetails(req).then((resp) => {
        if (resp.data.suc) {
          setIsLoad(false);
          dispatch({ type: "enableManualReissue", payload: true });
          setSearchPNR(false);
          //dispatch({ type: 'pnrResponse', payload: resp })
          dispatch({ type: "manualResponse", payload: resp.data });
          dispatch({ type: "enablePnrResp", payload: true });
          dispatch({ type: "isItineraryLoading", payload: false });
        } else {
          setIsLoad(false);
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({
            type: "notiMessage",
            payload: "PNR details not available.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          setIsLoadingMore(false);
          hidemessage();
        }
      }).catch(function (err) {
        setIsLoad(false);
        log.error("Exception occured in retrive PNR data---" + err);
      });
    } else {
      setIsLoad(false);
      dispatch({ type: "isItineraryLoading", payload: false });
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Enter PNR" });
      dispatch({ type: "notiVarient", payload: "danger" });
      setIsLoadingMore(false);
      hidemessage();
    }
  };

  const callEditBooking = () => {
    if(validatePayment()) {
      dispatch({ type: "showEditpop", payload: true });
    }
  };

  const handleChangeemail = (e) => {
    e.preventDefault();
       let trimmedEmail = e.target.value.trim();
         dispatch({ type: 'email', payload: trimmedEmail })
         dispatch({ type: 'shareAlert', payload: "" })
   }

   const sendEmail = ()=>{
    if(validateEmail()){
      if(Number(state.fltresponse?.bkng_info?.booking_status) == 23 || Number(state.fltresponse?.bkng_info?.booking_status) == 36 ){
        if(state.flow_type == "normal"){
          if(state.flt_supplier == "0"){
            if(state.summaryRes.bkng_info.bktype == "REISSUE/SPLIT" || state.summaryRes.bkng_info.bktype == "IMPORT/SPLIT"){
              if(state.splittid == undefined || state.splittid == ""){
                state.splittid = state.summaryRes.bkng_info.tid
                 }
            const payload = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid": state.splittid,
              "email_id":state.email,
              "sup": state.summaryRes.bkng_info.supplier,
              "jtype": state.summaryRes.bkng_info.journey_type,
              "ftype": state.summaryRes.bkng_info.flow_type
             }
             shareItinerary(payload)
            }
            else if(state.summaryRes.bkng_info.bktype == "REISSUE" || state.summaryRes.bkng_info.bktype == "MANUAL/REISSUE" || state.summaryRes.bkng_info.bktype == "MANUAL" || state.summaryRes.bkng_info.bktype == "DIRECT" || state.summaryRes.bkng_info.bktype == "IMPORT" || state.summaryRes.bkng_info.bktype == "IMPORT/REISSUE"){
           if(state.fbfreturn){
            const payload = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid":  state.summaryRes.return_info.seg_det[0].tid,
              "email_id":state.email,
              "sup": state.summaryRes.return_info.seg_det[0].supplier,
              "jtype": state.summaryRes.return_info.seg_det[0].journey_type,
              "ftype": state.summaryRes.return_info.seg_det[0].flow_type
             }
             shareItinerary(payload)
           }else{
              const payload = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid":  state.summaryRes.bkng_info.tid,
              "email_id":state.email,
              "sup": state.summaryRes.bkng_info.supplier,
              "jtype": state.summaryRes.bkng_info.journey_type,
              "ftype": state.summaryRes.bkng_info.flow_type
             }
             shareItinerary(payload)
            }
            }
          }
          else{
            const payload1 = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid": state.seg_det[0].trvel_type !== "1" ? state.summaryRes.bkng_info.tid +"/"+state.summaryRes.bkng_info.tid.slice(0, -1)+"2" :  state.summaryRes.bkng_info.tid ,
              "email_id":state.email,
              "sup": state.summaryRes.bkng_info.supplier,
              "jtype": "O",
              "ftype": state.summaryRes.bkng_info.flow_type
             }
             shareItinerary(payload1)
             if(state.seg_det[0].trvel_type !== "1"){
              setTimeout(() => {
                const payload2 = {
                  "booking_id": state.summaryRes.bkng_info.booking_id,
                  "tid": state.summaryRes.bkng_info.tid +"/"+state.summaryRes.bkng_info.tid.slice(0, -1)+"2",
                  "email_id":state.email,
                  "sup": state.summaryRes.bkng_info.supplier,
                  "jtype": "R",
                  "ftype": state.summaryRes.bkng_info.flow_type
                 }
               shareItinerary(payload2)
              }, 10000);
            }
          }
        }
        else{
            if(state.summaryRes.bkng_info.bktype == "REISSUE/SPLIT"){
              if(state.splittid == undefined){
                state.splittid = state.summaryRes.bkng_info.tid
                 }
            const payload = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid": state.splittid,
              "email_id":state.email,
              "sup": state.fbfreturn ? state.flt_supplier_return : state.flt_supplier,
              "jtype": state.fbfreturn ? "R" : "O",
              "ftype": "split"
             }
             shareItinerary(payload)
            }
          else if(state.summaryRes.bkng_info.bktype == "REISSUE" || state.summaryRes.bkng_info.bktype == "MANUAL/REISSUE" || state.summaryRes.bkng_info.bktype == "MANUAL" || state.summaryRes.bkng_info.bktype == "DIRECT" ){
            if(state.fbfreturn){
              const payload = {
                "booking_id": state.summaryRes.bkng_info.booking_id,
                "tid":  state.summaryRes.return_info.seg_det[0].tid,
                "email_id":state.email,
                "sup": state.summaryRes.return_info.seg_det[0].supplier,
                "jtype": state.summaryRes.return_info.seg_det[0].journey_type,
                "ftype": state.summaryRes.return_info.seg_det[0].flow_type
               }
               shareItinerary(payload)
             }else{
            const payload = {
              "booking_id": state.summaryRes.bkng_info.booking_id,
              "tid": state.fbfreturn ? state.pax_det[1].tid : state.pax_det[0].tid,
              "email_id":state.email,
              "sup": state.fbfreturn ? state.flt_supplier_return : state.flt_supplier ,
              "jtype":state.fbfreturn ? "R" : "O",
              "ftype": "split"
             }
             shareItinerary(payload)
          }
         }
        }
      }
    }
  }
  const validateEmail = ()=>{
    if(state.email == ""){
      dispatch({ type: 'shareAlert', payload: "Please enter Email" })
      return false
     }
     else if(!emailValidation(state.email)){
      dispatch({ type: 'shareAlert', payload: "Please enter valid Email" })
      return false
     }
     return true
   }
   const shareItinerary = (data) =>{
    sendConfirmationObj(data).then(response=>{
      if(response.success){
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response.data })
        dispatch({ type: 'notiVarient', payload: 'success' })
        dispatch({ type: 'splittid', payload: "" })
        window.location = `/crm/flight/summary/${state.summaryRes.bkng_info.booking_id}`
        hidemessage();
        dispatch({ type: 'fbfreturn', payload: false })
      } else {
        dispatch({ type: 'shareAlert', payload: "Something went wrong" })
      }
     }).catch(function (error) {
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'email', payload: "" })
      dispatch({ type: 'splittid', payload: "" })
      dispatch({ type: 'fbfreturn', payload: false })
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
   }

   const handleemailClose = ()=>{
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
    dispatch({ type: 'splittid', payload: "" })
    dispatch({ type: 'fbfreturn', payload: false })
    dispatch({ type: 'shareAlert', payload: "" })
   }
  
  const validatePayment = (type) => {
    if(type==="before") {      
      paymentredirect.current?.scrollIntoView({behavior: 'smooth'});
      return true;
    } else {
      if (paymentActive === "payment") {
        if(!state.payment_details.is_pay_uniq_id && !state.payment_details.is_bank_transfer && !state.payment_details.is_cheque) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter payment details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          state.payment_details.is_pay_uniq_id &&
          (state.payment_details.pay_uniq_id == "" ||
            state.payment_details.paid_amount === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please check Payment UID details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          state.payment_details.is_bank_transfer &&
          (state.payment_details.bank_name === "" ||
            state.payment_details.ac_number === "" ||
            state.payment_details.total_amt === "" ||
            state.payment_details.cust_name === "" ||
            state.payment_details.trans_number === "" ||
            state.payment_details.date_of_transfer === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Bank Transfer details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          state.payment_details.is_cheque &&
          (state.payment_details.che_bank_name === "" ||
            state.payment_details.che_ac_number === "" ||
            state.payment_details.che_total_amt === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Cheque details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      } else if (paymentActive === "remarks") {
        if(remarks === null) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: "Enter Remarks" });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      }  
    }
   return true;
  }

  const cancelEdit = () => {
      setManageBookingOnward(null);
      setManageBookingReturn(null);
      localStorage.removeItem(EDIT_SEG_DATA);
      localStorage.removeItem(EDIT_TICKET_DATA);
      localStorage.removeItem(EDIT_FARE_DETAILS);
      sessionStorage.removeItem(EDIT_INITIAL_ONWARDSEG_DATA);
      sessionStorage.removeItem(EDIT_INITIAL_RETURNDSEG_DATA);  
      sessionStorage.removeItem(EDIT_INITIAL_ONWARDPAX_DATA);
      sessionStorage.removeItem(EDIT_INITIAL_RETURNPAX_DATA);      
      // localStorage.removeItem(EDIT_INITIAL_PAX_DATA);
      localStorage.removeItem(EDIT_INITIAL_FARE_DATA);
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch({ type: "enableEdit", payload: false });
      dispatch({ type: "enableOnwardEdit", payload: false }); 
      dispatch({ type: "enableReturnEdit", payload: false });
      dispatch({ type: "enableEditBooking", payload: false });
      dispatch({ type: 'updateBstatus', payload: "" })
  };

  const oncloseEidt = () => {
    dispatch({ type: "showEditpop", payload: false });
  };

  const processEditBookingFlow = () => {
    if (state.type === "Onward") {
        processEditBookingFlowOnward(state.block, "final");
      } else if (state.type === "Return") {
        processEditBookingFlowReturn(state.block, "final");
      } else {
        processEditBookingFlowOnward(state.block, "final");
      }
  };

  const processEditBooking = (type, block) => {
    dispatch({ type: "type", payload: type });
    dispatch({ type: "block", payload: block });
    if (type === "Onward") {
        processEditBookingFlowOnward(block);
      } else if (type === "Return") {
        processEditBookingFlowReturn(block);
    }
  };

  //Handle Edit Booking flow
  const processEditBookingFlowOnward = (block, type) => {
    let segsList = [];
    let validate = true;
    var onwardTid = "";
    let value = false;
    let status = "";
    if(block === "seg") {
      status = "Segment";
    } else if(block === "pax") {
      status = "Pax";
    } else if(block === "fare") {
      status = "Fare";
    }
    //Checking Airline and GDS PNRS nad Ticket Numbers
    if(block === "seg") {
      var data = JSON.parse(localStorage.getItem(EDIT_SEG_DATA));
      if (data !== null) {
        for (let seg1 of state.seg_det) {
          for (let seg2 of data) {
            if (Number(seg1.segid) === Number(seg2.segid)) {
              if (seg2.changed !== undefined && seg2.changed) {
                seg1.changed = true;
                seg1.normal_airline_pnr = seg2.normal_airline_pnr;
                seg1.normal_gds_pnr = seg2.normal_gds_pnr;
                seg1.split_airline_pnr = seg2.split_airline_pnr;
                seg1.split_gds_pnr = seg2.split_gds_pnr ? seg2.split_gds_pnr : seg2.normal_gds_pnr;
                seg1.flt_no = seg2.flt_no;
                seg1.cabin_class = seg2.cabin_class;
                seg1.fare_class = seg2.fare_class;
                seg1.operating_airline = seg2.operating_airline; 
                seg1.depart_time = seg2.depart_time;  
                seg1.arrival_time = seg2.arrival_time;           
              }
            }
          }
        }
      } else if(state.updateBstatus === "") {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Edit atleast one field and proceed",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      } else if (state.updateBstatus === booking_status_str) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Edit atleast one field and proceed",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      }
    }
    if (state.bkng_det.flow_type === "normal") {
      if (state.updateBstatus === "Confirmed") {
        value = true;
      }
    } else {
      if (state.bkng_det.booking_status === "34") {
        if (state.updateBstatus === "Partially Ticketed") {
          value = true;
        }
      } else {
        value = checkBookingStatus();
      }
    }
    if (true) {
      if(block === "seg") {
        if (state.seg_det.length !== 0) {
          for (let seg of state.seg_det) {
            if (state.bkng_det.flow_type === "normal") {
              if (
                seg.normal_airline_pnr !== undefined &&
                seg.normal_airline_pnr !== ""
              ) {
                if (
                  seg.normal_gds_pnr !== undefined &&
                  seg.normal_gds_pnr !== ""
                ) {
                  if (seg.changed !== undefined && seg.changed) {
                    segsList.push(getSegmentNormalObj(seg));
                    // if(validatePayment("before")) {
                    //   validate = false;
                    // }           
                  } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                    // if(validatePayment("before")) {
                      validate = true;
                      status = "status";
                    // }     
                  }
                } else {
                  validate = false;
                  validateGds("gds");
                }
              } else {
                validate = false;
                validateGds("airline");
              }
            } else {
              let tidArray = state.bkng_det.tid.split("/");
              onwardTid = tidArray[0];
              if (seg.tid === onwardTid) {
                if (
                  seg.split_airline_pnr !== undefined &&
                  seg.split_airline_pnr !== ""
                ) {
                  if (
                    seg.split_gds_pnr !== undefined &&
                    seg.split_gds_pnr !== ""
                  ) {
                    if (seg.changed !== undefined && seg.changed) {
                      segsList.push(getSegmentSplitObj(seg));
                      // if(validatePayment("before")) {
                      //   validate = false;
                      // }     
                    } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                      // if(validatePayment("before")) {
                        validate = true;
                        status = "status";
                      // }     
                    }
                  } else if (
                    seg.normal_gds_pnr !== undefined &&
                    seg.normal_gds_pnr !== ""
                  ) {
                    if (seg.changed !== undefined && seg.changed) {
                      segsList.push(getSegmentSplitObj(seg));
                      // if(validatePayment("before")) {
                      //   validate = false;
                      // }     
                    } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                      // if(validatePayment("before")) {
                        validate = true;
                      // }     
                    }
                  } else {
                    validate = false;
                    validateGds("gds");
                  }
                } else {
                  validate = false;
                  validateGds("airline");
                }
              }
            }
          }
        }
      }
      let paxList = [];
      if(block === "pax") {
        var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
        if (ticketsInfo !== null && ticketsInfo.length.length !== 0) {
          for (let val of ticketsInfo) {
            // for (let val of pax) {
              if (state.bkng_det.flow_type === "normal") {
                if (
                  validatePax(val) &&
                  val.changed !== undefined &&
                  val.changed
                ) {
                  const obj = {
                    paxid: val.trv_op_id,
                    sts: val.sts,
                    tkt_no: val.tkt_no,
                    pax_name: val.pax_name,
                    pax_type: val.pax_type,
                    segst: val.segst,
                  };
                  paxList.push(obj);
                  // if(validatePayment("before")) {
                  //   validate = false;
                  // }         
                } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                  // if(validatePayment("before")) {
                    validate = true;
                    status = "status";
                  // }     
                } else {
                  validate = false;
                }
              } else {
                let tidArray = state.bkng_det.tid.split("/");
                onwardTid = tidArray[0];
                if (val.tid === onwardTid) {
                  if (
                    validatePax(val) &&
                    val.changed !== undefined &&
                    val.changed
                  ) {
                    const obj = {
                      paxid: val.trv_op_id,
                      sts: val.sts,
                      tkt_no: val.tkt_no,
                      pax_name: val.pax_name,
                      pax_type: val.pax_type,
                      segst: val.segst,
                    };
                    paxList.push(obj);
                    // if(validatePayment("before")) {
                    //   validate = false;
                    // }         
                  } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                    // if(validatePayment("before")) {
                      validate = true;
                      status = "status";
                    // }     
                  } else {
                    validate = false;
                  }
                }
              }
            // }
          }
        } else if(state.updateBstatus === "") {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (state.updateBstatus === booking_status_str) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        }
      }
      let fareDetails = [];
      if(block === "fare") {
        var fareInfo = JSON.parse(localStorage.getItem(EDIT_FARE_DETAILS));
        if (fareInfo !== null) {
          for (let fare1 of state.fare_info[0].pasngrs[0].pax) {
            for (let fare2 of fareInfo) {
              if (fare1.trv_id === fare2.trv_id) {
                if (fare2.changed !== undefined && fare2.changed) {
                  fare1.changed = true;
                  fare1.purchase.basefare = fare2.purchase.basefare;
                  fare1.taxes = fare2.taxes;
                  fare1.sales.basefare = fare2.sales.basefare;
                  fare1.sales.markup = fare2.sales.markup;
                  fare1.sales.servicefee = fare2.sales.servicefee;
                }
              }
            }
          }
          
        if (state.fare_info[0].pasngrs[0].pax.length !== 0) {
          for (let fare of state.fare_info[0].pasngrs[0].pax) {
            if (state.bkng_det.flow_type === "normal") {
              if (fare.changed !== undefined && fare.changed) {
                const obj = {
                  fareid: fare?.trv_id,
                  trv_op_id: fare?.trv_op_id,
                  taxes: fare.taxes,
                  purchase: {
                    ...fare.purchase,
                    basefare : fare.purchase.basefare,
                  },
                  sales: {
                    ...fare.sales,
                    basefare: fare.sales.basefare,
                    markup: fare.sales.markup,
                    servicefee: fare.sales.servicefee,
                  },
                };
                fareDetails.push(obj);
                // if(validatePayment("before")) {
                //   validate = false;
                // }     
              } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                // if(validatePayment("before")) {
                  validate = true;
                  status = "status";
                // }     
              }
            } else {
              let tidArray = state.bkng_det.tid.split("/");
              onwardTid = tidArray[0];
              if (fare.changed !== undefined && fare.changed) {
                const obj = {
                  fareid: fare?.trv_id,
                  trv_op_id: fare?.trv_op_id,
                  taxes: fare.taxes,
                  purchase: {
                    ...fare.purchase,
                    basefare : fare.purchase.basefare,
                  },
                  sales: {
                    ...fare.sales,
                    basefare: fare.sales.basefare,
                    markup: fare.sales.markup,
                    servicefee: fare.sales.servicefee,
                  },
                };
                fareDetails.push(obj);
                // if(validatePayment("before")) {
                //   validate = false;
                // }     
              } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                // if(validatePayment("before")) {
                  validate = true;
                  status = "status";
                // }     
              }
            }
          }
        }
        } else if(state.updateBstatus === "") {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (state.updateBstatus === booking_status_str) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        }
      }
      var payload = "";
      if (validate || type === "final") {
        if (state.bkng_det.flow_type === "normal") {
          payload = {
            booking_id: state.bkng_det.booking_id,
            bst: state.updateBstatus,
            upby: `${context?.logindata?.fname} ${context?.logindata?.lname}`,
            region_type: state.bk_region_type,
            req_cur: state.usr_cur,
            pay: remarks === null ? createPayObj() : [],
            remarks: remarks,
            type: block,
            bktype : state.bkng_det.bktype,
            bkngdetails: [
              {
                tid: state.bkng_det.tid,
                pax: paxList,
                segs: segsList,
                fare: fareDetails,
              },
            ],
          };
        } else if (state.bkng_det.flow_type === "split") {
          payload = {
            booking_id: state.bkng_det.booking_id,
            bst: state.updateBstatus,
            upby: `${context?.logindata?.fname} ${context?.logindata?.lname}`,
            region_type: state.bk_region_type,
            req_cur: state.usr_cur,
            pay: remarks === null ? createPayObj() : [],
            remarks: remarks,
            type: block,
            bktype : state.bkng_det.bktype,
            bkngdetails: [
              {
                tid: onwardTid,
                pax: paxList,
                segs: segsList,
                fare: fareDetails,
              },
            ],
          };
        }
        setIsLoad(true);
        processFlightEditBooking(payload).then((resp) => {
          dispatch({ type: "showEditpop", payload: false });
          localStorage.removeItem(EDIT_SEG_DATA);
          localStorage.removeItem(EDIT_TICKET_DATA);
          localStorage.removeItem(EDIT_FARE_DETAILS);
          if (resp.suc) {
            validateEditBooking(true, status);
          } else {
            validateEditBooking(false, status);
          }
        }).catch(function (error) {
          dispatch({ type: "showEditpop", payload: false });
          setIsLoad(false);
          log.error(
            "Exception occured in edit booking function---" + error
          );
        });
      }
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please select proper Booking status",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  //Handle Edit Booking flow
  const processEditBookingFlowReturn = (block, type) => {
    let segsList = [];
    let validate = true;
    var returnTid = "";
    let value = false;
    let status = "";
    if(block === "seg") {
      status = "Segment";
    } else if(block === "pax") {
      status = "Pax";
    } else if(block === "fare") {
      status = "Fare";
    }
    //Checking Airline and GDS PNRS nad Ticket Numbers
    if(block === "seg") {
      var data = JSON.parse(localStorage.getItem(EDIT_SEG_DATA));
      if (data !== null) {
        for (let seg1 of state.rt_seg_det) {
          for (let seg2 of data) {
            if (Number(seg1.segid) === Number(seg2.segid)) {
              if (seg2.changed !== undefined && seg2.changed) {
                seg1.changed = true;
                seg1.split_airline_pnr = seg2.split_airline_pnr;
                seg1.split_gds_pnr = seg2.split_gds_pnr ? seg2.split_gds_pnr : seg2.normal_gds_pnr;
                seg1.flt_no = seg2.flt_no;
                seg1.cabin_class = seg2.cabin_class;
                seg1.fare_class = seg2.fare_class;
                seg1.operating_airline = seg2.operating_airline;   
                seg1.depart_time = seg2.depart_time;   
                seg1.arrival_time = seg2.arrival_time;  
              }
            }
          }
        }
      } else if(state.updateBstatus === "") {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Edit atleast one field and proceed",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      } else if (state.updateBstatus === booking_status_str) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Edit atleast one field and proceed",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      }
    }
    if (state.bkng_det.flow_type === "normal") {
      if (state.updateBstatus === "Confirmed") {
        value = true;
      }
    } else {
      if (state.bkng_det.booking_status === "34") {
        if (state.updateBstatus === "Partially Ticketed") {
          value = true;
        }
      } else {
        value = checkBookingStatus();
      }
    }
    if (true) {
      if(block === "seg") {
        if (state.rt_seg_det.length !== 0) {
          for (let seg of state.rt_seg_det) {
            if (state.bkng_det.flow_type === "normal") {
              if (
                seg.normal_airline_pnr !== undefined &&
                seg.normal_airline_pnr !== ""
              ) {
                if (
                  seg.normal_gds_pnr !== undefined &&
                  seg.normal_gds_pnr !== ""
                ) {
                  if (seg.changed !== undefined && seg.changed) {
                    segsList.push(getSegmentNormalObj(seg));
                    // if(validatePayment("before")) {
                    //   validate = false;
                    // }     
                  } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                    // if(validatePayment("before")) {
                      validate = true;
                      status = "status";
                    // }     
                  }
                } else {
                  validate = false;
                  validateGds("gds");
                }
              } else {
                validate = false;
                validateGds("airline");
              }
            } else {
              let tidArray = state.bkng_det.tid.split("/");
              returnTid = tidArray[1];
              if (seg.tid === returnTid) {
                if (
                  seg.split_airline_pnr !== undefined &&
                  seg.split_airline_pnr !== ""
                ) {
                  if (
                    seg.split_gds_pnr !== undefined &&
                    seg.split_gds_pnr !== ""
                  ) {
                    if (seg.changed !== undefined && seg.changed) {
                      segsList.push(getSegmentSplitObj(seg));
                      // if(validatePayment("before")) {
                      //   validate = false;
                      // }     
                    } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                      // if(validatePayment("before")) {
                        validate = true;
                        status = "status";
                      // }     
                    }
                  } else if(
                    seg.normal_gds_pnr !== undefined &&
                    seg.normal_gds_pnr !== ""
                    ) {
                      if (seg.changed !== undefined && seg.changed) {
                        segsList.push(getSegmentSplitObj(seg));
                        // if(validatePayment("before")) {
                        //   validate = false;
                        // }     
                      } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                        // if(validatePayment("before")) {
                          validate = true;
                          status = "status";
                        // }     
                      }             
                    } else {
                      validate = false;
                      validateGds("gds");
                    }
                } else {
                  validate = false;
                  validateGds("airline");
                }
              }
            }
          }
        }
      }
      let paxList = [];
      if(block === "pax") {
        var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
        if (ticketsInfo !== null && ticketsInfo.length.length !== 0) {
          for (let val of ticketsInfo) {
            // for (let val of pax) {
              if (state.bkng_det.flow_type === "normal") {
                if (
                  validatePax(val) &&
                  val.changed !== undefined &&
                  val.changed
                ) {
                  const obj = {
                    paxid: val.trv_op_id,
                    sts: val.sts,
                    tkt_no: val.tkt_no,
                    pax_name: val.pax_name,
                    pax_type: val.pax_type,
                    segst: val.segst,
                  };
                  paxList.push(obj);
                  // if(validatePayment("before")) {
                  //   validate = false;
                  // }     
                } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                  // if(validatePayment("before")) {
                    validate = true;
                    status = "status";
                  // }     
                } else {
                  validate = false;
                }
              } else {
                let tidArray = state.bkng_det.tid.split("/");
                returnTid = tidArray[1];
                if (val.tid === returnTid) {
                  if (
                    validatePax(val) &&
                    val.changed !== undefined &&
                    val.changed
                  ) {
                    const obj = {
                      paxid: val.trv_op_id,
                      sts: val.sts,
                      tkt_no: val.tkt_no,
                      pax_name: val.pax_name,
                      pax_type: val.pax_type,
                      segst: val.segst,
                    };
                    paxList.push(obj);
                    // if(validatePayment("before")) {
                    //   validate = false;
                    // }     
                  } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                    // if(validatePayment("before")) {
                      validate = true;
                      status = "status";
                    // }     
                  } else {
                    validate = false;
                  }
                }
              }
            // }
          }
        } else if(state.updateBstatus === "") {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (state.updateBstatus === booking_status_str) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        }
      }
      let fareDetails = [];
      if(block === "fare") {
        var fareInfo = JSON.parse(localStorage.getItem(EDIT_FARE_DETAILS));
        if (fareInfo !== null) {
          for (let fare1 of state.rt_fare_info[0].pasngrs[0].pax) {
            for (let fare2 of fareInfo) {
              if (fare1.trv_id === fare2.trv_id) {
                if (fare2.changed !== undefined && fare2.changed) {
                  fare1.changed = true;
                  fare1.purchase.basefare = fare2.purchase.basefare;
                  fare1.taxes = fare2.taxes;
                  fare1.sales.basefare = fare2.sales.basefare;
                  fare1.sales.markup = fare2.sales.markup;
                  fare1.sales.servicefee = fare2.sales.servicefee;
                }
              }
            }
          }
          
        if (state.rt_fare_info[0].pasngrs[0].pax.length !== 0) {
          for (let fare of state.rt_fare_info[0].pasngrs[0].pax) {
            if (state.bkng_det.flow_type === "normal") {
              if (fare.changed !== undefined && fare.changed) {
                const obj = {
                  fareid: fare?.trv_id,
                  trv_op_id: fare?.trv_op_id,
                  taxes: fare.taxes,
                  purchase: {
                    ...fare.purchase,
                    basefare : fare.purchase.basefare,
                  },
                  sales: {
                    ...fare.sales,
                    basefare: fare.sales.basefare,
                    markup: fare.sales.markup,
                    servicefee: fare.sales.servicefee,
                  },
                };
                fareDetails.push(obj);
                // if(validatePayment("before")) {
                //   validate = false;
                // }     
              } else if (state.updateBstatus !== "" && state.updateBstatus !== booking_status_str) {
                // if(validatePayment("before")) {
                  validate = true;
                  status = "status";
                // }     
              }
            } else {
              let tidArray = state.bkng_det.tid.split("/");
              returnTid = tidArray[1];
              if (fare.changed !== undefined && fare.changed) {
                const obj = {
                  fareid: fare?.trv_id,
                  trv_op_id: fare?.trv_op_id,
                  taxes: fare.taxes,
                  purchase: {
                    ...fare.purchase,
                    basefare : fare.purchase.basefare,
                  },
                  sales: {
                    ...fare.sales,
                    basefare: fare.sales.basefare,
                    markup: fare.sales.markup,
                    servicefee: fare.sales.servicefee,
                  },
                };
                fareDetails.push(obj);
                // if(validatePayment("before")) {
                //   validate = false;
                // }     
              } 
            }
          }
        }
        } else if(state.updateBstatus === "") {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (state.updateBstatus === booking_status_str) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Edit atleast one field and proceed",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        }
      }
      var payload = "";
      if (validate || type === "final") {
        if (state.bkng_det.flow_type === "normal") {
          payload = {
            booking_id: state.bkng_det.booking_id,
            bst: state.updateBstatus,
            upby: `${context?.logindata?.fname} ${context?.logindata?.lname}`,
            region_type: state.bk_region_type,
            req_cur: state.usr_cur,
            pay: remarks === null ? createPayObj() : [],
            remarks: remarks,
            type: block,
            bktype : state.bkng_det.bktype,
            bkngdetails: [
              {
                tid: state.bkng_det.tid,
                pax: paxList,
                segs: segsList,
                fare: fareDetails,
              },
            ],
          };
        } else if (state.bkng_det.flow_type === "split") {
          payload = {
            booking_id: state.bkng_det.booking_id,
            bst: state.updateBstatus,
            upby: `${context?.logindata?.fname} ${context?.logindata?.lname}`,
            region_type: state.bk_region_type,
            req_cur: state.usr_cur,
            pay: remarks === null ? createPayObj() : [],
            remarks: remarks,
            type: block,
            bktype : state.bkng_det.bktype,
            bkngdetails: [
              {
                tid: returnTid,
                pax: paxList,
                segs: segsList,
                fare: fareDetails,
              },
            ],
          };
        }
        processFlightEditBooking(payload).then((resp) => {
          dispatch({ type: "showEditpop", payload: false });
          localStorage.removeItem(EDIT_SEG_DATA);
          localStorage.removeItem(EDIT_TICKET_DATA);
          localStorage.removeItem(EDIT_FARE_DETAILS);          
          if (resp.suc) {
            validateEditBooking(true, status);
          } else {
            validateEditBooking(false, status);
          }
        }).catch(function (error) {
          dispatch({ type: "showEditpop", payload: false });
          log.error(
            "Exception occured in edit booking function---" + error
          );
        });
      }
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please select proper Booking status",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const createPayObj = () => {
    let payObj = [];
    let uniq_pay = "";
    let bank_pay = "";
    let che_pay = "";
    if (state.payment_details.is_pay_uniq_id) {
      dispatch({
        type: "pay_uniq_id",
        payload: state.payment_details.pay_uniq_id,
      });
      uniq_pay = {
        pay_uniq_id: state.payment_details.pay_uniq_id,
        ptype: "Pay UID",
        amt: state.payment_details.paid_amount,
        psta: "Confirmed",
      };
      payObj.push(uniq_pay);
    }
    if (state.payment_details.is_bank_transfer) {
      bank_pay = {
        bank_name: state.payment_details.bank_name,
        ac_number: state.payment_details.ac_number,
        cust_name: state.payment_details.cust_name,
        trans_number: state.payment_details.trans_number,
        date_of_transfer: state.payment_details.date_of_transfer,
        psta: "Confirmed",
        amt: state.payment_details.total_amt,
        ptype: "Bank Transfer",
      };
      payObj.push(bank_pay);
    }
    if (state.payment_details.is_cheque) {
      che_pay = {
        bank_name: state.payment_details.che_bank_name,
        ac_number: state.payment_details.che_ac_number,
        trans_number: state.payment_details.che_trans_number,
        psta: "Confirmed",
        ptype: "Cheque",
        amt: state.payment_details.che_total_amt,
      };
      payObj.push(che_pay);
    }

    return payObj;
  };

  const validateEditBooking = (suc, block) => {
    if (suc) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: block === "status" ? "Booking status updated Successfully" : block ? block + " details are updated Successfully" : "Booking details are updated Successfully",
      });
      dispatch({ type: "notiVarient", payload: "success" });
      setTimeout(() => {
        setIsLoad(false);
        return (window.location = window.location.pathname);
      }, 2000);
    } else {
      setIsLoad(false);
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Something went wrong. Booking details are not updated",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  //Validate the Pax
  const validatePax = (pax) => {
    if (pax.tkt_no === undefined || pax.tkt_no === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please Enter the Ticket numbers.",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } 
    // else if (pax.sts !== "Confirmed") {
    //   dispatch({ type: "notiMessageShow", payload: true });
    //   dispatch({
    //     type: "notiMessage",
    //     payload: "Please select proper Ticketing status",
    //   });
    //   dispatch({ type: "notiVarient", payload: "danger" });
    //   hidemessage();
    //   return false;
    // } 
    else if (pax.tkt_no !== undefined && pax.tkt_no.length !== 14) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload:
          "Please provide valid Ticket number and include hyfen after ticketing Code",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    return true;
  };

  const checkBookingStatus = () => {
    if (state.onwardAvail && state.returnAvail) {
      if (state.updateBstatus === "Partially Ticketed") {
        return true;
      } else {
        return false;
      }
    } else if (
      (!state.onwardAvail && state.returnAvail) ||
      (state.onwardAvail && !state.returnAvail)
    ) {
      if (state.updateBstatus === "Confirmed") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const validateGds = (type) => {
    if (type === "gds") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Please enter the GDS PNR." });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter the Airline PNR.",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const getSegmentNormalObj = (seg) => {
    const obj = {
      segid: seg.segid,
      normal_gds_pnr: seg.normal_gds_pnr,
      normal_airline_pnr: seg.normal_airline_pnr,
      depart_time: seg.depart_time,
      arrival_time: seg.arrival_time,
      flt_no: seg.flt_no,
      cabin_class: seg.cabin_class,
      fare_class: seg.fare_class,
      operating_airline: seg.operating_airline,
    };
    return obj;
  };

  const getSegmentSplitObj = (seg) => {
    const obj = {
      segid: seg.segid,
      split_gds_pnr: seg.split_gds_pnr ? seg.split_gds_pnr : seg.normal_gds_pnr,
      split_airline_pnr: seg.split_airline_pnr,
      depart_time: seg.depart_time,
      arrival_time: seg.arrival_time,
      flt_no: seg.flt_no,
      cabin_class: seg.cabin_class,
      fare_class: seg.fare_class,
      operating_airline: seg.operating_airline,
    };
    return obj;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  };

  const handleClose = () => {};

  const closeDialog = () => {
    dispatch({ type: "enableManualReissue", payload: false });
    dispatch({ type: "insertPnr", payload: "" });
  };

  const updatePaxTkts = (data) => {
    dispatch({ type: "insertPax", payload: data });
  };

  const cancelBookUpdate = (data) => {
    dispatch({ type: 'isCancelBook', payload: data })
  }

   //Call refund service to get the details
   const continueRefund = (data) => {
    // dispatch({type:"isLoading",payload:true})
    let journeyType = "";
    let valid = false
     if(data[0].length!==0){
       valid = true
     }
    if (valid) {
      let btype = 'partial'
      setIsLoadingMore(true);
      let req = []
      if (state.pax_det.length > 0) {
        req.push(state.pax_det[0].source)
        req.push(state.pax_det[0].destination)
      }
      let arr = [] //country codes
      // getCountryCodes(req).then((res) => 
      // {
      //   if (res.data !== undefined && res.data.length > 0) {
      //     for (let val of res.data) {
      //       arr.push(val.country_code)
      //     }
      //   }
        var tid = "";
        var ofcid = "";
        let pnr = '';
        if (state.bkng_det !== undefined && state.bkng_det.flow_type === "normal") {
          tid = state.bkng_det.tid;
          ofcid = state.bkng_det.office_id;
          pnr = state.seg_det[0].normal_gds_pnr;
          if (state.bkng_det.travel_type == 1) {
            journeyType = "O"
          } else if (state.bkng_det.travel_type == 2) {
            journeyType = "O/R"
          } else {
            journeyType = "O"
          }
        } else {
          var type = data[2];
          let tidAry = state.bkng_det.tid.split("/");
          let ofcidAry = state.bkng_det.office_id.split("/");
          let pnrArray = state.seg_det[0].normal_gds_pnr.split(",");
          if (type === "Onward") {
            let tid_c = ""
            let ofcid_c = ""
            let pnr_c = ""
            if (state.bktype.toUpperCase() !== "REISSUE") {
              tid_c = tidAry[0]
              ofcid_c = ofcidAry[0]
              pnr_c = pnrArray[0]
            } else {
              //if reissue latest pnr and tid for reqpective itenary is need so selecteing form seg_det
              tid_c = state.seg_det[0].tid
              ofcid_c = state.seg_det[0].office_id
              pnr_c = state.seg_det[0].normal_gds_pnr
            }
            tid = tid_c;
            ofcid = ofcid_c;
            pnr = pnr_c;
            journeyType = "O";
          } else {
            //return
            let tid_c = ""
            let ofcid_c = ""
            let pnr_c = ""
            if (state.bktype.toUpperCase() !== "REISSUE") {
              tid_c = tidAry[1]
              ofcid_c = ofcidAry[1]
              pnr_c = pnrArray[1]
            } else {
              //if reissue latest pnr and tid for reqpective itenary is need so selecteing form seg_det
              tid_c = state.seg_det[1].tid
              ofcid_c = state.seg_det[1].office_id
              pnr_c = state.seg_det[1].normal_gds_pnr
            }
            tid = tid_c;
            ofcid = ofcid_c;
            pnr = pnr_c;
            journeyType = "R";
          }
        }
        if (state.bkng_det.flow_type === "normal") {
          let circulated_arr = data[0].concat(state.ticketsRefunded)
          if (uniqueJson(circulated_arr).length === state.allTktslength) {
            btype = 'full'
          }
          // journeyType="O";
        } else {
          /****split *****/
          let type = ""
          let refundedTkts = []
          //checking if selected ticket is onward or return
          for (let val of data[0]) {
            if (state.owpaxTNO?.includes(val)) {
              type = "onward"
              journeyType = "O";
            } else {
              type = "return"
              journeyType = "R";
            }
          }
          if (type == "onward") {
            refundedTkts = data[0].concat(state.ticketsRefunded[0])
          } else {
            refundedTkts = data[0].concat(state.ticketsRefunded[1])
          }
          if (uniqueJson(refundedTkts).length === state.allTktslength) {
            btype = 'full'
          }
        }
        //Tickets Info
        let source = "";
        let destination = "";
        let dd = "";
        let ad = "";
        let paxInfo=[];
        if(data[1]==="Manual Refund" && data[3] === undefined){
          for(let tkt of data[0]){
            if(state.bkng_det !== undefined && state.bkng_det.flow_type === "normal"){
              let paxList =state.pax_det.filter((pax)=>pax.tkt_no===tkt);
              paxInfo.push(paxList[0]);
              source = state.pax_det[0].source;
              destination = state.pax_det[0].destination;
              dd = DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD");
              ad = DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD");
            }else{
              if(journeyType==="O"){
                let paxList =state.pax_det.filter((pax)=>pax.tkt_no===tkt);
                paxInfo.push(paxList[0]);
                source = state.pax_det[0].source;
                destination = state.pax_det[0].destination;
                dd = DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD");
                ad = DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD");
              }else if(journeyType==="R"){
                if(state.rt_pax_det!==undefined && state.rt_pax_det.length > 0){
                  let paxList =state.rt_pax_det.filter((pax)=>pax.tkt_no===tkt);
                  paxInfo.push(paxList[0]);
                  source = state.rt_pax_det[0].source;
                  destination = state.rt_pax_det[0].destination;
                  dd = DateUtils.prettyDate(state.rt_seg_det[0].depart_time, "YYYY-MM-DD");
                  ad = DateUtils.prettyDate(state.rt_seg_det[0].arrival_time, "YYYY-MM-DD");
                }
              }
            }
          }
        } else if(data[1]==="Manual Refund" && data[3]) {
          for(let tkt of data[0]){
            if(state.bkng_det !== undefined && state.bkng_det.flow_type === "normal"){
              let paxList =state.pax_det.filter((pax)=>pax.trv_id===tkt.trv_id);
              paxInfo.push(paxList[0]);
              source = state.pax_det[0].source;
              destination = state.pax_det[0].destination;
              dd = DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD");
              ad = DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD");
            }else{
              if(data[2]==="Onward"){
                let paxList =state.pax_det.filter((pax)=>pax.trv_id===tkt.trv_id);
                paxInfo.push(paxList[0]);
                source = state.pax_det[0].source;
                destination = state.pax_det[0].destination;
                dd = DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD");
                ad = DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD");
              }else if(data[2]==="Return"){
                if(state.rt_pax_det!==undefined && state.rt_pax_det.length > 0){
                  let paxList =state.rt_pax_det.filter((pax)=>pax.trv_id===tkt.trv_id);
                  paxInfo.push(paxList[0]);
                  source = state.rt_pax_det[0].source;
                  destination = state.rt_pax_det[0].destination;
                  dd = DateUtils.prettyDate(state.rt_seg_det[0].depart_time, "YYYY-MM-DD");
                  ad = DateUtils.prettyDate(state.rt_seg_det[0].arrival_time, "YYYY-MM-DD");
                }
              }
            }
          }
        }
        const payload = {
          "tid": tid,
          "rtype": data[1],
          "btype": btype,
          "cancelPnr": state.isCancelBook,
          "pnr": pnr,
         //  "ofcid": "DOHQR2718",
          "office_id": ofcid,
          // "butype": context.logindata.butype,
          "tkts": state.isCancelBook ? [] : data[0],
          "tkts_info":paxInfo,
          "bref": state.bkng_det.booking_id,
          "ac": state.aircodes,
          "dep": source,
          "arr": destination,
          "cid": state.bkng_det.company_id,
          "pcid": (context?.logindata?.pcid !== null || context?.logindata?.pcid !== undefined) ? context?.logindata?.pcid : 0,
          "tt": parseInt(state.seg_det[0].trvel_type),
          "cls": state.seg_det[0].cabin_class,
          "supplier": "Amadeus",
          "sc_code": arr[0],
          "dc_code": arr[1],
          "dd": dd,
          "ad": ad,
          "bf": new Date(),
          "bt": new Date(),
          "rbd": "",
          "ftype": "Refund",
          "bid": state.bkng_det.booking_id,
          "paxCount": state.allTktslength,
          "cus_code": state.cus_code,
          "bktype" : state.bkng_det.bktype,
          "region_type": state.bk_region_type, //journey type
          "req_cur": state.usr_cur
        }
        getRefundDetails(payload, data[1]).then((resp) => {
          if ((resp?.refundPricingRes?.suc && resp?.refundPricingRes?.data !== undefined && resp?.refundPricingRes?.data?.psngrs?.length !== 0)) {
            setIsLoadingMore(false);
            navigate('/crm/flight/manual-refund', {
              state: {
                req: resp.refundPricingRes,
                bref: state.bkng_det.booking_id,
                type: data[2],
                journey_type: journeyType,
                region_type:state.bk_region_type,
                req_cur:state.usr_cur
              }
            });
          } else {
            setIsLoadingMore(false);
            // dispatch({type:"isLoading",payload:false})
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in refund pricing function---" + error
          );
        });
      // })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(data[0].length!==0){
        dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later' })
      }else{
       dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Refund' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }

  function uniqueJson(jsonarray) {
    var jsonarrayfilter = jsonarray.filter((data) => data!==undefined)
    let myArrSerialized = jsonarrayfilter.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

    return myUniqueArr;
  }


  function uniquearray(array) {
    var uniqueArray = [];

    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  
  const handlerefundCancelBooking = () => {
    dispatch({ type: "splitCancel", payload: true })
    dispatch({ type: "refundenable", payload: true })
    dispatch({ type: "voidenable", payload: false })
    dispatch({ type: "enableVoidBooking", payload: false })
    dispatch({ type: "enableVoidCancel", payload: false })
  }

  const pnrFetch = (e) => {
    dispatch({ type: "splitPNR", payload: e })
  }
  
  const openSplitRefundMOre = () => {
    if (editrefundMore) {
      setrefundEditMore(false)
    } else {
      setrefundEditMore(true)
    }
  }
  //Handle Booking Status
  const updateBstatus = (data) => {
    dispatch({ type: 'updateBstatus', payload: data })
  }

  const updatePayment=(data)=>{
    dispatch({ type: 'payment_details', payload: data })
  }

  const handleRadioChange = (value) => {
    setPaymentActive(value);
    setRemarks(null);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu />
          </Col>
        
          {isLoadingMore ? <Col xs={10} className="mainftr"> <div className='loadding'><span class="loadernew"></span></div></Col> : 
          <Col xs={10} className="mainftr">
            {state.notiMessageShow && (
              <Alert
                className="notification alert"
                variant={state.notiVarient}
                show={state.notiMessageShow}
                style={{zIndex:'9999'}}
                onClose={() =>
                  dispatch({ type: "notiMessageShow", payload: true })
                }
                dismissible
              >
                {" "}
                {state.notiMessage}
              </Alert>
            )}
            {state?.loading && (
              <div className="loadding noDatafound">
                <span class="loadernew"></span>
              </div>
            )}
            {!state?.loading &&
              state?.fltresponse !== undefined &&
              state?.fltresponse !== null && (
                <div className="resultSection boxShadow summary">
                  <div className="transactionSummery bg-white avoidOverhidde">
                    <h5 className="title d-flex justify-content-between">
                      Booking Details
                    </h5>
                    <TransactionSummary
                      bkngdetails={state.fltresponse?.bkng_info}
                      enableEdit={state.enableEdit}
                      sendBookStatus={updateBstatus}
                    />
                  </div>
                  <div className="hotelreviewDetailsbooSummary bg-white">
                    <div className="bookingSUmmary flightSUm">
                      <div className="d-flex justify-content-between">
                      <h5 className="pageTitle d-flex justify-content-between justify-items-center">
                        Booking Summary
                      </h5>
                      { (state.flt_supplier === 0 && state.bkng_det?.booking_status ==23) &&
                            <div className='socialIcons '>
                           <Button className="prient ezyIcon icon-share" onClick={()=>openshareevent("O")}  variant="link"></Button>
                </div>}
                </div>
                      {state?.fltresponse?.onword_info &&
                        state?.fltresponse?.onword_info?.seg_det &&
                        state?.fltresponse?.onword_info?.seg_det.length > 0 && (
                          <div className="hotelReviewDetails marginTop30 position-relative">                            
                            <div className="selectMangeBook topRight">
                              <Select
                                value={manageBookingOnward}
                                isSearchable={false}
                                onChange={(selectedOption) => handleChangeType(selectedOption, "ONWARD")}
                                options={state.flt_supplier === 0 && (state.bkng_det?.booking_status == "23" || state.manageBooking.length > 0) ? SelectOptions : state.flt_supplier === 0 ? SelectOptions1 : []}
                                placeholder="Manage Booking"
                                classNamePrefix="filter"
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#F0F0F0",
                                    primary: "#3156ab",
                                    cursor: "pointer",
                                  },
                                })}
                              />
                            </div>
                            <FlightSummary
                              book_info={state?.fltresponse?.bkng_info}
                              itiData={state?.fltresponse?.onword_info}
                              base_usr_exrate={
                                state?.fltresponse?.bkng_info?.base_usr_exrate
                              }
                              travel_type="Onward"
                              mappings={state?.fltresponse?.mappings}
                              isEdit={state.enableOnwardEdit}
                              enableRefundTkt={state.enableRefund}
                              bookingId={booking_id}
                              sendRefund={continueRefund}
                              cancelRefundblock={cancelRefundblock}
                              processEditBookingFlow={processEditBooking}
                              btnLoader={isLoad}
                              sendCancelBook={cancelBookUpdate}
                            />
                            {state.refundAvailonward && state.bktype !== 'IMPORT' &&
                              <FlightSummaryRefund refundResp={request} />
                            }

                            {/* Displaying Refund Split PNR Details */}
                            {state.refundAvailonward && isArrayNotEmpty(state.refund_split_onward) && state.bktype !== 'IMPORT' &&
                              <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                                <h5 className="title d-flex justify-content-between">Refund Split PNR 
                                {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitRefundMOre}> */}
                                  <div className={"showDetails " + (editrefundMore ? 'active' : 'inactive')}>
                                    <ul className="d-flex flex-column">
                                      {/* {state.showCancel && <li>
                                        <Link to="#" onClick={handlerefundCancelBooking}>Cancel Booking</Link>
                                      </li>} */}
                                    </ul>
                                  </div>
                                {/* </span> */}
                                </h5>
                                {isArrayNotEmpty(state.refund_split_onward) &&
                                  <SplitPnr res={state.refund_split_onward} type={"Refund"} refundEnable={state.refundenable} pnr={pnrFetch} />
                                }
                              </div>} 
                              {/* {state?.fltresponse?.pay_info &&
                              state?.fltresponse?.pay_info.length > 0 && (
                                <div className="hotelReviewDetails marginTop30">
                                  <PaymentSummary
                                    payments={state?.fltresponse?.bkng_info?.flow_type !== "split" ? 
                                    state?.fltresponse?.pay_info : 
                                    state?.fltresponse?.pay_info.filter((pay) => state?.fltresponse?.onword_info?.seg_det[0]?.tid === pay.tid)}
                                    base_usr_exrate={
                                      state?.fltresponse?.bkng_info?.base_usr_exrate
                                    }
                                  />
                                </div>
                              )} */}
                            {state.onwardReissueList !== undefined && state.onwardReissueList.length > 0 && state.onwardReissueList.map((seg,index) => (
                              <div key={index}>
                              <h5 className="historyTitle mt-4">History</h5>
                              <div className="transactionSummery mb-3">
                                <h5 className="title d-flex justify-content-between">
                                  Booking Details
                                </h5>
                                <TransactionSummary
                                  bkngdetails={seg.flight_info?.bkng_info}
                                  isEdit={state.enableEdit}
                                />
                              </div>
                              <FlightSummary
                                book_info={state?.fltresponse?.bkng_info}
                                itiData={seg.flight_info}
                                base_usr_exrate={
                                  state?.fltresponse?.bkng_info?.base_usr_exrate
                                }
                                travel_type="Onward"
                                mappings={state?.fltresponse?.mappings}
                                bookingId={booking_id}
                                sendRefund={continueRefund}
                                cancelRefundblock={cancelRefundblock}
                              />                              
                              <div className="hotelReviewDetails marginTop30">
                                <PaymentSummary
                                  payments={seg.flight_info?.fare_info[0]?.paydetails}
                                  base_usr_exrate={
                                    state?.fltresponse?.bkng_info?.base_usr_exrate
                                  }
                                />
                              </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {state?.fltresponse?.onword_info &&
                        state?.fltresponse?.return_info?.seg_det &&
                        state?.fltresponse?.return_info?.seg_det.length > 0 && (
                          <div className="hotelReviewDetails marginTop30">                            
                            <div className="selectMangeBook topRight">
                            { (state.flt_supplier === 0 && state.bkng_det?.booking_status ==23) &&<div className='socialIcons'>
                           <Button className="prient ezyIcon icon-share" onClick={()=>openshareevent("R")}  variant="link"></Button>
                </div>}
                              <Select
                                value={manageBookingReturn}
                                isSearchable={false}
                                onChange={(selectedOption) => handleChangeType(selectedOption, "RETURN")}
                                options={state.flt_supplier_return === 0 && (state.bkng_det?.booking_status == "23" || state.rt_manageBooking.length > 0) ? SelectOptions : state.flt_supplier_return === 0 ? SelectOptions1 : []}
                                placeholder="Manage Booking"
                                classNamePrefix="filter"
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#F0F0F0",
                                    primary: "#3156ab",
                                    cursor: "pointer",
                                  },
                                })}
                              />
                            </div>
                            <FlightSummary
                              book_info={state?.fltresponse?.bkng_info}
                              itiData={state?.fltresponse?.return_info}
                              base_usr_exrate={
                                state?.fltresponse?.bkng_info?.base_usr_exrate
                              }
                              travel_type="Return"
                              mappings={state?.fltresponse?.mappings}
                              isEdit={state.enableReturnEdit}
                              enableRefundReturnTkt={state.enableRefundReturn}
                              bookingId={booking_id}
                              sendRefund={continueRefund}
                              cancelRefundblock={cancelRefundblock}
                              processEditBookingFlow={processEditBooking}
                              btnLoader={isLoad}
                              sendCancelBook={cancelBookUpdate}
                            />
                            {state.refundAvailreturn && state.bktype !== 'IMPORT' &&
                              <FlightSummaryRefund refundResp={request} />
                            }

                            {/* Displaying Refund Split PNR Details */}
                            {state.refundAvailreturn && isArrayNotEmpty(state.refund_split_return) && state.bktype !== 'IMPORT' &&
                              <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                                <h5 className="title d-flex justify-content-between">Refund Split PNR 
                                {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitRefundMOre}> */}
                                  <div className={"showDetails " + (editrefundMore ? 'active' : 'inactive')}>
                                    <ul className="d-flex flex-column">
                                      {/* {state.showCancel && <li>
                                        <Link to="#" onClick={handlerefundCancelBooking}>Cancel Booking</Link>
                                      </li>} */}
                                    </ul>
                                  </div>
                                {/* </span> */}
                                </h5>
                                {isArrayNotEmpty(state.refund_split_return) &&
                                  <SplitPnr res={state.refund_split_return} type={"Refund"} refundEnable={state.refundenable} pnr={pnrFetch} />
                                }
                              </div>} 
                            {/* {state?.fltresponse?.pay_info &&
                              state?.fltresponse?.pay_info.length > 0 && (
                                <div className="hotelReviewDetails marginTop30">
                                  <PaymentSummary
                                    payments={state?.fltresponse?.bkng_info?.flow_type !== "split" ? 
                                    state?.fltresponse?.pay_info : 
                                    state?.fltresponse?.pay_info.filter((pay) => state?.fltresponse?.return_info?.seg_det[0]?.tid === pay.tid)}
                                    base_usr_exrate={
                                      state?.fltresponse?.bkng_info?.base_usr_exrate
                                    }
                                  />
                                </div>
                              )} */}
                            {state.returnReissueList !== undefined && state.returnReissueList.length > 0 && state.returnReissueList.map((seg,index) => (
                              <div key={index}>
                              <h5 className="historyTitle">History</h5>
                              <div className="transactionSummery">
                                <h5 className="title d-flex justify-content-between">
                                  Booking Details
                                </h5>
                                <TransactionSummary
                                  bkngdetails={seg.flight_info?.bkng_info}
                                  isEdit={state.enableEdit}
                                />
                              </div>
                              <FlightSummary
                                book_info={state?.fltresponse?.bkng_info}
                                itiData={seg.flight_info}
                                base_usr_exrate={
                                  state?.fltresponse?.bkng_info?.base_usr_exrate
                                }
                                travel_type="Onward"
                                mappings={state?.fltresponse?.mappings}
                                bookingId={booking_id}
                                sendRefund={continueRefund}
                              />                           
                              <div className="hotelReviewDetails marginTop30">
                                <PaymentSummary
                                  payments={seg.flight_info?.fare_info[0]?.paydetails}
                                  base_usr_exrate={
                                    state?.fltresponse?.bkng_info?.base_usr_exrate
                                  }
                                />
                              </div>
                              </div>
                            ))}
                          </div>
                        )}   
                        
                        {state?.fltresponse?.pay_info &&
                          state?.fltresponse?.pay_info.length > 0 && (
                            <div className="hotelReviewDetails marginTop30">
                              <PaymentSummary
                                payments={state?.fltresponse?.bkng_info?.flow_type !== "split" ? 
                                state?.fltresponse?.pay_info : 
                                state?.fltresponse?.pay_info.filter((pay) => state?.fltresponse?.onword_info?.seg_det[0]?.tid === pay.tid)}
                                base_usr_exrate={
                                  state?.fltresponse?.bkng_info?.base_usr_exrate
                                }
                              />
                            </div>
                          )}              
                    </div>   
                  </div>
                </div>
              )}
            {!state?.loading &&
              (state?.fltresponse === undefined ||
                state?.fltresponse === "") && (
                <div className="noDatafound">
                  <div>No data found</div>
                </div>
              )}

            {state.enableEditBooking && (              
            <div className="commonWrap" ref={paymentredirect}>
            <label className="paymentMode">
              <input
                type="radio"
                value={paymentActive}
                checked={paymentActive === "payment"}
                onChange={() => handleRadioChange("payment")}
                className="paymentModeInput"
              />
              Continue with payment
            </label>
            <label className="paymentMode">
              <input
                type="radio"
                value={paymentActive}
                checked={paymentActive === "remarks"}
                onChange={() => handleRadioChange("remarks")}
                className="paymentModeInput ml-5"
              />
              Continue without payment
            </label>

            
            {paymentActive === "payment" && (
              
              <PaymentDetails
                sendPaymentinfo={updatePayment} 
                region_type={state.region_type}
              />
              
            )}

            {paymentActive === "remarks" && (
             <>
             <h5 class="layoutTitle  d-flex justify-content-between mb-3"><span>Remarks<sup>*</sup></span></h5>
              <textarea
                value={remarks}
                onChange={handleRemarksChange}
                placeholder="Enter remarks"
                className="remarksArea"
                maxLength={100}
              />
             </>
            )}

            <div className="text-right buttonGrop border-0 pt-4 pb-0">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  className="mr-3"
                  type="submit"
                  onClick={cancelEdit}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  size="xs"
                  variant="primary"
                  type="submit"
                  onClick={callEditBooking}
                >
                  {" "}
                  Update
                </Button>
                </div>
            
            </div>
            )}

<Modal
        show={state.showPopup}
        onHide={handleemailClose}
        className="importPNRMdl shareIpop searchPNRPopup"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>Share Itinerary</Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-end">
          <Form.Group controlId="uem">
              <Form.Label>Email Id <sup>*</sup></Form.Label>
              <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
              {state.shareAlert && 
              <small style={{ color: "red" }}>
              {state.shareAlert}</small>}
            </Form.Group>
            <Button variant="primary" onClick={sendEmail}>Submit
          </Button>
          </div>
        </Modal.Body>
        </Modal>

            {state.showEditpop && (
              <Modal
                show={state.showEditpop}
                onHide={oncloseEidt}
                className="importPNRMdl editpnrPop"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton className="border-bottom-0">
                  <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <strong>
                      Are you sure! You want to proceed with the Edit Booking?{" "}
                    </strong>

                    {/* <p className="text-center d-flex mb-2">
                      <span className="text-secondary mr-1 "> NOTE : </span>{" "}
                      Please cross check all GDS PNR's Should be same.
                    </p> */}
                  </div>
                </Modal.Header>
                <Modal.Footer className="justify-content-center border-top-0 pb-4">
                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={oncloseEidt}
                    type="submit"
                  >
                    No
                  </Button>
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={processEditBookingFlow}
                    type="submit"
                  >
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            <Footer />
          </Col>
          }
        </Row>
      </Container>
      {!state.enableReissue && (
        <Modal
          show={searchPNR}
          // onHide={onclosePNR}
          className="searchPNRPopup"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="border-bottom-0">
            <Form.Group className="m-0 p-0" as={Col} controlId="insertPnr">
              <Form.Label>
                PNR<sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter PNR"
                value={state.insertPnr}
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Header>
          <Modal.Footer>
            <Button className="btn btn-outline-secondary" onClick={onclosePNR}>
              Cancel
            </Button>
            {!isLoad ? (
              <Button variant="primary" onClick={retrivePNRData}>
                Submit
              </Button>
            ) : (
              <div class="progress progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Processing...</span>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      )}

      {state.enableManualReissue && (
        <Modal
          show={state.enableManualReissue}
          className={
            state.enablePnrResp && state.manualResponse !== ""
              ? "morefaeoption inpnr"
              : "" + "reissuePnrpop"
          }
          backdrop="static"
          keyboard={false}
          size={state.enablePnrResp && state.manualResponse !== "" ? "xl" : ""}
        >
          <Modal.Header>
          <Form.Row>
                <Form.Group
                  className="m-0 pnrWrap"
                  as={Col}
                  xs={
                    state.enablePnrResp && state.manualResponse !== ""
                      ? "12"
                      : "12"
                  }
                  controlId="insertPnr"
                >
                  <Form.Label className="pnrTitle">
                    PNR <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={state.enablePnrResp}
                    required
                    placeholder="Enter PNR"
                    value={state.insertPnr}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
          </Modal.Header>
          <Modal.Body
            className={
              state.enablePnrResp && state.manualResponse !== "" ? "" : ""
            }
          >
            <div className="pnrSummaryInput dd">
              
              {state.isItineraryLoading ? (
                <div class="loaderPageBackdrop">
                  <div class="loaderPage">
                    <div class="loaderIcon"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.enablePnrResp && state.manualResponse !== "" && (
                // <ShowManualPnrData pnrResponse={state.manualResponse} sendPaxInfo={updatePaxTkts} enableReissue={state.continueReissue} closePopup={closeDialog} bookingId={state.bkng_det.booking_id} paxList={state.pax_det} oldtid={state.oldTid} userCurrency={state.usr_cur}/>
                <ShowManualPnrData
                  pnrResponse={state.manualResponse}
                  sendPaxInfo={updatePaxTkts}
                  closePopup={closeDialog}
                  paxList={state.pax_det}
                  bookingId={booking_id}
                  oldtid={state.oldTid}
                  userCurrency={state.usr_cur}
                />
              )}
            </div>
          </Modal.Body>
          {!state.enablePnrResp && (
              
          <Modal.Footer className="d-flex justify-content-end border-top pt-3">
            
              <Button
                size="xs"
                variant="outline-primary"
                onClick={handleClose}
                type="submit"
              >
                Cancel
              </Button>
              <Button
                size="xs"
                variant="primary"
                onClick={() => retrivePNRData()}
                type="submit"
              >
                Submit
              </Button>
              </Modal.Footer>
            )}
          
        </Modal>
      )}
    </>
  );
}
export default FlightSummaryPage;
