import CRMCommonService from '../../services/CRMCommonService';

/**
 * @author Praveen Varma
 * @date 04-12-2023
 * @description Process payment
 * @param {*} props 
 * @returns 
 */
export const processPayment = async (obj) => {
    let finalRes = [];
    await CRMCommonService.processPayment(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @author Praveen Varma
 * @date 04-12-2023
 * @description Mongo pesistace payment req and resp
 * @param {*} props 
 * @returns 
 */
export const savePaymentRequest = async (obj) => {
    let finalRes = [];
    await CRMCommonService.savePayRequest(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @author Praveen Varma
 * @date 04-12-2023
 * @description Process payment
 * @param {*} props 
 * @returns 
 */
 export const validatePaymentUrl = async (obj) => {
    let finalRes = [];
    await CRMCommonService.validatePaymentUrl(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const getPaymentInfo = async (obj) => {
    let finalRes = [];
    await CRMCommonService.getPaymentInfo(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

