import React from "react";
import FormatCurrency from "../../../utilities/FormatCurrency";
import { useContext } from "react";
import { Context } from "../../../../App";

function PaymentDetails(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
      <div level={3} className="heading">
        {"Payment Details"}
      </div>
      <div className="tableHeader row m-0">
        <div className="tableValue col-md">
          {"Mode of Payment"}
        </div>
        <div className="tableValue col-md">
          {"Card Type"}
        </div>
        <div className="tableValue col-md">
          {"Card Details"}
        </div>
        <div className="tableValue col-md">
          {"Amount Paid"}
        </div>
      </div>
      {props.payment_info !== undefined &&
        <div className="tableData row m-0">
          <div className="tableValue col-md">
            {props.payment_info.ptype} {"CARD"}
          </div>
          <div className="tableValue col-md">
            {props.payment_info.ctype}
          </div>
          <div className="tableValue col-md">
            xxxxxxxxxxxx{props.payment_info.cno}
          </div>
          <div className="tableValue wordBreak col-md">
            {props.payment_info.ccode}  <FormatCurrency value={props.payment_info.amt * (props.base_usr_exrate ? props.base_usr_exrate : 1)} className="currencyAmount" />
          </div>
        </div>
      }
    </>
  );
}

export default PaymentDetails;
