import axios from 'axios'

const B2C_ENGINE_URL = process.env.REACT_APP_B2C_ENGINE_URL
const CRM_ENGINE_URL = process.env.REACT_APP_CRM_ENGINE_URL
const B2C_HOTEL_COMMON_URL = process.env.REACT_APP_B2C_HOTEL_COMMON
const PAY_API_URL = process.env.REACT_APP_CHKOUT_PAY_URL;
const SK_KEY = process.env.REACT_APP_PAY_SECURITY_KEY;


/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations service
 * @param {*} Service 
 */
class CRMCommonService {

  async quicksearch(req) {
    return await axios.get(
      `${CRM_ENGINE_URL}/crmc/dashboard/quick-search?id=${req.id}&uid=${req.uid}&is_admin=${req.is_admin}&tag=${req.tag}&region_type=${req.region_type}`
    );
  }
    
    async searchFlightBookings(obj) {
        
        return await axios.post(`${CRM_ENGINE_URL}/crmf/opt/fetchdata`, obj);
    }

    async searchHotelBookings(obj) {
        
        return await axios.post(`${CRM_ENGINE_URL}/crmh/opt/fetchdata`, obj);
    }

    async fetchFlightBookingInfo(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/queue/fetch-add-queue`, obj);
    }

    async fetchHotelBookingInfo(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmh/queue/fetch-add-queue`, obj);
    }

    async searchFlightQueues(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/queue/fetch-queuelist`, obj);
    }

    async searchHotelQueues(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmh/queue/fetch-queuelist`, obj);
    }

    async updateFlightQueueStatus(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/queue/update-status`, obj);
    }

    async updateHotelQueueStatus(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmh/queue/update-status`, obj);
    }
    async loadCaseHistory(obj) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/queue/fetch-history`, obj);
    }
    async cancelFlightTicket(data,token){
      const headers = {
      headers: {
          'Content-Type': 'application/json',
          'auth-token': token
        }
      };
      return await axios.post(`${B2C_ENGINE_URL}/cnt/booking/postflow-operations`, data,headers);
    }
    async cancelRoom(data,token){
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'auth-token': token
          }
        };
      return await axios.post(`${B2C_HOTEL_COMMON_URL}/cnt/hotelpostbook/refund_req`, data,headers);
    }
    async checkCancelPrice(data) {
        return await axios.post(`${B2C_HOTEL_COMMON_URL}/crmh/opt/summary/htlrefundpricing`, data);
    }
    async processHotelRefund(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmh/opt/htlmanualrefund`, data);
    }
    async processEditBooking(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmh/opt/summary/hotel_edit`, data);
    }
    async processFlightEditBooking(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/opt/flight_edit`, data);
    }

    async fetchSummaryInfo(data) {
      return await axios.post(`${CRM_ENGINE_URL}/crmf/opt/summary`, data);
    }
    async sendConfirmationObj(data) {
      return await axios.post(`${CRM_ENGINE_URL}/crmf/opt/share-itinerary`, data);
    }

    async getHistory(id) {
        return await axios.get(`${CRM_ENGINE_URL}/crmf/opt/getReissueSummary/${id}`);
    }

    async getRefundDetails(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/postTicket/refundPricing`, data);
    }

    async processManualRefund(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/postTicket/refund-save`, data);
    }   

    async showRefunds(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmf/postTicket/refund-retrive`, data);
    }    

    async processPayment(data) {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${SK_KEY}`
        }
      };
      return await axios.post(`${PAY_API_URL}`, data, headers);
    }

    async getPaymentInfo(payId){
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${SK_KEY}`
            }
      };
      return await axios.get(`${PAY_API_URL}/${payId}`,headers);
    }

    async savePayRequest(data) {
      return await axios.post(`${CRM_ENGINE_URL}/crmc/payment/add_pay_transaction`, data);
    }

    async validatePaymentUrl(data) {
        return await axios.post(`${CRM_ENGINE_URL}/crmc/payment/auth_verification`, data);
    }

}
export default new CRMCommonService()