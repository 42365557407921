import PaymentService from '../../../services/PaymentService';

/**
 * @description: CRUD operations for Payment
 * @author: Ubedullah
 * @param {*}
 * @function CRM
 * @date : 22-11-2023
 */


// ========================================================================
//  Saving the Payment details 
// ========================================================================
export const savePayment = async (obj) => {
    let finalRes = [];
    await PaymentService.savePayment(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Fetching Payment data
// ========================================================================
export const getPaymentData = async (obj) => {
    let finalRes = [];
    await PaymentService.getPaymentData(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Update the Payment details 
// ========================================================================
export const updatePayment = async (obj) => {
    let finalRes = [];
    await PaymentService.updatePayment(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Resend the Payment details 
// ========================================================================
export const resendPaymentLink = async (obj) => {
    let finalRes = [];
    await PaymentService.resendPaymentLink(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const loadPaymentLinksHistory = async (obj) =>{
    let finalRes = [];
    await PaymentService.loadPaymentLinksHistory(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}