import React, { useEffect, useState,useContext } from 'react';
import { Col, Nav, Row, Tab, Container, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import CommonSearch from './CommonSearch';
import DateUtils from '../../common/DateUtils';
import {flightSearch,hotelSearch} from '../operations';
import log from "loglevel";
import { Context } from "../../../App";
import Sidebarmenu from "../../home/SideBar";
let airlineMaster = require('../../masterData/AirlinesMasterData.json');
let suppliers = require('../../masterData/Suppliers.json');
//let resp = require('../jsons/Test.json');
function BookingList(props) {
const [context, setContext] = useContext(Context);
const [book_resp , setBook_Resp] = useState([]);
const [book_resp_temp , setBook_Resp_temp] = useState([]);
const [product , setProduct] = useState("");
const [products , setProducts] = useState([{value:"Flight",label:"Flight"}]);
const [advanceSearch, setAdvanceSearch] = useState(false);
const [updateStatus, setUpdateStatus] = useState("All");
const [isLoad, setIsLoad] = useState(false);
const [amedeous, setAmedeous] = useState(false);

const SelectOptions = (context?.logindata?.roles?.includes('B_L_F') && context?.logindata?.roles?.includes('B_L_H')) ? [
  { label: "Flight", value: "Flight" },
  { label: "Hotel", value: "Hotel" }
] : context?.logindata?.roles?.includes('B_L_F') ? [
  { label: "Flight", value: "Flight" }
] : context?.logindata?.roles?.includes('B_L_H') && [
  { label: "Hotel", value: "Hotel" }
]

  useEffect(() => {
    if(context?.logindata?.roles?.includes('B_L_F')){
      setProduct("Flight")
      setProducts([{value:"Flight",label:"Flight"}])
      searchBookingCriteria("Flight");
    }else if(context?.logindata?.roles?.includes('B_L_H')){
      setProduct("Hotel")
      setProducts([{value:"Flight",label:"Flight"}])
      searchBookingCriteria("Hotel");
    }
  }, [context?.logindata])

  const searchBookingCriteria=(product)=>{
    setIsLoad(true)
    setProduct(product)
    const payload = {
        "region_type":context.logindata!==undefined && context.logindata.region_type!==undefined?context.logindata.region_type:"",
        "product":product,
        "channel_type":"All",
        "booking_id":"",
        "fmdate": "",
        "todate": "",
        "user_id":context.logindata!==undefined?context.logindata.user_id:0,
        "is_admin":context.logindata!==undefined?context.logindata.is_admin:0,
        "user_type":"CRM_USER",
        "comp_ids":context.logindata!==undefined?context.logindata.cmp_id:0,
      }
      if(context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined) {
        if(context.logindata.roles?.includes('B_L_F')) {
          if(product==="Flight"){
            flightSearch(payload).then(response => {
              if (response.suc){
                  loadFlightBookings(response.res);
                  setIsLoad(false);
              }
              setIsLoad(false);
            }).catch(function (error) {
              setIsLoad(false);
              log.error(
                "Exception occured in searchBooking function---" + error
              );
            });
          }
        } 
      }
      if(context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined) {
        if(context.logindata.roles?.includes('B_L_H')) {
          if(product==="Hotel"){
          hotelSearch(payload).then(response => {
            if (response.suc) {
                loadHotelBookings(response.res);
                setIsLoad(false);
            }
            setIsLoad(false);
          }).catch(function (error) {
            setIsLoad(false);
            log.error(
              "Exception occured in searchBooking function---" + error
            );
          });
        }
      }
    }
  }

  const loadFlightBookings = (response) => {
    setBook_Resp([])
    setProducts([{value:"Flight",label:"Flight"}])
    setProduct("Flight")
    let data = [];
    for(let val of response) {
       let air_name =airlineMaster.filter((obj)=>obj.value===val.markting_airline);
       let supplier = suppliers.filter((obj)=>obj.value===val.supplier);
        data.push({service:"Flight",ch_type:"CNT",bk_id:val.booking_id,bk_dt:val.date,bk_type:val.booking_type,pax_name:val.first_name +" " +val.last_name,pax_type:val.pax_type,email:val.email,mobile:val.ph_code+"-"+val.phone_no,tkt_num:val.ticket_number!==""?val.ticket_number:"N/A",pnr:val.pnr,airline:air_name.length!==0?air_name[0].label:"N/A",sup:val.supplier==="0"?val.office_id:supplier[0]?.label,sector:val.sector,total:val.total_fare+" "+val.usr_currency,st:val.ticket_status_str,int_st:val.int_status!==null?val.int_status:"N/A",action: <><a onClick={(e) => edit(e,val,"Flight")}><span className="ezyIcon icon-View"></span></a> </> })
    }
    setBook_Resp(data);
    setBook_Resp_temp(data)
   }

   

  const loadHotelBookings = (response) => {
       setBook_Resp([])
       setBook_Resp_temp([])
        let data = [];
        setProducts([{value:"Hotel",label:"Hotel"}])
        setProduct("Hotel")
        for(let val of response) {
            data.push({service:"Hotel",ch_type:"CNT",bk_id:val.booking_id,bk_dt:DateUtils.convertToDateFormate(val.created_date),bk_type:val.bktype,pax_name:val.first_name +" " +val.last_name,pax_type:val.pax_type,email:val.email,mobile:val.ph_code+"-"+val.phone_no,room_name:val.room_name,sup:"Expedia",hotel_name:val.hotel_name,htl_num:val.htl_confirm_num!==null?val.htl_confirm_num:"N/A",sup_conf_number:val.htl_ref_num!==null?val.htl_ref_num:"N/A",city_name:val.city_name,total:val.total_fare+" "+val.usr_currency,st:val.room_status_str, action: <><a onClick={(e) => edit(e,val,"Hotel")}><span className="ezyIcon icon-View"></span></a> </> })
        }
        setBook_Resp(data);
        setBook_Resp_temp(data)
       }
       const edit = (e,data,product_type) => {
        if(product_type === "Hotel"){
          let url = "hotel/summary/"+data.booking_id;
          window.open(url);
        }else if(product_type === "Flight"){
          let url = "flight/summary/"+data.booking_id;
          window.open(url);
        }
      }

      const getColor = (status) => {
        if (status) {
          if (status === "Cancel Request"|| status === "cancel request") {
            return "cancelRequest"
          } else if (status === "Reissue Request"||status === "cancel request") {
            return "reissueRequest"
          } else if (status === "Cancelled") {
            return "Cancelled"
          } else if (status === "Confirmed") {
            return "Confirmed"
          } else if (status === "Pending") {
            return "Pending"
          } else if (status === "Failed") {
            return "Failed"
          }
         
        }
      }
    
  const columns = [
    {
        dataField: 'service',
        text: 'Service',
        sort: true,
        filter: textFilter()
      },
    {
      dataField: 'ch_type',
      text: 'Channel Type',
      sort: true,
      filter: textFilter()
    }, 
    {
        dataField: 'bk_id',
        text: 'Booking Ref',
        sort: true,
        filter: textFilter()
      },

      {
        dataField: 'bk_dt',
        text: 'Booking Date',
        sort: true,
        filter: textFilter()
      },
    
      {
        dataField: 'bk_type',
        text: 'Booking Type',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'pax_name',
        text: 'Pax Name',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'pax_type',
        text: 'Pax Type',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'mobile',
        text: 'Mobile',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'tkt_num',
        text: 'Ticket Number',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'pnr',
        text: 'PNR',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'airline',
        text: 'Airline',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'sup',
        text: `${amedeous ? 'Office id' : 'Supplier'}`,
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'sector',
        text: 'Sector',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'total',
        text: 'Total Price',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'int_st',
        text: 'Internal Status',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'st',
        text: 'Status',
        filter: textFilter(),
        editable: false,
      formatter: (cell, row) =>{
        return <span className={getColor(row?.st)}>{row?.st}</span>;
      }
      },

    {
      dataField: 'action',
      text: 'Action'
    }];

    const columnsHeaders = [
        {
            dataField: 'service',
            text: 'Service',
            sort: true,
            filter: textFilter()
          },
        {
          dataField: 'ch_type',
          text: 'Channel Type',
          sort: true,
          filter: textFilter()
        }, 
        {
            dataField: 'bk_id',
            text: 'Booking Ref',
            sort: true,
            filter: textFilter()
          },
    
          {
            dataField: 'bk_dt',
            text: 'Booking Date',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'bk_type',
            text: 'Booking Type',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'pax_name',
            text: 'Pax Name',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'pax_type',
            text: 'Pax Type',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'email',
            text: 'Email',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'mobile',
            text: 'Mobile',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'room_name',
            text: 'Room Name',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'sup',
            text: 'Supplier',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'hotel_name',
            text: 'Hotel Name',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'htl_num',
            text: 'Hotel Conf Num',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'sup_conf_number',
            text: 'Supplier Ref Num',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'city_name',
            text: 'City Name',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'total',
            text: 'Room price',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'st',
            text: 'Status',
            sort: true,
            filter: textFilter()
          },
    
        {
          dataField: 'action',
          text: 'Action'
        }];

    // const defaultSorted = [{
    //   dataField: 'bk_dt',
    //   order: 'asc'
    // }];

    const productsChange = (e) => {
        if (e.length > 0) {
          let selected_value = e[0]
          setProduct(selected_value.value)
          if(selected_value.value == "Flight"){
            searchBookingCriteria("Flight");
          }else if(selected_value.value == "Hotel"){
            searchBookingCriteria("Hotel");
          }
        }
      }
    

    const searchBooking=(payload)=>{
        if(payload.channel_type==="CNT"){
          setBook_Resp([]);
        if(payload.product==="Flight"){
        flightSearch(payload).then(response => {
            if (response.suc) {
              let filteramedeus = response?.res?.filter((res) => res.office_id !== "");
              if(filteramedeus.length !== 0) {
                setAmedeous(true);
              } else {
                setAmedeous(false);
              }
              loadFlightBookings(response.res);
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in searchBooking function---" + error
            );
          });
        }else if(payload.product==="Hotel"){
            hotelSearch(payload).then(response => {
                if (response.suc) {
                    loadHotelBookings(response.res);
                }
              }).catch(function (error) {
                log.error(
                  "Exception occured in searchBooking function---" + error
                );
              });
        }
      }else{
        setBook_Resp([]);
      }
    }
    const advanceSearchOpen = () => {
      if (advanceSearch) {
        setAdvanceSearch(false)
      } else {
        setAdvanceSearch(true)
      }
    }

    const loadBookingsList=(status)=>{
      setBook_Resp(book_resp_temp);
      if(book_resp.length!==0 && status!=="All" && status!=="On hold"){
          let finalResp = book_resp.filter((obj) => obj.st === status);
          setBook_Resp(finalResp);
      }else if(status==="On hold"){
        let finalResp = book_resp.filter((obj) => obj.int_st == "On hold" && obj.st === "Confirmed");
          setBook_Resp(finalResp);
      }
    }


    return (
        <>
          
            <Container fluid>
                 <Header/>
            <Row>
            <Col xs={2} className="pl-0 sidebarMain">
            <Sidebarmenu active="BookingList"/>
   
                </Col>


                <Col xs={10}  className="mainftr">
<Row>
<Col className="bookingSearch">

             <h5 className="pageTitle d-flex justify-content-between justify-items-center">Search Booking 
                <span onClick={advanceSearchOpen} className={"plusAdv ezyIcon icon-"+ (advanceSearch ? 'arrowup' : 'down-arrow')}> Advance Search</span>
              </h5>

              <div className={"advSerch " + (advanceSearch ? 'active' : 'inactive')}>
     
              <div className="submenu">
                <CommonSearch sendSearchCreteria={searchBooking} selproduct={product}/>
                     </div>
                </div>
  </Col>
</Row>
                 
<div className='cardMainlayout dashBrd mb-0 mt-0'>


<div className="sortBookingList mt-3">
<div className="d-flex requestWrap">
<button
size="xs"
variant="outline-dark"
type="submit" 
className= {updateStatus === "All" ? "btn-outline-dark btn active" :"btn-outline-dark btn"}
onClick={(e)=>{
  setUpdateStatus("All")
  loadBookingsList("All")
}}
><span>All Bookings</span></button>
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Confirmed" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Confirmed")
  loadBookingsList("Confirmed")
}}
> <span>Confirmed</span></button>

{product==="Flight" &&
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Pending" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Pending")
  loadBookingsList("Pending")
}}
> <span>Pending</span></button>
}

{product==="Hotel" &&
<>
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "In Complete" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("In Complete")
  loadBookingsList("In Complete")
}}
> <span>In Complete</span> </button>

</>
}

<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Cancelled" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Cancelled")
  loadBookingsList("Cancelled")
}}
> <span>Cancelled</span> </button>

<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "On hold" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("On hold")
  loadBookingsList("On hold")
}}
> <span>On Hold</span> </button>

<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Cancel Request" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Cancel Request")
  loadBookingsList("Cancel Request")
}}
> <span>Cancel Request</span> </button>

{product==="Flight" &&
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Reissue Request" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Reissue Request")
  loadBookingsList("Reissue Request")
}}
> <span>Reissue Request</span> </button>
}
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Failed" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Failed")
  loadBookingsList("Failed")
}}
> <span>Booking Failed</span> </button>
<button
size="xs"
variant="outline-dark"
type="submit"
className= {updateStatus === "Payment Failed" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
onClick={(e)=>{
  setUpdateStatus("Payment Failed")
  loadBookingsList("Payment Failed")
}}
> <span>Payment Failed</span> </button>

</div>

  <div className='ProdcutDrop bkgLstt'>
              {context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined && (
                <>
                {(context.logindata.roles?.includes("B_L_F") || context.logindata.roles?.includes("B_L_H")) && (
               <Form.Row>
               <Form.Group className="selectplugin sideBysidep m-0" as={Col} controlId="firstName">
                <Form.Label>Products :</Form.Label>
                            <Select
                                options={SelectOptions}
                                values={products}
                                onChange={(value) => productsChange(value)}
                                placeholder='Select Product'
                                classNamePrefix='filter'
                                theme={theme => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#F0F0F0',
                                    primary: '#3156ab',
                                    cursor: 'pointer',
                                  },
                                })}
                            />
                        </Form.Group>
                        </Form.Row>
                )}
                </>
              )}
                 </div>
                </div>
                {isLoad ? <div className='loadding'><span class="loadernew"></span></div> :
                <div className="cardMainlayout innerCardmain bkgListt tableRes p-0">
                    <div className="tablescroll">
                          <BootstrapTable
                              bootstrap4
                              keyField="id"
                              data={ book_resp }
                              classes="markup filterTbl bookingListth dd"
                              columns={product==="Flight"?columns:columnsHeaders }
                              // defaultSorted={ defaultSorted }
                              filter={ filterFactory()}
                              pagination={ paginationFactory() }
                              />
                         </div>
                         <div className="norecord">
                         {book_resp.length === 0 &&
                          <span>No Records Found</span>
                         }
                         </div>
                       </div>
                         }
                       </div>

                       <Footer />
                </Col>
           
                </Row>
                </Container>

         
        </>
    )
}
export default BookingList;