import React, { useEffect, useContext, useState, useReducer } from "react";
import { Context } from "../../../App";
import moment from 'moment';
import AirlinesMemo from "../../common/airlines/AirlinesMemo";
import DateUtils from "../../utilities/DateUtils";
import Utilities from "../../utilities/Utilities";
import { Col, Form, Row, Table, Alert, Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Duration from "../../../assets/images/duration.png";
import Select from "react-dropdown-select";
export const EDIT_SEG_DATA = "editData";
export const EDIT_INITIAL_ONWARDSEG_DATA = "editinitialOnwardSegData";
export const EDIT_INITIAL_RETURNSEG_DATA = "editinitialReturnSegData";
const _ = require("lodash");
let rbdMaster = require("../../masterData/Rbd.json");

/**
 * @author Praveen Varma
 * @date 29-11-2023
 * @description Flight booking summary segments data
 * @param {*} props 
 * @returns 
 */

/**
* Initial State Declaration
*/
const initialState = {
    notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false, disablereturned: false, disableonward: false, onward: false, return: false, checkRefundable: false,ticketStatusList:[],paxTypeItems:[],isCancelBooking:false,hideReturnIt:false,enableSplit:false,enableReissue:true,emdAvail:false, cheked: false, showSplit: false, onHoldSplit: true
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function FlightSummarySegments({ sector, index, seg, mappings, isEdit, trvl_type, processSegEditBooking, btnLoader }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [fareload, setFareload] = useState(false);
    const [deptDate, setDeptDate] = useState("");
    const [arrDate, setArrDate] = useState("");
    const [segEdit, setSegEdit] = useState(false);
    const [showEditpop, setShowEditpop] = useState(false);
    const [cls, setCls] = useState("");
    const [rbd, setRbd] = useState("");
    
    const TravelClass = [
        { value: 'Economy', label: 'Economy' },
        { value: 'Premium',label:'Premium Economy'},
        { value: 'Business', label: 'Business' },
        { value: 'First class', label: 'First class' }
    ]

    useEffect(() => {
        setSegEdit(false);
    }, [isEdit])

    useEffect(() => {
        if(seg.cabin_class !== "" && seg.cabin_class !== undefined) {
            let cb_class = TravelClass.filter((obj) => obj.value === seg.cabin_class);
            setCls(cb_class);
        }
    }, [seg.cabin_class])

    useEffect(() => {
        if(seg.fare_class !== "" && seg.fare_class !== undefined) {
            let fr_class = rbdMaster.filter((obj) => obj.value === seg.fare_class);
            setRbd(fr_class);
        }        
    }, [seg.fare_class])

    const handleSegChange = (data,type,flowType) => (e) => {
        const { id, value } = e.target !== undefined ? e.target : e;
        dispatch({ type: id, payload: value });
        localStorage.removeItem(EDIT_SEG_DATA);
        if(sector.length!==0){
            for(let seg of sector){
               if(flowType==="normal"){
                if(type==="gds"){
                    seg.normal_gds_pnr=value;
                    seg.changed=true;
                }
                if(seg.segid===data.segid){
                    seg.changed=true;
                    if(type==="airline"){
                        seg.normal_airline_pnr=value;
                    }
                    if(type==="depDate") {
                        let dt = moment(e).format('YYYY-MM-DD');
                        let time = moment(e).format('HH:mm:ss');
                        seg.depart_time=dt + "T" + time;
                        setDeptDate(e)
                    }
                    if(type==="arrDate") {
                        let dt = moment(e).format('YYYY-MM-DD');
                        let time = moment(e).format('HH:mm:ss');
                        seg.arrival_time=dt + "T" + time;
                        setArrDate(e)
                    }    
                    if(type==="flt_no") {
                        seg.flt_no=value
                    }  
                    if(type==="cabin_class") {
                        seg.cabin_class=e.length !== 0 ? e[0].value : "";
                        setCls(e);
                    }    
                    if(type==="fare_class") {
                        seg.fare_class=e.length !== 0 ? e[0].value : "";
                        setRbd(e);
                    }    
                    if(type==="operating_airline") {
                        seg.operating_airline=e.length !== 0 ? e[0].value : "";
                    }                      
                   }
                }else{
                if(type==="gds"){
                    seg.split_gds_pnr=value;
                    seg.changed=true;
                }
                if(seg.segid===data.segid){
                    seg.changed=true;
                    if(type==="airline"){
                    seg.split_airline_pnr=value;
                    }
                    if(type==="depDate") {
                        let dt = moment(e).format('YYYY-MM-DD');
                        let time = moment(e).format('HH:mm:ss');
                        seg.depart_time=dt + "T" + time;
                        setDeptDate(e)
                    }
                    if(type==="arrDate") {
                        let dt = moment(e).format('YYYY-MM-DD');
                        let time = moment(e).format('HH:mm:ss');
                        seg.arrival_time=dt + "T" + time;
                        setArrDate(e)
                    }    
                    if(type==="flt_no") {
                        seg.flt_no=value
                    }    
                    if(type==="cabin_class") {
                        seg.cabin_class=e.length !== 0 ? e[0].value : "";
                        setCls(e);
                    }    
                    if(type==="fare_class") {
                        seg.fare_class=e.length !== 0 ? e[0].value : "";
                        setRbd(e);
                    }    
                    if(type==="operating_airline") {
                        seg.operating_airline=e.length !== 0 ? e[0].value : "";
                    }                                    }
                }
            }
            localStorage.setItem(EDIT_SEG_DATA,JSON.stringify(sector));
       }

    }

    const openLongRules = (e) => {
    }

    const conversion = (ex) => {
        if (ex && ex.includes(",")) {
            let pnrarray = ex.split(",")
            return pnrarray[0]
        } else {
            return ex
        }
    }

    const handleSegEdit = () => {
        localStorage.removeItem(EDIT_SEG_DATA);
        setSegEdit(true);
    }

    const cancelEdit = () => {
        setSegEdit(false);
        localStorage.removeItem(EDIT_SEG_DATA);
        if(trvl_type === "Onward") {
            var segInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_ONWARDSEG_DATA));
            if(sector.length!==0){
                for(let seg of sector){
                    for(let seg1 of segInfo) {
                        if(seg.segid===seg1.segid) {
                            seg.normal_gds_pnr=seg1.normal_gds_pnr;
                            seg.normal_airline_pnr=seg1.normal_airline;
                            seg.split_gds_pnr=seg1.split_gds_pnr;
                            seg.split_airline_pnr=seg1.split_airline_pnr;
                            seg.depart_time=seg1.depart_time;
                            seg.arrival_time=seg1.arrival_time;
                            seg.flt_no=seg1.flt_no;
                            seg.cabin_class=seg1.cabin_class;
                            seg.fare_class=seg1.fare_class;
                            seg.operating_airline=seg1.operating_airline;
                        }
                    }
                }
            }
        } else {
            var segInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_RETURNSEG_DATA));
            if(sector.length!==0){
                for(let seg of sector){
                    for(let seg1 of segInfo) {
                        if(seg.segid===seg1.segid) {
                            seg.normal_gds_pnr=seg1.normal_gds_pnr;
                            seg.normal_airline_pnr=seg1.normal_airline;
                            seg.split_gds_pnr=seg1.split_gds_pnr;
                            seg.split_airline_pnr=seg1.split_airline_pnr;
                            seg.depart_time=seg1.depart_time;
                            seg.arrival_time=seg1.arrival_time;
                            seg.flt_no=seg1.flt_no;
                            seg.cabin_class=seg1.cabin_class;
                            seg.fare_class=seg1.fare_class;
                            seg.operating_airline=seg1.operating_airline;
                        }
                    }
                }
            }            
        }
    }

    const callEditBooking = () => {
        setShowEditpop(true);
    }

    const callSegEditBooking = () => {
        processSegEditBooking(trvl_type, "seg");
    }
    
    const oncloseEidt = () => {
        setShowEditpop(false);
      }

      const processEditBookingFlow = () => {
        // if (state.enableEdit) {
        //   processEditBookingFlowOnward();
        // } else if (state.enableEditBooking) {
        //   processEditBookingSplitReturn();
        // }
      }

    //For layover time calculation
    const layOverTimeCal = (one, two, deptCode, arCode) => {
    if (deptCode !== undefined && arCode !== undefined) {
        var deptCnt = mappings.filter((obj) => obj.airportCode === deptCode);
        var arCnt = mappings.filter((obj) => obj.airportCode === arCode);
        if(deptCnt.length > 0 && arCnt.length > 0) {
            let time1 = DateUtils.getTimeZoneDate(two, deptCnt[0]?.countryCode);
            let time2 = DateUtils.getTimeZoneDate(one, arCnt[0]?.countryCode);
            let timeDiff = DateUtils.calculateHoursandMinsbetweendates(time2, time1);
            let time = _.cloneDeep(timeDiff);
            let hrs = 0;
            let mins = 0;
            if (timeDiff < 60) {
            //inital minutes calculation
            mins = timeDiff;
            } else {
            for (let i = 0; i < timeDiff; i++) {
                if (time >= 60) {
                time = time - 60;
                hrs = hrs + 1;
                } else {
                mins = time;
                break;
                }
            }
            }
    
            return hrs + "h:" + mins + "m";

        } else {
            let totTime = Math.abs(new Date(one) - new Date(two));
            return DateUtils.msToTime(totTime);
        }
    } else {
        let totTime = Math.abs(new Date(one) - new Date(two));
        return DateUtils.msToTime(totTime);
    }
    };

    return (
        <div className="flightRoutInfod">
            <div className="editBtnr d-flex">
            {isEdit && <Button onClick={handleSegEdit} className="d-flex"> Edit <span className="calendar ezyIcon icon-edit ml-2"></span></Button>}
            </div>
            <div className={segEdit ? "flightRotin editRe" : "flightRotin"}>
            <Row className="rs-row">
                <Col xs={4} className="rs-rTypeDate align-items-start">
                    <div className="airLogo d-flex gg">
                        <img
                            src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + seg.markting_airline + '.png'}
                            className="logo"
                            alt="AirLine"
                        />
                        <div className="flightNo d-flex flex-column ml-4">
                            <span className="planeName"><AirlinesMemo code={seg.markting_airline} /></span>
                            <div className="planeCodeCatWrap">
                            <span className="planeCode">{seg.markting_airline}<span className="sepRatos">-</span>{!segEdit ? seg.flt_no : 
                            <Form.Row>
                                <Form.Group as={Col} controlId="flt_no" className="d-flex align-items-center justify-content-between">
                                    <Form.Control type="text" placeholder="Enter Flight no" onChange={handleSegChange(seg, "flt_no", seg.flow_type)} value={seg.flt_no} />
                                </Form.Group>
                            </Form.Row>
                            }</span>
                            {!segEdit &&
                                <span className="category">{seg.cabin_class} {seg.fare_class ? " - " + seg.fare_class : ""}</span>
                            }
                            {segEdit &&
                                <span className="category">
                                    <Form.Group controlId="cabin_class">
                                    <Select 
                                        options={TravelClass}
                                        values={cls}
                                        onChange={handleSegChange(seg, "cabin_class", seg.flow_type)}
                                    /> 
                                    </Form.Group>
                                   <span className="seperaTion">
                                    {seg.fare_class && " - "}</span>
                                    {seg.fare_class &&
                                    
                                   <Form.Group controlId="fare_class" className="editClaasd">
                                        <Select 
                                            options={rbdMaster}
                                            values={rbd}
                                            className="classSeg"
                                            onChange={handleSegChange(seg, "fare_class", seg.flow_type)}
                                        /> 
                                    </Form.Group>
                                    }</span>
                            }
                            </div>
                            <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={seg.operating_airline} isEdit={segEdit} sendoperatingAirline={handleSegChange(seg, "operating_airline", seg.flow_type)} /></span></div>
                        </div>
                    </div>
                </Col>
                <Col xs={6} className="rs-FromTo">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column maxwidthWrap">
                            
                            <span>
                                <span className="time">{!segEdit ? DateUtils.prettyDate(seg.depart_time, "HH:mm") : ""}</span>&nbsp;
                                <span className="countryCode">
                                    {seg.seg_source}
                                </span>                                    
                            </span>
                            {segEdit &&
                                <Form.Group
                                    className="selectplugin datePicker mb-0"
                                    controlId="depart_time"
                                    >
                                    <DatePicker
                                        selected={DateUtils.convertNewToDate(seg.depart_time)}
                                        onChange={handleSegChange(seg, "depDate", seg.flow_type)}
                                        fromDate={seg.depart_time}
                                        showTimeSelect
                                        placeholderText="DD-MM-YYYY"
                                        timeFormat="p"
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        minDate={DateUtils.convertNewToDate(seg.depart_time)}
                                        dateFormat="Pp"
                                        popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"], // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false, // turn off since needs preventOverflow to be enabled
                                        },
                                        }}
                                    />
                                    <span className="calendar ezyIcon icon-newcalendar"></span>
                                    {/* <small style={{ color: "red" }}>
                                        {error &&
                                        fromDate === "" &&
                                        "Please select from date"}
                                    </small> */}
                                    </Form.Group>
                            }
                                
                            {!segEdit &&
                                <span className="dateMonthYearWrap">{DateUtils.prettyDate(seg.depart_time, "ddd, DD MMM YYYY")}</span>
                            }
                            <span className="airportName">{Utilities.getAirportdata(mappings, seg.seg_source, 'a')}</span>
                            {seg.depart_terminal &&
                                <small className="trm">{"Terminal - " + seg.depart_terminal}</small>
                            }
                        </div>
                        
                        {seg.duration !== "" && seg.duration !== undefined && (
                            <div className="duration">
                            <span> {layOverTimeCal(seg.arrival_time, seg.depart_time, seg.seg_source, seg.seg_destination)}</span>
                            <img src={Duration} alt="duration-icon" className="imgDimension" />
                            {/* <span className="diviDer"></span> */}
                            
                            </div>
                        )}
                        {/* <Col className="p-0" xs="1"><span className="ezyIcon icon-flight ml-2"></span></Col> */}
                        <div className="d-flex flex-column maxwidthWrap">
                            
                            <span>
                                <span className="time mr-1">{!segEdit ? DateUtils.prettyDate(seg.arrival_time, "HH:mm") : ""}</span> 
                                <span className="countryCode">
                                    {seg.seg_destination}</span>
                                
                            </span>
                            
                            {segEdit &&
                                <Form.Group
                                    className="selectplugin datePicker mb-0"
                                    controlId="arrival_time"
                                    >
                                    <DatePicker
                                        selected={DateUtils.convertNewToDate(seg.arrival_time)}
                                        onChange={handleSegChange(seg, "arrDate", seg.flow_type)}
                                        fromDate={seg.arrival_time}
                                        showTimeSelect
                                        placeholderText="DD-MM-YYYY"
                                        timeFormat="p"
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        minDate={DateUtils.convertNewToDate(seg.depart_time)}
                                        dateFormat="Pp"
                                        popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"], // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false, // turn off since needs preventOverflow to be enabled
                                        },
                                        }}
                                    />
                                    <span className="calendar ezyIcon icon-newcalendar"></span>
                                    </Form.Group>
                            }
                            {!segEdit &&
                           <    span className="dateMonthYearWrap"> {DateUtils.prettyDate(seg.arrival_time, "ddd, DD MMM YYYY")}</span>
                            }
                            <span className="airportName">{Utilities.getAirportdata(mappings, seg.seg_destination, 'a')}</span>
                            {seg.arrival_terminal &&
                                <small className="trm">{"Terminal - " + seg.arrival_terminal}</small>
                            }
                        </div>
                    </div>

                    <span>

                    </span>
                </Col>
                <Col xs={2} className={segEdit ? 'text-right' : 'airlineGdsWrap'}>
                    {/* {seg.supplier === 0 || seg.supplier === "0" ? <p><span>Class : </span>{seg.cabin_class} {seg.fare_class ? " - " + seg.fare_class : ""}</p> : <p><span>Fare Type : </span>{seg.fare_type === "Regular - R" ? "SAVER" : seg.fare_type === "Flexi - J" ? "FLEXI" : seg.fare_type === "Corporate - F" ? "CORPORATE" : seg.fare_type}</p>} */}
                    {!segEdit &&
                        <>
                            {seg.flow_type === 'split' && (<div className="airlinepnrWrap"><span>Airline PNR : </span> {seg.split_airline_pnr ? seg.split_airline_pnr : "N/A"}</div>)}
                            {seg.flow_type === 'normal' && (<div className="airlinepnrWrap"><span>Airline PNR : </span> {seg.normal_airline_pnr ? seg.supplier == 7 ? (seg.normal_airline_pnr.includes("/") ? (seg.journey_type == "O" ? (seg.normal_airline_pnr.split("/")[0]) : (seg.normal_airline_pnr.split("/")[1])) : (seg.normal_airline_pnr)) : (seg.normal_airline_pnr) : "N/A"}</div>)}
                        </>
                    }
                    {segEdit && seg.flow_type === 'normal' &&
                        <Form.Row className="justify-content-end align-items-center">
                            <Form.Label>Airline PNR</Form.Label>
                            <Form.Group as={Col} controlId="normal_airline_pnr" className="edittime d-flex align-items-center justify-content-between">
                                <Form.Control type="text" placeholder="Enter Airline PNR" value={seg.normal_airline_pnr} onChange={handleSegChange(seg, "airline", seg.flow_type)} />
                            </Form.Group>

                        </Form.Row>
                    }
                    {seg.flow_type === 'split' && segEdit &&
                        <Form.Row className="justify-content-end align-items-center">
                            <Form.Label>Airline PNR</Form.Label>
                            <Form.Group as={Col} controlId="split_airline_pnr" className="edittime d-flex align-items-center justify-content-between">
                                <Form.Control type="text" placeholder="Enter Airline PNR" value={seg.split_airline_pnr} onChange={handleSegChange(seg, "airline", seg.flow_type)} />
                            </Form.Group>

                        </Form.Row>
                    }
                    {!segEdit && (seg.supplier == undefined || seg.supplier == 0) &&
                        <>
                            {seg.flow_type === 'split' && (<div className="gdspnrWrap"><span>GDS PNR : </span> {seg.split_gds_pnr ? seg.split_gds_pnr : seg.normal_gds_pnr ? seg.normal_gds_pnr : "N/A"}</div>)}
                            {seg.flow_type === 'normal' && (<div className="gdspnrWrap"><span>GDS PNR : </span> {seg.normal_gds_pnr ? seg.normal_gds_pnr.includes(",") ? conversion(seg.normal_gds_pnr) : seg.normal_gds_pnr : "N/A"}</div>)}
                        </>
                    }
                    {segEdit && seg.flow_type === 'normal' &&
                        <Form.Row className="justify-content-end align-items-center editGdspn">
                            <Form.Label>GDS PNR</Form.Label>
                            <Form.Group as={Col}  controlId="normal_gds_pnr" className="edittime d-flex align-items-center justify-content-between">
                                <Form.Control type="text" placeholder="Enter GDS PNR" value={seg.normal_gds_pnr} onChange={handleSegChange(seg, "gds", seg.flow_type)} />
                            </Form.Group>
                        </Form.Row>
                    }
                    {seg.flow_type === 'split' && segEdit &&
                        <Form.Row className="justify-content-end align-items-center">
                            <Form.Label>GDS PNR</Form.Label>
                            <Form.Group as={Col} controlId="split_gds_pnr" className="edittime d-flex align-items-center justify-content-between">
                                <Form.Control type="text" placeholder="Enter GDS PNR" value={seg.split_gds_pnr ? seg.split_gds_pnr : seg.normal_gds_pnr} onChange={handleSegChange(seg, "gds", seg.flow_type)} />
                            </Form.Group>
                        </Form.Row>
                    }
                </Col>
                </Row>
                {((seg.sup != undefined && seg.sup === "0") || seg.supplier === "0") &&
                    <div className="bgginfo">
                        <Row>
                            <Col>{seg.bgd && <div className="sectBaggageinfo ezyIcon icon-baggage"> <span>Baggage Info :&nbsp; </span> Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                            </Col>
                        </Row>
                    </div>
                }
                {(((seg.sup != undefined && seg.sup !== "0") || seg.supplier !== "0") && seg.bgd && seg.bgd !== '-') &&
                    <div className="bgginfo">
                        <Row>
                            <Col>{seg.bgd && <div className="sectBaggageinfo ezyIcon icon-baggage"> <span>Baggage Info :&nbsp;</span> Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                            </Col>
                        </Row>
                    </div>
                }
                </div>
            {sector.length > 1 &&
                sector[index + 1] &&
                sector[index + 1].depart_time !== undefined && (
                  <div className="layover">
                    <span>
                      {" "}
                      Layover :&nbsp;
                      <>
                        {layOverTimeCal(
                          sector[index].arrival_time,
                          sector[index + 1].depart_time
                        )}
                      </>
                    </span>
                  </div>
                )}
                
                        
                {segEdit &&
                <div className="text-right buttonGrop border-0 pt-0">
                    <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    className="mr-3"
                    onClick={cancelEdit}
                    > Cancel</Button>
                    
                {!btnLoader? 
                    <Button
                        size="xs"
                        variant="primary"
                        type="submit"
                        onClick={callSegEditBooking}
                    > 
                    Update
                    </Button> 
                    :                    
                    <div class="progress progress-striped active mt-0" style={{float:"right"}}>
                        <div role="progressbar progress-striped" class="progress-bar">
                        <span>Updating..</span>
                        </div>
                    </div>
                }
                </div>
                }
                
                        
                {showEditpop &&
                <Modal
                show={showEditpop}
                onHide={oncloseEidt}
                className="importPNRMdl editpnrPop"
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton className="border-bottom-0">
                    <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <strong>Are you sure! You want to proceed with the Edit Booking? </strong>

                    {/* <p className="text-center d-flex mb-2"><span className="text-secondary mr-1 "> NOTE : </span> Please cross check all GDS PNR's Should be same.</p> */}
                    </div>

                </Modal.Header>
                <Modal.Footer className="justify-content-center border-top-0 pb-4">
                    <Button
                    size="xs"
                    variant="secondary"
                    onClick={oncloseEidt}
                    type="submit"
                    >No</Button>
                    <Button
                    size="xs"
                    variant="primary"
                    onClick={processEditBookingFlow}
                    type="submit"
                    >Yes</Button>
                </Modal.Footer>
                </Modal>
            }
         
        </div>
    )
}

export default FlightSummarySegments;