import log from "loglevel";
import React, {useRef, useReducer, useState,useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Form, Tab, Tabs, Table, Alert } from "react-bootstrap";
import Button from '../../common/Button';
import DateUtils from "../../common/DateUtils";
import DatePicker from 'react-datepicker';
import Utilities from "../../utilities/Utilities";
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import { emailValidation, mobileNumberValidation } from '../../utilities/FormValidations';
let stdCodes = require('../../masterData/CountrySTDCodes.json');
let countriesList = require('../../masterData/CountriesList.json');
/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
    adts:0,chds:0,infs:0,notiMessage: '', isRegister: true, usr_email: "", notiMessageShow: false, notiVarient: '', proEnable: false, profiles: [], noRecords: false, tl: "", fn: "", mn: "", ln: "", phc: "", phn: "", em: "", cem: "", profid: 0, selTitle: [], selPhc: [], travelsList: [], prof: "", adtList: [], chdList: [], infList: [], pax_options: [], paxtl: "", paxfn: "", paxln: "", paxphc: "", paxphn: "", paxem: "", infantEnable: true, passengers: [], pa: "", na: "", isc: "", selIsc: [], isd: null, exd: null,
    inf_mindate: "", chd_mindate: "", enablePax: false, sno: 0, enableSeg: false, selRbd: [], selSegOrign: [], selSegDest: [], segNo: 0, selMa: [], selOa: [], adtdis: "", adtdista: "", infdis: "", infdista: "", chddis: "", chddista: "", total_price: 0, departure_date: null, arrival_date: null, adtinf: [], disable: false, enableSector: false, selFltType: [], manual_resp: "", insertPnr: "", selpaxfn: [], updateinf: false, insertGDSPnr: "", seldeparture_date: null, selarrival_date: null, depdate: null, arrdate: "", selsa: "", selmp: "", selsp: "", lcc_sup: "", onwBglabel: "", onwBgPrice: "", retBglabel: "", retBgPrice: "", onwMeallabel: "", onwMealPrice: "", retMeallabel: "", retMealPrice: "", onwSeatlabel: "", onwSeatPrice: "", retSeatlabel: "", retSeatPrice: "",
    isInfType:"",isManualReissue:false, emd_number:"", selTt: [{ "label": "Onward", "value": "O" }], selptype: [], selpaxtl: [], paxselPhc: [], ptype: "", selIsc: [], nat: [], sa: "", sp: "", mp: "", ffnum: "", air: "", jtype: "", supplier: "0", ttype: "", adtpax: [], infpax: [], chdpax: [],trave_id:0,adtBagTotal: 0, adtMealTotal: 0, adtSeatTotal: 0, chdBagTotal: 0, chdMealTotal: 0, chdSeatTotal: 0,adtRetBagTotal: 0, adtRetMealTotal: 0, adtRetSeatTotal: 0, chdRetBagTotal: 0, chdRetMealTotal: 0, chdRetSeatTotal: 0,paxRettn:"",
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function PassengerInfo(props) {
    const typeaheadRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [onwardPaxlist, setOnwardPaxlist] = useState([]);
    const [returnPaxlist, setReturnPaxlist] = useState([])
    const [extras, setExtras] = useState(false);
    const leadPax = [{ label: "Adult", value: 'ADT' }]
    const paxType = [
        { label: "Adult", value: 'ADT' },
        { label: "Child", value: 'CHD' },
        { label: "Infant", value: 'INF' }]
    const titles = [
        { label: "Mr", value: "Mr" },
        { label: "Miss", value: "Miss" },
        { label: "Mstr", value: "Mstr" },
        { label: "Mrs", value: "Mrs" },
        { label: "Ms", value: "Ms" }]
        
        useEffect(() => {
            dispatch({ type: "ttype", payload: props.ttype });
          }, [props.ttype])

        useEffect(() => {
            if(props.onward_pax!==undefined){
                dispatch({ type: "passengers", payload: props.onward_pax });
            }
        }, [props.onward_pax])

        useEffect(() => {
            if(props.onward_paxRes!="" && props.onward_paxRes!==undefined){ 
                dispatch({ type: "passengers", payload: props.onward_paxRes });
                // dispatch({ type: "paxtype", payload: props.onward_paxRes });
            }
        }, [props.onward_paxRes])
        useEffect(() => {
            if(props.return_pax!==undefined){
                dispatch({ type: "passengers", payload: props.return_pax });
            }
        }, [props.return_pax])

        useEffect(() => {
            onwardPaxlist.adtBagTotal = state.adtBagTotal;
            onwardPaxlist.adtMealTotal = state.adtMealTotal;
            onwardPaxlist.adtSeatTotal = state.adtSeatTotal;
            onwardPaxlist.chdBagTotal = state.chdBagTotal;
            onwardPaxlist.chdMealTotal = state.chdMealTotal;
            onwardPaxlist.chdSeatTotal = state.chdSeatTotal;
            if(props.ttype === "2") {
                onwardPaxlist.adtRetBagTotal = state.adtRetBagTotal;
                onwardPaxlist.adtRetMealTotal = state.adtRetMealTotal;
                onwardPaxlist.adtRetSeatTotal = state.adtRetSeatTotal;
                onwardPaxlist.chdRetBagTotal = state.chdRetBagTotal;
                onwardPaxlist.chdRetMealTotal = state.chdRetMealTotal;
                onwardPaxlist.chdRetSeatTotal = state.chdRetSeatTotal;
            }
            if(onwardPaxlist.length !== 0) {
                props.sendOwPax(onwardPaxlist);
            }
        }, [state.adtBagTotal, state.adtMealTotal, state.adtSeatTotal, state.chdBagTotal, state.chdMealTotal, state.chdSeatTotal])

        useEffect(() => {
            returnPaxlist.adtRetBagTotal = state.adtRetBagTotal;
            returnPaxlist.adtRetMealTotal = state.adtRetMealTotal;
            returnPaxlist.adtRetSeatTotal = state.adtRetSeatTotal;
            returnPaxlist.chdRetBagTotal = state.chdRetBagTotal;
            returnPaxlist.chdRetMealTotal = state.chdRetMealTotal;
            returnPaxlist.chdRetSeatTotal = state.chdRetSeatTotal;
            if(returnPaxlist.length !== 0) {
                props.sendRtPax(returnPaxlist);
            }
        }, [returnPaxlist, state.adtRetBagTotal, state.adtRetMealTotal, state.adtRetSeatTotal, state.chdRetBagTotal, state.chdRetMealTotal, state.chdRetSeatTotal])

    const handlepaxType = (data) => {
        dispatch({ type: "ptype", payload: data.value });
        dispatch({ type: "selptype", payload: [data] });
        if (data.value == "ADT") {
            let adtList = props.traveList.filter(
                (pax) => pax.pax_type === "ADT"
              );
            // dispatch({ type: 'adts', payload: state.adts + 1 })
            dispatch({ type: "paxtype", payload: adtList });
            dispatch({ type: 'infantEnable', payload: true })
        }
        if (data.value == "CHD") {
            let chdList = props.traveList.filter(
                (pax) => pax.pax_type === "CHD"
              );
            // dispatch({ type: 'chds', payload: state.chds + 1 })
            dispatch({ type: "paxtype", payload: chdList });
            dispatch({ type: 'infantEnable', payload: true })
        }
        if (data.value == "INF") {
            let infList = props.traveList.filter(
                (pax) => pax.pax_type === "INF"
              );
            // dispatch({ type: 'infs', payload: state.infs + 1 })
            dispatch({ type: "paxtype", payload: infList });
            dispatch({ type: 'infantEnable', payload: false })
        }
    }
    const handlepaxTitle = (value) => {
        dispatch({ type: 'paxtl', payload: value.value })
        dispatch({ type: 'selpaxtl', payload: [{ label: value.value, value: value.value }] })
    }

    const convertProfile = (obj, type) => {
           if (type == "pax"){
            dispatch({ type: 'selpaxtl', payload: [{ "value": obj.title, "label": obj.title }] })
            dispatch({ type: 'trave_id', payload: obj.value })
            dispatch({ type: 'paxtl', payload: obj.title })
            dispatch({ type: 'paxfn', payload: obj.fname })
            dispatch({ type: 'paxln', payload: obj.lname })
            dispatch({ type: 'paxem', payload: obj.email })
            dispatch({ type: 'paxphc', payload: obj.phn_code })
            dispatch({ type: 'paxphn', payload: obj.ph_num })
            const convertDate = (obj.dob !== null ? DateUtils.convertToDate(obj.dob) : "");
            dispatch({ type: 'paxdob', payload: convertDate })
            stdCodes.map((std, sidx) => {
                if (obj.phn_code!=="" && (std.value == obj.phn_code)) {
                    dispatch({ type: 'paxselPhc', payload: [{ "value": std.name, "label": std.name }] })
                }
            });
            dispatch({ type: 'pa', payload: obj.passport_no })
            dispatch({ type: 'na', payload: obj.na })
            dispatch({ type: 'isc', payload: obj.issue_cnt })
            dispatch({ type: 'isd', payload: obj.issue_date !== null && obj.issue_date !== undefined ? DateUtils.convertStringToDate(obj.issue_date) : null })
            dispatch({ type: 'paxexd', payload: obj.exp_date !== null && obj.exp_date !== undefined ? DateUtils.convertStringToDate(obj.exp_date) : null })
            countriesList.map((country, sidx) => {
                if (obj.na!==null && (obj.na == country.value)) {
                    dispatch({ type: 'nat', payload: [{ label: country.label, value: country.value }] })
                }
            })
            countriesList.map((country, sidx) => {
                if (obj.issue_cnt!==null && (obj.issue_cnt == country.value)) {
                    dispatch({ type: 'selIsc', payload: [{ label: country.label, value: country.label }] })
                }
            })
            dispatch({ type: 'selIsd', payload: obj.issue_date !== null ? DateUtils.convertStringToDate(obj.issue_date) : "" })
            dispatch({ type: 'selExd', payload: obj.exp_date !== null ? DateUtils.convertStringToDate(obj.exp_date) : "" })
        }
    
    }

    const handlepaxphnCode = (e) => {
        if (e) {
            dispatch({ type: 'paxselPhc', payload: [{ "value": e.value, "label": e.label }] })
            dispatch({ type: 'paxphc', payload: e.value })
        }
    }

    const paxDate = (dt, n) => {
        return new Date(dt.setFullYear(dt.getFullYear() - n));
    }

    const handleissueDate = (date) => {
        const convertDate = (date !== null ? DateUtils.convertHiToDate(date) : null);
        dispatch({ type: 'isd', payload: convertDate })
    }
    const handleexpDate = (date) => {
        const convertDate = (date !== null ? DateUtils.convertHiToDate(date) : null);
        dispatch({ type: 'exd', payload: convertDate })
        dispatch({ type: 'paxexd', payload: convertDate })
    }

    const handlepaxChange = (e) => {
        if (e[0] !== undefined && e[0].value !== undefined) {
            convertProfile(e[0], "pax");
            dispatch({ type: 'selpaxfn', payload: e })
        } else {
            dispatch({ type: 'paxfn', payload: e })
            dispatch({ type: 'selpaxfn', payload: [] })
        }
    }

    const handleTravellersData = () => {
        if (state.ptype == "ADT") {
            let adtList = props.traveList.filter(
                (pax) => pax.pax_type === "ADT"
                );
            dispatch({ type: "paxtype", payload: adtList });
            dispatch({ type: 'infantEnable', payload: true })
        }
        if (state.ptype == "CHD") {
            let chdList = props.traveList.filter(
                (pax) => pax.pax_type === "CHD"
              );
            dispatch({ type: "paxtype", payload: chdList });
            dispatch({ type: 'infantEnable', payload: true })
        }
        if (state.ptype == "INF") {
            let infList = props.traveList.filter(
                (pax) => pax.pax_type === "INF"
              );
            dispatch({ type: "paxtype", payload: infList });
            dispatch({ type: 'infantEnable', payload: false })
        }
    }

    const handlechangePax = (evt) => {
        try {
            const { id, value } = evt.target;
            evt.preventDefault();
            evt.nativeEvent.stopImmediatePropagation();
            dispatch({ type: id, payload: value });
            if (id == "pax_tl") {
                dispatch({ type: "paxtl", payload: value });
            }
            else if (id == "pax_fn") {
                dispatch({ type: "paxfn", payload: value });
            }
            else if (id == "pax_ln") {
                dispatch({ type: "paxln", payload: value });
            }
            else if (id == "pax_phn") {
                dispatch({ type: "paxphn", payload: value });
            }
            else if (id == "pax_email") {
                dispatch({ type: "paxem", payload: value });
            }
            else if (id == "pax_tn") {
                dispatch({ type: "paxtn", payload: value });
            }
            if (id == "num") {
                dispatch({ type: "pa", payload: value });
            }
            if (id == "emd_number") {
                dispatch({ type: "emd_number", payload: value });
            }

            //   dispatch({ type: "data", payload: payload });
        } catch (err) {
            log.error('Exception occured in handleChange function---' + err);
        }

    }
    const handleDobChange = date => {
        const convertDate = (date !== null ? DateUtils.convertHiToDate(date) : "");
        dispatch({ type: 'dob', payload: convertDate })
        dispatch({ type: 'paxdob', payload: convertDate })
    }

    const handleNationality = (data) => {
        dispatch({ type: "na", payload: data.value })
        dispatch({ type: "nat", payload: [{ "label": data.label, "value": data.value }] })
    }
    const handleIsc = (data) => {
        dispatch({ type: "isc", payload: data.value })
        dispatch({ type: "selIsc", payload: [{ "label": data.label, "value": data.value }] })
    }

    const updatePassenger = (type) => {
        if (validatePax()) {
            let onBgprice = 0;
            let reBgprice = 0;
            let onMealprice = 0;
            let reMealprice = 0;
            let onSeatprice = 0;
            let reSeatprice = 0;
            for (let pax of state.passengers) {
                if (type!="inf"){
                if (pax.sno === state.sno) {
                    pax.trave_id=state.trave_id;
                    pax.dob = state.paxdob
                    pax.exd = state.paxexd
                    pax.ty = state.ptype;
                    pax.tl = state.paxtl;
                    pax.fn = state.paxfn;
                    pax.ln = state.paxln;
                    pax.em = state.paxem;
                    pax.emd_number = state.emd_number;
                    pax.segst = "Confirmed";
                    pax.tkt = state.paxtn;
                    pax.tkt_ret = state.paxRettn;
                    pax.phn = state.paxphn;
                    pax.phc = state.paxphc;
                    if (pax.ty !== "INF") {
                        pax.opref.sa = state.sa
                        pax.opref.mp = state.mp
                        pax.opref.sp = state.sp
                    }
                    if (pax.baggage !== undefined && pax.baggage.length !== 0) {
                        for (let bag of pax.baggage) {
                            if (bag.nm === "OutwardLuggageOptions") {
                                onBgprice = bag.price;
                            } else {
                                reBgprice = bag.price;
                            }
                        }
                    }
                    if (pax.meals !== undefined && pax.meals.length !== 0) {
                        for (let m of pax.meals) {
                            if (m.nm === "OutwardMealOptions") {
                                onMealprice = m.price;
                            } else {
                                reMealprice = m.price;
                            }
                        }
                    }
                    if (pax.seats !== undefined && pax.seats.length !== 0) {
                        for (let seat of pax.seats) {
                            if (seat.jtype === "O") {
                                onSeatprice = seat.price;
                            } else {
                                reSeatprice = seat.price;
                            }
                        }
                    }
                    let baggageList = [];
                    let mealList = [];
                    let seatList = [];
                    if (props.sup === "1" && state.ptype !== "INF") {
                        if (Number(state.ttype) === 1) {
                            //Baggage
                            if (state.onwBglabel !== "" && state.onwBgPrice !== "") {
                                baggageList.push(cretaeRequest("OutwardLuggageOptions", state.onwBglabel, state.onwBgPrice));
                            }
                            //Meal
                            if (state.onwMeallabel !== "" && state.onwMealPrice !== "") {
                                mealList.push(cretaeRequest("OutwardMealOptions", state.onwMeallabel, state.onwMealPrice));
                            }
                            if (state.onwSeatlabel !== "" && state.onwSeatPrice !== "") {
                                seatList.push(cretaeSeatRequest(state.onwSeatlabel, "O", state.onwSeatPrice));
                            }
                            updateExtraPrices(onBgprice,onMealprice,onSeatprice,0,0,0)
                        } else if (Number(state.ttype) === 2) {
                            //Baggage
                            if (state.onwBglabel !== "" && state.onwBgPrice !== "") {
                                baggageList.push(cretaeRequest("OutwardLuggageOptions", state.onwBglabel, state.onwBgPrice));
                            }
                            if (state.retBglabel !== "" && state.retBgPrice !== "") {
                                baggageList.push(cretaeRequest("ReturnLuggageOptions", state.retBglabel, state.retBgPrice));
                            }
                            //Meal
                            if (state.onwMeallabel !== "" && state.onwMealPrice !== "") {
                                mealList.push(cretaeRequest("OutwardMealOptions", state.onwMeallabel, state.onwMealPrice));
                            }
                            if (state.retMeallabel !== "" && state.retMealPrice !== "") {
                                mealList.push(cretaeRequest("ReturnMealOptions", state.retMeallabel, state.retMealPrice));
                            }
                            //Seat
                            if (state.onwSeatlabel !== "" && state.onwSeatPrice !== "") {
                                seatList.push(cretaeSeatRequest(state.onwSeatlabel, "O", state.onwSeatPrice));
                            }
                            if (state.retSeatlabel !== "" && state.retSeatPrice !== "") {
                                seatList.push(cretaeSeatRequest(state.retSeatlabel, "R", state.retSeatPrice));
                            }
                            updateExtraPrices(onBgprice,onMealprice,onSeatprice,reBgprice,reMealprice,reSeatprice)
                        }
                    }
                    pax.baggage = baggageList
                    pax.meals = mealList
                    pax.seats = seatList
                }
            }else{
                if (pax.inf?.sno === state.sno) {
                    pax.inf.trave_id=state.trave_id;
                    pax.inf.dob = state.paxdob
                    pax.inf.exd = state.paxexd
                    pax.inf.ty = state.ptype;
                    pax.inf.tl = state.paxtl;
                    pax.inf.fn = state.paxfn;
                    pax.inf.ln = state.paxln;
                    pax.inf.em = state.paxem;
                    pax.inf.emd_number = state.emd_number;
                    pax.inf.segst = "Confirmed";
                    pax.inf.tkt = state.paxtn;
                    pax.inf.tkt_ret = state.paxRettn;
                    pax.inf.phn = state.paxphn;
                    pax.inf.phc = state.paxphc;
                    if (pax.inf.baggage !== undefined && pax.inf.baggage.length !== 0) {
                        for (let bag of pax.inf.baggage) {
                            if (bag.nm === "OutwardLuggageOptions") {
                                onBgprice = bag.price;
                            } else {
                                reBgprice = bag.price;
                            }
                        }
                    }
                    if (pax.inf.meals !== undefined && pax.inf.meals.length !== 0) {
                        for (let m of pax.inf.meals) {
                            if (m.nm === "OutwardMealOptions") {
                                onMealprice = m.price;
                            } else {
                                reMealprice = m.price;
                            }
                        }
                    }
                    if (pax.inf.seats !== undefined && pax.inf.seats.length !== 0) {
                        for (let seat of pax.inf.seats) {
                            if (seat.jtype === "O") {
                                onSeatprice = seat.price;
                            } else {
                                reSeatprice = seat.price;
                            }
                        }
                    }
                }
            }
            }
            dispatch({ type: 'isInfType', payload: "" })
            dispatch({ type: 'enablePax', payload: false })
            dispatch({ type: 'updateinf', payload: false })
            dispatch({ type: 'isManualReissue', payload: false })
            if(props.trip==="Onward"){
                setOnwardPaxlist(state.passengers);
                props.sendOwPax(state.passengers);
            }else if(props.trip==="Return"){
                setReturnPaxlist(state.passengers)
                props.sendRtPax(state.passengers);
            }
            clearPax();
        }
    }

    

    const updateExtraPrices=(onBgprice,onMealprice,onSeatprice,reBgprice,reMealprice,reSeatprice)=>{
        if(Number(state.ttype)===1){
          if(state.ptype==="ADT"){
            if(Number(state.onwBgPrice)!==0){
              dispatch({ type: 'adtBagTotal', payload: Number(state.adtBagTotal) + Number(state.onwBgPrice) - Number(onBgprice) })
            }
            if(Number(state.onwMealPrice)!==0){
              dispatch({ type: 'adtMealTotal', payload: Number(state.adtMealTotal) + Number(state.onwMealPrice) - Number(onMealprice) })
            }
            if(Number(state.onwSeatPrice)!==0){
              dispatch({ type: 'adtSeatTotal', payload: Number(state.adtSeatTotal) + Number(state.onwSeatPrice) - Number(onSeatprice) })
            }
          }else{
            if(Number(state.onwBgPrice)!==0){
              dispatch({ type: 'chdBagTotal', payload: Number(state.chdBagTotal) + Number(state.onwBgPrice) - Number(onBgprice)})
            }
            if(Number(state.onwMealPrice)!==0){
              dispatch({ type: 'chdMealTotal', payload: Number(state.chdMealTotal) + Number(state.onwMealPrice) - Number(onMealprice) })
            }
            if(Number(state.onwSeatPrice)!==0){
              dispatch({ type: 'chdSeatTotal', payload: Number(state.chdSeatTotal) + Number(state.onwSeatPrice) - Number(onSeatprice) })
            }
          }
        }else{
          if(state.ptype==="ADT"){
            if(Number(state.onwBgPrice)!==0){
              dispatch({ type: 'adtBagTotal', payload: Number(state.adtBagTotal) + Number(state.onwBgPrice) - Number(onBgprice)})
            }
            if(Number(state.retBgPrice)!==0){
             dispatch({ type: 'adtRetBagTotal', payload:Number(state.adtRetBagTotal) + Number(state.retBgPrice) - Number(reBgprice) })
            }
            if(Number(state.onwMealPrice)!==0){
              dispatch({ type: 'adtMealTotal', payload: Number(state.adtMealTotal) + Number(state.onwMealPrice) - Number(onMealprice)})
            }
            if(Number(state.retMealPrice)!==0){
              dispatch({ type: 'adtRetMealTotal', payload: Number(state.adtRetMealTotal) + Number(state.retMealPrice) - Number(reMealprice) })
             }
            if(Number(state.onwSeatPrice)!==0){
              dispatch({ type: 'adtSeatTotal', payload:Number(state.adtSeatTotal) + Number(state.onwSeatPrice) - Number(onSeatprice) })
            }
            if(Number(state.retSeatPrice)!==0){
              dispatch({ type: 'adtRetSeatTotal', payload: Number(state.adtRetMealTotal) + Number(state.retSeatPrice) - Number(reSeatprice) })
             }
            }else{
            if(Number(state.onwBgPrice)!==0){
              dispatch({ type: 'chdBagTotal', payload: Number(state.chdBagTotal) + Number(state.onwBgPrice) - Number(onBgprice) })
            }
            if(Number(state.retBgPrice)!==0){
              dispatch({ type: 'chdRetBagTotal', payload: Number(state.chdRetBagTotal) + Number(state.retBgPrice) - Number(reBgprice) })
            }
            if(Number(state.onwMealPrice)!==0){
              dispatch({ type: 'chdMealTotal', payload: Number(state.chdMealTotal) + Number(state.onwMealPrice) - Number(onMealprice) })
            }
            if(Number(state.retMealPrice)!==0){
              dispatch({ type: 'chdRetMealTotal', payload: Number(state.chdRetMealTotal) + Number(state.retMealPrice) - Number(reMealprice) })
            }
  
            if(Number(state.onwSeatPrice)!==0){
              dispatch({ type: 'chdSeatTotal', payload: Number(state.chdSeatTotal) + Number(state.onwSeatPrice) - Number(onSeatprice) })
            }
  
            if(Number(state.retSeatPrice)!==0 ){
              dispatch({ type: 'chdRetSeatTotal', payload: Number(state.chdRetSeatTotal) + Number(state.retSeatPrice) - Number(reSeatprice) })
            }
          }
        }
      }

    const cancelPax = () => {
        dispatch({ type: 'isManualReissue', payload: false })
        dispatch({ type: 'enablePax', payload: false })
        clearPax();
    }

    const handleChangePnr = (evt) => {
        const { id, value } = evt.target;
        dispatch({ type: id, payload: value });
    }

    const addPassenger = () => {
        if (validatePax()) {
            let baggageList = [];
            let mealList = [];
            let seatList = [];
            if (props.sup === "1" && state.ptype !== "INF") {
                if (Number(state.ttype) === 1) {
                    //Baggage
                    if (state.onwBglabel !== "" && state.onwBgPrice !== "") {
                        baggageList.push(cretaeRequest("OutwardLuggageOptions", state.onwBglabel, state.onwBgPrice));
                    }
                    //Meal
                    if (state.onwMeallabel !== "" && state.onwMealPrice !== "") {
                        mealList.push(cretaeRequest("OutwardMealOptions", state.onwMeallabel, state.onwMealPrice));
                    }
                    if (state.onwSeatlabel !== "" && state.onwSeatPrice !== "") {
                        seatList.push(cretaeSeatRequest(state.onwSeatlabel, "O", state.onwSeatPrice));
                    }
                    updateExtraPrices(0,0,0,0,0,0)
                } else if (Number(state.ttype) === 2) {
                    //Baggage
                    if (state.onwBglabel !== "" && state.onwBgPrice !== "") {
                        baggageList.push(cretaeRequest("OutwardLuggageOptions", state.onwBglabel, state.onwBgPrice));
                    }
                    if (state.retBglabel !== "" && state.retBgPrice !== "") {
                        baggageList.push(cretaeRequest("ReturnLuggageOptions", state.retBglabel, state.retBgPrice));
                    }
                    //Meal
                    if (state.onwMeallabel !== "" && state.onwMealPrice !== "") {
                        mealList.push(cretaeRequest("OutwardMealOptions", state.onwMeallabel, state.onwMealPrice));
                    }
                    if (state.retMeallabel !== "" && state.retMealPrice !== "") {
                        mealList.push(cretaeRequest("ReturnMealOptions", state.retMeallabel, state.retMealPrice));
                    }
                    //Seat
                    if (state.onwSeatlabel !== "" && state.onwSeatPrice !== "") {
                        seatList.push(cretaeSeatRequest(state.onwSeatlabel, "O", state.onwSeatPrice));
                    }
                    if (state.retSeatlabel !== "" && state.retSeatPrice !== "") {
                        seatList.push(cretaeSeatRequest(state.retSeatlabel, "R", state.retSeatPrice));
                    }
                    updateExtraPrices(0,0,0,0,0,0)
                }
            }
            let paxList=[];
            const payload = {
                "sno": state.passengers.length + 1,
                "lead": state.sno === 0 ? true : false,
                "trave_id":state.trave_id,
                "ty": state.ptype,
                "tl": state.paxtl,
                "fn": state.paxfn,
                "fname": state.paxfn,
                "ln": state.paxln,
                "phc": state.paxphc,
                "phn": state.paxphn,
                "em": state.paxem,
                "emd_number": state.emd_number,
                "segst" : "Confirmed",
                "tkt": state.paxtn,
                "tkt_ret": state.paxRettn,
                "dob": state.paxdob,
                "exd": state.paxexd,
                "opref": { sa: state.sa, sp: state.sp, mp: state.mp },
                "ffinfo": { ffnum: state.ffnum, air: state.air },
                "doc": {
                    pa: state.pa,
                    na: state.na,
                    isc: state.isc,
                    isd: state.isd,
                    exd: state.exd
                },
                "baggage": baggageList,
                "meals": mealList,
                "seats": seatList
            }
            if (!state.infantEnable) {
                payload.opref = "";
                payload.ffinfo = "";
            }
            let tktnum = []
            if (state.ptype == "ADT") {
                let obj = {
                    sno: state.passengers.length + 1,
                    pname: state.paxfn,
                    number: props.sup === "0" ? state.paxtn : state.paxtn + "/" + state.paxRettn
                }
                tktnum.push(obj)
                dispatch({ type: 'adtpax', payload: state.adtpax.concat(tktnum) })
                dispatch({ type: 'adts', payload: state.adts + 1 })
                state.adts = state.adts + 1;
            }
            else if (state.ptype == "CHD") {
                let obj = {
                    sno: state.passengers.length + 1,
                    pname: state.paxfn,
                    number: props.sup === "0" ? state.paxtn : state.paxtn + "/" + state.paxRettn
                }
                tktnum.push(obj)
                dispatch({ type: 'chdpax', payload: state.chdpax.concat(tktnum) })
                dispatch({ type: 'chds', payload: state.chds + 1 })
                state.chds = state.chds + 1;
            }
            else if (state.ptype == "INF") {
                let obj = {
                    sno: state.passengers.length + 1,
                    pname: state.paxfn,
                    number: state.paxtn
                }
                tktnum.push(obj)
                dispatch({ type: 'infpax', payload: state.infpax.concat(tktnum) })
                dispatch({ type: 'infs', payload: state.infs + 1 })
                state.infs = state.infs + 1;
            }
            if (state.ptype == "INF") {
                let all_pax = state.passengers.concat(payload)
                paxList=all_pax;
                dispatch({ type: 'sno', payload: state.passengers.length + 1 })
                dispatch({ type: 'passengers', payload: all_pax })
                let adultFilter = state.adtinf.filter(psg => psg.ty !== "INF"
                )
                for (let adt of adultFilter) {
                    if (adt.ty == "ADT" && adt.inf == undefined) {                        
                        adt.inf = {...payload, sno: Number(adt.sno)}
                        dispatch({ type: 'adtinf', payload: adultFilter })
                        break
                    }
                }
            }
            else {
                let all_pax = state.passengers.concat(payload)
                paxList=all_pax;
                let all_adtinf = state.adtinf.concat(payload)
                dispatch({ type: 'sno', payload: state.passengers.length + 1 })
                dispatch({ type: 'passengers', payload: all_pax })
                dispatch({ type: 'adtinf', payload: all_adtinf })
            }
            const pax={
                adt:state.adts,
                chd:state.chds,
                inf:state.infs
            }
            if(props.trip==="Onward"){
                props.sendOwPaxCount(pax);
                setOnwardPaxlist(paxList);                
                paxList.adtBagTotal = state.adtBagTotal;
                paxList.adtMealTotal = state.adtMealTotal;
                paxList.adtSeatTotal = state.adtSeatTotal;
                paxList.chdBagTotal = state.chdBagTotal;
                paxList.chdMealTotal = state.chdMealTotal;
                paxList.chdSeatTotal = state.chdSeatTotal;
                if(props.ttype === "2") {
                    paxList.adtRetBagTotal = state.adtRetBagTotal;
                    paxList.adtRetMealTotal = state.adtRetMealTotal;
                    paxList.adtRetSeatTotal = state.adtRetSeatTotal;
                    paxList.chdRetBagTotal = state.chdRetBagTotal;
                    paxList.chdRetMealTotal = state.chdRetMealTotal;
                    paxList.chdRetSeatTotal = state.chdRetSeatTotal;     
                }
                props.sendOwPax(paxList);
            }else if(props.trip==="Return"){
                props.sendRtPaxCount(pax);   
                setReturnPaxlist(paxList)    
                paxList.adtRetBagTotal = state.adtRetBagTotal;
                paxList.adtRetMealTotal = state.adtRetMealTotal;
                paxList.adtRetSeatTotal = state.adtRetSeatTotal;
                paxList.chdRetBagTotal = state.chdRetBagTotal;
                paxList.chdRetMealTotal = state.chdRetMealTotal;
                paxList.chdRetSeatTotal = state.chdRetSeatTotal;         
                props.sendRtPax(paxList);
            }
            clearPax();
        }
    }

    const clearPax = () => {
        dispatch({ type: 'isManualReissue', payload: false })
        dispatch({ type: 'paxtl', payload: "" })
        dispatch({ type: 'selptype', payload: [] })
        dispatch({ type: 'emd_number', payload: "" })
        dispatch({ type: 'ptype', payload: "" })
        dispatch({ type: 'paxfn', payload: "" })
        dispatch({ type: 'selpaxfn', payload: [] })
        if (typeaheadRef.current) {
            typeaheadRef.current.clear();
        }
        dispatch({ type: 'paxln', payload: "" })
        dispatch({ type: 'paxem', payload: "" })
        dispatch({ type: 'paxcem', payload: "" })
        dispatch({ type: 'paxphc', payload: "" })
        dispatch({ type: 'paxphn', payload: "" })
        dispatch({ type: 'selpaxtl', payload: [] })
        dispatch({ type: 'paxdob', payload: "" })
        dispatch({ type: 'paxexd', payload: "" })
        dispatch({ type: 'paxdetails', payload: "" })
        dispatch({ type: 'pa', payload: "" })
        dispatch({ type: 'na', payload: "" })
        dispatch({ type: 'isc', payload: "" })
        dispatch({ type: 'isd', payload: "" })
        dispatch({ type: 'exd', payload: "" })
        dispatch({ type: 'sa', payload: "" })
        dispatch({ type: 'sp', payload: "" })
        dispatch({ type: 'mp', payload: "" })
        dispatch({ type: 'ffnum', payload: "" })
        dispatch({ type: 'air', payload: "" })
        dispatch({ type: 'nat', payload: [] })
        dispatch({ type: 'selIsc', payload: [] })
        dispatch({ type: 'selIsd', payload: "" })
        dispatch({ type: 'selExd', payload: "" })
        dispatch({ type: 'paxselPhc', payload: [] })
        dispatch({ type: 'paxtn', payload: "" })
        dispatch({ type: 'paxRettn', payload: "" })
        dispatch({ type: 'onwBglabel', payload: "" })
        dispatch({ type: 'onwBgPrice', payload: "" })
        dispatch({ type: 'onwMeallabel', payload: "" })
        dispatch({ type: 'onwMealPrice', payload: "" })
        dispatch({ type: 'onwSeatlabel', payload: "" })
        dispatch({ type: 'onwSeatPrice', payload: "" })
        dispatch({ type: 'retBglabel', payload: "" })
        dispatch({ type: 'retBgPrice', payload: "" })
        dispatch({ type: 'retMeallabel', payload: "" })
        dispatch({ type: 'retMealPrice', payload: "" })
        dispatch({ type: 'retSeatlabel', payload: "" })
        dispatch({ type: 'retSeatPrice', payload: "" })

    }


    const validatePax = () => {
        if (state.ptype == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Pax type for pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.ptype !== "ADT" && state.passengers.length == 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select pax as Adult for Lead pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        else if (state.adts.length=== 0 && state.ptype == "INF") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select paxtype as Adult for lead pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        else if (state.paxtl == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Title for pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.paxfn == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid First name  ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.paxfn !== "" && state.paxfn !== undefined && state.paxfn.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'First name should be minimum two characters for pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        else if (state.paxln == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid Last name for Pax ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.paxln !== "" && state.paxln !== undefined && state.paxln.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Last name should be minimum two characters for Pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue && (state.paxdob == null || state.paxdob == "")) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Date of birth for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue &&(state.paxphc == "" && state.sno === 0)) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid Phone code for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue &&(state.paxphn == "" && state.sno === 0)) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Phone number for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue && !mobileNumberValidation(state.paxphn) && state.sno === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid Phone number for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue && (state.paxem == "" && state.sno === 0)) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Email id for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (!props.manualReissue && state.sno === 0 && !emailValidation(state.paxem)) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid Email id for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        else if ((state.paxtn == "" && props.sup == "0")||((state.paxtn ==undefined || state.paxtn == "") && props.manualReissue)) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Ticket Number number for pax' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.selTt.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Journey Type' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }

        return true;
    }

    const handleRemovePax = (pax, idx) => {
        if(props.manualReissue){
            props.removedPax(state.passengers.filter((p, sidx) => idx !== sidx))
            dispatch({ type: "passengers", payload: state.passengers.filter((p, sidx) => idx !== sidx) });
        }else{
        let adt=state.adts;
        let chd=state.chds;
        let inf=state.infs;
        if (pax.ty === "ADT") {
            adt=state.adts - 1;
            dispatch({ type: 'adts', payload: adt })
            dispatch({ type: "adtpax", payload: state.adtpax.filter(obj => obj.sno !== pax.sno) });
            //props.senAdtCount(adt);
        } else if (pax.ty === "CHD") {
             chd=state.chds - 1;
            dispatch({ type: 'chds', payload: chd })
            dispatch({ type: "chdpax", payload: state.chdpax.filter(obj => obj.sno !== pax.sno) });
            //props.senChdCount(chd);
        } else if (pax.ty === "INF") {
            inf=state.infs - 1;
            dispatch({ type: 'infs', payload: inf })
            dispatch({ type: "infpax", payload: state.infpax.filter(obj => obj.sno !== pax.sno) });
            
            //props.senInfCount(inf);
        }
        let paxList = state.passengers.filter((p, sidx) => idx !== sidx);
        dispatch({ type: "passengers", payload: state.passengers.filter((p, sidx) => idx !== sidx) });
        dispatch({ type: "adtinf", payload: state.adtinf.filter((p, sidx) => idx !== sidx) });
        const paxObj={
            "adt":adt,
            "chd":chd,
            "inf":inf,
            }
        if(props.trip==="Onward"){
            props.sendOwPax(paxList);
            props.sendOwPaxCount(paxObj);
        }else if(props.trip==="Return"){
            props.sendRtPax(paxList);
            props.sendRtPaxCount(paxObj);
         }
        }
        dispatch({ type: 'selpaxfn', payload: [] })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Removed selected Pax successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
    }

    const handleEditPax = (obj, id, type) => {
        if (type!="inf"){
        obj.fname=obj.fn;
        dispatch({ type: 'isManualReissue', payload: true })
        dispatch({ type: 'enablePax', payload: true })
        dispatch({ type: 'trave_id', payload: obj.trave_id })
        dispatch({ type: 'emd_number', payload: obj.emd_number })
        dispatch({ type: 'sno', payload: obj.sno })
        dispatch({ type: 'selpaxtl', payload: [{ "value": obj.tl, "label": obj.tl }] })
        dispatch({ type: "ptype", payload: obj.ty });
        dispatch({ type: "selptype", payload: [{ "value": obj.ty, "label": obj.ty }] });
        dispatch({ type: 'paxtl', payload: obj.tl })
        dispatch({ type: 'paxfn', payload: obj.fn })
        dispatch({ type: 'selpaxfn', payload: [obj] })
        dispatch({ type: 'paxln', payload: obj.ln })
        dispatch({ type: 'paxem', payload: obj.em!==undefined?obj.em:"" })
        dispatch({ type: 'paxphc', payload: obj.phc })
        dispatch({ type: 'paxphn', payload: obj.phn })
        dispatch({ type: 'paxtn', payload: obj.tkt })
        dispatch({ type: 'paxRettn', payload: obj.tkt_ret })
        dispatch({ type: 'paxdob', payload: obj.dob })
        dispatch({ type: 'paxexd', payload: obj.exd })
        stdCodes.map((std, sidx) => {
            if (std.value!=="" && Number(std.value) == Number(obj.phc)) {
                dispatch({ type: 'paxselPhc', payload: [{ "value": std.value, "label": std.label }] })
            }
        });
        if (obj.doc !== undefined) {
            if (obj.doc.pa !== undefined && obj.doc.pa !== "" && obj.doc.pa !== null) {
                dispatch({ type: 'pa', payload: obj.doc.pa })
            } else {
                dispatch({ type: 'pa', payload: obj.doc.num })
            }
            dispatch({ type: 'na', payload: obj.doc.na })
            dispatch({ type: 'isc', payload: obj.doc.isc })
            dispatch({ type: 'isd', payload: obj.doc.isd !== null && obj.doc.isd !== "" ? DateUtils.convertHiToDate(obj.doc.isd) : null })
            dispatch({ type: 'exd', payload: DateUtils.convertHiToDate(obj.doc.exd) })
           
            countriesList.map((country, sidx) => {
                if (obj.doc.na == country.value) {
                    dispatch({ type: 'nat', payload: [{ label: country.label, value: country.label }] })
                }
            })
            countriesList.map((country, sidx) => {
                if (obj.doc.isc == country.value) {
                    dispatch({ type: 'selIsc', payload: [{ label: country.label, value: country.label }] })
                }
            })
            dispatch({ type: 'selIsd', payload: obj.doc.isd !== null ? DateUtils.convertToDate(obj.doc.isd) : "" })
            dispatch({ type: 'selExd', payload: obj.doc.exd !== null ? DateUtils.convertToDate(obj.doc.exd) : "" })
        }
        if (obj.baggage !== undefined && obj.baggage.length !== 0) {
            let onwardBagList = obj.baggage.filter(bag => bag.nm === "OutwardLuggageOptions");
            if (onwardBagList.length !== 0) {
                dispatch({ type: 'onwBglabel', payload: onwardBagList[0].label })
                dispatch({ type: 'onwBgPrice', payload: onwardBagList[0].price })
            }
            let retunBagList = obj.baggage.filter(bag => bag.nm === "ReturnLuggageOptions");
            if (retunBagList.length !== 0) {
                dispatch({ type: 'retBglabel', payload: retunBagList[0].label })
                dispatch({ type: 'retBgPrice', payload: retunBagList[0].price })
            }
        }
        if (obj.meals !== undefined && obj.meals.length !== 0) {
            let onwardmealList = obj.meals.filter(meal => meal.nm === "OutwardMealOptions");
            if (onwardmealList.length !== 0) {
                dispatch({ type: 'onwMeallabel', payload: onwardmealList[0].label })
                dispatch({ type: 'onwMealPrice', payload: onwardmealList[0].price })
            }
            let retunMealList = obj.meals.filter(meal => meal.nm === "ReturnMealOptions");
            if (retunMealList.length !== 0) {
                dispatch({ type: 'retMeallabel', payload: retunMealList[0].label })
                dispatch({ type: 'retMealPrice', payload: retunMealList[0].price })
            }
        }
        if (obj.seats !== undefined && obj.seats.length !== 0) {
            let onwardSeatList = obj.seats.filter(seat => seat.jtype === "O");
            if (onwardSeatList.length !== 0) {
                dispatch({ type: 'onwSeatlabel', payload: onwardSeatList[0].sno })
                dispatch({ type: 'onwSeatPrice', payload: onwardSeatList[0].price })
            }
            let retunSeatList = obj.seats.filter(seat => seat.jtype === "R");
            if (retunSeatList.length !== 0) {
                dispatch({ type: 'retSeatlabel', payload: retunSeatList[0].sno })
                dispatch({ type: 'retSeatPrice', payload: retunSeatList[0].price })
            }
        }
        if (obj.ty == "INF") {
            dispatch({ type: 'updateinf', payload: true })
        }
      }else{
        obj.inf.fname=obj.inf.fn;
        dispatch({ type: 'isInfType', payload: type })
        dispatch({ type: 'isManualReissue', payload: true })
        dispatch({ type: 'enablePax', payload: true })
        dispatch({ type: 'trave_id', payload: obj.inf.trave_id })
        dispatch({ type: 'emd_number', payload: obj.inf.emd_number })
        dispatch({ type: 'sno', payload: obj.inf.sno })
        dispatch({ type: 'selpaxtl', payload: [{ "value": obj.inf.tl, "label": obj.inf.tl }] })
        dispatch({ type: "ptype", payload: obj.inf.ty });
        dispatch({ type: "selptype", payload: [{ "value": obj.inf.ty, "label": obj.inf.ty }] });
        dispatch({ type: 'paxtl', payload: obj.inf.tl })
        dispatch({ type: 'paxfn', payload: obj.inf.fn })
        dispatch({ type: 'selpaxfn', payload: [obj.inf] })
        dispatch({ type: 'paxln', payload: obj.inf.ln })
        dispatch({ type: 'paxem', payload: obj.inf.em!==undefined?obj.inf.em:"" })
        dispatch({ type: 'paxphc', payload: obj.inf.phc })
        dispatch({ type: 'paxphn', payload: obj.inf.phn })
        dispatch({ type: 'paxtn', payload: obj.inf.tkt })
        dispatch({ type: 'paxRettn', payload: obj.inf.tkt_ret })
        dispatch({ type: 'paxdob', payload: obj.inf.dob })
        dispatch({ type: 'paxexd', payload: obj.inf.exd })
      }
    }

    const cretaeRequest = (name, label, price) => {
        let tempStr = label
        const tempArray = tempStr.split("-");
        if (tempArray.length > 1) {
            tempStr = tempArray[0]
        }
        let newLabel = tempStr + "- " + price + " " + props.cur
        const payload = {
            "nm": name,
            "val": label,
            "label": newLabel,
            "price": price,
            "sup_price": price,
            "sup_cur_price": 0,
            "mkp": 0,
            "tid": ""
        }
        return payload;
    }

    const cretaeSeatRequest = (seat, type, price) => {
        const payload = {
            "sno": seat,
            "jtype": type,
            "route": "",
            "price": price,
            "sup_price": price,
            "sup_cur_price": 0,
            "tid": ""
        }
        return payload;
    }

    const hidemessage = () => {
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
    }

    const extrasLcc = () => {
        setExtras(!extras);
    }

    return (
        <div className="paxDetails pb-0 commonWrap">
            <div className="paxDetailin pt-0">
                <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                    onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                    dismissible> {state.notiMessage}</Alert>

                    <div className="BookingDetails">
                <h5 className="layoutTitle  d-flex justify-content-between mb-3">{!props.manualReissue?<span>{props.jtype !== "Round Trip" ? props.trip : ""} Passenger Details</span>:"Pax Details"}</h5>
                <Form.Row>
                   {(!props.manualReissue || state.isManualReissue) &&
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="pax_type">
                        <Form.Label>Pax Type<sup>*</sup></Form.Label>
                        <Select
                            options={paxType}
                            searchable={false}
                            value={state.selptype}
                            onChange={value => (handlepaxType(value))}
                            isDisabled={state.enablePax}
                            placeholder="Select"
                            classNamePrefix='filter'
                            theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                            },
                            })}
                        />

                    </Form.Group>}
                    {(!props.manualReissue || state.isManualReissue) &&
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="pax_title">
                        <Form.Label>Title <sup>*</sup></Form.Label>
                        <Select
                            options={titles}
                            searchable={false}
                            value={state.selpaxtl}
                            onChange={value => (handlepaxTitle(value))}
                            placeholder="Select"
                            classNamePrefix='filter'
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#F0F0F0',
                                    primary: '#3156ab',
                                    cursor: 'pointer',
                                },
                                })}
                        />

                    </Form.Group>}
                    {(!props.manualReissue || state.isManualReissue) &&
                    <Form.Group as={Col} className="selectplugin" xs={3}>
                        <Form.Label>First Name <sup>*</sup></Form.Label>
                        <Typeahead
                            ref={typeaheadRef}
                            id="pax_fn"
                            labelKey={prof => `${prof.fname}`}
                            options={state.paxtype && state.paxtype.length !== 0 ? state.paxtype : [] }
                            placeholder="First Name"
                            selected={state.selpaxfn}
                            //value={state.paxfn}
                            onInputChange={handlepaxChange}
                            onChange={pax => handlepaxChange(pax)}
                            onFocus={handleTravellersData}
                            />
                    </Form.Group>}
                    {(!props.manualReissue || state.isManualReissue) &&
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="pax_ln">
                        <Form.Label>Last Name <sup>*</sup></Form.Label>
                        <Form.Control type="text" placeholder="Last Name" autoComplete="off" onChange={handlechangePax} value={state.paxln} />

                    </Form.Group>}
                    {!props.manualReissue &&
                    <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="pax_dob">
                        <Form.Label>Date of Birth <sup>*</sup></Form.Label>
                        {(state.ptype == "ADT" || state.ptype == "") &&
                            <DatePicker
                                selected={paxType.dob && DateUtils.convertStringToDate(paxType.dob)}
                                value={state.paxdob}
                                onChange={date => handleDobChange(date)}
                                placeholderText="DD-MM-YYYY"
                                dateFormat="dd-MM-yyyy"
                                className="form-control dbcntrl"
                                maxDate={paxDate(new Date(), 12).setDate(new Date().getDate() - 1)}
                                minDate={new Date(1900, 1, 1)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />

                        }
                        <span className="calendar ezyIcon icon-newcalendar"></span>

                        {state.ptype === "CHD" &&
                            <DatePicker
                                selected={paxType.dob && DateUtils.convertNewToDate(paxType.dob)}
                                onChange={value => handleDobChange(value)}
                                value={state.paxdob}
                                placeholderText="DD-MM-YYYY"
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                maxDate={paxDate(new Date(), 2).setDate(new Date().getDate() - 1)}
                                minDate={state.chd_mindate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        }
                        {state.ptype === "INF" &&
                            <DatePicker
                                selected={paxType.dob && DateUtils.convertNewToDate(paxType.dob)}
                                onChange={value => handleDobChange(value)}
                                value={state.paxdob}
                                placeholderText="DD-MM-YYYY"
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                maxDate={paxDate(new Date(), 0)}
                                minDate={state.inf_mindate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        }

                    </Form.Group>}
                    {!props.manualReissue && state.ptype == "ADT" &&
                        <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} controlId="pax_phc">
                            <Form.Label>Phone Code<sup>*</sup></Form.Label>
                            <Select
                                options={stdCodes}
                                searchable={true}
                                value={state.paxselPhc}
                                onChange={value => handlepaxphnCode(value)}
                                placeholder="Select"
                                classNamePrefix='filter'
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0F0F0',
                                            primary: '#3156ab',
                                            cursor: 'pointer',
                                        },
                                    })}
                                
                            />
                        </Form.Group>
                    }
                    {!props.manualReissue && state.ptype == "ADT" &&
                        <Form.Group className="selectplugin" as={Col} xs={3} controlId="pax_phn">
                            <Form.Label>Phone <sup>*</sup></Form.Label>
                            <Form.Control type="text" placeholder="Phone" autoComplete="off" onChange={handlechangePax} value={state.paxphn} />
                        </Form.Group>
                    }
                    {!props.manualReissue && state.ptype == "ADT" &&
                        <Form.Group className="selectplugin" as={Col} xs={3} controlId="pax_email">
                            <Form.Label>Email <sup>*</sup></Form.Label>
                            <Form.Control type="text" placeholder="Email" autoComplete="off" onChange={handlechangePax} value={state.paxem} />
                        </Form.Group>
                    }
                    {(!props.manualReissue || state.isManualReissue) &&
                        <Form.Group as={Col} xs={3} controlId="pax_tn" className="selectplugin">
                            <Form.Label>{props.trip && !props.manualReissue} Ticket Number {props.sup === "0"?<sup>*</sup>:""}</Form.Label>
                            <Form.Control type="text" placeholder="Ticket Number" autoComplete="off" maxlength="14" onKeyUp={(e) => Utilities.handleKeyUp(e)} onChange={handlechangePax} value={state.paxtn} />

                        </Form.Group>}
                    
                    
                        {props.sup==="1" && Number(state.ttype)===2 && !props.isContentAgg &&
                          <Form.Group as={Col} xs={3} controlId="paxRettn" className="selectplugin">
                          <Form.Label>Return Ticket Number</Form.Label>
                          <Form.Control type="text" placeholder="Ticket Number" autoComplete="off"  maxlength="14" onKeyUp={(e) => Utilities.handleKeyUp(e)} onChange={handlechangePax} value={state.paxRettn} />
                          </Form.Group>
                          }

                     {props.manualReissue && state.isManualReissue &&
                      <Form.Group as={Col} xs={3} controlId="emd_number" className="selectplugin">
                        <Form.Label>EMD Number</Form.Label>
                        <Form.Control type="text" placeholder="EMD Number" autoComplete="off" value={state.emd_number} onChange={handlechangePax} />

                    </Form.Group>}

                </Form.Row>
           
            <div className="passPortinfo buttonGrop">
                {!props.manualReissue &&
                <h5 className="layoutTitle  d-flex justify-content-between mb-3 mt-3"><span>Passport Info</span></h5>}
                {!props.manualReissue &&
               <Form.Row>
                    <Form.Group as={Col} xs={3} controlId="num" className="selectplugin">
                        <Form.Label>Passport Number</Form.Label>
                        <Form.Control type="text" placeholder="Passport Number" autoComplete="off" value={state.pa} onChange={handlechangePax} />

                    </Form.Group>

                    <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} controlId="na">
                        <Form.Label>Nationality</Form.Label>
                        <Select
                            options={countriesList}
                            searchable={false}
                            value={state.nat}
                            onChange={value => handleNationality(value)}
                            placeholder="Select"
                            classNamePrefix='filter'
                            theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                            },
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} controlId="isc">
                        <Form.Label>Issued Country</Form.Label>
                        <Select
                            options={countriesList}
                            searchable={false}
                            value={state.selIsc}
                            onChange={value => handleIsc(value)}
                            placeholder="Select"
                            classNamePrefix='filter'
                            theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                            },
                            })}
                        />

                    </Form.Group>
                    {(state.ptype == "ADT" || state.ptype == "") &&
                        <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="isd">
                            <Form.Label>Issued Date</Form.Label>
                            <DatePicker
                                // selected={state.exp}
                                value={state.isd}
                                onChange={date => handleissueDate(date)}
                                fromDate={state.exp}
                                toDate={toDate}
                                placeholderText="DD-MM-YYYY"
                                maxDate={new Date()}
                                dateFormat="dd-MM-yyyy"
                                //minDate={new Date(1900, 1, 1)}
                                showMonthDropdown
                                showYearDropdown
                                peekNextMonth
                                dropdownMode="select"
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>

                        </Form.Group>
                    }
                    {state.ptype == "CHD" &&
                        <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="isd">
                            <Form.Label>Issued Date</Form.Label>
                            <DatePicker
                                // selected={state.exp}
                                value={state.isd}
                                onChange={date => handleissueDate(date)}
                                fromDate={state.exp}
                                toDate={toDate}
                                placeholderText="DD-MM-YYYY"
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                //minDate={state.chd_mindate}
                                peekNextMonth
                                dropdownMode="select"
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>

                        </Form.Group>
                    }
                    {state.ptype == "INF" &&
                        <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="isd">
                            <Form.Label>Issued Date</Form.Label>
                            <DatePicker
                                // selected={state.exp}
                                value={state.isd}
                                onChange={date => handleissueDate(date)}
                                fromDate={state.exp}
                                placeholderText="DD-MM-YYYY"
                                toDate={toDate}
                                minDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>

                        </Form.Group>
                    }


                    <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="exd">
                        <Form.Label>Expiry Date</Form.Label>
                        <DatePicker
                            // selected={state.exp}
                            value={state.paxexd}
                            onChange={date => handleexpDate(date)}
                            fromDate={fromDate}
                            toDate={toDate}
                            placeholderText="DD-MM-YYYY"
                            minDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                        <span className="calendar ezyIcon icon-newcalendar"></span>
                    </Form.Group>
                </Form.Row>
                }
                {state.ptype !== "INF" && props.sup === "1" &&
                <div className="OtherPreferenceInfo">
                    {/* <Tabs defaultActiveKey="" id="otherInfo" className="extraTab"> */}

                        {/* <Tab eventKey="extra" title={<><span className="iconSy">
                            <span className="plus ezyIcon icon-plus"></span>
                            <span className="minus ezyIcon icon-minus"></span>
                        </span><span>Extras</span></>}> */}
                        <span onClick={extrasLcc} className={extras ? "active" : ""}>{extras ? <span className="minus ezyIcon icon-minus"></span> : <span className="plus ezyIcon icon-plus"></span>} <span>Extras</span></span>
                        {extras && (
                            <div className="extradetails extra eachList">
                                <Tabs defaultActiveKey="bgg" id="extraType" className="extralist">
                                    <Tab eventKey="bgg" title={<><span className="ezyIcon icon-tBaggage"></span> <span>Add Baggage</span> </>} className="addExtraOption">
                                        <Tabs defaultActiveKey="dtp" id="roots">
                                            {Number(state.ttype) !== 0 && props.trip === "Onward" &&
                                                <Tab eventKey="dtp" title="Onward">
                                                    <div className="extrainfolcc">
                                                        <span className="extraIcon ezyIcon"></span>
                                                        <Form.Row>
                                                        <Form.Group as={Col} xs={3} controlId="onwBglabel">
                                                            <Form.Label>Baggage</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Bagage Label" autoComplete="off" onChange={handleChangePnr} value={state.onwBglabel} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} xs={3} controlId="onwBgPrice">
                                                            <Form.Label>Price</Form.Label>
                                                            <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.onwBgPrice} />
                                                        </Form.Group>
                                                        </Form.Row>
                                                    </div>
                                                </Tab>
                                            }
                                            {Number(state.ttype) === 2 && !props.isContentAgg &&
                                                <Tab eventKey="arv" title="Return">
                                                    <div className="extrainfolcc">
                                                        <span className="extraIcon ezyIcon"></span>
                                                        <Form.Row>
                                                        <Form.Group as={Col} xs={3} controlId="retBglabel">
                                                            <Form.Label>Baggage</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Bagage Label" autoComplete="off" onChange={handleChangePnr} value={state.retBglabel} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} xs={3} controlId="retBgPrice">
                                                            <Form.Label>Price</Form.Label>
                                                            <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retBgPrice} />
                                                        </Form.Group>
                                                        </Form.Row>
                                                    </div>
                                                </Tab>
                                            }
                                            {props.isContentAgg && props.trip === "Return" &&
                                                <Tab eventKey="dtp" title="Return">
                                                    <div className="extrainfolcc">
                                                        <span className="extraIcon ezyIcon"></span>
                                                        <Form.Row>
                                                        <Form.Group as={Col} xs={3} controlId="retBglabel">
                                                            <Form.Label>Baggage</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter Bagage Label" autoComplete="off" onChange={handleChangePnr} value={state.retBglabel} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} xs={3} controlId="retBgPrice">
                                                            <Form.Label>Price</Form.Label>
                                                            <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retBgPrice} />
                                                        </Form.Group>
                                                        </Form.Row>
                                                    </div>
                                                </Tab>
                                            }
                                        </Tabs>
                                    </Tab>

                                    <Tab eventKey="admeal" title={<><span className="ezyIcon icon-meal1"></span> Add Meal</>} className="addExtraOption">
                                        <>
                                            <Tabs defaultActiveKey="onml" id="roots">
                                                {Number(state.ttype) !== 0  && props.trip === "Onward" &&
                                                    <Tab eventKey="onml" title="Onward">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="onwMeallabel">
                                                                <Form.Label>Meal</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Meal Label" autoComplete="off" onChange={handleChangePnr} value={state.onwMeallabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="onwMealPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.onwMealPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                                {Number(state.ttype) === 2 && !props.isContentAgg &&
                                                    <Tab eventKey="arv" title="Return">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="retMeallabel">
                                                                <Form.Label>Meal</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Meal Label" autoComplete="off" onChange={handleChangePnr} value={state.retMeallabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="retMealPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retMealPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                                {props.isContentAgg && props.trip === "Return" &&
                                                    <Tab eventKey="onml" title="Return">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="retMeallabel">
                                                                <Form.Label>Meal</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Meal Label" autoComplete="off" onChange={handleChangePnr} value={state.retMeallabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="retMealPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retMealPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </>
                                        <>
                                        </>
                                    </Tab>
                                    <Tab eventKey="admeall" title={<><span className="ezyIcon icon-seatSelect"></span> Select Seats</>} className="addExtraOption">
                                        <>
                                            <Tabs defaultActiveKey="onmll" id="roots">
                                                {Number(state.ttype) !== 0 && props.trip === "Onward" &&
                                                    <Tab eventKey="onmll" title="Onward">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="onwSeatlabel">
                                                                <Form.Label>Seat No</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Seat No" autoComplete="off" onChange={handleChangePnr} value={state.onwSeatlabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="onwSeatPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.onwSeatPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                                {Number(state.ttype) === 2 && !props.isContentAgg &&
                                                    <Tab eventKey="arv" title="Return">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="retSeatlabel">
                                                                <Form.Label>Seat No</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Seat No" autoComplete="off" onChange={handleChangePnr} value={state.retSeatlabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="retSeatPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retSeatPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                                {props.isContentAgg && props.trip === "Return" &&
                                                    <Tab eventKey="onmll" title="Return">
                                                        <div className="extrainfolcc">
                                                            <span className="extraIcon ezyIcon"></span>
                                                            <Form.Row>
                                                            <Form.Group as={Col} xs={3} controlId="retSeatlabel">
                                                                <Form.Label>Seat No</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Seat No" autoComplete="off" onChange={handleChangePnr} value={state.retSeatlabel} />
                                                            </Form.Group>
                                                            <Form.Group as={Col} xs={3} controlId="retSeatPrice">
                                                                <Form.Label>Price</Form.Label>
                                                                <Form.Control type="number" placeholder="Enter Price" autoComplete="off" min={0} onChange={handleChangePnr} value={state.retSeatPrice} />
                                                            </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </>
                                        <>
                                        </>
                                    </Tab>
                                </Tabs>
                            </div>
                        )}
                        {/* </Tab> */}
                    {/* </Tabs> */}
                </div>
            }
                <div className="d-flex justify-content-end text-right">
                    {!state.enablePax && !props.manualReissue && 
                        <Button
                            size="xs"
                            variant="primary m-0"
                            type="submit"
                            className="d-flex plusBtn"
                            onClick={addPassenger}
                        ><span className="ezyIcon icon-plus mr-2"></span>Add</Button>
                    }
                    {state.enablePax &&
                       <Button
                        size="xs"
                        variant="outline-secondary"
                        className="mr-3"
                        type="submit"
                        onClick={cancelPax}
                      > Cancel</Button>
                    }
                    {state.enablePax && state.isInfType !=="inf" &&

                        <Button
                        size="xs"
                        variant="primary"
                        type="submit"
                        onClick={updatePassenger}
                        > Update</Button>
                      
                    }
                    {state.enablePax && state.isInfType =="inf" &&

                        <Button
                        size="xs"
                        variant="primary"
                        type="submit"
                        onClick={()=>updatePassenger("inf")}
                        > Update</Button>
                      
                    }
                </div>

            </div>
            

            <div className="table-responsive tableCell onwardpassDetails">
                <Table>
                    <thead>
                        <tr>
                            {!props.manualReissue &&
                            <th>Lead Pax</th>}
                            {props.manualReissue &&
                            <th>Travellers</th>}
                            <th>Pax Type</th>
                            {!props.manualReissue &&
                            <th>First Name</th>}
                            {!props.manualReissue &&
                            <th>Last Name</th>}
                            <th>Ticket Number</th>
                            {!props.manualReissue &&
                            <th>Email</th>}
                            {props.manualReissue &&
                            <th>EMD Number</th>}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.passengers.length > 0 && state.passengers.map((pasenger, idx) => (
                            <React.Fragment key={idx}>
                                <tr>
                                    {!props.manualReissue && 
                                    <td>
                                        {pasenger.lead ? pasenger.tl + " " + pasenger.fn + " " + "(Lead)" : pasenger.tl + " " + pasenger.fn}
                                    </td>}
                                    {props.manualReissue && 
                                    <td>
                                        {pasenger.lead ? pasenger.fn + " " +pasenger.ln + " " + "(Lead)" : pasenger.fn+ " " +pasenger.ln}
                                    </td>}

                                    <td>
                                        {pasenger.ty}
                                    </td>

                                    {!props.manualReissue &&
                                    <td>
                                        {pasenger.fn}
                                    </td>}

                                    {!props.manualReissue &&
                                    <td>
                                        {pasenger.ln}
                                    </td>}
                                    {props.sup === "0" &&
                                        <td>
                                            {pasenger.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "1" &&
                                        <td>
                                            {pasenger.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.tkt !== "" && pasenger.tkt_ret !== "" &&
                                        <td>
                                            {pasenger.tkt + "/" + pasenger.tkt_ret}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.tkt !== "" && pasenger.tkt_ret === "" &&
                                        <td>
                                            {pasenger.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.tkt === "" && pasenger.tkt_ret !== "" &&
                                        <td>
                                            {pasenger.tkt_ret}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.tkt === "" && pasenger.tkt_ret === "" &&
                                        <td>
                                            {"N/A"}
                                        </td>
                                    }
                                    {!props.manualReissue &&
                                    <td>
                                        {pasenger.em}
                                    </td>}
                                    {props.manualReissue &&
                                    <td>
                                        {pasenger.emd_number}
                                    </td>}
                                    {!state.disable &&
                                        <td>
                                            <div className="d-flex editDele">
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-edit"
                                                dis
                                                //isDisabled={idx == 0 && state.passengers.length>1}
                                                onClick={() => handleEditPax(pasenger, idx)}
                                                type="submit">
                                            </Button>

                                            {!props.manualReissue &&  
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-delete"
                                                onClick={() => handleRemovePax(pasenger, idx)}
                                                type="submit">
                                            </Button>}
                                            </div>
                                        </td>
                                    }
                                
                                </tr>
                                {pasenger.inf!=undefined && pasenger.inf!="" && props.manualReissue &&(
                                    <>
                                    {props.manualReissue && 
                                        <td>
                                            { pasenger.inf.fn+" "+pasenger.inf.ln}
                                        </td>}
    
                                        <td>
                                            {pasenger.inf.ty}
                                        </td>
                                        {props.sup === "0" &&
                                        <td>
                                            {pasenger.inf.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "1" &&
                                        <td>
                                            {pasenger.inf.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.inf.tkt !== "" && pasenger.inf.tkt_ret !== "" &&
                                        <td>
                                            {pasenger.inf.tkt + "/" + pasenger.inf.tkt_ret}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.inf.tkt !== "" && pasenger.inf.tkt_ret === "" &&
                                        <td>
                                            {pasenger.inf.tkt}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.inf.tkt === "" && pasenger.inf.tkt_ret !== "" &&
                                        <td>
                                            {pasenger.inf.tkt_ret}
                                        </td>
                                    }
                                    {props.sup === "1" && state.ttype == "2" && pasenger.inf.tkt === "" && pasenger.inf.tkt_ret === "" &&
                                        <td>
                                            {"N/A"}
                                        </td>
                                    }
                                    {props.manualReissue &&
                                    <td>
                                        {pasenger.inf.emd_number}
                                    </td>}
                                    {!state.disable &&
                                        <td>
                                            <div className="d-flex editDele">
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-edit"
                                                dis
                                                //isDisabled={idx == 0 && state.passengers.length>1}
                                                onClick={() => handleEditPax(pasenger, idx, "inf")}
                                                type="submit">
                                            </Button>

                                            {!props.manualReissue &&
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-delete"
                                                onClick={() => handleRemovePax(pasenger, idx, "inf")}
                                                type="submit">
                                            </Button>}
                                            </div>
                                        </td>
                                    }
                                        </>
                                ) 
                                }
                            </React.Fragment>
                        ))}
                    </tbody>

                </Table>
            </div>
            </div>
            </div>
        </div>
    )
}
export default PassengerInfo