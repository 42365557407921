/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function About(props) {

  return (
    <>
    <Header/>
    <div className="breadcrumbWrap">
        <div className="container">
            <ul className="breadcrumb">
                <li className="breadcrumbMenu">
                    <Link to="/">
                        Home
                    </Link>
                </li>
                <li className="breadcrumbMenu">
                    <Link to="/about">
                        About
                    </Link>
                </li>
            </ul>
        </div>
    </div>
      <div className="aboutPage">
        
      </div>
      <Footer/>
    </>
  );
}

export default About;
