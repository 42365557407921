import CRMCommonService from '../../services/CRMCommonService';

/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function CRM
 * @date : 06-11-2023
 */


// ========================================================================
//  CRM Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const quicksearchHeader = async (obj) => {
    let finalRes = [];
    await CRMCommonService.quicksearch(obj).then(response => {
            finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
// ========================================================================
//  CRM Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const flightSearch = async (obj) => {
    let finalRes = [];
    await CRMCommonService.searchFlightBookings(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  CRM Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const hotelSearch = async (obj) => {
    let finalRes = [];
    await CRMCommonService.searchHotelBookings(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Master Countries List
//================================
export const countriesWitCode = async () => {
    let countriesList = [];
    await CRMCommonService.searchBookings().then(response => {
        if (response.data.suc) {
            countriesList = response.data.res.map((cnt) =>
                <option value={cnt.code}>{cnt.name}</option>
            );
        }

    });
    return Promise.resolve(countriesList);
};
