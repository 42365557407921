import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  Row,
  Button,
  Modal,
} from "react-bootstrap";
import Utilities from "../../utilities/Utilities";
import FormateCurrency from "../../utilities/FormatCurrency";
import { useLocation, useNavigate } from "react-router-dom";
import { processHotelRefund } from "../../dashboard/queues/operations";
import Header from "../../common/Header";
import Sidebarmenu from "../../home/SideBar";

function ManualRefund() {
  const [state, setState] = useState({});
  const [roomsList, setRoomsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [request, setRequest] = useState(
    location.state !== null &&
      location.state.req &&
      location.state.req !== undefined
      ? location.state.req
      : ""
  );

  useEffect(() => {
    if (
      //   props.location.state !== undefined &&
      request !== undefined
    ) {
      if (request.refundprice.length !== 0) {
        let index = 1;
        let roomsList = request.refundprice;
        for (let room of roomsList) {
          room.rno = index;
          index = index + 1;
        }
        setRoomsList(roomsList);
      }
    }
  }, [request]);

  const handleChangePrice = (type, idx) => (e) => {
    const { id, value } = e.target;
    let newRoomList = roomsList.map((room, sidx) => {
      if (idx !== sidx) return room;
      else if (type === "CNT") {
        return {
          ...room,
          prices: {
            ...room.prices,
            [id]: value,
          },
        };
      } 
    //   else {
    //     return {
    //       ...room,
    //       taprice: {
    //         ...room.taprice,
    //         [id]: value,
    //       },
    //     };
    //   }
    });
    for (let room of newRoomList) {
      if (Number(room.rno) === Number(idx + 1)) {
        if (type === "CNT") {
          room.prices.roomprice=Number(room.prices.bf) + Number(room.prices.taxes) + Number(room.prices.bkmarkup) + Number(room.prices.bkservice) - Number(room.prices.canservice) - Number(room.prices.expediacharges) - Number(room.prices.canmarkup) - Number(room.prices.mtadisc) - Number(room.prices.com_disc);
        if(id==="expediacharges" && room.prices.expediacharges!=="" && room.prices.expediacharges!==0){
          room.prices.reffrmsup=Number(room.prices.bf) + Number(room.prices.taxes) - Number(room.prices.expediacharges);
        }
        } 
      }
    }
    setRoomsList(newRoomList);
  };

  const cancelRefund = () => {
    return (window.location = "/crm/hotel/summary/" + request.booking_id);
  };

  const totalPrice = (data, type) => {
    let total = 0;
    if (data.length !== 0) {
      if (type === "CNT") {
        for (let val of data) {
          if (
            val.prices?.roomprice !== "" &&
            val.prices?.roomprice !== undefined
          ) {
            if (total === 0) {
              total = Utilities.addPrice(
                Number(val.prices?.roomprice),
                0,
                "Add"
              );
            } else {
              total = Utilities.addPrice(
                total,
                Number(val.prices?.roomprice),
                "Add"
              );
            }
          }
        }
      }
    }
    return total;
  };

  const totalRefundPrice = (data, type) => {
    let total = 0;
    if (data.length !== 0) {
      if (type === "CNT") {
        for (let val of data) {
          if (
            val.prices?.reffrmsup !== "" &&
            val.prices?.reffrmsup !== undefined
          ) {
            if (total === 0) {
              total = Utilities.addPrice(
                Number(val.prices?.reffrmsup),
                0,
                "Add"
              );
            } else {
              total = Utilities.addPrice(
                total,
                Number(val.prices?.reffrmsup),
                "Add"
              );
            }
          }
        }
      }
    }
    return total;
  };

  //Proceess Refund flow
  const procesRefundFlow = () => {
    const payload = {
      st: request.st,
      isRoomCancel: false,
      upby: 1,
      //   butype: props.location.state.req.butype,
      cid: request.cid,
      pcid: request.pcid,
      booking_id: request.booking_id,
      hid: request.hid,
      rooms: roomsList,
    };
    setEnableLoader(true);
    console.log("payloadddddd", payload)
    processHotelRefund(payload).then((resp) => {
      if (resp.suc) {
        setNotiMessageShow(true);
        setNotiMessage("Refund processed successfully...");
        setNotiVarient("success");
        setTimeout(() => {
          navigate("/crm/hotel/summary/" + request.booking_id, {});
        }, 2000);
      } else {
        setEnableLoader(false);
        setNotiMessageShow(true);
        setNotiMessage("Something went wrong please try again later");
        setNotiVarient("danger");
      }
    });
  };

  const handlePopup = () => {
    setShowPopup(true);
  };

  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu />
          </Col>
          <Col xs={10}  className="mainftr">
            <div className="confirmation refundRecord">
              {notiMessageShow && (
                <Alert
                  className="notification alert"
                  variant={notiVarient}
                  show={notiMessageShow}
                  onClose={() => setNotiMessageShow(false)}
                  dismissible
                >
                  {" "}
                  {notiMessage}
                </Alert>
              )}
              {/* <div className="topSec summarytopSec">
          <Button
            variant="li"
            className="backbtn ezyIcon icon-rightArrow"
            onClick={cancelRefund}
          >
            Back
          </Button>
        </div> */}

              {roomsList.length !== 0 &&
                roomsList.map((room, idx) => (
                  <React.Fragment key={idx}>
                    <div className="mannualRefundH recordInfo  pb-0">
                      <h5 className="title d-flex align-items-center mt-0">
                        Refund Details({request.cur})
                      </h5>

                      <h5 className="layoutTitle title d-flex justify-content-between mt-3">
                        <span className="d-flex">
                          <span className="ezyIcon icon-refund mr-1"></span>Room{" "}
                          {idx + 1} : {room.rnm}
                        </span>{" "}
                        <span>Supplier Ref No : {room.htlconfno}</span>
                      </h5>
                      <div className="cardMainlayout pb-0">
                        <div className="prDetails">
                          <h5 className="layoutTitle  d-flex justify-content-between">
                            <span>Price Details({request.cur})</span>
                          </h5>
                          <div className="table-responsive">
                            <table class="m-0 table passDetails">
                              <tr>
                                <th>Company Name</th>
                                <th>Base Fare</th>
                                <th>Taxes</th>
                                <th>Markup</th>
                                <th>Service Fee</th>
                                {/* <th>Supplier Fee</th> */}
                                {/* <th>Onfly Markup</th> */}
                                <th className="canclInfo">
                                  <h5>Cancellation</h5>Discount
                                </th>
                                <th className="canclInfo">Supplier Charges</th>
                                <th className="canclInfo">Refund Markup</th>
                                <th className="canclInfo">Refund Fee</th>
                                {/* <th className="canclInfo">
                                  Commission Discount
                                </th> */}
                                <th width="10%">Total Refund</th>
                              </tr>
                              <tr>
                                <td>
                                  <span>CNT </span>
                                </td>
                                <td>
                                  <span>{room.prices?.bf.toFixed(2)}</span>
                                </td>
                                <td>
                                  <span>{room.prices?.taxes.toFixed(2)}</span>
                                </td>
                                <td>
                                  <span>
                                    {room.prices?.bkmarkup.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {room.prices?.bkservice.toFixed(2)}
                                  </span>
                                </td>
                                {/* <td>
                                  <span>
                                    {room.mtaprice?.supfee.toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {room.mtaprice?.onflymkp.toFixed(2)}
                                  </span>
                                </td> */}
                                <td className="canclInfo">
                                  <Form.Group as={Col} controlId="mtadisc">
                                    <Form.Control
                                      type="number"
                                      disabled={true}
                                      min={0}
                                      placeholder="0"
                                      autoComplete="off"
                                      value={room.prices?.mtadisc}
                                    />
                                  </Form.Group>
                                </td>
                                <td className="canclInfo">
                                  <Form.Group
                                    as={Col}
                                    controlId="expediacharges"
                                  >
                                    <Form.Control
                                      type="number"
                                      min={0}
                                      placeholder="Supplier charges"
                                      autoComplete="off"
                                      value={room.prices?.expediacharges}
                                      onChange={handleChangePrice("CNT", idx)}
                                    />
                                  </Form.Group>
                                </td>
                                <td className="canclInfo">
                                  <Form.Group as={Col} controlId="canmarkup">
                                    <Form.Control
                                      type="number"
                                      min={0}
                                      placeholder="Refund Markup"
                                      autoComplete="off"
                                      value={room.prices?.canmarkup}
                                      onChange={handleChangePrice("CNT", idx)}
                                    />
                                  </Form.Group>
                                </td>

                                <td className="canclInfo">
                                  <Form.Group as={Col} controlId="canservice">
                                    <Form.Control
                                      type="number"
                                      min={0}
                                      placeholder="Refund Service"
                                      autoComplete="off"
                                      value={room.pricesv?.canservice}
                                      onChange={handleChangePrice("CNT", idx)}
                                    />
                                  </Form.Group>
                                </td>
                                {/* <td className="canclInfo">
                                  <Form.Group as={Col} controlId="com_disc">
                                    <Form.Control
                                      type="number"
                                      disabled={true}
                                      min={0}
                                      placeholder="Commission Disc"
                                      autoComplete="off"
                                      value={room.mtaprice?.com_disc}
                                    />
                                  </Form.Group>
                                </td> */}
                                <td>
                                  <span>
                                    {room.prices?.roomprice.toFixed(2)}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

              <div className="ticketSummerynew newTicketal  d-flex">
                <Col xs={6} className="cardMainlayout mr-2">
                  <h5 className="layoutTitle  d-flex justify-content-between">
                    <span className="panelTitle ezyIcon icon-document">
                      Refund Summary Supplier
                    </span>
                  </h5>
                  <div className="table-responsive">
                    <table class="m-0 table">
                      <tr>
                        <th width="50%" align="left">
                          Room Type
                        </th>
                        <th width="50%" align="left">
                          Refund from Supplier
                        </th>
                        {/* <th width="50%" align="right" class="text-right">
                          Refund to TA
                        </th> */}
                      </tr>

                      {roomsList.length !== 0 &&
                        roomsList.map((room, idx) => (
                          <tr>
                            <td width="50%" align="left">
                              {room.rnm}
                            </td>
                            <td width="50%" align="left">
                              {request.cur}{" "}
                              {room.prices?.reffrmsup.toFixed(2)}
                            </td>
                            {/* <td width="50%" align="right" class="text-right">
                              {request.cur}{" "}
                              {room.prices?.roomprice.toFixed(2)}
                            </td> */}
                          </tr>
                        ))}
                      <tr>
                        <td colSpan="3">
                          <div className="d-flex">
                            <div className="leftBox">
                              <span>
                                <strong>
                                  Total : {request.cur}{" "}
                                  {
                                    <FormateCurrency
                                      value={totalRefundPrice(roomsList, "CNT")}
                                    />
                                  }
                                </strong>
                              </span>
                            </div>
                            {/* <div className="rightBox">
                              <span>
                                <strong>
                                  {request.cur}{" "}
                                  {
                                    <FormateCurrency
                                      value={totalPrice(roomsList, "CNT")}
                                    />
                                  }
                                </strong>
                              </span>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
                <Col xs={6} className="cardMainlayout mr-2">
                  <h5 className="layoutTitle  d-flex justify-content-between">
                    <span className="panelTitle ezyIcon icon-document">
                      Refund Summary Customer
                    </span>
                  </h5>
                  <div className="table-responsive">
                    <table class="m-0 table">
                      <tr>
                        <th width="50%" align="left">
                          Room Type
                        </th>
                        {/* <th width="50%" align="left">
                          Refund from Supplier
                        </th> */}
                        <th width="50%" align="right" class="text-right">
                          Refund to Customer
                        </th>
                      </tr>

                      {roomsList.length !== 0 &&
                        roomsList.map((room, idx) => (
                          <tr>
                            <td width="50%" align="left">
                              {room.rnm}
                            </td>
                            {/* <td width="50%" align="left">
                              {request.cur}{" "}
                              {room.prices?.roomprice.toFixed(2)}
                            </td> */}
                            <td width="50%" align="right" class="text-right">
                              {request.cur} {room.prices?.roomprice.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td colSpan="3">
                          <div className="d-flex">
                            {/* <div className="leftBox">
                              <span>
                                <strong>
                                  Total : {request.cur}{" "}
                                  {
                                    <FormateCurrency
                                      value={totalPrice(roomsList, "CNT")}
                                    />
                                  }
                                </strong>
                              </span>
                            </div> */}
                            <div className="rightBox">
                              <span>
                                <strong>
                                  {request.cur}{" "}
                                  {
                                    <FormateCurrency
                                      value={totalPrice(roomsList, "CNT")}
                                    />
                                  }
                                </strong>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </div>
              <div className="text-right buttonGrop mt-4">
                <Button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  onClick={cancelRefund}
                >
                  {" "}
                  Cancel Refund
                </Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={handlePopup}
                >
                  {" "}
                  Process Refund
                </Button>
              </div>

              <Modal
                show={showPopup}
                onHide={() => setShowPopup(false)}
                className="importPNRMdl requiredFare"
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header>
                  <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <div>
                      <strong>
                        Are you sure, you want to process the refund?
                      </strong>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Footer className="justify-content-center">
                  {enableLoader ? (
                    ""
                  ) : (
                    <Button
                      variant="outline-dark"
                      onClick={() => setShowPopup(false)}
                    >
                      Close
                    </Button>
                  )}
                  <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                    onClick={procesRefundFlow}
                  >
                    {" "}
                    {!enableLoader ? "Yes" : "Processing..."}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ManualRefund;
