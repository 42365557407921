/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-18 00:16:44
 * @ Description: This is to fetch the Airport Object with the help of airport code
 */

import React, { useState } from "react";
/**
 * For fetching the airport data with the conditions and code sent in the props
 */
function AirportMemo(props) {
  const [mapping, setMapping] = useState(props.mapping);
  const airportInfo = mapping.filter((airport) => {
    return airport.airportCode === props.code;
  });
  props.seg === "sege" && console.log("aiprortNameInfo", props, airportInfo);
  if (props.type === "filters") {
    if (airportInfo[0] && airportInfo[0].cityName !== "")
      return (
        <>
          <span>
            {props.code}, {airportInfo[0].cityName}{" "}
          </span>
        </>
      );
  } else if (
    props.type !== "Layover" &&
    props.type !== "Rule" &&
    props.type !== "City" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== "" &&
    props.field &&
    props.field === "ResultSectionHeader"
  ) {
    return (
      <>
      {airportInfo[0].cityName && airportInfo[0].cityName!==undefined &&
        <span>{airportInfo[0].cityName} {" "}</span>
      }
      {airportInfo[0].airportName=="London All" &&
        <span>London {" "}</span>
      } 
      </>
    );
  } else if (
    props.type !== "Layover" &&
    props.type !== "Rule" &&
    props.type !== "City" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== "" &&
    props.field &&
    props.field === "ResultSegmentSection"
  ) {
    return (
      <>
        <span>{airportInfo[0].cityName}</span>
      </>
    );
  } else if (
    props.type !== "Layover" &&
    props.type !== "Rule" &&
    props.type !== "City" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== "" &&
    props.field &&
    props.field === "ResultDetails"
  ) {
    return (
      <>
        <span>{airportInfo[0].airportName}</span>
      </>
    );
  } else if (
    props.type !== "Layover" &&
    props.type !== "Rule" &&
    props.type !== "City" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== ""
  ) {
    return (
      <>
        <span> {airportInfo[0].cityName}</span>
        <span>{airportInfo[0].airportName}</span>{" "}
      </>
    );
  } else if (
    props.type === "Layover" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== ""
  ) {
    return (
      <>
        <span>
          {airportInfo[0].cityName}, {airportInfo[0].airportName}{" "}
        </span>
      </>
    );
  } else if (
    props.type === "Rule" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== ""
  ) {
    return (
      <>
        <span>
          {airportInfo[0].airportName},{airportInfo[0].cityName},
          {airportInfo[0].countryName}{" "}
        </span>
      </>
    );
  } else if (
    props.type === "City" &&
    airportInfo[0] &&
    airportInfo[0].cityName !== ""
  ) {
    return (
      <>
        <span>{airportInfo[0].cityName}</span>
      </>
    );
  } else {
    return "";
  }
}

export default React.memo(AirportMemo);
