import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Col, Row, Container, Form, Dropdown, DropdownButton } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import CommonQueueSearch from './CommonQueueSearch';
import { Context } from "../../../App";
import Sidebarmenu from "../../home/SideBar";
import FlightDetailsQueuePopup from './FlightDetailsQueuePopup';
import RemarksPopup from './RemarksPopup';
import log from "loglevel";
import { flightSearch, hotelSearch, fetchFlightBookingInfo, fetchHotelBookingInfo, loadCaseHistory } from './operations';
import Constants from '../../utilities/Constants';
import Select from 'react-dropdown-select';
import HistoryPopup from './HistoryPopup';
import HotelDetailsQueuePopup from './HotelDetailsQueuePopup';
import { useNavigate } from 'react-router-dom';

/**
 * @description: Queues List
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */

const initialState = {
  booking_id: "", bid:undefined, fmdate:undefined,
  todate:undefined, channel_type:undefined, itinerary: undefined, hotelItinerary: undefined,
  status_info: "", history_data:[], flights: false, hotels: false, productType: "",fetch_bid:"",
  queueProductType:"", tkt_num_history:""
};

const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function QueuesList(props) {
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [queues_resp, setQueue_Resp] = useState([]);
  const [filter_queues_resp, setFilterQueue_Resp] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [error, setErroMsg] = useState("");
  const [showItinerary, setShowItinerary] = useState(false);
  const [showHotelItinerary, setShowHotelItinerary] = useState(false);
  const [show, setShow] = useState(false);
  const [status_update_popup, setStatusUpdatePopup] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [isLoad, setIsLoad] = useState(false);
  const [isBtnLoad, setIsBtnLoad] = useState(false);

  const SelectOptions = (context?.logindata?.roles?.includes('Q_U_F') && context?.logindata?.roles?.includes('Q_U_H')) ? [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" }
  ] : context?.logindata?.roles?.includes('Q_U_F') ? [
    { label: "Flight", value: "Flight" }
  ] : context?.logindata?.roles?.includes('Q_U_H') && [
    { label: "Hotel", value: "Hotel" }
  ]

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setErroMsg("");
    if(state?.productType === "Flight"){
      dispatch({ type: "flights", payload: true });
      dispatch({ type: "hotels", payload: false });
      dispatch({ type: "queueProductType", payload: "Flight" });
    }else if(state?.productType === "Flight"){
      dispatch({ type: "flights", payload: false });
      dispatch({ type: "hotels", payload: true });
      dispatch({ type: "queueProductType", payload: "Hotel" });
    }
    dispatch({ type: "fetch_bid", payload: "" });
    setShow(true);
  }

  useEffect(() => {
    if(context?.logindata?.roles?.includes('Q_U_F')){
      dispatch({ type: "flights", payload: true });
      dispatch({ type: "hotels", payload: false });
      dispatch({ type: "productType", payload: "Flight" });
      searchQueueCriteria({}, "Flight");
    }else if(context?.logindata?.roles?.includes('Q_U_H')){
      dispatch({ type: "flights", payload: false });
      dispatch({ type: "hotels", payload: true });
      dispatch({ type: "productType", payload: "Hotel" });
      searchQueueCriteria({}, "Hotel");
    }
  }, [context?.logindata])

  /**
   * Dropdowns for channel types
   * @param {*} cell 
   * @param {*} row 
   * @returns 
   */
  const getDropdowns = (cell, row) => {
    return (
      <>
        <Form.Row className="selectplugin" controlId="channel_type">
          <Select
            options={Constants.issue_status_dropdown}
            values={getSelStatus(row?.issue_st)}
            onChange={(value) => (updateCellData(value, cell, row))}
          />
        </Form.Row>
      </>
    )
  }

  const getHistoryButton = (cell, row) => {
    return (
      <>
        <div className="selectplugin historYsec text-center" controlId="channel_type">
          <Button
            onClick={(e) => loadHistory(row)} className='histoRy'>
            View
          </Button>
        </div>
      </>
    )
  }

  const getSelStatus = (status) => {
    let data = Constants.issue_status_dropdown.find(itm => itm.value + "" === status + "");
    if (data) {
      return [data]
    } else {
      return []
    }
  }

  const searchQueueCriteriaContrct = (data) =>{
      dispatch({ type: "booking_id", payload: data?.bid });
      dispatch({ type: "channel_type", payload: data?.channel_type });
      state.productType= data.product 
      dispatch({ type: 'case_number', payload: data.case_number })
      dispatch({ type: 'case_status', payload: data.case_status })
      dispatch({ type: 'issue_status', payload: data.issue_status })
      dispatch({ type: "fmdate", payload: data?.fmdate });
      dispatch({ type: "todate", payload: data?.todate });
      searchQueueCriteria(data, state?.productType)
  }

 
/**
 * @description: Advanced Queues List
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */
  const searchQueueCriteria = (data, product) => {
    setIsLoad(true);
    setQueue_Resp([]);
    setFilterQueue_Resp([]);
    const payload = {
      region_type: context?.logindata?.region_type,
      channel_type: data?.channel_type,
      case_number: data?.case_number,
      case_status: data?.case_status,
      issue_status: data?.issue_status,
      fmdate: data?.fmdate || undefined,
      todate: data?.todate || undefined,
      user_id: context?.logindata?.user_id,
      user_type: "CRM_USER",
      cid: context?.logindata?.cmp_id || undefined,
      bid: data?.bid || undefined,
      is_admin: context?.logindata?.is_admin ? 1:0,
    }
    if(context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined) {
      if(context.logindata.roles?.includes('Q_U_F')) {
        if (product && product === "Flight") {
          flightSearch(payload).then(response => {
            if (response?.suc) {
              loadFlightQueues(response?.res);
              setIsLoad(false);
            }
            setIsLoad(false);
          }).catch(function (error) {
            setIsLoad(false);
            log.error(
              "Exception occured in searchBooking function---" + error
            );
          });
        } 
      } 
    }
    if(context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined) {
      if(context.logindata.roles?.includes('Q_U_H')) {
        if (product && product === "Hotel") {
          hotelSearch(payload).then(response => {
            if (response?.suc) {
              loadHotelQueues(response?.res);
              setIsLoad(false);
            }
            setIsLoad(false);
          }).catch(function (error) {
            setIsLoad(false);
            log.error(
              "Exception occured in searchBooking function---" + error
            );
          });
        }
      }
    }
  }

  const loadFlightQueues = (response) => {
    setQueue_Resp([])
    setFilterQueue_Resp([]);
    let data = [];
    for (let val of response) {
      let paxStatus = Constants.pax_status_dropdown.filter(itm => itm.value && val.pax_status).find(x => x.value + "" === val.pax_status + "");
      data.push({ service: val.product, case_number: val.case_number, bk_id: val.bid, pax_status: paxStatus && paxStatus.label ? paxStatus.label : "", tkt_issue_number:val.tkt_issue_number, issue_st: val.issue_status, history: <><a onClick={(e) => loadHistory(e, val)}>History</a> </>, action: <><a onClick={(e) => loadSummary(e,val)}><span className="ezyIcon icon-View"></span></a> </> })
    }
    setQueue_Resp(data);
    setFilterQueue_Resp(data);
  }

  const loadHotelQueues = (response) => {
    setQueue_Resp([]);
    setFilterQueue_Resp([]);
    let data = [];
    for (let val of response) {
      let paxStatus = Constants.pax_status_dropdown.filter(itm => itm.value && val.pax_status).find(x => x.value + "" === val.pax_status + "");
      data.push({ service: val.product, case_number: val.case_number, bk_id: val.bid, pax_status: paxStatus && paxStatus.label ? paxStatus.label : "", tkt_issue_number:val.tkt_issue_number, issue_st: val.issue_status, history: <><a onClick={(e) => loadHistory(e, val)}>History</a> </>, action: <><a onClick={(e) => loadSummary(e,val)}><span className="ezyIcon icon-View"></span></a> </> })
    }
    setQueue_Resp(data);
    setFilterQueue_Resp(data);
  }

  /**
    * Text field changes
    */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErroMsg("");
    dispatch({ type: name, payload: value });
  };

  const loadSummary = (e,data) => {
    if(data?.bid && data?.product === "Hotel"){
      let url = "hotel/summary/"+data.bid;
      window.open(url);
    }else if(data?.bid && data?.product === "Flight"){
      let url = "flight/summary/"+data.bid;
      window.open(url);
    }
  }

  const loadFilteredQueuesList=(status)=>{
    if(queues_resp && queues_resp.length > 0){
       let finalResp = status ? queues_resp.filter((obj) => obj.pax_status && obj.pax_status === status) : queues_resp;
      setFilterQueue_Resp(finalResp)
    }else{
      setFilterQueue_Resp([]);
    }
  }

  const loadHistory = (row) => {
    let payload = {
      case_number: row.case_number
    }
    dispatch({ type: "tkt_num_history", payload: row?.tkt_issue_number || ""});
    loadCaseHistory(payload).then(response => {
      if (response?.suc) {
        dispatch({ type: "history_data", payload: response.res ?  response.res : []});
        setHistoryPopup(true);
      }else{
        dispatch({ type: "history_data", payload: []});
        setErroMsg("No history found");
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in searchBooking function---" + error
      );
    });

  }

  const getColor = (status) => {
    if (status) {
      if (status === "Cancel Request") {
        return "CancelRequest"
      } else if (status === "Reissue Request") {
        return "ReissueRequest"
      } else if (status === "Cancelled") {
        return "Cancelled"
      }
    }
  }

  const columns = [
    {
      dataField: 'case_number',
      text: 'Case Number',
      sort: true,
      filter: textFilter(),
      editable: false
    },
    {
      dataField: 'service',
      text: 'Service',
      sort: true,
      filter: textFilter(),
      editable: false,
      rowStyle: (row, rowIndex) => {
        return {
          color: '#FF0000'
        }
      }
    },
    {
      dataField: 'bk_id',
      text: 'Booking Ref',
      sort: true,
      filter: textFilter(),
      editable: false
    },
    {
      dataField: 'pax_status',
      text: 'Status',
      filter: textFilter(),
      editable: false,
      formatter: (cell, row) =>{
        return <span className={getColor(row?.pax_status)}>{row?.pax_status}</span>;
      }
    },
    {
      dataField: 'issue_st',
      text: 'Issue Status',
      formatter: (cell, row) => {
        return Constants.issue_status_dropdown.filter(itm => itm.value && cell).find(x => x.value + "" === cell + "")?.label;
      },
      formatter: (cell, row) => getDropdowns(cell, row),
    },
    {
      dataField: 'history',
      text: 'History',
      formatter: (cell, row) => getHistoryButton(cell, row),
    },
    {
      dataField: 'action',
      text: 'Action'
    }];

  const advanceSearchOpen = () => {
    if (advanceSearch) {
      setAdvanceSearch(false)
    } else {
      setAdvanceSearch(true)
    }
  }

  const fetchBookingInfo = async (e) => {
    if (state.fetch_bid === null || state.fetch_bid === "") {
      setErroMsg("Please provide booking id");
      return
    }
    if (state.queueProductType === null || state.queueProductType === "") {
      setErroMsg("Please provide product");
      return
    }
    let payload = {
      region_type: context?.logindata?.region_type,
      bid: state.fetch_bid,
      cid: context?.logindata?.cmp_id || undefined,
      uid: context?.logindata?.user_id || undefined,
      is_admin: context?.logindata?.is_admin ? 1:0
    }
    if (state.queueProductType === "Flight") {
      setIsBtnLoad(true);
      fetchFlightBookingInfo(payload).then(response => {
        if (response.suc) {
          dispatch({ type: "itinerary", payload: response });
          setShow(false);
          setShowItinerary(true);
          setIsBtnLoad(false);
        } else {
          dispatch({ type: "itinerary", payload: undefined });
          setErroMsg("No data found");
          setShowItinerary(false);
          setIsBtnLoad(false);
        }
      }).catch(function (error) {
        setIsBtnLoad(false);
        console.error(
          "Exception occured in fetchFlightBookingInfo function---" + error
        );
      });
    } else if (state.queueProductType === "Hotel") {
      setIsBtnLoad(true);
      fetchHotelBookingInfo(payload).then(response => {
        if (response.suc) {
          dispatch({ type: "hotelItinerary", payload: response });
          setShow(false);
          setShowHotelItinerary(true);
          setIsBtnLoad(false);
        } else {
          dispatch({ type: "hotelItinerary", payload: undefined });
          setErroMsg("No data found");
          setShowHotelItinerary(false);
          setIsBtnLoad(false);
        }
      }).catch(function (error) {
        setIsBtnLoad(false);
        console.error(
          "Exception occured in fetchFlightBookingInfo function---" + error
        );
      });
    } else{
      setIsBtnLoad(false);
    }
  }

  const closeItinerary = () => {
    setShowItinerary(false);
  };

  const closeHotelItinerary = () => {
    setShowHotelItinerary(false);
  };

  const closeUpdateStatus = () => {
    setStatusUpdatePopup(false);
  }

  const closeHistoryStatus = () => {
    setHistoryPopup(false);
  }

  const updateCellData = async (val, cell, row) => {
    let updatePayload = {
      ...row,
      issue_update_value: val[0].value //newValue
    }
    if (updatePayload.pax_status) {
      let paxInfo = Constants.pax_status_dropdown.filter(itm => itm.label === updatePayload.pax_status);
      if (paxInfo && paxInfo.length > 0) {
        updatePayload.pax_status = paxInfo[0].value;
      }
    }
    dispatch({ type: "status_info", payload: updatePayload });
    setStatusUpdatePopup(true);
  }

  const handleProductChange = (event) => {
    setErroMsg("");
    if (event.target.id === "Flight") {
      dispatch({ type: "queueProductType", payload: "Flight" });
      dispatch({ type: "flights", payload: true });
      dispatch({ type: "hotels", payload: false });
    } else if (event.target.id === "Hotel") {
      dispatch({ type: "queueProductType", payload: "Hotel" });
      dispatch({ type: "flights", payload: false });
      dispatch({ type: "hotels", payload: true });
    }
  };

  const productsChange = (value) => {
    let selected_value = value[0].value;
    dispatch({ type: "productType", payload: selected_value });
    let payload = {
      channel_type: state?.channel_type || undefined,
      bid: state?.booking_id || undefined,
      fmdate: state?.fmdate || undefined,
      todate: state?.todate || undefined,
    }
    searchQueueCriteria(payload, value[0].value);
  }

  const clearAdvancedSearchFields= () =>{
    dispatch({ type: "booking_id", payload: "" });
    dispatch({ type: "channel_type", payload: "" });
    dispatch({ type: "fmdate", payload: undefined });
    dispatch({ type: "todate", payload: undefined })
  }

  const defaultSorted = [{
    dataField: 'case_number',
    order: 'asc'
  }];

  const reloadQueueList = async (data) =>{
    let payload = {
      channel_type: state?.channel_type || undefined,
      bid: state?.booking_id || undefined,
      fmdate: state?.fmdate || undefined,
      todate: state?.todate || undefined,
    }
    searchQueueCriteria(payload, state.productType);
  }

  const reloadQueueListAfterCancelParent = async (data) =>{
    let payload = {
      channel_type: state?.channel_type || undefined,
      bid: state?.booking_id || undefined,
      fmdate: state?.fmdate || undefined,
      todate: state?.todate || undefined,
    }
    searchQueueCriteria(payload, data?.product);
  }
  


  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu active="Dashboard" />
          </Col>
          <Col xs={10}  className="mainftr">
            <Row>
              <Col className="bookingSearch queuesList">
                <h5 className="pageTitle d-flex justify-content-between justify-items-center">Search Booking
                  <Button
                    size="xs"
                    variant="primary"
                    type="submit"
                    className="mr-3 addtoQue"
                    onClick={handleShow}
                    > Add to Queue</Button>
                </h5>
                <div className="submenu">
                  <CommonQueueSearch searchQueueCriteria={searchQueueCriteriaContrct} clearAdvancedSearchFields= {clearAdvancedSearchFields} />
                </div>
              </Col>
            </Row>
            <div className='cardMainlayout bkngList mt-0 mb-0'>
            <div className="sortBookingList mt-3">
              <div className="d-flex requestWrap">
                <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "all" ? "btn-outline-dark btn active" :"btn-outline-dark btn"}
                  onClick={(e)=> {
                    setActiveTab("all");
                    loadFilteredQueuesList("")
                  }}
                ><span>All Bookings</span></button>
                <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "cancelreq" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
                  onClick={(e)=>{
                    setActiveTab("cancelreq");
                    loadFilteredQueuesList("Cancel Request")
                  }}
                ><span>Cancel Request</span> </button>
                <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "reissue" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
                  onClick={(e)=>{
                    setActiveTab("reissue");
                    loadFilteredQueuesList("Reissue Request")
                  }}
                ><span>Reissue Request</span></button>
                <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "cancelled" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
                  onClick={(e)=>{
                    setActiveTab("cancelled");
                    loadFilteredQueuesList("Cancelled")
                  }}
                ><span>Cancelled</span></button>
                 <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "On hold" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
                  onClick={(e)=>{
                    setActiveTab("On hold");
                    loadFilteredQueuesList("On hold")
                  }}
                ><span>On Hold</span> </button>
                <button
                  size="xs"
                  variant="outline-dark"
                  type="submit"
                  className= {activeTab && activeTab === "Failed" ? "btn-outline-dark btn ml-2 active" :"btn-outline-dark btn ml-2"}
                  onClick={(e)=>{
                    setActiveTab("Failed");
                    loadFilteredQueuesList("Failed")
                  }}
                ><span>Booking Failed</span> </button>
              </div>
              {context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined && (
                <>
                {(context.logindata.roles?.includes("Q_U_F") || context.logindata.roles?.includes("Q_U_H")) && (
                  <div className='ProdcutDrop'>
                    <Form.Group className="selectplugin sideBysidep m-0" as={Col} controlId="firstName">
                    <Form.Label>Products : </Form.Label>
                    <Select
                            options={SelectOptions}
                            searchable={false}
                            value={state.productType}
                            onChange={(value) => productsChange(value)}
                            placeholder="Select Product"
                            className="regionDrop"
                            classNamePrefix='filter'
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                              },
                            })}
                        />
                    </Form.Group>

                
                  </div>
                )}
                </>
              )}
            </div>
            <div className="cardMainlayout tableRes p-0 innerCardmain queuL">
                {isLoad ? <div className='loadding'><span class="loadernew"></span></div> :
              <div className="tablescroll">
                {filter_queues_resp && filter_queues_resp.length > 0 ?
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={filter_queues_resp}
                    classes="markup filterTbl queuesList"
                    columns={columns}
                    defaultSorted={defaultSorted}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  /> : <span className='norecordFnd'>No records found</span>
                }
              </div>
              
              }
           
           </div>

            </div>
        
           
            <Footer />
         
          </Col>
        </Row>
      
      </Container>
     
      <Modal show={show} onHide={handleClose} className='addtoQue'>
        <Modal.Header closeButton>
          <Modal.Title>Add to Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={e => fetchBookingInfo(e)}>
            <Form.Row className='m-0'>
            <div className="flightsHotels">
                {context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined && (
                  <>
                    {context.logindata.roles?.includes('A_T_Q_F') && (
                      <label>
                        <input
                          type="radio"
                          id="Flight"
                          checked={state.flights}
                          value={state.queueProductType}
                          onChange={handleProductChange}
                          custom
                          name="classes"
                        />
                        Flights
                      </label>)}
                  </>
                )}
                {context && context.logindata !== "" && context.logindata !== undefined && context.logindata.roles !== "" && context.logindata.roles !== undefined && (
                  <>
                    {context.logindata.roles?.includes('A_T_Q_H') && (
                      <label>
                        <input
                          type="radio"
                          id="Hotel"
                          checked={state.hotels}
                          value={state.queueProductType}
                          onChange={handleProductChange}
                          custom
                          name="classes"
                          className="ml-3"
                        />
                        Hotels
                      </label>
                    )}
                  </>
                )}
              </div>
            </Form.Row>
            <Form.Row className='m-0 mt-3 pt-3'>
              <Form.Group className="selectplugin" as={Col} controlId="fetch_bid">
                <Form.Label className='fetch_bid'>Booking Ref Number</Form.Label>
                <Form.Control type="text" className="username" placeholder="Enter Booking ID" name="fetch_bid" autoComplete="off"
                  value={state.fetch_bid} onChange={handleChange} />
           
              {error ?
                <small className="errorText">
                  {error}
                </small> : ""
              }
                 </Form.Group>

                 {!isBtnLoad?<Button variant="secondary" onClick={fetchBookingInfo}>
            Fetch {state.queueProductType}
          </Button>:
                <div class="progress progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Fetching..</span>
                </div>
                </div>
                }
            </Form.Row>
          </form>
        </Modal.Body>
       
      </Modal>
      {showItinerary &&
        <FlightDetailsQueuePopup close={closeItinerary} itiData={state?.itinerary?.res[0]} reloadQueueListAfterCancelParent = {reloadQueueListAfterCancelParent}/>
      }
      {showHotelItinerary &&
        <HotelDetailsQueuePopup close={closeHotelItinerary} itiData={state?.hotelItinerary?.res} reloadQueueListAfterCancelParent = {reloadQueueListAfterCancelParent} />
      }
      {status_update_popup &&
        <RemarksPopup close={closeUpdateStatus} status_data={state.status_info ? state.status_info : undefined} reloadQueueList= {reloadQueueList}/>
      }
      {historyPopup &&
        <HistoryPopup close={closeHistoryStatus} history_data={state.history_data ? state.history_data : []} tkt_num_history={state.tkt_num_history} />
      }
    
    </>
  )
}
export default QueuesList;