import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../App";
import Button from '../../common/Button';
import DateUtils from '../../common/DateUtils';
import ManageBookingService from '../../../services/ManageBookingService';
import { mobileNumberValidation, validateEmail } from "../../utilities/FormValidations";
let flightSuppliers = require('../../masterData/AllSuppliers.json');
// let bookingTypeOptions = require('../../masterData/BookingType.json');
let bookingStatusOptions = require('../../masterData/BookingStatus..json');
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;

/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 06-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
  booking_id:"",tkt_no:"",mobile_no:"",email_id:"",htl_conf_no:"",user_id:0,product:"Flight",notiMessage: '', notiMessageShow: false, notiVarient: '',region_type: "Qatar",
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state,booking_id:"",user_id:0,product:"",notiMessage: '', notiMessageShow: false, notiVarient: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function CommonSearch(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [channel_type, setChanelType] = useState([{ label: "CNT", value: 'CNT' }]);
  const [channel_temp, setChnelTemp] = useState([{ label: "CNT", value: 'CNT' }]);
  const channel_types = [{ label: "Select", value: '' },{ label: "CNT", value: 'CNT' }, { label: "WEGO", value: 'WEGO' },{ label: "SKYSCANNER", value: 'SKYSCANNER' }]
  const [officeIdList, setOfficeIdList] = useState([]);
  const [ofcId, setOfcId] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [bookingType, setBookingType] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [products , setProducts] = useState([{value:"Flight",label:"Flight"}]);
  const [product , setProduct] = useState("Flight");

  const productOptions = [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" },
  ];

  const bookingTypeOptions = [
    { label: "Direct", value: "DIRECT" },
    { label: "Manual", value: "MANUAL" },
    { label: "Reissue", value: "REISSUE" },
    { label: "Manual/Reissue", value: "MANUAL/REISSUE" },
  ];

  useEffect(() => {
    if(supplier.length!==0 && supplier[0].value !== "0") {
      setOfcId([]);
    }
  },[supplier])

  const hotelSuppliers = [];

  useEffect(() => {
    if (context.logindata?.region_type!==undefined) {
      dispatch({ type: "region_type", payload: "Qatar" })
    }
  }, [context.logindata])

  useEffect(() => {
      if(supplier.length!==0 && supplier[0].value == "0" && supplier[0].value !== "" && officeIdList.length===0){
          ManageBookingService.loadOfficeIdList({"region":state.region_type,"cid":FLIGHT_SEARCH_CID,"is_admin":context.logindata.is_admin}).then(response => {
              if(response.data.suc){
                  setOfficeIdList(response.data.res)
                }
            }).catch(function (error) {
              log.error(
                "Exception occured in loadOfficeIdList function---" + error
              );
            })
          }
    }, [context.logindata, supplier])
  

  const handleSearch = () => {
        if(validateSearch()){
        const payload = {
            "region_type":context.logindata!==undefined && context.logindata.region_type!==undefined?context.logindata.region_type:"",
            // "product":props.selproduct,
            "product":product,
            "booking_id":state.booking_id,
            "channel_type":channel_type.length!==0?channel_type[0].value:"",
            "fmdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
            "user_id":context.logindata!==undefined?context.logindata.uid:0,
            "user_type":"CRM_USER",
            "comp_ids":context.logindata!==undefined?context.logindata.cmp_id:0,
            "sup": supplier.length!==0?supplier[0].value:"",
            "bktype" : bookingType.length!==0?bookingType[0].value:"",
            "bkstatus" : bookingStatus.length!==0?bookingStatus[0].value:"",
            "phno" : state.mobile_no,
            "emailid" : state.email_id,
          }
          if(product === "Flight") {
            payload.ofcid = ofcId.length!==0?ofcId[0].label:"";
            payload.tkt_no = state.tkt_no
          } else if(product === "Hotel") {
            payload.htl_conf_no = state.htl_conf_no
          }
          props.sendSearchCreteria(payload);
        }
  }
  const validateSearch=()=>{
   if(channel_type==="" && fromDate==="" && toDate==="" && state.booking_id===""){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please select atleast one search criteria and proceed' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
   }else if(fromDate!=="" && toDate!=="" &&  fromDate===toDate){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'From date and To date cannot be same.' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
   }else if(state.email_id !== "" && !validateEmail(state.email_id)){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please enter valid email.' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
  }else if (state.tkt_no !== "" && state.tkt_no !== undefined && state.tkt_no.length !== 14) {
    dispatch({ type: "notiMessageShow", payload: true });
    dispatch({ type: "notiMessage", payload: "Please provide valid Ticket number and include hyfen after ticketing Code",});
    dispatch({ type: "notiVarient", payload: "danger" });
    hidemessage();
    return false;
  }else if(state.mobile_no !== "" && !mobileNumberValidation(state.mobile_no)){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please enter valid contact number.' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
  }
   return true;
  }
  const resetSearch = () => {
    dispatch({ type: 'booking_id', payload: "" })
    setFromDate("")
    setToDate("")
    // setChanelType("")
    setChnelTemp([])
    setOfcId([])
    setBookingStatus([])
    setBookingType([])
    setSupplier([])
    dispatch({ type: 'tkt_no', payload: "" })
    dispatch({ type: 'mobile_no', payload: "" })
    dispatch({ type: 'email_id', payload: "" })
    dispatch({ type: 'htl_conf_no', payload: "" })
    window.location="/crm/bookings";
  }
  const handleOnChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

 
  return (
    <>
      <div>
        {state.notiMessageShow &&
          <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }

        <div className='cardMainlayout'>
        <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="channel_type">
            <Form.Label>Channel Type</Form.Label>
              <Select
                options={channel_types}
                searchable={false}
                values={channel_temp}
                disabled={true}
                className='disaBled'
                onChange={(value) => (setChanelType(value))}
              />
                </Form.Group>
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="booking_id">
                <Form.Label>Booking Ref Number</Form.Label>
                      <Form.Control type="text" value={state.booking_id} placeholder="Booking Ref Number"  
                      onChange={handleOnChange}/>
                </Form.Group>     
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="booking_type">
            <Form.Label>Product</Form.Label>
              <Select
                options={productOptions}
                searchable={false}
                values={products}
                placeholder="Select"
                onChange={(value) => {setProducts(value); setProduct(value[0].value)}}
                classNamePrefix='filter'
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#3156ab',
                    cursor: 'pointer',
                  },
                })}
              />
                </Form.Group>     
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="supplier">
            <Form.Label>Supplier</Form.Label>
              <Select
                options={product === "Flight" ? flightSuppliers : hotelSuppliers}
                searchable={false}
                values={supplier}
                placeholder="Select"
                onChange={(value) => (setSupplier(value))}
                classNamePrefix='filter'
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#3156ab',
                    cursor: 'pointer',
                  },
                })}
              />
                </Form.Group>    
                {product === "Flight" && (supplier.length!==0 && supplier[0].value == "0") && officeIdList.length!==0 && (
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="ofcId">
            <Form.Label>Office Id</Form.Label>
              <Select
                options={officeIdList}
                searchable={false}
                values={ofcId}
                placeholder="Select"
                onChange={(value) => (setOfcId(value))}
                classNamePrefix='filter'
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#3156ab',
                    cursor: 'pointer',
                  },
                })}
              />
                </Form.Group>   
                )}                        
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="booking_type">
            <Form.Label>Booking Type</Form.Label>
              <Select
                options={bookingTypeOptions}
                searchable={false}
                values={bookingType}
                placeholder="Select"
                onChange={(value) => (setBookingType(value))}
                classNamePrefix='filter'
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#3156ab',
                    cursor: 'pointer',
                  },
                })}
              />
                </Form.Group>   
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="booking_status">
            <Form.Label>Booking Status</Form.Label>
              <Select
                options={bookingStatusOptions}
                searchable={false}
                values={bookingStatus}
                placeholder="Select"
                onChange={(value) => (setBookingStatus(value))}
                classNamePrefix='filter'
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#3156ab',
                    cursor: 'pointer',
                  },
                })}
              />
                </Form.Group>
                {product === "Flight" && (
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="tkt_no">
                <Form.Label>Ticket Number</Form.Label>
                      <Form.Control type="text" value={state.tkt_no} placeholder="Ticket Number"  
                      onChange={handleOnChange}/>
                </Form.Group>
                )}
                {product === "Hotel" && (
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="htl_conf_no">
                <Form.Label>Hotel Confirmation Number</Form.Label>
                      <Form.Control type="text" value={state.htl_conf_no} placeholder="Hotel Confirmation Number"  
                      onChange={handleOnChange}/>
                </Form.Group>
                )}
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="mobile_no">
                <Form.Label>Contact Number</Form.Label>
                      <Form.Control type="text" value={state.mobile_no} placeholder="Contact Number"  
                      onChange={handleOnChange}/>
                </Form.Group>
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="email_id">
                <Form.Label>Email</Form.Label>
                      <Form.Control type="text" value={state.email_id} placeholder="Email"  
                      onChange={handleOnChange}/>
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin datePicker mb-0" controlId="fromDate">
                <Form.Label>Booking From</Form.Label>
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}
                    fromDate={fromDate}
                    toDate={toDate}
                    placeholderText="MM/DD/YYYY"
                    maxDate={new Date()}
                    popperModifiers={{
                      flip: {
                          behavior: ['bottom'] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                
                </Form.Group>
              
                <Form.Group as={Col} xs={3} className="selectplugin datePicker datepicleft mb-4" controlId="toDate">
                <Form.Label>Booking To</Form.Label>
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate ? fromDate : null}
                    className="datefield"
                    placeholderText="MM/DD/YYYY"
                    popperModifiers={{
                      flip: {
                          behavior: ['bottom'] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                </Form.Group>   
          </Form.Row>
          <div className="text-right pb-0 buttonGrop  creat_absolute">
            <Button
              size="xs"
              variant="outline-secondary"
              className="mr-3"
              type="submit"
              onClick={resetSearch}
            > Reset</Button>
            <Button
              size="xs"
              variant="primary"
              type="submit"
              onClick={handleSearch}
            > Search</Button>
          </div>
            </div>
          </div>
    </>
  )
}
export default CommonSearch