import React, { Component, useState, useContext } from "react";
import { Nav, NavDropdown, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../App";

const SidebarMenuFun = (props) =>{
  const { state } = props || {}
  const [context, setContext] = useContext(Context);
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const closedTrigger = () => {
    document.querySelector(".slide-up").classList.toggle("slide-down");
    setActive(!active);
  };
  const openTrigger = () => {
    document.querySelector(".slide-upSub").classList.toggle("slide-downSub");
    setActive(!active);
  };
  function openNav() {
    if(document.querySelector(".sideBar ").style.left === "0px"){
      document.querySelector(".sideBar ").style.left = "-100%";
    }
    else{
      document.querySelector(".sideBar ").style.left = "0";
    }
    
  }
  return (
    <>
<div className="humberger" onClick={openNav}>
          <div className="humbergerIcon"></div>
        </div>
        <div className="sideBar">
        <ul>
        {context &&
            context.logindata !== "" &&
            context.logindata !== undefined &&
            context.logindata.roles !== "" &&
            context.logindata.roles !== undefined && (
              <>
                {context.logindata.roles.includes("D_B") && (
                  <li className={state.active==="Dashboard" ? 'sidebar-menu-nav active':'sidebar-menu-nav'}>
              <Link to="/crm/queues" title="Dashboard">
              <span className="zyIcon icon-Dashboard mr-2"></span>
                          Dashboard
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
              </li>
          
          )}
          </>
        )}
          {context &&
            context.logindata !== "" &&
            context.logindata !== undefined &&
            context.logindata.roles !== "" &&
            context.logindata.roles !== undefined && (
              <>
                {context.logindata.roles.includes("B_L") && (
                  <li className={state.active==="BookingList" ? 'sidebar-menu-nav active':'sidebar-menu-nav'}>
              <Link to="/crm/bookings" title="Booking List">
              <span className="zyIcon icon-Booking-List mr-2"></span>
                          Booking List
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
              </li>
          
          )}
          </>
        )}
        {context &&
            context.logindata !== "" &&
            context.logindata !== undefined &&
            context.logindata.roles !== "" &&
            context.logindata.roles !== undefined && (
              <>
                {context.logindata.roles.includes("M_L_B") && (
                  <>
                        {state.active==="Flight" || state.active==="Hotel" ?
                        (
                          <li className={active ? 'sidebar-dropdown active':'sidebar-dropdown'}>
              <Link to="#" title="Manage Booking" onClick={openTrigger}>
              <span className="zyIcon icon-Manage-Booking mr-2"></span>
                    Manage Booking
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
                          <div className="slide-upSub">
                        <div>
                          <div class="contents">
                          {context.logindata.roles.includes("M_L_B_F") && (
                        <div className={state.active==="Flight" ? 'sidebar-submenu-nav active':'sidebar-submenu-nav'}>
                          <Link
                                to="/crm/manage-flight"
                                title="Manage Booking"
                              >
                                <span className="zyIcon icon-Flight mr-2"></span>
                                Flights
                              </Link>
                              </div>
                               )}

                               {context.logindata.roles.includes("M_L_B_H") && (
                                                       <div className={state.active==="Hotel" ? 'sidebar-submenu-nav active':'sidebar-submenu-nav'}>
                                                        <Link
                                to="/crm/manage-hotel"
                                title="Manage Booking"
                              >
                                <span className="zyIcon icon-Hotel mr-2"></span>
                                Hotel
                              </Link>
                              </div>
                        )}
                </div>
              </div>
            </div>
            </li>
                        ):(
                          <li className={active ? 'sidebar-dropdown active':'sidebar-dropdown'}>
              <Link to="#" title="Manage Booking" onClick={closedTrigger}>
              <span className="zyIcon icon-Manage-Booking mr-2"></span>
                          Manage Booking
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
                          <div className="slide-up">
                        <div>
                          <div class="contents">
                          {context.logindata.roles.includes("M_L_B_F") && (
                        <div className={state.active==="Flight" ? 'sidebar-submenu-nav active':'sidebar-submenu-nav'}>
                          <Link
                                to="/crm/manage-flight"
                                title="Manage Booking"
                              >
                                 <span className="zyIcon icon-Flight mr-2"></span>
                                Flights
                              </Link>
                              </div>
                               )}

                               {context.logindata.roles.includes("M_L_B_H") && (
                                                       <div className={state.active==="Hotel" ? 'sidebar-submenu-nav active':'sidebar-submenu-nav'}>
                                                        <Link
                                to="/crm/manage-hotel"
                                title="Manage Booking"
                              >
                                  <span className="zyIcon icon-Hotel mr-2"></span>
                                Hotel
                              </Link>
                              </div>
                        )}
                </div>
              </div>
            </div>
            </li>
                        )
                      }
                        
                    
                    </>
                    )}
                    </>
                  )}

          <li className={state.active==="Alert" ? 'sidebar-menu-nav active':'sidebar-menu-nav'}>
              <Link to="#" title="Alert">
              <span className="zyIcon icon-Alerts mr-2"></span>
              Alert
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
              </li>
          {context &&
            context.logindata !== "" &&
            context.logindata !== undefined &&
            context.logindata.roles !== "" &&
            context.logindata.roles !== undefined && (
              <>
                {context.logindata.roles.includes("P_T_I") && (
                  <li className={state.active==="Payment" ? 'sidebar-menu-nav active':'sidebar-menu-nav'}>
              <Link to="/crm/payments" title="Booking List">
              <span className="zyIcon icon-Payment mr-2"></span>
                          Payments
                          <span className="zyIcon icon-down-arrow"></span>
                        </Link>
              </li>
          
          )}
          </>
        )}
      </ul>
        </div>
      
      
    </>
  );
}



class SideMenuBar extends Component {
  constructor(props) {
  super(props);
      console.log("propspropsprops",props)
      this.state = {
          active:props.active
      };
  }

  render() {
  return <SidebarMenuFun state={this.state} />
}
}
export default SideMenuBar;
