import CRMCommonService from '../../../services/CRMCommonService';

/**
 * @description: CRUD operations for Queues
 * @author: Praveen Varma
 * @param {*}
 * @function CRM
 * @date : 07-11-2023
 */
export const flightSearch = async (obj) => {
    let finalRes = [];
    await CRMCommonService.searchFlightQueues(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const hotelSearch = async (obj) => {
    let finalRes = [];
    await CRMCommonService.searchHotelQueues(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const fetchFlightBookingInfo = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.fetchFlightBookingInfo(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const fetchHotelBookingInfo = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.fetchHotelBookingInfo(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const updateFlightQueueStatus = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.updateFlightQueueStatus(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const updateHotelQueueStatus = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.updateHotelQueueStatus(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const loadCaseHistory = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.loadCaseHistory(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const cancelRoom = async (obj, token) =>{
    let finalRes = [];
    await CRMCommonService.cancelRoom(obj, token).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const cancelFlightTicket = async (obj, token) =>{
    let finalRes = [];
    await CRMCommonService.cancelFlightTicket(obj, token).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const fetchSummaryInfo = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.fetchSummaryInfo(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
// ========================================================================
//  Calling the send confirmation obj and email request in confirmation page
// ========================================================================
export const sendConfirmationObj = async (req) => {
    req.is_eticket = true
    req.is_invoice = true
    let finalRes = [];
    await CRMCommonService.sendConfirmationObj(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// fetchHistoryData
export const fetchHistoryData = async (req) => {
    let finalRes = '';
    await CRMCommonService.getHistory(req).then(response => {
        finalRes = response.data;
    })
    return Promise.resolve(finalRes);
}

//Manual refund Service
export const getRefundDetails = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.getRefundDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const processManualRefund = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.processManualRefund(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Refund Retrieve
export const showRefunds = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.showRefunds(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Cancel Price Service
export const checkCancelPrice = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.checkCancelPrice(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Cancel Price Service
export const processHotelRefund = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.processHotelRefund(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Edit Booking Hotel Service
export const processEditBooking = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.processEditBooking(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Edit Booking Flight Service
export const processFlightEditBooking = async (obj) =>{
    let finalRes = [];
    await CRMCommonService.processFlightEditBooking(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}