exports.issue_status_dropdown = [
    {
        label: "Inprogress",
        value: 46
    },
    {
        label: "Open",
        value: 52
    },
    {
        label: "Closed",
        value: 53
    }
]

exports.pax_status_dropdown =[
    {
      label:"Cancel Request",
      value: "50"
    },
    {
      label:"Reissue Request",
      value: "51"
    },
    {
      label:"Cancelled",
      value: "28"
    },
    {
      label:"On hold",
      value: "22"
    },
    {
      label:"Booking Failed",
      value: "25"
    },
    {   label: "Confirmed",
        value: "23"
    }
  ]

  exports.flight_itinerary_status =[
    {
      label:"Reissue Request",
      value: "reissue"
    },
    {
      label:"Cancel Request",
      value: "cancel"
    },
  ]
