/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-13 14:05:26
 * @ Description: Common footer for the application
 */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {Col,Row } from "react-bootstrap";
import { Context } from "../../App";


function Footer() {
  const [context, setContext] = useContext(Context);
  const [showSupport, setShowSupport] = useState(false);


  return (
    <>
      <div className="footer">
       
        <div className="bottomFooter">
          <Row>
            <Col span={24}>
              <div className="commonFlex">
                <div className="f-copyright">
                  <span>
                    {/* Copyright  */}
                    {context.translation && context.translation.copyRights}
                    </span> <span>Copyright © {new Date().getFullYear()} Click n Travel </span>
                </div>
              
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Footer;
