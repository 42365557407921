/**
 * @ Author: Praveen Varma
 * @ Create Time: 30-11-2023
 * @ Description: Component for formating the current to required format
 */

import React from "react";
import CurrencyFormat from "react-currency-format";

/**
 * For formatting the amount sent to a specific currency format
 */
function FormatCurrency(props) {

  const roundCurrency = (value) => {
    const decimalPart = value % 1; // Get the decimal part of the value

    if (decimalPart < 0.1) {
      // If decimal part is less than 0.1, round down
      return Math.floor(value);
    } else {
      // If decimal part is greater than or equal to 0.1, round up
      return Math.ceil(value);
    }
  }

  return (
    <>
      {props.value !== undefined && (
        <CurrencyFormat
          // value={roundCurrency(parseFloat(props.value))}
          value={parseFloat(props.value)}
          displayType={"text"}
          fixedDecimalScale={true}
          decimalScale={"2"}
          thousandSeparator={true}
          prefix={""}
          className={props.className}
        />
      )}
    </>
  );
}

export default FormatCurrency;
