import React, { useState, useEffect } from 'react';
import FormatCurrency from "../../utilities/FormatCurrency";

/**
 * @author Praveen Varma
 * @date 30-11-2023
 * @description Payment details summary data
 * @param {*} props 
 * @returns 
 */
function PaymentSummary({ payments, base_usr_exrate }) {
    return (
        <>
            <div className="heading mt-0">
               <h5 className='title mt-0 pt-0'>{"Payment Method"}</h5> 
            </div>      
            <div className="tableHeader PaymentMothod mrthHead row m-0">
                <div className="tableValue col-md">
                    {"Mode of Payment"}
                </div>
                <div className="tableValue col-md">
                    {"Card Type"}
                </div>
                <div className="tableValue col-md">
                    {"Card Details"}
                </div>
                <div className="tableValue col-md">
                    {"Amount Paid"}
                </div>
                <div className="tableValue col-md">
                {"Remarks"}
                </div>
            </div>
            {payments !== undefined && payments?.length > 0 &&
                payments.map((pay, index) => {
                    return (
                        <React.Fragment key={index}>     
                            <div className="tableData PaymentMothod row m-0">
                                <div className="tableValue col-md">
                                    {pay.payment_type ? pay.payment_type : "N/A"} {(pay.payment_type && (pay.payment_type == "Credit" || pay.payment_type == "CREDIT" || pay.payment_type == "Debit" || pay.payment_type == "DEBIT")) && "Card"}
                                </div>
                                <div className="tableValue col-md">
                                    {pay.card_type ? pay.card_type+" Card" : "N/A"} 
                                </div>
                                {pay.card_details ? (
                                    <div className="tableValue col-md">
                                        xxxxxxxxxxxx{pay.card_number}
                                    </div>
                                ) : (
                                    <div className="tableValue col-md">
                                        N/A
                                    </div>
                                )}
                                <div className="tableValue wordBreak col-md">
                                    {pay.pay_curr_code}  <FormatCurrency value={pay.pay_amount * (base_usr_exrate ? base_usr_exrate : 1)} className="currencyAmount" />
                                </div>
                                <div className="tableValue wordBreak col-md">
                                    {pay.payment_remarks ? pay.payment_remarks : "N/A"}
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })

            }
        </>
    )
}
export default PaymentSummary;