
import React, { useReducer, useState,useEffect } from 'react';
import { Col, Form,Alert,Row } from "react-bootstrap";
import Utilities from '../../utilities/Utilities';

/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Price details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
   notiMessage: '', isRegister: true, notiMessageShow: false, notiVarient: '', adtList: [], chdList: [], infList: [], passengers: [], adtbase: "", adttaxes: "", adtmarkup: "", adtsf: "", adtRetbase: "", adtRetTax: "", adtRetmarkup: "", adtRetsf: "", 
   adtbag: "", adtmeals: "", adttotal: 0,  adttotalamt: 0, adts: 0, adttotalamtta: 0, chdRetbase: "", chdRettaxes: "",
   adtdis: "", adtRetDisc: "", chdRetdis: "",  infdis: "", chddis: "", total_price: 0, adtinf: [], disable: false, adtBagTotal: "", adtMealTotal: "", adtSeatTotal: "", chdBagTotal: "", chdMealTotal: "", chdSeatTotal: "",
   chdbase:"",chdtaxes:"",chdmarkup: "", chdsf: "", chdbag: "", chdmeals: "", chdtotal: 0,chdtotalamt: 0, chds: 0,atd_sup_pricer:0,chd_sup_pricer:0,inf_sup_pricer:0,
   infbase: "", infRetbase: "", inftaxes: "", infRettaxes: "", infmarkup: "", infsf: "", infbag: "", infmeals: "", inftotal: 0, inftotalamt: 0, infs: 0,cur:"",chdCount:0,infCount:0,
   adtRettotal: 0, adtRettotalamt: 0, chdRettotal: 0, chdRettotalamt: 0, infRettotal: 0, infRettotalamt: 0, adtRetBagTotal: 0, chdRetBagTotal: 0 ,
   adtRetMealTotal: 0, adtRetSeatTotal: 0, chdRetMealTotal: 0, chdRetSeatTotal: 0, adtRetMkp: "", adtRetSf: "", chdRetmarkup: "", chdRetsf: "", chdRettaxes: "", infRettaxes: "", infRetmarkup: "", infRetsf: "", infRetdis: "",
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function FlightPriceInfo(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [enableAttPrice, setEnableAttPrice] = useState(false);
  const [enableAttLCCPrice, setEnableAttLCCPrice] = useState(false);
  const [ptype, setPType] = useState("");

      useEffect(()=>{
        if(props.paxFares!==undefined){
          updateFare(props.paxFares);
        // if(props.trip==="Onward" && props.onward_pax.length >0){
        //   let chdList = props.onward_pax.filter((pax)=>pax.ptype==="CHD");
        //   dispatch({ type: "chdCount", payload: chdList.length })
        //   let infList = props.onward_pax.filter((pax)=>pax.ptype==="INF");
        //   dispatch({ type: "infCount", payload: infList.length })
        // }
        }
      },[props.paxFares])

    useEffect(() => {
        if (enableAttPrice) {
          calculatePrice();
        }
      }, [enableAttPrice])

      useEffect(() => {
        if(enableAttLCCPrice){
          calculateLCCPrice();
        }
      }, [enableAttLCCPrice])

      useEffect(() => {
        calculatePrice();
      }, [state.adtBagTotal, state.adtMealTotal, state.adtSeatTotal, state.chdBagTotal, state.chdMealTotal, state.chdSeatTotal])

      useEffect(() => {
        dispatch({ type: "adts", payload: props.adt })
        dispatch({ type: "chds", payload: props.chd })
        dispatch({ type: "infs", payload: props.inf })
        dispatch({ type: "cur", payload: props.currency })
        
      }, [props])

      useEffect(() => {
        dispatch({ type: "adts", payload: props.adt })
        dispatch({ type: "chds", payload: props.chd })
        dispatch({ type: "infs", payload: props.inf })
        dispatch({ type: "cur", payload: props.currency })
        if(props.trip==="Onward" && props.onward_pax.length >0){
          let chdList = props.onward_pax.filter((pax)=>pax.ptype==="CHD");
          dispatch({ type: "chdCount", payload: chdList.length })
          let infList = props.onward_pax.filter((pax)=>pax.ptype==="INF");
          dispatch({ type: "infCount", payload: infList.length })
        }
      }, [props])

      useEffect(() => {
        if(props.sup==="1") {
          if(props.trip === "Onward") {
            dispatch({ type: "adtBagTotal", payload: props.paxFares?.adtBagTotal !== undefined ? Number(props.paxFares?.adtBagTotal) : 0 })
            dispatch({ type: "adtMealTotal", payload: props.paxFares?.adtMealTotal !== undefined ? Number(props.paxFares?.adtMealTotal) : 0 })
            dispatch({ type: "adtSeatTotal", payload: props.paxFares?.adtSeatTotal !== undefined ? Number(props.paxFares?.adtSeatTotal) : 0 })
            dispatch({ type: "chdBagTotal", payload: props.paxFares?.chdBagTotal !== undefined ? Number(props.paxFares?.chdBagTotal) : 0 })
            dispatch({ type: "chdMealTotal", payload: props.paxFares?.chdMealTotal !== undefined ? Number(props.paxFares?.chdMealTotal) : 0 })
            dispatch({ type: "chdSeatTotal", payload: props.paxFares?.chdSeatTotal !== undefined ? Number(props.paxFares?.chdSeatTotal) : 0 })
            if(props.ttype === "2") {
            dispatch({ type: "adtRetBagTotal", payload: props.paxFares?.adtRetBagTotal !== undefined ? Number(props.paxFares?.adtRetBagTotal) : 0 })
            dispatch({ type: "adtRetMealTotal", payload: props.paxFares?.adtRetMealTotal !== undefined ? Number(props.paxFares?.adtRetMealTotal) : 0 })
            dispatch({ type: "adtRetSeatTotal", payload: props.paxFares?.adtRetSeatTotal !== undefined ? Number(props.paxFares?.adtRetSeatTotal) : 0 })
            dispatch({ type: "chdRetBagTotal", payload: props.paxFares?.chdRetBagTotal !== undefined ? Number(props.paxFares?.chdRetBagTotal) : 0 })
            dispatch({ type: "chdRetMealTotal", payload: props.paxFares?.chdRetMealTotal !== undefined ? Number(props.paxFares?.chdRetMealTotal) : 0 })
            dispatch({ type: "chdRetSeatTotal", payload: props.paxFares?.chdRetSeatTotal !== undefined ? Number(props.paxFares?.chdRetSeatTotal) : 0 })
            }
          }
          if(props.trip === "Return") {
            dispatch({ type: "adtRetBagTotal", payload: props.paxFares?.adtRetBagTotal !== undefined ? Number(props.paxFares?.adtRetBagTotal) : 0 })
            dispatch({ type: "adtRetMealTotal", payload: props.paxFares?.adtRetMealTotal !== undefined ? Number(props.paxFares?.adtRetMealTotal) : 0 })
            dispatch({ type: "adtRetSeatTotal", payload: props.paxFares?.adtRetSeatTotal !== undefined ? Number(props.paxFares?.adtRetSeatTotal) : 0 })
            dispatch({ type: "chdRetBagTotal", payload: props.paxFares?.chdRetBagTotal !== undefined ? Number(props.paxFares?.chdRetBagTotal) : 0 })
            dispatch({ type: "chdRetMealTotal", payload: props.paxFares?.chdRetMealTotal !== undefined ? Number(props.paxFares?.chdRetMealTotal) : 0 })
            dispatch({ type: "chdRetSeatTotal", payload: props.paxFares?.chdRetSeatTotal !== undefined ? Number(props.paxFares?.chdRetSeatTotal) : 0 })
          }
        } 

      }, [props])

      const updateFare=(fareList)=>{
        if(props.trip==="Onward"){
          for (let price of fareList) {
            if (price.ptype == "ADT") {
              dispatch({ type: "adtbase", payload: price.bf })
              dispatch({ type: "adttaxes", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "adtsupCharge", payload: Number(price.tf) })
              dispatch({ type: "adttotal", payload: Number(price.tf) })
              dispatch({ type: "adttotalamt", payload: Number(price.tf) * Number(props.adt) })
            } else if (price.ptype == "CHD") {
              dispatch({ type: "chdbase", payload: price.bf })
              dispatch({ type: "chdtaxes", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "chdsupCharge", payload: Number(price.tf) })
              dispatch({ type: "chdtotal", payload: Number(price.tf) })
              dispatch({ type: "chdtotalamt", payload: Number(price.tf) * Number(props.chd) })
            } else if (price.ptype == "INF") {
              dispatch({ type: "infs", payload: Number(props.inf) })
              dispatch({ type: "infbase", payload: price.bf })
              dispatch({ type: "inftaxes", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "infsupCharge", payload: Number(price.tf) })
              dispatch({ type: "inftotal", payload: Number(price.tf) })
              dispatch({ type: "inftotalamt", payload: Number(price.tf) * Number(props.inf) })
            }
          }
        }
        if(props.trip==="Return"){
          for (let price of fareList) {
            if (price.ptype == "ADT") {
              dispatch({ type: "adtRetBf", payload: price.bf })
              dispatch({ type: "adtRetTax", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "adtRetsupCharge", payload: Number(price.tf) })
              dispatch({ type: "adtRettotal", payload: Number(price.tf) })
              dispatch({ type: "adtRettotalamt", payload: Number(price.tf) * Number(props.adt) })
            } else if (price.ptype == "CHD") {
              dispatch({ type: "chdRetbase", payload: price.bf })
              dispatch({ type: "chdRettaxes", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "chdRetsupCharge", payload: Number(price.tf) })
              dispatch({ type: "chdRettotal", payload: Number(price.tf) })
              dispatch({ type: "chdRettotalamt", payload: Number(price.tf) * Number(props.chd) })
            } else if (price.ptype == "INF") {
              dispatch({ type: "infs", payload: Number(props.inf) })
              dispatch({ type: "infRetbase", payload: price.bf })
              dispatch({ type: "infRettaxes", payload: Utilities.taxCalculation(price.tax) })
              dispatch({ type: "infRetsupCharge", payload: Number(price.tf) })
              dispatch({ type: "infRettotal", payload: Number(price.tf) })
              dispatch({ type: "infRetotalamt", payload: Number(price.tf) * Number(props.inf) })
            }
          }
        }
      }
    //Calculation ADT Price for ATT , TA AND STA
  const handleChangePrice = (paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setPType(paxType)
    setEnableAttPrice(true);
  }
  
  //Calculation ADT,CHD,INF Price
  const calculatePrice = () => {
    if (ptype === "ADT") {
      let supprice = Number(state.adtbase) + Number(state.adttaxes);
      let total = Number(supprice) + Number(state.adtmarkup) + Number(state.adtsf) - Number(state.adtdis);
      dispatch({ type: "atd_sup_pricer", payload: supprice })
      dispatch({ type: "adttotal", payload: total.toFixed(2) })
      dispatch({ type: "adttotalamt", payload: Number(state.adtBagTotal) + Number(state.adtMealTotal) + Number(state.adtSeatTotal) + (Number(total.toFixed(2)) * state.adts) })
    } else if (ptype === "CHD") {
      let suppChdrice = Number(state.chdbase) + Number(state.chdtaxes);
      let price = Number(suppChdrice) + Number(state.chdmarkup) + Number(state.chdsf) - Number(state.chddis);
      dispatch({ type: "chd_sup_pricer", payload: Number(suppChdrice) })
      dispatch({ type: "chdtotal", payload: price.toFixed(2) })
      dispatch({ type: "chdtotalamt", payload: Number(state.chdBagTotal) + Number(state.chdMealTotal) + Number(state.chdSeatTotal) + (Number(price.toFixed(2)) * state.chds) })
    } else if (ptype === "INF") {
      let supInfprice = Number(state.infbase) + Number(state.inftaxes);
      let total = Number(supInfprice) + Number(state.infmarkup) + Number(state.infsf) - Number(state.infdis)
      dispatch({ type: "inf_sup_pricer", payload: Number(supInfprice) })
      dispatch({ type: "inftotal", payload: total.toFixed(2) })
      dispatch({ type: "inftotalamt", payload: total.toFixed(2) * state.infs })
    }
    if(props.trip==="Onward"){
      props.sendOwPrice(state);
    }else if(props.trip==="Return"){
      props.sendRtPrice(state);
    }
    setEnableAttPrice(false);
  }

  

//Calculation ADT Price for ATT , TA AND STA
const handleChangeLCCPrice = (type) => (e) => {
  const { id, value } = e.target;
  dispatch({ type: id, payload: value });
  setPType(type)
  setEnableAttLCCPrice(true);
}
//Calculation ADT Price for ATT , TA AND STA
const calculateLCCPrice=()=>{
  if (ptype === "ADT") {
    let supAdtprice=Number(state.adtRetBf) + Number(state.adtRetTax);
    let price = Number(supAdtprice) + Number(state.adtRetMkp) + Number(state.adtRetSf);
    let total = Number(price) - Number(state.adtRetDisc)
    dispatch({type: "atd_ret_sup_pricer", payload: supAdtprice})
    dispatch({type: "adtRettotal", payload: total.toFixed(2)})
    dispatch({type: "adtRettotalamt", payload: Number(state.adtRetBagTotal) + Number(state.adtRetMealTotal) + Number(state.adtRetSeatTotal) + (Number(total.toFixed(2)) * state.adts)})
  } else if (ptype === "CHD") {
    let supChdprice = Number(state.chdRetbase) + Number(state.chdRettaxes);
    let price = Number(supChdprice) + Number(state.chdRetmarkup) + Number(state.chdRetsf) - Number(state.chdRetdis);
    dispatch({ type: "chd_ret_sup_pricer", payload: Number(supChdprice) })
    dispatch({ type: "chdRettotal", payload: price.toFixed(2) })
    dispatch({ type: "chdRettotalamt", payload: Number(state.chdRetBagTotal) + Number(state.chdRetMealTotal) + Number(state.chdRetSeatTotal) + (Number(price.toFixed(2)) * state.chds) })
  } else if (ptype === "INF") {
    let supInfprice = Number(state.infRetbase) + Number(state.infRettaxes);
    let total = Number(supInfprice) + Number(state.infRetmarkup) + Number(state.infRetsf) - Number(state.infRetdis)
    dispatch({ type: "inf_ret_sup_pricer", payload: Number(supInfprice) })
    dispatch({ type: "infRettotal", payload: total.toFixed(2) })
    dispatch({ type: "infRettotalamt", payload: total.toFixed(2) * state.infs })
  }
  if(props.trip==="Onward"){
    props.sendOwPrice(state);
  }else if(props.trip==="Return"){
    props.sendRtPrice(state);
  }
  setEnableAttLCCPrice(false);
}
    
    return (
        <>     
            <div className="pb-0">
            {state.notiMessageShow &&
               <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
               }
        
              <div className="BookingDetails buttonGrop mt-3">


            <h5 className="layoutTitle  d-flex justify-content-between mb-3 mt-3"><span>{props.jtype !== "Round Trip" ? props.trip : ""} Passenger Info</span></h5>
            <div className="passengerName buttonGrop">

             
                  {props.onward_pax!==undefined && props.onward_pax.length !== 0 && props.onward_pax.map((pax, idx) => (
                    <h6 className="layoutTitle passDetName  d-flex justify-content-start mb-3">
                    <React.Fragment key={idx}>
                      <span className="flex-column"><span className="passName mr-3">{pax.fn} {pax.ln}{"("+pax.ty+")"}</span></span></React.Fragment>
                      </h6>
                  ))}
                  
              <Row>
              <h6 className="layoutTitle passDetName  d-flex justify-content-start mb-3">
               {props.trip==="Onward" && props.onward_pax!==undefined &&
                <Col className="d-flex">
                  <span>{props.jtype !== "Round Trip" ? props.trip : ""} Price Details({props.currency})</span>
                
                </Col>
                }
                </h6>
                <div className='d-flex flex-column'>
                {props.trip==="Return" && props.return_pax!==undefined &&
                <Col className="d-flex mb-3">
                  {/* <span className="d-flex">{props.trip} Passengers : {props.return_pax.length} </span> */}
                  {props.return_pax.length !== 0 && props.return_pax.map((pax, idx) => (
                    <React.Fragment key={idx}>
                      <span className="flex-column"><span className="passName">{pax.fn} {pax.ln}{"("+pax.ty+"),"}</span> </span> </React.Fragment>
                  ))}
                </Col>
                }
                <h6 className="layoutTitle passDetName  d-flex justify-content-start mb-3">
                 {props.trip==="Return" && props.return_pax!==undefined &&
                  <Col className="d-flex">
                    <span>{props.trip} Price Details({props.currency})</span>
                  
                  </Col>
                  }
                  </h6>
                  </div>
              </Row>
            </div>
            <div className="table-responsives onWordPrcc">
            {props.sup==="1" && props.ttype==="2" &&  !props.isContentAgg && "Onward Fare dd"}
              <table class="m-0 table passDetails onWordPrc">
              {props.trip === "Onward" && 
              <>
                <tr>
                  <th width="25%">Pax Type</th>
                  <th width="15%">Base Fare<sup>*</sup></th>
                  <th width="10%">Taxes<sup>*</sup></th>
                  <th width="10%">Markup</th>
                  <th width="10%">Service Fee</th>
                  <th width="10%">Discount(-)</th>
                  <th width="10%">Sub Total</th>
                  <th width="10%">No. of Pax</th>
                  {props.sup==="1" &&
                    <>
                    <th width="10%">Baggage</th>
                    <th width="10%">Meals</th>
                    <th width="10%">Seat</th>
                    </>
                   }
                  <th width="10%">Total Amount</th>
                </tr>
                {props.adt > 0 &&
                <tr>
                  <td><span>ADT</span></td>
                  <td><Form.Group as={Col} controlId="adtbase">
                    <Form.Control type="number" min={0} placeholder="Base Fare*" autoComplete="off" disabled={state.disable} value={state.adtbase} onChange={handleChangePrice("ADT")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="adttaxes">
                    <Form.Control type="number" min={0} placeholder="Taxes" autoComplete="off" disabled={state.disable} value={state.adttaxes} onChange={handleChangePrice("ADT")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="adtmarkup">
                    <Form.Control type="number" min={0} placeholder="Markup" autoComplete="off" disabled={state.disable} value={state.adtmarkup} onChange={handleChangePrice("ADT")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="adtsf">
                    <Form.Control type="number" min={0} placeholder="Service Fee" autoComplete="off" disabled={state.disable} value={state.adtsf} onChange={handleChangePrice("ADT")} />
                  </Form.Group></td>
                   <td><Form.Group as={Col} controlId="adtdis">
                    <Form.Control type="number" min={0} placeholder="Discount" autoComplete="off" disabled={state.disable} value={state.adtdis} onChange={handleChangePrice("ADT")} />
                  </Form.Group></td>
                  <td><span>{state.adttotal}</span></td>
                  <td><span>{state.adts}</span></td>
                  
                  {props.sup==="1" &&
                    <>
                    <td><Form.Group as={Col}  controlId="adtBagTotal">
                    <Form.Control type="number"  min={0} autoComplete="off"  placeholder="Baggage Price" disabled={true} value={state.adtBagTotal} onChange={handleChangePrice("ADT")}/>
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="adtMealTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal Price" disabled={true} value={state.adtMealTotal} onChange={handleChangePrice("ADT")}/>
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="adtSeatTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat Price" disabled={true} value={state.adtSeatTotal} onChange={handleChangePrice("ADT")} />
                    </Form.Group></td>
                    </>
                    }
                  <td>{state.adttotalamt}</td>
                </tr>
               }
                {props.chd > 0 &&
                  <tr>
                  <td><span>CHD</span></td>
                  <td><Form.Group as={Col} controlId="chdbase">
                    <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.chdbase} onChange={handleChangePrice("CHD")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="chdtaxes">
                    <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.chdtaxes} onChange={handleChangePrice("CHD")}/>
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="chdmarkup">
                    <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.chdmarkup} onChange={handleChangePrice("CHD")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="chdsf">
                    <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.chdsf} onChange={handleChangePrice("CHD")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="chddis">
                    <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.chddis} onChange={handleChangePrice("CHD")} />
                  </Form.Group></td>
                  <td><span>{state.chdtotal}</span></td>
                  <td><span>{state.chds}</span></td>
                  {props.sup==="1" &&
                    <>
                    <td><Form.Group as={Col}  controlId="chdBagTotal">
                    <Form.Control type="number"  min={0} autoComplete="off"  placeholder="Baggage price" disabled={true} value={state.chdBagTotal} onChange={handleChangePrice("CHD")}/>
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="chdMealTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal price" disabled={true} value={state.chdMealTotal} onChange={handleChangePrice("CHD")}/>
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="chdSeatTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat price" disabled={true} value={state.chdSeatTotal} onChange={handleChangePrice("CHD")} />
                    </Form.Group></td>
                    </>
                    }
                  <td>{state.chdtotalamt}</td>
                </tr>
                }
                {props.inf > 0 &&
                <tr>
                  <td><span>INF</span></td>
                  <td><Form.Group as={Col} controlId="infbase">
                    <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.infbase} onChange={handleChangePrice("INF")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="inftaxes">
                    <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.inftaxes} onChange={handleChangePrice("INF")}  />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="infmarkup">
                    <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.infmarkup} onChange={handleChangePrice("INF")} />
                  </Form.Group></td>
                  <td><Form.Group as={Col} controlId="infsf">
                    <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.infsf} onChange={handleChangePrice("INF")} />
                  </Form.Group></td>
                     <td><Form.Group as={Col} controlId="infdis">
                    <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.infdis} onChange={handleChangePrice("INF")} />
                  </Form.Group></td>
                  <td><span>{state.inftotal}</span></td>
                  <td><span>{state.infs}</span></td>
                  {props.sup==="1" &&
                    <>
                    <td><Form.Group as={Col}  controlId="chdBagTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" disabled={true} />
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="chdMealTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" disabled={true} />
                    </Form.Group></td>
                    <td><Form.Group as={Col}  controlId="chdSeatTotal">
                    <Form.Control type="number"  min={0} autoComplete="off" disabled={true}  />
                    </Form.Group></td>
                    </>
                    }
                  <td>{state.inftotalamt}</td>
                </tr>
                 }
                 </>
                }
                 {props.sup==="1" && props.ttype==="2" && !props.isContentAgg &&
                    <>
                    {"Return Fare"}
                    <tr>
                            <td><span>ADT</span></td>
                            <td><Form.Group as={Col}  controlId="adtRetBf">
                            <Form.Control type="number" min={0}  placeholder="Base Fare" autoComplete="off" disabled={state.disable} value={state.adtRetBf} onChange={handleChangeLCCPrice("ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtRetTax">
                            <Form.Control type="number" min={0}  placeholder="Taxes" autoComplete="off" disabled={state.disable} value={state.adtRetTax} onChange={handleChangeLCCPrice("ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtRetMkp">
                            <Form.Control type="number" min={0} placeholder="Markup" autoComplete="off" disabled={state.disable} value={state.adtRetMkp} onChange={handleChangeLCCPrice("ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="adtRetSf">
                            <Form.Control type="number" min={0} placeholder="Service Fee" autoComplete="off" disabled={state.disable} value={state.adtRetSf} onChange={handleChangeLCCPrice("ADT")}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtRetDisc">
                            <Form.Control type="number" min={0} placeholder="Discount" autoComplete="off" disabled={state.disable} value={state.adtRetDisc} onChange={handleChangeLCCPrice("ADT")}/>
                        </Form.Group></td>
                            <td><span>{state.adtRettotal}</span></td>
                            <td><span>{state.adts}</span></td>
                             <td><Form.Group as={Col}  controlId="adtRetBagTotal">
                            <Form.Control type="number"  min={0} autoComplete="off" placeholder="Baggage price" disabled={true} value={state.adtRetBagTotal} onChange={handleChangeLCCPrice("ADT")} />
                            </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtRetMealTotal">
                            <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal price"  disabled={true} value={state.adtRetMealTotal} onChange={handleChangeLCCPrice("ADT")} />
                            </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtRetSeatTotal">
                            <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat price" disabled={true} value={state.adtRetSeatTotal} onChange={handleChangeLCCPrice("ADT")} />
                            </Form.Group></td>
                            <td>{state.adtRettotalamt}</td>
                        </tr>
                     {props.chd > 0 &&
                       <tr>
                       <td><span>CHD</span></td>
                       <td><Form.Group as={Col} controlId="chdRetbase">
                         <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.chdRetbase} onChange={handleChangeLCCPrice("CHD")} />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="chdRettaxes">
                         <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.chdRettaxes} onChange={handleChangeLCCPrice("CHD")}/>
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="chdRetmarkup">
                         <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.chdRetmarkup} onChange={handleChangeLCCPrice("CHD")} />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="chdRetsf">
                         <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.chdRetsf} onChange={handleChangeLCCPrice("CHD")} />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="chdRetdis">
                         <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.chdRetdis} onChange={handleChangeLCCPrice("CHD")} />
                       </Form.Group></td>
                       <td><span>{state.chdRettotal}</span></td>
                       <td><span>{state.chds}</span></td>
                         <td><Form.Group as={Col}  controlId="chdRetBagTotal">
                         <Form.Control type="number"  min={0} autoComplete="off"  placeholder="Baggage price" disabled={true} value={state.chdRetBagTotal} onChange={handleChangeLCCPrice("CHD")}/>
                         </Form.Group></td>
                         <td><Form.Group as={Col}  controlId="chdRetMealTotal">
                         <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal price" disabled={true} value={state.chdRetMealTotal} onChange={handleChangeLCCPrice("CHD")}/>
                         </Form.Group></td>
                         <td><Form.Group as={Col}  controlId="chdRetSeatTotal">
                         <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat price" disabled={true} value={state.chdRetSeatTotal} onChange={handleChangeLCCPrice("CHD")} />
                         </Form.Group></td>
                       <td>{state.chdRettotalamt}</td>
                     </tr>
                     }
                     {props.inf > 0 &&
                     <tr>
                       <td><span>INF</span></td>
                       <td><Form.Group as={Col} controlId="infRetbase">
                         <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.infRetbase} onChange={handleChangeLCCPrice("INF")} />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="infRettaxes">
                         <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.infRettaxes} onChange={handleChangeLCCPrice("INF")}  />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="infRetmarkup">
                         <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.infRetmarkup} onChange={handleChangeLCCPrice("INF")} />
                       </Form.Group></td>
                       <td><Form.Group as={Col} controlId="infRetsf">
                         <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.infRetsf} onChange={handleChangeLCCPrice("INF")} />
                       </Form.Group></td>
                          <td><Form.Group as={Col} controlId="infRetdis">
                         <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.infRetdis} onChange={handleChangeLCCPrice("INF")} />
                       </Form.Group></td>
                       <td><span>{state.infRettotal}</span></td>
                       <td><span>{state.infs}</span></td>
                         <td><Form.Group as={Col}  controlId="chdBagTotal">
                         <Form.Control type="number"  min={0} autoComplete="off"  placeholder="" disabled={true} />
                         </Form.Group></td>
                         <td><Form.Group as={Col}  controlId="chdMealTotal">
                         <Form.Control type="number"  min={0} autoComplete="off" placeholder="" disabled={true} />
                         </Form.Group></td>
                         <td><Form.Group as={Col}  controlId="chdSeatTotal">
                         <Form.Control type="number"  min={0} autoComplete="off" placeholder="" disabled={true}  />
                         </Form.Group></td>
                       <td>{state.infRettotalamt}</td>
                     </tr>
                      }
                      
                     </>
                     }
                     {props.isContentAgg && props.trip === "Return" &&
                        <>
                        <tr>
                          <th width="25%">Pax Type</th>
                          <th width="15%">Base Fare<sup>*</sup></th>
                          <th width="10%">Taxes<sup>*</sup></th>
                          <th width="10%">Markup</th>
                          <th width="10%">Service Fee</th>
                          <th width="10%">Discount(-)</th>
                          <th width="10%">Sub Total</th>
                          <th width="10%">No. of Pax</th>
                          {props.sup==="1" &&
                            <>
                            <th width="10%">Baggage</th>
                            <th width="10%">Meals</th>
                            <th width="10%">Seat</th>
                            </>
                           }
                          <th width="10%">Total Amount</th>
                        </tr>
                        <tr>
                                <td><span>ADT</span></td>
                                <td><Form.Group as={Col}  controlId="adtRetBf">
                                <Form.Control type="number" min={0}  placeholder="Base Fare" autoComplete="off" disabled={state.disable} value={state.adtRetBf} onChange={handleChangeLCCPrice("ADT")}/>
                            </Form.Group></td>
                                <td><Form.Group as={Col}  controlId="adtRetTax">
                                <Form.Control type="number" min={0}  placeholder="Taxes" autoComplete="off" disabled={state.disable} value={state.adtRetTax} onChange={handleChangeLCCPrice("ADT")}/>
                            </Form.Group></td>
                                <td><Form.Group as={Col}  controlId="adtRetMkp">
                                <Form.Control type="number" min={0} placeholder="Markup" autoComplete="off" disabled={state.disable} value={state.adtRetMkp} onChange={handleChangeLCCPrice("ADT")}/>
                            </Form.Group></td>
                                <td><Form.Group as={Col}   controlId="adtRetSf">
                                <Form.Control type="number" min={0} placeholder="Service Fee" autoComplete="off" disabled={state.disable} value={state.adtRetSf} onChange={handleChangeLCCPrice("ADT")}/>
                            </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtRetDisc">
                                <Form.Control type="number" min={0} placeholder="Discount" autoComplete="off" disabled={state.disable} value={state.adtRetDisc} onChange={handleChangeLCCPrice("ADT")}/>
                            </Form.Group></td>
                                <td><span>{state.adtRettotal}</span></td>
                                <td><span>{state.adts}</span></td>
                            {props.sup==="1" &&
                              <>
                                 <td><Form.Group as={Col}  controlId="adtRetBagTotal">
                                <Form.Control type="number"  min={0} autoComplete="off" placeholder="Baggage price" disabled={true} value={state.adtRetBagTotal} onChange={handleChangeLCCPrice("ADT")} />
                                </Form.Group></td>
                                <td><Form.Group as={Col}  controlId="adtRetMealTotal">
                                <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal price"  disabled={true} value={state.adtRetMealTotal} onChange={handleChangeLCCPrice("ADT")} />
                                </Form.Group></td>
                                <td><Form.Group as={Col}  controlId="adtRetSeatTotal">
                                <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat price" disabled={true} value={state.adtRetSeatTotal} onChange={handleChangeLCCPrice("ADT")} />
                                </Form.Group></td>
                              </>
                            }
                                <td>{state.adtRettotalamt}</td>
                            </tr>
                         {props.chd > 0 &&
                           <tr>
                           <td><span>CHD</span></td>
                           <td><Form.Group as={Col} controlId="chdRetbase">
                             <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.chdRetbase} onChange={handleChangeLCCPrice("CHD")} />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="chdRettaxes">
                             <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.chdRettaxes} onChange={handleChangeLCCPrice("CHD")}/>
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="chdRetmarkup">
                             <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.chdRetmarkup} onChange={handleChangeLCCPrice("CHD")} />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="chdRetsf">
                             <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.chdRetsf} onChange={handleChangeLCCPrice("CHD")} />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="chdRetdis">
                             <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.chdRetdis} onChange={handleChangeLCCPrice("CHD")} />
                           </Form.Group></td>
                           <td><span>{state.chdRettotal}</span></td>
                           <td><span>{state.chds}</span></td>
                           {props.sup==="1" &&
                           <>
                             <td><Form.Group as={Col}  controlId="chdRetBagTotal">
                             <Form.Control type="number"  min={0} autoComplete="off"  placeholder="Baggage price" disabled={true} value={state.chdRetBagTotal} onChange={handleChangeLCCPrice("CHD")}/>
                             </Form.Group></td>
                             <td><Form.Group as={Col}  controlId="chdRetMealTotal">
                             <Form.Control type="number"  min={0} autoComplete="off" placeholder="Meal price" disabled={true} value={state.chdRetMealTotal} onChange={handleChangeLCCPrice("CHD")}/>
                             </Form.Group></td>
                             <td><Form.Group as={Col}  controlId="chdRetSeatTotal">
                             <Form.Control type="number"  min={0} autoComplete="off" placeholder="Seat price" disabled={true} value={state.chdRetSeatTotal} onChange={handleChangeLCCPrice("CHD")} />
                             </Form.Group></td>
                            </>
                            }
                           <td>{state.chdRettotalamt}</td>
                         </tr>
                         }
                         {props.inf > 0 &&
                         <tr>
                           <td><span>INF</span></td>
                           <td><Form.Group as={Col} controlId="infRetbase">
                             <Form.Control type="number" min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={state.infRetbase} onChange={handleChangeLCCPrice("INF")} />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="infRettaxes">
                             <Form.Control type="number" min={0} placeholder="Taxes" disabled={state.disable} autoComplete="off" value={state.infRettaxes} onChange={handleChangeLCCPrice("INF")}  />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="infRetmarkup">
                             <Form.Control type="number" min={0} placeholder="Markup" disabled={state.disable} autoComplete="off" value={state.infRetmarkup} onChange={handleChangeLCCPrice("INF")} />
                           </Form.Group></td>
                           <td><Form.Group as={Col} controlId="infRetsf">
                             <Form.Control type="number" min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.infRetsf} onChange={handleChangeLCCPrice("INF")} />
                           </Form.Group></td>
                              <td><Form.Group as={Col} controlId="infRetdis">
                             <Form.Control type="number" min={0} placeholder="Discount" disabled={state.disable} autoComplete="off" value={state.infRetdis} onChange={handleChangeLCCPrice("INF")} />
                           </Form.Group></td>
                           <td><span>{state.infRettotal}</span></td>
                           <td><span>{state.infs}</span></td>
                           {props.sup==="1" &&
                           <>
                             <td><Form.Group as={Col}  controlId="chdBagTotal">
                             <Form.Control type="number"  min={0} autoComplete="off"  placeholder="" disabled={true} />
                             </Form.Group></td>
                             <td><Form.Group as={Col}  controlId="chdMealTotal">
                             <Form.Control type="number"  min={0} autoComplete="off" placeholder="" disabled={true} />
                             </Form.Group></td>
                             <td><Form.Group as={Col}  controlId="chdSeatTotal">
                             <Form.Control type="number"  min={0} autoComplete="off" placeholder="" disabled={true}  />
                             </Form.Group></td>
                            </>
                            }
                           <td>{state.infRettotalamt}</td>
                         </tr>
                          }
                          
                         </>
                         }
                     
              </table>
            </div>
            </div>
          </div>
             
            

        </>
    )
}
export default FlightPriceInfo