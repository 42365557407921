import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Col, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Context } from "../../../App";
import log from "loglevel";
import { updateFlightQueueStatus, updateHotelQueueStatus } from './operations';
import Constants from '../../utilities/Constants'

/**
 * @description: Cancellation Full / Partial Remarks
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */
function RemarksPopup({ status_data, close, reloadQueueList }) {
    const [context, setContext] = useContext(Context);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        close();
        reloadQueueList();
    }

    const handleCloseCancel = () => {
        setCancelPopup(false)
    }
    const handleShow = () => setShow(true);
    const [state, setState] = useState({ remarks: "", ticket_number:"", disable_ticket_number: false })
    const [error, setErroMsg] = useState("");
    const [cancelPopup, setCancelPopup] = useState(false);

    useEffect(() => {
        setShow(true);
        setCancelPopup(false)
        setState(prevState => ({
            ...prevState,
            ticket_number: status_data?.tkt_issue_number || "",
            disable_ticket_number: status_data?.tkt_issue_number ? true : false
        }));
    }, [])

    const handleChange = (e) => {
        const { id, value } = e.target
        setErroMsg("");
        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    };


    const updateStatus = async (e) => {
        if (state.remarks === "" || state.remarks === null) {
            setErroMsg("Please provide remarks");
            return
        }
        if(status_data?.issue_update_value && status_data?.issue_update_value == 46 &&
            (state.ticket_number === undefined || state.ticket_number === "" || state.ticket_number === null)){
                setErroMsg("Ticket number must be provided");
                return
        }
        let payload = {
            bid: status_data?.bk_id,
            case_number: status_data?.case_number,
            issue_status: status_data?.issue_update_value,
            pax_status: status_data?.pax_status,
            tkt_issue_number: state?.ticket_number || null,
            remarks: state.remarks,
            upby: `${context?.logindata?.fname} ${context?.logindata?.lname}`,
            upby_id: context?.logindata?.user_id,
        }
        if (status_data?.service === "Flight") {
            updateFlightQueueStatus(payload).then(response => {
                if (response?.suc) {
                    setShow(false);
                    close();
                    reloadQueueList();
                } else {
                    setErroMsg("Error while update")
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in updateStatus pax function---" + error
                );
            });
        } else if (status_data?.service === "Hotel") {
            updateHotelQueueStatus(payload).then(response => {
                if (response?.suc) {
                    setShow(false);
                    close();
                    reloadQueueList();
                } else {
                    setErroMsg("Error while update")
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in updateStatus pax function---" + error
                );
            });
        }

    }

    const getIssueStatus = (status) => {
        let arraydata = Constants.issue_status_dropdown.find(x => x.value + "" === status + "");
        if (arraydata) {
            return arraydata.label;
        } else {
            return "";
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} className='satusUpdate'>
                <Modal.Header closeButton>
                    <Modal.Title>Status Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                        <span> Case number :</span><span> {status_data?.case_number}</span>
                        </Form.Group>
                        <Form.Group as={Col}>
                        <span> Booking Ref :</span><span> {status_data?.bk_id}</span>
                        </Form.Group>
                               </Form.Row>
                               <Form.Row>
                               <Form.Group as={Col}>
                               <span> Issue status :</span><span> {getIssueStatus(status_data?.issue_update_value)}</span>
                        </Form.Group>

                            <Form.Group className="selectplugin d-flex tickNum" as={Col} controlId="ticket_number">
                                <Form.Label className='ticket_number'>Ticket Number :</Form.Label>
                                <Form.Control type="text" className="username" placeholder="Enter Ticket Number" name="ticket_number" autoComplete="off"
                                    value={state.ticket_number} disabled={state.disable_ticket_number} onChange={handleChange} />
                                               </Form.Group>
                               </Form.Row>
                    
                    <form onSubmit={e => updateStatus(e)}>
                      
                        <Form.Row>
                            <Form.Group className="selectplugin remarksBox" as={Col} controlId="remarks">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={"Remarks"}
                                    onChange={handleChange}
                                    value={state.remarks}
                                    maxLength={100}
                                />
                            </Form.Group>
                            
                        </Form.Row>
                        {error ?
                                <small className="errorText">
                                    {error}
                                </small> : ""
                            }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e) =>{
                        if(status_data?.issue_update_value && status_data?.issue_update_value == 46 &&
                            (state.ticket_number === undefined || state.ticket_number === "" || state.ticket_number === null)){
                                setErroMsg("Ticket number must be provided");
                                return
                        }else if (state.remarks === "" || state.remarks === null) {
                            setErroMsg("Please provide remarks");
                        }else if(status_data?.issue_update_value && status_data?.issue_update_value == 53){
                            setCancelPopup(true);
                        }else{
                            updateStatus(e);
                        }
                    }}>
                        Update
                    </Button>
                    <Button className='ml-3 btn btn-outline-secondary' onClick={(e) => {
                        setShow(false);
                        close();
                        reloadQueueList();
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={cancelPopup} onHide={handleCloseCancel} className='addtoQuePrcee'>
                {/* <Modal.Header closeButton>
                   
                </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex aryDel">
                    <Modal.Title>Are you sure want to proceed</Modal.Title>
                    <div className='DelB'>
                    <Button variant="primary" className='mr-4' onClick={(e) => {
                        updateStatus(e);
                    }}>
                        Yes
                    </Button>
                    <Button variant="outline-secondary" onClick={() => {
                        setCancelPopup(false);
                    }}>
                        No
                    </Button>

                    </div>
             

                    </div>
                

                </Modal.Body>
               
            </Modal>
        </div>
    )
}
export default RemarksPopup;