import React from "react";
import { Col, Table } from "react-bootstrap";
import Utilities from "../../utilities/Utilities";
import FormateCurrency from "../../utilities/FormatCurrency";

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:20-05-2024
 * @author:Ubedullah
 *
 */
const calculateRefundSup = (price) => {
  const total = price.reduce((prev, t) => {
    return Utilities.addPrice(prev, t.refunSupPrice, "Add");
  }, 0);

  return total;
};

const calculateRefundCL = (price) => {
  const total = price.reduce((prev, t) => {
    return Utilities.addPrice(prev, t.refundToCl, "Add");
  }, 0);

  return total;
};

function RefundSummary(props) {
  return (
    <>      
      <Col>
        <div className="ticketSummerynewr d-flex justify-content-between">

          <div className="commonWrap">
          <h5 className="layoutTitle d-flex justify-content-between ticketSummaryTitle">
            {props.flow} Summary {props.type}
          </h5>
          <div className="bookingListinfo">
            <Table className="m-0">
              <thead>
                <tr>
                  <th>Passenger Type</th>
                  <th>Sub Total x PAX</th>
                  {props.type === "Supplier" && <th>{props.flow} from Supplier</th>}
                  {props.type === "Customer" && (
                    <th>{props.flow} to Customer</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {props.priceData &&
                  props.priceData.map((pr, idx) => (
                    <tr key={idx}>
                      <td>
                        {pr.ptype === "ADT" && <span>Adult</span>}
                        {pr.ptype === "CHD" && <span>Child</span>}
                        {pr.ptype === "INF" && <span>Infant</span>}
                      </td>
                      <td>
                        <span>
                          {props.cur} <FormateCurrency value={pr.subtotal} /> x {pr.count}
                        </span>
                      </td>
                      <td>
                        <span>
                        {props.cur} {props.type === "Supplier" ? <FormateCurrency value={pr.refunSupPrice} /> : <FormateCurrency value={pr.refundToCl} />}
                        </span>
                      </td>
                      {/* <td>
                        <span>{<FormateCurrency value={pr.refundToCl} />}</span>
                      </td> */}
                    </tr>
                  ))}
                <tr className="totalAmount">
                  <td colSpan="2" className="text-right">
                    <strong>Total :</strong>
                  </td>
                  {props.type === "Supplier" ? <td>{props.cur} {calculateRefundSup(props.priceData)} </td> : <td>{props.cur} {calculateRefundCL(props.priceData)}</td>}
                  
                  
                </tr>
              </tbody>
            </Table>
          </div>
          </div>
        </div>
      </Col>
    </>
  );
}
export default RefundSummary;
