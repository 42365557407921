/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-06-12 09:05:48
 * @ Description: Common header for the application
 */

import React, { useContext, useState } from "react";
import { Container, Form, Row, Col, Modal, Alert, Button, Navbar, FormControl, NavDropdown, Nav } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import { Link } from 'react-router-dom';
import logo from "../../assets/images/cntlogo.png"
import Select from 'react-dropdown-select';
import { quicksearchHeader } from "../dashboard/operations";
export const USER_AUTH_DATA = "userAuthData";

function Header(props) {
  const [visible, setVisible] = useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const [signin, setSignin] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [uploadProfPic, setUploadProfPic] = useState(null);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Flights");
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [context, setContext] = useContext(Context);
  const [showSupport, setShowSupport] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [logoutPopup, setLogutPopup] = useState(false);
  const [loggedin_info, setLoggedinInfo] = useState("");
  const [quick_search_lable, setQuick_search_lable] = useState("");
  const [quick_search_varient, setQuick_search_varient] = useState("");
  const [quicksearch, setQuickSearch] = useState('')

  const SelectOptions = [{ label: 'Airline PNR', value: 'airlinepnr' },
  { label: 'GDS PNR', value: 'gdspnr' },
  { label: 'TKT Number', value: 'tkt_num' },
  { label: 'Booking ID', value: 'booking_id' },
  { label: 'Hotel Config #', value: 'hotel_num' }]

  const selectChange = selectedOption => {
    if (quick_search_varient!=selectedOption[0].value){
      setQuickSearch("")
    }
    if (selectedOption) {
      setQuick_search_lable(selectedOption[0].label)
      setQuick_search_varient(selectedOption[0].value)
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'com_id') {
      setQuickSearch(value)
    }
  }
  const clickSearch = () => {
    if (quicksearch && quick_search_varient) {
      let req = {
        region_type: context.logindata.region_type,
        id: quicksearch,
        tag: quick_search_varient,
        uid: context.logindata.user_id,
        is_admin: context.logindata.is_admin
      };
      quicksearchHeader(req).then((resp)=>{
        if (resp.suc){
          if(resp.res.product === "Hotel"){
            window.open(`/crm/hotel/summary/${resp.res.booking_id}`)
          }else if(resp.res.product === "Flight"){
            window.open(`/crm/flight/summary/${resp.res.booking_id}`)
          }
        }else {
          setNotiMessageShow(true);
          setNotiMessage(resp.err);
          setNotiVarient("danger");
          hidemessage()
        }
      })
    } else {
        setNotiMessageShow(true);
        setNotiMessage("Please enter Value to be searched");
        setNotiVarient("danger");
        hidemessage()
    }
  }

  useEffect(() => {
    let data = localStorage.getItem(USER_AUTH_DATA);
    if (data && data !== undefined && data !== null) {
      let itm = JSON.parse(data)
      setLoggedinInfo(itm);
    }
  }, []);


  useEffect(() => {
    let data = localStorage.getItem(USER_AUTH_DATA);
    if (data && data !== undefined && data !== null) {
      let itm = JSON.parse(data)
      setProfileImage(itm?.profile_pic);
    }
  }, []);



  const logoutCall = (e) => {
    setLogutPopup(true)
  }


  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  let prof_pic = localStorage.getItem("PROFILE_PIC");
  useEffect(() => {
    if (prof_pic !== null) {
      setUploadProfPic(prof_pic);
    }
  }, [localStorage.getItem("PROFILE_PIC")]);

  console.log("profile image header", prof_pic);

  const handleMyAccount = () => {
    navigate("../my-account/user-profile");
    // window.location = "../my-account/user-profile";
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
    // window.location.reload();
  };
  const [top] = useState(10);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    setshowMenu(!showMenu);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    showDrawer();
  };

  const setTabs = (type) => {
    setSelectedTab(type);
    sessionStorage.setItem("SEL_TAB", type)
    props.setTab(type);
  }

  const supportClick = () => {
    setShowSupport(!showSupport);
  };

  const clearCache = (e) =>{
    localStorage.removeItem(USER_AUTH_DATA);
    window.location.href="/crm/login";
  }

  
  return (
    <>
      <div className="headerAffixHeight"></div>
      {notiMessageShow ? (
        <Alert
          description={notiMessage}
          type={notiVarient}
          closable
          onClose={() => setNotiMessageShow(false)}
          className="alertForm"
          // showIcon
          style={{ zIndex: 99999 }}
        >
          {notiMessage}
        </Alert>
      ) : (
        ""
      )}
      <Row className="header">

        <Col xs={2} className="topHeadse">
        <div className="logoSec">
            <Link to="/crm/queues"><img src={logo} /></Link>
            </div>

        </Col>

        <Col xs={10} className="pr-0">
          <div className="headerQuickadm">
          <div className="quickSearch">
              <Form.Group className="selectplugin m-0" as={Col} controlId="firstName">
                <Select
                  options={SelectOptions}
                  values={[]}
                  onChange={selectChange}
                  searchable={true}
                  placeholder="Quick Search"
                />
              </Form.Group>
              {quick_search_varient &&
                <>
                  <Form.Control type="text" id="com_id" placeholder={"Enter "+ quick_search_lable} value={quicksearch} onChange={handleChange} />
                  <button className="searchIcon ezyIcon icon-search" onClick={clickSearch} icon="search"></button>
                </>
              }
            </div>
          <div className="AdmnHi">
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto ezyIcon icon-customer-service">
                  <NavDropdown title={loggedin_info && loggedin_info.fname && loggedin_info.lname ? `Hi, ${loggedin_info?.fname} ${loggedin_info?.lname}` : `Hi, ${loggedin_info?.fname}`} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={(e) => logoutCall(e)}><span className="d-flex"><span className="ezyIcon icon-logout mr-2"></span>Logout</span></NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          </div>
     
        </Col>
              <Modal show={logoutPopup} onHide={(e) =>{
          setLogutPopup((val) => !val)
        }} className='logOutyes'>
          <Modal.Body >
            <div className="areYoulg">
            <div className="areMtitle">
            <Modal.Title>Are you sure want to Logout ?</Modal.Title>
            </div>
             <div className="lgSec">
            
                <Button size="xs" variant="outline-secondary" className="mr-3" onClick={() => {
                  setLogutPopup(false);
                }}>
                  No
                </Button>
                <Button size="xs" className="mr-2" variant="primary" onClick={(e) => {
                  clearCache(e);
                }}>
                  Yes
                </Button>
              </div>

            </div>
                    
          </Modal.Body>
       
        </Modal>
      </Row>
    </>
  );
}

export default Header;
