import { useState, useEffect, useContext } from "react";
import { Context } from "../../App";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Form, Alert, Row, Col, Button, Modal } from "react-bootstrap";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { processPayment, savePaymentRequest, getPaymentInfo } from "./operations";
import FormateCurrency from "../../components/utilities/FormatCurrency";
import LoaderN from "../../components/common/loaders/LoaderN";

const PK_KEY = process.env.REACT_APP_PAY_PRIVATE_KEY;
const RETURN_URL = process.env.REACT_APP_CNT_RETURN_URL;
const TERMS_COND_URL = process.env.REACT_APP_PAY_TERMS_COND_URL;
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

/**
 * @author Praveen Varma
 * @description Payment gateway integration
 * @returns Payment info
 * @date 04-12-2023
 */
function CheckoutPay({ payment, redirectParent, payErrMsg, returnUrl }) {
  const [context, setContext] = useContext(Context)
  //Enable 3d true/false
  const [enable3D, setEnable3D] = useState(true);
  const [showFailed, setShowFailed] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [rbiCheck, setIsRBICheck] = useState(false);
  const [cardHolder, setCardHolder] = useState("");
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);
  const [isExpValid, setIsExpValid] = useState(false);
  const [isCvvValid, setIsCvvValid] = useState(false);
  const [errorMsg1, setErrMsg1] = useState("");
  const [errorMsg2, setErrMsg2] = useState("");
  const [errorMsg3, setErrMsg3] = useState("");
  const location = useLocation();
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [product, setProduct] = useState("");
  const [payBtnDisabled, setPayBtnDisabled] = useState(false)



  useEffect(() => {
  }, []);

  useEffect(() => {
    if (payErrMsg) {
      setNotiMessageShow(true);
      setNotiMessage(payErrMsg);
      setNotiVarient('error');
      hidemessage();
    }
  }, [payErrMsg])

  useEffect(() => {
      if(sessionStorage.getItem("cnt_pay_request")!== null){
        let req = JSON.parse(sessionStorage.getItem("cnt_pay_request"));
        getPaymentDetails(req, "PAY");
      } else {
        getPaymentDetails(null, "CKO");
      }
  }, []);

  const getPaymentDetails = (req, type) => {
    if (type === "PAY") {
      try {
        getPaymentInfo(req.pay_id).then((resp) => {
          if (resp !== undefined && 
              resp.approved && 
              (resp.status === "Authorized" || resp.status === "Captured")
          ) {
            let payload;
            if(sessionStorage.getItem("pay_req")!== null){
              payload = JSON.parse(sessionStorage.getItem("pay_req"));
            }
            let req = {};
            if(payment?.booking_id){
              req.booking_id=payment?.booking_id;
            }
            req.payment_unique_id = payment?.payment_unique_id;
            req.product = payment?.product;
            req.cko_session_id = payment?.payment_unique_id;
            req.pay_req = payload;
            req.pay_resp = resp;
            req.pay = [
              {
                ptype: resp.source.card_type,
                amt: resp.amount,
                ccode: resp.currency,
                psta: resp.status,
                pgauthid: resp?.auth_code,
                pgtid: resp.source.payment_account_reference,
                pay_id: resp.id,
                pgstc: resp?.response_code,
                ctype: resp.source.scheme,
                cno: resp.source.last4,
                cname: cardHolder,
                cexp: resp.source.expiry_month + "/" + resp.source.expiry_year,
              }
            ];
            console.log("Mongo PAY REQUEST : " + JSON.stringify(req));
            savePaymentRequest(req).then((res) => {
              if (res && res.suc) {
                console.log("Payment payload mongo persistance success ", payment?.payment_unique_id);
                setIsLoading(false);
                setButtonLoading(false);
                redirectParent({ status: "success", payId: resp?.id })
              } else {
                console.log("Error : Payment payload mongo persistance failed ", payment?.payment_unique_id);
                setProduct(req.product)
                setIsLoading(false)
                setShowFailed(true);
                setButtonLoading(false);
                redirectParent({ status: "failed", payId: resp?.id })
              }
            }).catch(function (error) {
              if(error?.response?.data==="Unauthorized"){
                setButtonLoading(false);
              } 
              console.error("Error:" + error); 
            });
          } else {
            console.log("Error while pay from checkout");
            setProduct(req.product)
            setIsLoading(false)
            setShowFailed(true);
            setButtonLoading(false);
            redirectParent({ status: "failed", payId: resp?.id })
          }
          // if (
          //   resp !== undefined &&
          //   resp.approved &&
          //   (resp.status === "Authorized" || resp.status === "Captured")
          // ) {
          //   setIsLoading(false);   
          //   redirectParent({ status: "success", payId: resp?.id });
          //   setPayBtnDisabled(true);
          // } else {
          //   // setPaymentReq(req)
          //   setProduct(req.product)
          //   setIsLoading(false)
          //   setShowFailed(true);
          //   setButtonLoading(false);
          //   redirectParent({ status: "failed", payId: resp?.id })
          //   // props.sendPayRequest(req)
          // }
        }).catch(function (error) {
          console.error("Error in Payment" + error);       
        });   
      } catch (error) {
        console.error("Error in Payment" + error);           
      }
    }else{
        // let urlAray=window.location.href.split("=");
        // try {
        //   let payload = {"cko_session_id" :urlAray[1]}
        //   let tokenKey = Utilities.generateAPIToken(payload);
        //   getPayResponse(payload,tokenKey).then((resp) => {
        //       if (resp !== undefined && resp.data.suc !== undefined) {
        //           getPaymentInfo({"pay_id":resp.data.pay_id}).then((resp) => {
        //               if (
        //                 resp !== undefined &&
        //                 resp.status === "Authorized" &&
        //                 resp.approved
        //               ) {
        //                 setIsLoading(false);   
        //                 redirectParent({ status: "success", payId: resp?.id });
        //                 setPayBtnDisabled(true);
        //               } else {
        //                 setIsLoading(false)
        //                 setShowFailed(true);
        //                 setButtonLoading(false);
        //               }
        //             }).catch(function (error) {
        //               console.error("Error:" + error);      
        //             });
        //       } else {
        //          setProduct(req.product)
        //          setIsLoading(false)
        //          setShowFailed(true);
        //          setButtonLoading(false);
        //       }
        //     }).catch(function (error) {
        //       console.error("Error in Payment" + error);      
        //     }); 
        // } catch (error) {
        //   console.error("Error in Payment" + error);             
        // }
    }
  };


  const paymentInit = async (e) => {
    setIsLoading(true);
    try {
      let payload = {
        source: {
          type: "token",
          token: e.token,
        },
        amount: payment?.amt,
        currency: payment?.cur,
        "3ds": {
          enabled: enable3D,
        },
        capture: true,
        success_url: RETURN_URL+returnUrl,
        failure_url: RETURN_URL+returnUrl,
      };
      console.log("Request: Checkout pay payload", JSON.stringify(payload));
      sessionStorage.setItem("pay_req", JSON.stringify(payload));
      processPayment(payload).then((response) => {
        if (response) {
          let resp = response;
          if (
            resp._links &&
            resp._links.redirect &&
            resp._links.redirect.href
          ) {
            console.log("Response : ==== 3D Flow ==== Resp", JSON.stringify(resp));
            let req = {}
            if(payment?.booking_id){
              req.booking_id=payment?.booking_id;
            }
            req.payment_unique_id = payment?.payment_unique_id;
            req.product = payment?.product;
            req.pay_req = payload;
            req.pay_resp = resp;
            req.pay_id = resp.id;
            let sessionAry = resp._links.redirect.href.split("sessions-interceptor")
            req.cko_session_id = sessionAry[1]?.replace(/^\/|\/$/g, '');
            // req.pay = [
            //   {
            //     ptype: resp.source.card_type,
            //     amt: resp.amount,
            //     ccode: resp.currency,
            //     psta: resp.status,
            //     pgauthid: resp.auth_code,
            //     pgtid: resp.source.payment_account_reference,
            //     pay_id: resp.id,
            //     pgstc: resp.response_code,
            //     ctype: resp.source.scheme,
            //     cno: resp.source.last4,
            //     cname: cardHolder,
            //     cexp: resp.source.expiry_month + "/" + resp.source.expiry_year,
            //   }
            // ];
            console.log("Mongo 3D Flow PAY REQUEST : " + JSON.stringify(req));
            sessionStorage.setItem("cnt_pay_request", JSON.stringify(req));
            window.location.href = resp._links.redirect.href;
            savePaymentRequest(req).then((res) => {
              window.location.href = resp._links.redirect.href;
            }).catch(function (error) {
              if(error?.response?.data==="Unauthorized"){
                setButtonLoading(false);
              }
              console.error("Error:" + error);      
            });
          } else {
            console.log("Response :  ==== Non 3D Flow ==== " + JSON.stringify(resp));
            if (resp.approved) {
              let req = {};
              if(payment?.booking_id){
                req.booking_id=payment?.booking_id;
              }
              req.payment_unique_id = payment?.payment_unique_id;
              req.product = payment?.product;
              req.cko_session_id = payment?.payment_unique_id;
              req.pay_req = payload;
              req.pay_resp = resp;
              req.pay = [
                {
                  ptype: resp.source.card_type,
                  amt: resp.amount,
                  ccode: resp.currency,
                  psta: resp.status,
                  pgauthid: resp.auth_code,
                  pgtid: resp.source.payment_account_reference,
                  pay_id: resp.id,
                  pgstc: resp.response_code,
                  ctype: resp.source.scheme,
                  cno: resp.source.last4,
                  cname: cardHolder,
                  cexp: resp.source.expiry_month + "/" + resp.source.expiry_year,
                }
              ];
              console.log("Mongo PAY REQUEST : " + JSON.stringify(req));
              savePaymentRequest(req).then((res) => {
                if (res && res.suc) {
                  console.log("Payment payload mongo persistance success ", payment?.payment_unique_id);
                  setIsLoading(false);
                  setButtonLoading(false);
                  redirectParent({ status: "success", payId: resp?.id })
                } else {
                  console.log("Error : Payment payload mongo persistance failed ", payment?.payment_unique_id);
                  setIsLoading(false);
                  setButtonLoading(false);
                  redirectParent({ status: "failed", payId: resp?.id })
                }
              }).catch(function (error) {
                if(error?.response?.data==="Unauthorized"){
                  setButtonLoading(false);
                } 
                console.error("Error:" + error); 
              });
            } else {
              console.log("Error while pay from checkout");
              redirectParent({ status: "failed", payId: resp?.id })
            }
          }
        } else {
          setButtonLoading(false);
          console.log("Error while pay from checkout outer loop");
          redirectParent({ status: "failed", payId: "" })
        }
      }).catch(function (error) {
        console.error("ERROR IN PAYMENT FLOW : " + error);      
      });
    } catch (err) {
      setButtonLoading(false);
      console.log("ERROR IN PAYMENT FLOW : " + err);
    }
  };

  const handleName =(e) => {
    setCardHolder(e.target.value?.replace(/[^a-z]/gi, ' '))
  }

  const handleSave = () => {
    if (rbiCheck) {
      setIsRBICheck(false)
    } else {
      setIsRBICheck(true)
    }
  }

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage('');
    }, 5000);
  };
  
  const retryPay = () => {
    setShowFailed(false)
  };

  const retryPayment = () => {
    setShowFailed(false)
  };

  return (
    <>
      {loading &&
        <LoaderN
          title={"Please Wait...!"}
          subtitle={"Do not refresh the page while we securely process your payment."}
        />
      }
      {payment !== undefined && payment !== "" && payment !== null &&
        <div className="App paymentWrap">
          <Frames
            config={{
              debug: true,
              publicKey: PK_KEY,
              localization: {
                cardNumberPlaceholder: "Enter card number",
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: "CVV",
              },
            }}
            ready={(e) => { }}
            frameActivated={(e) => { }}
            frameFocus={(e) => { }}
            frameBlur={(e) => { }}
            frameValidationChanged={(e) => {
              setErrMsg1("");
              setErrMsg2("");
              setErrMsg3("");
              if (e.element === "card-number") {
                if (e.isValid) {
                  setIsCardValid(true);
                } else {
                  setIsCardValid(false);
                  setErrMsg1("Invalid Card number")
                }
              }
              if (e.element === "expiry-date") {
                if (e.isValid) {
                  setIsExpValid(true)
                } else {
                  setIsExpValid(false);
                  setErrMsg2("Invalid Expiry-date")
                }
              }
              if (e.element === "cvv") {
                if (e.isValid) {
                  setIsCvvValid(true);
                } else {
                  setIsCvvValid(false);
                  setErrMsg3("Invalid CVV")
                }
              }
            }}
            paymentMethodChanged={(e) => { }}
            cardValidationChanged={(e) => {
              setErrMsg1("");
              setErrMsg2("");
              setErrMsg3("");
              if (e.isElementValid) {
                if (e.isValid && e.isElementValid.cardNumber && e.isElementValid.expiryDate && e.isElementValid.cvv) {
                  setIsAllFilled(true);
                } else {
                  setIsAllFilled(false);
                }
              }
            }}
            cardSubmitted={() => { }}
            cardTokenized={(e) => {
              paymentInit(e);
            }}
            cardTokenizationFailed={(e) => { }}
            cardBinChanged={(e) => { }}
          >
            <div className="paymentDetailsWrapper">
              <Alert className="notification alert" variant={notiVarient} show={notiMessageShow}
                onClose={() => setNotiMessageShow(false)}
                dismissible> {notiMessage}</Alert>
              <div gutter={30} className="paymentDetailsSubWrapper">
                <Row>
                  <Col xs={3}>
                    <div span={5} className="paymentOptionWrap">
                      <div className="paymentOption">
                        <ul>
                          <li>
                            <Link className="active">
                              {"Credit/Debit Card"} <i class="icon icon-down-arrow"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col xs={9}>

                    <div span={19} className="enterCardDetailsWrap">
                      <Row>
                        <Col xs={12}>
                          <div>
                            <div span={24}>
                              <div className="enterCardDetails">Enter Card Details</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div gutter={20}>
                        <Row>
                          <Col>
                            <div span={12}>
                              <div className="paymentLabel">Card Number</div>
                              <CardNumber />
                              {!isCardValid &&
                                <div className="error">{errorMsg1}</div>
                              }
                            </div>
                          </Col>
                          <Col>
                            <Form.Row>
                              <div span={12}>
                                <div className="paymentLabel">{"Card Holder Name"}</div>
                                <input
                                  type="text"
                                  className="frame--activated"
                                  disabled={payBtnDisabled}
                                  name="cardHolder"
                                  value={cardHolder}
                                  placeholder={"Name as on card"}
                                  // onChange={(e) => setCardHolder(e.target.value)}
                                  onChange={handleName}
                                />
                              </div>
                            </Form.Row>
                          </Col>
                        </Row>
                      </div>
                      <div gutter={20}>
                        <Row>
                          <Col>
                            <div span={12}>
                              <div className="paymentLabel">{"Expiry Date"}</div>
                              <ExpiryDate />
                              {!isExpValid &&
                                <div className="error">{errorMsg2}</div>
                              }
                            </div>
                          </Col>
                          <Col>
                            <div span={12}>
                              <div className="paymentLabel">{"CVV"}</div>
                              <Cvv />
                              {!isCvvValid &&
                                <div className="error">{errorMsg3}</div>
                              }
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div gutter={20} className="marginTop30">
                        <div span={24}>
                          <div>
                            <Form.Group className="m-0">
                              <Form.Check disabled={payBtnDisabled} type="checkbox" id="xx" label="Save my card as per the RBI Guidelines" value="" onChange={handleSave} custom />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="paymentDetailsWrappertwo">
              <div gutter={20} className="marginTop30">
                <div span={24}>
                  <div>
                    <Form.Group className="m-0 termsCon">
                      <span><Form.Check disabled={payBtnDisabled} type="checkbox" id="terms" label={""} onClick={(e) => setTerms(trm => !trm)} /></span>
                      <span> I Understand and agree the <Link
                        className="knowMore"
                        to={TERMS_COND_URL} target="_blank"
                      >{" "}Terms & Conditions</Link>{" "}of Click N Travel</span>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="maninTamount">
                <div className="tAmountcrm">
                  <div className="TotalBut">
                    <span>
                      Total Payable Price
                    </span>
                    <span>
                      Including All Taxes and fees
                    </span>
                  </div>
                  <span className="moneRreg"><span className="regiOnt">{payment?.cur}</span>{" "}{<FormateCurrency value={payment?.amt} />}</span>
                </div>
                <button
                  type="primary"
                  className="cntBtn payBtn"
                  disabled={payBtnDisabled ? payBtnDisabled : buttonLoading}
                  onClick={(e) => {
                    if (cardHolder !== "" && isAllFilled && terms) {
                      setIsLoading(true);
                      setButtonLoading(true);
                      Frames.submitCard();
                    } else if (cardHolder === "") {
                      setNotiMessageShow(true)
                      setNotiMessage('Enter all the fields and proceed');
                      setNotiVarient('error');
                      hidemessage();
                    } else if (!isAllFilled) {
                      setNotiMessageShow(true);
                      setNotiMessage('Enter all the fields and proceed');
                      setNotiVarient('error');
                      hidemessage();
                    } else if (!terms) {
                      setNotiMessageShow(true);
                      setNotiMessage('Please read terms and conditions');
                      setNotiVarient('error');
                      hidemessage();
                    } else {
                      setNotiMessageShow(true);
                      setNotiMessage('Please read terms and conditions');
                      setNotiVarient('error');
                      hidemessage();
                    }
                  }}
                  style={{
                    background: buttonLoading ? "#FF4500" : "#008D00",
                    padding: "0 40px",
                    height: "50px",
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontFamily: "var(--fontMedium)",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  {buttonLoading ? "Processing...." : "Pay Now"}
                </button>
              </div>
            </div>
          </Frames>
        </div>
      }

      {showFailed && product!=="" &&
        <Modal show={showFailed} className="addtoQue payMentsuc" backdrop="static">
            <Modal.Body>
                <Modal.Title>Payment Failed</Modal.Title>
                <p>We are unable to process your payment.</p>
                <p>Please try again</p>    
                <Button type="primary" className="cntBtn failedBtn" onClick={product && product!==""?retryPay:retryPayment}>
                  Retry Payment 
                </Button>
                <Link to={WEBSITE_URL} className="btn-primary">Back to Home</Link>
            </Modal.Body>
        </Modal>
      }
    </>
  );
}

export default CheckoutPay;
