import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../App";
import FlightDetailsContent from "./FlightDetailsContent";
import { isArrayNotEmpty } from "../../utilities/validators";
import Modal from 'react-bootstrap/Modal';
import { Col, Row, Container, Form, Dropdown, DropdownButton } from "react-bootstrap";
import Constants from '../../utilities/Constants';
import Select from 'react-dropdown-select';
import MyTripsPassengersDetails from "./MyTripsPassengersDetails";
import PassengersDetails from "./flights/PassengersDetails";
import FareDetails from "./flights/FareDetails";
import PaymentDetails from "./flights/PaymentDetails";
import CancelFlightTrip from "./flights/CancelFlightTrip";
import ReissueFlightTrip from "./flights/ReissueFlightTrip";


/**
 * @description: Flight Details fetch Queue
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */
function FlightDetailsQueuePopup({ itiData, close, reloadQueueListAfterCancelParent }) {
  const [context, setContext] = useContext(Context);
  const [sectors, setSectors] = useState();
  const [cancelType, setCancelType] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    close();
  }
  const handleShow = () => setShow(true);

  const handleCancelTypeChange = (value) => {
    setCancelType(value);
  };

  useEffect(() => {
    constructSectors();
    setShow(true);
  }, []);

  const reloadQueueListAfterCancel = (data) =>{
    reloadQueueListAfterCancelParent(data)
}

  const constructSectors = () => {
    // Initialize the result array
    var sec = [];
    var tt = parseInt(itiData.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      itiData.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var dd = srcorign.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
        var ad = srcorign.seg[srcorign.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
        sec.push({
          src,
          des,
          dd,
          ad,
          leg: index + 1, // Increment leg number
        });
      });
      if (tt === 2) {
        itiData.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var dd = destorigin.seg[0].dd.split("T")[0]; // Format "dd" as yyyy-MM-dd
          var ad = destorigin.seg[destorigin.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd

          // Push the data into the "sec" array
          sec.push({
            src,
            des,
            dd,
            ad,
            leg: itiData.origns[0].srcorigns.length + index + 1, // Increment leg number
          });
        });
      }
    }
    if (isArrayNotEmpty(sec)) {
      setSectors(sec);
    }
  };

  const getSelStatus = (status) => {
    let data = Constants.flight_itinerary_status.find(itm => itm.value + "" === status + "");
    if (data) {
      return [data]
    } else {
      return []
    }
  }
  const updateCellData = async (val) => {
    setCancelType(val[0].value);
  }

  return (
    <Modal show={show} onHide={handleClose} className='flightDetcrm right fade' backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Your Itinerary</Modal.Title>
        {(itiData.sty === "split" || itiData.tt == 1) && (
          <div className="selectMangeBook commonFlex paddingRight FlightYourItinerary">
            <Form.Row>
              <Form.Group className="selectplugin mb-0" as={Col} controlId="channel_type"> 
              <Select
                placeholder="Manage Booking"
                options={Constants.flight_itinerary_status}
                onChange={(value) => (updateCellData(value))}
              />
              </Form.Group>
          
            </Form.Row>
          </div>
        )}
      </Modal.Header>
      <div className="viewItineraryWrap">
      
        {itiData && sectors &&
          itiData.origns[0] && itiData.origns[0].srcorigns &&
          itiData.origns[0].srcorigns.length > 0 &&
          itiData.origns[0].srcorigns.map((s, i) => (
            <>
              <FlightDetailsContent
                key={i}
                mapping={itiData.mappings}
                sectors={itiData.tt.toString() !== "3" ? sectors : (sectors.length > 0 &&
                  sectors.filter((sec) => {
                    return sec.leg === i + 1;
                  }))}
                secType={"onward"}
                secTypeIndex={i}
                trip={itiData.tt.toString()}
                flt={s} // Use s directly as the flt prop
                seg={s.seg[0]}
                refund={s.re}
                flow={false}
              />
              {itiData.sty === "split" && cancelType === "" && (
                <MyTripsPassengersDetails sup={s.sup} response={itiData} psngrs={itiData.psngrs.filter((pax) => pax.tid === itiData.origns[0].srcorigns[0].tid)} />
              )}
            </>
          ))}

        {itiData && sectors &&
          itiData.origns[0] && itiData.origns[0].destorigins &&
          itiData.origns[0].destorigins.length > 0 &&
          itiData.origns[0].destorigins.map((s, i) => (
            <>
              <FlightDetailsContent
                key={i}
                mapping={itiData.mappings}
                sectors={sectors}
                secType={"arrival"}
                secTypeIndex={0}
                trip={itiData.tt.toString()}
                flt={s} // Use s directly as the flt prop
                seg={s.seg[0]}
                refund={s.re}
                flow={false}
              />
              {itiData.sty === "split" && cancelType === "" && (
                <MyTripsPassengersDetails sup={s.sup} response={itiData} psngrs={itiData.psngrs.filter((pax) => pax.tid === itiData.origns[0].destorigins[0].tid)} />
              )}
            </>
          ))}

        {cancelType === "" && (
          <>
            <div class="horizontalDivider"></div>
            {itiData.sty !== "split" && (
              <PassengersDetails sup={itiData.sup} response={itiData} />
            )}
            {itiData && (
              <div className="travellerDetails">
                <FareDetails response={itiData} />
              </div>
            )}
            {itiData && itiData.pay.length > 0 && (
              <div className="travellerDetails payMentsdetail">
                <PaymentDetails payment_info={itiData.pay[0]} base_usr_exrate={itiData.base_usr_exrate} />
              </div>
            )}
          </>
        )}
        {cancelType && cancelType === "cancel" && (
          <CancelFlightTrip close={handleClose} cancelData={itiData} reloadQueueListAfterCancel ={reloadQueueListAfterCancel}/>
        )}
        {cancelType && cancelType === "reissue" && (
          <ReissueFlightTrip close={handleClose} cancelData={itiData} />
        )}
      </div>
    </Modal>
  );
}

export default FlightDetailsQueuePopup;
