import log from "loglevel";
import React, { useCallback, useEffect, useState, useReducer, useContext, useRef } from 'react';
import { Context} from "../../../App";
import { Alert, Col, Form, Row,Modal} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Button from '../../common/Button';
import useOnclickOutside from 'react-cool-onclickoutside';
import Utilities from "../../utilities/Utilities";
import FileUpload from '../../common/FileUpload';
import { Scrollbars } from 'react-custom-scrollbars';
import {emailValidation, mobileNumberValidation } from '../../utilities/FormValidations';
import debounce from 'lodash.debounce';
import moment from "moment";
import DateUtils from "../../utilities/DateUtils";
import ManageBookingService from "../../../services/ManageBookingService";
import ProfileDetails from "./ProfileDetails";
import PaymentDetails from "./PaymentDetails";
import { publicIpv4 } from "public-ip";
import { async } from "q";
let stdCodes = require('../../masterData/CountrySTDCodes.json');
let supplierlist = require('../../masterData/HotelSuppliers.json');
export const USER_AUTH_DATA = "userAuthData";
export const CLIENT_IP = "clientIp";
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;

/**
 * @description:This function will create manual booking request
 * @author: Lakshmi
 * @param {*}
 * @function Manual Booking Hotel
 * @date : 06-12-2023
 */

/**
 * Initial State Declaration
 */
 const initialState = {
   region_type:"",cur:"QAR",isRegister:true,prof:"",isItineraryLoading:false, sup:"",notiMessage: '', notiMessageShow: false, notiVarient: '', destName:"",cleardb:false , hotelData: [] , hotelTempData: [], selHotel:[] , hotelPredectiveDropDown:false , _startDateCal: false, _endDateCal: false,selRating:[] , rating:"" ,nights:0,
   cat:"", landmark:"",phno:"",add:"" , total_price:0, roomDetails:[] , childAgeList:[] ,showCustoms:true, stdCodeMenu: stdCodes,payData:"",custms:[],conf_mail: false, inv_mail: false,enablePay:false,dest_obj:"",hotel_img:"", refundable: false, supError: false, destError: false, htnameError: false, checkinError: false, checkoutError: false, htphnError: false, sup_name: "",
   gst_type:"",gst_total:0,cgst:0, sgst:0, igst:0,att_gst:0, att_cgst:0, att_sgst:0,att_igst:0,ta_gst:0, ta_cgst:0, ta_sgst:0, ta_igst:0,payment_details:"",pay_uniq_id:"",adtList:[],selpaxfn:[],selRegion:[{label:"Qatar",value:"Qatar"}],
  };
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return { ...state, [action.type]: action.payload };
    }
  };


function HotelmanualBook(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [originIsFocus, setOriginIsFocus,] = useState(false);
    const [hotelData,setHotelData] = useState([]);
    const [selRating,setSelRating] = useState([]);
    const [selAccom,setSelAccom] = useState([]);
    const [selAdts,setSelAdts] = useState([{"value": "", "label": "Select"}]);
    const [selChds,setSelChds] = useState([{"value": "", "label": "Select"}]);
    const [selTl,setSelTl] = useState([{"value": "", "label": "Select"}]);
    const [selPhc,setSelPhc] = useState([]);
    const [selSup,setSelSup] = useState([]);
    const [selAge,setSelAge] = useState([]);
    const [isSameRoom,setIsSameRoom] = useState(false);
    const [toDate, setToDate] = useState(null);
    const destination = useRef();
    const [regionTypes, setRegionTypes] = useState([{ label: "Qatar", value: 'Qatar' }]);
    const [showPopup, setShowPopUp] = useState(false)
    const [isLoad, setIsLoad] = useState(false);

  // useEffect(() => {
  //     if (context.logindata.region_type!==undefined) {
  //       if(context.logindata.region_type.includes(",")){
  //         setRegionTypes([{ label: "Qatar", value: 'Qatar' }, { label: "India", value: 'India' }])
  //       }else{
  //         dispatch({ type: "region_type", payload: context.logindata.region_type })
  //         dispatch({ type: 'cur', payload: context.logindata.region_type==="India"?"INR":"QAR" })
  //       }
  //     }
  //   }, [context.logindata])

  useEffect(() => {
    if (context.logindata.region_type!==undefined) {
      setRegionTypes([{ label: "Qatar", value: 'Qatar' }])
      dispatch({ type: "region_type", payload: "Qatar" })
    }
  }, [context.logindata])
    

  const [ratings,setRatings] = useState([
    {"value":"1.0","label":"1.0"},
    {"value":"2.0","label":"2.0"},
    {"value":"3.0", "label": "3.0"},
    {"value":"4.0", "label": "4.0"},
    {"value":"5.0", "label": "5.0"}
  ])
  const [accomTypes,setAccomTypes] = useState([
    {"value":"Apartment","label":"Apartment"},
    {"value":"Aparthotel", "label": "Aparthotel"},
    {"value":"All-inclusive property", "label": "All-inclusive property"},
    {"value":"Campsite", "label": "Campsite"},
    {"value":"Condominium resort", "label": "Condominium resort"},
    {"value":"Guesthouse", "label": "Guesthouse"},
    {"value":"Hotel","label":"Hotel"},
    {"value":"Hostel/Backpacker accommodation", "label": "Hostel/Backpacker accommodation"},
    {"value":"Property","label":"Property"},
    {"value":"Palace","label":"Palace"},
    {"value":"Private vacation home", "label": "Private vacation home"},
    {"value":"Resort", "label": "Resort"},
    {"value":"Residence", "label": "Residence"},
    {"value":"Safari/Tentalow","label":"Safari/Tentalow"},
    {"value":"Villa", "label": "Villa"},
    
  ])

const title = [
  { label: "Select", value: "" },
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Shka", value: "Shka" },
  { label: "Shk", value: "Shk" },
];

const [adutlsList,setAdultsList] = useState([
  {"value": "", "label": "Select"},
  {"value":1,"label":"1"},
  {"value":2,"label":"2"},
  {"value":3, "label": "3"},
  {"value":4, "label": "4"},
  {"value":5, "label": "5"},
  {"value":6, "label": "6"}
])

const [chdsList,setChdsList] = useState([
  {"value":"","label":"Select"},
  {"value":1,"label":"1"},
  {"value":2,"label":"2"},
  {"value":3, "label": "3"},
  {"value":4, "label": "4"},
  {"value":5, "label": "5"}
])

const ChildernAge = [
  { value: '1', label: '1' },{ value: '2', label: '2' },{ value: '3', label: '3' },{ value: '4', label: '4' },{ value: '5', label: '5' },{ value: '6', label: '6' },{ value: '7', label: '7' },{ value: '8', label: '8' },{ value: '9', label: '9' },{ value: '10', label: '10' },{ value: '11', label: '11' }]


useEffect(() => {
  convertRoomPax(false)
}, []);

const convertRoomPax = (isAdd) => {
  let roomInitRequest = [];
  const obj = [{
    sno: !isAdd?1:state.roomDetails.length+1,
    tl: "",
    ty: "ADT",
    plbl: "Adult",
    lead: true,
    fn: "",
    ln: "",
    em: "",
    phc: "",
    phn: "",
    selTl:[],
    trave_id:0
  }];
  const fare={
    attBaseFare:"",
    attTaxes:"",
    attMarkup:"",
    attServiceFee:"",
    attCommision: "",
    attSupFee:"",
    attDisc:"",
    attAgentDisc:"",
    attTotal:"",
    taMarkup:"",
    taServiceFee:"",
    taSupFee:"",
    taDisc:"",
    taTotal:"",
    gst_type:"",
    sgst:0,
    cgst:0,
    igst:0,
    att_cgst:0,
    att_sgst:0,
    att_igst:0,
    ta_cgst:0,
    ta_sgst:0,
    ta_igst:0,
    gst_total:0
  }
  let payload = {
    rmindex: !isAdd?1:state.roomDetails.length+1,
    rmid: !isAdd?1:state.roomDetails.length+1,
    adt: 1,
    chd: 0,
    chdage:"",
    rnm:"",
    pax: obj,
    spr:"",
    canpolicy:"",
    imp_info:"",
    sup_ref:"",
    htl_conf:"",
    ra:"",
    pr:fare,
    isSameRoom:false,
    selAdts:[],
    selChds:[]
  };
  roomInitRequest.push(payload);
  if(isAdd){
    let roomsLIst=state.roomDetails;
    roomsLIst.push(payload)
    dispatch({ type: "roomDetails", payload: roomsLIst });
    setSelAdts([]);
    setSelChds([]);
  }else{
    dispatch({ type: "roomDetails", payload: roomInitRequest });
  }

}

const addRooms=()=>{
  convertRoomPax(true);
}

  const openStartDateCal = () => state._startDateCal.setOpen(true);
  const errorRemoveBlur = () => {
    console.log('errorRemoveBlur');
    dispatch({ type: "notiMessageShow", payload: false })
    document.querySelector('.origin').classList.remove('errorSerField');
  };

  const refOutClick = useOnclickOutside(() => {
    dispatch({ type: "hotelPredectiveDropDown", payload: false })
    document.querySelector('.datePick').classList.remove('activeSerField');

  });
  //=====================================
  // This function will get the Source data
  //=====================================
const handleChangOrigin = (e) => {
  const { id, value } = e.target;
  if(value.length > 0){
    dispatch({type: "cleardb", payload: true})
  }
  else{
    dispatch({type: "cleardb", payload: false})
  }

  if(value.length>2){
    let obj ={
      textValue:value
    }
    debouncedSave(obj);
  }else{
    dispatch({ type: 'hotelData', payload: [] })
    dispatch({ type: 'hotelTempData', payload: [] })
    
  }
  dispatch({ type: id, payload: value });
}

const companySelect = () =>{
  if (context.logindata.utype === "att_user") {
    if (context.logindata.cid === 0) {
      document.querySelector('.domin').classList.add('dominFocus');
    }else{
      document.querySelector('.domin').classList.remove('dominFocus');
    }
  }
}

// highlight-starts
const debouncedSave = useCallback(
  debounce(obj => predectiveCall(obj.textValue), 500),
  [], // will be created only once initially
);

const clearDestination = ()=>{
  dispatch({type: "destName", payload: ""})
  dispatch({type: "hotelData", payload: []})
  dispatch({type: "hotelTempData", payload: []})
  dispatch({type: "destError", payload: true })
  dispatch({type: "cleardb", payload: false})
}

//=====================================
// This function will call the service to get the Hotel data
//=====================================
const predectiveCall = (input) => {
  dispatch({ type: "hotelPredectiveDropDown", payload: false })
  ManageBookingService.hotelSearch(input).then((data) => {
    console.log("Predictive Response :"+JSON.stringify(data.data))
    dispatch({ type: "hotelData", payload: data.data.data!== "" ? [data.data.data] : [] })
    dispatch({ type: "hotelTempData", payload: data.data.data!== "" ? [data.data.data] : [] })
    dispatch({ type: "showResults", payload: true })
    dispatch({ type: "hotelPredectiveDropDown", payload: true })

  }).catch(function (error) {
    console.error(
      "Exception occured in hotel predective search function---" + error
    );
  });

}

//=====================================
// This function will get the Source data
//=====================================
const handleOnOrigin = (obj,type) =>() => {
    companySelect();
    if(obj!==undefined){
      dispatch({ type: "hotelPredectiveDropDown", payload: false })
      obj.type=type;
      if(type!=="city"){
        dispatch({ type: "destName", payload: obj.description+", "+obj.country})
      }else{
        if(obj.description!==null){
          dispatch({ type: "destName", payload: obj.description})
        }else{
          dispatch({ type: "destName", payload: obj.text+", "+obj.country})
        }
      }
      if(obj._id!==undefined && obj._id!==""){
            dispatch({ type: "dest_obj", payload: obj})
            let hotels = []
            let data = state.hotelTempData[0].property
            if(data.length > 0) {
            for(let val in state.hotelTempData[0].property) {
                let hotel_data = data[val]
                let obj = {
                    "value":hotel_data.pid?hotel_data.pid:"",
                    "label":hotel_data.description + ", "+ hotel_data.country,
                    "phn":hotel_data.phone?hotel_data.phone:"",
                    "rating":hotel_data.ratings?hotel_data.ratings:"",
                    "cat":hotel_data.category?hotel_data.category.name:"",
                    "add":hotel_data.address ?(hotel_data.address.line1+","+hotel_data.address.city+","+hotel_data.address.countryCode):"",
                }
                hotels.push(obj)
            }
            }
            setHotelData(hotels);
            dispatch({ type: 'destError', payload: false });
    
    }
      dispatch({ type: "selHotel", payload: [] })
      //openStartDateCal();
      errorRemoveBlur();
    }
  }

// OnChange event we will call this and update the state
const handleCheckout = date => {
  setEndDate(date);
  dispatch({ type: "checkoutError", payload: false})
  if(startDate!==undefined && date!==undefined && date !== null && startDate!==""){
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
if(utc2 < utc1){
  return 0
}
else{
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
}
const a = new Date(startDate),
    b = new Date(date),
    difference = dateDiffInDays(a, b);
    var diff_In_Time = date - startDate;
    var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
    dispatch({ type: "nights", payload: difference})
  }
}

//=====================================
  // This function will update the Airline
  //=====================================
  const handleHtlChange = (obj) => {
    dispatch({ type: "selHotel", payload: obj })
    if(obj.length == 0){
      dispatch({ type: "htnameError", payload: true })
    }
    else{
      dispatch({ type: "htnameError", payload: false })
    }
    if(obj[0]!==undefined){
    dispatch({ type: "phno", payload:obj[0].phn!==undefined?obj[0].phn.replaceAll("-", ""):"" })
    dispatch({ type: "add", payload: obj[0].add!==undefined?obj[0].add:"" })
    if(obj[0].rating!=="" && obj[0].rating!==undefined){
      setSelRating({value:obj[0].rating,label:obj[0].rating})
    }
    if(obj[0].cat!=="" && obj[0].cat!==undefined){
      dispatch({ type: "cat", payload:obj[0].cat})
      setSelAccom({value:obj[0].cat,label:obj[0].cat})
    }
  }
  }

  const handleChangeManual = (evt) => {
    const { id, value } = evt.target;
    dispatch({ type: id, payload: value });
}

  const setAccomdation=(obj,idx)=>{
    setSelAccom(obj)
    dispatch({ type: "cat", payload: obj.value})
  }

  const setRtatings=(obj,idx)=>{
    setSelRating(obj)
    dispatch({ type: "rating", payload: obj.value})
  }

  const setAdults = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        room.selAdtsError = false
        return {
            ...room, adt:e.value,selAdts:[{label:e.value,value:e.value}]
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }

  const setPhoneCode=(obj,idx)=>{
    setSelPhc(obj)
    for(let room of state.roomDetails){
      if(room.rmid===idx+1){
          room.pax.phc=obj.value;
          room.paxphcError = false
      }
     }
  }


  const setTitle = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        room.tlError =false;
        return {
            ...room, pax: {
            ...room.pax, tl: e.value,selTl:[{label:e.value,value:e.value}]
            }
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
    
  }

  const updateSup=(obj)=>{
    setSelSup(obj);
    dispatch({ type: "sup", payload: obj.value})
    dispatch({ type: "sup_name", payload: obj.label})
    dispatch({ type: "supError", payload: false})
  }

  const setChilds = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
      let chdList=[]
        for (var i = 0; i < Number(e.value); i++) {
          const req={
            "room":idx+1,
            "chd_count":i,
            "sel_age":[]
          }
          chdList.push(req);
        }
        return {
            ...room, chd:e.value,selChds:[{label:e.value,value:e.value}],childAgeList:chdList
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }

  
  const clearRoom=(room)=>{
    room.adt=1;
    room.chd=0;
    room.chdage="";
    room.rnm="";
    room.sup_ref="";
    room.htl_conf="";
    room.spr="";
    room.pax.fn="";
    room.pax.ln="";
    room.pax.em="";
    room.pax.tl="";
    room.canpolicy="";
    room.imp_info="";
    room.pr.attCommision = "";
    room.ra="";
    room.pr.attBaseFare="";
    room.pr.attTaxes="";
    room.pr.attMarkup=0;
    room.pr.attDisc="";
    room.pr.attAgentDisc=0;
    room.pr.attTotal="";
    room.pr.taMarkup="";
    room.pr.taServiceFee="";
    room.pr.taSupFee="";
    room.pr.taDisc="";
    room.pr.taTotal="";
  }

  const updateRoomData = (idx) => (e) => {
      for(let room of state.roomDetails){
        if(Number(room.rmid)===Number(idx)){
        if(room.isSameRoom){  
          room.isSameRoom=false;
          //clearRoom(room);
          // let newRoomList = state.roomDetails.map((room, sidx) => {
          //   if (idx !== sidx) return room;
          //   else{
          //     return {
          //         ...room, pr: {attBaseFare: "", attTaxes: ""}
          //     }
          //   }
          // });
          // dispatch({ type: "roomDetails", payload: newRoomList})
          setSelTl()
          break;
        }else{
          let oldrm=idx-1;
          let oldRoomData=state.roomDetails.filter((obj)=>Number(obj.rmid)===Number(oldrm));
          room.isSameRoom=true;
          room.rnm=oldRoomData[0].rnm;
          room.attBaseFareError = oldRoomData[0].attBaseFareError
          room.attTaxesError = oldRoomData[0].attTaxesError
          room.canpolicyError = oldRoomData[0].canpolicyError
          room.fnError = oldRoomData[0].fnError
          room.lnError = oldRoomData[0].lnError
          room.rmhtl_confError = oldRoomData[0].rmhtl_confError
          room.rmrnmError = oldRoomData[0].rmrnmError
          room.rmsup_refError = oldRoomData[0].rmsup_refError
          room.selAdtsError = oldRoomData[0].selAdtsError
          room.tlError = oldRoomData[0].tlError
          room.sup_ref=oldRoomData[0].sup_ref;
          room.htl_conf=oldRoomData[0].htl_conf;
          room.spr=oldRoomData[0].spr;
          room.pax=oldRoomData[0].pax;
          room.canpolicy=oldRoomData[0].canpolicy;
          room.imp_info=oldRoomData[0].imp_info;
          room.ra=oldRoomData[0].ra;
          room.pr=oldRoomData[0].pr;
          room.adt=oldRoomData[0].adt;
          room.chd=oldRoomData[0].chd;
          room.chdage=oldRoomData[0].chdage;
          room.selAdts=[{"label":oldRoomData[0].adt,"value":oldRoomData[0].adt}]
          room.selChds=[{"label":oldRoomData[0].chd,"value":oldRoomData[0].chd}]
          room.selTl=[{"label":oldRoomData[0].pax.tl,"value":oldRoomData[0].pax.tl}]
          setSelTl({label:oldRoomData[0].pax.tl,value:oldRoomData[0].pax.tl})
    }
    }
    }
    
  }

  const handleChdAge=(obj,idx,index)=>{
    for(let room of state.roomDetails){
      if(Number(room.rmid)===Number(idx+1)){
        setSelAge(obj)
        if(room.chdage===""){
          room.chdage=obj.value;
        }else{
          room.chdage=room.chdage+","+obj.value;
        }
        for(let age of room.childAgeList){
           if(age.chd_count===index){
              age.sel_age=[obj]
           }
        }
      }
     }
  }

  

  const handleChangePax = (idx) => (e) => {
    const { id, value } = e.target;
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        if(id == "fn"){
          if(value !== ""){
          room.fnError = false
        }
        else{
          room.fnError = true
        }
      }
        if(id == "ln"){
          if(value !== ""){
          room.lnError = false
        }
        else{
          room.lnError = true
        }
      }
        if(id == "em"){
          if(value !== ""){
          room.emError = false
        }
        else{
          room.emError = true
        }
      }
        if(id == "phn"){
          if(value !== ""){
          room.paxphnError = false
        }
        else{
          room.paxphnError = true
        }
      }
      
        return {
            ...room, pax: {
            ...room.pax, [id]: value,
            }
        }
      }
    });
    // newRoomList[0].pax[0].ln = newRoomList[0].pax.ln
    // newRoomList[0].pax[0].em = newRoomList[0].pax.em
    // newRoomList[0].pax[0].fn = newRoomList[0].pax.fn
    // newRoomList[0].pax[0].phc = newRoomList[0].pax.phc
    // newRoomList[0].pax[0].phn = newRoomList[0].pax.phn
    // newRoomList[0].pax[0].tl = newRoomList[0].pax.tl
    // newRoomList[0].pax[0].selTl = newRoomList[0].pax.selTl
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }
  


  // OnChange event we will call this and update the state
  const handleChange = (type, idx) => (e) => {
    try {
      idx=idx+1;
        const { id, value } = e.target;
        for(let room of state.roomDetails){
            if(room.rmid===idx){
              if(type==="rn"){
                room.rnm=value;
                if(value.length == 0){
                  room.rmrnmError = true
                }
                else{
                  room.rmrnmError = false
                }
                break;
              }else if(type==="sup"){
                room.sup_ref=value;
                if(value.length == 0){
                room.rmsup_refError = true
                }
                else{
                  room.rmsup_refError = false
                }
              }else if(type==="conf"){
                room.htl_conf=value;
                if(value.length == 0){
                  room.rmhtl_confError = true
                  }
                  else{
                    room.rmhtl_confError = false
                  }
              }else if(type==="spr"){
                room.spr=value;
              }else if(type==="policy"){
                room.canpolicy=value;
                if(value.length == 0){
                  room.canpolicyError = true
                  }
                  else{
                    room.canpolicyError = false
                  }
              }else if(type==="info"){
                room.imp_info=value;
              }else if(type==="rfundable"){
                room.rfundable=e.target.checked;
              }else if(type==="bb"){
                room.ra=value;
              }
              // }else if(type==="fn"){
              //   room.pax.fn=value;
              // }else if(type==="ln"){
              //   room.pax.ln=value;
              // }else if(type==="em"){
              //   room.pax.em=value;
              // }else if(type==="phn"){
              //   room.pax.phn=value;
              // }
            }
        }
        dispatch({ type: id, payload: value });
        console.log("Room List++++++++++++++",state.roomDetails)
    } catch (err) {
      log.error("Exception occured in handleChange function---" + err);
    }
  };
 

  const handleChangePrice = (type,idx) => (e) => {
    const { id, value } = e.target;
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else if(type==="ATT"){
        if(id == "attBaseFare"){
        if(value.length == 0){
          room.attBaseFareError = true
        }
        else{
          room.attBaseFareError = false
        }
      }
      else if( id== "attTaxes"){
        if(value.length == 0){
          room.attTaxesError = true
        }
        else{
          room.attTaxesError = false
        }
      }
        return {
            ...room, pr: {
            ...room.pr, [id]: value,
            }
        }
      }
    });
    for(let room of newRoomList){
      if(Number(room.rmid)===Number(idx+1)){
          room.pr.taTaxes=room.pr.attTaxes;
          room.pr.attTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attDisc) - Number(room.pr.attAgentDisc);
        }
    }
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }


const removeRoom = (index) => () => {
  dispatch({ type: "roomDetails", payload: state.roomDetails.filter((s, sidx) => index !== sidx) });
};


const handleConf = ()=>{
  dispatch({ type: "conf_mail", payload: !state.conf_mail })
}
const handleInv = ()=>{
  dispatch({ type: "inv_mail", payload: !state.inv_mail })
}

const hidemessage =() =>{
  setTimeout(function () {
  dispatch({ type: 'notiMessageShow', payload: false })
  dispatch({ type: 'notiMessage', payload: '' })
  dispatch({ type: 'notiVarient', payload: '' })
}, 5000);
}

const checkErrors=() =>{
  if(state.sup!==""){
    dispatch({ type: 'supError', payload: false })
  }
  else{
    dispatch({ type: 'supError', payload: true })
  }
  
  if(state.hotelData.length===0){
      dispatch({ type: 'destError', payload: true })
    }
    else{
      dispatch({ type: 'destError', payload: false })
    }
  if(state.selHotel.length===0){
    dispatch({ type: 'htnameError', payload: true })
  }
  else{
    dispatch({ type: 'htnameError', payload: false })
  }
  if(startDate===null || startDate===""){
    dispatch({ type: 'checkinError', payload: true })
  }
  else{
    dispatch({ type: 'checkinError', payload: false })
  }
  if(endDate===null || endDate===""){
    dispatch({ type: 'checkoutError', payload: true })
  }
  else{
    dispatch({ type: 'checkoutError', payload: false })
  }
  if(state.phno!=="" && !mobileNumberValidation(state.phno)){
    dispatch({ type: 'htphnError', payload: true })
  }
  else{
    dispatch({ type: 'htphnError', payload: false })
  }
//Rooms Validation
for(let room of state.roomDetails){
  if(room.rnm===""){
    room.rmrnmError = true
  }if(state.sup!=="2" && room.sup_ref===""){
    room.rmsup_refError = true
  }if(room.htl_conf===""){
    room.rmhtl_confError = true
  }if(room.pax.tl===""){
    room.tlError = true
  }if(room.pax.fn===""){
    room.fnError = true
  }if(room.pax.ln===""){
    room.lnError = true
  }if(room.pax.em===""){
    room.emError = true
  }if(room.pax.em!=="" && !emailValidation(room.pax.em)){
    room.emvalidError = true
  }if( room.pax.phn===""){
    room.paxphnError = true
  }if(room.pax.phn!=="" && !mobileNumberValidation(room.pax.phn)){
    room.paxphnvalidError = true
  } if(room.pax.phc===""){
    room.paxphcError = true
  }if(room.selAdts.length===0){
    room.selAdtsError = true
  }if(room.canpolicy===""){
    room.canpolicyError = true
  }if(room.pr.attBaseFare==="" || room.pr.attBaseFare==="0"){
    room.attBaseFareError = true
  }if(room.pr.attTaxes==="" || room.pr.attTaxes==="0"){
    room.attTaxesError = true
  }
}
}

const validatePrice=() => {
     checkErrors()
    if(state.region_type==="") {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Region' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        window.scrollTo(0, 0);
        return false;
    }
  if(state.sup===""){
      return false;
  }
else if(state.hotelData.length===0){

      return false;
  }else if(state.selHotel.length===0){
      return false;
  }else if(startDate===null || startDate===""){
    return false;
   }else if(endDate===null || endDate===""){
    return false;
}else if(state.phno!=="" && !mobileNumberValidation(state.phno)){
  return false;
}
//Rooms Validation
for(let room of state.roomDetails){
  if(room.rnm===""){
    return false;
  }else if(state.sup!=="2" && room.sup_ref===""){
    return false;
  }else if(room.htl_conf===""){
    return false;
  }else if(room.pax.tl===""){
    return false;
  }else if(room.pax.fn===""){
    return false;
  }else if(room.pax.ln===""){
    return false;
  }else if(room.rmid===1 && room.pax.em===""){
    return false;
  }else if(room.rmid===1 && room.pax.em!=="" && !emailValidation(room.pax.em)){
    return false;
  }else if(room.rmid===1 && room.pax.phn===""){
    return false;
  }else if(room.rmid===1 && room.pax.phn!=="" && !mobileNumberValidation(room.pax.phn)){
    return false;
  }else if(room.rmid===1 && room.pax.phc===""){
    return false;
  }else if(room.selAdts.length===0){
    return false;
  }else if(room.canpolicy===""){
    return false;
  }else if(room.pr.attBaseFare==="" || room.pr.attBaseFare==="0"){
    return false;
  }else if(room.pr.attTaxes==="" || room.pr.attTaxes==="0"){
    return false;
  }
}
if(state.payment_details.is_pay_uniq_id && (state.payment_details.pay_uniq_id=="" || state.payment_details.paid_amount==="")){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please check Payment UID details and proceed.' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    window.scrollTo(0, 0);
    return false;
  }
  if(state.payment_details.is_bank_transfer && (state.payment_details.bank_name==="" || state.payment_details.ac_number==="" || state.payment_details.total_amt==="")){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please enter Bank Transfer details and proceed.' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    window.scrollTo(0, 0);
    return false;
  }
  if(state.payment_details.is_cheque && (state.payment_details.che_bank_name==="" || state.payment_details.che_ac_number==="" || state.payment_details.che_total_amt==="")){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please enter Cheque details and proceed.' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    window.scrollTo(0, 0);
    return false;
  }
  return true;
}

const continueBooking = async ()=>{
  setShowPopUp(false);
  try {
    if(DateUtils.convertToDateHifen(startDate)!==DateUtils.convertToDateHifen(endDate)){     
        dispatch({ type: 'isItineraryLoading', payload: true })
        setIsLoad(true)
        let dt=moment(startDate).format('YYYY-MM-DD');
        let time=moment(startDate).format('HH:mm:ss');
        let adt=moment(endDate).format('YYYY-MM-DD');
        let atime=moment(endDate).format('HH:mm:ss');
        const htldtls={
          cin: dt+" "+time,
          cout: adt+" "+atime,
          htnm: state.selHotel[0].label,
          htimg: state.hotel_img,
          pid: state.selHotel[0].value,
          add: state.add,
          city: state.dest_obj.text,
          country: state.dest_obj.country+","+state.dest_obj.countryCode,
          cityId:state.dest_obj.externalId,//_id
          landmark: state.landmark,
          rating: state.rating,
          category:{
            "id": "1",
            "name":state.cat,
          },
          phone_no: state.phno
        }
        const roomDetails = state.roomDetails.map(room => ({
          ...room, 
          pax: [
            {
              sno: room.pax[0].sno,
              ty: room.pax[0].ty,
              plbl: room.pax[0].plbl,
              lead: room.pax[0].lead,
              fn: room.pax.fn,
              trave_id: room.pax[0].trave_id,
              tl: room.pax.tl,
              selTl: room.pax.selTl,
              ln: room.pax.ln,
              em: room.pax.em,
              phc: room.pax.phc,
              phn: room.pax.phn
            }
          ],
        }));
       const payload={
        "pbreq": {
          "cha": "B2C",
          "cur": state.cur,
          "cid": FLIGHT_SEARCH_CID,
          "cname": "QA b2c",
          "sty": "normal",
          "ctid": state.dest_obj.expediaCityId,
          "utype": state.isRegister?"Registered":"Guest",
          "client_ip": await publicIpv4(),
          "sup": state.sup,
          "sup_name": state.sup_name,
          "bkby": context.logindata.nm,
          "bkby": context.logindata.fname + " "+context.logindata.lname,
          "bktype" :"MANUAL",
          "crby": context.logindata.user_id,
          "user_id" :context.logindata.user_id,
          "nonights":state.nights,
          // "roompax": [{...state.roomDetails[0], pax:[{...state.roomDetails[0].pax[0]}]}],
          "roompax": roomDetails,          
          "rooms":createRoomList(),
          "pay":createPayObj(),
          "htldtls":htldtls,
          "conf": state.conf_mail,
          "inv": state.inv_mail,
          "region_type":state.region_type,
          "pay_uniq_id": state.pay_uniq_id,
          "bkby_name" :context.logindata.fname + " "+context.logindata.lname,
          "region_cur":"QAR",
          "req_cur":"QAR",
          "sup_base_exrate":1,
          "dest_sup_base_exrate":1,
          "base_sup_exrate":1,
          "dest_base_sup_exrate":1,
          "base_usr_exrate":1,
          "base_region_exrate":1,
          "is_voucher_mail": state.conf_mail,
          "is_invoice_mail": state.inv_mail,
       }
       }
      console.log("Hotel Manual Prebook request : "+JSON.stringify(payload))
      ManageBookingService.saveHotelManualBooking(payload).then((response) => {
        console.log("Prebook response : " + JSON.stringify(response));
        if (response.data.suc !== undefined && response.data.res !== null && response.data.res.pbres!==undefined) {
          if (response.data.res.pbres.status !== undefined && response.data.res.pbres.status === "inserted") {
            console.log("Created TRIP ID: " + response.data.res.pbres.bookingId);
            dispatch({ type: 'isItineraryLoading', payload: false })
            setIsLoad(false)
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Booking created successfully with '+response.data.res.pbres.bookingId})
            dispatch({ type: 'notiVarient', payload: 'success' })
            hidemessage();
            setTimeout(function () {
              window.location="/crm/manage-hotel";
             }, 5000);
          }else{
            dispatch({ type: 'isItineraryLoading', payload: false })
            setIsLoad(false)
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Persistance Failed..Please try again later'})
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
          }
          window.scrollTo(0, 0);
        } else {
          dispatch({ type: 'isItineraryLoading', payload: false })
          setIsLoad(false)
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Persistance Failed..Please try again later'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          window.scrollTo(0, 0);
          hidemessage();
        }
      }).catch(function (error) {
        setIsLoad(false)
        console.error(
          "Exception occured in hotel save function---" + error
        );
      });
     }else{
        setIsLoad(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Check-In and Check-Out dates cannot be same'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        window.scrollTo(0, 0);
        hidemessage();
     }
    
  } catch (err) {
    setIsLoad(false)
    log.error("Exception occured in continueBooking function---" + err);
  }
}


const createRoomList=()=>{
  let finalRooms=[];
  for(let rm of state.roomDetails){
  const req={
  "count": rm.rmindex,
  "rmindex": rm.rmindex,
  "rmimg": "",
  "imgecnt": "",
  "adt": rm.adt,
  "chd": rm.chd,
  "chdage": "1 ",
  "rmid": rm.rmindex,
  "rnm": rm.rnm,
  "hs": 0,
  "ocp": rm.adt+"-"+rm.chdage,
  "note": rm.imp_info,
  "rmpr": [
    {
      "tid": "",
      "pid": state.selHotel[0].value,
      "rtid": "",
      "refundable": rm.rfundable,
      "freeCancelDate":null,
      "rsize": "",
      "ra": [{
        id:0,
        name:rm.ra
      }],
      "bed": "",
      "canpol": [rm.canpolicy],
      "available_rooms": "",
      "servicefee": [
        {
          "type": "CNT",
          "servicefee_name": "CNT Manual Service Fee",
          "apl_on": "Per Booking",
          "sf": Number(rm.pr.attServiceFee),
          "sup_fee": 0
        }
      ],
      "pr": [
        {
          "tf": Number(rm.pr.attTotal),
          "bf": Number(rm.pr.attBaseFare),
          "tax": [
            {
              "OT": Number(rm.pr.attTaxes),
              "SUP_OT":Number(rm.pr.attTaxes)
            },
            {
              "attmkp": Number(rm.pr.attMarkup)
            },
            {
              "attdisc": Number(rm.pr.attDisc)
            }
          ],
          "per_night": Number(rm.pr.attTotal),
          "ty": state.cat,
          "disc": Number(rm.pr.attDisc),
          "dwtf": Number(rm.pr.attTotal) + Number(rm.pr.attDisc),
          "cur": state.cur,
          "sup_fare": rm.pr.attBaseFare+"_"+rm.pr.attTotal,
          "package": false,
          "ezy_deal": false,
          "market_fee": Number(rm.pr.attCommision),
          "sup_market_fee": rm.pr.attCommision,
          "sup_disc": 0,
          "sup_dwtf": 0,
          "sup_usr_exc": 1,
          "sup_usr_com_exc": 1,
          "rtf": Number(rm.pr.attTotal),
          "rdwtf": Number(rm.pr.attTotal) + Number(rm.pr.attDisc),
          "com_disc": Number(rm.pr.attAgentDisc),
          "markupnames": createMarkupname(),
          "gst":"",
          "gst_total":""
        }
      ]
    }
  ],
  "selected1": true,
  "stat": "Confirmed",
  "confirmnum": rm.sup_ref,
  "htlrefnum": rm.htl_conf
  }
  finalRooms.push(req);
}
return finalRooms;
}

const createMarkupname=()=>{
  let markNames=[];
  if(state.attMarkup!==0){
    const att={
     "code":"CNT",
     "name" : "CNT Manual Markup"
    }
    markNames.push(att);
  }
  return markNames;
}

const createPayObj=()=>{
    let payObj=[];
    let uniq_pay="";
    let bank_pay="";
    let che_pay="";
    if(state.payment_details.is_pay_uniq_id){
        dispatch({ type: 'pay_uniq_id', payload: state.payment_details.pay_uniq_id })
        uniq_pay={
          pay_uniq_id:state.payment_details.pay_uniq_id,
          ptype: "Pay UID",
          amt: state.payment_details.paid_amount,
          psta: "Confirmed"
        }
        payObj.push(uniq_pay);
    }
    if(state.payment_details.is_bank_transfer){
      bank_pay={
        bank_name:state.payment_details.bank_name,
        ac_number:state.payment_details.ac_number,
        cust_name:state.payment_details.cust_name,
        trans_number:state.payment_details.trans_number,
        date_of_transfer:state.payment_details.date_of_transfer,
        psta: "Confirmed",
        amt: state.payment_details.total_amt,
        ptype: "Bank Transfer",
      }
      payObj.push(bank_pay);
    }
    if(state.payment_details.is_cheque){
        che_pay={
        bank_name:state.payment_details.che_bank_name,
        ac_number:state.payment_details.che_ac_number,
        trans_number:state.payment_details.che_trans_number,
        psta: "Confirmed",
        ptype: "Cheque",
        amt: state.payment_details.che_total_amt,
      }
       payObj.push(che_pay);
    }
    
   return payObj;
  }


const updatePayment=(data)=>{
    dispatch({ type: 'payment_details', payload: data })
  }

const updateFileName=(url)=>{
  dispatch({ type: "hotel_img", payload: url });
}

const handleCheckin = (date)=>{
  setStartDate(date)
  dispatch({ type: "checkinError", payload: false });
  if(endDate!==undefined && date!==undefined && date !== null && endDate!==""){
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
const a = new Date(date),
    b = new Date(endDate)
    let difference = dateDiffInDays(a, b);
    dispatch({ type: "nights", payload: difference})
  }
}

const setRegion = (data) => {
    dispatch({ type: 'region_type', payload: data.value })
    if(data.value==="India"){
      dispatch({ type: 'cur', payload: "INR" })
    }else{
      dispatch({ type: 'cur', payload: "QAR" })
    }
    dispatch({ type: "selRegion", payload: [data] })
  }

  const handleUserType = () => {
    if (state.isRegister) {
      dispatch({ type: 'isRegister', payload: false });
    } else {
      dispatch({ type: 'isRegister', payload: true });
    }
  }

  const updateProfile=(data)=>{
    dispatch({ type: 'prof', payload: data})
    const payload = {usr_uniq_id:data.uniq_id};
    let tokenKey = Utilities.generateAPIToken(payload);
    ManageBookingService.fetchTravellers(payload,tokenKey).then((response) => {
        if(response.data !== undefined && response.data.suc){
          let list = response.data.res.filter(
            (pax) => pax.pax_type === "ADT"
          );
          dispatch({ type: "adtList", payload: list });
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "Fetched the Travellers data"})
          dispatch({ type: 'notiVarient', payload: 'success' })
          hidemessage();
      }else{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: "Unable to fetch the Travellers details."})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
    }).catch(function (error) {
      console.error(
        "Exception occured in travel data function---" + error
      );
    });
  }


const showConfirmPop = ()=>{
    if (validatePrice()) {
      setShowPopUp(true);
    }
  }

  const closePopUP=()=>{
    setShowPopUp(false);
    setIsLoad(false)
  }

  const handlepaxChange = (e,idx) => {
    if (e[0] !== undefined && e[0].value !== undefined) {
        for(let room of state.roomDetails){
          if(room.rmid===idx+1){
            room.pax.selTl = [{ "value": e[0].title, "label": e[0].title }];
            room.pax.tl =e[0].title;
            room.pax.fn = e[0].fname;
            room.pax.ln = e[0].lname;
            room.pax.em = e[0].email;
            room.pax.phc = e[0].phn_code;
            room.pax.phn = e[0].ph_num;
            room.pax.trave_id = e[0].value;
            stdCodes.map((std, sidx) => {
            if(e[0].phn_code!=="" && (std.value == e[0].phn_code)) {
                setSelPhc([{ "value": std.name, "label": std.name }])
              }
              });
        }
      }
        dispatch({ type: 'selpaxfn', payload: e })
    } else {
        dispatch({ type: 'paxfn', payload: e })
        dispatch({ type: 'selpaxfn', payload: [] })
        for(let room of state.roomDetails){
          if(room.rmid===idx+1){
            room.pax.fn = e;
        }
      }
    }
}

    return (
        <>
            
            <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
              ) : ""}
            <div className="FlighManual HotelManual">
                    
                    <form>
                    <div className="FLightBoT">
                    <h5 className="layoutTitle  d-flex justify-content-between mb-0"><span>Manage Booking / Hotel / New Booking</span></h5>
                     {regionTypes.length > 0 &&
                    <Form.Row>
                        <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                        <Select
                            options={regionTypes}
                            searchable={false}
                            value={state.selRegion}
                            onChange={(value) => setRegion(value)}
                            placeholder="Select Country"
                            className="regionDrop"
                            classNamePrefix='filter'
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                              },
                            })}

                        />
                        </Form.Group>
                       </Form.Row>
                       }
                       </div>
                             <div className="BookingDetails buttonGrop mt-3">

                             <Form.Row>
                        <Form.Group as={Col} xs={3} controlId="ttype" className="inputCheck pt-0 mb-0">
                              <div className="d-flex align-items-center registerGuest">
                              <label>
                                <input type="radio" id="register" className="ml-0" name="applicableOn" checked={state.isRegister} custom onChange={handleUserType} />Registered User
                                </label>
                                <label>
                                <input type="radio" id="guest" className="ml-3" name="applicableOn" checked={!state.isRegister} custom onChange={handleUserType} />
                                Guest User 
                                </label>
                            </div>
                        
                            </Form.Group>
                        </Form.Row>
                            
                     
                        {state.isRegister &&
                           <>
                            <h5 className="layoutTitle d-flex justify-content-between mb-3 mt-4"><span>Profile Details</span></h5>
                            <ProfileDetails sendProfile={updateProfile}/>
                            </>
                        }
                         </div>
                    </form>
                 
                <div className="BookingDetails buttonGrop mt-3">
                    <>
                    <h5 className="layoutTitle  d-flex justify-content-between pb-2"><span>Hotel Bookings</span></h5>

                    <Form.Row>
                            <Form.Group className="selectplugin scrollHandle pb-0 mb-0" as={Col} xs={3} >
                            <Form.Label>Supplier <sup>*</sup></Form.Label>
                               <Select
                                    options={supplierlist}
                                    searchable={false}
                                    value={selSup}
                                    isDisabled={state.disable}
                                    onChange={(value) => updateSup(value)}
                                    classNamePrefix='filter'
                                    theme={theme => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#F0F0F0',
                                        primary: '#3156ab',
                                        cursor: 'pointer',
                                      },
                                    })}
                                />
                           
                                {state.supError && (
                                <p className="vError">Please select Supplier</p>
                                )}
                            </Form.Group>
                        </Form.Row>
                        <hr/>
              <div className="destination hotelMannual">
              <h5 className="layoutTitle  d-flex justify-content-between pb-2"><span>Accommodation Details</span></h5>
              <Form.Row>
              <Form.Group as={Col} xs={3} controlId="destName" className="selectplugin form-group col-3">
              <div className={"addInput origin" + (originIsFocus ? " active" : "")} >

              <Form.Label>Destination<sup>*</sup></Form.Label>
              <Form.Control type="text" disabled={state.disable} autoComplete="off" title={(state.destName)} value={state.destName}
              placeholder="City or Hotel Name"  onChange={handleChangOrigin} ref = {destination} />
              {state.cleardb  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearDestination}
                variant="link">
              </Button>
            }
               </div>
               {state.destError && (
                      <p className="vError">Please select Destination</p>
                    )}
              {state.hotelPredectiveDropDown &&
            
             <div className={state.hotelData!==undefined && state.hotelData.length!==0 && state.hotelData[0].city.length!==0 ? "pdtSerchPart cityNam":"pdtSerchPart nocity"}>
              <Scrollbars style={{ height: 300 }}>
                <div className="searchContent">
             {state.hotelData!==undefined && state.showResults && state.hotelData.length!==0 &&

                <>
                {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                {state.hotelData!==undefined && state.hotelData[0].city.length===0 && state.hotelData[0].landmark.length ===0 && state.hotelData[0].area.length ===0 && state.hotelData[0].property.length ===0 && <p className="nomachFound">
                  No matches found.
                  </p>}
                  <Row>
                {state.hotelData[0].city!==undefined && state.hotelData[0].city.length!==0 &&
                  <Col className="pr-2">
                  {state.hotelData[0].city.map((cty, index) => (
                    <>
                    {index===0 &&
                    <>
                      <h4 className="typ">City</h4>
                      </>
                    }
                    <div className="plc ezyIcon icon-map" onClick={handleOnOrigin(cty,"city")}>
                    {cty.description!==null &&
                      <span  className="plcNm" title={cty.description}> {cty.description}</span>
                    }
                    {cty.description==null &&
                      <span  className="plcNm"title={cty.text + cty.country} > {cty.text}, {cty.country}</span>
                    }
                    </div>
                    </>
                  ))}

                  </Col>
                 }
                </Row>
                </>
             }
             {state.hotelData.length===0 &&
             <h4 className="typ">No Results Found</h4>
             }
             </div>
          </Scrollbars>
             </div>

              }
          </Form.Group>
                </Form.Row>

              </div>
           </>

                          <Form.Row>
                          <Form.Group
                                as={Col}
                                controlId="fn"
                                className="selectplugin"
                                xs={3}
                              >
                                  <Form.Label>
                                  Hotel Name<sup>*</sup>
                                </Form.Label>
                                <Typeahead
                                id="basic-typeahead-single"
                                labelKey={htl => `${htl.label}`}
                                allowNew
                                readOnly
                                disabled={state.disable}
                                onChange={htl => handleHtlChange(htl)}
                                options={hotelData}
                                placeholderText="Enter Hotel Name"
                                selected={state.selHotel}
                              />
                              
                                {state.htnameError && (
                      <p className="vError">Please select Hotel Name</p>
                    )}
                              </Form.Group>
                              <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} >
                              <Form.Label>Accomdation Type</Form.Label>
                              <Select
                                  options={accomTypes}
                                  searchable={false}
                                  value={selAccom}
                                  isDisabled={state.disable}
                                  onChange={(value) => setAccomdation(value)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                             
                            </Form.Group>
                            <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} >
                            <Form.Label>Star Rating</Form.Label>
                              <Select
                                  options={ratings}
                                  searchable={false}
                                  isDisabled={state.disable}
                                  value={selRating}
                                  onChange={(value) => setRtatings(value)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                               
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} xs={3} className="selectplugin datePicker arrivalTime" controlId="from">
                            <Form.Label>
                              Check In<sup>*</sup>
                            </Form.Label>
                              <DatePicker
                              // selected={arrival_date}
                                      onChange={date=> handleCheckin(date)}
                                      selected={startDate}
                                      showTimeSelect
                                      placeholderText="DD-MM-YYYY"
                                      timeFormat="p"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="Pp"
                                      disabled = {state.disable}
                                      popperModifiers={{
                                          flip: {
                                              behavior: ['bottom'] // don't allow it to flip to be above
                                          },
                                          preventOverflow: {
                                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                          },
                                          hide: {
                                              enabled: false // turn off since needs preventOverflow to be enabled
                                          }
                                        }}
                                  fromDate={startDate}
                                  toDate={toDate}
                                  minDate={ new Date()}
                                  maxDate={endDate !== "" && endDate }
                          />

                        
                            {state.checkinError && (
                      <p className="vError">Please select Checkin Date</p>
                    )}
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                          <Form.Group as={Col} xs={3} className="selectplugin datePicker arrivalTime" controlId="from">
                          <Form.Label>
                            Check Out<sup>*</sup>
                          </Form.Label>
                          <DatePicker
                          // selected={arrival_date}
                                  onChange={handleCheckout}
                                  selected={endDate}
                                  showTimeSelect
                                  placeholderText="DD-MM-YYYY"
                                  timeFormat="p"
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  disabled = {state.disable}
                                  dateFormat="Pp"
                                  popperModifiers={{
                                      flip: {
                                          behavior: ['bottom'] // don't allow it to flip to be above
                                      },
                                      preventOverflow: {
                                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                      },
                                      hide: {
                                          enabled: false // turn off since needs preventOverflow to be enabled
                                      }
                                    }}
                              fromDate={startDate}
                              minDate={startDate == "" ? new Date(moment().startOf('month')) : startDate}
                      />
                          
                          {state.checkoutError && (
                      <p className="vError">Please select Checkout Date</p>
                    )}
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>

                              <Form.Group as={Col} xs={3} controlId="nights" className="selectplugin">
                        <Form.Label>Number of Nights</Form.Label>
                          <Form.Control type="text" value={state.nights} disabled={true} placeholder="Number of Nights" autoComplete="off" />
                   
                        </Form.Group>
                                </Form.Row>
                        <Form.Row>
                       
                        <Form.Group as={Col} xs={3} controlId="landmark" className="selectplugin">
                        <Form.Label>Land Mark</Form.Label>
                          <Form.Control type="text" value={state.landmark} disabled={state.disable} onChange={handleChangeManual} placeholder="Land Mark" autoComplete="off" />
                          
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="phno" className="selectplugin">
                        <Form.Label>Phone Number<sup>*</sup></Form.Label>
                          <Form.Control type="text" value={state.phno} disabled={state.disable} onChange={handleChangeManual} placeholder="Phone Number" autoComplete="off" />
                          
                          {state.htphnError && (
                      <p className="vError">Please select Valid Phone Number</p>
                    )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="add" className="selectplugin">
                        <Form.Label>Hotel Address</Form.Label>
                          <Form.Control type="textarea" value={state.add} disabled={state.disable} onChange={handleChangeManual} placeholder="Hotel Address" autoComplete="off" />
                    
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>

                    <Form.Group as={Col} xs={12} className="pb-0 mb-0">
                        <FileUpload reportUrls={state.logo_url} type={"Hotel"} sendFileUrl={updateFileName} disabled = {state.disable}/>
                        </Form.Group>
                    </Form.Row>

                </div>
                {/* ROOM DETAILS */}
                {state.roomDetails.map((room, idx) => (
                  <React.Fragment key={idx}>
                <div className="BookingDetails buttonGrop mt-3 roomdeTailss">
                    <h5 className="layoutTitle  d-flex justify-content-between mb-3"><span>Room {idx+1} Details</span></h5>
                        <Form.Row>
                            <Form.Group as={Col} xs={3} controlId="rnm" className="selectplugin">
                            <Form.Label>Room Name <sup>*</sup></Form.Label>
                                <Form.Control type="text" disabled={state.disable} value={room.rnm}
                              onChange={handleChange("rn", idx)}  placeholder="Enter Room Type"
                              autoComplete="off" />
                             
                                
                                {room.rmrnmError == true && (
                      <p className="vError">Please select room name</p>
                    )}
                            </Form.Group>
                            {state.sup!=="2" &&
                            <Form.Group className="selectplugin" as={Col} xs={3} >
                              <Form.Label>Supplier Reference No <sup>*</sup></Form.Label>
                            <Form.Control type="text" disabled={state.disable} value={room.sup_ref} onChange={handleChange("sup", idx)} placeholder="Supplier Ref No"
                              autoComplete="off" />
                                
                                                                {room.rmsup_refError == true && (
                      <p className="vError">Please enter Supplier Ref No</p>
                    )}
                            </Form.Group>
                            }
                            {idx>=1 &&
                            <Form.Group className="selectplugin SameasRoomone" as={Col} xs={3} >
                            <Form.Check type="checkbox" id={"rm"+idx+1}
                            label={"Same as Room"+idx}
                            disabled={state.disable}
                            value={room.isSameRoom} checked={room.isSameRoom} onChange={updateRoomData(idx+1)} custom />
                            </Form.Group>
                            }
                        </Form.Row>
                        <div className="sepRationbrd"></div>
                        <Form.Row>
                        <Form.Group className="selectplugin" as={Col} xs={3} >
                        <Form.Label>Title <sup>*</sup></Form.Label>
                              <Select
                                  options={title}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  value={room.pax.selTl}
                                  onChange={setTitle(idx)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                           
                                {room.tlError == true && (
                      <p className="vError">Please select Title</p>
                    )}
                            </Form.Group>
                            <Form.Group as={Col} className="selectplugin" xs={3}>
                            <Form.Label>First Name <sup>*</sup></Form.Label>
                            <Typeahead
                                id="pax_fn"
                                labelKey={prof => `${prof.fname}`}
                                options={state.adtList && state.adtList.length !== 0 ? state.adtList : [] }
                                placeholder="First Name"
                                // selected={state.selpaxfn}
                                value={room.pax.fn}
                                onInputChange={(e) => handlepaxChange(e,idx)}
                                onChange={pax => handlepaxChange(pax,idx)}
                                />
                                {room.fnError == true && (
                               <p className="vError">Please enter First Name</p>
                               )}
                              </Form.Group>
                              {/* <Form.Group as={Col} xs={3} controlId="fn" className="selectplugin">
                              <Form.Label>First Name<sup>*</sup></Form.Label>
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.fn} onChange={handleChangePax(idx)}  placeholder="First Name" autoComplete="off" />
                                  
                                  {room.fnError == true && (
                      <p className="vError">Please enter First Name</p>
                    )}
                              </Form.Group> */}
                              <Form.Group as={Col} xs={3} controlId="ln" className="selectplugin">
                              <Form.Label>Last Name<sup>*</sup></Form.Label>
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.ln} onChange={handleChangePax(idx)}  placeholder="Last Name" autoComplete="off" />
                                  
                                  {room.lnError == true && (
                      <p className="vError">Please enter Last Name</p>
                    )}
                              </Form.Group>
                              {idx==0 &&
                                <Form.Group as={Col} xs={3} controlId="em" className="selectplugin">
                                   <Form.Label>Email <sup>*</sup></Form.Label>
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.em} onChange={handleChangePax(idx)} placeholder="Email" autoComplete="off" />
                                 
                                  {room.emError == true && (
                      <p className="vError">Please enter Email</p>
                    )}
                                  {room.emvalidError == true && (
                      <p className="vError">Please enter valid Email</p>
                    )}
                              </Form.Group>
                              }
                              </Form.Row>
                              <Form.Row>
                              {idx==0 &&
                              <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} >
                                <Form.Label>Phone Code<sup>*</sup></Form.Label>
                              <Select
                                    options={state.stdCodeMenu}
                                    searchable={false}
                                    value={selPhc}
                                    isDisabled ={state.disable}
                                    onChange={(value) => setPhoneCode(value,idx)}
                                    classNamePrefix='filter'
                                    theme={theme => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#F0F0F0',
                                        primary: '#3156ab',
                                        cursor: 'pointer',
                                      },
                                    })}
                                />
                                
                                {room.paxphcError == true && (
                      <p className="vError">Please enter Phone Code</p>
                    )}
                                  </Form.Group>
                               }
                              {idx==0 &&
                              <Form.Group as={Col} xs={3} controlId="phn" className="selectplugin">
                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                              <Form.Control type="text" disabled={state.disable} value={room.pax.phn} onChange={handleChangePax(idx)} placeholder="Phone Number" autoComplete="off" />
                              
                              {room.paxphnError == true && (
                      <p className="vError">Please enter Phone Number</p>
                    )}
                              </Form.Group>
                               }
                              <Form.Group as={Col} xs={3} controlId="ra" className="selectplugin">
                              <Form.Label>Board Type</Form.Label>
                                      <Form.Control type="text" disabled={state.disable} value={room.ra} onChange={handleChange("bb", idx)} placeholder="Board Type" autoComplete="off" />
                                      
                                  </Form.Group>
                              <Form.Group as={Col} xs={3} controlId="htl_conf" className="selectplugin">
                              <Form.Label>Hotel Conf#<sup>*</sup></Form.Label>
                                      <Form.Control type="text" disabled={state.disable} value={room.htl_conf} onChange={handleChange("conf", idx)} placeholder="Hotel Conf#" autoComplete="off" />
                                      
                                      {room.rmhtl_confError == true && (
                                 <p className="vError">Please Enter Hotel Conf#</p>
                               )}
                                  </Form.Group>
                              </Form.Row>
                               <Form.Row>
                              
                                  <Form.Group className="selectplugin" as={Col} xs={3} >
                                  <Form.Label>Adult (12+)<sup>*</sup></Form.Label>
                                  <Select
                                  options={adutlsList}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  placeholder="Select"
                                  value={room.selAdts}
                                  onChange={setAdults(idx)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                                       
                                          {room.selAdtsError == true && (
                                    <p className="vError">Please select Adults</p>
                                      )}
                                      </Form.Group>
                                      </Form.Row>
                                      <Form.Row>
                                  <Form.Group className="selectplugin" as={Col} xs={3}>
                                  <Form.Label>Children (0-11)</Form.Label>
                                  <Select
                                  options={chdsList}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  placeholder="Select"
                                  value={room.selChds}
                                  onChange={setChilds(idx)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                              
                                  </Form.Group>
                                  {room.childAgeList!==undefined && room.childAgeList.map((rm, index) => (
                                  <React.Fragment key={index}>
                                  <Form.Group className="selectplugin ageChild" as={Col}>
                                  <Form.Label>Age of Child{index+1}</Form.Label>
                                  <Select
                                  isDisabled ={state.disable}
                                  options={ChildernAge}
                                  searchable={false}
                                  value={rm.sel_age}
                                  onChange={(value) => handleChdAge(value,idx,index)}
                                  classNamePrefix='filter'
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#F0F0F0',
                                      primary: '#3156ab',
                                      cursor: 'pointer',
                                    },
                                  })}
                                />
                                 
                                  </Form.Group>
                                  </React.Fragment>
                                  ))}
                                  </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="spr" className="selectplugin">
                                    <Form.Label>Special Request</Form.Label>
                                            <Form.Control as="textarea" value={room.spr} 
                                            placeholder="Special Request" disabled={state.disable} onChange={handleChange("spr", idx)} autoComplete="off" />
                                           
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3} controlId="imp_info" className="selectplugin">
                                        <Form.Label>Important Details</Form.Label>
                                            <Form.Control as="textarea" disabled={state.disable} value={room.imp_info} onChange={handleChange("info", idx)} placeholder="Important Details" autoComplete="off" />
                                          
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3} controlId="canpolicy" className="selectplugin">
                                        <Form.Label>Cancellation Policy<sup>*</sup></Form.Label>
                                            <Form.Control as="textarea" disabled={state.disable} value={room.canpolicy} onChange={handleChange("policy", idx)} placeholder="Cancellation Policy" autoComplete="off" />
                                         
                                            {room.canpolicyError == true && (
                                             <p className="vError">Please enter Cancellation Policy</p>
                                             )}
                                        </Form.Group>
                                        <Form.Group className="m-0" as={Col}>
                                        <Form.Label className="refundable">Cancellation Policy<sup>*</sup></Form.Label>
                            <Form.Check type="checkbox" id="rfundable" label="Refundable" disabled={state.disable}
                              value={room.rfundable} onChange={handleChange("rfundable", idx)} custom />
                        </Form.Group>
                                    </Form.Row>
                     
              
                            <h5 className="layoutTitle  d-flex justify-content-between mt-4"><span>Price Details ({state.cur})</span></h5>
                            <div className="table-responsive">
                            <table class="m-0 table passDetails manHot">
                            <tr>
                            <th width="15%">Room Type</th>
                            <th width="15%">Base Fare<sup>*</sup></th>
                            <th width="10%">Taxes<sup>*</sup></th>
                            <th width="10%">Markup</th>
                            <th width="10%">Service Fee</th>
                            {/* <th width="10%">Commision</th> */}
                            <th width="10%">Discount(-)</th>
                            <th width="20%">Agent Discount(-)</th>
                            <th width="10%">Total Amount</th>
                        </tr>
                        <tr>
                        <td width="15%"><span>{room.rnm}</span></td>
                        <td width="15%">
                          <Form.Group as={Col}  controlId={"attBaseFare"} className="ml-0 pl-0"> 
                            <Form.Control type="number" disabled={state.disable} min={0} value={room.pr.attBaseFare} placeholder="Base Fare" autoComplete="off"  onChange={handleChangePrice("ATT",idx)}/>
                            {room.attBaseFareError == true && (
                      <p className="vError">Please enter Base Fare</p>
                    )}
                        </Form.Group>

                        </td>
                        <td width="10%">
                          <Form.Group as={Col}  controlId={"attTaxes"} className="ml-0 pl-0">
                            <Form.Control type="number" disabled={state.disable} min={0} value={room.pr.attTaxes}  placeholder="Tax Fare" autoComplete="off"  onChange={handleChangePrice("ATT",idx)} />
                            {room.attTaxesError == true && (
                            <p className="vError">Please enter Tax Fare</p>
                             )}
                        </Form.Group>
                        </td>
                        <td width="10%">
                          <Form.Group as={Col}  controlId="attMarkup" className="ml-0 pl-0">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attMarkup} onChange={handleChangePrice("ATT",idx)} />
                        </Form.Group>
                        </td>
                        <td width="10%">
                          <Form.Group as={Col}  controlId="attServiceFee" className="ml-0 pl-0">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Service Fee" autoComplete="off"  value={room.pr.attServiceFee} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>
                        </td>
                        {/* <td width="10%">
                        <Form.Group as={Col} controlId="attCommision" className="ml-0 pl-0">
                          <Form.Control type="number" disabled={state.disable} min={0} placeholder="Base Fare" autoComplete="off" value={room.pr.attCommision} onChange={handleChangePrice("ATT", idx)} />
                        </Form.Group>

                      </td> */}
                        <td width="10%">
                          <Form.Group as={Col}  controlId="attDisc" className="ml-0 pl-0">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Disc" autoComplete="off"  value={room.pr.attDisc} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>
                        </td>
                        <td width="20%">
                          <Form.Group as={Col}  controlId="attAgentDisc" className="ml-0 pl-0">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Disc" autoComplete="off"  value={room.pr.attAgentDisc} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>
                        </td>
                        
                        <td width="10%">
                          <div className="deletB">
                          <Form.Group as={Col} controlId="attTotal" className="mb-0 ml-0 pl-0">
                          <Form.Control  disabled={true}   placeholder="Disc" value={room.pr.attTotal}/>
                        </Form.Group>

                
                </div>
                  </td>
                   </tr>
              </table>
            </div>
        
            <div className="text-right pb-4 deleTroom">
                {(idx > 0) ? (
                    <Col>
                        <Button
            size="xs"
            variant="outline-secondary"
            className="ezyIcon add ezyIcon icon-delete align-self-end"
            type="submit"
            isDisabled={state.disable}
            onClick={removeRoom(idx)}
        > <span className="ml-2">Remove Room</span></Button>
        
                    </Col>
                ) : ''}
                </div>
              </div>
                </React.Fragment>
                ))}
    <div className="d-flex justify-content-end fetchUdib addRom">
        <Button
            size="xs"
            variant="secondary"
            className="ezyIcon icon-plus"
            type="submit"
            isDisabled={state.disable}
            onClick={addRooms}
        > <span className="ml-2">Add Room</span></Button>
        </div>
        {state.roomDetails.length > 0 &&
        <PaymentDetails sendPaymentinfo={updatePayment} region_type={state.region_type}/>
        }
        <div>
                
        <div className="cardMainlayout contRest minHight0 d-flex justify-content-between">
            <Form.Row className="align-items-center">
                <Form.Group className="m-0" as={Col}>
                    <Form.Check type="checkbox" id="xy" label="I would like to receive an booking Confirmation Mail" value="" onChange={handleConf} custom />
                    <Form.Check type="checkbox" id="xx" label="I would like to receive an Invoice Mail" value="" onChange={handleInv} custom />
                </Form.Group>
            </Form.Row>

            <div class="d-flex justify-content-end">
              {!isLoad?
                <button class="btn-primary btn-xs undefined cnfBooking d-flex justify-content-center align-items-center  btn" onClick={showConfirmPop}
            >Continue <span class="ezyIcon icon-rightArrow ml-2"></span></button>:
            <div class="progress progress-striped active">
            <div role="progressbar progress-striped" class="progress-bar">
              <span>Processing...</span>
            </div>
            </div>}
            </div>
        </div>
              </div>
</div>
<Modal show={showPopup} onHide={closePopUP} className="logOutyes paymentSucessfull">
                      <Modal.Header>
                        <Modal.Title>
                          Are you sure you want to proceed the Booking?
                          Make sure Paid Amount and Total Booking Amount should same.
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Footer>
                        <Button variant="primary" onClick={closePopUP}>
                          Cancel
                        </Button>
                        {!isLoad?
                        <Button variant="primary" onClick={continueBooking}>
                          Continue
                        </Button>:
            <div class="progress progress-striped active">
            <div role="progressbar progress-striped" class="progress-bar">
              <span>Processing...</span>
            </div>
            </div>}
                      </Modal.Footer>
      </Modal>
              
        </>
    )
}
export default HotelmanualBook