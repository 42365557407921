import React, {useState,useEffect} from "react";
import Router from "./router/Router";
import './App.scss';
export const AirlinesContext = React.createContext();
export const Context = React.createContext();
export const REGION_COUNTRY = "REGION_COUNTRY";
let airportsData = require("./components/masterData/AirlinesMasterData.json");

const App = () => {
  const [context, setContext] = useState({logindata:""});
  
  return (
    <div className="cnt">
      <AirlinesContext.Provider value={airportsData}>
      <Context.Provider value={[context, setContext]}>
      <Router/>
      </Context.Provider>
      </AirlinesContext.Provider>
    </div>
  );
};

export default App;
