import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../../App";
import { isArrayNotEmpty } from "../../utilities/validators";
import FlightSummaryFare from "./FlightSummaryFare";
import FlightSummaryPassengers from "./FlightSummaryPassengers";
import FlightSummarySegments from "./FlightSummarySegments";
import SectorHeader from "./SectorHeader";
import Select from "react-select";

/**
 * @author Praveen Varma
 * @date 27-11-2023
 * @description Flight booking summary data
 * @param {*} props 
 * @returns 
 */
function FlightSummary({ book_info, itiData, travel_type, mappings, isEdit, base_usr_exrate, enableRefundTkt, enableRefundReturnTkt, bookingId, sendRefund, cancelRefundblock, processEditBookingFlow, btnLoader, sendCancelBook }) {
    const [context, setContext] = useContext(Context);

    useEffect(() => {
    }, [])

    const SelectOptions = [
      { value: "Edit", label: "Edit Booking" },
    ];

    const continueRefund = (data) => {
        sendRefund(data);
    }

    const cancelRefunddata = (data, type) => {
        cancelRefundblock(data, type);
    }

    const callSegEditBooking = (data, block) => {
        processEditBookingFlow(data, block)
    }

    const callPaxEditBooking = (data, block) => {        
        processEditBookingFlow(data, block)
    }

    const callFareEditBooking = (data, block) => {        
        processEditBookingFlow(data, block)
    }

    const sendCancelPnr = (data) => {
        sendCancelBook(data);
    }

    return (
        <div className="viewItineraryWrap">
            <SectorHeader itiData={itiData} trvl_type={travel_type} mappings={mappings} />
            {isArrayNotEmpty(itiData.seg_det) && (
                itiData?.seg_det?.map((segment, index) => (
                    <FlightSummarySegments seg={segment} sector={itiData.seg_det} index={index} mappings={mappings} isEdit={isEdit} trvl_type={travel_type} processSegEditBooking={callSegEditBooking} btnLoader={btnLoader} />
                ))
            )}
            {isArrayNotEmpty(itiData.pax_info) &&
                <FlightSummaryPassengers itiData={itiData} isEdit={isEdit} enableRefundTkt={enableRefundTkt} enableRefundReturnTkt={enableRefundReturnTkt} bookingId={bookingId} trvl_type={travel_type} sendRefund={continueRefund} cancelRefundblock={cancelRefunddata} processPaxEditBooking={callPaxEditBooking} btnLoader={btnLoader} cancelPnr={sendCancelPnr} />
                // {isArrayNotEmpty(state.pax_det) && (<FlightSummaryPaxDetails onward={state.onward} pax_det={state.pax_det} enableVoidBooking={state.enableVoidBooking} enableVoidCancel={state.enableVoidCancel} tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled} disableCheckbox={state.disableCheckbox} tid = {state.tid} voidTickets={voidBookReq} enableRefundTkt={state.enableRefund} sendRefund={continueRefund} bookDetails={state.bkng_det[0]} refundAvailable={state.refundAvail} enableReisueTkt={state.enableReissue} sendReissueTickts={reissueTickts} reissueMsg={state.atcmsg} bookingId={state.bkng_det[0].booking_id} flowType={state.bkng_det[0].flow_type} isEdit={state.enableEdit}/>)}

            }
            {isArrayNotEmpty(itiData.fare_info) &&
                <FlightSummaryFare book_info={book_info} itiData={itiData} base_usr_exrate={base_usr_exrate} isEdit={isEdit} trvl_type={travel_type} processFareEditBooking={callFareEditBooking}btnLoader={btnLoader} />
            }

        </div>
    )
}

export default FlightSummary;