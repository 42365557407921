import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table, Container } from "react-bootstrap";
import Button from "../../common/Button";
import Select from 'react-select';
import FlightSegments from '../../dashboard/manageBookings/FlightSegments';
import { useLocation } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import DateUtils from '../../common/DateUtils';
import PassengerInfo from '../../dashboard/manageBookings/PassengerInfo';
import PaymentDetails from '../../dashboard/manageBookings/PaymentDetails';
import { Context } from '../../../App';
import Utilities from '../../utilities/Utilities';
import ManageBookingService from '../../../services/ManageBookingService';
import Header from '../../common/Header';
import Sidebarmenu from "../../home/SideBar";
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import Footer from '../../common/Footer';
const FLIGHT_SEARCH_CID = process.env.REACT_APP_FLIGHT_SEARCH_CID;
let stdCodes = require('../../masterData/CountrySTDCodes.json');
let countriesList = require('../../masterData/CountriesList.json');



const initialState = {
    onward_fltresponse:[],segment_details:[],return_fltresponse:[],onward_paxRes:[], passengers:[], mappings:[], ptype:"",enablePax:false, infantEnable:false, payment_details:[], region_type:"",
    selptype:[], paxtype:[], paxtl:"", selpaxtl:[],insertPnr:"",nat:[],selIsc:[],supplier:"",adts:1,chds:0,infs:0,
    adtpax:[],infpax: [], chdpax: [],adtbase:"",adttaxes:"", adtmarkup:"", adtsf:"",adtPenalty:"",adtResVal:0,adttotal:0, adttotalamt:0,adttotalta: 0,adttotalamtta: 0,
    chdbase:"",chdtaxes:"", chdmarkup:"", chdsf:"",chdPenalty:"",chdResVal:0,chdtotal:0,chdtotalamt:0, chdtotalamtta:0,
    infbase:"",inftaxes:"", infmarkup:"", infsf:"",infPenalty:"",infResidualVal:0,inftotal:0,inftotalamt:0,inftotalamtta:0,
    disable: false,oldtid:"",adtdis: 0,adtdista: 0,chddis: 0,chddista: 0,infdis: 0,infdista: 0,gst_type: "",cgst: 0,sgst: 0,igst: 0,adt_attgst: 0,
    chd_attgst: 0,inf_attgst: 0,adt_tagst: 0,chd_tagst: 0,inf_tagst: 0,att_adtcgst: 0,att_adtsgst: 0,att_adtigst: 0,att_adtRetcgst: 0,att_adtRetsgst: 0,att_adtRetigst: 0,att_chdcgst: 0,att_chdsgst: 0,att_chdigst: 0,ta_adtcgst: 0,
    ta_adtsgst: 0,ta_adtigst: 0,ta_chdcgst: 0,ta_chdsgst: 0,ta_chdigst: 0,ta_infcgst: 0,ta_infsgst: 0,ta_infigst: 0,
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


function FlightSummaryManualReissue(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const typeaheadRef = useRef(null);
    const location = useLocation();
    const [fltresponse, setFltresponse] = useState(location.state);
    const [type, setType] = useState("");
    const [ptype, setPType] = useState("");
    const [enableAttPrice, setEnableAttPrice] = useState(false);
    const [enableTAPrice, setEnableTAPrice] = useState(false);
    const [typeOne, setTypeOne] = useState("");
    const [ptypeOne, setPTypeOne] = useState("");
    const [typeTwo, setTypeTwo] = useState("");
    const [ptypeTwo, setPTypeTwo] = useState("");
    const [enableSTAPrice, setEnableSTAPrice] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [context, setContext] = useContext(Context);
    const [paymentActive, setPaymentActive] = useState("payment");
    const [remarks, setRemarks] = useState(null);

    

    
    useEffect(()=>{
        if (location.state){
            dispatch({type:"supplier", payload:fltresponse.bkng_info.supplier})
            dispatch({type:"region_type", payload:fltresponse.bkng_info.region_type})
            dispatch({type:"userCurrency", payload:fltresponse.bkng_info.usr_curr_code})
            dispatch({type:"mappings", payload:fltresponse.mappings})

            if(fltresponse.bkng_info.tid.includes("/")){
            const tid= fltresponse.bkng_info.tid.split("/")
            if(fltresponse.type=="ONWARD"){
              dispatch({type:"oldtid", payload:tid[0]})
            }else{
              dispatch({type:"oldtid", payload:tid[1]})
            }
          }else{
            dispatch({type:"oldtid", payload:fltresponse.bkng_info.tid})
          }
            if (fltresponse.type=="ONWARD"){
              dispatch({type:"insertPnr", payload:fltresponse.onword_info.seg_det[0].normal_gds_pnr})
                dispatch({type:"onward_fltresponse", payload:fltresponse.onword_info.seg_det})
                convertPaxpriceDetails(fltresponse.mongo_db_data[1]?fltresponse.mongo_db_data[1]:fltresponse.mongo_db_data[0]);
                for (let price of fltresponse.mongo_db_data){
                  if(price?.psngrs){
                      createPaxCnt(price?.psngrs)
                 dispatch({ type: "onward_paxRes", payload: price?.psngrs });
                 dispatch({ type: "passengers", payload: price?.psngrs });
                 dispatch({ type: "travelsList", payload: price?.psngrs });
              }
             }
            } 
          if(fltresponse.type=="RETURN") {
               dispatch({type:"onward_fltresponse", payload:fltresponse.return_info.seg_det})
               dispatch({type:"insertPnr", payload:fltresponse.return_info.seg_det[0].normal_gds_pnr})

               convertPaxpriceDetails(fltresponse.mongo_db_data[0]);
               for (let price of fltresponse.mongo_db_data){
                if(price?.psngrs){
                    createPaxCnt(price?.psngrs)
               dispatch({ type: "onward_paxRes", payload: price?.psngrs });
               dispatch({ type: "passengers", payload: price?.psngrs });
               dispatch({ type: "travelsList", payload: price?.psngrs });
            }
           }
           }
        }
    },[location.state])

    const createPaxCnt=(psngrs)=>{
    let adtList = [];
    let chdList = [];
    let infList = [];
    for (let pax of psngrs) {
      if (pax.ty == "ADT") {
        let obj1 = {
          pname: pax.fn+" "+pax.ln,
          number: pax.tkt,
          ty: pax.ty,
        };
        adtList.push(obj1);
        dispatch({ type: "adtpax", payload: adtList });
        dispatch({ type: "adts", payload: adtList.length });
      } if (pax.inf != undefined && pax.inf != "") {
        let obj3 = {
          pname: pax.inf.fn+" "+pax.inf.ln,
          number: pax.inf.tkt,
          ty: pax.inf.ty,
        };
        infList.push(obj3);
        dispatch({ type: "infpax", payload: infList });
        dispatch({ type: "infs", payload: infList.length });
      }
      if (pax.ty == "CHD") {
        let obj2 = {
            pname: pax.fn+" "+pax.ln,
            number: pax.tkt,
            ty: pax.ty,
        };
        chdList.push(obj2);
        dispatch({ type: "chdpax", payload: chdList});
        dispatch({ type: "chds", payload: chdList.length });
      } 
    } 

  }

  useEffect(() => {
    if (enableAttPrice) {
      calculateADTPrice();
    }
  }, [enableAttPrice]);

  useEffect(() => {
    if (enableTAPrice) {
      calculateCHDPrice();
    }
  }, [enableTAPrice]);

  useEffect(() => {
    if (enableSTAPrice) {
      calculateSTAPrice();
    }
  }, [enableSTAPrice]);
   const convertPaxpriceDetails = (obj) => {
    for (let price of obj.origns[0].srcorigns[0].paxpr) {
      if (price.ptype == "ADT") {
        // dispatch({ type: "adtbase", payload: price.bf });
        dispatch({ type: "adtbase", payload: 0 });
        // dispatch({
        //   type: "adttaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({
          type: "adttaxes",
          payload: 0,
        });
        dispatch({ type: "adtsupCharge", payload: Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        // dispatch({ type: "adttotal", payload: Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "adttotal", payload: 0 });
        // dispatch({
        //   type: "adttotalamt",
        //   payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.adt),
        // });
        dispatch({
          type: "adttotalamt",
          payload: 0,
        });
        // dispatch({ type: "adttotalta", payload: Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "adttotalta", payload: 0 });
        // dispatch({
        //   type: "adttotalamtta",
        //   payload:(Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.adt),
        // });
        dispatch({
          type: "adttotalamtta",
          payload: 0,
        });
        dispatch({ type: "adttotalsta", payload: Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({
          type: "adttotalamtsta",
          payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.adt),
        });
      } else if (price.ptype == "CHD") {
        // dispatch({ type: "chdbase", payload: price.bf });
        dispatch({ type: "chdbase", payload: 0 });
        // dispatch({
        //   type: "chdtaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({
          type: "chdtaxes",
          payload: 0,
        });
        dispatch({ type: "chdsupCharge", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        // dispatch({ type: "chdtotal", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "chdtotal", payload: 0 });
        // dispatch({
        //   type: "chdtotalamt",
        //   payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.chd),
        // });
        dispatch({
          type: "chdtotalamt",
          payload: 0,
        });
        // dispatch({ type: "chdtotalta", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "chdtotalta", payload: 0 });
        // dispatch({
        //   type: "chdtotalamtta",
        //   payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.chd),
        // });
        dispatch({
          type: "chdtotalamtta",
          payload: 0,
        });
        dispatch({ type: "chdtotalsta", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({
          type: "chdtotalamtsta",
          payload:(Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.chd),
        });
      } else if (price.ptype == "INF") {
        // dispatch({ type: "infbase", payload: price.bf });
        dispatch({ type: "infbase", payload: 0 });
        // dispatch({
        //   type: "inftaxes",
        //   payload: Utilities.taxCalculation(price.tax),
        // });
        dispatch({
          type: "inftaxes",
          payload: 0,
        });
        dispatch({ type: "infsupCharge", payload: Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        // dispatch({ type: "inftotal", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "inftotal", payload: 0 });
        // dispatch({
        //   type: "inftotalamt",
        //   payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.inf),
        // });
        dispatch({
          type: "inftotalamt",
          payload: 0,
        });
        // dispatch({ type: "inftotalta", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf) });
        dispatch({ type: "inftotalta", payload: 0 });
        // dispatch({
        //   type: "inftotalamtta",
        //   payload: (Utilities.taxCalculation(price.tax)+ Number(price.bf)) * Number(obj.inf),
        // });
        dispatch({
          type: "inftotalamtta",
          payload: 0,
        });
        dispatch({ type: "inftotalsta", payload:Utilities.taxCalculation(price.tax)+ Number(price.bf)});
        dispatch({
          type: "inftotalamtsta",
          payload:(Utilities.taxCalculation(price.tax)+ Number(price.bf))* Number(obj.inf),
        });
      }
    }
  };
  //Calculation ADT Price for ATT , TA AND STA
  const calculateADTPrice = () => {
    if (type === "ATT" && ptype === "ADT") {
      let supprice = Number(state.adtbase) + Number(state.adttaxes);
      dispatch({ type: "sup_totalPrice", payload: supprice });
      let price =
        Number(supprice) +
        Number(state.adtmarkup) +
        Number(state.adtsf) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      let taPrice = Number(price);
      if (state.adtmarkup !== "" || state.adtsf !== "") {
        let totalGst =
          Number(state.adtmarkup) + Number(state.adtsf) - Number(state.adtdis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_adtcgst", payload: Number(attCgst) });
            dispatch({ type: "att_adtsgst", payload: Number(attSgst) });
            dispatch({
              type: "adt_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            price = price + Number(attSgst) + Number(attCgst);
            taPrice = taPrice + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_adtigst", payload: Number(attIgst) });
            dispatch({ type: "adt_attgst", payload: Number(attIgst) });
            price = price + Number(attIgst);
            taPrice = taPrice + Number(attIgst);
          }
        }
      }
      dispatch({ type: "adtsupCharge", payload: Number(supprice) });
      dispatch({ type: "adttotal", payload: price.toFixed(2) });
      dispatch({ type: "adttotalta", payload: taPrice.toFixed(2) });
      dispatch({ type: "adttotalsta", payload: taPrice.toFixed(2) });
      dispatch({ type: "adttotalamt", payload: Number(price) * state.adts });
      dispatch({
        type: "adttotalamtta",
        payload: Number(taPrice) * state.adts,
      });
      dispatch({
        type: "adttotalamtsta",
        payload: Number(taPrice) * state.adts,
      });
    } else if (type === "TA" && ptype === "ADT") {
      let price =
        Number(state.sup_totalPrice) +
        Number(state.adtmarkupta) +
        Number(state.adtmarkup) +
        Number(state.adtsf) +
        Number(state.adtsfta) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      let staPrice = Number(price);
      if (state.adtmarkupta !== "" || state.adtsfta !== "") {
        let totalGst =
          Number(state.adtmarkupta) +
          Number(state.adtsfta) -
          Number(state.adtdista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_adtcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_adtsgst", payload: Number(taSgst) });
            dispatch({
              type: "adt_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            price = price + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_adtigst", payload: Number(attIgst) });
            dispatch({ type: "adt_tagst", payload: Number(attIgst) });
            price = price + Number(attIgst);
          }
        }
      }
      dispatch({ type: "adttotalta", payload: price.toFixed(2) });
      dispatch({
        type: "adttotalamtta",
        payload: price.toFixed(2) * state.adts,
      });
      dispatch({ type: "adttotalsta", payload: staPrice });
      dispatch({ type: "adttotalamtsta", payload: staPrice * state.adts });
    } else if (type === "STA" && ptype === "ADT") {
      let totalsta =
        Number(state.sup_totalPrice) +
        Number(state.adtmarkup) +
        Number(state.adtmarkupta) +
        Number(state.adtsfta) +
        Number(state.adtsf) +
        Number(state.adtmarkupsta) +
        Number(state.adtsfsta) +
        Number(state.adtPenalty) -
        Number(state.adtResVal);
      dispatch({ type: "adttotalsta", payload: totalsta });
      dispatch({ type: "adttotalamtsta", payload: totalsta * state.adts });
    }
    setEnableAttPrice(false);
  };

  const calculateCHDPrice = () => {
    if (typeOne === "ATT" && ptypeOne === "CHD") {
      let suppChdrice = Number(state.chdbase) + Number(state.chdtaxes);
      let price =
        Number(suppChdrice) +
        Number(state.chdmarkup) +
        Number(state.chdsf) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      let taPrice = Number(price);
      if (state.chdmarkup !== "" || state.chdsf !== "") {
        let totalGst =
          Number(state.chdmarkup) + Number(state.chdsf) - Number(state.chddis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_chdcgst", payload: Number(attCgst) });
            dispatch({ type: "att_chdsgst", payload: Number(attSgst) });
            dispatch({
              type: "chd_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            price = price + Number(attSgst) + Number(attCgst);
            taPrice = taPrice + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_chdigst", payload: Number(attIgst) });
            dispatch({ type: "chd_attgst", payload: Number(attIgst) });
            price = price + Number(attIgst);
            taPrice = taPrice + Number(attIgst);
          }
        }
      }
      dispatch({ type: "chdsupCharge", payload: Number(suppChdrice) });
      dispatch({ type: "chdtotal", payload: price.toFixed(2) });
      dispatch({ type: "chdtotalamt", payload: price.toFixed(2) * state.chds });
      dispatch({ type: "chdtotalta", payload: taPrice });
      dispatch({
        type: "chdtotalamtta",
        payload: Number(taPrice) * state.chds,
      });
      dispatch({ type: "chdtotalsta", payload: Number(taPrice) });
      dispatch({
        type: "chdtotalamtsta",
        payload: Number(taPrice) * state.chds,
      });
    } else if (typeOne === "TA" && ptypeOne === "CHD") {
      let price =
        Number(state.chdsupCharge) +
        Number(state.chdmarkup) +
        Number(state.chdsf) +
        Number(state.chdmarkupta) +
        Number(state.chdsfta) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      let staPrice = Number(price);
      if (state.chdmarkupta !== "" || state.chdsfta !== "") {
        let totalGst =
          Number(state.chdmarkupta) +
          Number(state.chdsfta) -
          Number(state.chddista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_chdcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_chdsgst", payload: Number(taSgst) });
            dispatch({
              type: "chd_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            price = price + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_chdigst", payload: Number(attIgst) });
            dispatch({ type: "chd_tagst", payload: Number(attIgst) });
            price = price + Number(attIgst);
          }
        }
      }
      dispatch({ type: "chdtotalta", payload: price.toFixed(2) });
      dispatch({
        type: "chdtotalamtta",
        payload: price.toFixed(2) * state.chds,
      });
      dispatch({ type: "chdtotalsta", payload: staPrice });
      dispatch({ type: "chdtotalamtsta", payload: staPrice * state.chds });
    } else if (typeOne === "STA" && ptypeOne === "CHD") {
      let totalsta =
        Number(state.chdsupCharge) +
        Number(state.chdmarkup) +
        Number(state.chdmarkupta) +
        Number(state.chdsfta) +
        Number(state.chdsf) +
        Number(state.chdmarkupsta) +
        Number(state.chdsfsta) +
        Number(state.chdPenalty) -
        Number(state.chdResVal);
      dispatch({
        type: "chdsupChargeSTA",
        payload: Number(state.chdsupCharge),
      });
      dispatch({ type: "chdtotalsta", payload: totalsta });
      dispatch({ type: "chdtotalamtsta", payload: totalsta * state.chds });
    }
    setEnableTAPrice(false);
  };

  //Calculation INF Price for ATT , TA AND STA
  const calculateSTAPrice = () => {
    if (typeTwo === "ATT" && ptypeTwo === "INF") {
      let supInfprice = Number(state.infbase) + Number(state.inftaxes);
      let total =
        Number(supInfprice) + Number(state.infmarkup) + Number(state.infsf);
      if (state.infmarkup !== "" || state.infsf !== "") {
        let totalGst =
          Number(state.infmarkup) + Number(state.infsf) - Number(state.infdis);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const attCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const attSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_infcgst", payload: Number(attCgst) });
            dispatch({ type: "att_infsgst", payload: Number(attSgst) });
            dispatch({
              type: "inf_attgst",
              payload: Number(attSgst) + Number(attCgst),
            });
            total = total + Number(attSgst) + Number(attCgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "att_infigst", payload: Number(attIgst) });
            dispatch({ type: "inf_attgst", payload: Number(attIgst) });
            total = total + Number(attIgst);
          }
        }
      }
      dispatch({ type: "infsupCharge", payload: supInfprice });
      dispatch({ type: "inftotal", payload: total });
      dispatch({ type: "inftotalamt", payload: total * state.infs });
      dispatch({ type: "inftotalta", payload: total });
      dispatch({ type: "inftotalamtta", payload: total * state.infs });
      dispatch({ type: "inftotalsta", payload: Number(total) });
      dispatch({ type: "inftotalamtsta", payload: Number(total) * state.infs });
    } else if (typeTwo === "TA" && ptypeTwo === "INF") {
      let totalta =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infsf) +
        Number(state.infmarkupta) +
        Number(state.infsfta);
      let charge = Number(state.infsupCharge);
      let staPrice =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infmarkupta) +
        Number(state.infsf) +
        Number(state.infsfta);
      if (state.infmarkupta !== "" || state.infsfta !== "") {
        let totalGst =
          Number(state.infmarkupta) +
          Number(state.infsfta) -
          Number(state.infdista);
        if (Number(totalGst) > 0) {
          if (state.gst_type === "same") {
            const taCgst = ((state.cgst / 100) * totalGst).toFixed(2);
            const taSgst = ((state.sgst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_infcgst", payload: Number(taCgst) });
            dispatch({ type: "ta_infsgst", payload: Number(taSgst) });
            dispatch({
              type: "inf_tagst",
              payload: Number(taCgst) + Number(taSgst),
            });
            totalta = totalta + Number(taCgst) + Number(taSgst);
          } else {
            const attIgst = ((state.igst / 100) * totalGst).toFixed(2);
            dispatch({ type: "ta_infigst", payload: Number(attIgst) });
            dispatch({ type: "inf_tagst", payload: Number(attIgst) });
            totalta = totalta + Number(attIgst);
          }
        }
      }
      dispatch({ type: "infsupChargeTA", payload: charge.toFixed(2) });
      dispatch({ type: "inftotalta", payload: totalta.toFixed(2) });
      dispatch({
        type: "inftotalamtta",
        payload: totalta.toFixed(2) * state.infs,
      });
      dispatch({ type: "inftotalsta", payload: Number(staPrice) });
      dispatch({
        type: "inftotalamtsta",
        payload: Number(staPrice) * state.infs,
      });
    } else if (typeTwo === "STA" && ptypeTwo === "INF") {
      let totalsta =
        Number(state.infsupCharge) +
        Number(state.infmarkup) +
        Number(state.infmarkupta) +
        Number(state.infmarkupsta) +
        Number(state.infsfsta) +
        Number(state.infsf) +
        Number(state.infsfta);
      dispatch({
        type: "infsupChargeSTA",
        payload: Number(state.infsupCharge),
      });
      dispatch({ type: "inftotalsta", payload: totalsta });
      dispatch({ type: "inftotalamtsta", payload: totalsta * state.infs });
    }
    setEnableSTAPrice(false);
  };

    const updateOwSegments=(segs)=>{
        dispatch({ type: 'segment_details', payload: segs })
      }
    const removedOwSegments=(segs)=>{
        dispatch({ type: 'segment_details', payload: segs })
      }
      const updateOwPax=(pax)=>{
        dispatch({ type: 'passengers', payload: pax })
        createPaxCnt(pax)
        // setPaxFare(pax);
        // setEnbaleFare(true);
      }
      const removedPax=(pax)=>{
        dispatch({ type: 'passengers', payload: pax })
        createPaxCnt(pax)
      }

       //Calculation ADT Price for ATT , TA AND STA
  const handleChangeADTPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setType(type);
    setPType(paxType);
    setEnableAttPrice(true);
  };
   //Calculation CHD Price for ATT , TA AND STA
   const handleChangeCHDPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setTypeOne(type);
    setPTypeOne(paxType);
    setEnableTAPrice(true);
  };

  const handleChangeINFPrice = (type, paxType) => (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    setTypeTwo(type);
    setPTypeTwo(paxType);
    setEnableSTAPrice(true);
  };
  const updatePayment = (e) => {
    dispatch({ type: "payment_details", payload: e });
  };

  const handleRadioChange = (value) => {
    setPaymentActive(value);
    setRemarks(null);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };


  const createMainPr = () => {
    let totalSf = 0;
    let baseFare =
      Number(state.adtbase * state.adts) +
      Number(state.chdbase * state.chds) +
      Number(state.infbase * state.infs);
    let taxes =
      Number(state.adttaxes * state.adts) +
      Number(state.chdtaxes * state.chds) +
      Number(state.inftaxes * state.infs);
    let total = Number(baseFare) + Number(taxes);
    const payload = {
      bf: Number(baseFare),
      tf: Number(total),
      tax: [
        {
          OT: Number(taxes),
        },
        {
          attmkp:
            Number(state.adtmarkup * state.adts) +
            Number(state.chdmarkup * state.chds) +
            Number(state.infmarkup * state.infs),
        },
        {
          attdisc:
            Number(state.adtdis * state.adts) +
            Number(state.chddis * state.chds) +
            Number(state.infdis * state.infs),
        },
        {
          att_gst:
            Number(state.adt_attgst * state.adts) +
            Number(state.chd_attgst * state.chds) +
            Number(state.inf_attgst * state.infs),
        },
      ],

      tfd: Number(total),
      penlty:
        Number(state.adtPenalty * state.adts) +
        Number(state.chdPenalty * state.chds) +
        Number(state.infPenalty * state.infs),
      resdval: 0,
      gtd: Number(total),
      bfd: Number(baseFare),
      scur: state.cur,
      sup_fare: baseFare + "_" + total,
      sup_cur_fare: baseFare + "_" + total,
      markupnames: createMarkupname(),
      gst_total:0,
    };
    return payload;
  };
  const createMarkupname = () => {
    let markNames = [];
    if (state.adtmarkup !== 0) {
      const att = {
        "code": "CNT",
        "name": "CNT Manual Markup"
      }
      markNames.push(att);
    }
    return markNames;
  };

  const createPaxPr = () => {
    let paxList = [];
    paxList.push(createPaxRequest("ADT"));
    if (state.chds > 0) {
      paxList.push(createPaxRequest("CHD"));
    }
    if (state.infs > 0) {
      paxList.push(createPaxRequest("INF"));
    }
    return paxList;
  };

  const createPaxRequest = (type) => {
    let baseFare = 0;
    let total = 0;
    let taxes = 0;
    let att = 0;
    let attdisc = 0;
    let fbc = [];
    let penalty = 0;
    let resVal = 0;
    let att_cgst = 0;
    let att_sgst = 0;
    let att_igst = 0;
    if (type === "ADT") {
      baseFare = Number(state.adtbase) + Number(state.adtsf);
      let totalSup = Number(state.adtbase) + Number(state.adttaxes);
      total = Number(totalSup) + Number(state.adtsf);
      taxes = state.adttaxes;
      att = state.adtmarkup;
      attdisc = state.adtdis;
      fbc = fltresponse.mongo_db_data[0].origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "ADT"
      );
      penalty = state.adtPenalty;
      resVal = state.adtResVal;
      att_cgst = state.att_adtcgst;
      att_sgst = state.att_adtsgst;
      att_igst = state.att_adtigst;
    } else if (type === "CHD") {
      baseFare = Number(state.chdbase) + Number(state.chdsf);
      let totalSup = Number(state.chdbase) + Number(state.chdtaxes);
      total = Number(totalSup) + Number(state.chdsf);
      taxes = state.chdtaxes;
      att = state.chdmarkup;
      attdisc = state.chddis;
      fbc = fltresponse.mongo_db_data[0].origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "CHD"
      );
      penalty = state.chdPenalty;
      resVal = state.chdPenalty;
      att_cgst = state.att_chdcgst;
      att_sgst = state.att_chdsgst;
      att_igst = state.att_chdigst;
    } else if (type === "INF") {
      baseFare =Number(state.infbase) + Number(state.infsf);
      let totalSup = Number(state.infbase) + Number(state.inftaxes);
      total = Number(totalSup) + Number(state.infsf);
      taxes = state.inftaxes;
      att = state.infmarkup;
      attdisc = state.infdis;
      fbc = fltresponse.mongo_db_data[0].origns[0].srcorigns[0].paxpr.filter(
        (pax) => pax.ptype === "INF"
      );
      penalty = state.infPenalty;
      resVal = state.infResidualVal;
      att_cgst = state.att_infcgst;
      att_sgst = state.att_infsgst;
      att_igst = state.att_infigst;
    }
    const payload = {
      bf: Number(baseFare),
      tf: Number(total),
      ptype: type,
      tax: [
        {
          OT: Number(taxes),
        },
        {
          attmkp: Number(att),
        },
        {
          attdisc: Number(attdisc),
        },
        {
          att_cgst: Number(att_cgst),
        },
        {
          att_sgst: Number(att_sgst),
        },
        {
          att_igst: Number(att_igst),
        },
      ],
      scur: state.cur,
      fbc: fbc[0]?.fbc,
      tfd: Number(total),
      penlty: Number(penalty),
      resdval: 0,
      gtd: Number(total),
      bfd: Number(baseFare),
      psup_fare: baseFare + "_" + total,
      sup_cur_fare: baseFare + "_" + total,
    };
    return payload;
  };

  const createServiceFee = () => {
    let services = [];
    let att = request("CNT", 0);
    services.push(att);
    return services;
  };

  const request = (type, supFee) => {
    let adt = 0;
    let chd = 0;
    let inf = 0;
    let total = 0;
    let nm = "";
    if (type === "CNT") {
      adt = state.adtsf;
      chd = state.chdsf;
      inf = state.infsf;
      total = Number(state.adtsf) + Number(state.chdsf) + Number(state.infsf);
      nm = "CNT Manual Service Fee";
      supFee = 0;
    }
    const payload = {
      type: type,
      adt: adt,
      chd: chd,
      inf: inf,
      servicefee_name: nm,
      apl_on: "Per Ticket",
      sf: total,
      sf_disc: 0,
      adt_sf_disc: 0,
      chd_sf_disc: 0,
      inf_sf_disc: 0,
      complete_sfvalue: total,
      sup_fee: supFee,
    };
    return payload;
  };

  const convertSegReq = (ref) => {
    const payload = {
      tid:"",
      ref: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].ref,
      ofcid: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].office_id,
      sdur: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].sdur,
      re: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].re,
      stop: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].stop,
      sup: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].sup,
      cl: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].cl,
      ft: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].ft,
      tktair: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].tktair,
      tecst: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].tecst,
      seg: state.segment_details,
      bgd: fltresponse.mongo_db_data[1].origns[0].srcorigns[0].bgd,
      pr: createMainPr(),
      paxpr: createPaxPr(),
      serviceFee: createServiceFee(),
    };
    return payload;
  }
  const convertRTSegReq = (ref) => {
    const payload = {
      tid:"",
      ref: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].ref,
      ofcid: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].office_id,
      sdur: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].sdur,
      re: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].re,
      stop: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].stop,
      sup: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].sup,
      cl: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].cl,
      ft: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].ft,
      tktair: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].tktair,
      tecst: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].tecst,
      seg: state.segment_details,
      bgd: fltresponse.mongo_db_data[0].origns[0].srcorigns[0].bgd,
      pr: createMainPr(),
      paxpr: createPaxPr(),
      serviceFee: createServiceFee(),
    };
    return payload;
  }


  const convertSrcorigns = () => {
    if (fltresponse.type=="ONWARD") {
      if(fltresponse.mongo_db_data[1]!=undefined){
      const payload = {
        srcorigns: [convertSegReq("O")],
        
      };
      return payload;
      }else{
        const payload1 = {
          srcorigns: [convertRTSegReq("R")],
        };
        return payload1;
      }
    } else {
      const payload1 = {
        srcorigns: [convertRTSegReq("R")],
      };
      return payload1;
    }
  };

  const createPayObj = () => {
    let payObj = [];
    let uniq_pay = "";
    let bank_pay = "";
    let che_pay = "";
    if (state.payment_details.is_pay_uniq_id) {
      dispatch({
        type: "pay_uniq_id",
        payload: state.payment_details.pay_uniq_id,
      });
      uniq_pay = {
        pay_uniq_id: state.payment_details.pay_uniq_id,
        ptype: "Pay UID",
        amt: state.payment_details.paid_amount,
        psta: "Confirmed",
      };
      payObj.push(uniq_pay);
    }
    if (state.payment_details.is_bank_transfer) {
      bank_pay = {
        bank_name: state.payment_details.bank_name,
        ac_number: state.payment_details.ac_number,
        cust_name: state.payment_details.cust_name,
        trans_number: state.payment_details.trans_number,
        date_of_transfer: state.payment_details.date_of_transfer,
        psta: "Confirmed",
        amt: state.payment_details.total_amt,
        ptype: "Bank Transfer",
      };
      payObj.push(bank_pay);
    }
    if (state.payment_details.is_cheque) {
      che_pay = {
        bank_name: state.payment_details.che_bank_name,
        ac_number: state.payment_details.che_ac_number,
        trans_number: state.payment_details.che_trans_number,
        psta: "Confirmed",
        ptype: "Cheque",
        amt: state.payment_details.che_total_amt,
      };
      payObj.push(che_pay);
    }

    return payObj;
  };
  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const validatePrice = () => {
    if (state.adtbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Adult",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.adttaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Adult",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    } else if (state.segment_details == "" || state.segment_details ==undefined) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({type: "notiMessage",payload: "Please enter segment details"});
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.passengers == "" || state.passengers ==undefined) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({type: "notiMessage",payload: "Please enter passenger details"});
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.chds > 0 && state.chdbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Child",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.chds > 0 && state.chdtaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Child",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.infs > 0 && state.infbase === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Base Fare for Infant",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.infs > 0 && state.inftaxes === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Taxes for Infant",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.payment_details === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Payment deatails and proceed",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    if (state.passengers.length!=0){
      let tkts1 = state.passengers.filter((obj) => obj.tkt === undefined || obj.tkt === "");
      let tkts2 = state.passengers.filter((obj) => obj.inf !== undefined && obj.inf !="" && obj.inf.tkt === "");
      if (tkts1.length !== 0 || tkts2.length !== 0) {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: "Enter Ticket Number" });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      }
      }else{
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({ type: "notiMessage", payload: "Please enter passenger details" });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        return false;
      }
       if (paymentActive === "payment") {
        if(!state.payment_details.is_pay_uniq_id && !state.payment_details.is_bank_transfer && !state.payment_details.is_cheque) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter payment details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          window.scrollTo(0, 0);
          return false;
        } else if (
          state.payment_details.is_pay_uniq_id &&
          (state.payment_details.pay_uniq_id == "" ||
            state.payment_details.paid_amount === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please check Payment UID details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          window.scrollTo(0, 0);
          return false;
        } else if (
          state.payment_details.is_bank_transfer &&
          (state.payment_details.bank_name === "" ||
            state.payment_details.ac_number === "" ||
            state.payment_details.total_amt === "" ||
            state.payment_details.cust_name === "" ||
            state.payment_details.trans_number === "" ||
            state.payment_details.date_of_transfer === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Bank Transfer details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          state.payment_details.is_cheque &&
          (state.payment_details.che_bank_name === "" ||
            state.payment_details.che_ac_number === "" ||
            state.payment_details.che_total_amt === "")
        ) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Please enter Cheque details and proceed.",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      } else if (paymentActive === "remarks") {
        if(remarks === null) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: "Enter Remarks" });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          return false;
        } else {
          return true;
        }
      }   
    
    return true;
  }

  const continueBooking = () => {
    if (validatePrice()) {
      confirmManualBooking();
    }
  };
  
  const cancel = () =>{
    return (window.location = `/crm/flight/summary/${fltresponse.bkng_info.booking_id}`);

  }

  const confirmManualBooking = () => {
    const payload = {
        pbreq: {
          cha: "B2C",
          cur: state.userCurrency,
          oldtid: state.oldtid,
          bkref: fltresponse.bkng_info.booking_id,
          pnr: fltresponse.bkng_info.pnr,
          adt: fltresponse.mongo_db_data[0].adt,
          chd: fltresponse.mongo_db_data[0].chd,
          inf: fltresponse.mongo_db_data[0].inf,
          cid: FLIGHT_SEARCH_CID,
          cname: "QA b2c",
          sty: "normal",
          tt:3,
          utype: context.logindata.utype,
          bid: fltresponse.bkng_info.booking_id,
          butype: context.logindata.butype,
          pcid: context.logindata.pcid,
          ofcId: fltresponse.bkng_info.office_id,
          tkt_user_type: "CRM_USER",
          tkt_user_id: context.logindata.user_id,
          sup: "0",
          bkby: context.logindata.user_id,
          cuscode: context.logindata.cuscode,
          bktype: "REISSUE",
          crby: context.logindata.user_id,
          booking_type:"REISSUE",
          prof: [],
          psngrs: state.passengers,
          origns: [convertSrcorigns()],
          pay: remarks === null ?createPayObj():[],
          remarks: remarks,
          tfflow: false,
          region_type: state.region_type,
          type: state.gst_type,
          cgst: state.cgst,
          sgst: state.sgst,
          igst: state.igst,
          pay_uniq_id: state.pay_uniq_id,
          bkby_name: context.logindata.fname + " " + context.logindata.lname,
          region_cur: "QAR",
          req_cur: "QAR",
          sup_base_exrate: 1,
          dest_sup_base_exrate: 1,
          base_sup_exrate: 1,
          dest_base_sup_exrate: 1,
          base_usr_exrate: 1,
          base_region_exrate: 1,
        },
      };
      setIsLoad(true);
      ManageBookingService.saveBooking(payload)
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data.success &&
          response.data.data !== undefined
        ) {
          if (
            response.data.data.pbres.status !== undefined &&
            response.data.data.pbres.status === "inserted"
          ) {
            setIsLoad(false);
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Successfully Re-Issued your Booking",
            });
            dispatch({ type: "notiVarient", payload: "success" });
            dispatch({ type: "isItineraryLoading", payload: false });
            setIsLoad(false);
            window.location = `/crm/flight/summary/${response.data.data.pbres.bookingId}`
            setTimeout(function () {
              return (window.location = `/crm/flight/summary/${response.data.data.pbres.bookingId}`);
            }, 3000);
          } else {
            setIsLoad(false);
            dispatch({ type: "isItineraryLoading", payload: false });
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Something went wrong..Please try again later",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
          }
        } else {
          setIsLoad(false);
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Something went wrong..Please try again later",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
        }
         })
  }
  

    return (
      <>
       <Container fluid>
       <Header />
       <Row>
            <Col xs={2} className="pl-0 sidebarMain">
            <Sidebarmenu />
   
                </Col>
                <Col xs={10}  className="mainftr">
                {state.notiMessageShow &&
      <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible><div>{state.notiMessage}</div></Alert>}
        <div>
        
        <h5 className="layoutTitle  d-flex justify-content-between mb-3 mt-4"><span>Reissue Summary</span></h5>
        <div className='commonWrap manualReissueWrap'>
            <Modal.Header>
             <Form.Row>
                <Form.Group
                  className="m-0 pnrWrap"
                  as={Col}
                  xs={ "12"}
                  controlId="insertPnr"
                >
                  <Form.Label className="pnrTitle">
                    PNR <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={state.insertPnr}
                    required
                    placeholder="Enter PNR"
                    value={state.insertPnr}
                  />
                </Form.Group>
              </Form.Row>
              </Modal.Header>
            <FlightSegments manualReissue={true} trip={"Onward"} removeseg={removedOwSegments} sendOwSegments={updateOwSegments} sup={""} onward_fltresponse={state.onward_fltresponse} mappings={state.mappings} />
            </div>
            <PassengerInfo manualReissue={true} trip={"Onward"} sendOwPax={updateOwPax} removedPax={removedPax}  onward_paxRes={state.onward_paxRes} traveList={state.travelsList} sup={state.supplier}/>
            
            <div className="paxDetailsWrap commonWrap">
            <h5 className="layoutTitle">Passenger Details({state.userCurrency})</h5>
            <div className="confirmation confirmationMore mt-3">
            {state.adts > 0 && (
            <div>
               <div className="passengerName mb-3">
               <Row>
                    <Col className="d-flex flex-wrap">
                    {state.adtpax.length !== 0 &&
                        state.adtpax.map((adt, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{adt.pname}</span>
                              <span className="ticketNo">
                                ({adt.ty})
                              </span>
                            </span>
                          </React.Fragment>
                        ))}
                    {state.chdpax.length !== 0 &&
                        state.chdpax.map((chd, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{chd.pname}</span>
                              <span className="ticketNo">
                                ({chd.ty})
                              </span>
                            </span>
                          </React.Fragment>
                        ))}
                    {state.infpax.length !== 0 &&
                        state.infpax.map((inf, idx) => (
                          <React.Fragment key={idx}>
                            <span className="d-flex mr-3">
                              <span className="passName">{inf.pname}</span>
                              <span className="ticketNo">
                                ({inf.ty})
                              </span>
                            </span>
                          </React.Fragment>
                        ))}
                    </Col>
               </Row>
               </div>
               <h5 className="layoutTitle  d-flex justify-content-between mb-3">
                  <span>Fare Details ({state.userCurrency})</span>
                </h5>
                <table class="m-0 table passDetails">
                  <tr>
                    <th width="10%">Pax Type</th>
                    <th width="10%">Fare Difference</th>
                    <th width="10%">Tax Difference</th>
                    <th width="10%">Markup</th>
                    <th width="10%">Service Fee</th>
                    <th width="10%">Penalty</th>
                    <th width="10%">Residual Value</th>
                    {/* <th width="10%">Supplier Fee</th> */}
                    {/* {context.logindata.region_type === "India" && (
                      <th width="10%">GST</th>
                    )} */}
                    <th width="10%">Sub Total Charge</th>
                    <th width="10%">No. of Adults</th>
                    <th width="10%">Total Charge</th>
                  </tr>
                  <tr>
                    <td>
                      <span>ADT</span>
                    </td>
                        <td>
                        <Form.Group as={Col} controlId="adtbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtbase}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group> 
                      </td>
                      <td>
                      <Form.Group as={Col} controlId="adttaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adttaxes}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtmarkup === "" ? 0 : state.adtmarkup}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtsf === "" ? 0 : state.adtsf}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.adtPenalty === "" ? 0 : state.adtPenalty}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="adtResVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={true}
                          value={state.adtResVal}
                          onChange={handleChangeADTPrice("ATT", "ADT")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <span>{Number(state.adttotal).toFixed(2)}</span>
                    </td>
                    <td>
                      <span>{state.adts}</span>
                    </td>
                    <td>{Number(state.adttotalamt).toFixed(2)}</td>
                      </tr>
                      {state.chds !== 0 &&
                  <tr>
                    <td>
                      <span>CHD</span>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdbase}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdtaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdtaxes}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdmarkup}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.chdsf}
                          disabled={state.disable}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.chdPenalty}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="chdResVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={true}
                          value={state.chdResVal}
                          onChange={handleChangeCHDPrice("ATT", "CHD")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <span>{Number(state.chdtotal).toFixed(2)}</span>
                    </td>
                    <td>
                      <span>{state.chds}</span>
                    </td>
                    <td>{Number(state.chdtotalamt).toFixed(2)}</td>
                  </tr>
                  }
                  {state.infs !== 0 && (
                  <tr>
                    <td>
                      <span>INF</span>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infbase" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infbase}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="inftaxes" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.inftaxes}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infmarkup" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infmarkup}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infsf" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          value={state.infsf}
                          disabled={state.disable}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infPenalty" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={state.disable}
                          value={state.infPenalty}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group as={Col} controlId="infResidualVal" className="mb-0 px-0">
                        <Form.Control
                          min={0}
                          placeholder="0"
                          autoComplete="off"
                          disabled={true}
                          value={state.infResidualVal}
                          onChange={handleChangeINFPrice("ATT", "INF")}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <span>{(state.inftotal).toFixed(2)}</span>
                    </td>
                    <td>
                      <span>{state.infs}</span>
                    </td>
                    <td>{state.inftotalamt.toFixed(2)}</td>
                  </tr>
                  )}
                  </table>
            </div>
            )}
            </div>
           </div>
        <div className="ticketSummerynew d-flex justify-content-between">
          {state.adts > 0 && (
            <div className="commonWrap">
                <h5 className="layoutTitle d-flex justify-content-between ticketSummaryTitle">
                  Ticket Summary Supplier
                </h5>
             <div className="table-responsive">
                  <table class="m-0 table">
                    <tr>
                      <th width="16%">Pax Type</th>
                      <th width="16%">Sub Total x Pax</th>
                      <th width="16%">
                        Supplier Changes
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <span>ADT</span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotal).toFixed(2)} x {state.adts}
                        </span>
                      </td>
                      <td>
                        <span>
                          {(Number(state.adttotal) * Number(state.adts)).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    {state.chds > 0 && (
                      <tr>
                        <td>
                          <span>CHD</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotal).toFixed(2)} x {state.chds}
                          </span>
                        </td>
                        <td>
                          <span>
                            {(Number(state.chdtotal) * Number(state.chds)).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {state.infs > 0 && (
                      <tr>
                        <td>
                          <span>INF</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotal).toFixed(2)} x {state.infs}
                          </span>
                        </td>
                        <td>
                          <span>
                            {(Number(state.inftotal) * Number(state.infs)).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td align="right" className="pr-0 brandColor">Total Charges :</td>
                      <td className="brandColor">
                        {(Number(state.adttotalamt) +
                        Number(state.chdtotalamt) +
                        Number(state.inftotalamt)).toFixed(2)}
                      </td>
                    </tr>
                   </table>
                </div>
            </div>
          )}
          {state.adts > 0 && (
              <div className="commonWrap">
                <h5 className="layoutTitle d-flex justify-content-between ticketSummaryTitle">
                  Ticket Summary Customer
                </h5>
                <div className="table-responsive">
                  <table class="m-0 table">
                    <tr>
                      <th width="16%">Passenger Type</th>
                      <th width="16%">Sub Total x Pax</th>
                      <th width="16%">Customer Changes</th>
                    </tr>
                    <tr>
                      <td>
                        <span>ADT</span>
                      </td>
                      <td>
                        <span>
                          {Number(state.adttotalta).toFixed(2)} x {state.adts}
                        </span>
                      </td>
                      <td>
                        <span>
                          {(Number(state.adttotalta) * Number(state.adts)).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    {state.chds > 0 && (
                      <tr>
                        <td>
                          <span>CHD</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.chdtotalta).toFixed(2)} x {state.chds}
                          </span>
                        </td>
                        <td>
                          <span>
                            {(Number(state.chdtotalta) * Number(state.chds)).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    {state.infs > 0 && (
                      <tr>
                        <td>
                          <span>INF</span>
                        </td>
                        <td>
                          <span>
                            {Number(state.inftotalta).toFixed(2)} x {state.infs}
                          </span>
                        </td>
                        <td>
                          <span>
                            {(Number(state.inftotalta) * Number(state.infs)).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td align="right" className="pr-0 brandColor">Total Charges :</td>
                      <td className="brandColor">
                        {(Number(state.adttotalamtta) +
                        Number(state.chdtotalamtta) +
                        Number(state.inftotalamtta)).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
        </div>
        <div>
        <div className="commonWrap">
        <label className="paymentMode">
                <input
                  type="radio"
                  value={paymentActive}
                  checked={paymentActive === "payment"}
                  onChange={() => handleRadioChange("payment")}
                  className="paymentModeInput"
                />
                Continue with payment
              </label>
              <label className="paymentMode">
                <input
                  type="radio"
                  value={paymentActive}
                  checked={paymentActive === "remarks"}
                  onChange={() => handleRadioChange("remarks")}
                  className="paymentModeInput ml-5"
                />
                Continue without payment
              </label>
{console.log(fltresponse.bkng_info.booking_id, "hswhsj")}
          {paymentActive === "payment" &&(
          <PaymentDetails 
            booking_id={fltresponse.bkng_info.booking_id}
            type="REISSUE"
            sendPaymentinfo={updatePayment}
            region_type={state.region_type}
          />)}
          {paymentActive === "remarks" && (
               <>
               <h5 class="layoutTitle  d-flex justify-content-between mb-3"><span>Remarks<sup>*</sup></span></h5>
                <textarea
                  value={remarks}
                  onChange={handleRemarksChange}
                  placeholder="Enter remarks"
                  className="remarksArea"
                  maxLength={100}
                />
               </>
              )}
          </div>
          </div>
          <div className="d-flex justify-content-end footerWrap">
            <div className="text-right mr-3">
              <Button
               className="btn btn-outline-secondary"
                onClick={() => cancel()}
                type="submit"
              >
                Cancel
              </Button>
            </div>

            {!isLoad ? (
              <div className="text-right">
                <Button
                  variant="primary"
                  onClick={() => continueBooking()}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            ) : (
              <div class="progress subMitt progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Processing...</span>
                </div>
              </div>
            )}
          </div>
      </div>
      <Footer />
                  </Col>
                
</Row>

       </Container>
      
      </>
    );
}

export default FlightSummaryManualReissue;