import log from "loglevel";
import React, { useReducer, useState, useRef, useCallback, useContext, useEffect } from 'react';
import { Context } from "../../../App";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Button from '../../common/Button';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import ManageBookingService from "../../../services/ManageBookingService";
import moment from "moment";
import debounce from 'lodash.debounce';
import Utilities from "../../utilities/Utilities";
let airlineMaster = require('../../masterData/AirlinesMasterData.json');
let rbdMaster = require("../../masterData/Rbd.json");
/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
    notiMessage: '', notiMessageShow: false, notiVarient: '', noRecords: false, flight_no: "", dep_airport: "", arr_airport: "", dep_terminal: "", arr_terminal: "", air_pnr: "", checkin_baggage: "", cabin_baggage: "", marketing_airline: "", operating_airline: "", cabin: [], selCabin: [], bagValueType: [], selBagValueType: [], rbd: "", sector: "", aircraft_type: "", segment_details: [], all_segments: [], pa: "", na: "", isc: "", selIsc: [], isd: null, exd: null, gdsType: "GDS", ofce_id: "", pnr: "", airlines: [], isItineraryLoading: false, selSector: [], supplier: "0",
    sno: 0, enableSeg: false, selRbd: [], selSegOrign: [], selSegDest: [], selTempOrign: [], selTempDest: [],segNo: 0, selMa: [], selOa: [], departure_date: null, arrival_date: null, adtinf: [], disable: false, enableSector: false, selFltType: [], manual_resp: "", insertPnr: "", selpaxfn: [], updateinf: false, insertGDSPnr: "", seldeparture_date: null, selarrival_date: null, depdate: null, arrdate: "", selsa: "", selmp: "", selsp: "", lcc_sup: "", onwBglabel: "", onwBgPrice: "", retBglabel: "", retBgPrice: "", onwMeallabel: "", onwMealPrice: "", retMeallabel: "", retMealPrice: "", onwSeatlabel: "", onwSeatPrice: "", retSeatlabel: "", retSeatPrice: ""
    , airportsOW: [], airportsRT: [], orignData: [], selOrigin: [], src: "", dest: "", destData: [], selDest: [], cleardb: false, clearob: false, fareop: "", sector:[]
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function FlightSegments(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const classesoption = [{ label: "Economy", value: 'Economy' }, { label: "Business", value: 'Business' }, { label: "First", value: 'First' }]
    const bgdoption = [{ label: "Kg", value: "Kg" }, { label: "Pieces", value: "Pieces" }]
    const [destinationIsFocus, setDestinationIsFocus,] = useState(false)
    const uref = useRef();
    const originref = useRef();

    const sectors=[{label:"Onward", value:"O"}, {label:"Return", value:"R"}]
   
   useEffect(()=>{
    if(props.owseginfo!==undefined){
        dispatch({ type: "segment_details", payload: props.owseginfo })
        dispatch({ type: "all_segments", payload: props.owseginfo })
        dispatch({ type: "manual_resp", payload: "TRUE" })
    }
   },[props.owseginfo])

   useEffect(()=>{
if(props.onward_fltresponse && props.mappings){
   let segData=[]
    for(let i = 0; i < props.onward_fltresponse.length; i++){
        const ow_seg = props.onward_fltresponse[i];
        const segSource = props.mappings.find(airport => airport.airportCode === ow_seg.seg_source);
        const segDestination = props.mappings.find(airport => airport.airportCode === ow_seg.seg_destination);
        let baggage= ow_seg.bgd.split(" ");
        const segPayload = {
        ac: ow_seg.markting_airline,
        ma: ow_seg.markting_airline,
        oa: ow_seg.operating_airline,
        sno: ow_seg.seg_order,
        da: segSource.airportCode,
        ar: segDestination.airportCode,
        dd: ow_seg.depart_time,
        ad:ow_seg.arrival_time,
        rbd: ow_seg.rbd,
        fn: ow_seg.flt_no,
        // ac: state.aircraft_type,
        chb: baggage[0],
        bt: baggage[1],
        cb: ow_seg.cabin_bag,
        airpnr: ow_seg.normal_airline_pnr,
        cl: ow_seg.cabin_class,
        meal: ow_seg.meals,
        sector: ow_seg.journey_type,
        dt: ow_seg.depart_time,
        at: ow_seg.arrival_time,
        dnm: segSource.airportName,
        anm: segDestination.airportName,
        fare_type: ow_seg.fare_type,
        terminal: {
            arr: ow_seg.arrival_terminal,
            dep: ow_seg.depart_terminal
        },
    }
    segData.push(segPayload);
   }
   props.sendOwSegments(segData)
 dispatch({ type: "segment_details", payload: segData });
 dispatch({ type: "all_segments", payload: segData });
 dispatch({ type: "manual_resp", payload: "TRUE" });
 }
},[props.onward_fltresponse]) 
   
   useEffect(()=>{
    if(props.rtseginfo!==undefined){
        dispatch({ type: "segment_details", payload: props.rtseginfo })
        dispatch({ type: "all_segments", payload: props.rtseginfo })
        dispatch({ type: "manual_resp", payload: "TRUE" })
    }
   },[props.rtseginfo])

    const handleMA = (e) => {
        dispatch({ type: "selMa", payload: [] })
        if (e && e[0] !== undefined && e[0] !== null) {
            dispatch({ type: "marketing_airline", payload: e[0].value })
            dispatch({ type: "selMa", payload: [{ "label": e[0].label, "value": e[0].label }] })
        }
    }
    const handleOA = (e) => {
        dispatch({ type: "selOa", payload: [] })
        if (e && e[0] !== undefined && e[0] !== null) {
            dispatch({ type: "operating_airline", payload: e[0].value })
            dispatch({ type: "selOa", payload: [{ label: e[0].label, value: e[0].label }] })
        }
    }
    const handleChangePnr = (evt) => {
        const { id, value } = evt.target;
        dispatch({ type: id, payload: value });
    }
    const handleSector = (obj) => {
        dispatch({ type: "sector", payload: obj?.value });
    }

    const handlesegChange = (e) => {
        const { id, value } = e.target;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        const re = /^[0-9\b]+$/;
        if (id == "flight_no") {
            dispatch({ type: "flight_no", payload: value })
        }
        if (id == "dep_air") {
            dispatch({ type: "dep_airport", payload: value })
        }
        if (id == "arr_air") {
            dispatch({ type: "arr_airport", payload: value })
        }
        if (id == "dep_terminal") {
            dispatch({ type: "dep_terminal", payload: value })
        }
        if (id == "arr_terminal") {
            dispatch({ type: "arr_terminal", payload: value })
        }
        if (id == "air_pnr") {
            dispatch({ type: "air_pnr", payload: value })
        }
        if (id == "cabin_allow") {
            if (e.target.value === '' || re.test(e.target.value)) {
                dispatch({ type: "cabin_baggage", payload: e.target.value })
            }
        }
        if (id == "bag_allow") {
            if (e.target.value === '' || re.test(e.target.value)) {
                dispatch({ type: "checkin_baggage", payload: e.target.value })
            }
        }
        if (id == "aircraft_type") {
            dispatch({ type: "aircraft_type", payload: value })
        }
        if (id == "meals") {
            dispatch({ type: "meals", payload: value })
        }
    }

    const handleCabin = (data) => {
        dispatch({ type: 'cabin', payload: data.value })
        dispatch({ type: 'selCabin', payload: [{ label: data.value, value: data.value }] })
    }

    const handleRbd = (data) => {
        dispatch({ type: 'rbd', payload: data.value })
        dispatch({ type: 'selRbd', payload: [{ label: data.label, value: data.value }] })
    }
    const handleBagType = (data) => {
        dispatch({ type: 'bagValueType', payload: data.value })
        dispatch({ type: 'selBagValueType', payload: [{ label: data.value, value: data.value }] })
    }

    const handleOnDest = (obj) => {
        if (obj.length > 0) {
            dispatch({ type: "dest", payload: obj[0].airportCode })
            dispatch({ type: "destData", payload: obj })
            dispatch({ type: "selDest", payload: obj })
            dispatch({ type: "selSegDest", payload: obj })
            if(state.selTempDest.length===0){
                dispatch({ type: "selTempDest", payload: obj })
            }else{
                state.selTempDest.push(obj[0])
                dispatch({ type: "selTempDest", payload: state.selTempDest })
            }
        }
    }

    const handleOnOrigin = (obj) => {
        if (obj.length > 0) 
            dispatch({ type: "src", payload: obj[0].airportCode })
            dispatch({ type: "orignData", payload: obj })
            dispatch({ type: "selOrigin", payload: obj })
            dispatch({ type: "selSegOrign", payload: obj })
            if(state.selTempOrign.length===0){
                dispatch({ type: "selTempOrign", payload: obj })
            }else{
                state.selTempOrign.push(obj[0])
                dispatch({ type: "selTempOrign", payload: state.selTempOrign })
            }
    }

    const handleDestination = (input, e) => {
        dispatch({ type: "dest", payload: "" })
        dispatch({ type: "destData", payload: [] })
        dispatch({ type: "selDest", payload: [] })
        if (input.length > 0) {
            dispatch({ type: "cleardb", payload: true })
        }
        else {
            dispatch({ type: "cleardb", payload: false })
        }
        if (input.length > 2) {
            state.selDest = [];
            let obj = {
                textValue: input,
                type: 'airportsRT'
            }
            debouncedSave(obj);
        }
        else {
            state.selDest = [];
            dispatch({ type: 'airportsRT', payload: [] })
        }
    }

    const debouncedSave = useCallback(
        debounce(obj => predectiveCall(obj.textValue, obj.type), 500),
        // [], // will be created only once initially
    );

    const predectiveCall = async (input, route) => {
        if (input.length > 1) {
            const payload = {
              searchCriteria: input
            };
            let tokenKey = Utilities.generateAPIToken(payload);
            await ManageBookingService.retreieveSearch(payload,tokenKey).then((data) => {
                dispatch({ type: route, payload: data.data !== "" ? data.data : [] })
            }).catch(function (error) {
                console.error("Error in Airports Predective Search" + error);      
            });
        }
        else {
            dispatch({ type: route, payload: [] })
        }
    }

    const handleOrigin = (input, e) => {
        dispatch({ type: "src", payload: "" })
        dispatch({ type: "orignData", payload: [] })
        dispatch({ type: "selOrigin", payload: [] })
        if (input.length > 0) {
            dispatch({ type: "clearob", payload: true })
        }
        else {
            dispatch({ type: "clearob", payload: false })
        }
        if (input.length > 2) {
            state.selOrigin = [];
            let obj = {
                textValue: input,
                type: 'airportsOW'
            }
            debouncedSave(obj);
        }
        else {
            state.selOrigin = [];
            dispatch({ type: 'airportsOW', payload: [] })
        }
    }

    const clearDestination = () => {
        dispatch({ type: "dest", payload: "" })
        dispatch({ type: "destData", payload: [] })
        dispatch({ type: "selDest", payload: [] })
        dispatch({ type: "cleardb", payload: false })
        uref.current.clear()
    }

    const clearOrigin = () => {
        dispatch({ type: "src", payload: "" })
        dispatch({ type: "orignData", payload: [] })
        dispatch({ type: "selOrigin", payload: [] })
        dispatch({ type: "clearob", payload: false })
        originref.current.clear()
    }

    const handleaddSeg = () => {
        if (segValidation()) {
            let dt = moment(state.departure_date).format('YYYY-MM-DD');
            let time = moment(state.departure_date).format('HH:mm:ss');
            let adt = moment(state.arrival_date).format('YYYY-MM-DD');
            let atime = moment(state.arrival_date).format('HH:mm:ss');
            const payload = {
                "ref": state.all_segments.length + 1,
                "da": state.src,
                "ar": state.dest,
                "dd": dt + "T" + time,
                "ad": adt + "T" + atime,
                "rbd": state.rbd,
                "fn": state.flight_no,
                "trn": "",
                "eq": "320",
                "ac": state.operating_airline,
                "ma": state.marketing_airline,
                "seat": "",
                "oa": state.operating_airline,
                "fa": "",
                "cl": state.cabin !== "" ? state.cabin : "Economy",
                "cb": state.cabin_baggage,
                "chb": state.checkin_baggage,
                "bt": state.bagValueType !== "" ? state.bagValueType : "kg",
                "terminal": {
                    "arr": state.arr_terminal,
                    "dep": state.dep_terminal
                },
                "rph": "",
                "airpnr": state.air_pnr,
                "sector": state.sector,
                "fare_type": state.fareop
            }
            const segPayload = {
                sno: state.all_segments.length + 1,
                da: state.orignData[0].airportCode,
                ar: state.destData[0].airportCode,
                dd: dt + "T" + time,
                ad: adt + "T" + atime,
                rbd: state.rbd,
                fn: state.flight_no,
                // ac: state.aircraft_type,
                ac: state.operating_airline,
                ma: state.marketing_airline,
                oa: state.operating_airline,
                chb: state.checkin_baggage,
                bt: state.bagValueType,
                cb: state.cabin_baggage,
                airpnr: state.air_pnr,
                cl: state.cabin,
                meal: state.meals,
                sector: state.sector,
                dt: state.dep_terminal,
                at: state.arr_terminal,
                dnm: state.orignData[0].airportName,
                anm: state.destData[0].airportName,
                fare_type: state.fareop,
                terminal: {
                    arr: state.arr_terminal,
                    dep: state.dep_terminal
                },
            }
            let all_segdetails = state.segment_details.concat(payload)
            dispatch({ type: "segment_details", payload: all_segdetails })
            let all_seg = state.all_segments.concat(segPayload)
            dispatch({ type: "all_segments", payload: all_seg })
            dispatch({ type: "dest", payload: "" })
            dispatch({ type: "src", payload: "" })
            if(props.trip==="Onward"){
                props.sendOwSegments(all_seg);
            }else if(props.trip==="Return"){
                props.sendRtSegments(all_seg);
            }
            clearSegment();
        }
    }
    const updateSegment = () => {
        if (segValidation()) {
            for (let seg of state.all_segments) {
                if (seg.sno === state.segNo) {
                    const terminal = {
                        "arr": state.arr_terminal,
                        "dep": state.dep_terminal
                    }
                    seg.da = state.orignData[0].airportCode;
                    seg.ar = state.destData[0].airportCode;                    
                    let dt = moment(state.departure_date).format('YYYY-MM-DD');
                    let time = moment(state.departure_date).format('HH:mm:ss');
                    let adt = moment(state.arrival_date).format('YYYY-MM-DD');
                    let atime = moment(state.arrival_date).format('HH:mm:ss');
                    if (state.manual_resp === "") {
                        seg.dd = state.departure_date;
                        seg.ad = state.arrival_date;
                    } else {
                        seg.dd = dt + "T" + time;
                        seg.ad = adt + "T" + atime;
                    }
                    seg.rbd = state.rbd;
                    seg.fn = state.flight_no;
                    seg.airpnr = state.air_pnr;
                    seg.chb = state.checkin_baggage;
                    seg.bt = state.bagValueType;
                    seg.cb = state.cabin_baggage;
                    seg.ma = state.marketing_airline;
                    seg.oa = state.operating_airline;
                    seg.cl = state.cabin;
                    seg.meal = state.meals;
                    seg.sector = state.sector;
                    seg.dnm = state.orignData[0].airportName;
                    seg.anm = state.destData[0].airportName;
                    seg.fare_type = state.fareop;
                    seg.terminal = terminal;
                }
            }
            dispatch({ type: 'enableSeg', payload: false })
            dispatch({ type: "segment_details", payload: state.all_segments })
            if(props.trip==="Onward"){
                props.sendOwSegments(state.all_segments);
            }else if(props.trip==="Return"){
                props.sendRtSegments(state.all_segments);
            }
            clearSegment();
        }
    }

    const clearSegment = () => {
        dispatch({ type: "departure_date", payload: null })
        dispatch({ type: "depdate", payload: null })
        dispatch({ type: "seldeparture_date", payload: null })
        dispatch({ type: "arrival_date", payload: null })
        dispatch({ type: "arrdate", payload: null })
        dispatch({ type: "selarrival_date", payload: null })
        dispatch({ type: "rbd", payload: "" })
        dispatch({ type: "flight_no", payload: "" })
        dispatch({ type: "aircraft_type", payload: "" })
        dispatch({ type: "dep_terminal", payload: "" })
        dispatch({ type: "arr_terminal", payload: "" })
        dispatch({ type: "cabin", payload: "" })
        dispatch({ type: "air_pnr", payload: "" })
        dispatch({ type: "checkin_baggage", payload: "" })
        dispatch({ type: "selBagValueType", payload: [] })
        dispatch({ type: "bagValueType", payload: "" })
        dispatch({ type: "cabin_baggage", payload: "" })
        dispatch({ type: "meals", payload: "" })
        dispatch({ type: "sector", payload: [] })
        dispatch({ type: "selCabin", payload: [] })
        dispatch({ type: "marketing_airline", payload: [] })
        dispatch({ type: "operating_airline", payload: [] })
        dispatch({ type: "selDest", payload: [] })
        dispatch({ type: "selOrigin", payload: [] })
        dispatch({ type: "orignData", payload: [] })
        dispatch({ type: "destData", payload: [] })
        dispatch({ type: "selRbd", payload: [] })
        dispatch({ type: "selSector", payload: [] })
        dispatch({ type: "selMa", payload: [] })
        dispatch({ type: "selOa", payload: [] })
        dispatch({ type: "clearob", payload: false })
        dispatch({ type: "fareop", payload: "" })
        dispatch({ type: "cleardb", payload: false })
        dispatch({ type: "enableSeg", payload: false })
    }

    const handleEditSeg = (obj) => {
        dispatch({ type: 'enableSeg', payload: true })
        dispatch({ type: 'segNo', payload: obj.sno })
        if(state.selTempOrign.length > 1){
            let orgins=state.selTempOrign.filter(org => org !== undefined && org.airportCode == obj.da)
            dispatch({ type: 'orignData', payload: orgins })
            dispatch({ type: 'selOrigin', payload: orgins })
        }else{
            dispatch({ type: 'orignData', payload: state.selSegOrign })
            dispatch({ type: 'selOrigin', payload: state.selSegOrign })
        }
        if(state.selTempDest.length > 1){
            let destins=state.selTempDest.filter(org => org !== undefined && org.airportCode == obj.ar)
            dispatch({ type: 'destData', payload: destins })
            dispatch({ type: 'selDest', payload: destins })
        }else{
            dispatch({ type: 'destData', payload: state.selSegDest })
            dispatch({ type: 'selDest', payload: state.selSegDest })
        }
        if (state.manual_resp === "") {
            dispatch({ type: 'departure_date', payload: obj.dd })
            dispatch({ type: 'arrival_date', payload: obj.ad })
        } else {
            dispatch({ type: 'departure_date', payload: new Date(obj.dd) })
            dispatch({ type: 'arrival_date', payload: new Date(obj.ad) })
        }
        dispatch({ type: 'flight_no', payload: obj.fn })
        const ma_airline = airlineMaster.filter(airline => airline.value == obj.ma)
        dispatch({ type: 'marketing_airline', payload: obj.ma })
        if (ma_airline !== undefined && ma_airline.length !== 0) {
            dispatch({ type: 'selMa', payload: [{ "value": ma_airline[0].value, "label": ma_airline[0].label }] })
        }
        const oa_airline = airlineMaster.filter(airline => airline.value == obj.oa)
        dispatch({ type: 'operating_airline', payload: obj.oa })
        if (oa_airline !== undefined && oa_airline.length !== 0) {
            dispatch({ type: 'selOa', payload: [{ "value": oa_airline[0].value, "label": oa_airline[0].label }] })
        }
        dispatch({ type: 'air_pnr', payload: obj.airpnr })
        dispatch({ type: 'rbd', payload: obj.rbd })
        dispatch({ type: 'selRbd', payload: [{ "value": obj.rbd, "label": obj.rbd }] })
        dispatch({ type: 'cabin', payload: obj.cl })
        dispatch({ type: 'selCabin', payload: [{ "value": obj.cl, "label": obj.cl }] })
        dispatch({ type: 'checkin_baggage', payload: obj.chb })
        dispatch({ type: 'selBagValueType', payload: [{ "value": obj.bt, "label": obj.bt }] })
        dispatch({ type: 'bagValueType', payload: obj.bt })
        dispatch({ type: 'cabin_baggage', payload: obj.cb })
        dispatch({ type: 'meals', payload: obj.meal })
        dispatch({ type: 'sector', payload: obj.sector })
        dispatch({ type: 'fareop', payload: obj.fare_type })
        if (obj.terminal !== undefined) {
            dispatch({ type: 'dep_terminal', payload: obj.terminal.dep })
            dispatch({ type: 'arr_terminal', payload: obj.terminal.arr })
        } else {
            dispatch({ type: 'dep_terminal', payload: obj.dt })
            dispatch({ type: 'arr_terminal', payload: obj.at })
        }

        if (obj.sector !== undefined) {
            dispatch({ type: "selSector", payload: [{ "label": obj.sector === "O" ? "Onward" : "Return", "value": obj.sector }] })
        } else {
            dispatch({ type: "selSector", payload: [] })
        }
        if (props.mappings !== "" && props.manualReissue && state.selTempOrign.length==0 &&state.selSegOrign.length==0) {
            let arrList = []
            props.mappings.map((data) => {
                if (data.airportCode === obj.da) {
                    arrList.push(data);
                }
                dispatch({ type: 'orignData', payload: arrList })
                dispatch({ type: 'selOrigin', payload: arrList })
            });
            let daList = []
            props.mappings.map((data) => {
                if (data.airportCode === obj.ar) {
                    daList.push(data);
                }
                dispatch({ type: 'destData', payload: daList })
                dispatch({ type: 'selDest', payload: daList })

            });
        }
        if (props.mappings !== "" && !props.manualReissue) {
            let arrList = []
            props.mappings.map((data) => {
                if (data.airportCode === obj.da) {
                    arrList.push(data);
                }
                dispatch({ type: 'orignData', payload: arrList })
                dispatch({ type: 'selOrigin', payload: arrList })
            });
            let daList = []
            props.mappings.map((data) => {
                if (data.airportCode === obj.ar) {
                    daList.push(data);
                }
                dispatch({ type: 'destData', payload: daList })
                dispatch({ type: 'selDest', payload: daList })

            });
        }
    }

    const segValidation = () => {
        if (state.selMa.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Marketing Airline ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.selOa.length == 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Operating Airline ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.flight_no == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Flight No. ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        if (state.cabin == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Cabin Class' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (props.sup === "0" && state.rbd == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select RBD ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.selOrigin.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Departure ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.selDest.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Arrival ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.departure_date == null || state.departure_date == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Departure Date & Time for passenger ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.arrival_date == null || state.arrival_date == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Arrival Date & Time for passenger ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.checkin_baggage == "") {
        }
        else if (props.manualReissue && state.sector == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select sector type ' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        }
        else if (state.checkin_baggage == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter checkin baggage' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.cabin_baggage == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter cabin baggage' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.bagValueType == "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter baggage value type' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (props.sup === "1" && state.fareop === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Fare Option Type' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (props.sup === "1" && state.air_pnr === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter Airline PNR' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
        } else if (state.departure_date !== null && state.departure_date !== "" && state.arrival_date !== null && state.arrival_date !== "") {
            let de = new Date(state.departure_date).getTime()
            let ar = new Date(state.arrival_date).getTime()
            if (de === ar) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Departure Date&Time and Arrival Date&Time cant be same. ' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage();
                return false;
            } else if (de > ar) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Arrival Date&Time should be greater than Departure Date&Time.' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage();
                return false;
            }
        }
        return true;
    }


    const hidemessage = () => {
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
    }

    const handleRemoveSegment = (seg, idx) => {
        dispatch({ type: "all_segments", payload: state.all_segments.filter((s, sidx) => idx !== sidx) });
        dispatch({ type: "segment_details", payload: state.segment_details.filter((s, sidx) => idx !== sidx) });
        if(props.manualReissue){
            props.removeseg(state.all_segments.filter((s, sidx) => idx !== sidx))
        }
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Removed selected Segment successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
    }



    return (
        
        <div className="segementConfirmation mt-3">
            <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
                <div className="BookingDetails">
              
            <h5 className="layoutTitle  d-flex justify-content-between mb-3">{props.onward_fltresponse?<span>Segment Information</span>:<span>{props.trip}  Segment Details</span>}</h5>
            <Form.Row>
                <Form.Group as={Col} xs={3} className="selectplugin scrollHandle" controlId="marketing_airline">
                    <Form.Label>Marketing Airline <sup>*</sup></Form.Label>
                    <Typeahead
                        id="air_master"
                        options={airlineMaster}
                        placeholder="Enter Airline"
                        selected={state.selMa}
                        onChange={air => handleMA(air)}
                        />
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin" controlId="operating_airline">
                    <Form.Label>Operating Airline <sup>*</sup></Form.Label>
                    <Typeahead
                        id="basic-typeahead-single"
                        options={airlineMaster}
                        placeholder="Enter Airline"
                        selected={state.selOa}
                        onChange={air => handleOA(air)}
                    />

                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="flight_no" className="selectplugin">
                    <Form.Label>Flight No <sup>*</sup></Form.Label>
                    <Form.Control type="text" placeholder="Flight No" autoComplete="off" onChange={handlesegChange} value={state.flight_no} />
                </Form.Group>
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="cabin">
                    <Form.Label>Cabin Class <sup>*</sup></Form.Label>
                    <Select
                        options={classesoption}
                        searchable={false}
                        value={state.selCabin}
                        onChange={handleCabin}
                        placeholder="Select"
                        classNamePrefix='filter'
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F0F0F0',
                            primary: '#3156ab',
                            cursor: 'pointer',
                        },
                        })}
                    />
                </Form.Group>
                <Form.Group className="selectplugin scrollHandle" as={Col} xs={3} controlId="rbd">
                    <Form.Label>RBD {props.sup === "0" && <sup>*</sup>}</Form.Label>
                    <Select
                        options={rbdMaster}
                        searchable={false}
                        value={state.selRbd}
                        onChange={handleRbd}
                        placeholder="Select"
                        classNamePrefix='filter'
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F0F0F0',
                            primary: '#3156ab',
                            cursor: 'pointer',
                        },
                        })}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin manualForm">
                    <div className={"addInput destination" + (destinationIsFocus ? " active" : "")}>
                        <Form.Label>Departure <sup>*</sup></Form.Label>
                        <Typeahead
                        id="originmaster"
                        labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                        onInputChange={handleOrigin}
                        options={state.airportsOW}
                        selected={state.selOrigin}
                        onChange={airport => handleOnOrigin(airport)}
                        filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                        placeholder="Select Departure"
                        readOnly
                        minLength={1}
                        renderMenuItemChildren={(airport) => (
                            <>
                                <div className="airportNamecode-box">

                                    <div className="airPortcode">
                                        <div className="citIco">
                                            <span className="ezyIcon icon-plane"></span>
                                            <strong>{airport.cityName} </strong>
                                        </div>
                                        <small>{airport.airportCode}</small>
                                    </div>
                                    <div className="cityName">

                                        <span>{airport.countryName} </span>
                                    </div>


                                </div>

                            </>
                        )}
                        ref={originref}
                    />
                    
                    {state.clearob && <Button
                        size="sm"
                        className="predictiveClear ezyIcon icon-close"
                        onClick={clearOrigin}
                        variant="link">
                    </Button>
                    }
                    </div>

                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin manualForm">
                <div className="addInput arrival">
                    <Form.Label>Arrival<sup>*</sup></Form.Label>
                    <Typeahead
                            id="basic-typeahead-single"
                            labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                            onInputChange={handleDestination}
                            options={state.airportsRT}
                            placeholder="Select Arrival"
                            selected={state.selDest}
                            classNamePrefix='filter'
                            onChange={airport => handleOnDest(airport)}
                            filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                            readOnly
                            minLength={1}
                            renderMenuItemChildren={(airport) => (
                                <> <div className="airportNamecode-box">

                                    <div className="airPortcode">
                                        <div className="citIco">
                                            <span className="ezyIcon icon-plane"></span>
                                            <strong>{airport.cityName} </strong>
                                        </div>
                                        <small>{airport.airportCode}</small>
                                    </div>
                                    <div className="cityName">

                                        <span>{airport.countryName} </span>
                                    </div>


                                </div>
                                </>
                            )}
                            ref={uref}
                        />
                    {state.cleardb && <Button
                        size="sm"
                        className="predictiveClear ezyIcon icon-close"
                        onClick={clearDestination}
                        variant="link">
                    </Button>
                    }
                </div>
                </Form.Group>

                <Form.Group as={Col} xs={3} className="selectplugin datePicker departureTime" controlId="from">
                    <Form.Label>Departure Date & Time<sup>*</sup> </Form.Label>
                    <DatePicker
                        // selected={fromDate}
                        onChange={(date) => (dispatch({ type: 'departure_date', payload: date }))}
                        selected={state.departure_date}
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={1}
                        placeholderText="MM/DD/YYYY"
                        timeCaption="Time"
                        dateFormat="Pp"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                        fromDate={fromDate}
                        toDate={toDate}
                        minDate={new Date()}
                    />
                    <span className="calendar ezyIcon icon-newcalendar"></span>
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin datePicker arrivalTime" controlId="from">
                    <Form.Label>Arrival Date & Time<sup>*</sup> </Form.Label>
                    <DatePicker
                        onChange={(date) => (dispatch({ type: 'arrival_date', payload: date }))}
                        selected={state.arrival_date}
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={1}
                        minDate={state.departure_date}
                        timeCaption="Time"
                        placeholderText="MM/DD/YYYY"
                        dateFormat="Pp"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                        
                        toDate={toDate}
                    />
                    <span className="calendar ezyIcon icon-newcalendar"></span>

                </Form.Group>

                <Form.Group as={Col} xs={3} className="selectplugin" controlId="dep_terminal">
                    <Form.Label>Departure Terminal</Form.Label>
                    <Form.Control type="text" placeholder="Departure Terminal" autoComplete="off" onChange={handlesegChange} value={state.dep_terminal} />
                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="arr_terminal" className="selectplugin">
                    <Form.Label>Arrival Terminal</Form.Label>
                    <Form.Control type="text" placeholder="Arrival Terminal" autoComplete="off" value={state.arr_terminal} onChange={handlesegChange} />
                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="air_pnr" className="selectplugin">
                    <Form.Label>Airline PNR {props.sup === "1" && <sup>*</sup>}</Form.Label>
                    <Form.Control type="text" placeholder="Airline PNR" autoComplete="off" onChange={handlesegChange} value={state.air_pnr} />

                </Form.Group>
                {props.sup === "1" &&
                    <Form.Group as={Col} xs={3} controlId="fareop" className="selectplugin">
                        <Form.Label>Fare Option Type<sup>*</sup></Form.Label>
                        <Form.Control type="text" placeholder="Fare Option" autoComplete="off" onChange={handleChangePnr} value={state.fareop} />
                    </Form.Group>
                }
                {console.log(state.selSector,"gaahkd")}
                {props.manualReissue &&
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="sector">
                <Form.Label> Sector Type <sup>*</sup></Form.Label>
                <Select
                    options={sectors}
                    searchable={false}
                    value={state.selSector}
                    onChange={handleSector}
                />

            </Form.Group>
                }
                <Form.Group as={Col} xs={3} controlId="bag_allow" className="selectplugin">
                    <Form.Label>Checkin Baggage <sup>*</sup> </Form.Label>
                    <Form.Control type="text" placeholder="Checkin Baggage" autoComplete="off" onChange={handlesegChange} value={state.checkin_baggage} />

                </Form.Group>
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="bagValueType">
                    <Form.Label> Baggage Type{props.sup === "0" && <sup>*</sup>}</Form.Label>
                    <Select
                        options={bgdoption}
                        searchable={false}
                        value={state.selBagValueType}
                        onChange={handleBagType}
                        placeholder="Select"
                        className="showPoint"
                        classNamePrefix='filter'
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F0F0F0',
                            primary: '#3156ab',
                            cursor: 'pointer',
                        },
                        })}                                       
                    />

                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="cabin_allow" className="selectplugin">
                    <Form.Label>Cabin Baggage <sup>*</sup></Form.Label>
                    <Form.Control type="text" placeholder="Cabin Baggage" autoComplete="off" onChange={handlesegChange} value={state.cabin_baggage} />

                </Form.Group>

                {props.sup === "0" &&
                    <Form.Group as={Col} xs={3} controlId="meals" className="selectplugin">
                        <Form.Label>Meals</Form.Label>
                        <Form.Control type="text" placeholder="Meals" autoComplete="off" onChange={handlesegChange} value={state.meals} />

                    </Form.Group>
                }
            </Form.Row>


            <div className="d-flex justify-content-end text-right pb-3">
                {!state.enableSeg && !state.disable &&
                    <Button
                        size="xs"
                        variant="primary m-0"
                        type="submit"
                        onClick={handleaddSeg}
                        className="d-flex plusBtn align-items-center"
                    ><span className="ezyIcon icon-plus mr-2"></span>Add</Button>
                }
                {state.enableSeg &&
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            type="submit"
                            className="mr-3"
                            onClick={clearSegment}
                        > Cancel</Button>
                }
                {state.enableSeg &&
                   <Button
                    size="xs"
                    variant="primary"
                    type="submit"
                    onClick={updateSegment}
                  > Update</Button>
                }
            </div>
            <div className="table-responsive tableCell onWardsegInfo">
                <Table>
                    <thead>
                        <tr>
                            <th>Flight No</th>
                            <th>RBD</th>
                            <th>Cabin</th>
                            <th>Departure</th>
                            <th>Arrival</th>
                            <th>Airline PNR</th>
                            {props.manualReissue&&
                            <th>Sector</th>}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.all_segments.map((segment, idx) => (
                            <React.Fragment key={idx}>
                                <tr>
                                    <td>
                                        {segment.fn}
                                    </td>

                                    <td>
                                        {segment.rbd}
                                    </td>

                                    <td>
                                        {segment.cl}

                                    </td>

                                    <td>
                                        {segment.dnm + "(" + segment.da + ")"}
                                    </td>

                                    <td>
                                        {segment.anm + "(" + segment.ar + ")"}
                                    </td>

                                    <td>
                                        {segment.airpnr}
                                    </td>
                                   {props.manualReissue &&
                                    <td>
                                        {segment.sector=="O"?"Onward":"Return"}
                                    </td>}
                                    
                                    {!state.disable &&
                                        <td>
                                             <div className="d-flex editDele">
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-edit"
                                                dis
                                                onClick={() => handleEditSeg(segment, idx)}
                                                type="submit">
                                            </Button>
                                            <Button
                                                size="xs"
                                                variant="link"
                                                className="ezyIcon icon-delete"
                                                type="submit"
                                                onClick={() => handleRemoveSegment(segment, idx)}>
                                            </Button>
                                            </div>
                                        </td>
                                    }
                                   
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
            </div>
        </div>
    )
}
export default FlightSegments