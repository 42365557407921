import React from "react";
import { useContext } from "react";
import { Context } from "../../../../App";
import FormatCurrency from "../../../utilities/FormatCurrency";

function FareDetails(props) {
  const [context, setContext] = useContext(Context)
  return (
    <>
        <div level={3} className="heading">
        {"Fare Details"}
      </div>
      <div className="travellersWrapper">
        <span className="travellersTitle">{"Travellers"}:</span>
        <span className="travellersDetailWrap">
        {props.response && props.response.psngrs && props.response.psngrs.map((pax, index) => (
          <React.Fragment key={index}>
            
          <span className="travellersDetail">
          <i className="icon icon-newtick"></i>   {pax.tl} {pax.fn} {pax.ln} ({pax.ty}){" "}
            {pax.inf!==undefined && pax.inf!=="" &&
              <span className="infpsngr">
               <i className="icon icon-newtick"></i> {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
              </span>
            }
        </span>
        
          </React.Fragment>
          ))}
          </span>
      </div>
      
      <div className="tableHeader row m-0">
        <div className="tableValue col-md">
          {"Air Fare"}
        </div>
        <div className="tableValue col-md">
          {"Travel Insurance"}
        </div>
        <div className="tableValue col-md">
          {"Service Fee"}
        </div>
        <div className="tableValue col-md">
          {"Discount"}
        </div>
        <div className="tableValue col-md">
          {"Total Payable"}
        </div>
      </div>

      {props.response && props.response.trip_price!==undefined &&
      <div className="tableData row m-0">
        <div className="tableValue col-md">
          {props.response.trip_price.cur}    <FormatCurrency value={props.response.trip_price.air_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate :1)} className="currencyAmount"  />
         
        </div>
        <div className="tableValue col-md">
        {props.response.trip_price.cur} <FormatCurrency value={props.response.trip_price.ins_fare * (props.response.base_usr_exrate ? props.response.base_usr_exrate :1)} className="currencyAmount"  />
        </div>
        <div className="tableValue col-md">
        {props.response.trip_price.cur} <FormatCurrency value={props.response.trip_price.service_fee * (props.response.base_usr_exrate ? props.response.base_usr_exrate :1)} className="currencyAmount"  />
        </div>
        <div className="tableValue active col-md">
        {props.response.trip_price.cur} <FormatCurrency value={props.response.trip_price.disc * (props.response.base_usr_exrate ? props.response.base_usr_exrate :1)} className="currencyAmount"  />
        </div>
        <div className="tableValue col-md">
        {props.response.trip_price.cur} <FormatCurrency value={props.response.trip_price.total_price * (props.response.base_usr_exrate ? props.response.base_usr_exrate :1)} className="currencyAmount"  />
        </div>
      </div>
      
      }
      <div class="horizontalDivider"></div>
    </>
  );
}

export default FareDetails;
