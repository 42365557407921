import { Context } from "../../../App";
import React, { useEffect, useReducer, useState, useRef, useContext } from 'react';
import { Col, Form, Row,Table, Alert, Button, Modal} from "react-bootstrap";
import { isArrayNotEmpty, isNotNull } from '../../utilities/validators';
import Select from 'react-dropdown-select';
import mp from '../../masterData/MealPreference.json'
import sr from '../../masterData/SeatPreference.json'
import sa from '../../masterData/SpecialAssistance.json'
import Utilities from "../../utilities/Utilities";
export const EDIT_TICKET_DATA = "editTicketData";
export const EDIT_INITIAL_ONWARDPAX_DATA = "editinitialOnwardPaxData";
export const EDIT_INITIAL_RETURNPAX_DATA = "editinitialReturnPaxData";
let bookingStatus = require('../../masterData/BookingStatus..json');

/**
 * @author Praveen Varma
 * @date 29-11-2023
 * @description Flight booking summary passengers data
 * @param {*} props 
 * @returns 
 */

/**
* Initial State Declaration
*/
const initialState = {
    notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false, disablereturned: false, disableonward: false, onward: false, return: false, checkRefundable: false,ticketStatusList:[],paxTypeItems:[],isCancelBooking:false,hideReturnIt:false,enableSplit:false,enableReissue:true,emdAvail:false, cheked: false, showSplit: false, onHoldSplit: true,
    cancelPNR: false,
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};


let ticketsArr = []
let ticketReisueArr = []
let ticketReisueArrReturn = []
let onholdTicket = []
let onHoldJSONarray = []

function FlightSummaryPassengers(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [enableCheck, setEnableCheck] = useState(false)
    const [enableCheckOnward, setEnableCheckOnward] = useState(false)    
    const [enableCheckReturn, setEnableCheckReturn] = useState(false)    
    const [enableCheckbox, setEnableCheckbox] = useState(true)
    const [test, setTest] = useState(false)
    const [status, setStatus] = useState("")
    const checkboxRef = useRef(null);
    const checkboxRRef = useRef(null);
    const [context, setContext] = useContext(Context);
    const [pax_det, setPax_det] = useState([]);
    const [edit_reset_pax_det, setEditResetPax_det] = useState(props?.itiData?.pax_info?.length > 0 ? props?.itiData?.pax_info : []);
    const [paxEdit, setPaxEdit] = useState(false);
    const [showEditpop, setShowEditpop] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [noTicketNo, setNoTicketNo] = useState([]);
    const [selectedPax, setSelectedPax] = useState([]);

    const paxTypeList = [
        { "label": "ADT", "value": "21" }, 
        { "label": "CHD", "value": "31" },
        { "label": "INF", "value": "41" }
    ]

    useEffect(() => {
        if (props.itiData && props.itiData.pax_info && props.itiData.pax_info.length > 0) {
            setPax_det(props.itiData.pax_info);
            let pax_noticket_no = props?.itiData?.pax_info?.filter((pax, i) => pax.tkt_no === "");
            setNoTicketNo(pax_noticket_no);
        } else {
            setPax_det([]);
            setNoTicketNo([]);
        }
    }, []);

    const bagLabelConstruction = (tag) => {
        let tagChanged = ""
        if (tag && tag.includes("total")) {
            let splitted_tag = tag.split("total")
            tagChanged = splitted_tag[0]
        } else if (tag) {
            let splitted_tag = tag.split("-")
            tagChanged = splitted_tag[0] + " - " + splitted_tag[1]
        }
        return tagChanged;
    }
    const mealPreference = (m) => {
        if (m) {
            for (let val of mp) {
                if (val.code == m) {
                    return val.name
                }
            }
        } else {
            return ""
        }
    }
    const specialRequest = (s) => {
        if (s) {
            for (let val of sr) {
                if (val.value == s) {
                    return val.label
                }
            }
        } else {
            return "N/A"
        }
    }

    const specialAssistance = (s) => {
        if (s) {
            for (let val of sa) {
                if (val.code == s) {
                    return val.name
                }
            }
        } else {
            return ""
        }
    }

    /**
     * @description : tickets to be voided are pushed in an array
     * @author : azamuddin
     * @date : 30-11-2020
     */
    //---------split------------


    useEffect(() => {
       if (props.enbaleReissueReturn) {
           ticketsArr = []
           setEnableCheck(true)
           dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
           props.voidTickets(ticketsArr)
       }
   }, [props.enbaleReissueReturn])
//      const ticketsTobeSplit = (e)=>{
//         var tkts_To_Hold = [];

//          if(e.target.checked == true){

//          tkts_To_Hold.push(e.target.id);
//          }
//         else{
//         tkts_To_Hold.pop(e.target.id);
//         }
//      }
const onHoldsplitSelection = (pax) => (e) => {
   const { id, value } = e.target;
   if (onholdTicket.indexOf(id)
> -1) {
       //available needs to remove
       onholdTicket = onholdTicket.filter(x => x !== id)
       onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== id)
       let chdTkt = ""
       //removal of inf if adt is selected
       if(pax.pax_type === "ADT") {
           for (let val of pax_det) {
               if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                   const idx = onholdTicket.indexOf(val.pax_name)
                   // ticketsArr.push(val.tkt_no);
                   if(idx > -1) {
                       chdTkt = val.pax_name
                       onholdTicket = onholdTicket.filter(x => x !== val.pax_name)
                       onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== val.pax_name)
                   }
               }
           }
       }

      //removal of adt if inf is selected
       if(pax.pax_type === "INF") {
           for (let val of pax_det) {
               if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                   const idx = onholdTicket.indexOf(val.pax_name)
                   // ticketsArr.push(val.tkt_no);
                   if(idx > -1) {
                       chdTkt = val.paxsno
                       onholdTicket = onholdTicket.filter(x => x !== val.pax_name)
                       onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== val.pax_name)
                   }
               }
           }
       }
       let array = onHoldJSONarray
           // props.onHoldsplitRequest(onHoldJSONarray)
       } else {
        //not available add
        onholdTicket = [...onholdTicket, id]
           if (pax.pax_type === "ADT") {
               for (let val of pax_det) {
                   if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                       const payload = {
                           "nm": val.pax_name,
                           "tkt": "",
                           "ty": "INF",
                           "paxsno":val.paxsno,
                           "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                           "bktype": "ONHOLD",
                           "tid": val.tid,
                           "trv_id":val.trv_id
                       }
                       onHoldJSONarray.push(payload);
                       onholdTicket.push(val.pax_name);
                   }
               }
           }
           else if(pax.pax_type === "INF") {
               for (let val of pax_det) {
                   if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                       const payload = {
                           "nm": val.pax_name,
                           "tkt": "",
                           "ty": "ADT",
                           "paxsno":val.paxsno,
                           "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                           "bktype": "ONHOLD",
                           "tid": val.tid,
                           "trv_id":val.trv_id
                       }
                       onHoldJSONarray.push(payload);
                       onholdTicket.push(val.pax_name);
                   }
               }
           }

           const payload = {
               "nm": pax.pax_name,
               "tkt": "",
               "ty": pax.pax_type,
               "paxsno":pax.paxsno,
               "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
               "bktype": "ONHOLD",
               "tid": pax.tid,
               "trv_id":pax.trv_id
           }
           onHoldJSONarray.push(payload);

        }
        if(pax_det.length === onHoldJSONarray.length ){
         dispatch({type: "onHoldSplit", payload: false})
        }
        else{
           dispatch({type: "onHoldSplit", payload: true})
        }
}




    useEffect(() => {
        if (props.enableVoidBookingOnward) {
            // props.disableReturn(["1"])
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.enableVoidBookingOnward])

    useEffect(() => {
        if (props.enableVoidBookingReturn) {
            // props.disableOnward(["1"])
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.enableVoidBookingReturn])

    //---------split------------
    useEffect(() => {
        if (props.enableRefundOnward) {
            props.disableReturn(["1"])
            ticketsArr = []
            setEnableCheck(true)
            // dispatch({ type: 'returnVoid', payload: false })
            dispatch({ type: "enabelRefnd", payload: true })
            dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
            dispatch({ type: "rtype", payload: "ATC Refund" })
        }
    }, [props.enableRefundOnward])

    useEffect(() => {
        if (props.enableRefundReturn) {
            props.disableOnward(["1"])
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: "enabelRefnd", payload: true })
            dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
            dispatch({ type: "rtype", payload: "NON ATC Refund" })
        }
    }, [props.enableRefundReturn])


    //-----------normal-----------
    //voidbooking
    useEffect(() => {
        if (props.enableVoidBooking) {
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.enableVoidBooking])

    //voidcancellation
    useEffect(() => {
        if (props.enableVoidCancel) {
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.enableVoidCancel])

    //disable void selection
    useEffect(() => {
        if (props.disableCheckbox) {
            setEnableCheck(false)
            ticketsArr = []
            dispatch({ type: 'disablereturned', payload: false })
            dispatch({ type: 'disableonward', payload: false })
            dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.disableCheckbox])


 useEffect(() => {
  const ticketsList = bookingStatus.map((obj) =>
  <option value={obj.value}>{obj.label}</option>
  );
  const paxtypeList = paxTypeList.map((obj) =>
  <option value={obj.value}>{obj.label}</option>
  );
  dispatch({ type: 'ticketStatusList', payload: ticketsList })
  dispatch({ type: 'paxTypeItems', payload: paxtypeList })
  if(pax_det!==undefined && pax_det.length!==0 && props.isEdit){
       for(let pax of pax_det){
        let pax_type = paxTypeList.filter((px) => px.label === pax.pax_type);
        pax.paxtypeid = pax_type[0]?.value
        let seg_sts = bookingStatus.filter((seg) => seg.label === pax.segst);
        pax.sstid = seg_sts[0]?.value
        let tkt_sts = bookingStatus.filter((tktst) => tktst.label === pax.sts);
        pax.tstid = tkt_sts[0]?.value
       }
  }
  }, [props.isEdit])

    /**to diable if split  */
    // useEffect(() => {
    //     if (props.enableVoidBookingOnward || props.enableVoidBookingReturn) {
    //         if (ticketsArr.length > 0) {
    //             let stack = false
    //             if (props.onTkts && props.onTkts.length > 0) {
    //                 for (let val of props.onTkts) {

    //                     if (ticketsArr.includes(val)) {

    //                         dispatch({ type: 'disablereturned', payload: true })
    //                         stack = true
    //                     }
    //                 }
    //             }
    //             if (props.retTkts && props.retTkts.length > 0 && !stack) {
    //                 for (let val of props.retTkts) {
    //                     if (ticketsArr.includes(val)) {
    //                         dispatch({ type: 'disableonward', payload: true })

    //                     }
    //                 }
    //             }
    //         } else {
    //             dispatch({ type: 'disablereturned', payload: false })
    //             dispatch({ type: 'disableonward', payload: false })
    //         }
    //     }
    //     if(ticketsArr.length === 0) {
    //         dispatch({ type: 'disablereturned', payload: false })
    //         dispatch({ type: 'disableonward', payload: false })
    //     }
    //     if(pax_det.length!==0){
    //        for(let val of pax_det){
    //           for(let pax of val){
    //               if(isNotNull(pax.emd_number)){
    //                dispatch({ type: 'emdAvail', payload: true })
    //               }
    //           }
    //        }
    //   }
    // }, [props.onTkts, props.retTkts, ticketsArr])

    useEffect(() => {
       if(props.enablesptpnr) {
           onholdTicket = []
           onHoldJSONarray = []
       }
    },[props.enablesptpnr])
    useEffect(() => {
       checkboxRef.current && checkboxRef.current.focus();
       },[props.checkboxFocus])

    useEffect(() => {
       if(props.enableRsptpnr) {
           onholdTicket = []
           onHoldJSONarray = []
       }
    },[props.enableRsptpnr])
    useEffect(() => {
       checkboxRRef.current && checkboxRRef.current.focus();
    },[props.checkboxRState])


    const ticketsTobeRefunded = (pax) => (e) => {
        const { id, value } = e.target;
        if (ticketsArr.indexOf(id) > -1) {
            const index = ticketsArr.indexOf(id);
            if (index > -1) {
                // var array = ticketsArr.filter(x => x !== id);
                ticketsArr = ticketsArr.filter(x => x !== id)
                if(props.enableReisueTkt) {
                    ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== id)
                }
                if(props.ticketReisueArrReturn) {
                   ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== id)
                }

                if (ticketsArr.length === 0) {
                    dispatch({ type: 'disablereturned', payload: false })
                    dispatch({ type: 'disableonward', payload: false })
                }
                let chdTkt = ""
                //removal of inf if adt is selected
                if(props.enableReisueTkt || props.enbaleReissueReturn || props.enableRefundOnward || props.enableRefundTkt || props.enableRefundReturn || props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "ADT") {
                    for (let val of pax_det) {
                        if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                            const idx = ticketsArr.indexOf(val.tkt_no)
                            // ticketsArr.push(val.tkt_no);
                            if(idx > -1) {
                                chdTkt = val.tkt_no
                                ticketsArr = ticketsArr.filter(x => x !== val.tkt_no)
                                if(props.enableReisueTkt) {
                                    ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== val.tkt_no)
                                }
                                if(props.ticketReisueArrReturn) {
                                   ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== val.tkt_no)
                                }
                                if (ticketsArr.length === 0) {
                                    dispatch({ type: 'disablereturned', payload: false })
                                    dispatch({ type: 'disableonward', payload: false })
                                }
                            }
                        }
                    }
                }

               //removal of adt if inf is selected
                if(props.enableReisueTkt || props.enbaleReissueReturn || props.enableRefundOnward || props.enableRefundTkt || props.enableRefundReturn || props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "INF") {
                    for (let val of pax_det) {
                        if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                            const idx = ticketsArr.indexOf(val.tkt_no)
                            // ticketsArr.push(val.tkt_no);
                            if(idx > -1) {
                                chdTkt = val.tkt_no
                                ticketsArr = ticketsArr.filter(x => x !== val.tkt_no)
                                if(props.enableReisueTkt) {
                                    ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== val.tkt_no)
                                }
                                if(props.enbaleReissueReturn){
                                   ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== val.tkt_no)
                                }
                                if (ticketsArr.length === 0) {
                                    dispatch({ type: 'disablereturned', payload: false })
                                    dispatch({ type: 'disableonward', payload: false })
                                }
                            }
                        }
                    }
                }
            }
            //     let array = ticketsArr
            //         props.voidTickets(array)
            //         if (ticketsArr.length === 0) {
            //             dispatch({ type: 'disablereturned', payload: false })
            //             dispatch({ type: 'disableonward', payload: false })
            //     // }
            // }
        } else {
            // ticketsArr.push(id)
            ticketsArr = [...ticketsArr, id]
            if (props.enableRefundTkt && pax.pax_type === "ADT") {
                for (let val of props.itiData.pax_info) {
                    if (val.pax_type === "INF" && val.sts==="Confirmed" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }

            // adding inf if void clicked
            if(props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "ADT") {
                for (let val of pax_det) {
                    if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }

            if(props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "INF") {
                for (let val of pax_det) {
                    if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }

            if(props.enableRefundTkt && pax.pax_type === "INF") {
                for (let val of pax_det) {
                    if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }


            //For Reissue Onward
            if (props.enableReisueTkt) {
                if (pax.pax_type === "ADT") {
                    for (let val of pax_det) {
                        if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                            const payload = {
                                "nm": val.pax_name,
                                "tkt": val.tkt_no,
                                "ty": "INF",
                                "paxsno":val.paxsno,
                                "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                                "tid":val.tid,
                                "tkt_time" : val.tkt_time ? val.tkt_time : null,
                                "trv_id":val.trv_id
                            }
                            ticketReisueArr.push(payload);
                            ticketsArr.push(val.tkt_no);
                        }
                    }
                }
                else if(pax.pax_type === "INF") {
                    for (let val of pax_det) {
                        if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                            const payload = {
                                "nm": val.pax_name,
                                "tkt": val.tkt_no,
                                "ty": "ADT",
                                "paxsno":val.paxsno,
                                "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                                "tid":val.tid,
                                "tkt_time" : val.tkt_time ? val.tkt_time : null,
                                "trv_id":val.trv_id
                            }
                            ticketReisueArr.push(payload);
                            ticketsArr.push(val.tkt_no);
                        }
                    }
                }

                const payload = {
                    "nm": pax.pax_name,
                    "tkt": id,
                    "ty": pax.pax_type,
                    "paxsno":pax.paxsno,
                    "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
                    "tid":pax.tid,
                    "tkt_time" : pax.tkt_time ? pax.tkt_time : null,
                    "trv_id":pax.trv_id
                }
                ticketReisueArr.push(payload);
            }

            //Reissue Return
            if (props.enbaleReissueReturn) {
            if (pax.pax_type === "ADT") {
               for (let val of pax_det) {
                   if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                       const payload = {
                           "nm": val.pax_name,
                           "tkt": val.tkt_no,
                           "ty": "INF",
                           "paxsno":val.paxsno,
                           "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                           "tid":val.tid,
                           "tkt_time" : val.tkt_time ? val.tkt_time : null,
                           "trv_id":val.trv_id
                       }
                       ticketReisueArrReturn.push(payload);
                       ticketsArr.push(val.tkt_no);
                   }
               }
           }

           else if(pax.pax_type === "INF") {
               for (let val of pax_det) {
                   if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                       const payload = {
                           "nm": val.pax_name,
                           "tkt": val.tkt_no,
                           "ptype": "ADT",
                           "paxno":val.paxsno,
                           "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                           "tid":val.tid,
                           "tkt_time" : val.tkt_time ? val.tkt_time : null,
                           "trv_id":val.trv_id
                       }
                       ticketReisueArrReturn.push(payload);
                       ticketsArr.push(val.tkt_no);
                   }
               }
           }

           const payload = {
               "nm": pax.pax_name,
               "tkt": id,
               "ty": pax.pax_type,
               "paxsno":pax.paxsno,
               "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
               "tid":pax.tid,
               "tkt_time" : pax.tkt_time ? pax.tkt_time : null,
               "trv_id":pax.trv_id
           }
           ticketReisueArrReturn.push(payload);
       }

           if(!props.enbaleReissueReturn && props.onTkts && props.onTkts.includes(id)) {
                dispatch({ type: 'disablereturned', payload: true })
            } else if (!props.enbaleReissueReturn && props.retTkts && props.retTkts.includes(id)) {
                dispatch({ type: 'disableonward', payload: true })
            }
            // props.voidTickets(ticketsArr)
           }
           if(ticketsArr!==undefined && ticketsArr.length!==0 && props.itiData.pax_info!==undefined){
               let active_pax = props.itiData.pax_info.filter(s => (s.split_pnr == null || s.stsid === "23"))                
               let pax_details = props.itiData.pax_info.filter(s => (s.split_pnr == null || s.stsid === "31" || s.stsid === "39" || s.stsid === "42" || s.stsid === "44"))
               if(ticketsArr.length!==pax_details.length){
                   if(active_pax.length == 1){
                   dispatch({ type: 'enableSplit', payload: false })
                   dispatch({ type: 'enableReissue', payload: true })
                   }
                   else{
                   dispatch({ type: 'enableSplit', payload: true })
                   dispatch({ type: 'enableReissue', payload: false })
                   }
               }else{
                   dispatch({ type: 'enableSplit', payload: false })
                   dispatch({ type: 'enableReissue', payload: true })
               }
           }else{
                   dispatch({ type: 'enableReissue', payload: true })
                   dispatch({ type: 'enableSplit', payload: false })
           }
    }
    //split tickets

   const continueHoldSplitPnr = ()=>{
       props.sendOnHoldTkts(onHoldJSONarray)
       }

   const continueHoldRSplitPnr = ()=>{
       props.sendOnHoldTktsReturn(onHoldJSONarray)
   }



    //Refund Onward Ticket
    useEffect(() => {
        if (props.enableRefundTkt) {
            ticketsArr = []
            setEnableCheckOnward(true)
            setEnableCheckReturn(false)
            setCancel(true)
            dispatch({ type: 'checkRefundable', payload: false }) //condition to check if refundable checking is necessary or not
            //props.refundTickets(ticketsArr)
        }
    }, [props.enableRefundTkt])

    //Refund Return Ticket
    useEffect(() => {
        if (props.enableRefundReturnTkt) {
            ticketsArr = []
            setEnableCheckOnward(false)
            setEnableCheckReturn(true)
            setCancel(true)
            dispatch({ type: 'checkRefundable', payload: false }) //condition to check if refundable checking is necessary or not
            //props.refundTickets(ticketsArr)
        }
    }, [props.enableRefundReturnTkt])

    //Refund Ticket
    useEffect(() => {
        if (props.enableReisueTkt) {
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: false }) //condition to check if refundable checking is necessary or not
            //props.refundTickets(ticketsArr)
        }else{
           setEnableCheck(false)
        }
    }, [props.enableReisueTkt])

    useEffect(() => {
        if(props.isEdit) {
            setPaxEdit(false);
            setCancel(false);
        }
    }, [props.isEdit])


    const continueRefund = () => {
        if(ticketsArr && ticketsArr.length !== 0) {
            props.sendRefund([ticketsArr, "Manual Refund", props.trvl_type])
        } else if(state.cancelPNR) {
            if(selectedPax && selectedPax.length > 0) {
                props.sendRefund([selectedPax, "Manual Refund", props.trvl_type, state.cancelPNR])
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Select atleast One pax and proceed for Refund' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed to Refund' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }
    
    const cancelRefund = () => {
        setCancel(false);
        if(props.enableRefundTkt) {
            props.cancelRefundblock(false, "ONWARD");
        } else if (props.enableRefundReturnTkt) {
            props.cancelRefundblock(false, "RETURN");
        }
    }

    const continueEligibility = () => {
        props.sendReissueTickts(ticketReisueArr)
    }

    const continueSplitPnr = () => {
       props.sendReissueSplit(ticketReisueArr)
   }

   const continueSplitPnrreturn = () => {
       props.sendReissueSplitReturn(ticketReisueArrReturn)
   }
    const continueEligibilityReturn=()=>{
       props.sendReissueTicktsReturn(ticketReisueArrReturn)
    }


    const getStatus=(id)=>{
     let stary=bookingStatus.filter((obj) => obj.value === id);
     return stary[0].label;
    }


    const getPax=(id)=>{
     let stary=paxTypeList.filter((obj) => obj.value === id);
     return stary[0].label;
    }

    const handleOnKeyup = (e)=>{
       Utilities.handleKeyUp(e)
    }
    const handlePaxChange = (data,type) => (e) => {
        const { id, value } = e.target
        dispatch({ type: id, payload: value });
        localStorage.removeItem(EDIT_TICKET_DATA);
        if(pax_det.length!==0){
            for(let pax of pax_det){
            //    for(let pax of val){
                   if(pax.trv_op_id===data.trv_op_id){
                    pax.changed=true;
                    if(type==="segStatus"){
                        pax.segst=getStatus(value);
                        pax.sstid=value;
                    }
                    if(type==="tktStatus"){
                        pax.sts=getStatus(value);
                        pax.tstid=value;
                    }
                    if(type==="paxtype"){
                        pax.pax_type=getPax(value);
                        pax.paxtypeid=value;
                    }
                    if(type==="ticket"){
                        pax.tkt_no=value;
                    }
                    if(type==="name"){
                        pax.pax_name=value;
                    }
                //    }
               }
            }
            localStorage.setItem(EDIT_TICKET_DATA,JSON.stringify(pax_det));
       }
    }

    const cancelReissue = () => {
        return window.location = "" + props.bookingId;
    }

    const handleChange = evt => {
        const { id, value } = evt.target;
        let isbook=false;
        if (state.isCancelBooking) {
            dispatch({ type: "isCancelBooking", payload: false })
            isbook=false;
        }else{
            dispatch({ type: "isCancelBooking", payload: true })
            isbook=true;
        }
        dispatch({ type: id, payload: value });
        props.sendCancelBook(isbook);
    }

    const handlePaxEdit = () => {
        localStorage.removeItem(EDIT_TICKET_DATA);
        var paxInfo;
        if(props.trvl_type === "Onward") {
            paxInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_ONWARDPAX_DATA));
        } else {
            paxInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_RETURNPAX_DATA));
        }
        if(paxInfo !== null) {
            if(paxInfo!==undefined && paxInfo.length!==0 && props.isEdit){
                for(let pax of paxInfo){
                let pax_type = paxTypeList.filter((px) => px.label === pax.pax_type);
                pax.paxtypeid = pax_type[0]?.value
                let seg_sts = bookingStatus.filter((seg) => seg.label === pax.segst);
                pax.sstid = seg_sts[0]?.value
                let tkt_sts = bookingStatus.filter((tktst) => tktst.label === pax.sts);
                pax.tstid = tkt_sts[0]?.value
                }
            }
        }
        setPax_det(paxInfo);
        setPaxEdit(true);
    }

    const cancelEdit = () => {
        localStorage.removeItem(EDIT_TICKET_DATA);
        setPaxEdit(false);
        if(props.trvl_type === "Onward") {
            var paxInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_ONWARDPAX_DATA));
            setPax_det(paxInfo);
        } else {
            var paxInfo = JSON.parse(sessionStorage.getItem(EDIT_INITIAL_RETURNPAX_DATA));
            setPax_det(paxInfo);
        }
    }

    const callEditBooking = () => {
        setShowEditpop(true);
    }
    
    const oncloseEidt = () => {
        setShowEditpop(false);
      }

      const processEditBookingFlow = () => {
        // if (state.enableEdit) {
        //   processEditBookingFlowOnward();
        // } else if (state.enableEditBooking) {
        //   processEditBookingSplitReturn();
        // }
      }

      const hidemessage=() =>{
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

      const callPaxEditBooking = () => {
          props.processPaxEditBooking(props.trvl_type, "pax");
      }

      const cancelPnr = () => {
        let isbook = false;
        if(state.cancelPNR) {
            dispatch({ type: 'cancelPNR', payload: false });
            isbook = false;
        } else {
            dispatch({ type: 'cancelPNR', payload: true });
            isbook = true;
        }
        props.cancelPnr(isbook)
      }

      const handleCancelPnr = (paxObj) => {
        if(selectedPax.find(pax => pax.paxsno === paxObj.paxsno)) {
            setSelectedPax(selectedPax.filter(pax => pax.paxsno !== paxObj.paxsno));
        } else {
            setSelectedPax([...selectedPax, paxObj]);
        }
      }

    return (
        <div className='table-responsive mobileTable marginTop30'>
            {state.notiMessageShow &&
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
            }
            
            <div className={props.isEdit ? "passengerList editRp" : "passengerList"}>
            <div className="editIcontil">
            <h5 class="title mt-0">Passenger Details</h5>
            <div className="editBtnr d-flex">
            {props.isEdit && <Button className="d-flex" onClick={handlePaxEdit}> Edit <span className="calendar ezyIcon icon-edit ml-2"></span></Button>}
            </div>
            </div>
                {pax_det.length > 0 &&
                    <Table className="paxTable refundListpS">
                        <tr>
                            <th width="15%">Traveller</th>
                            <th width="10%">Pax Type</th>
                            <th width="15%">Ticket Numbers</th>
                            {pax_det[0].supplier == "0" &&
                                <th width="11%">Special Request</th>
                            }
                            {pax_det[0].supplier !== "0" &&
                                (
                                <>
                                    <th width="15%">Baggage</th>
                                    <th width="15%">Meal</th>
                                    <th width="15%">Seat</th>
                                </>
                                )
                            }
                            <th width="14%">Segment Status</th>
                            <th width="17%">Ticketing status</th>
                        </tr>
                        {pax_det.map((pax, index) => (
                            <React.Fragment key={index}>
                                <tr className={pax.stsid === "31" || pax.stsid === "39" || pax.stsid === "42" || pax.stsid === "44"}>

                                {!paxEdit &&
                                    <td>
                                        <div className="voidTkt">
                                            {pax.pax_name?.toUpperCase()}{(isNotNull(pax.is_lead_pax) && pax.is_lead_pax !== 0) && '(lead)'}
                                        </div>
                                    </td>
                                }
                                {paxEdit &&
                                    <td>
                                        <div className="voidTkt">    
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="pax_name" className="edittime d-flex align-items-center justify-content-between m-0">
                                                <Form.Control type="text" placeholder="Enter Pax name" value={pax.pax_name} onChange={handlePaxChange(pax,"name")} />
                                            </Form.Group>
                                         </Form.Row>
                                        </div>
                                    </td>
                                }
                                {!paxEdit &&
                                    <td>{pax.pax_type}</td>
                                }

                                {paxEdit &&
                                <td>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="paxtypeid" className="selectplugin customSelect editOne m-0" >
                                            <Form.Control as="select" className="p-0" value={pax.paxtypeid} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"paxtype")}>
                                            {state.paxTypeItems}
                                            </Form.Control>
                                        </Form.Group>
                                 
                                               
                                    </Form.Row>
                                </td>
                                }

                                {cancel && pax.supplier == 0 && pax.tkt_no==="" &&
                                <td>                                
                                    {(pax.sts!=="Refund/booking" && pax.sts!=="Refund/cancel"  && pax.stsid !== "31" && pax.stsid !== "39" && pax.stsid !== "42" && pax.stsid !== "44") &&
                                    <>
                                        {state.cancelPNR && <Form.Check className="checkBoxd" type="checkbox" label="" disabled={pax.sts==="Cancelled" || pax.sts==="Refund/booking" || pax.sts==="Refund/cancel"} checked={!!selectedPax.find(item => item.paxsno === pax.paxsno)} onChange={() => handleCancelPnr(pax)} custom id={pax.paxsno} /> }
                                        <span className="checkBoxml">
                                            {pax.tkt_no ? pax.tkt_no : "N/A"} {(pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {pax.tkt_no ? "(E)" : ""}
                                        </span>
                                    </>
                                    }
                                </td>
                                }


                                {cancel && pax.supplier == 0 && pax.tkt_no!=="" &&
                                <td>                                
                                    {(pax.sts!=="Refund/booking" && pax.sts!=="Refund/cancel"  && pax.stsid !== "31" && pax.stsid !== "39" && pax.stsid !== "42" && pax.stsid !== "44") && pax.tkt_no!=="" ?
                                    <>
                                        {!state.cancelPNR && <Form.Check className="checkBoxd" type="checkbox" label="" disabled={pax.sts==="Cancelled" || pax.sts==="Refund/booking" || pax.sts==="Refund/cancel"} checked={ticketsArr.includes(pax.tkt_no)} onChange={ticketsTobeRefunded(pax)} custom id={pax.tkt_no} />}
                                        <span className="checkBoxml">
                                            {pax.tkt_no ? pax.tkt_no : "N/A"} {(pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {pax.tkt_no ? "(E)" : ""}
                                        </span>
                                    </>
                                    : 
                                    <span className="checkBoxml">
                                        {pax.tkt_no ? pax.tkt_no : "N/A"} {(pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {pax.tkt_no ? "(E)" : ""}
                                    </span>
                                    }
                                </td>
                                }
                                
                                {!paxEdit && !cancel &&
                                    <td>
                                        <span className="checkBoxml">
                                        {pax.tkt_no ? pax.tkt_no : "N/A"} {(pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {pax.tkt_no && !props.isEdit ? "(E)" : ""}
                                        </span>
                                    </td>
                                }

                                {paxEdit &&
                                <td>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="tkt_no" className="edittime d-flex align-items-center justify-content-between m-0">
                                        <Form.Control type="text" placeholder="Enter Ticket number" value={pax.tkt_no} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"ticket")} />
                                    </Form.Group>
                                </Form.Row>
                                </td>
                                }

                                {pax.supplier == "0" &&
                                    <td>  
                                    {pax.sa || pax.sp || pax.mp ?
                                        <>
                                        {/* {pax.sa!=""?pax.sa+" / ":""} {pax.sp!==""?pax.sp+" / ":""} {pax.mp!==""?pax.mp:""}  */}
                                        {pax.sa && pax.sp ==="" && pax.mp ==="" && (
                                            <>
                                            {pax.sa}
                                            </>
                                        )}
                                        {pax.sp && pax.sa === "" && pax.mp === "" && (
                                            <>
                                            {pax.sp}
                                            </>
                                        )}
                                        {pax.mp && pax.sp === "" && pax.sa === "" && (
                                            <>
                                            {pax.mp}
                                            </>
                                        )}
                                        {pax.sa && pax.sp && pax.mp === "" && (
                                            <>
                                            {pax.sa + " / " + pax.sp}
                                            </>
                                        )}
                                        {pax.sa && pax.mp && pax.sp === "" && (
                                            <>
                                            {pax.sa + " / " + pax.mp}
                                            </>
                                        )}
                                        {pax.sp && pax.mp && pax.sa === "" && (
                                            <>
                                            {pax.sp + " / " + pax.mp}
                                            </>
                                        )}
                                        {pax.sa && pax.sp && pax.mp && (
                                            <>
                                            {pax.sa + " / " + pax.sp + " / " + pax.mp}
                                            </>
                                        )}
                                        </> : "N/A"
                                    }
                                    </td>
                                }
                                
                                {pax.supplier !== "0" &&
                                <>
                                <td>
                                    {pax.bag_lable !== undefined && pax.bag_lable !== "" ? pax.bag_lable : 'N/A' }
                                </td>
                                <td>
                                    {pax.meal_lable !== undefined && pax.meal_lable !== "" ? pax.meal_lable : 'N/A' }
                                </td>
                                <td>
                                    {pax.seg_seat_no !== undefined && pax.seg_seat_no !== "" ? pax.seg_seat_no : 'N/A' }
                                </td>
                                </>
                                }

                                    
                                {!paxEdit &&
                                    <td>
                                        <span className={"status paxstatus " + ((pax.segst == "Confirmed") && ('text-success') || (pax.segst == "Incomplete") && ('text-primery') || (pax.segst == "On Hold") && ('text-primery') || (pax.segst == "Discarded") && ('text-danger') || (pax.segst == "Ticketing failed") && ('text-danger') || (pax.segst == "Cancelled") && ('text-danger'))}> {pax.segst}</span>
                                    </td>
                                }

                                {paxEdit &&
                                <td>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="sstid" className="customSelect editOne m-0" >
                                            <Form.Control as="select"  className="p-0" value={pax.sstid} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"segStatus")}>
                                                {state.ticketStatusList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </td>
                                }

                                {!paxEdit &&
                                    <td >
                                        <span className={"status paxstatus " + (('text-primery') || (pax.sts == "Confirmed") && ('text-success') || (pax.sts == "Incomplete") && ('text-primery') || (pax.sts == "On Hold") && ('text-primery') || (pax.sts == "Discarded") && ('text-danger') || (pax.sts == "Failed") && ('text-danger') || (pax.sts == "Cancelled") && ('text-danger'))}>{pax.sts}</span>
                                    </td>
                                }

                                {paxEdit &&
                                <td>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="tstid" className="customSelect editOne m-0" >
                                            <Form.Control as="select"  className="p-0" value={pax.tstid} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"tktStatus")}>
                                                {state.ticketStatusList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </td>
                                }

                                </tr>
                            </React.Fragment>
                        ))}
                    </Table>
                }
                
                

                {/* {(props.enableRefundTkt || props.enableRefundReturnTkt) && */}
                {cancel &&
                    (
                    <>
                    <div className="d-flex buttonGrop  creat_absolute mr-2">
                        <div>
                            {noTicketNo && noTicketNo.length > 0 && (
                                <>
                                    <input type="checkbox" label="" checked={state.cancelPNR} onChange={cancelPnr} />
                                    <span className="checkBoxml">
                                        Cancel PNR
                                    </span>
                                </>
                            )}
                        </div>
                        <div>
                            <Button
                                size="xs"
                                variant="outline-secondary"
                                className="mr-3"
                                onClick={cancelRefund}
                            >Cancel</Button>
                            <Button
                                size="xs"
                                variant="primary"
                                onClick={continueRefund}
                            >Continue</Button>
                        </div>
                    </div>
                    </> 
                    )
                }
                        
                {paxEdit &&
                <div className="text-right buttonGrop border-0 pt-0">
                    <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    className="mr-3"
                    onClick={cancelEdit}
                    > Cancel</Button>                    
                {!props.btnLoader? 
                    <Button
                    size="xs"
                    variant="primary"
                    type="submit"
                    onClick={callPaxEditBooking}
                    > Update</Button>
                    :                    
                    <div class="progress progress-striped active mt-0" style={{float:"right"}}>
                        <div role="progressbar progress-striped" class="progress-bar">
                        <span>Updating..</span>
                        </div>
                    </div>
                }
                </div>
                }
                
                        
                {showEditpop &&
                <Modal
                show={showEditpop}
                onHide={oncloseEidt}
                className="importPNRMdl editpnrPop"
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton className="border-bottom-0">
                    <div className="popUplayout center">
                    <div className="icons warning"></div>
                    <strong>Are you sure! You want to proceed with the Edit Booking? </strong>

                    {/* <p className="text-center d-flex mb-2"><span className="text-secondary mr-1 "> NOTE : </span> Please cross check all GDS PNR's Should be same.</p> */}
                    </div>

                </Modal.Header>
                <Modal.Footer className="justify-content-center border-top-0 pb-4">
                    <Button
                    size="xs"
                    variant="secondary"
                    onClick={oncloseEidt}
                    type="submit"
                    >No</Button>
                    <Button
                    size="xs"
                    variant="primary"
                    onClick={processEditBookingFlow}
                    type="submit"
                    >Yes</Button>
                </Modal.Footer>
                </Modal>
            }
            </div>
        </div>
    )
}

export default FlightSummaryPassengers;