/**
 * @description: Results sectors headers
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */

import React, { useEffect, useState, useContext } from "react";
import AirportMemo from "../../common/airlines/AirportNames";
import DateUtils from "../../utilities/DateUtils";
import { Context } from "../../../App";
import Airpalne from "../../../../src/assets/images/airplane.png";

function ResultSectionHeader(props) {
  const [context, setContext] = useContext(Context)
  const { mapping, sectors, secType, secTypeIndex, trip, refund, flow } = props;
  const [displayObj, setDisplayObj] = useState({ fd: "", da: "", aa: "" });

  useEffect(() => {
    if (secType === "onward") {
      if (sectors && sectors.length > 0) {
        setDisplayObj({
          fd: sectors[(!flow || (flow && flow === 'SRP')) ? 0 : (parseInt(trip) !== 3 ? 0 : secTypeIndex)]?.dd,
          da: sectors[(!flow || (flow && flow === 'SRP')) ? 0 : (parseInt(trip) !== 3 ? 0 : secTypeIndex)]?.src,
          aa: sectors[(!flow || (flow && flow === 'SRP')) ? 0 : (parseInt(trip) !== 3 ? 0 : secTypeIndex)]?.des,
        });
      }
    } else if (secType === "arrival") {
      if (sectors.length > 0) {
        setDisplayObj({
          fd: flow === "SRPMULTI" ? sectors[1].dd :
            sectors.length === 1 ? sectors[0].ad : sectors.length === 2 && sectors[1].ad,
          da: sectors[0].des,
          aa: sectors[0].src,
        });
      }
    }
  }, [secType, sectors]);

  return (
    <>
      {displayObj.da && (
        <div className="rb-top">
          <div className="info d-flex align-items-center">
            <span className="smallFlight">
            <img src={Airpalne} className="ml-2 mr-2 ezyIcon icon-flight"></img>
            </span>
            <span className="route">
              <AirportMemo
                field="ResultSectionHeader"
                code={displayObj.da}
                mapping={mapping}
              />
            </span>
            <span className="rightArrow">
              <i className="ezyIcon icon-rightArrow"></i>
            </span>
            <span className="route">
              <AirportMemo
                field="ResultSectionHeader"
                code={displayObj.aa}
                mapping={mapping}
              />
            </span>
            <span className="date">
              {DateUtils.convertToLetterDate(displayObj.fd)}
            </span>
            {refund === "Non-Refundable" && <span className="nonRefund">Non Refundable</span>}
            {refund !== "Non-Refundable" && <span className="refund">Partial Refundable</span>}
          </div>
        </div>
      )}
    </>
  );
}

export default ResultSectionHeader;
