
import React, { useState, useEffect, useTransition } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import LoginForm from "../home/LoginForm"
export const REGION_COUNTRY = "REGION_COUNTRY";

function HomePage(props) {
  

  return (
    <>
  
      <div className="homeSearch">
       
      <LoginForm/>
       
      </div>
 
    </>
  );
}

export default HomePage;
