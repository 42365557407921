import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Context } from "../App";
import Home from "../components/home/HomePage";
import About from "../components/common/About";
import BookingList from "../components/dashboard/bookingList/BookingList";
import QueuesList from "../components/dashboard/queues/QueuesList";
import ManageBookings from "../components/dashboard/manageBookings/ManageBookings";
import HotelmanualBook from "../components/dashboard/manageBookings/HotelmanualBook";
import HotelSummaryPage from "../components/summary/hotel";
import Payments from "../components/dashboard/payments/Payments";
import FlightSummaryPage from "../components/summary/flight";
import FlightSummaryManualReissue from "../components/summary/reissue/ManualReissueSummary";
import ProtectedRoute from './protected.route'
import PayAuth from "../components/payment";
import ManualRefund from "../components/summary/hotel/ManualRefund";
import ManualRefundRecord from "../components/summary/refund/ManualRefundRecord";
export const USER_AUTH_DATA = "userAuthData";

function Router() {
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if (context.logindata === "") {
      var userData = localStorage.getItem(USER_AUTH_DATA);
      if (userData && userData !== undefined) {
        setContext({ logindata: JSON.parse(userData) });
      }
    }
  }, [context.logindata === ""]);

  useEffect(() => {
    var filename = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (filename === "") {
      window.location = "/crm/login";
    }
  }, []);

  useEffect(() => {
    if (context.logindata === undefined || context.logindata === "") {
      var userData = localStorage.getItem(USER_AUTH_DATA);
      if (userData) {
        let data = JSON.parse(userData);
        setContext({ logindata: data });
      }
    }
  }, [context.logindata === ""]);


  
  return (
    <BrowserRouter forceRefresh={true}>
      <Routes>
        <Route path="/crm/login" element={<Home />} />
        <Route path="/crm/about" element={<About />} />
        <Route path="/crm/bookings" element={<ProtectedRoute><BookingList /></ProtectedRoute>} />
        <Route path="/crm/queues" element={<ProtectedRoute><QueuesList /></ProtectedRoute>} />
        <Route path="/crm/manage-flight" element={<ProtectedRoute><ManageBookings /></ProtectedRoute>} />
        <Route path="/crm/manage-hotel" element={<HotelmanualBook />} />
        <Route path="/crm/hotel/summary/:booking_id" element={
          <ProtectedRoute>
            <HotelSummaryPage />
          </ProtectedRoute>
        } />
        <Route path="/crm/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} />
        <Route path="/crm/flight/summary/:booking_id" element={
          <ProtectedRoute>
            <FlightSummaryPage />
          </ProtectedRoute>
        } />
        <Route path="/crm/flight/summary/manualReissue" element={
          <ProtectedRoute>
            <FlightSummaryManualReissue />
          </ProtectedRoute>
        } />
        <Route path="/crm/payment/auth" element={<PayAuth />} />
        <Route path="/crm/hotel/summary/manual-refund" element={
          <ProtectedRoute>
            <ManualRefund />
          </ProtectedRoute>
        } />
        <Route path="/crm/flight/manual-refund" element={
          <ProtectedRoute>
            <ManualRefundRecord />
          </ProtectedRoute>
        } />

      </Routes>
    </BrowserRouter>
  );
}

export default Router;
