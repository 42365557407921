import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../App";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Utilities from "../../utilities/Utilities";
import Button from 'react-bootstrap/Button';
import CancelHotel from "./hotel/CancelHotel";


function HotelDetailsQueuePopup({ itiData, close, reloadQueueListAfterCancelParent }) {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        close();
    }

    useEffect(() => {
        setShow(true);
    }, [])
    const [context, setContext] = useContext(Context);
    const [cancelType, setCancelType] = useState("");

    const handleCancelTypeChange = (value) => {
        setCancelType(value);
    };

    const handleShow = () => setShow(true);
    const contentStyle = {
        margin: 0,
        height: "178px",
        width: "242px",
        color: "#fff",
        lineHeight: "160px",
        textAlign: "center",
        background: "#364d79",
        borderRadius: "4px",
    };

    const checkDta = (e) => {
        setCancelType("cancel");
    }
    const viewData = (e) => {
        setCancelType("");
    }
    const [state, setState] = useState({
        selectedOption: null,
    });
    const SelectOptions = [
        { value: 'cancel', label: 'Cancel Request' },
    ];

    const handleChange = selectedOption => {
        // setState({ selectedOption });
        // setCancelType(selectedOption?.valye)
        setCancelType("cancel");
    };

    const reloadQueueListAfterCancel = (data) =>{
        reloadQueueListAfterCancelParent(data)
    }

    return (
        <Modal show={show} onHide={handleClose} className='flightDetcrmh ' backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Booking Summary</Modal.Title>
                <div className="selectMangeBook">
                    <Select
                        //value={selectedOption}
                        className="crsPoin"
                        isSearchable={false}
                        onChange={handleChange}
                        options={SelectOptions}
                        placeholder="Manage Booking"
                        classNamePrefix='filter'
                            theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                            },
                            })}
                  
                    />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="hotelReviewDetails marginTop30">
                    <div className="sliderWrapper">
                        <span className="hotelTag">{itiData.hotel_name}</span>
                        <div className="Carousel">
                            <img src={itiData.hotel_image} style={contentStyle} />
                        </div>
                    </div>

                    <div className="fullWidth">
                        <div>
                            <div className="starTitle">

                       
                            <h4> {itiData.hotel_name}</h4>
                            <span className="stars">
                                {Utilities.getHotelStartRating(
                                    +itiData.star_rating.split(".")[0]
                                ).map((star, idx) => (
                                    <React.Fragment key={idx}>
                                        <span className="hotelRating">
                                        <span className="ezyIcon icon-star"></span>
                                        </span>
                                    </React.Fragment>
                                ))}


                            </span>
                            </div>

                         
                            <h5 className="hotelAddress"> {itiData.hotel_address}, {itiData.hotel_city},{" "}
                                {itiData.hotel_country}</h5>
                            <div className="onlyFlex">
                                <div className="checkInBorderRight">
                                    <span className="checkInOut">
                                        {"Check-in"}
                                    </span>
                                    <span className="dateTime">
                                        {itiData.cin_date}, {itiData.cin_time}
                                    </span>
                                </div>
                                <div>
                                    <span className="checkInOut">
                                        {"Check-Out"}
                                    </span>
                                    <span className="dateTime">
                                        {itiData.cout_date}, {itiData.cout_time}
                                    </span>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>

                {cancelType === "" && (
                    <div>
                        <div className='roomDetailTitle'>
                            {"Room Details"}
                        </div>
                        {itiData &&
                            itiData.rooms.length > 0 &&
                            itiData.rooms.map((room, index) => (
                                <>
                                    <div className="guestsWrapper">
                                        <div className='roomWrapr'> {"Room"} {index + 1}: {room.room_name}</div>
                                        <div className="travellersWrapper">
                                            <span className="travellersTitle">
                                                {"Guests"}:
                                            </span>
                                            <span className="travellersDetailWrap">
                                                {room.pax.map((pax, index) => (
                                                    <React.Fragment key={index}>
                                                        <span className="travellersDetail">
                                                            <i className="ezyIcon icon-newtick"></i>
                                                            {pax.title} {pax.fname} {pax.lname} ({pax.type})
                                                        </span>
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="travellerDetails">
                                        <div className="tableHeader row m-0">
                                            <div className="tableValue col-md">
                                                {"Room Type"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {"Occupancy"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {"Board Type"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {/* {context.translation && context.translation.hotelRef} */}
                                                {"Hotel Ref"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {/* {context.translation && context.translation.supConfno} */}
                                                {"Supplier Conf"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {"Special Request"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {"Status"}
                                            </div>
                                            <div className="tableValue col-md">
                                                {"Total Price"}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tableData row m-0">
                                                <div className="tableValue col-md">
                                                    {room.bed_type ? room.bed_type : "N/A"}
                                                </div>
                                                <div className="tableValue col-md">
                                                    {room.adults}{" "}
                                                    {+room.childs.split(" ")[0] === 0
                                                        ? ""
                                                        : "," + room.childs}
                                                </div>
                                                <div className="tableValue col-md">
                                                    {/* {!Array.isArray(room.board_basis) && room.board_basis.toUpperCase().split(" ").join("") === "ROOMONLY" ? "Room Only" : Array.isArray(room.board_basis) ? room.board_basis.join(", ") :"N/A"} */}
                                                    {!Array.isArray(room.board_basis) && "Room Only"}
                                                    {Array.isArray(room.board_basis) &&
                                                        room.board_basis.join(", ")}
                                                </div>
                                                <div className="tableValue wordBreak col-md">
                                                    {room.hotel_ref ? room.hotel_ref : "N/A"}
                                                </div>
                                                <div className="tableValue wordBreak col-md">
                                                    {room.supplier_ref ? room.supplier_ref : "N/A"}
                                                </div>
                                                <div className="tableValue col-md">
                                                    {room.special_request ? room.special_request : "N/A "}
                                                </div>
                                                <div className={room.room_status.toUpperCase() === "CONFIRMED" ? "tableValue active col-md" : "tableValue primary"}>
                                                    {room.room_status}
                                                </div>
                                                <div className="tableValue wordBreak col-md">
                                                    {itiData.usr_currency} {room.total_price}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontalDivider"></div>
                                    <div>
                                        <div className='impWrap'>
                                            <div level={4} className='impTitle'>{"Cancellation Policy:"}</div>
                                            {room.cancellation_policy && room.cancellation_policy?.length !== 0 &&
                                                <>
                                                    {room.cancellation_policy.map((canp, index) => (
                                                        <React.Fragment key={index}>
                                                            <p className="impContent">{canp}</p>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            }
                                            {room.cancellation_policy?.length === 0 &&
                                                <>
                                                    <p className="impContent">{"Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws."}</p>
                                                </>
                                            }
                                        </div>
                                        <div className='impWrap'>
                                            <div level={4} className='impTitle'>{"Important Info:"}</div>
                                            <>
                                                {room.important_info && room.important_info !== undefined &&
                                                    <div className="toolTipCUstoom cnclInfo">
                                                        <p dangerouslySetInnerHTML={{ __html: room.important_info.instructions }} className="importantContent"></p>
                                                        <p dangerouslySetInnerHTML={{ __html: room.important_info.specialInstructions }} className="importantContent"></p>
                                                    </div>
                                                }
                                                {(room.important_info !== undefined && (room.important_info.instructions === "" || room.important_info.specialInstructions === "")) &&
                                                    <div className="toolTipCUstoom cnclInfo">
                                                        <p className="importantContent">N/A</p>
                                                    </div>
                                                }
                                            </>
                                        </div>
                                        {itiData.note !== "" && itiData.note !== undefined && (
                                            <div className='impWrap'>
                                                <div level={4} className='impTitle'>{"Note:"}</div>
                                                <p className='impContent'>{itiData.note}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="horizontalDivider"></div>

                                </>
                            ))}


                    </div>
                )}

            {cancelType === "" && (
                <div className="travellerDetails paymenDetal">
                    <h3 className="heading">
                        {"Payment Details"}
                    </h3>
                    <div className="tableHeader row m-0">
                        <div className="tableValue col-md">
                            {"Mode of Payment"}
                        </div>
                        <div className="tableValue col-md">
                            {"Card Type"}
                        </div>
                        <div className="tableValue col-md">
                            {"Card Details"}
                        </div>
                        <div className="tableValue col-md">
                            {"Amount Paid"}
                        </div>
                    </div>
                    <div className="tableData row m-0">
                        <div className="tableValue col-md">
                            {itiData.payment_mode} Card
                        </div>
                        <div className="tableValue col-md">
                            {itiData.card_type} Card
                        </div>
                        <div className="tableValue col-md">
                            xxxxxxxxxxxx{itiData.card_details}
                        </div>
                        <div className="tableValue col-md">
                            {itiData.usr_currency} {itiData.paid_amount}
                        </div>
                    </div>
                </div>
            )}

                {cancelType && cancelType === "cancel" && (
                    <div>
                        <div level={4} className='roomDetailTitle roomHotelDetailTitle'>
                            {"Room Details"}
                        </div>
                        <CancelHotel close={handleClose} cancelData={itiData} reloadQueueListAfterCancel ={reloadQueueListAfterCancel}/>
                    </div>
                )}


            </Modal.Body>
            <div className="viewItineraryWrap"></div>
        </Modal>
    )
}
export default HotelDetailsQueuePopup;