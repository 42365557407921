import React, { Component, useState, useEffect } from 'react';
import { Button, Spinner} from "react-bootstrap";
/**
 * @description:This function will add Button Component .
 * @author: Pravas
 * @param {*}
 * @function Button
 * @date : 01-09-2020
 */

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function ButtonCust(props) {
  //console.log(props);
  const { state } = props || {}
  const [size] = useState(props.size);
  const [className] = useState(props.className);
  const [variant] = useState(props.variant);
  const[isDisabled] = useState(props.isDisabled);

  return (
      <Button
      className={`btn-${variant} btn-${size} ${className}`} style ={props.style}onClick={props.onClick} disabled  = {isDisabled}>
      {props.loading===true ? (<Spinner animation="border" variant="light" />) : props.children}
    </Button>


  );
}
Button.defaultProps = {
  size:'',
  variant:'',
  onClick:()=>{},
  loading: false

}
export default ButtonCust;