import React, { useEffect, useState, useReducer, useContext } from "react";
import { Context } from "../../../../App";
import { isArrayNotEmpty } from "../../../utilities/validators";
import { cancelFlightTicket } from "../operations";
import { Button, Form, Alert, Col } from "react-bootstrap";
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utilities from "../../../utilities/Utilities";
import DateUtils from "../../../common/DateUtils";
export const USER_AUTH_DATA = "userAuthData";

const _ = require("lodash");

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  reissueRadioType: "FC",
  radioRS: true,
  radioRP: false,
};

//=====================================
// Reducer function for UserAction
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ReissueFlightTrip({ cancelData, close }) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sectorsRS, setSectorsRS] = useState([]);
  const [sectorsRP, setSectorsRP] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("RS");
  const [remarksRS, setRemarksRS] = useState([]);
  const [remarksRP, setRemarksRP] = useState([]);
  const [email, setEmail] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    constructSectors();
  }, []);

  const constructSectors = () => {
    // Initialize the result array
    var secRS = [];
    var secRP = [];
    var tt = parseInt(cancelData.tt);
    if (tt === 1 || tt === 2 || tt === 3) {
      cancelData.origns[0].srcorigns.forEach((srcorign, index) => {
        var src = srcorign.seg[0].da;
        var des = srcorign.seg[srcorign.seg.length - 1].ar;
        var psngrs = tt === 3 ? cancelData.psngrs : cancelData.psngrs.filter((pax) => pax.tid === srcorign.tid);
        var route =
          srcorign.seg[0].da + "-" + srcorign.seg[srcorign.seg.length - 1].ar;
        var office_id = srcorign.office_id;
        var tid = srcorign.tid;
        var re = srcorign.re;
        var st = "Confirmed";
        var pnr = cancelData.flt_b_data[0].pnr;
        var air_pnr = cancelData?.flt_d_data[0]?.airline_pnr;
        var cl = "";
        var travelDate = null;
        var ad = srcorign.seg[srcorign.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
        secRS.push({
          src,
          des,
          leg: index + 1, // Increment leg number
          office_id,
          psngrs,
          tid,
          route,
          re,
          st,
          pnr,
          air_pnr,
          cl,
          travelDate,
          ad,
        });
        secRP.push({
          src,
          des,
          leg: index + 1, // Increment leg number
          office_id,
          psngrs,
          tid,
          route,
          re,
          st,
          pnr,
          air_pnr,
          cl,
          travelDate,
          ad,
        });
      });
      if (tt === 2 || cancelData.sty === "split") {
        cancelData.origns[0].destorigins.forEach((destorigin, index) => {
          var src = destorigin.seg[0].da;
          var des = destorigin.seg[destorigin.seg.length - 1].ar;
          var psngrs = cancelData.sty === "split" ? (cancelData.psngrs.filter((pax) => pax.tid === destorigin.tid)) : cancelData.psngrs;
          var route =
            destorigin.seg[0].da +
            "-" +
            destorigin.seg[destorigin.seg.length - 1].ar;
          var office_id = destorigin.office_id;
          var tid = destorigin.tid;
          var re = destorigin.re;
          var st = "Confirmed";
          var pnr = cancelData.flt_b_data?.length === 1 ? cancelData.flt_b_data[0]?.pnr : cancelData.flt_b_data[1]?.pnr;
          var air_pnr = cancelData?.flt_d_data[cancelData?.flt_d_data.length - 1]?.airline_pnr;
          var cl = "";
          var travelDate = null;
          var ad = destorigin.seg[destorigin.seg.length - 1].ad.split("T")[0]; // Format "ad" as yyyy-MM-dd
          // Push the data into the "sec" array
          secRS.push({
            src,
            des,
            leg: cancelData.origns[0].srcorigns.length + index + 1, // Increment leg number
            office_id,
            psngrs,
            tid,
            route,
            re,
            st,
            pnr,
            air_pnr,
            cl,
            travelDate,
            ad,
          });
          secRP.push({
            src,
            des,
            leg: cancelData.origns[0].srcorigns.length + index + 1, // Increment leg number
            office_id,
            psngrs,
            tid,
            route,
            re,
            st,
            pnr,
            air_pnr,
            cl,
            travelDate,
            ad,
          });
        });
      }
    }
    if (isArrayNotEmpty(secRS)) {
      setSectorsRS(secRS);
    }
    if (isArrayNotEmpty(secRP)) {
      setSectorsRP(secRP);
    }

    let data = localStorage.getItem(USER_AUTH_DATA);
    if (data && data !== undefined && data !== null) {
      let itm = JSON.parse(data) 
      setEmail(itm?.email);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target.id === "RS") {
      dispatch({ type: "reissueRadioType", payload: "RS" });
      dispatch({ type: "radioRS", payload: true });
      dispatch({ type: "radioRP", payload: false });
      setSelectedRadio("RS");
    } else if (event.target.id === "RP") {
      dispatch({ type: "reissueRadioType", payload: "RP" });
      dispatch({ type: "radioRS", payload: false });
      dispatch({ type: "radioRP", payload: true });
      setSelectedRadio("RP");
    }
  };

  // State to track selected checkboxes
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    // Initial checkbox of first passengers array
    // 0: [0],
  });

  // Function to handle changes in nested checkboxes
  const handleNestedChange = (parentIndex, nestedIndex) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      if (!updatedSelected[parentIndex]) {
        updatedSelected[parentIndex] = [];
      }

      const nestedIndexInArray =
        updatedSelected[parentIndex].indexOf(nestedIndex);

      if (nestedIndexInArray === -1) {
        updatedSelected[parentIndex].push(nestedIndex);
      } else {
        updatedSelected[parentIndex].splice(nestedIndexInArray, 1);
      }

      return updatedSelected;
    });
  };

  const handleRemarksChange = (type) => (e) => {
    if (type === "RS") {
      setRemarksRS([e.target.value]);
    }
    if (type === "RP") {
      setRemarksRP([e.target.value]);
    }
  };

  const backToNormal = () => {
    close(false);
  };

  const validate = () => {
    if (selectedSectors.length === 0) {
      if (selectedRadio === "RS") {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Please select atleast one sector",
        });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }
    if (
      Object.keys(selectedCheckboxes).length === 0 &&
      selectedCheckboxes[0] === undefined
        ? true
        : selectedCheckboxes[0]?.length === 0
    ) {
      if (selectedRadio === "RP") {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Please select atleast one pax",
        });
        dispatch({ type: "notiVarient", payload: "error" });
        hidemessage();
        return false;
      }
    }
    return true;
  };

  const submitReissue = (type) => {
    if (validate()) {
      let payload;
      let sectsCopy = _.cloneDeep(sectorsRS);
      let sects = sectsCopy?.map((sec) => {
        delete sec.psngrs;
        delete sec.src;
        delete sec.des;
        delete sec.leg;
        delete sec.re;
        delete sec.ad;
        return sec;
      });
      let passengesNames = []
      cancelData.psngrs?.map((ticket) => {
        if(ticket.inf !== "" && ticket.inf !== undefined) {
          passengesNames.push(ticket.fn+"-"+ticket.ln);
          passengesNames.push(ticket.inf.fn+"-"+ticket.inf.ln);
        } else {
          passengesNames.push(ticket.fn+"-"+ticket.ln);
        }
      });
      try {
        if (type === "RS") {
          if (cancelData.tt == 3) {
            const selSects = [
              sects[selectedSectors[0]],
              sects[selectedSectors[1]],
              sects[selectedSectors[2]],
              sects[selectedSectors[3]],
              sects[selectedSectors[4]],
              sects[selectedSectors[5]],
            ];

            const selectedSects = selSects.filter((sec) => sec !== undefined);

            const selOrigns = [
              cancelData.origns[0].srcorigns[selectedSectors[0]],
              cancelData.origns[0].srcorigns[selectedSectors[1]],
              cancelData.origns[0].srcorigns[selectedSectors[2]],
              cancelData.origns[0].srcorigns[selectedSectors[3]],
              cancelData.origns[0].srcorigns[selectedSectors[4]],
              cancelData.origns[0].srcorigns[selectedSectors[5]],
            ];

            const selectedOrigns = selOrigns.filter((org) => org !== undefined);

            payload = {
              action: "reissue",
              email_id: email,
              region_type: cancelData.region_type,
              action_type: type,
              sty: cancelData.sty,
              tid: cancelData.tid,
              trip_type: cancelData.tt,
              booking_id: cancelData.booking_id,
              cid: cancelData.cid,
              sup: cancelData.sup,
              tkts: passengesNames,
              psngr_det: cancelData.psngr_det,
              psngrs: cancelData.psngrs,
              Cancelledpsngrs: cancelData.psngrs,
              sectors: selectedSects,
              remarks: remarksRS,
              origns: [{ srcorigns: selectedOrigns }],
              mappings: cancelData.mappings,
            };
          } else {
            payload = {
              action: "reissue",
              email_id: email,
              region_type: cancelData.region_type,
              action_type: type,
              sty: cancelData.sty,
              tid: cancelData.tid,
              trip_type: cancelData.tt,
              booking_id: cancelData.booking_id,
              cid: cancelData.cid,
              sup: cancelData.sup,
              tkts: passengesNames,
              psngrs: cancelData.psngrs,
              psngr_det: cancelData.psngr_det,
              Cancelledpsngrs:
                cancelData.sty !== "split" || selectedSectors.length === 2
                  ? cancelData.psngrs
                  : cancelData.psngrs.filter(
                      (psn) =>
                        psn.tid ===
                        (selectedSectors.length === 1 && selectedSectors[0] == 0
                          ? cancelData.origns[0].srcorigns[0].tid
                          : selectedSectors.length === 1 &&
                            selectedSectors[0] == 1 &&
                            cancelData.origns[0].destorigins[0].tid)
                    ),
              sectors:
                selectedSectors.length === 1 && selectedSectors[0] == 0
                  ? [sects[selectedSectors[0]]]
                  : selectedSectors.length === 1 && selectedSectors[0] == 1
                  ? [sects[selectedSectors[0]]]
                  : selectedSectors.length === 2 && sects,
              remarks: remarksRS,
              origns:
                selectedSectors.length === 1 && selectedSectors[0] == 0
                  ? [{ srcorigns: [cancelData.origns[0].srcorigns[0]] }]
                  : selectedSectors.length === 1 && selectedSectors[0] == 1
                  ? [{ destorigns: [cancelData.origns[0].destorigins[0]] }]
                  : selectedSectors.length === 2 && cancelData.origns,
              mappings: cancelData.mappings,
            };
          }
        }
        if (type === "RP") {
          let sectsRPCopy = _.cloneDeep(sectorsRP);

          let secCombine = [
            selectedCheckboxes[0] !== undefined &&
              selectedCheckboxes[0]?.length !== 0 &&
              sectsRPCopy[0],
            selectedCheckboxes[1] !== undefined &&
              selectedCheckboxes[1]?.length !== 0 &&
              sectsRPCopy[1],
            selectedCheckboxes[2] !== undefined &&
              selectedCheckboxes[2]?.length !== 0 &&
              sectsRPCopy[2],
            selectedCheckboxes[3] !== undefined &&
              selectedCheckboxes[3]?.length !== 0 &&
              sectsRPCopy[3],
            selectedCheckboxes[4] !== undefined &&
              selectedCheckboxes[4]?.length !== 0 &&
              sectsRPCopy[4],
            selectedCheckboxes[5] !== undefined &&
              selectedCheckboxes[5]?.length !== 0 &&
              sectsRPCopy[5],
            selectedCheckboxes[6] !== undefined &&
              selectedCheckboxes[6]?.length !== 0 &&
              sectsRPCopy[6],
            selectedCheckboxes[7] !== undefined &&
              selectedCheckboxes[7]?.length !== 0 &&
              sectsRPCopy[7],
          ];

          var filterSectorCombine = secCombine.filter(
            (obj) => obj !== undefined && obj !== false
          );

          let sectorsCT = filterSectorCombine?.map((sec) => {
            delete sec.psngrs;
            delete sec.src;
            delete sec.des;
            delete sec.leg;
            delete sec.re;
            delete sec.ad;
            return sec;
          });

          let ticketsCT = cancelData.psngrs;
          const tkCTOne =
            selectedCheckboxes[0]?.length !== 0 &&
            selectedCheckboxes[0]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTOneInf =
            selectedCheckboxes[0]?.length !== 0 &&
            selectedCheckboxes[0]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTTwo =
            selectedCheckboxes[1]?.length !== 0 &&
            selectedCheckboxes[1]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTTwoInf =
            selectedCheckboxes[1]?.length !== 0 &&
            selectedCheckboxes[1]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTThree =
            selectedCheckboxes[2]?.length !== 0 &&
            selectedCheckboxes[2]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTThreeInf =
            selectedCheckboxes[2]?.length !== 0 &&
            selectedCheckboxes[2]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTFour =
            selectedCheckboxes[3]?.length !== 0 &&
            selectedCheckboxes[3]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTFourInf =
            selectedCheckboxes[3]?.length !== 0 &&
            selectedCheckboxes[3]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTFive =
            selectedCheckboxes[4]?.length !== 0 &&
            selectedCheckboxes[4]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTFiveInf =
            selectedCheckboxes[4]?.length !== 0 &&
            selectedCheckboxes[4]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTSix =
            selectedCheckboxes[5]?.length !== 0 &&
            selectedCheckboxes[5]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTSixInf =
            selectedCheckboxes[5]?.length !== 0 &&
            selectedCheckboxes[5]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);
          const tkCTSeven =
            selectedCheckboxes[6]?.length !== 0 &&
            selectedCheckboxes[6]?.map((t, i) => ticketsCT[t].fn+"-"+ticketsCT[t].ln);
          const tkCTSevenInf =
            selectedCheckboxes[6]?.length !== 0 &&
            selectedCheckboxes[6]?.map((t, i) => ticketsCT[t].inf !== "" && ticketsCT[t].inf !== undefined && ticketsCT[t].inf.fn+"-"+ticketsCT[t].inf.ln);

          const tkCT = [].concat(
            tkCTOne,
            tkCTOneInf,
            tkCTTwo,
            tkCTTwoInf,
            tkCTThree,
            tkCTThreeInf,
            tkCTFour,
            tkCTFourInf,
            tkCTFive,
            tkCTFiveInf,
            tkCTSix,
            tkCTSixInf,
            tkCTSeven,
            tkCTSevenInf
          );
          const filtertkCT = tkCT.filter((t) => t !== undefined);

          let cnclticketsCT = cancelData.psngrs;
          const cncltkCTOne =
            selectedCheckboxes[0]?.length !== 0 &&
            selectedCheckboxes[0]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTTwo =
            selectedCheckboxes[1]?.length !== 0 &&
            selectedCheckboxes[1]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTThree =
            selectedCheckboxes[2]?.length !== 0 &&
            selectedCheckboxes[2]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTFour =
            selectedCheckboxes[3]?.length !== 0 &&
            selectedCheckboxes[3]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTFive =
            selectedCheckboxes[4]?.length !== 0 &&
            selectedCheckboxes[4]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTSix =
            selectedCheckboxes[5]?.length !== 0 &&
            selectedCheckboxes[5]?.map((t, i) => cnclticketsCT[t]);
          const cncltkCTSeven =
            selectedCheckboxes[6]?.length !== 0 &&
            selectedCheckboxes[6]?.map((t, i) => cnclticketsCT[t]);

          const cncltkCT = [].concat(
            cncltkCTOne,
            cncltkCTTwo,
            cncltkCTThree,
            cncltkCTFour,
            cncltkCTFive,
            cncltkCTSix,
            cncltkCTSeven
          );
          const cnclfiltertkCT = cncltkCT.filter((t) => t !== undefined);

          if (cancelData.tt == 3) {
            let cncloriginsCT = cancelData.origns[0];
            const cnclsrcoriginOne =
              selectedCheckboxes[0]?.length !== 0 && cncloriginsCT.srcorigns[0];
            const cnclsrcoriginTwo =
              selectedCheckboxes[1]?.length !== 0 && cncloriginsCT.srcorigns[1];
            const cnclsrcoriginThree =
              selectedCheckboxes[2]?.length !== 0 && cncloriginsCT.srcorigns[2];
            const cnclsrcoriginFour =
              selectedCheckboxes[3]?.length !== 0 && cncloriginsCT.srcorigns[3];
            const cnclsrcoriginFive =
              selectedCheckboxes[4]?.length !== 0 && cncloriginsCT.srcorigns[4];
            const cnclsrcoriginSix =
              selectedCheckboxes[5]?.length !== 0 && cncloriginsCT.srcorigns[5];

            const cnclorgCT = [].concat(
              cnclsrcoriginOne,
              cnclsrcoriginTwo,
              cnclsrcoriginThree,
              cnclsrcoriginFour,
              cnclsrcoriginFive,
              cnclsrcoriginSix
            );

            const cnclfilterorignsCT = cnclorgCT.filter((t) => t !== undefined);

            payload = {
              action: "reissue",
              email_id: email,
              region_type: cancelData.region_type,
              action_type: type,
              sty: cancelData.sty,
              tid: cancelData.tid,
              trip_type: cancelData.tt,
              booking_id: cancelData.booking_id,
              cid: cancelData.cid,
              sup: cancelData.sup,
              tkts: filtertkCT,
              psngrs: cancelData.psngrs,
              psngr_det: cancelData.psngr_det,
              Cancelledpsngrs: cnclfiltertkCT,
              sectors: sectorsCT,
              remarks: remarksRP,
              origns: [{ srcorigns: cnclfilterorignsCT }],
              mappings: cancelData.mappings,
            };
          } else {
            let cncloriginsCT = cancelData.origns[0];
            const cnclsrcorigin =
              selectedCheckboxes[0] === undefined
                ? undefined
                : selectedCheckboxes[0]?.length !== 0 &&
                  cncloriginsCT.srcorigns;
            const cncldestorigin =
              selectedCheckboxes[1] === undefined
                ? undefined
                : selectedCheckboxes[1]?.length !== 0 &&
                  cncloriginsCT.destorigins;

            const cnclsrcorgCT = [].concat(cnclsrcorigin);

            const cncldestorgCT = [].concat(cncldestorigin);

            const cnclfiltersrcorignsCT = cnclsrcorgCT.filter(
              (t) => t !== undefined
            );
            const cnclfilterdestorignsCT = cncldestorgCT.filter(
              (t) => t !== undefined
            );

            payload = {
              action: "reissue",
              email_id: email,
              region_type: cancelData.region_type,
              action_type: type,
              sty: cancelData.sty,
              tid: cancelData.tid,
              trip_type: cancelData.tt,
              booking_id: cancelData.booking_id,
              cid: cancelData.cid,
              sup: cancelData.sup,
              tkts: filtertkCT,
              psngrs: cancelData.psngrs,
              psngr_det: cancelData.psngr_det,
              Cancelledpsngrs: cnclfiltertkCT,
              sectors: sectorsCT,
              remarks: remarksRP,
              origns: [
                cnclfiltersrcorignsCT.length !== 0 && {
                  srcorigns: cnclfiltersrcorignsCT,
                },
                cnclfilterdestorignsCT.length !== 0 && {
                  destorigns: cnclfilterdestorignsCT,
                },
              ],
              mappings: cancelData.mappings,
            };
          }
        }
        //Call Reissue Ticket API
        let tokenKey = Utilities.generateAPIToken(payload);
        setIsLoad(true)
        cancelFlightTicket(payload,tokenKey).then((response) => {
          console.log("Cancel ticket response " + JSON.stringify(response));
          if (response.suc) {
            setIsLoad(false)
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: response.res,
            });
            dispatch({ type: "notiVarient", payload: "success" });
            hidemessage();
            setTimeout(() => {
              backToNormal();
            }, 3000);
          } else {
            setIsLoad(false)
            dispatch({ type: "notiMessageShow", payload: true });
            // dispatch({
            //   type: "notiMessage",
            //   payload: response.err,
            // });
            dispatch({
              type: "notiMessage",
              payload: "Something went wrong",
            });
            dispatch({ type: "notiVarient", payload: "error" });
            hidemessage();
            setTimeout(() => {
              backToNormal();
            }, 3000);
          }
        });
      } catch (error) {
        setIsLoad(false)
        console.error("Error:", error);
      }
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 3000);
  };

  const TravelClass = [
    { value: "Economy", label: "Economy", title: " " },
    { value: "Premium", label: "Premium Economy", title: " " },
    { value: "Business", label: "Business", title: " " },
    { value: "First class", label: "First class", title: " " },
  ];

  const handleChangeClassRS = (e, index) => {
    const updatedSectors = [...sectorsRS];
    updatedSectors[index].cl = e;
    setSectorsRS(updatedSectors);
  };

  const handleChangeClassRP = (e, index) => {
    const updatedSectors = [...sectorsRP];
    updatedSectors[index].cl = e;
    setSectorsRP(updatedSectors);
  };

  const handleDateChangeRS = (date, index) => {
    const updatedSectors = [...sectorsRS];
    updatedSectors[index].travelDate = date ? date : null;
    updatedSectors[index].ad = date ? DateUtils.convertStringToDate(date) : null;
    setSectorsRS(updatedSectors);
  };

  const handleDateChangeRP = (date, index) => {
    const updatedSectors = [...sectorsRP];
    updatedSectors[index].travelDate = date ? date : null;
    updatedSectors[index].ad = date ? DateUtils.convertStringToDate(date) : null;
    setSectorsRP(updatedSectors);
  };

  const [selectedSectors, setSelectedSectors] = useState(
    // initialItems.map((_, idx) => idx)
    sectorsRS.map((_, idx) => idx)
  );

  const handleCheckboxChange = (idx) => {
    const updatedItems = selectedSectors.includes(idx)
      ? selectedSectors.filter((selectedIndex) => selectedIndex !== idx)
      : [...selectedSectors, idx];

    setSelectedSectors(updatedItems);
  };

  const handleSelectClick = (event) => {
    // Prevent checkbox state change when the Select dropdown is clicked
    event.stopPropagation();
  };

  return (
    <>
      <div class="horizontalDivider"></div>
      <div className="reisChange">
        {state.notiMessageShow && (
          <Alert
            className="notification alert"
            variant={state.notiVarient}
            show={state.notiMessageShow}
            onClose={() =>
              dispatch({ type: "notiMessageShow", payload: false })
            }
            dismissible
          >
            {" "}
            {state.notiMessage}
          </Alert>
        )}

        <Form.Row className="m-0">
          <div className="d-flex align-items-center ReIssuep">
            {cancelData && sectorsRS && sectorsRS.length > 0 && (
                  <label>
              <input
                type="radio"
                id="RS"
                checked={state.radioRS}
                value={state.reissueRadioType}
                onChange={handleRadioChange}
                custom
                name="classes"
              />
                Reissue by sector
              
              </label>
            )}

            {cancelData &&
              sectorsRP &&
              sectorsRP.length === 1 &&
              sectorsRP[0].psngrs.length > 1 && (
                <label>
                <input
                  type="radio"
                  id="RP"
                  checked={state.radioRP}
                  value={state.reissueRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
                  Reissue by pax
              
              </label>
              )}

            {cancelData &&
              sectorsRP &&
              sectorsRP.length === 2 &&
              (sectorsRP[0].psngrs.length > 1 ||
                sectorsRP[1].psngrs.length > 1) && (
                  <label>
                  <input
                  type="radio"
                  id="RP"
                  checked={state.radioRP}
                  value={state.reissueRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
                    Reissue by pax
              
              </label>
              )}
            {cancelData &&
              sectorsRP &&
              sectorsRP.length === 3 &&
              (sectorsRP[0].psngrs.length > 1 ||
                sectorsRP[1].psngrs.length > 1 ||
                sectorsRP[2].psngrs.length > 1) && (
                  <label>
                  <input
                  type="radio"
                  id="RP"
                  checked={state.radioRP}
                  value={state.reissueRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"

                />
                
                Reissue by pax
              
              </label>
              )}
            {cancelData &&
              sectorsRP &&
              sectorsRP.length === 4 &&
              (sectorsRP[0].psngrs.length > 1 ||
                sectorsRP[1].psngrs.length > 1 ||
                sectorsRP[2].psngrs.length > 1 ||
                sectorsRP[3].psngrs.length > 1) && (
                <Form.Check
                  type="radio"
                  label="Reissue by pax"
                  id="RP"
                  checked={state.radioRP}
                  value={state.reissueRadioType}
                  onChange={handleRadioChange}
                  custom
                  name="classes"
                />
              )}
          </div>
        </Form.Row>

        {selectedRadio && selectedRadio === "RS" && (
          <div>
            <div className="tableHeader reIssues row m-0">
              <div className="tableValue col-md-1">
                
              </div>
              <div className="tableValue col-md-2">
                {"Sectors"}
              </div>
              <div className="tableValue col-md-2">
                {"Date change"}
              </div>
              <div className="tableValue col-md-2">
                {"Change class"}
              </div>
              <div className="tableValue col-md-2">
                {"Travellers"}
              </div>
              <div className="tableValue col-md-2">
                {"Traveller Type"}
              </div>
              <div className="tableValue col-md-1">
                {"PNR"}
              </div>
            </div>
            <div className="partialCancellation">
              {cancelData && sectorsRS && (
                <>
                  {sectorsRS.map((sec, idx) => (
                    <div key={idx}>
                      
                      <div className="tableData alignPaxDetails reissueF row">
                      <div className="tableValue col-md-1">
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleCheckboxChange(idx)}
                        checked={selectedSectors.includes(idx)}
                        custom
                        name="classes"
                        disabled={
                          sec.psngrs[0]?.tktst?.toUpperCase() !== "CONFIRMED" && sec.psngrs[1]?.tktst?.toUpperCase() !== "CONFIRMED" ? true : false
                        }
                      />
                      </div>
                        <div className="tableValue col-md-2">
                          {sec.src} - {sec.des}
                        </div>
                        <div className="tableValue col-md-2">
                          {selectedSectors.includes(idx) ? (
                            <Form.Group  className="selectplugin datePicker mb-0 ">
                              <DatePicker
                                value={sec.travelDate ? DateUtils.convertStringToDate(sec.travelDate) : null}
                                format="DD-MM-YYYY"
                                onChange={(travelDate) =>
                                  handleDateChangeRS(travelDate, idx)
                                }
                                placeholderText="MM/DD/YYYY"
                                minDate={DateUtils.convertNewToDate(sec.ad)}
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"], // don't allow it to flip to be above
                                  },
                                  preventOverflow: {
                                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                  },
                                  hide: {
                                    enabled: false, // turn off since needs preventOverflow to be enabled
                                  },
                                }}
                              />
                              <span className="calendar ezyIcon icon-newcalendar"></span>
                            </Form.Group>
                          ) : null}
                        </div>
                        <div className="tableValue col-md-2">
                          {selectedSectors.includes(idx) ? (
                            <Form.Group className="selectplugin reDropd">
                              <Select
                                aria-label="Default select example"
                                options={TravelClass}
                                value={sec.cl !== "" ? sec.cl : "Select"}
                                onChange={(e) => handleChangeClassRS(e, idx)}
                                onClick={(event) => handleSelectClick(event)}
                                placeholder="Select"
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group>
                              <Select
                                aria-label="Default select example"
                                options={TravelClass}
                                value={sec.cl !== "" ? sec.cl : "Select"}
                                onChange={(e) => handleChangeClassRS(e, idx)}
                                onClick={(event) => handleSelectClick(event)}
                                placeholder="Select"
                                style={{ visibility: "hidden" }}
                              />
                            </Form.Group>
                          )}
                        </div>
                        <div className="tableValue col-md-2">
                          {sec.psngrs.map((pax, index) => (
                            <React.Fragment key={index}>
                              <div className="paxFullName">
                                {pax.fn} {pax.ln}
                              </div>

                              {pax.inf !== undefined && pax.inf !== "" && (
                                <>
                                  <div className="paxFullName">
                                    {pax.inf.fn} {pax.inf.ln}
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="tableValue col-md-2">
                          {sec.psngrs.map((pax, index) => (
                            <React.Fragment key={index}>
                              <div>{pax.ty}</div>

                              {pax.inf !== undefined && pax.inf !== "" && (
                                <>
                                  <div>{pax.inf.ty}</div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="tableValue col-md-1">                          
                          {sec.psngrs.map((pax, index) => (
                            <React.Fragment key={index}>
                              <div>{sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}</div>
                              {pax.inf !== undefined && pax.inf !== "" && (
                                <>
                                <div>{sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}</div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={"Remarks"}
                onChange={handleRemarksChange(selectedRadio)}
                value={remarksRS[remarksRS.length - 1]}
                maxLength={100}
              />
            </Form.Group>
            <p className="noteWrap">
              {" "}
              <span className="notetitle">{"Note:"}</span>{" "}
              {
                "One of our executive will connect with you once your request is submitted"
              }{" "}
            </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {"Cancel"}
              </Button>
              {!isLoad?<Button
                type="primary"
                onClick={() => submitReissue(selectedRadio)}
                className="cntBtn"
                disabled={
                  (sectorsRS[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0 && sectorsRS[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0)
                    ? true
                    : false
                }
              >
                {"Request Reissue"}
              </Button>:
              <div class="progress progress-striped active">
              <div role="progressbar progress-striped" class="progress-bar">
                <span>Processing...</span>
              </div>
              </div>}
            </div>
          </div>
        )}
        {selectedRadio === "RP" && (
          <div>
            <div className="tableHeader reIssueTwo row m-0">
              <div className="tableValue col-md-2">
                {"Sectors"}
              </div>
              <div className="tableValue col-md-2">
                {"Date change"}
              </div>
              <div className="tableValue col-md-2">
                {"Change class"}
              </div>
              <div className="tableValue col-md-3">
                {"Travellers"}
              </div>
              <div className="tableValue col-md-2">
                {"Traveller Type"}
              </div>
              <div className="tableValue col-md-1">
                {"PNR"}
              </div>
            </div>
            <div>
              {cancelData &&
                sectorsRP &&
                sectorsRP.map((sec, parentidx) => (
                  <div className="tableData reIssueTwo alignPaxDetails row m-0">
                    <div className="tableValue col-md-2">
                      {sec.src} - {sec.des}
                    </div>
                    <div className="tableValue col-md-2">
                      {[sec.psngrs[0]].map((pax, index) => (
                        <>
                          {selectedCheckboxes[parentidx]?.length !== 0 && selectedCheckboxes[parentidx]?.length !== undefined && (selectedCheckboxes[parentidx]?.includes(index) || (parentidx === 0 && selectedCheckboxes[0] !== undefined) || selectedCheckboxes[1] !== undefined) ? (
                            <Form.Group className="position-relative">
                              <DatePicker
                                value={sec.travelDate ? DateUtils.convertStringToDate(sec.travelDate) : null}
                                format="DD-MM-YYYY"
                                onChange={(travelDate) =>
                                  handleDateChangeRP(travelDate, parentidx)
                                }
                                placeholderText="MM/DD/YYYY"
                                minDate={DateUtils.convertNewToDate(sec.ad)}
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"], // don't allow it to flip to be above
                                  },
                                  preventOverflow: {
                                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                  },
                                  hide: {
                                    enabled: false, // turn off since needs preventOverflow to be enabled
                                  },
                                }}
                              />
                              <span className="calendar ezyIcon icon-newcalendar"></span>
                            </Form.Group>
                          ) : null}
                        </>
                      ))}
                    </div>
                    <div className="tableValue col-md-2">
                      {[sec.psngrs[0]].map((pax, index) => (
                        <>
                          {selectedCheckboxes[parentidx]?.length !== 0 && selectedCheckboxes[parentidx]?.length !== undefined && (selectedCheckboxes[parentidx]?.includes(index) || (parentidx === 0 && selectedCheckboxes[0] !== undefined) || selectedCheckboxes[1] !== undefined ) ? (
                            <Form.Group>
                              <Select
                                aria-label="Default select example"
                                options={TravelClass}
                                value={sec.cl !== "" ? sec.cl : "Select"}
                                onChange={(e) =>
                                  handleChangeClassRP(e, parentidx)
                                }
                                placeholder="Select"
                              />
                            </Form.Group>
                          ) : (
                            <Form.Group>
                              <Select
                                aria-label="Default select example"
                                options={TravelClass}
                                value={sec.cl !== "" ? sec.cl : "Select"}
                                onChange={(e) =>
                                  handleChangeClassRP(e, parentidx)
                                }
                                placeholder="Select"
                                style={{ visibility: "hidden" }}
                              />
                            </Form.Group>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="tableValue col-md-3">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div className="d-flex">
                          <Form.Check
                            type="checkbox"
                            checked={selectedCheckboxes[parentidx]?.includes(
                              index
                            )}
                            onChange={() =>
                              handleNestedChange(parentidx, index)
                            }
                            custom
                            name="classes"
                            disabled={
                              pax?.tktst?.toUpperCase() !== "CONFIRMED" ? true : false
                            }
                          >
                          </Form.Check>
                          <div>
                            <div className="paxFullName">
                              {pax.fn} {pax.ln}
                            </div>

                            {pax.inf !== undefined && pax.inf !== "" && (
                              <>
                                <div className="paxFullName">
                                  {pax.inf.fn} {pax.inf.ln}
                                </div>
                              </>
                            )}
                            </div>
                            </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="tableValue col-md-2">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.ty}</div>

                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                              <div>{pax.inf.ty}</div>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="tableValue col-md-1">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}</div>
                          {pax.inf !== undefined && pax.inf !== "" && (
                            <>
                            <div>{sec.pnr !== "" && sec.pnr !== undefined ? sec.pnr : "N/A"}</div>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>    
                    {/* <div span={3} className="tableValue active">
                      {sec.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                          <div>{pax.tktst}</div>
                        </React.Fragment>
                      ))}
                    </div> */}
                  </div>
                ))}
            </div>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={"Remarks"}
                onChange={handleRemarksChange(selectedRadio)}
                value={remarksRP[remarksRP.length - 1]}
                maxLength={100}
              />
            </Form.Group>
            <p className="noteWrap">
              {" "}
              <span className="notetitle">{"Note:"}</span>{" "}
              {
                "One of our executive will connect with you once your request is submitted"
              }{" "}
            </p>
            <div className="buttonWrap">
              <Button onClick={backToNormal} className="backBtn">
                {"Cancel"}
              </Button>
              {!isLoad?<Button
                type="primary"
                onClick={() => submitReissue(selectedRadio)}
                className="cntBtn"
                disabled={
                  (sectorsRP[0]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0 && sectorsRP[1]?.psngrs.filter((pax) => pax.tktst == "Confirmed")
                    .length === 0)
                    ? true
                    : false
                }
              >
                {"Request Reissue"}
              </Button>:
              <div class="progress progress-striped active">
              <div role="progressbar progress-striped" class="progress-bar">
                <span>Processing...</span>
              </div>
              </div>}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ReissueFlightTrip;
