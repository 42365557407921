
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import DateUtils from '../../utilities/DateUtils';
import Select from 'react-dropdown-select';
let bookingStatusList = require('../../masterData/BookingStatus..json');

/**
 * @author Praveen Varma
 * @date 21-11-2023
 * @description Flight booking summary transaction data
 * @param {*} props 
 * @returns 
 */
function TransactionSummary(props) {
    const [state, setState] = useState({})
    const [bkdata, setBkData] = useState(props.bkngdetails);
    const [bookid, setBookid] = useState({ selBooking: [] })
    const [bookingList, setBookingList] = useState([])
    const [booking_status_str, setBokingStatusStr] = useState("");

    const [office_ids, set_office_ids] = useState({onward:"", return:""});

    //setBooking Status
    const setStatus = (data) => {
        props.sendBookStatus(data ? data[0]?.label:"")
    }


    useEffect(() =>{
        if(props?.bkngdetails){
            if(props.bkngdetails?.booking_status_str){
                setBokingStatusStr(props.bkngdetails?.booking_status_str);
            }else if(props.bkngdetails?.booking_status){
                let status = bookingStatusList?.filter(itm => itm.value === props.bkngdetails?.booking_status);
                if(status){
                    setBokingStatusStr(status[0].label);
                }
            }
        }
        if(props?.bkngdetails !== null && props?.bkngdetails?.office_id !== undefined && props?.bkngdetails?.office_id !== null && props?.bkngdetails?.office_id !== ""){
            let office_ids_split = props?.bkngdetails?.office_id?.split("/");
            let onward_offid="";
            let return_offid="";
            if(office_ids_split.length > 0){
                onward_offid=office_ids_split[0]
            }
            if(office_ids_split.length > 1){
                return_offid=office_ids_split[1]
            }
            set_office_ids({
                onward:onward_offid,
                return:return_offid
            })
        }
    })
    useEffect(() => {
        if (props?.enableEdit && props.bkngdetails !== undefined) {
            let bookingList = bookingStatusList.filter((obj) => obj.value !== "34" && obj.value !== "36" && obj.value !== "21" && obj.value !== "31" && obj.value !== "39" && obj.value !== "35" && obj.value !== "25");
            setBookingList(bookingList)
            for (let val of bookingList) {
                if (val.value === bkdata?.booking_status) {
                    setBookid({ selBooking: [{ label: val.label, value: val.value }] })
                }
            }
        }
    }, [props?.enableEdit])

    return (
        <>
            <div className="newlayoutDesign">
                <Row>
                    <Col xs={4}>
                        <Row>
                            <Col xs={12}><span>Booking Ref Number : </span> <span>{props.bkngdetails.booking_id}</span></Col>
                            <Col xs={12}><span>Booking Date : </span> <span>{DateUtils.prettyDate(bkdata?.booking_date, "ddd, DD MMM, YYYY")}</span></Col>
                            <Col xs={12}><span>Email  : </span><span>{bkdata?.email}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center"><span> Booking Status : </span>
                                {!props.enableEdit && <>
                                    {booking_status_str  && booking_status_str === "Cancelled" &&
                                        <span className="status paxstatus text-danger"> Cancelled</span>
                                    }
                                    {booking_status_str  && (booking_status_str == "On hold" || booking_status_str == "On Hold") &&
                                        <span className="status paxstatus"> On hold</span>
                                    }
                                    {booking_status_str  && booking_status_str === "Confirmed" &&
                                        <span className="status paxstatus text-success"> Confirmed</span>
                                    }
                                    {booking_status_str  && (booking_status_str === "Partially Confirmed") &&
                                        <span className="status paxstatus text-success"> Partially Confirmed</span>
                                    }
                                    {booking_status_str  && booking_status_str === "Incomplete" &&
                                        <span className="status paxstatus text-danger"> {bkdata?.booking_status_str}</span>
                                    }
                                    {booking_status_str && booking_status_str.toUpperCase() === "FAILED" &&
                                        <span className="status paxstatus text-danger"> Failed</span>
                                    }
                                </>
                                }
                                {props.enableEdit &&
                                    <Form.Group className="selectplugin editOne m-0" as={Col} controlId="firstName">
                                        <Select
                                            options={bookingList}
                                            searchable={false}
                                            values={bookid.selBooking}
                                            onChange={(value) => (setStatus(value))}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                            <Col xs={12}><span>Channel : </span> <span> {bkdata?.channel}</span></Col>
                            <Col xs={12}><span>Booking Type : </span>{bkdata?.bktype}</Col>
                        </Row>
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col xs={12}><span>Customer Name : </span><span>{bkdata?.customer_name}</span></Col>
                            <Col xs={12}><span>Phone  : </span><span>{bkdata?.phone_code ?bkdata?.phone_code+"-" : ""}{bkdata?.phone_no}</span> </Col>
                            {office_ids?.onward !== null && office_ids?.onward !== "" && (
                            <Col xs={12}><span>Onward Office Id  : </span><span>{office_ids?.onward}</span> </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <Row>
                            <Col xs={12}><span>Agency : </span><span>{bkdata?.company_name}</span></Col>
                            <Col xs={12}><span>Booked By : </span> <span>{bkdata?.bkby_name}</span></Col>
                            {office_ids?.return !== null && office_ids?.return !== "" && (
                            <Col xs={12}><span>Return Office Id  : </span><span>{office_ids?.return}</span> </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default TransactionSummary;
