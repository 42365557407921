/**
 * @ Author: Srikanth Bandaru
 * @ Create Time: 2023-05-18 00:16:44
 * @ Description: Common utilities for date related functions
 */

import moment from "moment";
var moments = require('moment-timezone');
class DateUtils { 
  //To convert from date string to required date format
  convertToLetterDate(dateString) {
    return moment(dateString).format("ddd, DD MMM, YYYY");
  }

  //For millisecond to time convert
  msToTime(duration) {
    let days = Math.floor(duration / (24 * 60 * 60 * 1000));
    let daysms = duration % (24 * 60 * 60 * 1000);
    let hours = Math.floor(daysms / (60 * 60 * 1000));
    let hoursms = duration % (60 * 60 * 1000);
    let minutes = Math.floor(hoursms / (60 * 1000));
    let minutesms = duration % (60 * 1000);
    let sec = Math.floor(minutesms / 1000);
    var dDisplay = days > 0 ? days + (days == 1 ? "d, " : "d, ") : "";
    var hDisplay = hours > 0 ? hours + (hours == 1 ? "h" : "h ") : "";
    var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? "m" : "m ") : "";
    return dDisplay + hDisplay + " " + mDisplay;
  }

  //For converting date to hours
  convertToHours(dateString) {
    return moment(dateString, "DD-MM-YYYYTHH:mm:ss").format("HH");
  }

  addDatePlusOne(dateString) {
    const dateObj = moment(dateString).add(1, 'days');
    return dateObj._d;
  }

  convertToDateHifen(dateString) {
    const dateObj = moment(dateString).format('DD-MM-YYYY');
    return dateObj;
  }
  
  //For checking date one is after date two
  isAfterTime(one, two) {
    var oneConverted = moment(one, "HH:mm:ss");
    var twoConverted = moment(two, "HH:mm:ss");
    var result = oneConverted.isAfter(twoConverted);
    return result;
  }
  
  convertNewToDate(dateString) {
    if (dateString === "" || dateString === undefined) return "";
    const dateObj = new Date(dateString);
    return dateObj;
  }

  prettyDate(timeStamp, format) {
    const dateObj = moment(timeStamp).format(format);
    return dateObj;
  }

  //For checking date one is before date two
  isBeforeTime(one, two) {
    var oneConverted = moment(one, "HH:mm:ss");
    var twoConverted = moment(two, "HH:mm:ss");
    var result = oneConverted.isBefore(twoConverted);
    return result;
  }

  //For checking date one is after date two
  isAfter(one, two) {
    var oneConverted = moment(one, "DD-MM-YYYYTHH:mm:ss");
    var twoConverted = moment(two, "DD-MM-YYYYTHH:mm:ss");
    var result = oneConverted.isAfter(twoConverted);
    return result;
  }

  //For checking date one is before date two
  isBefore(one, two) {
    var oneConverted = moment(one, "DD-MM-YYYYTHH:mm:ss");
    var twoConverted = moment(two, "DD-MM-YYYYTHH:mm:ss");
    var result = oneConverted.isBefore(twoConverted);
    return result;
  }

  getTimeZoneDate(date, domain_name) {
    let timeZone =  moments.tz.zonesForCountry(domain_name);
    //var utcTime    = moment.tz(date, timeZone[0]);
    let datetimestr = new Date(date).toUTCString("en-US", { timeZone: timeZone[0] })
    //let datetimestr = new Date(date.toUTCString()).toLocaleString("en-US", { timeZone: timeZone[0] })
    return moment(datetimestr).format('YYYY-MM-DD HH:mm:ss');
  }

calculateHoursandMinsbetweendates(endDate, startDate) {
  console.log('endDate---' + endDate);
  console.log('startDate----' + startDate);
  var timeStart = new Date(startDate).getTime();
  var timeEnd = new Date(endDate).getTime();
  var hourDiff = timeEnd - timeStart; //in ms
  var secDiff = hourDiff / 1000; //in s
  var minDiff = hourDiff / 60 / 1000; //in minutes
  return (minDiff);
}

  convertToFormat(inputDateStr){
    const inputDate = new Date(inputDateStr + "T00:00:00Z");
    const options = {
      weekday: 'short', // Abbreviated weekday name (e.g., "Tue")
      month: 'short',   // Abbreviated month name (e.g., "Aug")
      day: 'numeric',   // Day of the month (e.g., "29")
      year: 'numeric',  // Full year (e.g., "2023")
      timeZoneName: 'short' // Time zone name (e.g., "IST")
    };
    return new Intl.DateTimeFormat('en-IN', options).format(inputDate);
  }
}
export default new DateUtils();
