import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../../App";
import { Table, Row, Col } from "react-bootstrap";
import FormateCurrency from "../../utilities/FormatCurrency";
import Utilities from "../../utilities/Utilities";
import { isArrayNotEmpty, isNotNull } from "../../utilities/validators";

/**
 * @author Praveen Varma
 * @date 30-11-2023
 * @description Flight booking summary fare data
 * @param {*} props 
 * @returns 
 */
function HotelSummaryFare({ room, cur, index }) {
    const [context, setContext] = useContext(Context);
    const [fareData, setFareData] = useState([]);

    useEffect(() => {
        setFareData([]);
        if (room && room.fare_map) {
            setFareData([room.fare_map]);
        }
    }, []);

    //Calculating the Total salespr Amount
    const totalsalespr = (data) => {
        let total = 0;
        if (isNotNull(data)) {
            total = total + (data.bf || 0);
            total = total + (data.tax || 0);
            total = total + (data.markup || 0);
            total = total + (data.sup_fee || 0);
            total = total + (data.servicefee || 0);
            total = Utilities.addPrice(total, 0, "Add");
        }
        return total * (room.base_usr_exrate ? room.base_usr_exrate : 1);
    }

    const totalpurpr = (data) => {
        let total = 0;
        if (isNotNull(data)) {
            total = total + (data.bf || 0);
            total = total + (data.tax || 0);
            total = total + (data.markup || 0);
            total = total + (data.sup_fee || 0);
            total = Utilities.addPrice(total, 0, "Add");
        }
        return total * (room.base_usr_exrate ? room.base_usr_exrate : 1);
    }


    return (
        <>
        <div className="fareTablemHead">
        <h5 class="title">Fare Details {cur ? `(${cur})` : ""}</h5>

            {fareData && fareData.length > 0 &&
                <Row>
                    <Col className="fareTableLayout">
                        <Table className="fareTable noHover">
                            <thead>
                                <tr>
                                <th className="purcTitle">
                                        <h6 className="title"><span>Invoice</span></h6>
                                        Base Fare</th>
                                    <th>Tax</th>
                                    <th className="text-right">Payable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="space"> </td>
                                </tr>
                                {fareData.map((pr, idx) => (
                                    <tr>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.purpr.bf * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>

                                        </td>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.purpr.tax * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>
                                        <td className="tdborder text-right">
                                            <span>{<FormateCurrency value={totalpurpr(fareData[0].purpr)} />}</span>
                                        </td>

                                    </tr>
                                ))}
                                <tr className="alltotal">
                                    <td colSpan="11" className="text-right tdborder">
                                    <strong><span className="totalSp">Total Purchase : </span>{<FormateCurrency value={totalpurpr(fareData[0].purpr)} />}
                                    </strong>

                                    </td>
                                 
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="fareTable noHover">
                            <thead>
                                <tr>
                                    <th className="purcTitle">
                                        <h6 className="title"><span>Sales</span></h6>
                                        Base Fare</th>
                                    <th>Tax</th>
                                    <th>Markup</th>
                                    <th>Service Fee</th>
                                    <th>Discount</th>
                                    <th className="text-right">Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td colSpan="7" className="space">
                                </td></tr>
                                {fareData.map((pr, idx) => (
                                    <tr>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.salespr.bf * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.salespr.tax * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.salespr.markup * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>
                                        <td className="tdborder">
                                            <span>{<FormateCurrency value={pr.salespr.servicefee * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>

                                        <td className="tdborder">
                                            <span> {<FormateCurrency value={pr.salespr.mtadisc * (room.base_usr_exrate ? room.base_usr_exrate : 1) || 0} />}</span>
                                        </td>

                                        <td className="tdborder text-right">
                                            <span>{<FormateCurrency value={totalsalespr(fareData[0].salespr)} />}</span>
                                        </td>
                                    </tr>
                                ))}
                                <tr className="alltotal">
                                    <td colSpan="11" className="text-right tdborder"><strong> <span className="totalSp">Total Sales :</span> {<FormateCurrency value={totalsalespr(fareData[0].salespr)} />}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
             </div>
        </>
    )
}

export default HotelSummaryFare;