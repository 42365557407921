import React, { useContext, useEffect, useState } from "react";
import ResultSectionHeader from "./ResultSectionHeader";
import AirlinesMemo from "../../common/airlines/AirlinesMemo";
import AirportMemo from "../../common/airlines/AirportNames";
import DateUtils from "../../utilities/DateUtils";
import { Context } from "../../../App";
import { isNotNull } from "../../utilities/validators";
import Duration from "../../../assets/images/duration.png";
const _ = require("lodash");
const CDNURL = "https://img-clickntravel.mncdn.com";

/**
 * Flight Details for Queue popup
 * @author: Praveen Varma
 * @param {*} props 
 * @returns 
 */
function FlightDetailsContent(props) {
  const [context, setContext] = useContext(Context);
  const {
    mapping,
    sectors,
    secType,
    secTypeIndex,
    trip,
    flt,
    refund,
    flow,
    req,
    regionType,
  } = props;
  const [fltLoaded, setFltLoaded] = useState(false);
  const [gccCodes, setGccCodes] = useState([
    "XNB",
    "DWC",
    "DXB",
    "AZAZ",
    "DZB",
    "DOH",
    "XSB",
    "KWI",
    "MCT",
    "DMM",
    "SHJ",
    "IST",
    "JED",
  ]);
  //For layover time calculation
  const layOverTimeCal = (one, two, deptCode, arCode) => {
    if (deptCode !== undefined && arCode !== undefined) {
      var deptCnt = mapping.filter((obj) => obj.airportCode === deptCode);
      var arCnt = mapping.filter((obj) => obj.airportCode === arCode);
      let time1 = DateUtils.getTimeZoneDate(two, deptCnt[0].countryCode);
      let time2 = DateUtils.getTimeZoneDate(one, arCnt[0].countryCode);
      let timeDiff = DateUtils.calculateHoursandMinsbetweendates(time2, time1);
      let time = _.cloneDeep(timeDiff);
      let hrs = 0;
      let mins = 0;
      if (timeDiff < 60) {
        //inital minutes calculation
        mins = timeDiff;
      } else {
        for (let i = 0; i < timeDiff; i++) {
          if (time >= 60) {
            time = time - 60;
            hrs = hrs + 1;
          } else {
            mins = time;
            break;
          }
        }
      }

      return hrs + "h:" + mins + "m";
    } else {
      let totTime = Math.abs(new Date(one) - new Date(two));
      return DateUtils.msToTime(totTime);
    }
  };

  const getCheckInCabinValues = (selectedFlt) => {
    var check_qun = "";
    var cabin_qun = "0";
    let selFareObj =
      selectedFlt.fareop && selectedFlt.fareop.filter((obj) => obj.selected);
    if (selFareObj) {
      if (selectedFlt.sup === "4") {
        if (
          selFareObj[0].pr.ftyp === "Regular - R" ||
          selFareObj[0].pr.ftyp === "Flexi - J"
        ) {
          if (regionType === "Domestic") {
            check_qun = "15";
          } else if (regionType === "International") {
            if (
              (req.srcCode === "JED" &&
                isNotNull(req.dest) &&
                req.dest.country === "IN") ||
              (isNotNull(req.src) &&
                req.src.country === "IN" &&
                req.destCode === "JED")
            ) {
              check_qun = "30";
            } else if (
              (gccCodes.includes(req.srcCode) ||
                gccCodes.includes(req.destCode)) ||
              gccCodes.includes(req.src_code) ||
              gccCodes.includes(req.dest_code)
            ) {
              check_qun = "30";
            } else {
              check_qun = "20";
            }
          }
          cabin_qun = "7";
        } else if (selFareObj[0].pr.ftyp.includes("Corporate")) {
          cabin_qun = "10";
          if (regionType === "Domestic") {
            check_qun = "15";
          } else {
            check_qun = "30";
          }
        } else {
          check_qun = "30";
          cabin_qun = "7";
        }
      } else {
        // Setting Check in baggage
        if (selFareObj[0].pr.cabb !== undefined) {
          if (
            selFareObj[0].pr.cabb.includes("Chargable") ||
            selFareObj[0].pr.cabb.includes("Chargeable")
          ) {
            check_qun = "0";
          } else {
            if (
              selFareObj[0].pr.cabb.includes("free") ||
              selFareObj[0].pr.cabb.includes("Free")
            ) {
              if (selFareObj[0].pr.bagage_info !== undefined) {
                if (selFareObj[0].pr.bagage_info.includes("20")) {
                  check_qun = "20";
                } else if (selFareObj[0].pr.bagage_info.includes("30")) {
                  check_qun = "30";
                } else if (selFareObj[0].pr.bagage_info.includes("40")) {
                  check_qun = "40";
                }
              }
            } else if (selFareObj[0].pr.cabb.includes("20")) {
              check_qun = "20";
            } else if (selFareObj[0].pr.cabb.includes("30")) {
              check_qun = "30";
            } else if (selFareObj[0].pr.cabb.includes("40")) {
              check_qun = "40";
            }
          }
        }
        if (
          selFareObj[0].pr.chekb !== undefined &&
          selFareObj[0].pr.chekb !== ""
        ) {
          // Setting Cabin baggage
          if (
            selFareObj[0].pr.chekb.includes("Chargable") ||
            selFareObj[0].pr.chekb.includes("Chargeable")
          ) {
            cabin_qun = "0";
            if (selectedFlt.sup === "6") {
              cabin_qun = "7";
            } else if (selectedFlt.sup === "2") {
              cabin_qun = "10";
            }
          } else {
            if (selFareObj[0].pr.chekb.includes("7")) {
              cabin_qun = "7";
            } else if (selFareObj[0].pr.chekb.includes("10")) {
              cabin_qun = "10";
            }
          }
        } else {
          if (selectedFlt.sup === "6") {
            cabin_qun = "7";
          } else if (selectedFlt.sup === "2") {
            cabin_qun = "10";
          }
        }
      }
    }
    if (check_qun) {
      selectedFlt.cinqn = check_qun;
    }
    if (cabin_qun) {
      selectedFlt.cbnqn = cabin_qun;
    }
    setFltLoaded(true);
  };

  useEffect(() => {
    if (flt && flt.sup !== 0) {
      getCheckInCabinValues(flt);
    } else {
      setFltLoaded(true);
    }
  }, []);

  return (
    <>{fltLoaded &&
      (<>
      <div class="horizontalDivider"></div>
      <div className="info">
        <ResultSectionHeader
          mapping={mapping}
          sectors={sectors}
          secType={secType}
          secTypeIndex={secTypeIndex}
          trip={trip}
          refund={refund}
          flow={flow} />
      </div><div className="fdpr">
          <div className="leftSemicircle"></div>
          {flt &&
            flt.seg &&
            flt.seg.map((sege, index) => (
              <>
                <div className="ticket__divider">
                  <div className="ticket__notch"></div>
                  <div className="ticket__notch ticket__notch--right"></div>
                </div>
                <div className="airlinenamesnew">
                  <div className="airlineName">
                    {(
                      <>
                      
                        <img
                          src={CDNURL + "/airlines/1x/airline-logos/" +
                            sege.ma +
                            ".png"}
                          alt="AirLine" />
                          <div className="pCname">
                        <span className="planeName">
                          <AirlinesMemo code={sege.ma} />
                        </span>
                        <div className="planeCodeCatWrap">
                        <span className="planeCode">
                          {sege.ma} - {sege.fn}
                        </span>
                     
                        <span className="category">{flt.cl}</span>
                        </div>
                        <div className="operatedBy"><span>{"Operated By :"} </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                        </div>
                      </>
                    )}
                 
                  </div>
                  <div className="timeInfo">
                    <div>
                      <span className="time">
                        {sege.dd
                          .split("T")[1]
                          .substring(0, sege.dd.split("T")[1].length - 3)}{" "}
                      </span>
                      <span className="countryCode">{sege.da}</span>
                    </div>
                    <div className="aiNamterminal">
                    <span className="airportName">
                      <AirportMemo
                        field="ResultDetails"
                        code={sege.da}
                        mapping={mapping} />
                    </span>
                    {sege.terminal && sege.terminal.dep && (
                      <span className="terminal">
                        Terminal {context.translation && context.translation.terminal} {sege.terminal.dep}
                      </span>
                    )}
                    </div>
                  </div>
                  {flt.sdur !== "" && flt.sdur !== undefined && (
                    <div className="duration">
                      <span> {layOverTimeCal(sege.ad, sege.dd, sege.da, sege.ar)}</span>
                      <img src={Duration} alt="duration-icon" className="imgDimension" />
                      {/* <span className="diviDer"></span> */}
                     
                    </div>
                  )}

                  <div className="timeInfo">
                    <div>
                      <span className="time">
                        {sege.ad
                          .split("T")[1]
                          .substring(0, sege.ad.split("T")[1].length - 3)}{" "}
                      </span>
                      <span className="countryCode">{sege.ar}</span>
                    </div>

                    <div className="aiNamterminal">
               
                    <span className="airportName">
                      <AirportMemo
                        field="ResultDetails"
                        code={sege.ar}
                        mapping={mapping} />
                    </span>
                    {sege.terminal && sege.terminal.arr && (
                      <span className="terminal">
                        Terminal {context.translation && context.translation.terminal} {sege.terminal.arr}
                      </span>
                    )}
                    </div>
                  </div>
                </div>
                {flt.seg.length > 1 &&
                  flt.seg[index + 1] &&
                  flt.seg[index + 1].dd !== undefined && (
                    <div className="layover">
                      <span>
                        {" "}
                        Layover :&nbsp;
                        <>
                          {layOverTimeCal(
                            flt.seg[index].ad,
                            flt.seg[index + 1].dd
                          )}
                        </>
                      </span>
                    </div>
                  )}
              </>
            ))}
          <div className="rightSemicircle"></div>
        </div><div className="baggageAllowanceInfo">
          <div className="baggageAllowanceHeading">
            <span className="baggageAllowance">Baggage Info:</span>
          
          </div>
          {flt &&
            flt.bgd &&
            <div className="baggageWrapper">
              <div className="baggage">
                <i class="icon icon-Baggage"></i>
                <span className="cabin">Cabin</span>
                <span className="weight ml-1">7kg</span>,
              </div>
              <div className="baggage">
                <i class="icon-Check-in"></i>
                <span className="checkIn">Check-in</span>
                <span className="weight ml-1">
                  {flt.bgd && flt.bgd.length === 1 && flt.bgd[0] && flt.bgd[0].qun}{flt.bgd && flt.bgd.length === 1 && flt.bgd[0] && flt.bgd[0].wt}
                  {flt.bgd && flt.bgd.length > 1 && flt.bgd.filter((b) => b.type === "Checkin")[0] && flt.bgd.filter((b) => b.type === "Checkin")[0].qun}{flt.bgd && flt.bgd.length > 1 && flt.bgd.filter((b) => b.type === "Checkin")[0] && flt.bgd.filter((b) => b.type === "Checkin")[0].wt}
                </span>
              </div>
              <span className="perPassenger">Per Adult & Child</span>
            </div>}
          {!flt.bgd && (
            <div className="baggageWrapper">
              <div className="baggage">
                <i class="icon icon-Baggage"></i>
                <span className="cabin">Cabin Baggage</span>
                <span className="weight ml-1">{flt.cbnqn}kg</span>
              </div>
              <div className="baggage">
                <i class="icon-Check-in"></i>
                <span className="checkIn">Check-in Baggage</span>
                <span className="weight ml-1">{flt.cinqn}kg</span>
              </div>
            </div>
          )}
        </div></>)
    }
    </>
  );
}

export default FlightDetailsContent;
