import React ,{useState} from "react";
import { Col, Row, Container, Form, Dropdown, DropdownButton, Nav  } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HotelNewBooking from "./HotelNewBooking";
import HotelPostBook from "./HotelPostBook";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Sidebarmenu from "../../home/SideBar";

function HotelmanualBook() {
  const [manangeBook, setManageBook] = useState({
      active: 'NewBookingTab'
  });



  
  return (
      <>

<Container fluid>
           <Header />

           <Row>
          <Col xs={2} className="pl-0 sidebarMain">
          <Sidebarmenu active="Hotel" />
         
            </Col>
            <Col xs={10}  className="mainftr">
<div className="cardMainlayout mangeBooking mb-0">
<HotelNewBooking />

    {/* <Tabs
      defaultActiveKey="hotelNewBooking"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="hotelNewBooking" title="New Bookings">
      <HotelNewBooking />
      </Tab>
      <Tab eventKey="hotelpostbook" title="Post Bookings">
      <HotelPostBook />
      </Tab>
    </Tabs> */}
    </div>
    <Footer />
                </Col>
            </Row>

           </Container>
      </>
  )
}
export default HotelmanualBook;