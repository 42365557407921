import axios from "axios";
const API_URL = process.env.REACT_APP_CRM_ENGINE_URL

/**
 * @author: Ubedullah
 * @description: Integrated the Payment CURD Operations service
 * @param {*} Service
 */
class PaymentService {
  async savePayment(obj) {
    return await axios.post(`${API_URL}/crmc/payment/payment-save`, obj);
  }

  async getPaymentData(obj) {
    return await axios.post(
      `${API_URL}/crmc/payment/payment-fetchall`,
      obj
    );
  }

  async updatePayment(obj) {
    return await axios.post(
      `${API_URL}/crmc/payment/payment-update/${obj.id}`,
      obj
    );
  }

  async resendPaymentLink(obj) {
    return await axios.post(
      `${API_URL}/crmc/payment/resend_pmtlink`,
      obj
    );
  }

  async loadPaymentLinksHistory(obj) {
      return await axios.post(`${API_URL}/crmc/payment/fetch_links`, obj);
  }
}
export default new PaymentService();
