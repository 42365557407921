import React, { useState, useContext } from 'react';
import { Button, Col, Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Cntlogo from "../../assets/images/cntlogo.png";
import AuthenticationService from '../../services/AuthenticationService';
import { Context } from "../../App";
import { validateEmail } from "../utilities/FormValidations";
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
export const USER_AUTH_DATA = "userAuthData";


/**
 * @author Praveen Varma
 * @date 03-11-2023
 * @description Login page
 * @param {*} props 
 * @returns 
 */
function LoginForm(props) {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context);
    const [state, setState] = useState({ email: "", password: "", notiMessage: '', notiMessageShow: false, notiVarient: '', nwPass: '', cnfPass:'', newPasErr:"", cnfPassErr:'' })
    const [emailErr, setEmailError] = useState("");
    const [pwdErr, setPwdErr] = useState("");
    const [isForgot, setIsForgot] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [loginScreen, setLoginScreen] = useState(true);
    const [isLoginOTPPopup, setIsLoginOTPPopup] = useState(false);
    const [isForgotOTPPopup, setIsForgotOTPPopup] = useState(false);
    const [isChngPwdPopup, setIsChngPwdPopup] = useState(false);
    const [otp, setOtp] = useState('');
    const [error, setErrorMsg] = useState("");


    useEffect(() =>{
        setLoginScreen(true);
           
      
    },[])

    useEffect(() => {
        setTimeout(function () {
            setState(prevState => ({
                ...prevState,
                notiMessageShow: false,
                notiMessage: "",
                notiMessage: ""
            }));
        }, 5000);
    }, [state?.notiMessageShow])

    useEffect(() =>{
        setState(prevState => ({
            ...prevState,
            password:""
        }));
        setErrorMsg("");
        setEmailError("")
        setPwdErr("");
    },[otp])

    /**
     * @author Praveen Varma
     * @date 03-11-2023
     * Text field changes
     */
    const handleChange = (e) => {
        setErrorMsg("");
        const { id, value } = e.target
        if(emailErr && id && id === "email") setEmailError("");
        if(pwdErr && id && id === "password") setPwdErr("");
        setState(prevState => ({
            ...prevState,
            newPasErr:"",
            cnfPassErr:"",
            [id]: value
        }));
    };
    
    
    /**
     * @author Praveen Varma
     * @date 03-11-2023
     * checking the field validations
     * @param {*} e 
     */
    const validateLogin = (e) => {
        setErrorMsg("");
        e.preventDefault();
        let isValid = true;
        if (state.email === "" || state.email === null) {
            setEmailError("Please enter email id");
            isValid=false;
        }else if(!validateEmail(state.email)){
            setEmailError("Please provide valid email id");
            isValid=false;
        }
        if (state.password === "" || state.password === null) {
            setPwdErr("Please enter password");
            isValid=false;
        }
        if(isValid){
            loginUser();
        }
    }

    /**
     * @author Praveen Varma
     * @date 03-11-2023
     * Validate Forgot Password
     * @param {*} e 
     */
    const validateForgotPassword = (e) =>{
        setErrorMsg("");
        e.preventDefault();
        let isValid = true;
        if (state.email === "" || state.email === null) {
            setEmailError("Please enter email id");
            isValid=false;
        }else if(!validateEmail(state.email)){
            setEmailError("Please provide valid email id");
            isValid=false;
        }
        if(isValid){
            let payload ={
                email: state.email,
           }
           setIsLoad(true)
           AuthenticationService.forgotPwd(payload).then(response =>{
               if(response.data && response.data.suc) {
                   if (response?.data?.res?.is_OTP) {
                       setIsLoad(false)
                       setLoginScreen(false);
                       setIsForgot(false);
                       setIsForgotOTPPopup(true);
                       setState(prevState => ({
                           ...prevState,
                           notiMessageShow: true,
                           notiMessage: "OTP sent to your email",
                           notiVarient: "success"
                       }));
                   } else {
                       setIsLoad(false)
                       setState(prevState => ({
                           ...prevState,
                           notiMessageShow: true,
                           notiMessage: "Error while sending mail",
                           notiVarient: "danger"
                       }));
                   }
               }else{
                setIsLoad(false)
                setState(prevState => ({
                    ...prevState,
                    notiMessageShow: true,
                    notiMessage: response?.data?.err ? response?.data?.err : "Error while sending mail",
                    notiVarient: "danger"
                }));
               }
           })
        }
    }

    const clearFields = () =>{
        setState(prevState => ({
            ...prevState,
            password:""
        }));
        setErrorMsg("");
        setEmailError("")
        setPwdErr("");
        setOtp("");
    }

    /**
     * Sign in API Call
     * @author Praveen Varma
     * @date 03-11-2023
     */
    const loginUser = async() =>{
        setErrorMsg("");
        let payload ={
             eid: state.email,
        }
        if(isLoginOTPPopup){
            payload.otp= otp
        }else{
            payload.pwd= state.password
        }
        setIsLoad(true)
        AuthenticationService.signIn(payload).then(response =>{
            if(response.data && response.data.suc) {
                if(response?.data?.res?.is_OTP){
                    setIsLoad(false)
                    setState(prevState => ({
                        ...prevState,
                        password:"",
                        notiMessageShow: true,
                        notiMessage: "OTP sent to your email",
                        notiVarient: "success"
                    }));
                    setOtp("");
                    setLoginScreen(false);
                    setIsLoginOTPPopup(true);
                    return;
                }
                setIsLoad(false)
                setIsLoginOTPPopup(false);
                setContext({ logindata: response.data.res })
                localStorage.setItem(USER_AUTH_DATA, JSON.stringify(response.data.res));
                navigate("/crm/queues");
            }else{
                setIsLoad(false)
                setErrorMsg(response?.data?.err || "Invalid"); // For invalid credentials using pwdError variable
            }
        })
    }

    /**
     * Clearing the form fields
     * @author Praveen Varma
     * @date 03-11-2023
     * @param {*} e 
     */
    const clearNotifyMsg = (e) =>{
        setState(prevState => ({
            ...prevState,
            notiMessageShow: false,
        }));
    }

    /**
     * Signin OTP Verification
     * @author Praveen Varma
     * @date 03-11-2023
     * @param {*} e 
     * @returns 
     */
    const verifyLoginOTP = async(e) =>{
        setErrorMsg("");
        e.preventDefault();
        if(otp === "" || otp === null){
            setErrorMsg("Please provide OTP");
            return;
        }
        loginUser();
    }

    /**
     * Resend OTP for Signin and Forgot password
     * @author Praveen Varma
     * @date 03-11-2023
     * @param {*} e 
     */
    const resendOTP = async(e) =>{
        setErrorMsg("");
        let payload ={
            email: state.email,
            type: "RESEND"
       }
       AuthenticationService.resendOTP(payload).then(response =>{
           if(response.data && response.data.suc) {
               if (response?.data?.res?.is_OTP) {
                   setState(prevState => ({
                       ...prevState,
                       notiMessageShow: true,
                       notiMessage: "OTP sent to your email",
                       notiVarient: "success"
                   }));
               } else {
                   setState(prevState => ({
                       ...prevState,
                       notiMessageShow: true,
                       notiMessage: "Error while send OTP",
                       notiVarient: "danger"
                   }));
               }
           }else{
            setState(prevState => ({
                ...prevState,
                notiMessageShow: true,
                notiMessage: "Failed to send OTP",
                notiVarient: "danger"
            }));
           }
       })
    }

    /**
     * Verifying OTP for forgot password
     * @author Praveen Varma
     * @date 03-11-2023
     * @param {*} e 
     * @returns 
     */
    const verifyForgotOTP = (e) =>{
        setErrorMsg("");
        e.preventDefault();
        if(otp === "" || otp === null){
            setErrorMsg("Please provide OTP");
            return;
        }
        let payload ={
            email: state.email,
            otp: otp
       }
       AuthenticationService.verifyForgotPwdOTP(payload).then(response =>{
           if(response.data && response.data.suc) {
            setIsForgotOTPPopup(false);
            setIsChngPwdPopup(true);
           }else{
            setErrorMsg(response?.data?.err ? "Invalid OTP" : "Failed to send OTP");
           }
       })
    }

    /**
     * Change password
     * @author Praveen Varma
     * @date 03-11-2023
     * @param {*} e 
     * @returns 
     */
    const changePassword = (e) =>{
        setErrorMsg("");
        e.preventDefault();
        setState(prevState => ({
            ...prevState,
            newPasErr: "",
            cnfPassErr:""
        }));
        if(state.nwPass === "" || state.nwPass === null){
            setState(prevState => ({
                ...prevState,
                newPasErr: "Enter new password"
            }));
            return;
        }
        if(state.cnfPass === "" || state.cnfPass === null){
            setState(prevState => ({
                ...prevState,
                cnfPassErr: "Enter confirm password"
            }));
            return;
        }
        if(state.nwPass !== state.cnfPass){
            setState(prevState => ({
                ...prevState,
                cnfPassErr: "New password and confirm password should be match"
            }));
            return;
        }
        let payload ={
            email: state.email,
            type: "FORGOT",
            new_pw: state.nwPass
       }
       AuthenticationService.changePassword(payload).then(response =>{
           if(response.data && response.data.suc) {
            setIsForgotOTPPopup(false);
            setIsChngPwdPopup(false);
            setIsLoginOTPPopup(false);
            setLoginScreen(true);
            setState(prevState => ({
                ...prevState,
                email:"",
                nwPass:"",
                cnfPass:"",
                notiMessageShow: true,
                notiMessage: "Password changed successfully",
                notiVarient: "success"
            }));
            setOtp("");
           }else{
            setState(prevState => ({
                ...prevState,
                cnfPassErr: "Enter confirm password"
            })); // setting error message changes password error to cnfPassErr (Instead of createing new using exising key)
           }
       })
    }

    return (
        <>
            <div className="loginLayout">
                <div className="error">
                    <div className="container">
                        <div className="loginForm">
                            {state.notiMessageShow &&
                                <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                                    onClose={(e) => clearNotifyMsg(e)}
                                    dismissible> {state.notiMessage}</Alert>
                            }
                        
                            <div className='logoCnt'> <img src={Cntlogo} alt="Logo" /></div>
                            {loginScreen &&
                                <form onSubmit={e => validateLogin(e)}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} controlId="email">
                                            <span className="mailIcon ezyIcon icon-person"></span>
                                            <Form.Control type="text" className="username tt" placeholder="Enter User name" name="email" autoComplete="off"
                                                value={state.email} onChange={handleChange} />
                                            {emailErr ?
                                                <small className="errorText">
                                                    {emailErr}
                                                </small> : ""
                                            }
                                        </Form.Group>
                                        {!isForgot &&
                                        <Form.Group as={Col} xs={12} controlId="password" className="password">
                                            <span className="lockIcon ezyIcon icon-password"></span>
                                            <Form.Control className="password" placeholder="Enter Password" name="password" autoComplete="off" type='password'
                                                value={state.password} onChange={handleChange} />
                                            {pwdErr ?
                                                <small className="errorText">
                                                    {pwdErr}
                                                </small> : ""
                                            }
                                             {error ?
                                            <small className="errorText">
                                                {error}
                                            </small> : ""
                                        }
                                        </Form.Group>}
                                       
                                        {!isForgot &&
                                            <Form.Group as={Col} xs={6}>
                                                <div className='forgrtPass d-flex '>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="save"
                                                        label="Remember me"
                                                    />
                                                </div>
                                            </Form.Group>
                                        }
                                        {!isForgot &&
                                            <Form.Group as={Col} xs={6}>
                                                <div className='rememberMe d-flex'>
                                                    <Link onClick={() => {
                                                        clearFields();
                                                        setIsForgot(pre => !pre)
                                                    }}>Forgot Password?</Link>
                                                </div>
                                            </Form.Group>
                                        }
                                        {isForgot &&
                                            <Form.Group as={Col}>
                                                <div className='rememberMe d-flex'>
                                                    <Link onClick={() => {
                                                        clearFields();
                                                        setIsForgot(pre => !pre)
                                                    }}>Go back for Login</Link>
                                                </div>
                                            </Form.Group>
                                        }
                                    </Form.Row>

                                    <div className="text-right submitButton">
                                        {isForgot &&
                                            (!isLoad?<Button type="submit" variant="primary" onClick={validateForgotPassword}>
                                                Forgot password
                                            </Button>:
                                            <div class="progress loginP progress-striped active">
                                            <div role="progressbar progress-striped" class="progress-bar login-progress">
                                            <span>Processing...</span>
                                            </div>
                                            </div>)
                                        }
                                        {!isForgot &&
                                            (!isLoad?<Button type="submit" variant="primary" onClick={validateLogin}>
                                                Login
                                            </Button>:
                                            <div class="progress loginP progress-striped active">
                                            <div role="progressbar progress-striped" class="progress-bar login-progress">
                                            <span>Logging In...</span>
                                            </div>
                                            </div>)
                                            }
                                        
                                    </div>
                                </form>
                            }
                            {isLoginOTPPopup &&
                                <form onSubmit={e => verifyLoginOTP()} className='otpVerify'>
                                    <h1 className='OtpChange'>OTP Screen</h1>
                                    <Form.Row>
                                        <Form.Group as={Col} className='otpScreen'>
                                            <OtpInput
                                                className="otpPla"
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                             {error ?
                                            <small className="errorText">
                                                {error}
                                            </small> : ""
                                        }
                                        </Form.Group>
                                        
                                    </Form.Row>
                                   <Form.Row>
                                    <Form.Group as={Col}>
                                        <div className='forgrtPass d-flex'>
                                            <Link onClick={() => {
                                                clearFields();
                                                setLoginScreen(true);
                                                setIsLoginOTPPopup(false);
                                                setIsForgotOTPPopup(false);
                                            }}>Go back for Login</Link>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6}>
                                        <div className='rememberMe d-flex'>
                                            <Link onClick={() => {
                                                setOtp("");
                                                resendOTP();
                                            }}>Resend OTP?</Link>
                                        </div>
                                    </Form.Group>
                                    </Form.Row>
                                    <div className="text-right submitButton">
                                        <Button type="submit" variant="primary" onClick={verifyLoginOTP}>
                                            OTP Verify
                                        </Button>
                                    </div>
                                </form>
                            }
                            {isForgotOTPPopup &&
                                <form onSubmit={e => verifyForgotOTP()} className='otpVerify'>
                                    <h1 className='OtpChange'>OTP Screen</h1>
                                    <Form.Row>
                                        <Form.Group as={Col} className='otpScreen'>
                                        <OtpInput
                                            className="otpPla"
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                          {error ?
                                            <small className="errorText">
                                                {error}
                                            </small> : ""
                                        }
                                        </Form.Group>
                                      
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} xs={6}>
                                        <div className='rememberMe d-flex justify-content-start'>
                                            <Link onClick={() => {
                                                setOtp("");
                                                resendOTP();
                                            }}>Resend OTP?</Link>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6}>
                                        <div className='rememberMe d-flex'>
                                            <Link onClick={() => {
                                                clearFields();
                                                setLoginScreen(true);
                                                setIsLoginOTPPopup(false);
                                                setIsForgotOTPPopup(false);
                                            }}>Go back for Login</Link>
                                        </div>
                                    </Form.Group>
                                   
                                    </Form.Row>
                                    <div className="text-right submitButton">
                                        <Button type="submit" variant="primary" onClick={verifyForgotOTP}>
                                            OTP Verify
                                        </Button>
                                    </div>
                                </form>
                            }
                            {isChngPwdPopup && 
                                <form onSubmit={e => changePassword()}>
                                    <h1 className='changePassword'>Change Password</h1>
                                    <Form.Group as={Col} xs={12} controlId="nwPass" className="nwPass">
                                        <span className="lockIcon ezyIcon icon-password"></span>
                                        <Form.Control className="nwPass" placeholder="Enter New Password" name="nwPass" autoComplete="off" type='password'
                                            value={state.nwPass} onChange={handleChange} />
                                        {state.newPasErr ?
                                            <small className="errorText">
                                                {state.newPasErr}
                                            </small> : ""
                                    }
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} controlId="cnfPass" className="cnfPass">
                                        <span className="lockIcon ezyIcon icon-password"></span>
                                        <Form.Control className="cnfPass" placeholder="Enter Confirm Password" name="cnfPass" autoComplete="off" type='password'
                                            value={state.cnfPass} onChange={handleChange} />
                                        {state.cnfPassErr ?
                                            <small className="errorText">
                                                {state.cnfPassErr}
                                            </small> : ""
                                    }
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div className='rememberMe d-flex'>
                                            <Link onClick={() => {
                                                clearFields();
                                                setLoginScreen(true);
                                                setIsLoginOTPPopup(false);
                                                setIsForgotOTPPopup(false);
                                                setIsChngPwdPopup(false)
                                            }}>Go back for Login</Link>
                                        </div>
                                    </Form.Group>
                                    <div className="text-right submitButton">
                                        <Button type="submit" variant="primary" onClick={changePassword}>
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LoginForm;