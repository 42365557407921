import React,{useEffect, useState} from "react";
import { useContext } from "react";
import { Context } from "../../../../App";

function PassengersDetails(props) {
  const [enableParentCls,setEnableParentCls] = useState(true)
  const [context, setContext] = useContext(Context)
  useEffect(() => {
    if(props.sup!=="0"){
      setEnableParentCls(false);
    }
  }, [props.sup]);

  return (
    <>
      <h3 className="heading">
       {"Traveller Details"}
      </h3>
      <div className="tableWrap TravellerdetailsPass">
      <div className="tableHeader row m-0">
        <div className="tableValue col-md">
          {"Traveller"}
        </div>
        <div className="tableValue col-md">
          {"Ticket Number"}
        </div>
        {props.sup==="0" &&
        <>
        <div className="tableValue col-md">
        {"Special Request"}
        </div>
        <div className="tableValue col-md">
        {"Frequent Flyer"}  #
        </div>
        </>
        }
        {props.sup!=="0" &&
        <>
        <div className="tableValue col-md">
        {"Baggage"}
        </div>
        <div className="tableValue col-md">
        {"Meal"}
        </div>
        <div className="tableValue col-md">
        {"Seat"}
        </div>
        </>
        }
        <div className="tableValue col-md">
          {"Status"}
        </div>
      </div>
      {props.response && props.response.psngrs.map((pax, index) => (
      <React.Fragment key={index}>
      <div className="tableData alignPaxDetails row m-0">
        <div className="tableValue col-md">
        {pax.tl} {pax.fn} {pax.ln} ({pax.ty})
        </div>
        
        {pax.tkt!==undefined && pax.tkt!=="" &&
        <div className="tableValue col-md">
        {pax.tkt}
       
        </div>
        }
        {(pax.tkt===undefined || pax.tkt==="") && 
        <div className="tableValue col-md">
        {"N/A"}
        
        </div>
         }
        {props.sup==="0" && pax.opref!=undefined &&
        <div className="tableValue col-md">
          {pax.opref.sa || pax.opref.sp || pax.opref.mp ?
            <>
              {/* {pax.opref.sa!=""?pax.opref.sa+" / ":""} {pax.opref.sp!==""?pax.opref.sp+" / ":""} {pax.opref.mp!==""?pax.opref.mp:""}  */}
              {pax.opref.sa && pax.opref.sp ==="" && pax.opref.mp ==="" && (
                <>
                  {pax.opref.sa}
                </>
              )}
              {pax.opref.sp && pax.opref.sa === "" && pax.opref.mp === "" && (
                <>
                  {pax.opref.sp}
                </>
              )}
              {pax.opref.mp && pax.opref.sp === "" && pax.opref.sa === "" && (
                <>
                  {pax.opref.mp}
                </>
              )}
              {pax.opref.sa && pax.opref.sp && pax.opref.mp === "" && (
                <>
                  {pax.opref.sa + " / " + pax.opref.sp}
                </>
              )}
              {pax.opref.sa && pax.opref.mp && pax.opref.sp === "" && (
                <>
                  {pax.opref.sa + " / " + pax.opref.mp}
                </>
              )}
              {pax.opref.sp && pax.opref.mp && pax.opref.sa === "" && (
                <>
                  {pax.opref.sp + " / " + pax.opref.mp}
                </>
              )}
              {pax.opref.sa && pax.opref.sp && pax.opref.mp && (
                <>
                  {pax.opref.sa + " / " + pax.opref.sp + " / " + pax.opref.mp}
                </>
              )}
            </> : "N/A"
          }
      
        </div>
        }

        {props.sup==="0" && pax.ffinfo!=undefined &&
        <div className="tableValue col-md">
         {pax.ffinfo.ffnum!==""?pax.ffinfo.ffnum:"N/A"}
        
        </div>
         }
         {props.sup!=="0" &&
         <>
         <div className="tableValue col-md">
         <div className="detailsPop">
         {pax.baggage!=undefined && pax.baggage? pax.baggage.label:"N/A"}
         </div>
        
        </div>
        <div className="tableValue 123 dd col-md">
          <div className="detailsPop">
            {pax.meal!=undefined && pax.meal? pax.meal.label:"N/A"}
        </div>
       
        </div>
        <div className="tableValue col-md">
        {pax.seat!==undefined && pax.seat? pax.seat.label:"N/A"}
       
        </div>
         </>
         }
         <div className={"tableValue active col-md "+pax.segst}>
         {pax.segst}
        
        </div>      
      </div>


      {pax.inf!==undefined && pax.inf!=="" &&  
            
          
      <div className="tableData alignPaxDetails row m-0">
        <div className="tableValue col-md">
        {pax.inf.tl} {pax.inf.fn} {pax.inf.ln} ({pax.inf.ty})
        </div>
        
        {pax.tkt!==undefined && pax.tkt!=="" &&
        <div className="tableValue col-md">
        {pax.inf!==undefined && pax.inf!=="" && pax.inf.tkt!=="" &&
            <div className="infDetails">
            <>
            {pax.inf.tkt}
            </>
            </div>
        }
        </div>
        }
        {(pax.tkt===undefined || pax.tkt==="") && 
        <div className="tableValue col-md">
        {pax.inf!==undefined && pax.inf!=="" &&
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
        </div>
         }
        {props.sup==="0" && pax.opref!=undefined &&
        <div className="tableValue col-md">
        <>
        {pax.inf!=="" && 
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
        </> 
        </div>
        }

        {props.sup==="0" && pax.ffinfo!=undefined &&
        <div className="tableValue col-md">
        <>
        {pax.inf!=="" &&         
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
        </> 
        </div>
         }
         {props.sup!=="0" &&
         <>
         <div className="tableValue col-md">
        <>
       
        {pax.inf!=="" &&         
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
     
        
        </> 
        </div>
        <div className="tableValue 123 dd col-md">
        <>
      
        {pax.inf!=="" &&         
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
       
        
        </> 
        </div>
        <div className="tableValue col-md">
        <>
        {pax.inf!=="" &&         
            <div className="infDetails">
            <>
            {"N/A"}
            </>
            </div>
        }
        </> 
        </div>
         </>
         }
         <div className="tableValue active col-md">
          {pax.inf!=="" && pax.inf!==undefined &&     
            <div className="infDetails">
            <>
            {pax.inf.segst} 
            </>
            </div>
          }
        </div>      
      </div>
      }
      </React.Fragment>
      ))}
      </div>
    </>
  );
}

export default PassengersDetails;
