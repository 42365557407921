import React, { useState, useContext, useReducer, useEffect } from "react";
import { Col, Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Context } from "../../../App";
import { savePayment, updatePayment, resendPaymentLink } from "./operations";

let stdCodes = require("../../masterData/CountrySTDCodes.json");
let bookingTypeOptions = require('../../masterData/BookingType.json');

/**
 * @description: Initial state
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function Payment Configuration
 */

const initialState = {
  product: "",
  bk_type: "",
  booking_id: "",
  amt: "",
  cus_name: "",
  email_id: "",
  phc: "",
  phno: "",
  remarks: "",
  cur: "",
  region_type: "",
};

// Reducer function for useReducer Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    case "editRecord":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CreatePayment(props) {
  const { edit, back, formData } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [dataCalled, setDataCalled] = useState(true);
  const [disableFormFields, setDisableFormFields] = useState(false);
  const [showPayUniqId, setShowPayUniqId] = useState(false);
  const [payUniqId, setPayUniqId] = useState(0);
  const [id, setId] = useState(0);
  const [error, setError] = useState(false);

  const regionOptions = [
    { label: "India", value: "India" },
    { label: "Qatar", value: "Qatar" },
  ];

  const productOptions = [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" },
  ];

  // const bookingTypeOptions = [
  //   { label: "Manual", value: "Manual" },
  //   { label: "Reissue", value: "Reissue" },
  //   { label: "Amendament", value: "Amendament" },
  //   { label: "Cancel", value: "Cancel" },
  // ];

  useEffect(() => {
    if (edit) {
      setId(formData.id);
      setEnableUpdate(true);
      dispatch({ type: "product", payload: formData.product });
      dispatch({ type: "region_type", payload: formData.region_type });
      dispatch({ type: "bk_type", payload: formData.booking_type });
      dispatch({ type: "booking_id", payload: formData.booking_id });
      dispatch({ type: "amt", payload: formData.amount });
      dispatch({ type: "cus_name", payload: formData.customer_name });
      dispatch({ type: "email_id", payload: formData.email });
      dispatch({ type: "phc", payload: formData.phone_code });
      dispatch({ type: "phno", payload: formData.phone_number });
      dispatch({ type: "remarks", payload: formData.remarks });
      if (
        formData?.status?.toUpperCase() === "CONFIRMED" ||
        formData?.status?.toUpperCase() === "FAILED"
      ) {
        setDisableFormFields(true);
      }
      setShowPayUniqId(true);
      setPayUniqId(formData.payment_unique_id);
    } else {
      setId(0);
      setEnableUpdate(false);
      dispatch({ type: "product", payload: "" });
      dispatch({ type: "region_type", payload: "" });
      dispatch({ type: "bk_type", payload: "" });
      dispatch({ type: "booking_id", payload: "" });
      dispatch({ type: "amt", payload: "" });
      dispatch({ type: "cus_name", payload: "" });
      dispatch({ type: "email_id", payload: "" });
      dispatch({ type: "phc", payload: "" });
      dispatch({ type: "phno", payload: "" });
      dispatch({ type: "remarks", payload: "" });
      setDisableFormFields(false);
      setShowPayUniqId(false);
      setPayUniqId(0);
    }
  }, [formData]);

  const handleFieldChange = (field, value) => {
    // dispatch({ type: "UPDATE_FIELD", field, value });
    if (field === "product") {
      dispatch({ type: "product", payload: value });
    }
    if (field === "region_type") {
      dispatch({ type: "region_type", payload: value });
    }
    if (field === "bk_type") {
      dispatch({ type: "bk_type", payload: value });
    }
    if (field === "booking_id") {
      dispatch({ type: "booking_id", payload: value });
    }
    if (field === "amt") {
      dispatch({ type: "amt", payload: value });
    }
    if (field === "cus_name") {
      dispatch({ type: "cus_name", payload: value });
    }
    if (field === "email_id") {
      dispatch({ type: "email_id", payload: value });
    }
    if (field === "phc") {
      dispatch({ type: "phc", payload: value });
    }
    if (field === "phno") {
      dispatch({ type: "phno", payload: value });
    }
    if (field === "remarks") {
      dispatch({ type: "remarks", payload: value });
    }
  };

  const paymentValidation = () => {
    if (state.region_type == "") {
      setError(true);
      return false;
    } else if (state.product == "") {
      setError(true);
      return false;
    } else if (state.bk_type == "") {
      setError(true);
      return false;
    } else if (
      state.bk_type === "Reissue" ||
      state.bk_type === "Amendament" ||
      state.bk_type === "Cancel"
    ) {
      if(state.booking_id === "") {
        setError(true);
        return false;
      }
    } else if (state.amt === "") {
      setError(true);
      return false;
    } else if (state.cus_name === "") {
      setError(true);
      return false;
    } else if (state.email_id === "") {
      setError(true);
      return false;
    } else if (state.phc === "") {
      setError(true);
      return false;
    } else if (state.phno === "") {
      setError(true);
      return false;
    } else if (state.remarks === "") {
      setError(true);
      return false;
    }
    return true;
  };

  const handleSubmitSave = (e) => {
    e.preventDefault();
    if (paymentValidation()) {
      try {
        state.crby = context?.logindata?.email;
        state.cur = state.region_type === "India" ? "INR" : "QAR";
        savePayment(state).then((response) => {
          if (response?.suc) {
            setNotiMessageShow(true);
            setNotiMessage("Payment invoice saved successfully");
            setNotiVarient("success");
            hidemessage();
            setDataCalled(!dataCalled);
            props.fetchData(true);
            setTimeout(() => {
              goBack();
            }, 1000);
          } else {
            setNotiMessageShow(true);
            setNotiMessage("Payment invoice not saved");
            setNotiVarient("success");
            hidemessage();
          }
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
      }
    }
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (paymentValidation()) {
      try {
        state.crby = context?.logindata?.email;
        state.id = id;
        state.cur = state.region_type === "India" ? "INR" : "QAR";
        updatePayment(state).then((response) => {
          if (response?.suc) {
            setNotiMessageShow(true);
            setNotiMessage("Payment invoice updated successfully");
            setNotiVarient("success");
            hidemessage();
            setDataCalled(!dataCalled);
            props.fetchData(true);
            setTimeout(() => {
              goBack();
            }, 1000);
          } else {
            setNotiMessageShow(true);
            setNotiMessage("Payment invoice not updated");
            setNotiVarient("success");
            hidemessage();
          }
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
      }
    }
  };

  const goBack = () => {
    setId(0);
    setEnableUpdate(false);
    dispatch({ type: "product", payload: "" });
    dispatch({ type: "region_type", payload: "" });
    dispatch({ type: "bk_type", payload: "" });
    dispatch({ type: "booking_id", payload: "" });
    dispatch({ type: "amt", payload: "" });
    dispatch({ type: "cus_name", payload: "" });
    dispatch({ type: "email_id", payload: "" });
    dispatch({ type: "phc", payload: "" });
    dispatch({ type: "phno", payload: "" });
    dispatch({ type: "remarks", payload: "" });
    setDisableFormFields(false);
    setShowPayUniqId(false);
    setPayUniqId(0);
    back(false);
  };

  const hidemessage = () => {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage("");
      setNotiVarient("");
    }, 3000);
  };

  return (
    <>
      <Col className="bookingSearch">
        {notiMessageShow && (
          <Alert
            className="notification alert"
            variant={notiVarient}
            show={notiMessageShow}
            onClose={() => setNotiMessageShow(false)}
            dismissible
          >
            {" "}
            {notiMessage}
          </Alert>
        )}
        <h5 className="pageTitle">Payments</h5>

        <div className="cardMainlayout payPage">
          <h5 className="pageTitle">Payment Invoice</h5>
          {showPayUniqId && (
            <h6 className="pageSubTitle">Payment Unique Id: <span>{payUniqId}</span> </h6>
          )}
          <Form.Row>
            {context?.logindata?.region_type.includes(",") && (
              <Form.Group controlId="productDropdown" as={Col} xs={3}>
                <Form.Label>Region<sup>*</sup></Form.Label>
                <Select

                  options={regionOptions}
                  placeholder="Select"
                  value={regionOptions.find(
                    (option) => option.value === state.region_type
                  )}
                  onChange={(selectedOption) =>
                    handleFieldChange("region_type", selectedOption.value)
                  }
                  isDisabled={disableFormFields}
                  classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F8F9FF',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
                />
                <small style={{ color: "red" }}>
                  {error && state.region_type === "" && "Please select Region"}
                </small>
              </Form.Group>
            )}

            <Form.Group controlId="productDropdown" as={Col} xs={3}>
              <Form.Label>Product<sup>*</sup></Form.Label>
              <Select
                options={productOptions}
                placeholder="Select"
                value={productOptions.find(
                  (option) => option.value === state.product
                )}
                onChange={(selectedOption) =>
                  handleFieldChange("product", selectedOption.value)
                }
                isDisabled={disableFormFields}
                classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F8F9FF',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
              />
              <small style={{ color: "red" }}>
                {error && state.product === "" && "Please select Product"}
              </small>
            </Form.Group>

            <Form.Group controlId="bookingTypeDropdown" as={Col} xs={3}>
              <Form.Label>Booking Type<sup>*</sup></Form.Label>
              <Select
                options={bookingTypeOptions}
                value={bookingTypeOptions.find(
                  (option) => option.value === state.bk_type
                )}
                onChange={(selectedOption) =>
                  handleFieldChange("bk_type", selectedOption.value)
                }
                isDisabled={disableFormFields}
                placeholder="Select"
                classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F8F9FF',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
              />
              <small style={{ color: "red" }}>
                {error && state.bk_type === "" && "Please select Booking type"}
              </small>
            </Form.Group>
            {state.bk_type !== "" && state.bk_type !== "Manual" && (
              <Form.Group controlId="bookingNumber" as={Col} xs={3}>
                <Form.Label>Booking Ref#</Form.Label>
                <Form.Control
                  type="text"
                  value={state.booking_id}
                  onChange={(e) =>
                    handleFieldChange("booking_id", e.target.value)
                  }
                  disabled={disableFormFields}
                  placeholder="Enter booking number"
                />
                <small style={{ color: "red" }}>
                  {error &&
                    state.booking_id === "" &&
                    "Please enter Booking id"}
                </small>
              </Form.Group>
            )}

            <Form.Group controlId="payableAmount" as={Col} xs={3}>
              <Form.Label>
                Payable Amount in
                <strong>
                  {context?.logindata?.region_type?.includes(",")
                    ? state.region_type === "India"
                      ? " INR"
                      : state.region_type === "Qatar" && " QAR"
                    : context?.logindata?.region_type === "Qatar"
                    ? " QAR"
                    : "INR"}
                </strong>
                <sup>*</sup></Form.Label>
              <Form.Control
                type="text"
                value={state.amt}
                onChange={(e) => handleFieldChange("amt", e.target.value)}
                disabled={disableFormFields}
                placeholder="Enter amount"
              />
              <small>
              <span className="noteQpnr"><span>Note:</span> The amount entered in this field should match the booking
                amount.</span>
              </small>
              <br />
              <small style={{ color: "red" }}>
                {error && state.amt === "" && "Please enter amount"}
              </small>
            </Form.Group>

            <Form.Group controlId="customerName" as={Col} xs={3}>
              <Form.Label>Customer Name<sup>*</sup></Form.Label>
              <Form.Control
                type="text"
                value={state.cus_name}
                onChange={(e) => handleFieldChange("cus_name", e.target.value)}
                disabled={disableFormFields}
                placeholder="Enter name"
              />
              <small style={{ color: "red" }}>
                {error && state.cus_name === "" && "Please enter customer name"}
              </small>
            </Form.Group>

            <Form.Group controlId="email" as={Col} xs={3}>
              <Form.Label>Email Id<sup>*</sup></Form.Label>
              <Form.Control
                type="email"
                value={state.email_id}
                onChange={(e) => handleFieldChange("email_id", e.target.value)}
                disabled={disableFormFields}
                placeholder="Enter email id"
              />
              <small style={{ color: "red" }}>
                {error && state.email_id === "" && "Please enter email id"}
              </small>
            </Form.Group>

            <Form.Group controlId="countryCodeDropdown" as={Col} xs={3} className="mobileNumber scrollHandle">
       
              <Form.Label>Phone Code<sup>*</sup></Form.Label>
              <Select
              className="brdRadius"
                options={stdCodes}
                value={stdCodes.find((option) => option.value === state.phc)}
                onChange={(selectedOption) =>
                  handleFieldChange("phc", selectedOption.value)
                }
                isDisabled={disableFormFields}
                classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F8F9FF',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
              />
              <small style={{ color: "red" }}>
                {error && state.phc === "" && "Please select phone code"}
              </small>
            
            </Form.Group>

            <Form.Group controlId="phone" as={Col} xs={3}>
            <Form.Label className="mNumber">Mobile Number<sup>*</sup></Form.Label>
              <Form.Control
                type="text"
                value={state.phno}
                onChange={(e) => handleFieldChange("phno", e.target.value)}
                disabled={disableFormFields}
                placeholder="Enter mobile number"
              />
              <small style={{ color: "red" }}>
                {error && state.phno === "" && "Please enter mobile number"}
              </small>
            </Form.Group>

            <Form.Group controlId="remarks" as={Col} xs={6}>
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={state.remarks}
                onChange={(e) => handleFieldChange("remarks", e.target.value)}
                disabled={disableFormFields}
                placeholder="Remarks"
                maxLength={100}
              />
              <small style={{ color: "red" }}>
                {error && state.remarks === "" && "Please enter remarks"}
              </small>
            </Form.Group>
          </Form.Row>
          <div className="d-flex justify-content-end buttonGrop pb-0 creat_absolute">
            <Button
              size="xs"
              variant="outline-secondary"
              className="mr-3"
              type="submit"
              onClick={goBack}
            >
              {" "}
              Back
            </Button>
            <div>
              {enableUpdate ? (
                <Button
                  variant="primary"
                  onClick={handleSubmitUpdate}
                  disabled={disableFormFields}
                >
                  Update Payment link
                </Button>
              ) : (
                <Button variant="primary" onClick={handleSubmitSave}>
                  Generate Payment link
                </Button>
              )}
            </div>
          </div>
        </div>

        {formData?.status?.toUpperCase() === "CONFIRMED" && (
          <div className="cardMainlayout">
            <h5 className="pageTitle">Payment Transaction Details</h5>
          </div>
        )}
      </Col>
    </>
  );
}

export default CreatePayment;
