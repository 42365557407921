import axios from 'axios'
const API_URL = process.env.REACT_APP_CRM_ENGINE_URL

/**
 * @author: Praveen Varma
 * @date 03-11-2023
 * @description: Integrated the Login related API calls
 * @param {*} Service 
 */
class AuthenticationService {

    /**
     * User Signin API Call
     * @author Praveen Varma
     * @date 03-11-2023
     * @description whether the user is valid or not
     * @param {*} payload have email and pwd
     * @returns 
     */
    async signIn(payload) {
        return await axios.post(`${API_URL}/crmc/user/signin`, payload)
    }

     /**
     * User Resend OTP Verify API Call
     * @author Praveen Varma
     * @date 03-11-2023
     * @description whether the user OTP is valid or not
     * @param {*} payload have email and pwd
     * @returns 
     */
    async resendOTP(payload) {
        return await axios.post(`${API_URL}/crmc/user/resend-otp`, payload)
    }

    /**
     * User Forgot password API Call
     * @author Praveen Varma
     * @date 03-11-2023
     * @description sending mail to respected user
     * @param {*} payload
     * @returns 
     */
     async forgotPwd(payload) {
        return await axios.post(`${API_URL}/crmc/user/forgot-password`, payload)
    }

    /**
     * Verify Forgot password OTP API Call
     * @author Praveen Varma
     * @date 03-11-2023
     * @description sending mail to respected user
     * @param {*} payload
     * @returns 
     */
     async verifyForgotPwdOTP(payload) {
        return await axios.post(`${API_URL}/crmc/user/forgot-passwordOtp`, payload)
    }

    /**
     * User Change password API Call
     * @author Praveen Varma
     * @date 03-11-2023
     * @description sending mail to respected user
     * @param {*} payload
     * @returns 
     */
     async changePassword(payload) {
        return await axios.post(`${API_URL}/crmc/user/change-password`, payload)
    }
}

export default new AuthenticationService()