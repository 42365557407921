
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import Select from 'react-dropdown-select';
import { Context } from "../../../App";
import Button from '../../common/Button';
import DateUtils from '../../common/DateUtils';
import Constants from '../../utilities/Constants';
// let booking_status = require('../../masterData/BookingStatus..json');

/**
 * @description:This function will crete all the search criteria
 * @author: Praveen Varma
 * @param {*}
 * @function Common Search component
 * @date : 07-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
  booking_id: "", user_id: 0, productType: "Flight", case_number:"", case_status:"",issue_status :""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state,
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function CommonQueueSearch(props) {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [channel_type, setChanelType] = useState("CNT");
  const [channel_temp, setChnelTemp] = useState([{ label: "CNT", value: 'CNT' }]);
  const channel_types = [{ label: "CNT", value: 'CNT' }]

  const selectOptions = (context?.logindata?.roles?.includes('Q_U_F') && context?.logindata?.roles?.includes('Q_U_H')) ? [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" }
  ] : context?.logindata?.roles?.includes('Q_U_F') ? [
    { label: "Flight", value: "Flight" }
  ] : context?.logindata?.roles?.includes('Q_U_H') && [
    { label: "Hotel", value: "Hotel" }
  ]

  let booking_status = [{ "label": "Confirmed", "value": "23" }, 
    { "label": "Incomplete", "value": "21" },
    { "label": "Cancelled", "value": "28" },
    { "label": "Booking Failed", "value": "25" },
    { "label": "On Hold", "value": "22" },
    { "label": "Cancel Request", "value": "48" },
    { "label": "Reissue Request", "value": "49" },
    { "label": "Refund/booking", "value": "42" }
]

  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handleSearch = () => {
    if (validateSearch()) {
      const payload = {
        channel_type: channel_type || undefined,
        bid: state?.booking_id || undefined,
        product: state?.productType || undefined,
        case_number: state?.case_number || undefined,
        case_status: state?.case_status || undefined,
        issue_status: state?.issue_status || undefined,
        fmdate: (fromDate && fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : undefined,
        todate: (toDate && toDate !== "") ? DateUtils.convertStringToDate(toDate) : undefined
      }
      props.searchQueueCriteria(payload);
    }
  }
  const resetSearch = () => {
    dispatch({ type: 'booking_id', payload: "" })
    dispatch({ type: 'productType', payload: "" })
    dispatch({ type: 'case_number', payload: "" })
    dispatch({ type: 'case_status', payload: "" })
    dispatch({ type: 'issue_status', payload: "" })
    setChanelType("");
    setFromDate("")
    setToDate("");
    props.clearAdvancedSearchFields();
  }
  const handleOnChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }

  const handleChannelType = (val) =>{
     let sel = val && val.length >0 ? val[0].value : "";
     if(sel){
      setChanelType(sel);
     }
  }
  const handleCaseStatus = (val) =>{
     let sel = val && val.length >0 ? val[0].value : "";
      if(sel==49) { 
      dispatch({ type: "case_status", payload: 51 });
    }else if(sel==48){
    dispatch({ type: "case_status", payload: 50 })
  }else{
    dispatch({ type: "case_status", payload: sel });
  }
  }
  const handleIssueStatus = (val) =>{
     let sel = val && val.length >0 ? val[0].value : "";
     if(sel){    
      dispatch({ type: "issue_status", payload: sel });
     }
  }

  const validateSearch = () => {
    if (channel_type === "" && fromDate === "" && toDate === "" && state.booking_id === "") {
      dispatch({ type: 'notiMessageShow', payload: true });
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one search criteria and proceed' });
      dispatch({ type: 'notiVarient', payload: 'danger' });
      hidemessage();
      return false;
    } else if (fromDate !== "" && toDate !== "" && fromDate === toDate) {
      dispatch({ type: 'notiMessageShow', payload: true });
      dispatch({ type: 'notiMessage', payload: 'From date and To date cannot be same.' });
      dispatch({ type: 'notiVarient', payload: 'danger' });
      hidemessage();
      return false;
    }
    return true;
  }

  const productsChange = (value) => {
    let selected_value = value[0]?.value;
    dispatch({ type: "productType", payload: selected_value });
  }

  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <div className='cardMainlayout'>

        <Form.Row>
          <Form.Group className="selectplugin" as={Col} xs={3} controlId="channel_type">
            <Form.Label>Channel Type</Form.Label>
            <Select
              options={channel_types}
              searchable={false}
              values={channel_temp}
              onChange={(value) => handleChannelType(value)}
              classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}

            />

          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="booking_id" className="selectplugin">
            <Form.Label>Booking Ref Number</Form.Label>
            <Form.Control type="text" value={state.booking_id} placeholder="Booking Ref Number"
              onChange={handleOnChange} />

          </Form.Group>

          <Form.Group className="selectplugin" as={Col} xs={3} controlId="product">
                    <Form.Label>Product </Form.Label>
                    <Select
                            options={selectOptions}
                            searchable={false}
                            value={state.productType}
                            onChange={(value) => productsChange(value)}
                            placeholder="Select Product"
                            className="regionDrop"
                            classNamePrefix='filter'
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#F0F0F0',
                                primary: '#3156ab',
                                cursor: 'pointer',
                              },
                            })}
                        />
                    </Form.Group>

          <Form.Group as={Col} xs={3} controlId="case_number" className="selectplugin">
            <Form.Label>Case Number</Form.Label>
            <Form.Control type="text" value={state.case_number} placeholder="Case Number"
              onChange={handleOnChange} />
          </Form.Group>

          <Form.Group className="selectplugin" as={Col} xs={3} controlId="case_status">
            <Form.Label>Case Status</Form.Label>
            <Select
              options={booking_status}
              searchable={false}
              value={state.case_status}
              onChange={(value) => handleCaseStatus(value)}
              classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
            />
          </Form.Group>

          <Form.Group className="selectplugin" as={Col} xs={3} controlId="issue_status">
            <Form.Label>Issue Status</Form.Label>
            <Select
              options={Constants.issue_status_dropdown}
              searchable={false}
              value={state.issue_status}
              onChange={(value) => handleIssueStatus(value)}
              classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
            />
          </Form.Group>

          <Form.Group as={Col} xs={3} className="selectplugin datePicker mb-0" controlId="fromDate">
            <Form.Label>Case From</Form.Label>
            <DatePicker
              selected={fromDate}
              onChange={date => setFromDate(date)}
              fromDate={fromDate}
              toDate={toDate}
              maxDate={new Date()}
              placeholderText="MM/DD/YYYY"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            <span className="calendar ezyIcon icon-newcalendar"></span>

          </Form.Group>

          <Form.Group as={Col} xs={3} className="selectplugin datePicker datepicleft mb-4" controlId="toDate">
            <Form.Label>Case To</Form.Label>
            <DatePicker
              selected={toDate}
              onChange={date => setToDate(date) + 1}
              fromDate={fromDate}
              toDate={toDate}
              minDate={fromDate ? fromDate : null}
              className="datefield"
              placeholderText="MM/DD/YYYY"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            <span className="calendar ezyIcon icon-newcalendar"></span>

          </Form.Group>
        </Form.Row>

        <div className="text-right pb-0 buttonGrop  creat_absolute">
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            className="mr-3"
            onClick={resetSearch}
          > Reset</Button>
          <Button
            size="xs"
            variant="primary"
            type="submit"
            onClick={handleSearch}
          > Search</Button>
        </div>
      </div>
    </>
  )
}
export default CommonQueueSearch