import React,{ useState,useEffect } from 'react'
import axios from "axios";
import { Alert, Col, Form, Modal, Row, Container } from "react-bootstrap";
import Button from '../common/Button';
import {alpahnumericvalidate} from './../utilities/FormValidations';
import { useFileUpload } from 'use-file-upload'

const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH

const FileUpload = (props) => {
  const [files, selectFiles] = useFileUpload()
  const [urls,setUrls] = useState({urlsList:[]})
  const [enableUrl,setEnableUrl] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [file, setFile] = useState();
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [isLoading, setIsLoading,] = useState(false);
  const [pleasewait, setPleasewait,] = useState(false);
  const [single, setSingle,] = useState(true);
  const [selFile, selectFile] = useFileUpload()
  const [disableBrowse, setDisableBrowse] = useState()

  useEffect(() => {
    if(props.reportUrls!=="" && props.reportUrls!==null && props.reportUrls!==undefined){
      let url = props.reportUrls.split("/");
      let name = url[url.length - 1];
      const payload={
        "name":name,
        "url":props.reportUrls,
        "type":'companyimage'
      }
      {urls.urlsList.length==0 &&
        urls.urlsList.push(payload);
        setUrls({urlsList:urls.urlsList});
        setEnableUrl(true);
      }
    }
  }, [props.reportUrls]);
  useEffect(()=>{
    if(props.urlsList !== undefined && props.urlsList.length > 0){
      const payload={
        "name":props.urlsList&& props.urlsList[0]&& props.urlsList[0].name,
        "url":props.urlsList&& props.urlsList[0]&& props.urlsList[0].url,
        "type":'companyimage'
      }
      urls.urlsList.push(payload);
      setUrls({urlsList:urls.urlsList});
      setEnableUrl(true);

    }
  }, [props.urlsList])
  useEffect(()=>{
    if(props.disabled !== undefined){
      setDisableBrowse(true)
    }
  }, [props.disabled])

  const showLogoPopup = (obj) =>()=> {
    setShowPopup(true);
    setFile(obj);
   }

   //Call Delete service
  const deleteFile=()=>{
    setIsLoadingMore(true);
    setIsLoading(true);
    setPleasewait(true);
    const url = API_URL_COMMON+"/file/delete";;
    const req={"url":file.url,"type":'companyimage'}
    axios.post(url, req).then((response) => {
       if (response.data.suc){
        const finalUrls = urls.urlsList.filter((item) => item.name !== file.name);
        setUrls({urlsList:finalUrls});
        setShowPopup(false);
        setIsLoadingMore(false);
        setIsLoading(false);
        setPleasewait(false);
        props.sendFileUrl("");
        setNotiMessageShow(true);
        setNotiMessage('Logo deleted successfully..');
        setNotiVarient('success');
        hidemessage();
       }else{
        setShowPopup(false);
        setIsLoadingMore(false);
        setNotiMessageShow(true);
        setIsLoading(false);
        setPleasewait(false);
        setNotiMessage('Logo deletion failed');
        setNotiVarient('danger');
        window.scrollTo(0,0);
        hidemessage();
       }
   });
  }
  //Validating the Logo
  const validateLogo = (fileObj) => {
    if (fileObj) {
      const size = Math.round((fileObj.size / 1024));

      //validating the file name
      if (!alpahnumericvalidate(fileObj.name)) {
        setNotiMessageShow(true);
        setNotiMessage('File name should not have spaces & special characters');
        setNotiVarient('danger');
        hidemessage();
        return false;

      }
      //validteing the file size
      else if (size >= 1024) {
        setNotiMessageShow(true);
        setNotiMessage('Logo size should not exceed 1MB');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      //validat the file extension
      else if (!fileObj.name.includes(".jpg")) {
        setNotiMessageShow(true);
        setNotiMessage('Logo format should be JPG');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
    }
    return true;
  }

  //Call ther to upload selected file or Logo
  const uploadLogo=(e)=>{
    e.preventDefault();
    if(disableBrowse){
      return
    }
    setIsLoadingMore(true);
    if((single && urls.urlsList.length===0) || props.type==="Visa" ){
      setUrls({urlsList:[]});
      selectFile({}, ({ source, name, size, file }) => { //Single file - is the raw File Object
        console.log({ source, name, size, file })
        if(validateLogo(file)){
        const url = "http://65.0.46.253:3008/att/file/upload";
        console.log("file upload url : "+url)
        const formData = new FormData();
        formData.append('image',file)
        formData.append('type','companyimage');
        axios.post(url, formData).then((response) => {
          console.log("file upload response : "+JSON.stringify(response))
           if (response.data.suc && response.data.res.length!==0){
            const payload={
              "name":name,
              "url":response.data.res.url
            }
            urls.urlsList.push(payload);
            setUrls({urlsList:urls.urlsList});
            setEnableUrl(true);
            props.sendFileUrl(response.data.res.url,name);
            setIsLoadingMore(false);
            setNotiMessageShow(true);
            setNotiMessage('Logo uploaded successfully..');
            setNotiVarient('success');
            hidemessage();
           }
       });
      }
      })
    }else{
      setIsLoadingMore(false);
      setNotiMessageShow(true);
      setNotiMessage('Already logo is uploaded');
      setNotiVarient('danger');
      hidemessage();
    }
    // Multiple File Upload
    {!single &&
    selectFiles({ multiple: false }, (files) => {
        files.map(({ source, name, size, file }) =>{
          const url = "";
          const formData = new FormData();
          formData.append('image',file);
          formData.append('type','companyimage');
          axios.post(url, formData).then((response) => {
             if (response.data.suc && response.data.res.length!==0){
              const payload={
                "name":name,
                "url":response.data.res.url
              }
              urls.urlsList.push(payload);
              setUrls({urlsList:urls.urlsList});
              setEnableUrl(true);
              setIsLoadingMore(false);
              setNotiMessageShow(true);
              setNotiMessage('Logo uploaded successfully..');
              setNotiVarient('success');
              hidemessage();
             }
         });
      })
    })
  }
  }

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 5000);
  }

  return (
    <div  className="upload-report visaUpImg">
      {showPopup &&
    <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog"
        keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Logo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Are you sure you want to delete selected Logo ?</Form.Label>
                    </div>
                    <div className="text-center buttonGrop">
                        <Button
                        size="xs"
                        variant="outline-primary"
                        onClick={deleteFile}
                        disabled = {isLoading && pleasewait ? true : false } >
                          Yes
                        </Button>
                        {pleasewait ? "" : "" }
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => setShowPopup(false)}
                            type="submit"> No </Button>
                    </div>
                </div>
             </Modal.Body>
    </Modal>
}
      <Row>

        <Form.Group as={Col} xs={3} className="selectplugin">
          <div className="uploadButton hotelIMageup">
            <label>{props.type === "Company" ? "Add Logo" : "Add Hotel Image"}</label>
            <Button  type = "button" className="addlogo ezyIcon icon-attach-ico"
              onClick={(e)=>uploadLogo(e)}
            > Drag and Drop or Browse</Button>

          </div>
        </Form.Group>

      </Row>
  
      {/* {isLoadingMore ?  <InnerLoader/>:""} */}
      <div className="addedlayout">
      <Alert className="notification alert" variant={notiVarient} show={notiMessageShow}
       onClose={() => setNotiMessageShow(false)}
       dismissible> {notiMessage}</Alert>
      {urls.urlsList!==undefined && urls.urlsList.length!==0  && enableUrl ? (
        urls.urlsList.map((file,idx) => (
          <React.Fragment key={idx}>
          <div className="d-flex mt-1 mb-2">
           <a className="d-flex mr-3" href={file.url} target='_blank'>{file.name} </a>
            <div className="d-flex">
              <span
              className="iconBtn add ezyIcon icon-close"
              onClick={showLogoPopup(file)}
              >
            </span>

          </div></div>
          </React.Fragment>
        ))
      ) : (
        <span className="d-flex mt-1 mb-2">No file selected </span>
      )}
      </div >
        <p className='pb-0 mb-0'>  1. File size should not exceed 1 MB,<br/>
           2. File dimensions should be less or equal to 200 X 200,<br/>
           3. File format should be JPG<br/>
           4. File name should not have spaces & special characters</p>
    </div>
  )

}

export default FileUpload