import log from "loglevel";
import React, { useReducer, useState,useEffect,useContext } from 'react';
import { Col, Form,Alert } from "react-bootstrap";
import { Context } from "../../../App";
import Select from 'react-dropdown-select';
import Button from '../../common/Button';
import ManageBookingService from "../../../services/ManageBookingService";

let lccSuppliers = require('../../masterData/Suppliers.json');

/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-11-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id: 0, selOfcId: [], insertPnr: "",isItineraryLoading: false,sup:"0",selSupliers:[{ label: "GDS", value: 'GDS' }],selSupRT:[{ label: "GDS", value: 'GDS' }],rerun_pnr:""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function FetchPNR(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoad, setIsLoad] = useState(false);
    const [officeIdList, setOfficeIdList] = useState([]);
    const flightTypes = [{ label: "GDS", value: 'GDS' },{ label: "LCC", value: 'LCC' }]
    const [context, setContext] = useContext(Context);
    useEffect(() => {
        if(props.region!==undefined){
            ManageBookingService.loadOfficeIdList({"region":props.region,"cid":props.cid,"is_admin":context.logindata.is_admin}).then(response => {
                if(response.data.suc){
                    setOfficeIdList(response.data.res)
                  }
              }).catch(function (error) {
                log.error(
                  "Exception occured in loadOfficeIdList function---" + error
                );
              })
            }
      }, [context.logindata])
    

    const handleofcid = (e) => {
        dispatch({ type: 'selOfcId', payload: e });
        if(!props.isContentAgg){
          props.sendOwOfc(e[0].label);
        }else{
          if(props.trip==="Onward"){
            props.sendOwOfc(e[0].label);
          }else{
            props.sendRtOfc(e[0].label);
          }
        }
    }

    const handleChangePnr = (evt) => {
        const { id, value } = evt.target;
        dispatch({ type: id, payload: value });
        if(!props.isContentAgg){
          props.sendOwGDSPnr(value);
        }else{
          if(props.trip==="Onward"){
            props.sendOwGDSPnr(value);
          }else{
            props.sendRtGDSPnr(value);
          }
        }
    }

    const callRetrieve = (pnr,trip) => {
        const obj = {
          "pnr": pnr,
          "office_id": state.selOfcId.label,
          "region": props.region,
          "req_cur": "QAR",
          "type": "INSERT_PNR"
        }
        setIsLoad(true)
        ManageBookingService.getPNRDetails(obj).then((resp) => {
          if (resp.data.suc) {
            if(props.ttype === "2") {
              props.sendPNRResp(resp.data.res,props.ttype);
            } else if(!props.isContentAgg){
                props.sendPNRResp(resp.data.res,trip);
            }else{
                if(props.trip==="Onward"){
                    props.sendPNRResp(resp.data.res,trip);
                }else{
                    props.returnPNRResp(resp.data.res,trip);
                }
            }
            dispatch({ type: 'isItineraryLoading', payload: false })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Successfully fetched PNR Details' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            hidemessage();
            setIsLoad(false)
          } else {
            dispatch({ type: 'isItineraryLoading', payload: false })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'PNR details not available.' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            setIsLoad(false)
          }
        })
      }

      const hidemessage = () => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    //Retriveing the PNR Details
  const retrivePNRData = (trip) => {
    if (state.insertPnr !== "" && state.selOfcId.length!=0) {
      dispatch({ type: 'isItineraryLoading', payload: true })
      callRetrieve(state.insertPnr,trip)
    }else if (state.insertPnr === "") {
      dispatch({ type: 'isItineraryLoading', payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Enter PNR' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }else if (state.selOfcId.length==0) {
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Select Office Id' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
  }

  const setFlightType = (data) => {
    dispatch({ type: 'sup', payload: data[0].value === "GDS" ? "0" : "1" })
    dispatch({ type: "selSupliers", payload: data })
    dispatch({ type: "selFltType", payload: [{ label: "GDS", value: 'GDS' }] })
    if(props.trip==="Onward"){
      props.sendOWSup(data[0].value === "GDS" ? "0" : "1");
  }else{
      props.sendRTSup(data[0].value === "GDS" ? "0" : "1");
  }
  }

  const CaLccSup = (value) => {
    if(props.trip==="Onward"){
      props.sendOwLccSup(value[0].value)
    }else{
      props.sendRtLccSup(value[0].value)
    }
  }

    return (
        <>     {state.notiMessageShow &&
               <Alert className="notification alert" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
               }
               
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                        <div class="loaderPage">
                        <div class="loaderIcon"></div>
                        </div>
                    </div>
                    ) : ""}
             <Form.Row>
             {!props.isContentAgg &&
            <>
            <Form.Group as={Col} xs={3} className="selectplugin pb-0 mb-0" controlId="branch_id">
                <Form.Label>Office Id<sup>*</sup></Form.Label>
                <Select
                    options={officeIdList}
                    values={state.selOfcId}
                    searchable={false}
                    placeholder="Select"
                    onChange={(e) => handleofcid(e)}
                    classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
                />
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="insertPnr" className="selectplugin pb-0 mb-0">
                <Form.Label>GDS PNR <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter GDS PNR" value={state.insertPnr}
                    autoComplete="off" onChange={handleChangePnr} />
                <span className="noteQpnr"><span>Note :</span> Please Queue your PNR to {""}</span>
            </Form.Group>
            <div className="text-right ml-1">
               {!isLoad? <Button
                    size="xs"
                    variant="secondary"
                    onClick={() => retrivePNRData(props.trip)}
                    type="submit"
                >Fetch PNR</Button>:
                <div class="progress progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Fetching..</span>
                </div>
                </div>
                }
            </div>
            </>
             }
           </Form.Row>
           <Form.Row>
            {props.isContentAgg &&
            <>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                    <Form.Label>Flight Type <sup>*</sup></Form.Label>
                    <Select
                        options={flightTypes}
                        searchable={false}
                        values={state.selSupliers}
                        onChange={(value) => setFlightType(value)}
                        classNamePrefix='filter'
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#F0F0F0',
                            primary: '#3156ab',
                            cursor: 'pointer',
                          },
                        })}
                    />
                </Form.Group>
                
              {state.sup === "1" &&
                <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                   <Form.Label>LCC Suppliers <sup>*</sup></Form.Label>
                  <Select
                    options={lccSuppliers}
                    searchable={true}
                    //value={state.lcc_sup}
                    onChange={(value) => CaLccSup(value)}
                    classNamePrefix='filter'
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#3156ab',
                        cursor: 'pointer',
                      },
                    })}
                  />
                 
                </Form.Group>
              }
                {state.sup==="0" &&
                <>
                <Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                <Form.Label>Office Id<sup>*</sup></Form.Label>
                <Select
                    options={officeIdList}
                    values={state.selOfcId}
                    searchable={false}
                    onChange={(e) => handleofcid(e)}
                />
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="insertPnr" className="selectplugin">
                <Form.Label>GDS PNR <sup>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter GDS PNR" value={state.insertPnr}
                    autoComplete="off" onChange={handleChangePnr} />
                <span className="noteQpnr"><span>Note :</span> Please Queue your PNR to {""}</span>
               </Form.Group>
               <div className="text-right">
               {!isLoad? <Button
                    size="xs"
                    variant="secondary"
                    onClick={() => retrivePNRData(props.trip)}
                    type="submit"
                >Fetch {props.trip} PNR</Button>:
                <div class="progress progress-striped active">
                <div role="progressbar progress-striped" class="progress-bar">
                  <span>Fetching..</span>
                </div>
                </div>
                }
                
                </div>
                </>
                }
            </>
            }
            </Form.Row>

        </>
    )
}
export default FetchPNR