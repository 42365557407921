import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
export const USER_AUTH_DATA = "userAuthData";

/**
 * @author Praveen Varma
 * @date 29-11-2023
 * @description Protected routes
 * @param {*} props 
 * @returns 
 */
const ProtectedRoute = ({ children }) => {
  const userData = localStorage.getItem(USER_AUTH_DATA);
  let location = useLocation();
  if (userData === undefined || userData === null || userData === "") {
    return <Navigate to="/crm/login" state={{ from: location }} replace />
  }
  return children
}

export default ProtectedRoute;