import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-bootstrap/Modal';
import log from "loglevel";
import Constants from '../../utilities/Constants'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DateUtils from '../../common/DateUtils';

/**
 * @description: History data for status change
 * @author: Praveen Varma
 * @param {*} props 
 * @date : 07-11-2023
 * @returns 
 */
function HistoryPopup({ history_data, close, tkt_num_history }) {
    const [show, setShow] = useState(false);
    const [state, setState] = useState({ case_number: "", ticket_number:"", book_ref: "" });

    const columns = [
        
        {
            dataField: 'crm_status',
            text: 'Issue Status',
            formatter: (cell, row) => {
                return Constants.issue_status_dropdown.filter(itm => itm.value && cell).find(x => x.value + "" === cell + "")?.label;
            },
        },
       
        {
            dataField: 'created_by',
            text: 'Created By'
        },
        {
            dataField: 'created_date',
            text: 'Created Date',
            formatter: (cell, row) =>{
                return cell ? DateUtils.convertToDateFormate(cell) : "";
            }
        },
        {
            dataField: 'remarks',
            text: 'Remarks',
        }
    
    
    ];

    const handleClose = () => {
        setShow(false);
        close();
    }
    const handleShow = () => {
        setShow(true)
        close();
    };

    useEffect(() => {
        if(history_data && history_data.length > 0){
            setState(prevState => ({
                ...prevState,
                case_number: history_data[0]?.case_number,
                book_ref: history_data[0]?.booking_id,
                ticket_number: tkt_num_history || ""
            }));
        }
        setShow(true);
    }, [])

    return (
        <div>
            <Modal show={show} onHide={handleClose} className='historyPop right'>
                <Modal.Header closeButton>
                    <Modal.Title>History data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                         <span>Case number :</span> <span>{state?.case_number}</span>
                        </Form.Group>
                        <Form.Group as={Col}>
                        <span> Booking ref :</span> <span>{state?.book_ref}</span>
                        </Form.Group>
                        {state?.ticket_number != "" &&
                        <Form.Group as={Col}>
                           <span> Ticket number :</span> <span> {state?.ticket_number}</span>
                        </Form.Group>
                        }
                    </Form.Row>
                   <div className="cardMainlayout tableRes p-0">
                        <div className="tablescroll">
                            <BootstrapTable
                                bootstrap4
                                keyField="case_number"
                                data={history_data}
                                classes="markup filterTbl"
                                columns={columns}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default HistoryPopup;