import React from 'react';
import Header from "../../common/Header";
import Sidebarmenu from '../../home/SideBar';
export const USER_AUTH_DATA = "userAuthData";

/**
 * @author Lakshmi
 * @date 03-11-2023
 * @description Login page
 * @param {*} props 
 * @returns 
 */
function HotelPostBook() {
   
    
    return (
        <>
            <div>
                <span>POST Booking</span>
            </div>
        </>
    );
}
export default HotelPostBook;